'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationPreviewDeviceController
 * @description
 * # ConversationPreviewDeviceController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationPreviewDeviceController',
    [function () {

    var init = function () {
    };

    init();
}]);

(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:conversationPreviewDevice
     * @description
     * # conversationPreviewDevice
     */
    angular.module('dialengaExeviBackoffice').component('conversationPreviewDevice', {
        bindings: {
            previewState: '='
        },
        templateUrl: '/ng1/components/conversation-preview/conversation-preview-device.html',
        controllerAs: 'vm',
        controller: ['$rootScope', 'ConversationsService', function($rootScope, ConversationsService) {
                var vm = this;

                this.$onInit = function() {
                    getMotives();
                };

                function getMotives() {
                    var options = {size: 20000, sort: 'contents.motive,asc', 'archived': false};
                    ConversationsService.findAllMotives(options).then(function (response) {
                        vm.motives = response.data.content;
                    });
                }

                vm.closePreview = function() {
                    vm.previewState.opened = false;
                };

                $rootScope.$on('dialenga:reloadMotivesPreview', function () {
                    getMotives();
                });
            }
        ]
    });
})();
