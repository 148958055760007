'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationEmployeeModalController
 * @description
 * # ConversationEmployeeModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationEmployeeModalController',
    ['$uibModalInstance', '$timeout', '$translate', 'ConversationsService', 'KendoUtilsService', 'UsersService', 'params',
    function ($uibModalInstance, $timeout, $translate, ConversationsService, KendoUtilsService, UsersService, params) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.valid = false;
    vm.conversation = {
        tray: {},
        motive: {},
        message: ''
    };
    vm.attachedFiles = [];

    //******************************************* Methods ***************************************\\
    vm.accept = function () {
        var newConversation = {
            data: {
                toUserId : vm.conversation.employee.id,
                tray: vm.conversation.tray.id,
                motive: vm.conversation.motive.id,
                message: vm.conversation.message
            }
        }
        ConversationsService.createConversationWithEmployee(newConversation, vm.attachedFiles).then(function () {
            $uibModalInstance.close(vm.conversation);
        });

    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        vm.valid = (vm.conversation.employee && !!vm.conversation.employee.id) && (vm.conversation.tray && !!vm.conversation.tray.id) && (vm.conversation.message || (vm.attachedFiles && vm.attachedFiles.length > 0));
    };

    vm.onChange = function () {
        validateModel();
    };

    vm.attachFile = function () {
        document.getElementById('conversation-attach-file').click();
    };

    vm.onFileSelected = function ($files) {
        if ($files.length > 0) {
            vm.attachedFiles.push($files[0]);
            vm.onChange();
        }
    };

    vm.removeAttachedFile = function (index) {
        if (vm.attachedFiles.length > index) {
            vm.attachedFiles.splice(index, 1);
        }
        vm.onChange();
    };

    function applyEditorChangesToTextModel() {
        $timeout(function() {
            vm.conversation.message = $('#kendoTextEditor').data('kendoEditor').value();
            vm.onChange();
        });
    }

    var init = function() {
        if (params && params.employee) {
            vm.conversation.employee = params.employee;
        }
        var employeeFilter = !!vm.conversation.employee ? vm.conversation.employee.name : '';
        vm.employeeOptions = UsersService.getEmployeeOptions(employeeFilter);

        vm.traysOptions = ConversationsService.getTraysIconValueDropDownListOptions();
        vm.traysOptions.optionLabel.tray = $translate.instant('documents.import.attachFiles')

        vm.motivesOptions = ConversationsService.getMotivesIconValueDropDownListOptions();
        vm.motivesOptions.optionLabel.motive = $translate.instant('documents.import.attachFiles')

        vm.textareaOptions = KendoUtilsService.getTextareaOptions(applyEditorChangesToTextModel);
        vm.textareaOptions.tools.splice(0,1);
        vm.textareaOptions.tools.splice(3,2);
    };

    init();
}]);