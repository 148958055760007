(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:stateBadge
     * @description
     * # stateBadge
     */
    angular.module('dialengaExeviBackoffice').component('stateBadge', {
        bindings: {
            state: '<'
        },
        controller: ['MESSAGE_STATES', function (MESSAGE_STATES) {
            var vm = this;

            var stateName = 'global.states.';
            vm.$onInit = function () {
                switch (parseInt(vm.state)) {
                    case MESSAGE_STATES.DRAFT:
                        vm.stateColor = '#C6C6C6';
                        vm.stateName = stateName + 'draft';
                        break;
                    case MESSAGE_STATES.PENDING:
                        vm.stateColor = '#F5BE68';
                        vm.stateName = stateName + 'pending';
                        break;
                    case MESSAGE_STATES.EXPIRED:
                        vm.stateColor = '#81969C';
                        vm.stateName = stateName + 'expired';
                        break;
                    case MESSAGE_STATES.PLANNED:
                        vm.stateColor = '#03CFD7';
                        vm.stateName = stateName + 'planned';
                        break;
                    case MESSAGE_STATES.PUBLISHED:
                        vm.stateColor = '#5FAE4B';
                        vm.stateName = stateName + 'published';
                        break;
                    case MESSAGE_STATES.PUBLISHING_NOW:
                    case MESSAGE_STATES.DELETED:
                    default:
                        break;
                }
                
            };
        }],
        controllerAs: 'vm',
        template: '<span class="state-badge" data-translate="{{vm.stateName}}" ' +
            'data-ng-style="{\'background-color\': vm.stateColor}"></span>'
    });
})();
