/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserAbsencesModalController
 * @description
 * # UserAbsencesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserAbsencesModalController', ['$translate', '$uibModalInstance', 'TimeoffService', 'params',
    function($translate, $uibModalInstance, TimeoffService, params) {

        var vm = this;
        vm.isChanged = vm.isValid = false;
        vm.userId = params.userId;
        vm.summaryHolidays = params.summaryHolidays;
        vm.currentPeriod = params.currentPeriod;

        vm.percents = {};
        
        var latestPeriodAbsences = {
            content: {}
        };

        vm.periodAbsences = {
            content: {}
        };

        function getPercentsDays() {
            var startingDays = vm.periodAbsences.periodDays + vm.periodAbsences.acquiredDays + vm.periodAbsences.extraDays;
            vm.periodAbsences.startingDays = parseFloat(startingDays.toFixed(2));

            if (vm.periodAbsences.startingDays === 0) {
                vm.percents.percentAcquiredDays = 0;
                vm.percents.percentPeriodDays = 0;
                vm.percents.percentExtraDays = 0;
            } else {
                vm.percents.percentAcquiredDays = vm.periodAbsences.acquiredDays > 0 ? (vm.periodAbsences.acquiredDays * 100) / vm.periodAbsences.startingDays : 0;
                vm.percents.percentExtraDays = vm.periodAbsences.extraDays > 0 ? (vm.periodAbsences.extraDays * 100) / vm.periodAbsences.startingDays : 0;
                vm.percents.percentPeriodDays = vm.periodAbsences.periodDays > 0 ? vm.periodAbsences.periodDays * 100 / vm.periodAbsences.startingDays : 0;
            }
        }

        function dataHasChanged() {
            return !angular.equals(vm.periodAbsences, latestPeriodAbsences);
        }

        function isValidForm() {
            return (vm.periodAbsences.periodDays >= 0 && vm.periodAbsences.periodDays <= vm.periodAbsences.schemaDays);
        }

        function updateTooltipContent(id, value) {
            var tooltip = $(id).data('kendoTooltip');
            tooltip.options.content = $translate.instant('users.holidays.amountDays', {days: value}, 'messageformat');
            tooltip.refresh();
        }

        function getAmountDaysTooltip(daysAmount) {
            updateTooltipContent('#amount-period-days-modal', daysAmount.periodDays);
            updateTooltipContent('#amount-acquired-days-modal', daysAmount.acquiredDays);
            updateTooltipContent('#amount-extra-days-modal', daysAmount.extraDays);
        }

        vm.accept = function() {
            $uibModalInstance.close(vm.periodAbsences);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.onInputFieldDecimalChange = function(value) {
            if (value) {
                value.toFixed(1);
            }
            vm.onChange();
        };

        vm.onChange = function() {
            if (typeof vm.periodAbsences.acquiredDays !== 'undefined' && typeof vm.periodAbsences.extraDays !== 'undefined' &&
                (vm.periodAbsences.acquiredDays >= 0 && vm.periodAbsences.acquiredDays < 100) && (vm.periodAbsences.extraDays >= 0 && vm.periodAbsences.extraDays < 100)) {
                getAmountDaysTooltip(vm.periodAbsences);
                getPercentsDays();
            }
            vm.isChanged = dataHasChanged();
            vm.isValid = isValidForm();
        };

        vm.onPeriodDaysModelChange = function() {
            if (typeof vm.periodDaysModel !== 'undefined') {
                vm.periodAbsences.periodDays = vm.periodDaysModel;
                vm.onChange();
            }
        };

        function init() {
            TimeoffService.getUserPeriodAbsences(vm.userId).then(function(response) {
                vm.periodAbsences = response.data;
                if (vm.periodAbsences.periodDays === vm.periodAbsences.schemaDays) {
                    vm.periodDaysModel = null;
                } else  {
                    vm.periodDaysModel = vm.periodAbsences.periodDays;
                }
                latestPeriodAbsences = angular.copy(response.data);
                getPercentsDays();
                getAmountDaysTooltip(vm.periodAbsences);
            });
        }

        init();
    }
]);