'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:userstateTooltipFilter
 * @function
 * @description
 * # userstateTooltipFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('userStateTooltipFilter',
    ['$filter', 'USER_STATES',
    function ($filter, USER_STATES) {
    return function (state, lastAccessDate) {
        var tooltip = 'global.userstates.';
        switch(parseInt(state)){
            case USER_STATES.ANONYMOUS:
                tooltip += 'anonymous';
                break;
            case USER_STATES.PENDING_ACTIVATION:
                tooltip += 'pendingactivation';
                break;
            case USER_STATES.REGISTERED:
                tooltip += (typeof lastAccessDate === 'undefined' || lastAccessDate) ? 'registered' : 'pendingAccess';
                break;
            case USER_STATES.EXPIRED:
                tooltip += 'expired';
                break;
            case USER_STATES.DISABLED:
                tooltip += 'disabled';
                break;
            case USER_STATES.EXPIRED_CREDENTIALS:
                tooltip += 'expiredCredentials';
                break;
            case USER_STATES.LOCKED:
                tooltip += 'locked';
                break;
            default:
                tooltip = '';
                break;
        }
        return $filter('translate')(tooltip);
    };
}]);
