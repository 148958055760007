'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CreateSegmentModalController
 * @description
 * # CreateSegmentModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CreateSegmentModalController',
    ['$scope', 'params', '$uibModalInstance', 'SegmentService',
    function ($scope, params, $uibModalInstance, SegmentService) {

    //****************************************** Attributes *************************************\\
    $scope.changed = false;
    $scope.valid = false;
    $scope.segmentForm = {
        segment: {}
    };

    //******************************************* Methods ***************************************\\
    $scope.accept = function () {
        if($scope.segmentForm.segment.id){
            SegmentService.update($scope.segmentForm).then(function (response) {
                SegmentService.latestSegment = response.data;
                $uibModalInstance.close();
            });
        }else{
            SegmentService.create($scope.segmentForm).then(function (response) {
                SegmentService.latestSegment = response.data;
                $uibModalInstance.close();
            });
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        var valid = true;
        if (!$scope.segmentForm.segment.name) {
            valid = false;
        }
        $scope.valid = valid;
    };

    $scope.onChange = function () {
        $scope.changed = true;
    };

    var init = function () {
        var segmentId = params.id;
        if (segmentId) {
            SegmentService.findOne(segmentId).then(function (response) {
                $scope.segmentForm.segment = response.data;
                validateModel();
            });
        }
    };

    $scope.$watch('changed', function (newValue, oldValue) {
        if (newValue) {
            validateModel();
            $scope.changed = false;
        }
    });

    init();
}]);
