'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayController
 * @description
 * # FeedbackTrayController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayController', 
    ['$scope', '$rootScope', '$route', '$routeParams', '$translate', '$location', '$timeout', 'FeedbackTraysService', 'LanguageService', 'DateUtilsService', 
    'NotificationService', 'ModalService', 'FEEDBACKTRAY_TABS', 'selectedTab',
    function($scope, $rootScope, $route, $routeParams, $translate, $location, $timeout, FeedbackTraysService, LanguageService, DateUtilsService, 
            NotificationService, ModalService, FEEDBACKTRAY_TABS, selectedTab) {

        var originalFeedbackTray;

        //******************************************* Attributes ************************************\\
        $scope.feedbackTrayId = $routeParams.id;
        $scope.changed = false;
        $scope.valid = false;
        $scope.isFormDirty = false;
        $scope.usersGroupsScope = {};
        $scope.feedbackTray = {
            archived: true,
            validator: {},
            activeRating: true,
            contents: {},
            responsibles: [],
            anonymous: false
        };
        $scope.isPreviewVisible = false;
        $scope.currentTime = DateUtilsService.getTimeFromTimestamp(new Date().getTime());
        $scope.FEEDBACKTRAY_TABS = FEEDBACKTRAY_TABS;
        $scope.selectedTab = selectedTab;

        $scope.questionnaireHaveQuestions = true;
        var feedbackTraysPath = '/settings/conversations/feedback-trays';

        //******************************************** Methods **************************************\\
        $scope.loadTabContent = function(destinationTab) {
            if ($scope.selectedTab !== destinationTab && !!$scope.feedbackTrayId) {
                var arePendingChanges = ($scope.selectedTab === FEEDBACKTRAY_TABS.USERS_AND_GROUPS) ? $scope.usersGroupsScope.isFormDirty : $scope.isFormDirty;
                $rootScope.checkUpdatesAndChangeTab(arePendingChanges, changeTab, destinationTab);
            }
        };

        function changeTab (tab) {
            $scope.selectedTab = tab;
            if (tab === FEEDBACKTRAY_TABS.TRAY) {
                if ($scope.usersGroupsScope.discardChanges) {
                    $scope.usersGroupsScope.discardChanges();
                }
                setTooltips();
                $route.updateParams({ tabName: 'update' });
            } else if (tab === FEEDBACKTRAY_TABS.USERS_AND_GROUPS) {
                $scope.feedbackTray = angular.copy(originalFeedbackTray);
                $scope.isFormDirty = false;
                $route.updateParams({ tabName: 'users' });
            } else if (tab === FEEDBACKTRAY_TABS.QUESTIONNAIRE) {
                $scope.feedbackTray = angular.copy(originalFeedbackTray);
                $scope.isFormDirty = false;
                $route.updateParams({ tabName: 'questionnaire' });
            }
        }

        $scope.isPreviewVisibleFn = function(visibility) {
            return $scope.isPreviewVisible = visibility;
        }

        $scope.feedbackTrayFormSave = function() {
            if ($scope.isFormDirty) {
                LanguageService.removeEmptyLanguages($scope.feedbackTray);
                // if (!$scope.feedbackTray.validator.id) {
                //     $scope.feedbackTray.requestValidation = false;
                // }
                var promise;
                if ($scope.feedbackTray.id) {
                    promise = FeedbackTraysService.update($scope.feedbackTray);
                } else {
                    promise = FeedbackTraysService.create($scope.feedbackTray);
                }
                promise.then(function(response) {
                    var message = $translate.instant('global.messages.changesUpdated.success');
                    NotificationService.notifySuccess(message, 1000);
                    if (!$scope.feedbackTray.id) {
                        $location.path(feedbackTraysPath + '/update/' + response.data.id);
                    } else {
                        $scope.feedbackTray.responsibles = angular.copy(response.data.responsibles);
                        originalFeedbackTray = angular.copy($scope.feedbackTray);
                        $scope.isFormDirty = dataHasChanged();
                        $rootScope.$broadcast('dialenga:loadingEnd');
                        setTooltips();
                    }
                }, function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                    var message = $translate.instant('global.messages.error.unknown');
                    NotificationService.notifyError(message);
                });
            } 
        };

        $scope.validateModel = function() {
            var nonEmptyLanguagesValid = FeedbackTraysService.validateNonEmptyLanguages($scope.feedbackTray);
            $scope.valid = typeof($scope.feedbackTray.activeRating) !== "undefined" && nonEmptyLanguagesValid;
            $scope.isFormDirty = dataHasChanged();
        };

        $scope.onChange = function() {
            setTooltips();
            $scope.changed = true;
        };

        $scope.onChangeInitials = function() {
            var initials = $scope.feedbackTray.contents[$scope.selectedLanguage].initials;
            if (initials) {
                $scope.feedbackTray.contents[$scope.selectedLanguage].initials = initials.toUpperCase();
            }
            $scope.onChange();
        };

        $scope.setUserRating = function(value) {
            $scope.feedbackTray.activeRating = value;
            $scope.onChange();
        }

        function dataHasChanged() {
            return !angular.equals($scope.feedbackTray, originalFeedbackTray);
        }

        function setTooltips() {
            $timeout(function () {
                for (var index = 0; index < $scope.feedbackTray.responsibles.length; index++) {
                    var item = $scope.feedbackTray.responsibles[index];
                    var tooltipAvatar = $('#avatar-responsible-' + item.user).data('kendoTooltip');
                    if (tooltipAvatar) {
                        tooltipAvatar.options.content = item.name + ' '+ item.surname;
                        tooltipAvatar.refresh();
                    }
                }
            }, 200);
        }

        function confirmDeleteArchiveAction (title, message, acceptButtonText, promise, notificationMessage, localizedErrorString) {
            var feedbackTray = $scope.feedbackTray.alias ? $scope.feedbackTray.alias : $scope.feedbackTray.contents[$scope.selectedLanguage].nameInApp;

            ModalService.openConfirmationModal({
                title: title,
                message: message,
                messageData: {feedbackTray: feedbackTray},
                acceptButtonText: acceptButtonText,
                isTextInterpolated: true,
                accept: function () {
                    promise.call (FeedbackTraysService, $scope.feedbackTrayId, localizedErrorString)
                        .then( function() {
                            var notify = $translate.instant(notificationMessage, {feedbackTray: feedbackTray});
                            NotificationService.notifySuccess(notify);
                            $rootScope.goBackAndCheckForModifications(false, feedbackTraysPath);
                        })
                        .catch(actionErrorHandler)
                }
            });
        }

        $scope.goBack = function() {
            $scope.isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications($scope.isFormDirty, feedbackTraysPath);
        };

        $scope.archiveOrDelete = function () {
            if (!$scope.feedbackTray.deletable && !$scope.feedbackTray.archived) {
                confirmDeleteArchiveAction(
                    'feedbackTray.modal.archive.title',
                    'feedbackTray.modal.archive.message',
                    'global.form.archive',
                    FeedbackTraysService.archive,
                    'feedbackTray.notification.archive.message'
                );
            } else {
                confirmDeleteArchiveAction(
                    'feedbackTray.modal.delete.title',
                    'feedbackTray.modal.delete.message',
                    'global.form.delete',
                    FeedbackTraysService.delete,
                    'feedbackTray.notification.delete.message',
                    'messages.error.delete'
                );
            }
        };

        $scope.cloneTray = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/feedback-tray/feedback-tray-clone-modal.html',
                controller: 'FeedbackTrayCloneModalController',
                size: 'md',
                resolveParams: {
                    name: $scope.feedbackTray.contents[$rootScope.account.language].nameInApp || '',
                    language: $rootScope.account.language,
                    alias: $scope.feedbackTray.alias,
                    id: $scope.feedbackTray.id,
                    hasQuestionnaire: !!$scope.feedbackTray.questionnaire && !!$scope.feedbackTray.questionnaire.id
                },
                accept: function() {
                    // Toast for tray cloned
                    var message = $translate.instant('feedbackTray.notification.clone.message');
                    NotificationService.notifySuccess(message);
                    $rootScope.$broadcast('dialenga:loadingEnd');
                },
                cancel: function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        }

        var init = function() {
            $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
            if ($scope.feedbackTrayId) {
                FeedbackTraysService.findOne($scope.feedbackTrayId).then(function(response) {
                    $scope.feedbackTray = response.data;
                    // var responsibleFilter = $scope.feedbackTray.requiresValidation && !!$scope.feedbackTray.validator.id ? 
                    //                         $scope.feedbackTray.validator.name + ' ' +  $scope.feedbackTray.validator.surname : '';
                    // $scope.responsibleOptions = {
                    //     options: FeedbackTraysService.getEmployeeOptions(responsibleFilter)
                    // };

                    $scope.feedbackTray.alias = $scope.feedbackTray.alias === null ? '' : $scope.feedbackTray.alias;
                    originalFeedbackTray = angular.copy(response.data);
                    $scope.validateModel();
                    setTooltips();
                });
            } else {
                originalFeedbackTray = angular.copy($scope.feedbackTray);
                setTooltips();
            }
        };

        $scope.$watch('changed', function(newValue) {
            if (newValue) {
                $scope.validateModel();
                $scope.changed = false;
            }
        });

        $scope.$on('dialenga:questionnaireTrayDataUpdated', function(event, args) {
            $scope.questionnaireHaveQuestions = args.haveQuestions;
        })

        init();
    }
]);