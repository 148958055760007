'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.companiesService
 * @description
 * # companiesService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('CompaniesService',
    ['$http', '$location', '$q', 'SharedDataService',
    function ($http, $location, $q, SharedDataService) {

    var URL = SharedDataService.apiUrl + '/companies';

    function getCompanyConfiguration(companyId) {
        return $http({
            url: URL + '/configuration',
            method: 'GET',
            params: {
                brand: companyId
            }
        });
    }

    this.getCompanyLoginConfiguration = function (companyId) {
        var defaultCompanyConfiguration = {
            company: null
        };
        if (!companyId) {
            // Dialenga login, no company value is specified
            return $q.resolve(defaultCompanyConfiguration);
        } else {
            // Company specific login
            return getCompanyConfiguration(companyId).then(function (response) {
                var companyConfiguration = response.data;
                companyConfiguration.company = companyId;
                return $q.resolve(companyConfiguration);
            }, function (error) {
                // TODO: handle 404 error to avoid redirection

                // Company not found, assume default login kind
                console.error(error);
                $location.search('c', null);
                localStorage.removeItem('lastUserLoginBrand');
                return $q.resolve(defaultCompanyConfiguration);
            });
        }
    };

    this.getCompanyTestflightConfiguration = function (companyId) {
        return $http({
            url: URL + '/apple-testflight',
            method: 'GET',
            params: {
                brand: companyId
            }
        });
    }

}]);
