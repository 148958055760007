(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:cloneButton
     * @description
     * # cloneButton
     */
    angular.module('dialengaExeviBackoffice').component('cloneButton', {
        bindings: {
            buttonId: '<?',
            action: '&',
            isValid: '<',
            iconClass: '<?',
            buttonTooltip: '<?'
        },
        controller: function () {
            var vm = this;
        },
        controllerAs: 'vm',
        template: '<div kendo-tooltip k-content="vm.buttonTooltip || \'publication.cloneContent\' | translate" class="inline-block">' +
                '<feedback-action-button [button-id]="vm.buttonId" [icon-class]="vm.iconClass" [classes]="\'button button--small button--only-icon\'"' +
                    '(action)="vm.action()" [is-valid]="vm.isValid"></feedback-action-button>' +
            '</div>'
    });
})();