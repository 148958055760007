'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:StatisticsController
 * @description
 * # StatisticsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('StatisticsController',
    ['$scope', '$translate', 'StatisticsService',
        function ($scope, $translate, StatisticsService) {
            var init = function () {
                getGlobalStatistics();
            };

            function getGlobalStatistics(startDate, endDate) {
                StatisticsService.globalStatistics(startDate, endDate).then(function (response) {
                    $scope.statistics = response.data;

                    var total;
                    if ($scope.statistics.publicationStatistics) {
                        total = parseInt($scope.statistics.publicationStatistics.sent);
                        $scope.sentPercentage = StatisticsService.getPercentage($scope.statistics.publicationStatistics.sent, total);
                        $scope.receivedPercentage = StatisticsService.getPercentage($scope.statistics.publicationStatistics.received, total);
                        $scope.openedPercentage = StatisticsService.getPercentage($scope.statistics.publicationStatistics.read, total);
                        $scope.openedPercentageOverReceived = StatisticsService.getPercentage(
                            $scope.statistics.publicationStatistics.read, $scope.statistics.publicationStatistics.received);
                        $scope.interactedPercentage = StatisticsService.getPercentage($scope.statistics.publicationStatistics.interacted, total);
                        $scope.interactedPercentageOverOpened = StatisticsService.getPercentage(
                            $scope.statistics.publicationStatistics.interacted, $scope.statistics.publicationStatistics.read);
                    }

                    if ($scope.statistics.userStatistics) {
                        total = parseInt($scope.statistics.userStatistics.totalUsers);
                        $scope.anonymousUsersPercentage = StatisticsService.getPercentage($scope.statistics.userStatistics.anonymousUsers, total);
                        $scope.registeredUsersPercentage = StatisticsService.getPercentage($scope.statistics.userStatistics.registeredUsers, total);
                        $scope.totalUsersPercentage = StatisticsService.getPercentage($scope.statistics.userStatistics.totalUsers, total);

                        var datasetUsers = [{
                            "category": $translate.instant('statistics.global.registered'),
                            "value": $scope.statistics.userStatistics.registeredUsers,
                            "color": "#5daf4a"
                        }, {
                            "category": $translate.instant('statistics.global.anonymous'),
                            "value": $scope.statistics.userStatistics.anonymousUsers,
                            "color": "#b9353a"
                        }];

                        $scope.donutChartOptions = StatisticsService.doughnutsChart(datasetUsers);
                        $scope.areaChartOptions = StatisticsService.lineChart($translate.instant('statistics.global.usersincrease'), $scope.statistics.userRegistrationEvolution);

                        $(window).resize(function () {
                            if ($("#usersEvolutionChart").data("kendo-chart")) {
                                $("#usersEvolutionChart").data("kendo-chart").refresh();
                            }
                        });
                    }

                });

            }

            $scope.updateStatistics = function(event) {
                getGlobalStatistics(event.startDate, event.endDate);
            };

            init();
            
        }]);
