(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:setFocus
     * @description
     * # setFocus
     */
    angular.module('dialengaExeviBackoffice').directive('setFocus', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                var delay = 50;

                scope.$on('dialenga:setFocus:' + attrs.setFocus, function (e) {
                    $timeout(function () {
                        element[0].focus();
                    }, delay);
                });

            }
        };
    }]);

})();