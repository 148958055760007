'use strict';

angular.module('dialengaExeviBackoffice').service('DateUtilsService', ['$translate', function ($translate) {

    this.getDateTimeFromTimestamp = function (timestamp) {
        if (!timestamp) {
            return null;
        }
        return moment(timestamp).format('DD/MM/YYYY HH:mm');
    };

    this.getDateFromTimestamp = function (timestamp) {
        return moment(timestamp).format('DD/MM/YYYY');
    };

    this.getTimeFromTimestamp = function (timestamp) {
        return moment(timestamp).format('HH:mm');
    };

    this.getTimestampFromDateAndTime = function (date, time) {
        if (!date || !time) {
            return null;
        }
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var publicationDate = date;
        publicationDate.setHours(hours);
        publicationDate.setMinutes(minutes);
        publicationDate.setSeconds(0);
        publicationDate.setMilliseconds(0);
        return publicationDate.getTime();
    };

    this.getDateFromString = function (dateString) {
        // Assuming date format 'dd/MM/yyyy'
        var date = dateString.split('/');
        return new Date(date[2], date[1] - 1, date[0]);
    };

    this.getElapsedTimeFromNowToDate = function (date) {
        var fromNow = moment(date).fromNow();
        return fromNow.charAt(0).toUpperCase() + fromNow.slice(1);
    };

    var getOffsetBetweenZones = function (fromTimeZone, toTimeZone) {
        var fromTimeZoneOffset = moment.tz(fromTimeZone).utcOffset();
        var toTimeZoneOffset = moment.tz(toTimeZone).utcOffset();
        return toTimeZoneOffset - fromTimeZoneOffset;
    };

    var addPositiveSign = function (value) {
        return (value >= 0 ? '+' : '');
    };

    var addZeros = function (value) {
        var absValue = Math.abs(value);
        if (absValue < 10) {
            absValue = '0' + absValue;
        }
        if (value < 0) {
            absValue = '-' + absValue;
        }
        return absValue;
    };

    var formatOffset = function (offset) {
        return addPositiveSign(offset) + addZeros(offset / 60) + ':' + addZeros(offset % 60);
    };

    this.getRegionsOffset = function (fromTimeZone, toTimeZone) {
        var offset = getOffsetBetweenZones(fromTimeZone, toTimeZone);
        return !!offset ? formatOffset(offset) : false;
    };

    var getOffsetBetweenZonesForDate = function (date, fromTimeZone, toTimeZone) {
        var fromTimeZoneOffset = moment.tz(date, fromTimeZone).utcOffset();
        var toTimeZoneOffset = moment.tz(date, toTimeZone).utcOffset();
        return toTimeZoneOffset - fromTimeZoneOffset;
    };

    this.getCompanyLocalDateTime = function (date, fromTimeZone, toTimeZone) {
        if (!date) {
            return null;
        }
        var offset = getOffsetBetweenZonesForDate(date, fromTimeZone, toTimeZone);
        return new Date(date.getTime() + offset * 60000);
    };

    this.areDatesEquals = function (firstDate, secondDate) {
        if (!firstDate || !secondDate) {
            return false;
        }
        return firstDate.getFullYear() === secondDate.getFullYear() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getDate() === secondDate.getDate();
    };

    this.isValidDate = function (date) {
        return date instanceof Date && !isNaN(date);
    };

    function formatDateAsString(date) {
        var currentYear = new Date().getFullYear();
        var format = 'D MMM';
        if (parseInt(moment(date).format('YYYY')) !== currentYear) {
            format += ' YYYY';
        }
        return moment(date).format(format);
    }

    this.getFormattedPeriod = function (startDate, endDate) {
        if (!startDate || !endDate) {
            return null;
        }
        var isRange = moment(startDate).format('DD-MM-YYYY') !== moment(endDate).format('DD-MM-YYYY');
        if (isRange) {
            var startDateString = formatDateAsString(startDate);
            var endDateString = formatDateAsString(endDate);
            return $translate.instant('timeoffRequests.datesRequested', { startDate: startDateString, endDate: endDateString });
        } else {
            return formatDateAsString(startDate);
        }
    };

}]);
