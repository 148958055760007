'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationsSettingsController
 * @description
 * # ConversationsSettingsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationsSettingsController', 
    ['$route', '$scope', '$rootScope', '$translate', 'ModalService', 'NotificationService', 'CONVERSATIONS_SETTINGS_TABS', 'selectedTab',
    function($route, $scope, $rootScope, $translate, ModalService, NotificationService, CONVERSATIONS_SETTINGS_TABS, selectedTab) {
        var vm = this;

        vm.CONVERSATIONS_SETTINGS_TABS = CONVERSATIONS_SETTINGS_TABS;
        vm.selectedTab = selectedTab;
        vm.selectedTray = null;
        vm.previewState = { opened: false };

        vm.loadTabContent = function(tab) {
            if (vm.selectedTab !== tab) {
                vm.selectedTab = tab;
                if (tab === CONVERSATIONS_SETTINGS_TABS.MOTIVES) {
                    $route.updateParams({ tabName: 'motives' });
                } else if (tab === CONVERSATIONS_SETTINGS_TABS.FEEDBACK_TRAYS) {
                    $route.updateParams({ tabName: 'feedback-trays' });
                }
            }
        };

        vm.createMotive = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/conversations-settings-motives/create-motive-modal.html',
                controller: 'CreateMotiveModalController',
                accept: function(motive) {
                    if (motive) {
                        $scope.$broadcast('dialenga:reloadMotivesList');
                        var message = $translate.instant('motives.modal.create.notification', { name: motive.motive });
                        NotificationService.notifySuccess(message);
                    }
                }
            });
        };

        vm.cloneSelectedTray = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/feedback-tray/feedback-tray-clone-modal.html',
                controller: 'FeedbackTrayCloneModalController',
                size: 'md',
                resolveParams: {
                    name: vm.selectedTray.name,
                    language: vm.selectedTray.language,
                    alias: vm.selectedTray.alias,
                    id: vm.selectedTray.id,
                    hasQuestionnaire: !!vm.selectedTray.questionnaire && !!vm.selectedTray.questionnaire.id
                },
                accept: function() {
                    $scope.$broadcast('dialenga:reloadTrayList');
                    $rootScope.$broadcast('dialenga:loadingEnd');
                    var message = $translate.instant('feedbackTray.notification.clone.message');
                    NotificationService.notifySuccess(message);
                },
                cancel: function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        }
    }
]);