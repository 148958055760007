'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.SessionStorageService
 * @description
 * # SessionStorageService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('SessionStorageService', function () {
    
    this.isSupported = function () {
        return 'sessionStorage' in window && window['sessionStorage'];
    };

    this.setItem = function (key, value) {
        if (this.isSupported()) {
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            console.error('Session storage not supported.');
        }
    };

    this.getItem = function (key) {
        if (this.isSupported()) {
            return JSON.parse(sessionStorage.getItem(key));
        } else {
            console.error('Session storage not supported.');
        }
    };

    this.removeItem = function (key) {
        if (this.isSupported()) {
            sessionStorage.removeItem(key);
        } else {
            console.error('Session storage not supported.');
        }
    };

    this.clear = function () {
        if (this.isSupported()) {
            sessionStorage.clear();
        } else {
            console.error('Session storage not supported.');
        }
    };

});
