'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsController
 * @description
 * # TimeoffSettingsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsController', ['$rootScope','$scope', '$route', '$location', 'ModalService', 'TIMEOFF_SETTINGS_TABS', 'selectedTab',
    function($rootScope, $scope, $route, $location, ModalService, TIMEOFF_SETTINGS_TABS, selectedTab) {
        var vm = this;

        vm.TIMEOFF_SETTINGS_TABS = TIMEOFF_SETTINGS_TABS;
        vm.selectedTab = selectedTab;

        vm.loadTabContent = function(tab) {
            if (vm.selectedTab !== tab) {
                $scope.$broadcast('dialenga:timeOffSettingsChangedTab');
                vm.selectedTab = tab;
                if (tab === TIMEOFF_SETTINGS_TABS.SCHEMES) {
                    $route.updateParams({ tabName: 'schemes' });
                } else if (tab === TIMEOFF_SETTINGS_TABS.GLOBAL) {
                    $route.updateParams({ tabName: 'global' });
                }
            }
        };

        vm.createSchema = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/create-schema-modal/create-schema-modal.html',
                controller: 'CreateSchemaModalController',
                resolveParams: {
                    title: 'schemes.newSchema',
                    acceptButtonText: 'model.schemes.createSchema',
                },
                accept: function(latestSchema) {
                    if (latestSchema) {
                        $location.path('settings/timeoff/scheme/general/update/' + latestSchema.timeOffSchema.id);
                    }
                }
            });
        };

        vm.cloneSelectedContent = function() {

        };
    }
]);