'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.TimeoffSettingsTeamsService
 * @description
 * # TimeoffSettingsTeamsService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('TimeoffSettingsTeamsService',
    ['$http', 'SharedDataService', 'PAGE_SIZE',
    function($http, SharedDataService, PAGE_SIZE) {

        this.URL = SharedDataService.apiUrl + '/timeoff/teams';

        this.findAll = function(options) {
            if (options) {
                options.page = options.page || 0;
                options.size = options.size || PAGE_SIZE;
                options.sort = options.sort || undefined;
            }
            return $http({
                url: this.URL,
                method: 'GET',
                params: options
            });
        };

        this.findOne = function(id) {
            return $http({
                url: this.URL + '/' + id,
                method: 'GET'
            });
        };

        this.update = function(data) {
            return $http({
                url: this.URL + '/' + data.team.id,
                method: 'PUT',
                data: data.team
            });
        };

        this.getEmployeesOfATeam = function(teamId, options) {
            if (options) {
                options.page = options.page || 0;
                options.size = options.size || PAGE_SIZE;
                options.sort = options.sort || undefined;
            }
            return $http({
                url: this.URL + '/' + teamId + '/employees',
                method: 'GET',
                params: options
            });
        };

        this.updateEmployeesInTeam = function(teamId, employees) {
            return $http({
                url: this.URL + '/' + teamId + '/employees',
                method: 'POST',
                data: employees
            });
        };

        this.deleteEmployeeFromTeam = function(teamId, employeeId) {
            return $http({
                url: this.URL + '/' + teamId + '/employees/' + employeeId,
                method: 'DELETE'
            });
        };
    }
]);