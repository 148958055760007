'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:shortDateFilter
 * @function
 * @description
 * # shortDateFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('shortDateFilter', [function () {
    return function (date) {
        var timeoffDate = moment(date);
        var currentDate = moment();
        if (timeoffDate.year() === currentDate.year()) {
            return timeoffDate.format('DD/MM');
        } else {
            return timeoffDate.format('DD/MM/YYYY');
        }
    };
}]);
