'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CreateQuestionnaireModalController
 * @description
 * # CreateQuestionnaireModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CreateQuestionnaireModalController',
    ['$scope', '$location', 'params', '$rootScope', '$uibModalInstance', 'Session', 'CategoryService', 'QuestionnaireService', 'CONTENT_KIND', 'PUSH_OPTION',
    function ($scope, $location, params, $rootScope, $uibModalInstance, Session, CategoryService, QuestionnaireService, CONTENT_KIND, PUSH_OPTION) {

    //****************************************** Attributes *************************************\\
    $scope.changed = false;
    $scope.valid = false;
    $scope.questionnaireForm = {
        questionnaire: {}
    };
    $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
    $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
    $scope.pushOptions = Session.editorPermissions ? Session.editorPermissions.pushOptions : PUSH_OPTION.CUSTOMIZABLE;
    $scope.PUSH_OPTION = PUSH_OPTION;

    $scope.CONTENT_KIND = CONTENT_KIND;

    //******************************************* Methods ***************************************\\
    $scope.accept = function () {
        var promise;
        if ($scope.questionnaireForm.questionnaire.id) {
            promise = QuestionnaireService.update($scope.questionnaireForm);
        } else {
            promise = QuestionnaireService.create($scope.questionnaireForm);
        }
        promise.then(function (response) {
            $uibModalInstance.close(response.data);
            $location.path('/questionnaires/edit/' + response.data.id);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        var valid = true;
        if (!$scope.questionnaireForm.questionnaire.name
            || !$scope.questionnaireForm.questionnaire.category || !$scope.questionnaireForm.questionnaire.category.id) {
            valid = false;
        }
        $scope.valid = valid;
    };

    $scope.onChange = function () {
        $scope.changed = true;
    };

    $scope.init = function () {
        $rootScope.$broadcast('dialenga:loadingEnd');
        $scope.categoriesOptions = CategoryService.getCategoriesIconValueDropDownListOptions();
        $scope.title = params.id ? 'questionnaires.modal.edit.title' : 'questionnaires.modal.create.title';
        var questionnaireId = params.id;
        if (questionnaireId) {
            QuestionnaireService.findOne(questionnaireId).then(function (response) {
                $scope.questionnaireForm.questionnaire = response.data;
                $scope.valid = true;
            });
        } else {
            $scope.questionnaireForm.questionnaire.pushEnabled = $scope.pushOptions !== PUSH_OPTION.ALWAYS_OFF ? true : false;
            $scope.questionnaireForm.questionnaire.kind = CONTENT_KIND.SURVEY;
        }
    };

    $scope.init();

    $scope.$watch('changed', function (newValue) {
        if (newValue) {
            validateModel();
            $scope.changed = false;
        }
    });
}]);
