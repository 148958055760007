'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessageController
 * @description
 * # MessageController
 * Controller of the dialengaExeviBackoffice
 */
 angular.module('dialengaExeviBackoffice').controller('MessageController',
    ['$route', '$rootScope', '$scope', '$routeParams', '$location', '$translate', 'LanguageService', 'PublicationService', 'ModalService', 'MessageService', 'Session', 'selectedTab', 
        'DataService', 'NotificationService', 'contentType', 'CONTENT_KIND', 'CONTENT_TYPE', 'MESSAGE_STATES', 'MESSAGE_TABS',
    function ($route, $rootScope, $scope, $routeParams, $location, $translate, LanguageService, PublicationService, ModalService, MessageService, Session, selectedTab, 
        DataService, NotificationService, contentType, CONTENT_KIND, CONTENT_TYPE, MESSAGE_STATES, MESSAGE_TABS) {

    //******************************************* Attributes ************************************\\
    
    $scope.selectedTab = selectedTab;    
    $scope.DataService = DataService;
    $scope.messageId = $routeParams.id;
    $scope.contentType = contentType;
    
    $scope.messageForm = { };
    $scope.language = {};
    $scope.messageForm.message = { contents: {}, gallery: {images: [] }};
    $scope.messageForm.valid = false;
    $scope.messageForm.isFormDirty = false;
    $scope.messageForm.isPublished = false;

    $scope.messageStates = MESSAGE_STATES;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.CONTENT_TYPE = CONTENT_TYPE;
    $scope.MESSAGE_TABS = MESSAGE_TABS;
    $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
    $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
    $scope.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;
    $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;


    $scope.isImageVisible = true;
    $scope.isOptionalImageVisible = false;
    $scope.isPill = false;

    $scope.previewState = { opened: false };

    //******************************************** Methods **************************************\\

    function setChangeTab(tab) {
        $scope.selectedTab = tab;

        if (tab === MESSAGE_TABS.CONTENT) {
            $route.updateParams({ tabName: 'content' });
        } else if (tab === MESSAGE_TABS.RECIPIENTS) {
            $route.updateParams({ tabName: 'recipients' });
        } else if (tab === MESSAGE_TABS.SHIPMENT) {
            $route.updateParams({ tabName: 'preview' });
        }
    }

    $scope.loadTabContent = function(tab) {
        if ($scope.selectedTab !== tab) {
            if ($scope.messageForm.valid && $scope.messageForm.isFormDirty) {
                ModalService.openConfirmationModal({
                    title: 'global.modal.changes.title',
                    message: 'global.modal.changes.messageSaveAndContinue',
                    acceptButtonText: 'global.modal.saveAndContinue',
                    cancelButtonText: 'global.modal.changes.continueAndDiscard',
                    closeModalOnlyFromTopButton: true,
                    accept: function () {
                        $scope.$broadcast('dialenga:savePublicationFormAndStay', {tab: $scope.selectedTab, redirect: false});
                        setChangeTab(tab);
                    },
                    cancel: function(eventName) {
                        if (eventName !== 'closeButton') {
                            setChangeTab(tab);
                        }
                    }
                });
            } else {
                setChangeTab(tab);
            }
        }
    };

    $scope.archiveOrDelete = function (id) {
        PublicationService.archiveOrDelete(id, $scope.messageForm.isPublished, contentType, DataService);
    };

    $scope.publicationFormSaveAndStay = function () {
        $scope.$broadcast('dialenga:savePublicationFormAndStay', {tab: $scope.selectedTab});
    };

    $scope.redirectToStatistics = function (id) {
        var backPath = $scope.isPill ? '/knowledge-center/statistics/' + id : '/messages/statistics/' + id;
        $rootScope.goBackAndCheckForModifications($scope.messageForm.isFormDirty, backPath);
    }

    $scope.relocateMessage = function () {

        ModalService.openCategoriesModal({
            id: $scope.messageForm.message.category ? $scope.messageForm.message.category.id : null,
            accept: function (newHierarchy) {
                // Call endpoint to relocate publication and return to messages list
                MessageService.relocate($scope.messageId, newHierarchy.at(-1))
                .then(function() {
                    var message = $translate.instant('publication.modal.relocate.message');
                    NotificationService.notifySuccess(message);
                    $location.path('/messages');
                })
                .catch(actionErrorHandler);
                $rootScope.$broadcast('dialenga:loadingEnd');
            },
            cancel: function () {
                $rootScope.$broadcast('dialenga:loadingEnd');
            }
        });
    };

    var init = function () {
        $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $scope.isPill = $location.path().search('/knowledge-center') > -1;

        if ($scope.messageId) {
            DataService.findOne($scope.messageId).then(function (response) {
                $scope.messageForm.message = response.data;

                if ($scope.messageForm.message.users) {
                    $scope.messageForm.message.users = DataService.orderRecipientsUsers($scope.messageForm.message.users);
                }
                $scope.messageForm.isPublished = parseInt(response.data.state) === parseInt(MESSAGE_STATES.PUBLISHED);
                $scope.messageForm.type = !!$scope.messageForm.message.gallery ? CONTENT_TYPE.GALLERY : CONTENT_TYPE.IMAGE;
                $scope.isImageVisible = !(response.data.contents[$scope.language.selectedLanguage] && response.data.contents[$scope.language.selectedLanguage].youtubeURL);
                $scope.isOptionalImageVisible = !$scope.isImageVisible && response.data.contents[$scope.language.selectedLanguage] &&
                    (response.data.contents[$scope.language.selectedLanguage].imageURL || response.data.contents[$scope.language.selectedLanguage].imageOrder > -1);
                DataService.isOptionalImageVisible = angular.copy($scope.isOptionalImageVisible);
                DataService.latestMessage = angular.copy($scope.messageForm.message);
            });
        }
    };
    
    init();
}]);
