(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:infoTooltip
     * @description
     * # infoTooltip
     */
    angular.module('dialengaExeviBackoffice').component('infoTooltip', {
        bindings: {
            tooltipText: '<',
            iconClass: '<?',
            position: '<?'
        },
        controller: function () {
            var vm = this;

            vm.iconClass = vm.iconClass || 'icon-info';
            vm.position = vm.position || 'top';
        },
        controllerAs: 'vm',
        template: '<span class="icon {{vm.iconClass}}" kendo-tooltip k-content="\'{{vm.tooltipText}}\' | translate" ' +
            'k-position="\'{{vm.position}}\'"></span>'
    });
})();