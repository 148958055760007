'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:WKAuthSuccessController
 * @description
 * # WKAuthSuccessController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('WKAuthSuccessController', ['authCode', '$timeout', 'WKAuthenticationService',
    function (authCode, $timeout, WKAuthenticationService) {
        var vm = this;

        vm.isSyncActive = false;
        vm.availableCompanies = null;
        vm.selectedCompanies = [];

        vm.toggleCompany = function (selectedCompany) {
            var selectedCompanyIndex = vm.selectedCompanies.findIndex(function (company) {
                return company.companyCode === selectedCompany.companyCode;
            });
            if (selectedCompany.enabled && selectedCompanyIndex === -1) {
                vm.selectedCompanies.push(selectedCompany);
            } else if (!selectedCompany.enabled && selectedCompanyIndex !== -1) {
                vm.selectedCompanies.splice(selectedCompanyIndex, 1);
            }
        };

        function parseCompaniesBeforeSubmit() {
            vm.selectedCompanies.forEach(function (company) {
                delete company.enabled;
            });
        }

        vm.syncSelectedCompanies = function () {
            if (vm.selectedCompanies.length === 0) {
                return;
            }
            parseCompaniesBeforeSubmit();
            vm.isSyncActive = true;
            WKAuthenticationService.synchronizeWKA3EquipoUsersInCompanies(vm.selectedCompanies).then(function () {
                vm.isSyncSuccessful = true;
            }, function () {
                vm.isSyncSuccessful = false;
            });
        };

        function getCompanies() {
            vm.areCompaniesLoading = true;

            var startTime = Date.now();
            WKAuthenticationService.sendCodeToGetWKA3EquipoCompanies(authCode).then(function (response) {
                var elapsedTime = Date.now() - startTime;
                var timeoutTime = elapsedTime < 1500 ? (1500 - elapsedTime) : 0;
                $timeout(function () {
                    vm.availableCompanies = response.data;
                    vm.isAuthSuccessful = true;
                    vm.areCompaniesLoading = false;
                }, timeoutTime);
            }, function () {
                vm.isAuthSuccessful = false;
                vm.areCompaniesLoading = false;
            });
        }

        function init() {
            if (!authCode) {
                vm.isAuthSuccessful = false;
            } else {
                getCompanies();
            }
        }

        init();

    }
]);