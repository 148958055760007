'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:optionalLabel
 * @description
 * # optionalLabel
 */
angular.module('dialengaExeviBackoffice').directive('optionalLabel', function () {
    return {
        restrict: 'A',
        compile: function (element) {
            element.after('<span>{{"global.form.optional" | translate}}</span>');
        }
    };
});