'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:documentStateIconFilter
 * @function
 * @description
 * # documentStateIconFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('documentStateIconFilter',
    ['DOCUMENT_TRANSACTION_STATE',
    function (DOCUMENT_TRANSACTION_STATE) {
    return function (state) {
        var icon = '';
        var iconWarningCircleClass = 'icon icon-warning-circle-fill icon-state-';
        switch(parseInt(state)){
            case DOCUMENT_TRANSACTION_STATE.SUCCESSFUL:
                break;
            case DOCUMENT_TRANSACTION_STATE.SOME_ERRORS:
                icon = iconWarningCircleClass + ' yellow';
                break;
            case DOCUMENT_TRANSACTION_STATE.FAILURE:
                icon = iconWarningCircleClass + ' red';
                break;
            default:
                break;
        }
        return icon;
    };
}]);
