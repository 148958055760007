'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.localizedErrorsService
 * @description
 * # localizedErrorsService
 */
angular.module('dialengaExeviBackoffice').service('LocalizedErrorsService',
    ['$rootScope', '$translate', 'NotificationService',
    function ($rootScope, $translate, NotificationService) {

    var unknownErrorMessage = 'global.messages.error.unknown';

    this.showLocalizedError = function (errorStatus, errorData, defaultMessage) {
        if (errorData && errorData.localizedError) {
            $rootScope.$broadcast("event:error-unprocessableEntity", { localizedError: errorData.localizedError });
        } else if (errorStatus !== 404) { // 404 error is handled by redirecting to not found page
            var message = defaultMessage || unknownErrorMessage;
            $rootScope.$broadcast("event:error-unprocessableEntity", { status: errorStatus, message: message });
        }
    };

    this.showLocalizedErrorNotification = function (errorData, defaultMessage) {
        var message;
        if (typeof errorData === "string") {
            errorData = JSON.parse(errorData);
        }
        if (errorData && errorData.localizedError) {
            message = errorData.localizedError;
        } else {
            message = defaultMessage || unknownErrorMessage;
            message = $translate.instant(message);
        }
        NotificationService.notifyError(message);
    };

}]);
