'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.UsersGroupsService
 * @description
 * # UsersGroupsService
 */
angular.module('dialengaExeviBackoffice').service('UsersGroupsService',
    ['POPULATION', 'SEGMENTATION_KIND',
    function (POPULATION, SEGMENTATION_KIND) {

        function isSegmentationKindUnionAndNoUsers(segmentationKind, recipientsCount) {
            return parseInt(segmentationKind) === SEGMENTATION_KIND.UNION && recipientsCount === 0;
        }
    
        function isSegmentationKindIntersectionAndNoUsers(segmentationKind, recipientsCount) {
            return parseInt(segmentationKind) === SEGMENTATION_KIND.INTERSECTION && recipientsCount === 0;
        }

        this.areNoRecipients = function (segmentationKind, unionCount, intersectionCount) {
            return isSegmentationKindUnionAndNoUsers(segmentationKind, unionCount) || isSegmentationKindIntersectionAndNoUsers(segmentationKind, intersectionCount);
        };

        this.isAddAllSegmentsLinkEnabled = function (originSegments, addedSegments) {
            addedSegments = addedSegments.map(function (segment) { return segment.id; } );
            
            if (!originSegments) {
                return false;
            }
            var nonAddedSegments = originSegments.filter(function(segment) {
                return addedSegments.indexOf(segment.id) === -1;
            });
            return nonAddedSegments.length === 0;
        };

        this.getInitialRecipientsAllKind = function () {
            return {
                population: POPULATION.ALL,
                segmentationKind: SEGMENTATION_KIND.UNION,
                segments: [],
                users: []
            };
        };

        this.getInitialRecipientsSegmentsKind = function (addedSegments, segmentationKind) {
            var segments = addedSegments.map(function (segment) { return segment.id; } );
            return {
                population: POPULATION.SEGMENTS,
                segmentationKind: segmentationKind,
                segments: segments,
                users: []
            };
        };

        this.getInitialRecipientsSelectedUsersKind = function(users) {
            var selectedUsers = users.map(function (user) { return user.id; } );
            return {
                population: POPULATION.SELECTED_USERS,
                segmentationKind: null,
                segments: [],
                users: selectedUsers
            };
        };

        this.getRecipients = function (data) {
            return {
                population: data.population || POPULATION.ALL,
                segmentationKind: data.segmentation || SEGMENTATION_KIND.UNION,
                segments: data.segments,
                users: data.users || []
            };
        };

        this.getIndexOfItemInSegments = function (segments, segmentId) {
            var segmentsLength = segments.length;
            for (var index = 0; index < segmentsLength; index++) {
                if (segments[index].id === segmentId) {
                    return index;
                }
            }
            return -1;
        };

        function toggleSegmentInSelectedSegments(selectedSegments, segment, index) {
            if (index === -1) {
                selectedSegments.push(segment);
            } else {
                selectedSegments.splice(index, 1);
            }
            return selectedSegments;
        }

        this.addAvailableSegmentToSelected = function (selectedSegments, segment, event) {
            if (!event.currentTarget.classList.contains('added')) {
                event.currentTarget.classList.toggle('selected');
    
                var segmentIndex = this.getIndexOfItemInSegments(selectedSegments, segment.id);
                selectedSegments = toggleSegmentInSelectedSegments(selectedSegments, segment, segmentIndex);
            }
            return selectedSegments;
        };

    }]);
    