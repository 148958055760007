(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:dialengaSelect
     * @description
     * # dialengaSelect
     */
    angular.module('dialengaExeviBackoffice').component('dialengaSelect', {
        bindings: {
            isDisabled: '<?',
            isReadOnly: '<?',
            dialengaId: '@?',
            classes: '@?',
            kOptions: '<',
            selectModel: '=',
            changeFunction: '&'
        },
        controller: function () {
            var vm = this;
            if (typeof vm.isDisabled === 'undefined' || vm.isDisabled === null) {
                vm.isDisabled = false;
            }
            if (typeof vm.isReadOnly === 'undefined' || vm.isReadOnly === null) {
                vm.isReadOnly = false;
            }
        },
        controllerAs: 'vm',
        template: '<div><select id="{{vm.dialengaId}}" kendo-drop-down-list k-ng-model="vm.selectModel" k-options="vm.kOptions" k-on-change="vm.changeFunction()"' +
                    ' class="{{vm.classes}}" k-ng-disabled="vm.isDisabled" k-ng-readonly="vm.isReadOnly"></select></div>'
    });
})();
