(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:ratingIconText
     * @description
     * # ratingIconText
     */
    angular.module('dialengaExeviBackoffice').directive('ratingIconText', ['$rootScope', 'RATING_KIND',
        function ($rootScope, RATING_KIND) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                value: '=',
                numRatings: '='
            },
            template: '<div class="rating-value" data-ng-class="{\'stars-container\' : ratingKind === kinds.STAR}">' +
                '<rating-icon value="value"></rating-icon>' +
                '<span data-ng-bind="ratingValue"></span>' +
                '<span data-translate="statistics.messages.numRatings" ' +
                    'data-translate-values="{numRatings: numRatings}" data-translate-interpolation="messageformat"></span>' +
            '</div>',
            controller: ['$scope', function ($scope) {
                $scope.kinds = RATING_KIND;
                $scope.ratingKind = $rootScope.account.ratingKind;

                function formatValue(value) {
                    var formattedValue = parseFloat(value).toFixed(2);
                    formattedValue = parseFloat(formattedValue); // Remove leading zeros if any
                    return formattedValue;
                }

                $scope.ratingValue = formatValue($scope.value);
            }]
        };
    }]);

})();
