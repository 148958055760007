'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessageContentController
 * @description
 * # MessageContentController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('MessageContentController',
    ['$q', '$scope', '$rootScope', '$routeParams', '$location', '$timeout', '$translate', '$window', 'CategoryService', 'GlobalService', 'MessageService', 'ModalService', 'NotificationService', 'KnowledgeCenterService', 
    'AttachedContentService', 'CategoriesTreeService', 'KendoUtilsService', 'LanguageService', 'FileUtilsService','PublicationService', 'Session', 'UrlUtilsService', 'UtilsService', 'DialogService', 'videoService', 'CONTENT_KIND', 
    'CONTENT_TYPE', 'IMAGE_CROPPER', 'IMAGE_CROPPER_GALLERY', 'MESSAGE_STATES', 'GENERAL_CONFIG_STATES', 'MESSAGE_TABS', 'POPULATION', 'SEGMENTATION_KIND', 'PUSH_OPTION',
    function ($q, $scope, $rootScope, $routeParams, $location, $timeout, $translate, $window, CategoryService, GlobalService, MessageService, ModalService, NotificationService, KnowledgeCenterService, 
        AttachedContentService, CategoriesTreeService, KendoUtilsService, LanguageService, FileUtilsService, PublicationService, Session, UrlUtilsService, UtilsService, DialogService, videoService, CONTENT_KIND, 
        CONTENT_TYPE, IMAGE_CROPPER, IMAGE_CROPPER_GALLERY, MESSAGE_STATES, GENERAL_CONFIG_STATES, MESSAGE_TABS, POPULATION, SEGMENTATION_KIND, PUSH_OPTION) {

    //******************************************* Attributes ************************************\\
    var defaultCompanyLanguage = Session.companyDefaultLanguage;
    $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
    $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
    $scope.pushOptions = Session.editorPermissions ? Session.editorPermissions.pushOptions : PUSH_OPTION.CUSTOMIZABLE;
    $scope.PUSH_OPTION = PUSH_OPTION;
    $scope.getVideoThumbnail = videoService.getThumbnail;

    var uploadGalleryOptions = {
        multiple: false,
        select: function(e) {
            var allowedExtensions = ["image/jpg","image/jpeg","image/png","image/gif"]

            angular.forEach(e.files, function(value, index) {
                selectImage(value.rawFile).then(function (response) {
                    if (allowedExtensions.indexOf(response.image.type) > -1 ) {
                        $scope.messageForm.message.gallery.images.push(response);
                    }
                    if (e.files.length - 1 == index) {
                        parseImageOrder();
                        $scope.setSelectedImage(0);
                        $scope.onChange();
                    }
                });
            });
        },
        showFileList: false,
    };
    
    $scope.picGallerySelected = 0;
    $scope.showCaptionField = false;

    $scope.isValidURL = false;
    $scope.isValidURLYoutube = false;
    $scope.changed = false;
    $scope.isSyncing = false;
    
    $scope.isImageLoading = true;
    $scope.hideWhenEditImage = false;
    // $scope.messageForm = {
    //     enabled: false,
    //     message: { contents: {}, gallery: {images: [] }},
    //     images: [],
    //     resources: [],
    //     type: CONTENT_TYPE.IMAGE
    // };
    // Initialize object messageForm in this way for correct binding with parent controller
    $scope.messageForm.valid = false;
    $scope.messageForm.message = { contents: {}, gallery: {images: [] }, isAttachedResourceEnabled : false, linkedPublications: []};
    $scope.messageForm.enabled = false;
    $scope.messageForm.images = [];
    $scope.messageForm.resources = [];
    $scope.messageForm.type = CONTENT_TYPE.IMAGE;

    $scope.isImageVisible = true;
    $scope.isImageBankSelected = false;
    $scope.isOptionalImageVisible = false;
    $scope.currentStep = 1;
    $scope.messageId = $routeParams.id;
    $scope.isPill = false;
    $scope.IMAGE_CROPPER = IMAGE_CROPPER;
    $scope.messageStates = MESSAGE_STATES;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.CONTENT_TYPE = CONTENT_TYPE;
    $scope.GENERAL_CONFIG_STATES = GENERAL_CONFIG_STATES;
    $scope.MESSAGE_TABS = MESSAGE_TABS;
    $scope.commentsConfig = Session.commentsConfig;
    $scope.shareConfig = Session.shareConfig;

    $scope.textareaOptions = KendoUtilsService.getTextareaOptions(applyEditorChangesToTextModel);

    $scope.showUrlLink = UrlUtilsService.showUrlLink;
    $scope.attachedResourceEnabled = false;

    $scope.previewState = { opened: false };
    $scope.editResourceName = false;

    var DataService;
    var removeContentLanguagesChangedEventListener;
    $scope.readabilityIndex = null;

    //******************************************** Methods **************************************\\

    this.$onDestroy = function() {
        if (DataService.latestMessage) {
            delete DataService.latestMessage;
        }
        removeContentLanguagesChangedEventListener();
    }

    function initContentLanguagesChangedEvent() {
        if (!removeContentLanguagesChangedEventListener) {
            removeContentLanguagesChangedEventListener = $rootScope.$on('dialenga:contentLanguagesHasChanged', function () {
                $scope.validateModel();
                $scope.onChange();
            });
        }
    }

    function applyEditorChangesToTextModel() {
        var language = $scope.language.selectedLanguage || defaultCompanyLanguage;
        if (!$scope.messageForm.message.contents[language]) {
            $scope.messageForm.message.contents[language] = {};
        }
        $timeout(function() {
            $scope.messageForm.message.contents[language].text = $('#kendoTextEditor').data('kendoEditor').value();
            $scope.readingTime = GlobalService.getEstimatedReadingTime($scope.messageForm.message.contents[language].text);
            $scope.getReadabilityIndex();            
            $scope.onChange();
        });
    }

    function setCursorPosition(element, pos) {
        var range, selection;
        range = document.createRange();
        selection = window.getSelection();

        if (pos === 'start') {
            range.setStart(element.childNodes[0], 1);
            range.collapse(true);
        } else if (pos === 'end') {
            range.selectNodeContents(element);
            range.collapse(false);
        }
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }
    
    $scope.onEditResourceNameKeyDown = function (event, resource){
        var keyCode = event.which || event.keyCode;
        if (keyCode === 13 || keyCode === 27) {
            if (keyCode === 27) {
                event.currentTarget.innerText = resource.name;
            }
            event.currentTarget.blur();
        } 
    };

    $scope.onEditResourceName = function (index) {
        $scope.editResourceName = true;
        var itemResource = $('#resource-name-' + index)[0];

        $timeout(function() {
            angular.element(itemResource)
                .removeClass('resources-list__content--truncate')
                .addClass('resources-list__content--editing')
                .focus();
            angular.element(itemResource).parent().addClass('edit-mode');
            // scroll text in element to end 
            itemResource.scrollLeft = itemResource.scrollWidth;
            // move cursor position to end
            setCursorPosition(itemResource, 'end');
        });
    };

    $scope.editableResourceNameFocusOut = function(event, resource) {
        event.currentTarget.scrollLeft = 0;
        $scope.editResourceName = false;

        if (event.currentTarget.innerText.trim() !== '') {
            resource.name = event.currentTarget.innerText.trim();
            $scope.onChange();
        } else {
            event.currentTarget.innerText = resource.name;
        }
        angular.element(event.currentTarget)
            .addClass('resources-list__content--truncate')
            .removeClass('resources-list__content--editing');
        angular.element(event.currentTarget).parent().removeClass('edit-mode');
    };

    $scope.onResourceNameClick = function (resource) {
        if (!!resource.url) {
            $window.open(resource.url, '_blank');
        }
    };

    $scope.getImageForReadIndex = function() {
        return UtilsService.getReadabilityIndexImageFile($scope.readabilityIndex);
    };

    $scope.getTextForReadIndex = function() {
        return UtilsService.getReadabilityIndexText($scope.readabilityIndex);
    };

    $scope.getReadabilityIndex = function() {
        // Detect language of entered text
        // var textLanguage = UtilsService.detectLanguage($scope.messageForm.message.contents[$scope.language.selectedLanguage].text);

        // Get plain text for next operations (clean html tags and replace special characters)
        var textPlain = UtilsService.getPlainTextFromString($scope.messageForm.message.contents[$scope.language.selectedLanguage].text);

        var syllablesFromText = 0;
        var sentencesFromText = 1;
        var wordsFromText = [];
        
        // Obtain sentences number from text
        sentencesFromText = UtilsService.getSentencesNumberFromText(textPlain); 

        // Obtain words from text
        wordsFromText = textPlain.split(' ');
        // remove last position if empty: when text end with a white space
        if (wordsFromText[wordsFromText.length - 1] === '') {
            wordsFromText.pop();
        }

        // Calculate readability index if text have 3 or more words
        if (wordsFromText.length > 199) {
            // Obtain syllables number from text
            for( var index = 0; index < wordsFromText.length; index++) {
                syllablesFromText += UtilsService.syllablesCounterSpanish(wordsFromText[index]);
            }
    
            // Obtain Readability Index 
            var textData = {
                sentences: sentencesFromText,
                words: wordsFromText,
                syllables: syllablesFromText,
                indexKind: 'INFLESZ'
            }
            $scope.readabilityIndex = UtilsService.calculateReadabilityIndex(textData);

            // Update tooltip text for readability index
            $timeout(function () {
                var readIndexTooltip = $('#read-index-tooltip').data('kendoTooltip');
                if (readIndexTooltip) {
                        readIndexTooltip.options.content = $translate.instant('message.readability.tooltip', {readabilityIndex: $scope.readabilityIndex});
                        readIndexTooltip.refresh();
                }
            }, 500);
        } else {
            $scope.readabilityIndex = null;
            return;
        }
    };

    $scope.onResourceSelected = function ($files) {
        if ($files.length > 0) {
            AttachedContentService.selectMultipleResources($files, $scope.messageForm, 'message', $scope.language.selectedLanguage);
            $scope.onChange();
        }
        document.getElementById('resource-drag-drop').classList.remove('dragging');
    };

    $scope.deleteResource = function (resource, index) {
        AttachedContentService.deleteItemFromResources(resource, $scope.messageForm, 'message', $scope.language.selectedLanguage, index);
        $scope.onChange();
    };

    var submitErrorCallback = function () {
        $rootScope.$broadcast('dialenga:loadingEnd');
    };

    $scope.addInternalLink = function () {
        DialogService.openInternalLinkModalSelection().subscribe(function(response) {
            if (response?.success) {
                $scope.messageForm.message.linkedPublications.push(response.content);
                $scope.onChange();
            }
        });
    };

    $scope.removeInternalLink = function(idx) {
        if ($scope.messageForm.message.linkedPublications.length > idx) {
            $scope.messageForm.message.linkedPublications.splice(idx, 1);
            $scope.onChange();
        }
    }

    $scope.getContentIconClass = function (kind, isGallery) {
        let iconClass = '';

        switch (kind) {
            case this.CONTENT_KIND.MESSAGE:
                iconClass = isGallery ? 'icon-related-gallery' : 'icon-related-default';
                break;
            case this.CONTENT_KIND.KNOWLEDGE_PILL:
                iconClass = 'icon-related-knowledge';
                break;
            case this.CONTENT_KIND.SURVEY:
                iconClass = 'icon-related-survey';
                break;
            default:
                iconClass = 'icon-related-default';
                break;
        }
        return iconClass;
    }

    function redirectContent(redirectToNextStep) {
        DataService.latestMessage = angular.copy($scope.messageForm.message);
        $scope.messageForm.isFormDirty = dataHasChanged();

        if (!redirectToNextStep || (parseInt($scope.messageForm.message.state) === MESSAGE_STATES.PUBLISHED && !$scope.isPill)) {
            $rootScope.$broadcast('dialenga:loadingEnd');
            var message = $translate.instant('global.messages.changesUpdated.success');
            NotificationService.notifySuccess(message, 1500);
        } else {
            if (DataService.latestMessage) {
                delete DataService.latestMessage;
            }
            document.getElementById('recipients-tab').click();
        }
    }

    function clearFieldValueInAllLanguages(fieldName) {
        var languages = Object.keys($scope.messageForm.message.contents);
        for (var index = 0; index < languages.length; index++) {
            if ($scope.messageForm.message.contents[languages[index]].hasOwnProperty(fieldName)) {
                $scope.messageForm.message.contents[languages[index]][fieldName] = '';
            }
        }
    }

    $scope.publicationFormSave = function (redirectToNextStep, nextTab) {
        $scope.messageForm.isFormDirty = dataHasChanged();
        if ($scope.messageForm.isFormDirty && $scope.messageForm.valid) {

            LanguageService.removeEmptyLanguages($scope.messageForm.message);

            var ContentService = $scope.isPill ? KnowledgeCenterService : MessageService;

            var saveContentService;
            if ($scope.messageForm.message.id) {
                saveContentService = ContentService.update;
            } else {
                saveContentService = ContentService.create;
            }

            if ($scope.messageForm.type === CONTENT_TYPE.IMAGE) {
                if ($scope.isImageVisible) {
                    // Discard video if image tab is selected
                    clearFieldValueInAllLanguages('youtubeURL');
                } else if (!$scope.isOptionalImageVisible) {
                    // Discard image if video tab is selected and alt image toggle is disabled
                    $scope.messageForm.images = [];
                    clearFieldValueInAllLanguages('imageURL');
                }
                if ($scope.messageForm.message.allowImageZooming && (!$scope.isImageAttachedToAnyLanguage() || !$scope.isImageVisible)) {
                    $scope.messageForm.message.allowImageZooming = false;
                }
                $scope.messageForm.message.gallery = null;
            } else if ($scope.messageForm.type === CONTENT_TYPE.GALLERY) {
                $scope.messageForm.message.allowImageZooming = true;
            }

            if(!$scope.messageForm.message.isAttachedResourceEnabled) {
                AttachedContentService.removeAllResources($scope.messageForm, 'message');
            }

            saveContentService.call(ContentService, $scope.messageForm).then(function (response) {
                if (!$scope.messageForm.message.id) {
                    var recipients = {
                        population: POPULATION.ALL,
                        segmentationKind: SEGMENTATION_KIND.UNION,
                        segments: []
                    }
                    DataService.setRecipients(response.data.id, recipients).then(function () {
                        var editUrl = '';
                        if (redirectToNextStep) {
                            var nextTabToNavigate = nextTab == MESSAGE_TABS.SHIPMENT ? 'preview' : 'recipients';
                            editUrl = $scope.isPill ? '/knowledge-center/' + nextTabToNavigate+ '/update/' : '/messages/' + nextTabToNavigate+ '/update/';
                        } else {
                            editUrl = $scope.isPill ? '/knowledge-center/content/update/' : '/messages/content/update/';
                        }
                        var message = $translate.instant('global.messages.changesUpdated.success');
                        NotificationService.notifySuccess(message, 1500);
                        $location.path(editUrl + response.data.id);
                    });
                } else {
                    redirectContent(redirectToNextStep);
                }              
            }, submitErrorCallback);

        } else {
            redirectContent(redirectToNextStep);
        }
    };

    function updateDisabledButtonTooltip(isInvalidContent, isInvalidGallery) {
        var tooltipText = (!isInvalidContent && isInvalidGallery) ? 'message.galleryMinImagesValidation' : LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $timeout(function() {
            $('.disabled-tooltip').each(function () {
                var tooltip = $(this).data('kendoTooltip');
                if (tooltip) {
                    tooltip.options.content = $translate.instant(tooltipText);
                    tooltip.refresh();
                }
            });
        }, 100);
    }

    function updateDeleteImgButtonTooltip() {
        $timeout(function() {
            $('.disabled-tooltip-delete-img').each(function () {
                var tooltip = $(this).data('kendoTooltip');
                if (tooltip) {
                    tooltip.options.content = $translate.instant('message.galleryMinImagesValidation');
                    tooltip.refresh();
                }
            });
        }, 100);
    }

    $scope.validateModel = function () {
        var nonEmptyLanguagesValid = LanguageService.validatePublicationNonEmptyLanguages($scope.messageForm.message);
        var isInvalidContent = !$scope.messageForm.message.category || !$scope.messageForm.message.category.id || !nonEmptyLanguagesValid || $scope.messageForm.message.contents.length === 0;
        var isInvalidGallery = $scope.messageForm.type === CONTENT_TYPE.GALLERY && $scope.messageForm.message.gallery.images.length <= 1;
        
        $scope.messageForm.valid = !isInvalidContent && !isInvalidGallery;
        if (!$scope.messageForm.valid) {
            updateDisabledButtonTooltip(isInvalidContent, isInvalidGallery);
        }
    };

    $scope.onChange = function () {
        $scope.changed = true;
        $scope.messageForm.isFormDirty = dataHasChanged();
    };

    function dataHasChanged() {
        return !angular.equals($scope.messageForm.message, DataService.latestMessage) || !angular.equals($scope.isOptionalImageVisible, DataService.isOptionalImageVisible);
    }

    $scope.goBack = function () {
        var backPath = $scope.isPill ? '/knowledge-center' : '/messages';
        $scope.messageForm.isFormDirty = dataHasChanged();
        $rootScope.goBackAndCheckForModifications($scope.messageForm.isFormDirty, backPath);
    };

    var testUrlLinks = function () {
        $scope.isValidURL = PublicationService.validateUrlLinks($scope.messageForm.message, $scope.language.selectedLanguage, 'externalURL');
        $scope.isValidURLYoutube = PublicationService.validateUrlLinks($scope.messageForm.message, $scope.language.selectedLanguage, 'youtubeURL');
    };

    $scope.confirmAttachedResourcesDeactivation = function () {
        PublicationService.confirmAttachedResourcesDeactivation($scope.messageForm.message.contents, $scope.language.selectedLanguage);
    };


    $scope.iconFromMime = function(mimetype) {
        return UtilsService.getIconFromMimeType(mimetype);
    };

    $scope.toggleOptionalImageForVideo = function () {
        $scope.isOptionalImageVisible = !$scope.isOptionalImageVisible;
        if (!$scope.isOptionalImageVisible) {
            AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.messageForm.images, $scope.messageForm.message.contents, $scope.language.selectedLanguage);
        }
        $scope.onChange();
    };

    function selectCategoryPath(selectedCategoryPath) {
        if (selectedCategoryPath && selectedCategoryPath.length > 0) {
            var category = selectedCategoryPath[selectedCategoryPath.length - 1];
            $scope.messageForm.message.category = { id: category.id, name: category.name, color: category.color, hierarchy: selectedCategoryPath, contents: category.contents };
        } else {
            $scope.messageForm.message.category = null;
        }
    }

    function setCommentsTooltip() {
        if ($scope.commentsConfig === GENERAL_CONFIG_STATES.FORCE_DISABLED) {
            $scope.commentsTooltip = $translate.instant('model.message.tooltipForceDisabledComments');
        } else if ($scope.commentsConfig === GENERAL_CONFIG_STATES.FORCE_ENABLED) {
            $scope.commentsTooltip = $translate.instant('model.message.tooltipForceEnabledComments');
        } 
    }

    function setSharingTooltip() {
        if ($scope.shareConfig === GENERAL_CONFIG_STATES.FORCE_DISABLED) {
            $scope.sharingTooltip = $translate.instant('model.message.tooltipForceDisabledSharing');
        } else if ($scope.shareConfig === GENERAL_CONFIG_STATES.FORCE_ENABLED) {
            $scope.sharingTooltip = $translate.instant('model.message.tooltipForceEnabledSharing');
        } 
    }

    function initDragAndDrop() {
        // Directory upload input options
        $scope.uploadGalleryDirectoryOptions = angular.copy(uploadGalleryOptions);
        $scope.uploadGalleryDirectoryOptions.directory = true;
        $scope.uploadGalleryDirectoryOptions.directoryDrop = true;
        $scope.uploadGalleryDirectoryOptions.localization = {
            dropFilesHere: '',
            select: $translate.instant('documents.import.attachDirectory')
        };
        $scope.uploadGalleryDirectoryOptions.validation = {
            allowedExtensions: [".jpg",".jpeg",".png",".gif"]
        };
    }
    
    function selectImage (file) {
        var deferred = $q.defer();
        var reader = new FileReader();
        reader.onload = function (event) {
            var imageData = event.target.result;
            deferred.resolve({ 
                image: {
                    src: imageData,
                    name: file.name,
                    type: file.type
                }, 
                footnotes: {}
            });
        };
        reader.readAsDataURL(file);
       
        return deferred.promise;
    }
    
    $scope.openModalCategories = function () {
        ModalService.openCategoriesModal({
            id: $scope.messageForm.message.category ? $scope.messageForm.message.category.id : null,
            accept: function (selectedCategoryPath) {
                selectCategoryPath(selectedCategoryPath);
                $rootScope.$broadcast('dialenga:loadingEnd');
                $scope.validateModel();
                $scope.onChange();
            },
            cancel: function () {
                $rootScope.$broadcast('dialenga:loadingEnd');
            }
        });
    };

    function parseImageOrder() {
        for( var idx = 0; idx < $scope.messageForm.message.gallery.images.length; idx ++) {
            $scope.messageForm.message.gallery.images[idx].order = idx;
        }
    }

    function loadImagesGallery() {
        if ($scope.messageForm.message.gallery && $scope.messageForm.message.gallery.images.length > 0) {
            $scope.messageForm.message.gallery.images = $scope.messageForm.message.gallery.images.sort( function (a, b) { return a.order - b.order; } );
            checkShowCaptionField(0);
        }
    }

    function checkShowCaptionField(index) {
        $scope.showCaptionField = !!$scope.messageForm.message.gallery.images[index].footnotes && 
                                    !!$scope.messageForm.message.gallery.images[index].footnotes[$scope.language.selectedLanguage] &&
                                    !!$scope.messageForm.message.gallery.images[index].footnotes[$scope.language.selectedLanguage].note;
    }

    $scope.setSelectedImage = function (index, oldIndex) { 
        if ($scope.picGallerySelected !== index || oldIndex !== undefined) {
            $scope.isImageLoading = true;
            $scope.picGallerySelected = index;
            if(!!$scope.messageForm.message.gallery.images[index]) {
                checkShowCaptionField(index);
            }
        }
    };

    $scope.setMessageType = function (type) {
        switch ($scope.messageForm.type) {
            case CONTENT_TYPE.IMAGE:
                if($scope.messageForm.images[0] && $scope.isImageVisible) {
                    selectImage($scope.messageForm.images[0].image).then(function(response) {
                        $scope.messageForm.message.gallery.images.push(response);
                        $scope.setSelectedImage($scope.messageForm.message.gallery.images.length-1);
                        $scope.onChange();
                        $scope.messageForm.type = type;
                    });
                } else if (!!$scope.messageForm.message.contents[$scope.language.selectedLanguage].youtubeURL && !$scope.isImageVisible){
                    ModalService.openConfirmationModal({
                        title: 'global.modal.changes.title',
                        message: 'publication.modal.changeVideoToGallery.message',
                        acceptButtonText: 'global.modal.accept',
                        accept: function () {
                            delete $scope.messageForm.message.contents[$scope.language.selectedLanguage].youtubeURL;
                            $scope.isImageVisible = true;
                            $scope.onChange();
                            $scope.messageForm.type = type;
                        }
                    });
                } else {
                    $scope.onChange();
                    $scope.messageForm.type = type;
                }
                break;
            case CONTENT_TYPE.GALLERY:
                if ($scope.messageForm.message.gallery && $scope.messageForm.message.gallery.images.length > 0) {
                    ModalService.openConfirmationModal({
                        title: 'global.modal.changes.title',
                        message: 'publication.modal.changeKind.message',
                        acceptButtonText: 'global.modal.accept',
                        accept: function () {
                            $scope.messageForm.message.gallery.images = [];
                            $scope.messageForm.type = type;
                            $scope.onChange();
                        }
                    });
                } else {
                    $scope.messageForm.type = type;
                    $scope.onChange();
                }
                break;
            default:
                break;
        }
    };

    $scope.selectImageTab = function () {
        if ($scope.messageForm.message.contents[$scope.language.selectedLanguage] && $scope.messageForm.message.contents[$scope.language.selectedLanguage].youtubeURL) {
            ModalService.openConfirmationModal({
                title: 'message.videoToggleConfirmation.title',
                message: 'message.videoToggleConfirmation.description',
                acceptButtonText: 'global.form.continue',
                accept: function () {
                    $scope.isImageVisible = true;
                    clearFieldValueInAllLanguages('youtubeURL');
                    $scope.onChange();
                }
            });
        } else {
            $scope.isImageVisible = true;
            $scope.onChange();
        }
    };

    $scope.selectVideoTab = function () {
        $scope.isImageVisible = false;
        var localizedContent = $scope.messageForm.message.contents[$scope.language.selectedLanguage];
        $scope.isOptionalImageVisible = localizedContent && (localizedContent.imageURL || localizedContent.imageOrder > -1);
        $scope.onChange();
    };

    $scope.addImgToGallery = function() {
        var uploadmore = document.getElementById('picsGallery');
        uploadmore.click();
    };

    function openImageCropper(image) {
        ModalService.openImageEditionModal({
            image: image,
            cropOptions: IMAGE_CROPPER_GALLERY,
            showModalSubtitle: false,
            accept: function (croppedImage) {
                if (!$scope.messageId) {
                    $scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.src = croppedImage;
                } else {
                    $scope.isImageLoading = true;
                    DataService.updateImageGallery($scope.messageId, $scope.messageForm.message.gallery.images[$scope.picGallerySelected].id, croppedImage, $scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.name).then(function(response) {
                        var modifiedImage = response.data;
                        $scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.url = modifiedImage.image.url;
                        $scope.messageForm.message.gallery.images[$scope.picGallerySelected].thumbnail = modifiedImage.thumbnail;
                        var message = $translate.instant('messages.notifications.updateImage');
                        NotificationService.notifySuccess(message, 1200);
                    }) 
                }
            }
        });
        $scope.hideWhenEditImage = false;
        $rootScope.$broadcast('dialenga:loadingEnd');
    }

    $scope.editSelectedImage = function() {
        $scope.hideWhenEditImage = true;
        if ($scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.url && $scope.messageId) {
            FileUtilsService.getFileFromUrl($scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.url, function(image) {
                openImageCropper(image);
            });
        } else if ($scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.src && !$scope.messageId) {
            openImageCropper($scope.messageForm.message.gallery.images[$scope.picGallerySelected].image.src);
        }
    };

    $scope.deleteSelectedImage = function(numImage) {
       
        if ($scope.messageId) {
            DataService.deleteImageFromGallery($scope.messageId, $scope.messageForm.message.gallery.images[numImage].id).then(function() {
                var message = $translate.instant('messages.notifications.deleteImage');
                NotificationService.notifySuccess(message, 1200);
            })
        }

        $scope.messageForm.message.gallery.images.splice(numImage,1);

        if( numImage === $scope.picGallerySelected) {
            $scope.messageForm.message.gallery.images.length > 0 && $scope.picGallerySelected > 0 ? $scope.picGallerySelected-- : $scope.picGallerySelected = 0;
            $scope.isImageLoading = true;
            $scope.setSelectedImage($scope.picGallerySelected);
        } else if (numImage < $scope.picGallerySelected) {
            $scope.picGallerySelected--;
        }
        
        parseImageOrder();
        if($scope.messageForm.message.gallery.images.length == 2 && $scope.messageId) {
            updateDeleteImgButtonTooltip();
        } else {
            $rootScope.$broadcast('dialenga:loadingEnd');
        }
        $scope.onChange();
    };

    function newImagesToGalleryPublication(files, nextImage) {
        var newImagesToGallery= [];
        for (var index = 0; index < files.length; index++) {
            newImagesToGallery.push({
                order: nextImage + index,
                footnotes: {}
            })
        }
        DataService.addImageToGallery($scope.messageId, newImagesToGallery, files).then(function(response) {
            var addedImages = response.data;
            $scope.messageForm.message.gallery.images = $scope.messageForm.message.gallery.images.concat(addedImages);
            checkShowCaptionField(nextImage);

            var message = $translate.instant('messages.notifications.addImageSuccess', {totalImages: addedImages.length}, 'messageformat');
            NotificationService.notifySuccess(message, 1200);
            $scope.onChange();
        });
    }

    $scope.onSelectImagesForGallery = function($files) {
        if ($files.length > 0) {
            var howManyFiles = $files.length;
            if (!$scope.messageId) {
                angular.forEach($files, function(file, index) {
                    selectImage(file).then(function (response) {
                        howManyFiles--;
                        $scope.messageForm.message.gallery.images.push(response);

                        if (howManyFiles == 0) {
                            parseImageOrder();
                            $scope.setSelectedImage($scope.messageForm.message.gallery.images.length - $files.length);
                            $scope.onChange();
                        } 
                    });
                });
            } else {
                var nextImage = $scope.messageForm.message.gallery.images.length;
                newImagesToGalleryPublication($files, nextImage);
                $scope.setSelectedImage(nextImage);
                $scope.onChange();
            }
        }
    };

    $scope.toggleCaption = function() {
        $scope.showCaptionField = !$scope.showCaptionField;
    };

    $scope.onChangeCaption = function() {
        if (!$scope.messageForm.message.gallery.images[$scope.picGallerySelected].footnotes[$scope.language.selectedLanguage].note) {
            $scope.showCaptionField = false;
        } else {
            $scope.messageForm.message.gallery.images[$scope.picGallerySelected].footnotes[$scope.language.selectedLanguage].language = $scope.language.selectedLanguage;
        }
        $scope.onChange();
    };

    $scope.onLoadedImage = function() {
        $scope.isImageLoading = false;
    };

    $scope.galleryPlaceholder =  function(element) {
        return element.clone().css({
            "opacity": 0.3,
            "border": "2px dashed #000000"
        });
    };

    $scope.galleryHint = function(element) {
        return element.clone().css({
            "opacity": 0.7,
            "border": "3px solid red"
        });
    };

    $scope.endHandlerImages = function(e) {
        // prevent the sortable from modifying the DOM
        e.sender.draggable.dropped = true;
        e.preventDefault();

        // update the model data and let Angular update the DOM
        if (e.newIndex !== e.oldIndex) {

            $timeout(function () {
                var loadedImage = $scope.picGallerySelected; 
                $scope.messageForm.message.gallery.images.splice(e.newIndex, 0, $scope.messageForm.message.gallery.images.splice(e.oldIndex, 1)[0]);
                $scope.setSelectedImage(e.newIndex, e.oldIndex);

                if(e.oldIndex == loadedImage) {
                    $scope.isImageLoading = false;
                }

                if ($scope.messageId) {
                    var newOrder = {order: e.newIndex};
                    DataService.reorderImageGallery($scope.messageId, $scope.messageForm.message.gallery.images[e.newIndex].id, newOrder);
                } 
                parseImageOrder();
                $scope.onChange();
            });
        }
    };

    $scope.areMultipleLanguagesAvailable = function () {
        var languages = Object.keys($scope.messageForm.message.contents);
        return languages.length > 1;
    };

    $scope.isImageAttachedToAnyLanguage = function () {
        if ($scope.messageForm.images.length > 0) {
            return true;
        }
        var languages = Object.keys($scope.messageForm.message.contents);
        for (var index = 0; index < languages.length; index++) {
            if ($scope.messageForm.message.contents[languages[index]].imageURL) {
                return true;
            }
        }
        return false;
    };

    $scope.showAdviceNotAvailableCategories = function() {
        var message = $translate.instant('categories.noLocationsAvailables');
        NotificationService.notifyInfo(message, false, {delay: 5000});
    };

    var init = function () {
        $scope.isSyncing = true;
        initContentLanguagesChangedEvent();
        setCommentsTooltip();
        setSharingTooltip();
        initDragAndDrop();
        $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $scope.categoriesOptions = CategoryService.getCategoriesIconValueDropDownListOptions();
        $scope.isPill = $location.path().search('/knowledge-center') > -1;
        if ($scope.isPill) {
            DataService = KnowledgeCenterService;
            CategoryService.findAllKnowledgeCenterHierarchy().then(function(response) {
                $scope.categoriesAvailables = response.data.length > 0;
            });
            
        } else {
            DataService = MessageService;
        }
        if ($scope.messageId) {
            DataService.findOne($scope.messageId).then(function (response) {
                $scope.messageForm.message = response.data;
                $scope.messageForm.message.isAttachedResourceEnabled = !!(response.data.contents[$scope.language.selectedLanguage] && 
                                                    response.data.contents[$scope.language.selectedLanguage].resources.length > 0);
                $scope.messageForm.isPublished = parseInt(response.data.state) === parseInt(MESSAGE_STATES.PUBLISHED);
                $scope.messageForm.type = !!$scope.messageForm.message.gallery ? CONTENT_TYPE.GALLERY : CONTENT_TYPE.IMAGE;
                $scope.isImageVisible = !(response.data.contents[$scope.language.selectedLanguage] && response.data.contents[$scope.language.selectedLanguage].youtubeURL);
                $scope.isOptionalImageVisible = !$scope.isImageVisible && response.data.contents[$scope.language.selectedLanguage] &&
                    (response.data.contents[$scope.language.selectedLanguage].imageURL || response.data.contents[$scope.language.selectedLanguage].imageOrder > -1);
                DataService.isOptionalImageVisible = angular.copy($scope.isOptionalImageVisible);
                testUrlLinks();
                loadImagesGallery();
                DataService.latestMessage = angular.copy($scope.messageForm.message);
                $scope.validateModel();
                $scope.getReadabilityIndex();            
                $scope.messageForm.isFormDirty = dataHasChanged();

                updateDeleteImgButtonTooltip();

                if ($scope.messageForm.message.users) {
                    $scope.messageForm.message.users =  DataService.orderRecipientsUsers($scope.messageForm.message.users); 
                }

                $scope.readingTime = GlobalService.getEstimatedReadingTime(response.data.contents[$scope.language.selectedLanguage].text);

                DataService.setUsersAvatarTooltips($scope.messageForm.message.users);
                $scope.isSyncing = false;
            });
        } else {
            setNewPublicationContent();
            DataService.latestMessage = angular.copy($scope.messageForm.message);
            if ($scope.isPill) {
                CategoriesTreeService.clearSelectedCategoryPath();
                if (DataService.selectedCategoryPath) {
                    selectCategoryPath(DataService.selectedCategoryPath);
                }
            } else {
                // Add default option for category dropdown, so that object matches non-category selected when going back
                DataService.latestMessage.category = {
                    name: $translate.instant('global.form.select')
                };
            }
            $scope.messageForm.message.commentsEnabled = $scope.commentsConfig === GENERAL_CONFIG_STATES.ENABLED || $scope.commentsConfig === GENERAL_CONFIG_STATES.FORCE_ENABLED;
            $scope.messageForm.message.shareable = $scope.shareConfig === GENERAL_CONFIG_STATES.ENABLED || $scope.shareConfig === GENERAL_CONFIG_STATES.FORCE_ENABLED;
            DataService.setUsersAvatarTooltips($scope.$parent.messageForm.message.users);
            $scope.isSyncing = false;
        }
        KendoUtilsService.disableDropOnKendoEditor('kendoTextEditor');
    };

    function setNewPublicationContent() {
        $scope.language.selectedLanguage = defaultCompanyLanguage;
        $scope.messageForm.message.contents[defaultCompanyLanguage] = {};

        $scope.messageForm.message.pushEnabled = $scope.pushOptions !== PUSH_OPTION.ALWAYS_OFF ? true : false;
        // $scope.messageForm.message.ratingEnabled = Session.ratingConfig !== GENERAL_CONFIG_STATES.DISABLED && Session.ratingConfig !== GENERAL_CONFIG_STATES.FORCE_DISABLED;
    }

    $scope.$on('dialenga:savePublicationFormAndStay', function(event, args) {
        if (args.tab === MESSAGE_TABS.CONTENT) {
            $scope.publicationFormSave(args.redirect, args.nextTab);
        }
    });

    $scope.$watch('changed', function (newValue) {
        if (newValue) {
            testUrlLinks();
            $scope.validateModel();
            $scope.changed = false;
        }
    });
    
    init();
}]);
