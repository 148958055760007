'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CategoryController
 * @description
 '# Categ'oryController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CategoryController',
    ['$scope', '$rootScope', '$routeParams', '$location', 'CategoryService', 'LanguageService', 'CATEGORY_AVAILABILITY', 'IMAGE_CROPPER_CATEGORY',
        function ($scope, $rootScope, $routeParams, $location, CategoryService, LanguageService, CATEGORY_AVAILABILITY, IMAGE_CROPPER_CATEGORY) {

            //******************************************* Attributes ************************************\\

            var redDark = 10362398
            $scope.categoryId = $routeParams.id;
            $scope.changed = false;
            $scope.valid = false;
            $scope.categoryForm = {
                category: {
                    contents: {}
                },
                images: []
            };

            $scope.IMAGE_CROPPER_CATEGORY = IMAGE_CROPPER_CATEGORY;
            $scope.isCategoryPreviewOpened = false;
            $scope.CATEGORY_AVAILABILITY = CATEGORY_AVAILABILITY;
            $scope.modalTexts = {
                title: 'message.imageCategoryModalTitle',
                subtitle: 'message.imageCategoryModalSubtitle'
            };

            var categoriesPath = '/settings/categories';

            //******************************************** Methods **************************************\\
            $scope.categoryFormSave = function () {
                var isFormDirty = dataHasChanged();
                if (isFormDirty) {
                    LanguageService.removeEmptyLanguages($scope.categoryForm.category);
                    var promise;
                    if ($scope.categoryForm.category.id) {
                        promise = CategoryService.update($scope.categoryForm);
                    } else {
                        promise = CategoryService.create($scope.categoryForm);
                    }
                    promise.then(function (response) {
                        CategoryService.categoryTemp = response.data;
                        $location.path(categoriesPath);
                    }, function () {
                        $rootScope.$broadcast('dialenga:loadingEnd');
                    });
                } else {
                    $location.path(categoriesPath);
                }
            };

            $scope.validateModel = function () {
                var nonEmptyLanguagesValid = CategoryService.validateNonEmptyLanguages($scope.categoryForm.category);
                $scope.valid = !!$scope.categoryForm.category.color && nonEmptyLanguagesValid;
            };

            $scope.onChange = function () {
                $scope.changed = true;
            };

            function dataHasChanged() {
                return !angular.equals($scope.categoryForm.category, CategoryService.categoryTemp);
            }

            $scope.goBack = function () {
                var isFormDirty = dataHasChanged();
                $rootScope.goBackAndCheckForModifications(isFormDirty, categoriesPath);
            };

            $scope.selectColor = function (color) {
                $scope.categoryForm.category.color = color;
                $scope.onChange();
            };
            $scope.openCategoryPreview = function(){
                $scope.isCategoryPreviewOpened = !$scope.isCategoryPreviewOpened;
            }

            $scope.selectCategoryAvailability = function (availability) {
                $scope.categoryForm.category.availability = availability;
                $scope.onChange();
            };

            var init = function () {
                delete CategoryService.categoryTemp;
                $scope.availableColors = CategoryService.availableColors;
                $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
                if ($scope.categoryId) {
                    CategoryService.findOne($scope.categoryId).then(function (response) {
                        $scope.categoryForm.category = response.data;
                        CategoryService.categoryTemp = angular.copy(response.data);
                        $scope.validateModel();
                    });
                } else {
                    $scope.categoryForm.category.color = redDark;
                    $scope.categoryForm.category.availability = CATEGORY_AVAILABILITY.ALL;
                    CategoryService.categoryTemp = angular.copy($scope.categoryForm.category);
                }
            };

            $scope.$watch('changed', function (newValue) {
                if (newValue) {
                    $scope.validateModel();
                    $scope.changed = false;
                }
            });

            init();
        }]);
