'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CategoriesModalController
 * @description
 * # CategoriesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CategoriesModalController',
    ['$scope', 'params', '$uibModalInstance', '$window', 'CategoriesTreeService', 'CATEGORY_CONTAINER_STATE',
    function ($scope, params, $uibModalInstance, $window, CategoriesTreeService, CATEGORY_CONTAINER_STATE) {

    //****************************************** Attributes *************************************\\
    $scope.CATEGORY_CONTAINER_STATE = CATEGORY_CONTAINER_STATE;
    $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.OPENED;

    var lastSelectedCategory = null;

    //******************************************* Methods ***************************************\\
    $scope.saveSelectedCategoryAndCloseModal = function () {
        if ($scope.selectedCategoryId && lastSelectedCategory === $scope.selectedCategoryId) {
            // If no category is selected after showing the modal, select the initial category
            CategoriesTreeService.selectCategory($scope.selectedCategoryId);
        }
        var selectedCategoryPath = CategoriesTreeService.getSelectedCategoryPath();
        $uibModalInstance.close(selectedCategoryPath);
        $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.CLOSED;
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
        $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.CLOSED;
    };

    $scope.toggleEditionMode = function () {
        var modal = document.getElementsByClassName('modal-dialog');

        if ($scope.categoryContainerState == CATEGORY_CONTAINER_STATE.OPENED) {
            $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.EDITION;
            modal[0].classList.add('modal-lg');
        } else {
            $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.OPENED;
            modal[0].classList.remove('modal-lg');
        }
    };

    $window.closeCategoriesTreeModal = $scope.saveSelectedCategoryAndCloseModal;

    $window.setSelectedCategoryInModal = function (selectedCategoryId) {
        lastSelectedCategory = selectedCategoryId;
    };

    function init() {
        if (params.id) {
            $scope.selectedCategoryId = params.id;
        }
    }

    init();

}]);
