'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CreateMotiveModalController
 * @description
 * # CreateMotiveModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CreateMotiveModalController',
    ['$scope', '$timeout', '$uibModalInstance', 'CategoryService', 'ConversationsService', 'Session', 'params',
    function ($scope, $timeout, $uibModalInstance, CategoryService, ConversationsService, Session, params) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.changed = false;
    vm.valid = false;
    vm.motiveForm = { motive: { contents: {} } };

    //******************************************* Methods ***************************************\\
    vm.accept = function () {
        var promiseService = (params && params.id) ? ConversationsService.updateMotive : ConversationsService.createMotive;
        return promiseService.call(ConversationsService, vm.motiveForm.motive).then(function(response) {
            $uibModalInstance.close(response.data);
        }, function (error) {
            console.error(error);
            $uibModalInstance.close();
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        var valid = !!vm.motiveForm.motive.color && !!vm.motiveForm.motive.contents;
        var languages = Object.keys(vm.motiveForm.motive.contents);
        for (var index = 0; index < languages.length; index++) {
            var motiveContent = vm.motiveForm.motive.contents[languages[index]];
            if (!motiveContent.motive || !motiveContent.motiveInApp) {
                valid = false;
                break;
            }
        }
        vm.valid = valid;
    };

    vm.selectColor = function (color) {
        vm.motiveForm.motive.color = color;
        vm.onChange();
    };

    vm.onChange = function () {
        vm.changed = true;
    };

    vm.modalSaveOnEnter = function () {
        var keyCode = event.which || event.keyCode;
        if (keyCode === 13 && vm.valid) {
            vm.accept();
        }
    };

    $scope.$watch('vm.changed', function (newValue, oldValue) {
        if (newValue) {
            validateModel();
            vm.changed = false;
        }
    });

    function init () {
        vm.availableColors = CategoryService.availableColors;
        vm.selectedLanguage = Session.companyDefaultLanguage;
        if (params && params.id) {
            vm.titleTranslationKey = 'motives.modal.editionTitle';
            ConversationsService.getMotive(params.id).then(function (response) {
                vm.motiveForm.motive = response.data;
                validateModel();
            });
        } else {
            vm.titleTranslationKey = 'motives.modal.creationTitle';
            vm.motiveForm.motive.color = vm.availableColors.redDark;
        }
        $timeout(function() {
            $('#motiveNameInApp').focus();
        });
    }

    init();
}]);
