(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:passwordStrength
     * @description
     * # passwordStrength
     */
    angular.module('dialengaExeviBackoffice').component('passwordStrength', {
        bindings: {
            properties: '=',
            value: '=',
            password: '<'
        },
        controller: ['STRENGTH_TYPE', function (STRENGTH_TYPE) {
            var vm = this;

            vm.STRENGTH_TYPE = STRENGTH_TYPE;
        }],
        controllerAs: 'vm',
        templateUrl: '/ng1/components/password-strength/password-strength.html',
    });
})();
