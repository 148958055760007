'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.auditService
 * @description
 * # auditService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('AuditService',
    ['$http', 'SharedDataService', 'PAGE_SIZE',
    function ($http, SharedDataService, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/audit';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

}]);
