'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.timeoffSettingsGlobalService
 * @description
 * # timeoffSettingsGlobalService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('TimeoffSettingsGlobalService',
    ['$http', 'SharedDataService', 
    function ($http, SharedDataService) {

    this.URL = SharedDataService.apiUrl + '/timeoff/config/my-company';

    this.getData = function () {
        return $http({
            url: this.URL,
            method: 'GET'
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL,
            method: 'PUT',
            data: data
        });
    };

}]);
