(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewSurveyContent
     * @description
     * # devicePreviewSurveyContent
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewSurveyContent', {
        bindings: {
            publication: '<',
            language: '<',
            isOpened: '<'
        },
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-survey-content.html',
        controllerAs: 'vm',
        controller: ['$timeout', '$window', 'videoService', 'QUESTION_KIND', function ($timeout, $window, videoService, QUESTION_KIND) {
            var vm = this;

            vm.QUESTION_KIND = QUESTION_KIND;
            vm.MULTIPLE_KIND = { LIMITLESS: 1, EXACT: 2, RANGE: 3 };

            vm.getVideoThumbnail = videoService.getThumbnail;
            vm.currentWindowScrollPosition = 0;
            vm.scrollPositions = [];
            vm.activeItemIndex = 0;

            var currentScrollPosition = 0;
            var scrollContainerHeight = 0;
            var lastWindowWidth;
            var ignoreScrollEvent = false;

            this.$onInit = function () {
                var previewContainerDisplay = getComputedStyle(document.getElementById('mobile-preview-container'), null).display;
                if (previewContainerDisplay !== 'none') {
                    $timeout(function() {
                        initializeItemsPositionsAndScrollEvent();
                    }, 200)
                }

                angular.element($window).on('resize', function (event) {
                    if (lastWindowWidth <= 1750 && event.currentTarget.innerWidth > 1750) {
                        initializeItemsPositionsAndScrollEvent();
                    } else if (event.currentTarget.innerWidth <= 1750 && lastWindowWidth > 1750) {
                        removeScrollEventListener();
                    }
                    lastWindowWidth = event.currentTarget.innerWidth;
                });
            };

            this.$onChanges = function (changes) {
                if (changes && changes.isOpened) {
                    if (changes.isOpened.previousValue === false && changes.isOpened.currentValue === true) {
                        initializeItemsPositionsAndScrollEvent();
                    } else if (changes.isOpened.previousValue === true && changes.isOpened.currentValue === false) {
                        removeScrollEventListener();
                    }
                }
            };

            this.$onDestroy = function () {
                removeScrollEventListener();
            };

            var scrollEventListener = function (event) {
                if (!ignoreScrollEvent && event.target.id === 'scroll-device') {
                    currentScrollPosition = event.target.scrollTop;
                    vm.activeItemIndex = getActiveItem();
                }
            };

            function initializeItemsPositionsAndScrollEvent() {
                $timeout(function () {
                    setQuestionnaireItemsPositions();
                    document.addEventListener('scroll', scrollEventListener, true);
                }, 750);
            }

            function removeScrollEventListener() {
                document.removeEventListener('scroll', scrollEventListener, true);
            }

            function getActiveItem() {
                var activePosition, activeItemIndex = null;
                if (currentScrollPosition === 0) {
                    activeItemIndex = 0;
                } else {
                    for (var index = 0; index < vm.scrollPositions.length; index++) {
                        activePosition = currentScrollPosition + (scrollContainerHeight * 2/5);
                        if (index < vm.scrollPositions.length - 1 && activePosition >= vm.scrollPositions[index]
                            && activePosition < vm.scrollPositions[index + 1]) {
                                activeItemIndex = index;
                                break;
                            }
                    }
                }
                return activeItemIndex !== null ? activeItemIndex : vm.scrollPositions.length - 1;
            }

            function setQuestionnaireItemsPositions() {
                vm.scrollPositions = [];

                var scrollPosition;
                var scrollOffset = $('.scroll-device').offset().top;
                
                if (vm.publication.cover) {
                    scrollPosition = $('#question-linked-cover').offset().top - scrollOffset;
                    // If a scroll was made before resizing the screen, that scroll position becomes the new base position.
                    // To fix that, if first item's scroll position is negative, reset to 0 and add that offset to the initial scroll offset.
                    if (scrollPosition < 0) {
                        scrollOffset += scrollPosition;
                        scrollPosition = 0;
                    }
                    vm.scrollPositions.push(scrollPosition);
                }

                var questionLinkedOffset = !!vm.publication.cover ? 1 : 0;

                if (vm.publication.questions) {
                    for (var index = 0; index < vm.publication.questions.length; index++) {
                        scrollPosition = $('#question-linked-' + (index + questionLinkedOffset)).offset().top - scrollOffset;
                        // If a scroll was made before resizing the screen, that scroll position becomes the new base position.
                        // To fix that, if first item's scroll position is negative, reset to 0 and add that offset to the initial scroll offset.
                        if (index === 0 && scrollPosition < 0) {
                            scrollOffset += scrollPosition;
                            scrollPosition = 0;
                        }
                        vm.scrollPositions.push(scrollPosition);
                    }
                }

                if (vm.publication.endings) {
                    var questionsLength = vm.publication.questions.length;
                    for (var index = 0; index < vm.publication.endings.length; index++) {
                        scrollPosition = $('#question-linked-' + (index + questionsLength + questionLinkedOffset)).offset().top - scrollOffset;
                        vm.scrollPositions.push(scrollPosition);
                    }
                }
                scrollContainerHeight = $('.scroll-device').height();
            }

            vm.scrollTo = function (index) {
                vm.activeItemIndex = index;
                ignoreScrollEvent = true;
                $('.scroll-device--survey').animate({ scrollTop: vm.scrollPositions[index] }, "slow", function () {
                    $timeout(function () {
                        ignoreScrollEvent = false;
                    }, 100);
                });
            };

            vm.getQuestionKindBackground = function(kind) {
                var backgroundClass = '';
                switch (kind) {
                    case QUESTION_KIND.SINGLE_CHOICE:
                        backgroundClass = 'questionnaire-options'
                        break;
                    case QUESTION_KIND.OPEN:
                        backgroundClass = 'open-question'
                        break;
                    case QUESTION_KIND.IMAGE:
                        backgroundClass = 'add-attachment'
                        break;
                    case QUESTION_KIND.COMMENT:
                        backgroundClass = 'questionnaire-text'
                        break;
                    default:
                        break;
                }
                return backgroundClass;
            }

            vm.getCategoryLocalizedName = function () {
                if (!vm.publication || !vm.publication.category) {
                    return;
                }
                return (!!vm.publication.category.contents && !!vm.publication.category.contents[vm.language]) ? vm.publication.category.contents[vm.language].name : vm.publication.category.name;
            };
        }]
    });
})();
