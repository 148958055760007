'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserCategoriesModalController
 * @description
 * # UserCategoriesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserCategoriesModalController',
    ['$scope', '$uibModalInstance', 'ArrayUtilsService', 'CategoryService', 'params', 'ALLOWED_CATEGORIES_KIND',
    function ($scope, $uibModalInstance, ArrayUtilsService, CategoryService, params, ALLOWED_CATEGORIES_KIND) {
        var vm = this;

        vm.totalAvailableItems = 0;
        vm.addedCategories = [];
        vm.selectedCategories = [];
        vm.addedCategoriesIds = [];
        vm.arePendingChanges = false;
        vm.ALLOWED_CATEGORIES_KIND = ALLOWED_CATEGORIES_KIND;

        vm.filter = {
            availableCategories: '',
            addedCategories: ''
        };

        var initialAddedCategoriesIds;

        $scope.$watchCollection('vm.addedCategoriesIds', function (newValue) {
            if (newValue) {
                vm.arePendingChanges = !ArrayUtilsService.areEquals(initialAddedCategoriesIds, newValue);
            }
        });

        vm.accept = function () {
            var categories = vm.arePendingChanges ? vm.addedCategories : null;
            $uibModalInstance.close(categories);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function setAvailableCategories(data) {
            vm.availableCategories = data.content;
            vm.totalAvailableItems = data.numberOfElements;
        }

        function loadAvailableCategories() {
            var options = {
                active: true,
                page: 0,
                size: 20000,
                sort: 'publicationGroupingContents.name'
            };
            options['publicationGroupingContents.name'] = vm.filter.availableCategories;
            getAllAvailableCategories(options);
        }

        function getAllAvailableCategories(options) {
            vm.isSyncing = true;
            CategoryService.findAll(options).then(function (response) {
                vm.isSyncing = false;
                if (options['publicationGroupingContents.name'] === vm.filter.availableCategories) {
                    setAvailableCategories(response.data);
                }
            });
        }

        vm.searchAvailableCategories = function(text) {
            // Clear selected categories when starting modifing the filter
            vm.selectedCategories = [];
            vm.filter.availableCategories = text || '';
            loadAvailableCategories();
        };

        function getAddedCategoriesIds() {
            return vm.addedCategories.map(function (category) {
                return category.id;
            });
        }

        function init() {
            
            loadAvailableCategories();
            vm.addedCategories = params.categories;
            vm.allowedCategoriesKind = params.allowedCategoriesKind;
            initialAddedCategoriesIds = getAddedCategoriesIds();
        }
    
        init();
}]);