'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnairesController
 * @description
 * # QuestionnairesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnairesController', ['$location', '$scope', '$translate', 'KendoUtilsService', 'MessageService', 'ModalService', 'PublicationService', 'QuestionnaireService',
    'DateUtilsService', 'Session', 'CONTENT_KIND', 'MESSAGE_STATES', 'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH', 'GRID_DATE_COLUMN_WIDTH', 'GRID_CATEGORY_COLUMN_WIDTH', 'PUBLICATION_SCHEDULED_KIND', 'EXPIRATION_MODE',
    function($location, $scope, $translate, KendoUtilsService, MessageService, ModalService, PublicationService, QuestionnaireService,
        DateUtilsService, Session, CONTENT_KIND, MESSAGE_STATES, PAGE_SIZE, GRID_ICON_COLUMN_WIDTH, GRID_DATE_COLUMN_WIDTH, GRID_CATEGORY_COLUMN_WIDTH, PUBLICATION_SCHEDULED_KIND, EXPIRATION_MODE) {

        //******************************************* Attributes ************************************\\
        $scope.actionButtons = [];
        $scope.stateIsActive = true;
        $scope.states = MESSAGE_STATES;
        $scope.inputSearchField = "";
        $scope.selectedQuestionnaire = null;
        $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
        $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
        $scope.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;
        $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;

        $scope.EXPIRATION_MODE = EXPIRATION_MODE;
        $scope.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;

        MESSAGE_STATES.ALL = 0;

        $scope.questionnairesStates = [
            { id: MESSAGE_STATES.ALL, title: 'global.messages.stateFilters.all' },
            { id: MESSAGE_STATES.DRAFT, title: 'global.messages.stateFilters.draft' },
            { id: MESSAGE_STATES.PENDING, title: 'global.messages.stateFilters.pending' },
            { id: MESSAGE_STATES.PLANNED, title: 'global.messages.stateFilters.planned' },
            { id: MESSAGE_STATES.PUBLISHED, title: 'global.messages.stateFilters.published' }
        ];

        var gridColumns = [{
            field: "state",
            title: " ",
            template: '<span class="{{dataItem.state | stateIconFilter}}" data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + '"' +
                ' kendo-tooltip k-content="\'{{dataItem.state}}\' | stateTooltipFilter"></span>' +
                '<div data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '"><spinner-dialenga></spinner-dialenga></div>',
            attributes: { class: "text-center" },
            width: GRID_ICON_COLUMN_WIDTH,
            sortable: false
        }, {
            field: "name",
            title: $translate.instant('model.questionnaire.name'),
            template: '<div class="truncate-container-list"><i class="icon icon-dialog" ng-if="dataItem.kind == ' + CONTENT_KIND.DIALOGUE + '" kendo-tooltip k-content="\'questionnaires.dialogue\' | translate"></i>' +
                '<span class="icon icon-heisenberg icon-22 margin-right-5" kendo-tooltip k-content="\'questionnaires.preview.anonymousSurvey\' | translate" ng-if="!!dataItem.anonymous"></span>' +
                '<span data-ng-bind="dataItem.name" title="{{dataItem.name}}"></span></div>'
        }, {
            field: "category.name",
            title: $translate.instant('model.message.category'),
            template: '<span data-ng-bind-html="dataItem.category | messageCategoryFilter"></span>',
            width: GRID_CATEGORY_COLUMN_WIDTH
        }, {
            field: "publicationDate",
            title: $translate.instant('model.message.publicationDate'),
            type: "datetime",
            format: "{0:dd/MM/yyyy HH:mm}",
            width: GRID_DATE_COLUMN_WIDTH
        }, {
            field: "autoExpiration.autoExpirationEnabled",
            title: " ",
            template: '<div data-ng-if="dataItem.autoExpiration.autoExpirationEnabled == true">' +
                            '<div data-ng-if="!expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) || (expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && (dataItem.state == states.EXPIRED || dataItem.state == states.PUBLISHED))">' +
                                '<div ng-if="!dataItem.expirationMode || dataItem.expirationMode == EXPIRATION_MODE.AUTO_EXPIRED">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDate\' | translate:dataItem"></span>' +
                                '</div>' +
                                '<div ng-if="dataItem.expirationMode == EXPIRATION_MODE.MANUALLY">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color opacity-50" kendo-tooltip k-content="\'model.message.tooltipExpirationDateManual\' | translate:dataItem"></span>' +
                                '</div>' +
                            '</div>' +
                            '<div data-ng-if="expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && dataItem.state !== states.EXPIRED && dataItem.state !== states.PUBLISHED">' +
                                '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED">' +
                                    '<span class="icon icon-autoarchive icon-40 red-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDateExpired\' | translate:dataItem"></span>' +
                                '</div>' +
                                '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="getTooltipForScheduledNow(dataItem.autoExpiration)"></span>' +
                                '</div>' +
                            '</div>' +
                        '</div>',
            width: 70,
            sortable: false
        }, {
            command: [{
                name: "action-button",
                template: '<div class="td-on-hover"><a data-ng-class="{\'link-inactive opacity-50\': button.disabled}" class="k-button k-grid-edit-button" ' +
                    'kendo-tooltip k-content="\'{{button.title}}\' | translate" ' +
                    'data-ng-repeat="button in actionButtons[dataItem.state]" data-ng-click="performAction(dataItem, button.action)" ' +
                    'data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + 
                        ' && (!button.conditionalField || (!!dataItem[button.conditionalField] && dataItem.scheduleKind == ' + PUBLICATION_SCHEDULED_KIND.SCHEDULED +') || dataItem.scheduleKind == '+ PUBLICATION_SCHEDULED_KIND.NOW +')">' +
                    '<span data-ng-class="button.icon"></span></a>' +
                    '<span data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '" data-translate="global.states.publishing"></span></div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: ""
        }];

        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.state = MessageService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
                    saveGridPreferences(options);

                    var requestOptions = KendoUtilsService.getGridRequestOptions(options, 'lastModifiedDate,desc', $scope.inputSearchField, 'name');
                    QuestionnaireService.findAll(requestOptions).then(function(response) {
                        PublicationService.planUpdateIfThereArePublishingPublications(response.data.content, $scope.questionnairesGrid.dataSource);
                        $scope.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements",
                parse: function(data) {
                    $.each(data.content, function(idx, elem) {
                        if (elem.publicationDate)
                            data.content[idx].publicationDate = new Date(elem.publicationDate);
                    });
                    return data;
                }
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true }, { change: onChange, dataBound: onDataBound }).then(function(response) {
            $scope.gridOptions = response;
        });

        //******************************************** Methods **************************************\\
        var init = function() {
            $scope.actionButtons[MESSAGE_STATES.DRAFT] = [];
            $scope.actionButtons[MESSAGE_STATES.PENDING] = [];
            $scope.actionButtons[MESSAGE_STATES.PLANNED] = [];
            $scope.actionButtons[MESSAGE_STATES.PUBLISHED] = [];
            $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW] = [];
            $scope.actionButtons[MESSAGE_STATES.EXPIRED] = [];
            $scope.actionButtons[MESSAGE_STATES.DELETED] = [];

            // User has Admin or publisher Rol or has editor rol and can publish contents (is like publisher rol)
            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'validate', title: 'global.form.validate', icon: 'icon icon-double-validation' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send' });
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'pauseEdit', title: 'global.form.pauseedit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
                
                if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                }
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'archive', title: 'global.form.archive', icon: 'icon icon-archive' });
                
                $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send', disabled: true });
            // User has Editor role and not allowed publish contents
            } else {

                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'requestValidation', title: 'global.form.pendingvalidation', icon: 'icon icon-first-validation', conditionalField: 'publicationDate' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
             
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                
                if ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) {
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                } 
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                
            }
            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
            }
            $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });

            if (QuestionnaireService.navigateFromHomeCreateSurvey) {
                // Comming from Home (create survey)
                $scope.createQuestionnaire();
                delete QuestionnaireService.navigateFromHomeCreateSurvey;
            }
        };

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['questionnairesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['questionnairesGrid'].stateIsActive = $scope.stateIsActive;
            KendoUtilsService.gridPreferences['questionnairesGrid'].selectedButton = $scope.selectedButton;
            KendoUtilsService.gridPreferences['questionnairesGrid'].inputSearchField = $scope.inputSearchField;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === $scope.questionnairesGrid) {
                if (KendoUtilsService.gridPreferences['questionnairesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['questionnairesGrid'];
                    $scope.stateIsActive = requestOptions.stateIsActive;
                    $scope.selectedButton = requestOptions.selectedButton;
                    $scope.inputSearchField = requestOptions.inputSearchField;

                    $scope.questionnairesGrid.dataSource.query(requestOptions);
                } else {
                    $scope.selectedButton = MESSAGE_STATES.ALL;
                    $scope.questionnairesGrid.dataSource.read();
                }
            }
        });

        $scope.getTooltipForScheduledNow = function(item) {
            return $translate.instant('model.message.tooltipExpirationDateFromNow', {amount: item.autoExpirationAmount, kind: item.autoExpirationKind}, 'messageformat');
        }

        $scope.listItemsSearch = function(text) {
            $scope.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        $scope.expirationDateIsEarlier = function (expirationDate) {
            var currentDate = new Date();
            return new Date(expirationDate) <= currentDate;
        }

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
            $scope.selectedQuestionnaire = null;
        }

        function onChange() {
            var selectedRow = this.select();
            var newSelectedQuestionnaire = $scope.questionnairesGrid.dataItem(selectedRow[0]);

            if (newSelectedQuestionnaire !== $scope.selectedQuestionnaire) {
                $scope.selectedQuestionnaire = (selectedRow.length > 0) ? newSelectedQuestionnaire : null;
            } else {
                // Deselect the row
                this.clearSelection();
                $scope.selectedQuestionnaire = null;
            }
        }

        $scope.setSelected = function(button) {
            $scope.selectedButton = button.id;
            MessageService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
            reloadGrid({ page: 1 });
        };

        function redirectToQuestionnaireEditionView(questionnaireId) {
            $location.path('/questionnaires/edit/' + questionnaireId);
        }

        function pauseAndEditContent(id, state) {
            var skipModalAlias = 'pauseAndEditPublication';
            var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
            if (!shouldModalBeDisplayed) {
                QuestionnaireService.pause(id)
                    .then(function() {
                        redirectToQuestionnaireEditionView(id);
                    })
                    .catch(actionErrorHandler);
            } else {
                ModalService.openConfirmationModal({
                    title: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.title' : 'publication.modal.pauseAndEdit.titleForPending',
                    message: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.message' : 'publication.modal.pauseAndEdit.messageForPending',
                    messageData: { contentType: CONTENT_KIND.DIALOGUE },
                    acceptButtonText: 'global.form.edit',
                    skipModalAlias: skipModalAlias,
                    isTextInterpolated: true,
                    accept: function() {
                        QuestionnaireService.pause(id)
                            .then(function() {
                                redirectToQuestionnaireEditionView(id);
                            })
                            .catch(actionErrorHandler);
                    }
                });
            }
        }

        function requestValidation(questionnaireId, contentType) {
            QuestionnaireService.requestValidation(questionnaireId)
                .then(function() {
                    PublicationService.showSuccessNotification('publication.modal.validationRequest.validationRequested', contentType);
                    reloadGrid();
                })
                .catch(actionErrorHandler);
        }

        $scope.performAction = function(questionnaire, action) {
            var contentType = CONTENT_KIND.DIALOGUE;
            var date, time;
            switch (action) {
                case 'update':
                    if (questionnaire.state === MESSAGE_STATES.PENDING && !$scope.isUserPublisherOrAdmin) {
                        pauseAndEditContent(questionnaire.id, MESSAGE_STATES.PENDING);
                    } else {
                        editQuestionnaire(questionnaire);
                    }
                    break;
                case 'view':
                    $location.path('/questionnaires/view/' + questionnaire.id);
                    break;
                case 'validate':
                    date = DateUtilsService.getDateFromTimestamp(questionnaire.publicationDate || undefined);
                    time = DateUtilsService.getTimeFromTimestamp(questionnaire.publicationDate || undefined);
                    if (questionnaire.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED && questionnaire.publicationDate < new Date()) {
                        date = DateUtilsService.getDateFromTimestamp(undefined);
                        time = DateUtilsService.getTimeFromTimestamp(undefined);
                    }
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.validate.title',
                        message: 'publication.modal.validate.message',
                        messageData: {
                            contentType: contentType,
                            date: date,
                            time: time
                        },
                        acceptButtonText: 'publication.modal.validate.confirm',
                        isTextInterpolated: true,
                        accept: function() {
                            QuestionnaireService.validate(questionnaire.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.validate.validated', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'requestValidation':
                    if (questionnaire.scheduleKind === PUBLICATION_SCHEDULED_KIND.SCHEDULED && !questionnaire.publicationDate) {
                        return;
                    }
                    var skipModalAlias = 'requestValidation';
                    var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                    if (!shouldModalBeDisplayed) {
                        requestValidation(questionnaire.id, contentType);
                    } else {
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.validationRequest.title',
                            message: 'publication.modal.validationRequest.message',
                            messageData: { contentType: contentType },
                            acceptButtonText: 'global.form.pendingvalidation',
                            isTextInterpolated: true,
                            skipModalAlias: skipModalAlias,
                            accept: function() {
                                requestValidation(questionnaire.id, contentType);
                            }
                        });
                    }
                    break;
                case 'pauseEdit':
                    pauseAndEditContent(questionnaire.id);
                    break;
                case 'delete':
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.delete.title',
                        message: 'publication.modal.delete.message',
                        messageData: { contentType: contentType },
                        acceptButtonText: 'global.form.delete',
                        isTextInterpolated: true,
                        accept: function() {
                            QuestionnaireService.delete(questionnaire.id, 'questionnaires.error.delete')
                                .then(reloadGrid)
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'sendNow':
                    date = DateUtilsService.getDateFromTimestamp(questionnaire.publicationDate);
                    time = DateUtilsService.getTimeFromTimestamp(questionnaire.publicationDate);
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.send.title',
                        message: 'publication.modal.send.message',
                        messageData: {
                            contentType: contentType,
                            date: date,
                            time: time
                        },
                        acceptButtonText: 'global.form.sendnow',
                        isTextInterpolated: true,
                        accept: function() {
                            QuestionnaireService.publish(questionnaire.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.send.sent', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'statistics':
                    $location.path('/questionnaires/statistics/' + questionnaire.id);
                    break;
                case 'archive':
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.archive.title',
                        message: 'publication.modal.archive.message',
                        messageData: { contentType: contentType },
                        acceptButtonText: 'global.form.archive',
                        isTextInterpolated: true,
                        focusCancel: true,
                        hideCancelButton: false,
                        accept: function() {
                            QuestionnaireService.expire(questionnaire.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.archive.archived', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                default:
                    break;
            }
        };

        function reloadGrid(options) {
            $scope.isSyncing = true;
            PublicationService.updatePublications($scope.questionnairesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    $scope.isSyncing = false;
                });
            });
        }

        function actionErrorHandler(error) {
            if (error.status === 422) {
                reloadGrid();
            }
        }

        function redirectToPath(url) {
            $scope.$apply(function() {
                $location.path(url);
            });
        }

        $scope.getQuestionnairesByIsActive = function(isActive) {
            if ($scope.stateIsActive !== isActive) {
                $scope.stateIsActive = isActive;
                reloadGrid({ page: 1 });
            }
        };

        $scope.createQuestionnaire = function() {
            ModalService.openQuestionnaireModal();
        };

        function editQuestionnaire(questionnaire) {
            if (!questionnaire.category) {
                ModalService.openQuestionnaireModal(questionnaire.id, function() {
                    redirectToQuestionnaireEditionView(questionnaire.id);
                });
            } else {
                redirectToQuestionnaireEditionView(questionnaire.id);
            }
        }

        $scope.cloneSelectedContent = function() {
            if (!$scope.selectedQuestionnaire || !$scope.selectedQuestionnaire.id) {
                return;
            }

            QuestionnaireService.duplicate($scope.selectedQuestionnaire.id).then(function(response) {
                ModalService.openQuestionnaireModal(response.data.id);
                $scope.stateIsActive = true;
                reloadGrid();
            });
        };

        $scope.$on('$destroy', function() {
            PublicationService.stopWaitingForUpdates();
        });

        var onDblClick = function(dataItem) {
            var rowState = parseInt(dataItem.state);
            switch (rowState) {
                case MESSAGE_STATES.DRAFT:
                case MESSAGE_STATES.PENDING:
                    editQuestionnaire(dataItem);
                    break;
                case MESSAGE_STATES.PLANNED:
                    if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                        // User has Editor role and not allowed publish contents
                        pauseAndEditContent(dataItem.id, MESSAGE_STATES.PLANNED);
                    } else {
                        $scope.performAction(dataItem, 'view');
                    }
                    break;
                case MESSAGE_STATES.PUBLISHED:
                case MESSAGE_STATES.EXPIRED:
                    $scope.performAction(dataItem, $scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) ? 'statistics' : 'view');
                    break;
                default:
                    break;
            }
        };

        KendoUtilsService.dblClickGridCallback('kendo-grid', onDblClick);

        init();

    }
]);