'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:SurveyController
 * @description
 * # SurveyController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('SurveyController',
    ['$scope', '$rootScope', '$location', '$routeParams', 'ModalService', 'QuestionnaireService', 'QUESTIONNAIRE_KIND',
    function ($scope, $rootScope, $location, $routeParams, ModalService, QuestionnaireService, QUESTIONNAIRE_KIND) {

        $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;

        $scope.addEnding = function () {
            $location.path('/questionnaires/' + $routeParams.id + '/endings/');
        };

        $scope.editEnding = function (endingId) {
            $location.path('/questionnaires/' + $routeParams.id + '/endings/' + endingId);
        };

        $scope.editCover = function (coverId) {
            $location.path('/questionnaires/' + $routeParams.id + '/questions/' + coverId);
        }

        $scope.deleteCover = function (coverId, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            ModalService.openConfirmationModal({
                title: 'global.form.delete',
                message: 'questionnaire.modal.delete.cover',
                focusCancel: true,
                hideCancelButton: false,
                accept: function () {
                    QuestionnaireService.deleteQuestion($routeParams.id, coverId, 'questionnaire.question.error.delete').then(function() {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                }
            });
        };
}]);
