'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessageRecipientsController
 * @description
 * # MessageRecipientsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('MessageRecipientsController',
    ['$scope', '$routeParams', '$filter', '$rootScope', '$translate', 'NotificationService', 'LanguageService', 'ModalService', 'PublicationService',
    'SegmentService', 'UsersGroupsService', 'UsersService', 'CONTENT_KIND', 'MESSAGE_STATES', 'POPULATION', 'SEGMENTATION_KIND', 'MESSAGE_TABS',
    function ($scope, $routeParams, $filter, $rootScope, $translate, NotificationService, LanguageService, ModalService, PublicationService,
        SegmentService, UsersGroupsService, UsersService, CONTENT_KIND, MESSAGE_STATES, POPULATION, SEGMENTATION_KIND, MESSAGE_TABS
    ) {
    //******************************************* Attributes ************************************\\
    var DataService = $scope.DataService;

    $scope.recipients = {};
    $scope.selectedUser = {};
    $scope.POPULATION = POPULATION;
    $scope.segmentationTypes = SEGMENTATION_KIND;
    $scope.intersectionCount = null;
    $scope.unionCount = null;
    $scope.selectedSegments = [];
    $scope.addedSegments = [];
    
    $scope.filter = {
        segments: '',
        addedSegments: ''
    };
    $scope.previewState = { opened: false };
    $scope.isPublished = false;
    $scope.areAllAvailableSegmentsAdded = true;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.MESSAGE_STATES = MESSAGE_STATES;

    $scope.buttonBarTitle = 'messages.stepper.recipients';

    var publicationId = $routeParams.id;
    var areRecipientsInitialized = true;

    //******************************************** Methods **************************************\\

    this.$onDestroy = function() {
        if (DataService.initialRecipients) {
            delete DataService.initialRecipients;
        }
    }

    function getAddedUserIndexById(addedUserId) {
        var selectedUsersLength = !!$scope.recipients.users ? $scope.recipients.users.length : 0;
        for (var index = 0; index < selectedUsersLength; index++) {
            if ($scope.recipients.users[index].id === addedUserId) {
                return index;
            }
        }
        return -1;
    }

    function initializeSelectedUsersComboBox() {
        $scope.selectedUsersOptions = UsersService.getEmployeeOptions();
        $scope.selectedUsersOptions.valuePrimitive = false;

        $scope.selectedUsersOptions.change = function() {
            if (!!$scope.selectedUser.user) {
                var selectedUserIndex = getAddedUserIndexById($scope.selectedUser.user.id);
                if (selectedUserIndex === -1) {
                    if (!$scope.recipients.users) {
                        $scope.recipients.users = [];
                    }
                    $scope.recipients.users.push($scope.selectedUser.user);
                }
                $scope.selectedUser.user = null;
                onRecipientsFormChange();
            }
        };
    }

    function init () {
        DataService.findOne(publicationId)
            .then(function (response) {
                $scope.publication = response.data;
                if ($scope.publication.gallery && $scope.publication.gallery.images && $scope.publication.gallery.images.length > 0) {
                    reorderGallery();
                }
                if (response.data.kind) {
                    $scope.contentType = response.data.kind;
                }

                if ($scope.contentType === CONTENT_KIND.DIALOGUE || $scope.contentType === CONTENT_KIND.SURVEY) {
                    DataService.setQuestionnaireContentLanguage($scope.publication);
                }

                $scope.publicationKindIcon = PublicationService.getPublicationKindIcon($scope.contentType);
                $scope.headerTitle = getPublicationTitle(response.data);
                $scope.isPublished = parseInt(response.data.state) === parseInt(MESSAGE_STATES.PUBLISHED);

                Array.prototype.push.apply($scope.addedSegments, response.data.segments);
                $scope.filteredAddedSegments = angular.copy($scope.addedSegments);

                if (!response.data.population) {
                    $scope.publication.population = POPULATION.ALL;
                    // Set recipients data as empty for the first time to allow saving the default state
                    areRecipientsInitialized = false;
                } else if (response.data.population === POPULATION.SEGMENTS && response.data.segments.length === 0) {
                    $scope.publication.population = POPULATION.NO_GROUP;
                }

                $scope.recipients = angular.copy(UsersGroupsService.getRecipients(response.data));
                $scope.recipients.segments = $scope.recipients.segments.sort(function(a, b) {return a.id - b.id});

                getRecipientsCount();
                onRecipientsFormChange();

                DataService.setUsersAvatarTooltips($scope.$parent.messageForm.message.users);
            });

        $scope.getFilteredSegments();

        initializeSelectedUsersComboBox();
    }

    function validateRecipientsForm() {
        $scope.messageForm.valid = ($scope.recipients.population == POPULATION.ALL || $scope.recipients.population == POPULATION.NO_GROUP) || 
                                    ($scope.recipients.population == POPULATION.SEGMENTS && $scope.addedSegments.length > 0) || 
                                    ($scope.recipients.population == POPULATION.SELECTED_USERS && $scope.recipients.users.length > 0);
    }

    function onRecipientsFormChange() {
        validateRecipientsForm();
        $scope.messageForm.isFormDirty = dataHasChanged();
    } 

    function dataHasChanged() {
        return !angular.equals($scope.recipients, DataService.initialRecipients) || 
                !angular.equals($scope.addedSegments.sort(function(a, b) {return a.id - b.id}), DataService.initialRecipients.segments);
    }

    function reorderGallery() {
        $scope.publication.gallery.images = $scope.publication.gallery.images.sort( function (a, b) { return a.order - b.order; } );
    }

    function getDialogTitle(dialog) {
        return dialog.name;
    }

    function getMessageTitle(message) {
        var language = LanguageService.getMainContentLanguage(message.contents);
        return message.contents && message.contents[language] && message.contents[language].title;
    }

    function getPublicationTitle(data) {
        return getDialogTitle(data) || getMessageTitle(data);
    }

    function formatRecipients() {
        var recipients = {};
        switch ($scope.publication.population) {
            case POPULATION.ALL:
                recipients = UsersGroupsService.getInitialRecipientsAllKind();
                break;
            case POPULATION.SEGMENTS:
                recipients = UsersGroupsService.getInitialRecipientsSegmentsKind($scope.addedSegments, $scope.recipients.segmentationKind);
                $scope.areAllAvailableSegmentsAdded = UsersGroupsService.isAddAllSegmentsLinkEnabled($scope.segments, $scope.addedSegments);
                break;
            case POPULATION.NO_GROUP:
                recipients = UsersGroupsService.getInitialRecipientsSegmentsKind([], $scope.recipients.segmentationKind);
                break;
            case POPULATION.SELECTED_USERS:
                recipients = UsersGroupsService.getInitialRecipientsSelectedUsersKind($scope.recipients.users);
                break;
            default:
                break;
        }

        setInitialRecipientsData();
        return recipients;
    }

    function setInitialRecipientsData() {
        if (!DataService.initialRecipients) {
            DataService.initialRecipients = angular.copy($scope.recipients);
        }
    }

    function clearSegmentationKindCount() {
        $scope.intersectionCount = null;
        $scope.unionCount = null;
    }

    function getRecipientsCount() {
        var recipients = formatRecipients();
        clearSegmentationKindCount();

        $scope.isUserCountLoading = true;
        if(recipients.population !== POPULATION.SELECTED_USERS) {
            DataService.getRecipientsCount(publicationId, recipients).then(function (response) {
                $scope.unionCount = response.data.unionCount;
                $scope.intersectionCount = ($scope.addedSegments.length >= 2) ? response.data.intersectionCount : null;
                $scope.isUserCountLoading = false;
            });
        }

    }

    function searchAddedSegments() {
        $scope.filteredAddedSegments = $filter('filter')($scope.addedSegments, $scope.filter.addedSegments);
    }

    function setRecipients(redirectToNextStep) {
        var recipients = formatRecipients();
        DataService.setRecipients(publicationId, recipients).then(function (response) {
            // Update message data in parent data model           
            $scope.$parent.messageForm.message = response.data;
            if ($scope.$parent.messageForm.message.users) {
                $scope.$parent.messageForm.message.users =  DataService.orderRecipientsUsers($scope.$parent.messageForm.message.users);
            }
            DataService.setUsersAvatarTooltips($scope.$parent.messageForm.message.users);

            $scope.recipients = angular.copy(UsersGroupsService.getRecipients(response.data));
            $scope.recipients.segments = $scope.recipients.segments.sort(function(a, b) {return a.id - b.id});

            DataService.initialRecipients = angular.copy($scope.recipients);
            onRecipientsFormChange();

            $rootScope.$broadcast('dialenga:loadingEnd');
            var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);

            if (redirectToNextStep) {
                navigateToNextView();
            }
        });
    }

    function navigateToNextView() {
        if (DataService.initialRecipients) {
            delete DataService.initialRecipients;
        }
        document.getElementById('shipment-tab').click();
        $rootScope.$broadcast('dialenga:loadingEnd');
    }

    $scope.getFilteredAddedSegments = function(text) {
        $scope.filter.addedSegments = text;
        searchAddedSegments();
    };

    $scope.getFilteredSegments = function(text) {
        $scope.filter.segments = text || '';
        $scope.isSyncing = true;
        var options = {
            name: $scope.filter.segments,
            active: true
        };
        SegmentService.findAll(options).then(function (response) {
            $scope.isSyncing = false;
            if (options.name === $scope.filter.segments) {
                $scope.segments = response.data.content;
                $scope.areAllAvailableSegmentsAdded = UsersGroupsService.isAddAllSegmentsLinkEnabled($scope.segments, $scope.addedSegments);
            }
        });
    };

    $scope.setPopulationKind = function (population) {
        $scope.publication.population = population;
        $scope.recipients.population = population;

        getRecipientsCount();
        onRecipientsFormChange();
    };

    $scope.selectSegmentFromAvailables = function (segment, $event) {
        $scope.selectedSegments = UsersGroupsService.addAvailableSegmentToSelected($scope.selectedSegments, segment, $event);
    };

    $scope.doubleClickedSegment = function (segment) {
        $scope.addedSegments.push(segment);
        searchAddedSegments();
        document.getElementById(segment.id).classList.add("added")
        getRecipientsCount();
        onRecipientsFormChange();
    };

    $scope.setAddedSegments = function(segmentId) {
        var segmentsAdded = $scope.addedSegments.map(function (segment) { return segment.id; } );
        return segmentsAdded.indexOf(segmentId) !== -1
    };

    $scope.addSelectedSegments = function () {
        if (document.querySelectorAll('#available-segments-box .selected').length > 0) {
            Array.prototype.push.apply($scope.addedSegments, $scope.selectedSegments);
            $scope.selectedSegments = [];
            searchAddedSegments();
            getRecipientsCount();
            angular.element(document.querySelectorAll("#available-segments-box .selected")).addClass("added").removeClass("selected");
            onRecipientsFormChange();
        }
    };

    $scope.removeAddedItem = function (segment) {
        document.getElementById(segment.id).classList.remove("added")

        var segmentIndex = UsersGroupsService.getIndexOfItemInSegments($scope.addedSegments, segment.id);
        if (segmentIndex !== -1) {
            $scope.addedSegments.splice(segmentIndex, 1);
            getRecipientsCount();
        }
        searchAddedSegments();
        if($scope.addedSegments.length <= 1){
            $scope.recipients.segmentationKind = $scope.segmentationTypes.UNION;
        }
        onRecipientsFormChange();
    };

    $scope.removeAllAddedSegments = function(){
        $scope.addedSegments = [];
        $scope.filter.addedSegments = '';
        searchAddedSegments();
        getRecipientsCount();

        angular.element(document.querySelectorAll("#available-segments-box .added")).removeClass("added");
        $scope.recipients.segmentationKind = $scope.segmentationTypes.UNION;
        onRecipientsFormChange();
    };

    $scope.addAllSegments = function(){
        $scope.addedSegments = angular.copy($scope.segments);
        searchAddedSegments();
        getRecipientsCount();

        angular.element(document.querySelectorAll("#available-segments-box .available-segment")).addClass("added").removeClass("selected");
        onRecipientsFormChange();
    };

    $scope.submitRecipients = function (redirectToNextStep) {
        var isFormDirty = dataHasChanged();
        if (!areRecipientsInitialized || isFormDirty) {
            if (!$scope.publication.population === POPULATION.ALL &&
                UsersGroupsService.areNoRecipients($scope.recipients.segmentationKind, $scope.unionCount, $scope.intersectionCount)) {
                ModalService.openConfirmationModal({
                    title: 'segments.modal.noEstimatedUsers.title',
                    message: 'segments.modal.noEstimatedUsers.message',
                    messageData: {},
                    focusCancel: true,
                    hideCancelButton: false,
                    accept: function () {
                        setRecipients(redirectToNextStep);
                    }
                });
            }
            else {
                setRecipients(redirectToNextStep);
            }
        } else {
            navigateToNextView();
        }
    };

    $scope.removeAddedUser = function (addedUserId) {
        var addedUserIndex = getAddedUserIndexById(addedUserId);
        if (addedUserIndex !== -1) {
            $scope.recipients.users.splice(addedUserIndex, 1);
        }
        onRecipientsFormChange();
    };

    $scope.removeAllAddedUsers = function () {
        $scope.recipients.users = [];
        onRecipientsFormChange();
    };

    $scope.goBack = function() {
        $scope.messageForm.isFormDirty = dataHasChanged();
        $scope.loadTabContent(MESSAGE_TABS.CONTENT);
    };

    $scope.$on('dialenga:savePublicationFormAndStay', function(event, args) {
        if (args.tab === MESSAGE_TABS.RECIPIENTS) {
            $scope.submitRecipients();
        }
    });

    init();
}]);
