'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.oAuthAuthenticationService
 * @description
 * # oAuthAuthenticationService
 */
angular.module('dialengaExeviBackoffice').service('OAuthAuthenticationService',
    ['$rootScope', '$http', '$q', '$window', 'authService', 'AuthenticationService', 'CompaniesService', 'Session', 'SharedDataService',
    function ($rootScope, $http, $q, $window, authService, AuthenticationService, CompaniesService, Session, SharedDataService) {

    var self = this;

    var redirectUri = SharedDataService.backofficeUrl + 'oauth-success';

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    this.getLoginConfiguration = function (company) {
        if (sessionStorage.loginConfiguration) {
            return $q.resolve(JSON.parse(sessionStorage.loginConfiguration));
        } else {
            company = company || localStorage.getItem('lastUserLoginBrand');
            var ssoConfigId = localStorage.getItem('ssoConfigId');
            if (!company || !ssoConfigId) {
                return $q.reject();
            }
            return CompaniesService.getCompanyLoginConfiguration(company).then(function (response) {
                if (!response.loginConfiguration) {
                    return $q.reject();
                }
                var ssoConfigs = response.loginConfiguration.ssoConfigs;
                for (var index = 0; index < ssoConfigs.length; index++) {
                    if (ssoConfigs[index].id == ssoConfigId) {
                        localStorage.removeItem('ssoConfigId');
                        sessionStorage.loginConfiguration = JSON.stringify(ssoConfigs[index]);
                        return $q.resolve(ssoConfigs[index]);
                    }
                }
                if (ssoConfigs.length > 0) {
                    return $q.resolve(ssoConfigs[0]);
                } else {
                    return $q.reject();
                }
            });
        }
    };

    function uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, function (c) {
            return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
        });
    }

    function parseExtraParams(extraParams) {
        if (!extraParams) {
            return '';
        }
        var appVersion = '7.2.0';
        if (extraParams.indexOf('APP_VERSION') !== -1) {
            extraParams = extraParams.replace('APP_VERSION', appVersion);
        }
        if (extraParams.indexOf('ID_SO') !== -1) {
            extraParams = extraParams.replace('ID_SO', 2);
        }
        return '&' + extraParams;
    }

    function getClientId(clientId) {
        if (!clientId) {
            return;
        }
        return clientId.split(',')[0];
    }

    function getAuthorizeUrl(loginConfiguration) {
        var url = loginConfiguration.loginURL.replace(/\/$/, '');
        var scope = loginConfiguration.scope || 'openid+user.read+profile';
        var nonce = uuidv4();
        var clientId = getClientId(loginConfiguration.clientId);
        var responseType = loginConfiguration.responseType ? loginConfiguration.responseType : 'id_token';
        url += '?client_id=' + clientId + '&response_type=' + responseType + '&redirect_uri=' + redirectUri +
                '&response_mode=fragment&scope=' + scope;
        if (loginConfiguration.state) {
            // TODO: generate random string, save in session for later validation
            url += '&state=' + loginConfiguration.state;
        }
        if (loginConfiguration.nonce) {
            url += '&nonce=' + loginConfiguration.nonce;
        } else {
            url += '&nonce=' + nonce;
        }
        url += parseExtraParams(loginConfiguration.extraParams);
        console.log(url);
        return url;
    }

    this.authorize = function (loginConfiguration) {
        var url = getAuthorizeUrl(loginConfiguration);
        $window.open(url, '_self');
    };

    function customTransform(data) {
        var str = [];
        for (var p in data) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
        }
        return str.join("&");
    }

    function initUserSessionAndRedirect(user) {
        Session.create(user);
        $rootScope.account = Session;
        $rootScope.setLanguage(user.language);

        authService.loginConfirmed(user);
    }

    function clearUserSessionAndRedirect() {
        Session.invalidate();
        authService.loginCancelled();
    }

    function authenticateUserInternally(company, idToken, username, clientId) {
        return $http({
            url: SharedDataService.apiUrl + '/users/oauth2-authentication',
            method: 'POST',
            data: {
                c: company,
                jwtToken: idToken,
                username: username,
                clientId: getClientId(clientId)
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            transformRequest: customTransform,
            ignoreAuthModule: 'ignoreAuthModule',
            ignoreErrorInterceptor: true
        });
    }

    function getUsernameFromToken(token, usernameClaim, regex, usernameGroup) {
        var payload = parseJwt(token);
        if (!payload || !usernameClaim || !payload[usernameClaim]) {
            return false;
        }
        if (regex && usernameGroup) {
            var username = payload[usernameClaim].match(regex);
            return (!!username && username.length > usernameGroup) ? username[usernameGroup] : payload[usernameClaim];
        } else {
            return payload[usernameClaim];
        }
    }

    function authenticateUser (idToken, company, loginConfig) {
        var username = getUsernameFromToken(idToken, loginConfig.usernameClaim, loginConfig.regex, loginConfig.usernameGroup);
        if (username) {
            localStorage.setItem('lastUserLoginBrand', company);
            authenticateUserInternally(company, idToken, username, loginConfig.clientId).then(function (response) {
                AuthenticationService.saveAuthorizationToken(response);
                initUserSessionAndRedirect(response.data);
            }, function (error) {
                console.error(error);
                // TODO: if error.status === 401 -> show message to user
                clearUserSessionAndRedirect();
                $rootScope.externalLoginFailed = true;
            });
        }
    }

    self.authenticate = function (authorizationCode, company) {
        self.getLoginConfiguration(company).then(function (loginConfig) {
            authenticateUser(authorizationCode, company, loginConfig);
        }, function (error) {
            console.error(error);
            clearUserSessionAndRedirect();
            $rootScope.externalLoginFailed = true;
        });
    };
}]);