'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.globalService
 * @description
 * # globalService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('GlobalService',
    ['$location', '$q', '$rootScope', '$timeout', '$translate','$anchorScroll', 'tmhDynamicLocale', '$uibModalStack', '$window', 'authService',
        'AuthenticationService', 'FormUtilsService', 'KendoUtilsService', 'LocalizedErrorsService', 'ModalService', 'NotificationService',
        'Session', 'SharedDataService', 'SUPPORT_MAIL', 'USER_ROLES',
        function ($location, $q, $rootScope, $timeout, $translate,$anchorScroll, tmhDynamicLocale, $uibModalStack, $window, authService,
            AuthenticationService, FormUtilsService, KendoUtilsService, LocalizedErrorsService, ModalService, NotificationService,
            Session, SharedDataService, SUPPORT_MAIL, USER_ROLES) {

            var self = this;
            var supportedBrowsers = ['Chrome', 'Firefox', 'Safari'];
            var nonSupportedBrowsers = [];
            var initialWantedUrl = '/';

            function getParsedLanguageForMomentLibrary(language) {
                if (language.toLowerCase() === 'ca_es') {
                    return 'ca';
                } else if (language.toLowerCase() === 'eu_es') {
                    return 'eu';
                } else if (language.toLowerCase() === 'zh') {
                    return 'zh-cn';
                } else {
                    return language;
                }
            }

            function setInlineInputLabelAnimation() {
                $rootScope.focusInput = function ($event) {
                    var parentElement = $event.currentTarget.parentElement;
                    parentElement.classList.add('active');
                    parentElement.classList.add('focus');
                };

                $rootScope.blurInput = function ($event) {
                    var parentElement = $event.currentTarget.parentElement;
                    if ($event.currentTarget.value == "") {
                        parentElement.classList.remove("active");
                    }
                    parentElement.classList.remove("focus");
                };
            }

            function initializeGlobalVariables() {
                $rootScope.isSidemenuVisible = true;
                $rootScope.logout = AuthenticationService.logout;
                $rootScope.supportMail = SUPPORT_MAIL;
                $rootScope.environmentURL = SharedDataService.backofficeUrl;
                $rootScope.readableEnvironmentURL = $rootScope.environmentURL.replace(/^http(s?):\/\//, '').replace(/\/$/, '');
                $rootScope.webappURL = SharedDataService.webappUrl;
                $rootScope.readableWebappURL = SharedDataService.webappUrl.replace(/^http(s?):\/\//, '').replace(/\/$/, '');

                $rootScope.navigateToAnchor = function (anchor) {
                    $timeout(function () {
                        $location.hash(anchor);
                        $anchorScroll();
                    });
                };

                $rootScope.openTermsAndConditions = function () {
                    $rootScope.openInNewTab('/ng1/assets/DIALENGA(EXEVI S.L.). Términos y condiciones Rev.2020-10-20.pdf');
                };

                $rootScope.openPrivacyPolicy = function () {
                    $rootScope.openInNewTab('/ng1/assets/DIALENGA(EXEVI S.L.). TRATAMIENTO DATOS PERSONALES Rev 2020-10-20.pdf');
                };

                $rootScope.openInNewTab = function (url) {
                    if ($rootScope.account.language == 'en' || $rootScope.account.language == 'fr') {
                        url += '-' + $rootScope.account.language;
                    } 
                    $window.open(url, '_blank');
                }

                setInlineInputLabelAnimation();

                $rootScope.goBackAndCheckForModifications = function (isFormDirty, url, callback) {
                    if (isFormDirty) {
                        ModalService.openConfirmationModal({
                            title: 'global.modal.changes.title',
                            message: 'global.modal.changes.message',
                            acceptButtonText: 'global.modal.changes.exit',
                            accept: function () {
                                if (callback)
                                    callback();
                                else
                                    $location.path(url);
                            },
                            cancel: function() {
                                $rootScope.$broadcast('dialenga:loadingEnd');
                            }
                        });
                    } else {
                        if (callback)
                            callback();
                        else
                            $location.path(url);
                    }
                };

                $rootScope.checkUpdatesAndChangeTab = function(isFormDirty, callback, tab) {
                    if (isFormDirty) {
                        ModalService.openConfirmationModal({
                            title: 'global.modal.changes.title',
                            message: 'global.modal.changes.messageContinue',
                            acceptButtonText: 'global.modal.changes.continueAndDiscard',
                            accept: function () {
                                callback(tab);
                               
                            },
                            cancel: function() {
                            }
                        });
                    } else {
                        callback(tab);
                    }
                }

                $window.showConfirmationModal = function (params) {
                    ModalService.openConfirmationModal(params);
                };

                $rootScope.setLanguage = function (language) {
                    KendoUtilsService.initializeKendoMessages(language);

                    self.changeLanguage(language);

                    var parsedLanguage = getParsedLanguageForMomentLibrary(language);
                    moment.locale(parsedLanguage);

                    var selectedLanguage = language.toLowerCase();
                    tmhDynamicLocale.set(selectedLanguage);
                };

                $rootScope.openNewFeaturesAdvice = function () {
                    SharedDataService.checkForNewFeaturesAdvice(Session.language, 'popover');
                };

                $rootScope.goToDialengaFeedbackForm = function () {
                    var userId = Session.id;
                    var url = 'https://dialenga.typeform.com/to/DqN4uWgY#id_usuario=' + userId;
                    $window.open(url, '_blank');
                }
            }

            self.redirectUserToHomeBasedOnRoles = function () {
                if (!Session.roles || Session.roles.length === 0
                    || Session.roles.indexOf(USER_ROLES.admin) !== -1
                    || Session.roles.indexOf(USER_ROLES.publisher) !== -1
                    || Session.roles.indexOf(USER_ROLES.manager) !== -1) {
                    $location.url('/').replace();
                } else if (Session.roles.indexOf(USER_ROLES.ftrayResponsible) !== -1) {
                    $location.url('/requests').replace();
                } else if (Session.roles.indexOf(USER_ROLES.documentsManager) !== -1) {
                    $location.url('/documents').replace();
                } else if (Session.roles.indexOf(USER_ROLES.humanResources) !== -1) {
                    $location.url('/timeoff/requests').replace();
                } else if (Session.roles.indexOf(USER_ROLES.employeeManager) !== -1) {
                    $location.url('/users').replace();
                } else if (Session.roles.indexOf(USER_ROLES.ftrayManager) !== -1) {
                    $location.url('/global-settings').replace();
                } else if (Session.roles.indexOf(USER_ROLES.chatroomManager) !== -1) {
                    $location.url('/global-settings').replace();
                } else {
                    $location.url('/').replace();
                }
            };

            function initializeCustomEvents() {
                // Call when the the client is confirmed
                $rootScope.$on('event:auth-loginConfirmed', function () {
                    $rootScope.authenticated = true;
                    localStorage.setItem('lastUserLoginBrand', ($location.path() === "/oauth-success") ? Session.externalLoginBrand : '');
                    if ($location.path() === "/login" || $location.path() === "/oauth-success") {
                        var urlParams = $location.search();
                        if (!!urlParams.redirect) {
                            $location.url(urlParams.redirect).replace();
                        } else {
                            self.redirectUserToHomeBasedOnRoles();
                        }
                    }
                    SharedDataService.checkForNewFeaturesAdvice(Session.language);
                });

                // Call when the 401 response is returned by the server
                $rootScope.$on('event:auth-loginRequired', function () {
                    // Close all opened modals
                    $uibModalStack.dismissAll();

                    $rootScope.account = null;
                    $rootScope.authenticated = false;

                    authService.loginCancelled();

                    // If previous route required authentication, loginRequired event should fire the notification of the session closed due to inactivity
                    if ($rootScope.previousRoute && !AuthenticationService.isGloballyAuthorized($rootScope.previousRoute.access.authorizedRoles)) {
                        NotificationService.closeAllOpenedNotifications();
                        var sessionClosedInfoMessage = $translate.instant('login.messages.info.sessionClosed');
                        NotificationService.notifyInfo(sessionClosedInfoMessage, true);
                    }
                    if (!!initialWantedUrl && initialWantedUrl !== '/' && initialWantedUrl !== '/login') {
                        $location.search('redirect', initialWantedUrl);
                        initialWantedUrl = '/';
                    }
                });

                // Call when the user logs out
                $rootScope.$on('event:auth-loginCancelled', function () {
                    var lastUserLoginBrand = localStorage.getItem('lastUserLoginBrand');
                    if (lastUserLoginBrand) {
                        $location.path('/login').search({ c: lastUserLoginBrand }).replace();
                    } else {
                        $location.path('/login').replace();
                    }
                    Session.invalidate();
                });

                // Call when an action is improcesable
                $rootScope.$on('event:error-unprocessableEntity', function (event, args) {
                    if (args.localizedError) {
                        ModalService.openLocalizedErrorModal(args.localizedError);
                    } else {
                        ModalService.openHttpErrorModal([], args.status, args.message);
                    }
                });

                $rootScope.$on('dialenga:loadingEnd', function () {
                    $timeout(function () {
                        FormUtilsService.finishSubmitAction();
                    }, 100);
                });

                $rootScope.$on('dialenga:updateModulesInSideMenu', function () {
                    $timeout(function () {
                        FormUtilsService.finishModulesUpdate();
                    }, 100);
                });
            }

            function routeChangeStart() {
                var currentBrowser = getCurrentBrowser();
                $rootScope.$on('$routeChangeStart', function (event, next, previous) {
                    initialWantedUrl = $location.path();
                    if (nonSupportedBrowsers.indexOf(currentBrowser) !== -1 && next.$$route.originalPath !== '/browser-not-supported') {
                        event.preventDefault();
                        $location.path("/browser-not-supported");
                    } else {
                        if ($rootScope.currentRoute && $rootScope.currentRoute.originalPath === '/login'
                            && supportedBrowsers.indexOf(currentBrowser) !== -1) {
                            $rootScope.$broadcast('dialenga:closeOpenedNotifications');
                        }
                        if (next.$$route) {
                            if (previous && previous.$$route) {
                                KendoUtilsService.preserveGridFiltersIfDirectDescendant(previous.$$route.originalPath, next.$$route.originalPath);
                            }
                            if (next.$$route.access) {
                                next.resolve = next.resolve || {};
                                if (!next.resolve.authorization) {
                                    next.resolve.authorization = function () {
                                        return AuthenticationService.authorize(next.$$route.access.authorizedRoles, next.$$route.originalPath).then(function (response) {
                                            if (next.$$route.module) {
                                                var isModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(next.$$route.module);
                                                if (!isModuleActive) {
                                                    event.preventDefault();
                                                    $location.path("/forbidden").replace();
                                                }
                                            }
                                            return response;
                                        });
                                    };
                                }
                            }
                        }
                    }
                });
            }

            function routeChangeSuccess() {
                $rootScope.$on('$routeChangeSuccess', function (route, view) {
                    $rootScope.mainTitle = view.$$route ? view.$$route.title : null;
                    $rootScope.isHeaderHidden = false;

                    if (view.$$route) {
                        $rootScope.previousRoute = $rootScope.currentRoute;
                        $rootScope.currentRoute = view.$$route;
                        $rootScope.currentRoute.path = $location.path();

                        var viewsWithoutSidemenu = ['/forbidden', '/not-found', '/install-via-testflight', '/html-components'];
                        $rootScope.isSidemenuHidden = viewsWithoutSidemenu.indexOf($rootScope.currentRoute.originalPath) !== -1;
                    } else {
                        $rootScope.previousRoute = undefined;
                        $rootScope.currentRoute = undefined;
                    }

                    if (!$location.hash()) {
                        $timeout(function () {
                            $window.scrollTo(0, 0);
                        }, 200);
                    }
                });
            }

            function routeChangeError() {
                $rootScope.$on("$routeChangeError", function (clear, next, previous, error) {
                    if (error instanceof AuthorizationError) {
                        $location.path("/forbidden").replace();
                    } else if (error instanceof AlreadyLoggedError) {
                        if ($rootScope.currentRoute && $rootScope.currentRoute.path && $rootScope.currentRoute.path !== '/login') {
                            $location.path($rootScope.currentRoute.path).replace();
                        } else {
                            self.redirectUserToHomeBasedOnRoles();
                        }
                    } else {
                        $location.path("/not-found").replace();
                    }
                });
            }

            function translateChangeEnd() {
                $rootScope.$on('$translateChangeEnd', function (event) {
                    kendo.ui.FilterMenu.prototype.options.messages.clear = $translate.instant('global.modal.clear');
                    KendoUtilsService.initializeTextEditor();
                });
            }

            function initializeRouteChangeEvents() {
                routeChangeStart();
                routeChangeSuccess();
                routeChangeError();
            }

            this.initialize = function () {
                KendoUtilsService.initializeTextEditor();
                KendoUtilsService.initializeTooltip();
                initializeGlobalVariables();
                initializeCustomEvents();
                initializeRouteChangeEvents();
                translateChangeEnd();
            };

            this.initializeLanguage = function (language) {
                var parsedLanguage = getParsedLanguageForMomentLibrary(language);
                moment.locale(parsedLanguage);

                var selectedLanguage = language.toLowerCase();
                tmhDynamicLocale.set(selectedLanguage);
            };

            this.showHidePassword = function (value) {
                return (value == 'password') ? 'text' : 'password';
            };

            this.changeLanguage = function (language) {
                $translate.use(language);
                SharedDataService.changeTranslationsLanguage(language);
            };

            this.getEstimatedReadingTime = function (publicationText) {
                if (!publicationText) { return; }
                var readingSpeed = 200;    
                var cleanText = publicationText.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, ' ').replace(/\s\s+/g, ' ').trim();
                var words = cleanText.split(' ').length;
                var minutes = Math.round(words / readingSpeed);

                return (minutes > 0) ? minutes : 0;
            };

            function getCurrentBrowser() {
                var browserName;
                if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
                    // Opera 8.0+
                    browserName = 'Opera';
                } else if (typeof InstallTrigger !== 'undefined') {
                    // Firefox 1.0+
                    browserName = 'Firefox';
                } else if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))) {
                    // Safari 3.0+ "[object HTMLElementConstructor]"
                    browserName = 'Safari';
                } else if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
                    // Chrome 1 - 71
                    browserName = 'Chrome';
                } else {
                    if (/*@cc_on!@*/false || !!document.documentMode) {
                        // Internet Explorer 6-11
                        browserName = 'IE';
                    } else if (!!window.StyleMedia) {
                        // Edge 20+
                        browserName = 'Edge';
                    }
                }
                showBrowserAlertIfNotSupported(browserName);
                return browserName;
            }

            function showBrowserAlertIfNotSupported(browserName) {
                if (!browserName) {
                    return;
                }

                if (supportedBrowsers.indexOf(browserName) === -1 && nonSupportedBrowsers.indexOf(browserName) === -1) {

                    // Set current language in order to wait until translation is available
                    var currentLanguage = $translate.proposedLanguage() || $translate.use();
                    currentLanguage = currentLanguage.replace(new RegExp("\"", 'g'), "");
                    $translate.use(currentLanguage).then(function () {
                        var partiallySupportedMessage = $translate.instant('browserSupport.partiallySupported');
                        NotificationService.notifyFullWarning(partiallySupportedMessage);
                    });

                }
            }

            $window.getUserSession = function() {
                return Session;
            };

            $window.redirectToUrl = function (url) {
                if (url === '/') {
                    self.redirectUserToHomeBasedOnRoles();
                } else {
                    $location.$$search = {};
                    $location.path(url);
                }
            };

            $window.notifySuccess = function(message, delay) {
                NotificationService.notifySuccess(message, delay);
            };

            this.redirectToDashboardIfUserIsLogged = function () {
                return AuthenticationService.getUserAuthenticationStatus().then(function(isUserAuthenticated) {
                    if (!isUserAuthenticated) {
                        return $q.resolve();
                    } else {
                        self.redirectUserToHomeBasedOnRoles();
                        return $q.reject();
                    }
                });
            };

            $window.getLocalizedMonthsNames = function() {
                var months = moment.months().map(function (month, index) {
                    return { value: index + 1, name: month };
                });
                return months;
            }

            $window.showLocalizedError = LocalizedErrorsService.showLocalizedError;

        }]);
