(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:previewStateSelector
     * @description
     * # previewStateSelector
     */
    angular.module('dialengaExeviBackoffice').component('previewStateSelector', {
        bindings: {
            currentPreviewState: '=',
            pushEnabled: '<',
            publication: '<',
            isPill: '<',
            isHighlighted: '<',
            hideTimeline: '<?',
            isGallery: '<'
        },
        templateUrl: '/ng1/components/shared/publication-preview/preview-state-selector.html',
        controllerAs: 'vm',
        controller: ['$translate', 'PublicationService', 'Session', 'DEVICE_PREVIEW_STATES', function ($translate, PublicationService, Session, DEVICE_PREVIEW_STATES) {
            var vm = this;
            
            vm.DEVICE_PREVIEW_STATES = DEVICE_PREVIEW_STATES;
            vm.Session = Session;
            vm.timelineTitle = '';

            this.$onInit = function () {
                if (!vm.currentPreviewState || (vm.hideTimeline && vm.currentPreviewState == DEVICE_PREVIEW_STATES.TIMELINE)) {
                    vm.setPreviewState(DEVICE_PREVIEW_STATES.PREVIEW);
                }

                if (vm.isPill) {
                    vm.timelineTitle = (vm.Session.customNamings && vm.Session.language && vm.Session.customNamings['knowledgeCenter.title'] && vm.Session.customNamings['knowledgeCenter.title'][vm.Session.language]) ?
                        vm.Session.customNamings['knowledgeCenter.title'][vm.Session.language] : $translate.instant('knowledgecenter.title');
                } else {
                    vm.timelineTitle = (vm.Session.customNamings && vm.Session.language && vm.Session.customNamings['timeline.title'] && vm.Session.customNamings['timeline.title'][vm.Session.language]) ?
                        vm.Session.customNamings['timeline.title'][vm.Session.language] : $translate.instant('messages.appTitle');
                }
            };

            vm.setPreviewState = function (state) {
                vm.currentPreviewState = PublicationService.devicePreviewState.state = state;
            };

            vm.getDotView = function(state) {
                var dotView = 1;
                switch (state) {
                    case DEVICE_PREVIEW_STATES.HIGHLIGHTED:
                        dotView = vm.pushEnabled ? 2 : 1;
                        break;
                    case vm.DEVICE_PREVIEW_STATES.TIMELINE:
                        dotView = vm.pushEnabled && vm.isHighlighted ? 3 : (vm.pushEnabled || vm.isHighlighted ? 2 : 1);
                        break;
                    case vm.DEVICE_PREVIEW_STATES.PREVIEW:
                        if (!vm.hideTimeline) {
                            dotView = vm.pushEnabled && vm.isHighlighted ? 4 : (vm.pushEnabled || vm.isHighlighted ? 3 : 2);
                        } else {
                            dotView = 1;
                        }
                        break;
                    case vm.DEVICE_PREVIEW_STATES.GALLERY:
                        if (!vm.hideTimeline) {
                            dotView = vm.pushEnabled && vm.isHighlighted ? 5 : (vm.pushEnabled || vm.isHighlighted ? 4 : 3);
                        }
                        break;
                    case vm.DEVICE_PREVIEW_STATES.PUSH:
                    default:
                        break;
                };
                return dotView;
            };
        }]
    });
})();
