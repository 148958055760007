'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:percentage
 * @function
 * @description
 * # percentage
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('percentage', function () {
    return function (value, total) {
        var percentage = 0;
        var baseValue = parseInt(value);
        if (value && baseValue !== 0) {
            if (total) {
                percentage = (baseValue * 100) / parseInt(total);
                percentage = Math.round(percentage * 100 / 100);
            } else {
                percentage = value.toFixed(2);
            }
        }
        if (percentage < 0) percentage = 0;
        if (percentage > 100) percentage = 100;
        return +percentage + '%';
    };
});
