'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.modalService
 * @description
 * # modalService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ModalService', ['$rootScope', '$translate', '$uibModal',
    function($rootScope, $translate, $uibModal) {

        function emptyCallback() {
            // Empty function in error callback to avoid error in console when clicking on cancel button or outside the modal
            // Error description: "Possibly unhandled rejection: cancel"
        }

        function getTranslationText(params, textItem) {
            var translateInterpolation, translationString;
            if (params.isTextInterpolated) {
                translateInterpolation = 'messageformat';
            }
            if (params.messageData) {
                translationString = $translate.instant(textItem, params.messageData, translateInterpolation);
            } else {
                translationString = $translate.instant(textItem, {}, translateInterpolation);
            }
            return translationString;
        }

        this.openModal = function(params) {
            $uibModal.open({
                templateUrl: params.templateUrl,
                controller: params.controller,
                resolve: {
                    params: function() {
                        return params.resolveParams;
                    }
                },
                controllerAs: 'vm',
                size: params.size || 'md',
                windowClass: 'fade',
            }).result.then(params.accept, params.cancel || emptyCallback);
        };

        this.openConfirmationModal = function(params) {
            var title = getTranslationText(params, params.title);
            var message = getTranslationText(params, params.message);
            var modal = $uibModal.open({
                templateUrl: '/ng1/components/shared/confirmation-modal/confirmation-modal.html',
                controller: 'ConfirmationModalController',
                size: params.size ? params.size : 'md',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            title: title,
                            message: message,
                            acceptButtonText: params.acceptButtonText || 'global.modal.accept',
                            acceptButtonTextClass: params.acceptButtonTextClass || 'button--secondary',
                            cancelButtonText: params.cancelButtonText || 'global.modal.cancel',
                            focusCancel: params.focusCancel !== false, // set to true by default
                            hideCancelButton: params.hideCancelButton || false,
                            closeModalOnlyFromTopButton: !!params.closeModalOnlyFromTopButton,
                            skipModalAlias: params.skipModalAlias,
                            showInfoIcon: params.showInfoIcon
                        };
                    }
                }
            });
            modal.result.then(params.accept, params.cancel || emptyCallback);
        };

        this.openHttpErrorModal = function(data, status, message) {
            message = message || 'global.modal.error.' + (status ? status : 'message');

            $uibModal.open({
                templateUrl: '/ng1/components/shared/confirmation-modal/confirmation-modal.html',
                controller: 'ConfirmationModalController',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            title: 'global.modal.error.title',
                            message: $translate.instant(message),
                            acceptButtonText: 'global.modal.accept',
                            hideCancelButton: true
                        };
                    }
                }
            }).result.then(emptyCallback, emptyCallback);
        };

        this.openQuestionnaireModal = function(id, successCallback, errorCallback) {
            return $uibModal.open({
                templateUrl: '/ng1/components/create-questionnaire-modal/create-questionnaire-modal.html',
                controller: 'CreateQuestionnaireModalController',
                size: 'lg',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            id: id
                        };
                    }
                }
            }).result.then(successCallback || emptyCallback, errorCallback || emptyCallback);
        };
        this.openFeedbackTrayResponsiblesModal = function(successCallback, errorCallback) {
            return $uibModal.open({
                templateUrl: '/ng1/components/feedback-tray/feedback-tray-reponsibles-modal.html',
                controller: 'FeedbackTrayResponsiblesModalController',
                size: 'lg',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {};
                    }
                }
            }).result.then(successCallback || emptyCallback, errorCallback || emptyCallback);
        };

        this.openSegmentModal = function(params) {
            $uibModal.open({
                templateUrl: '/ng1/components/create-segment-modal/create-segment-modal.html',
                controller: 'CreateSegmentModalController',
                size: 'md',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            id: params.id
                        };
                    }
                }
            }).result.then(params.accept, params.cancel || emptyCallback);
        };

        this.openCategoriesModal = function(params) {
            $uibModal.open({
                templateUrl: '/ng1/components/categories-modal/categories-content-modal.html',
                controller: 'CategoriesModalController',
                size: 'md',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            id: params.id
                        };
                    }
                }
            }).result.then(params.accept, params.cancel || emptyCallback);
        };

        

        this.openLocalizedErrorModal = function(message) {
            $uibModal.open({
                templateUrl: '/ng1/components/shared/confirmation-modal/confirmation-modal.html',
                controller: 'ConfirmationModalController',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            title: 'global.modal.error.title',
                            message: message,
                            acceptButtonText: 'global.modal.back',
                            hideCancelButton: true
                        };
                    }
                }
            }).result.then(emptyCallback, emptyCallback);
        };

        this.openImageEditionModal = function(params) {
            var uibModalInstance = $uibModal.open({
                templateUrl: '/ng1/components/shared/image-crop-edition/image-crop-edition-modal.html',
                controller: 'ImageCropEditionModalController',
                size: 'lg',
                windowClass: 'fade',
                backdrop: 'static',
                resolve: {
                    params: function() {
                        return {
                            image: params.image,
                            mimeType: params.mimeType,
                            cropOptions: params.cropOptions,
                            modalTexts: params.modalTexts,
                            showModalSubtitle: params.showModalSubtitle
                        };
                    }
                }
            });
            uibModalInstance.result.then(params.accept, params.cancel || emptyCallback);
        };

        this.openCreateLinkInKendoEditorModal = function(params) {
            $uibModal.open({
                templateUrl: '/ng1/components/shared/create-link-modal/create-link-modal.html',
                controller: 'CreateLinkModalController',
                size: 'md',
                windowClass: 'fade',
                centered: true,
                resolve: {
                    params: function() {
                        return {
                            selection: params.selection
                        };
                    }
                }
            }).result.then(params.accept, emptyCallback);
        };

        this.openAddMention = function(params) {
            var element = $('#mentions-hook');
            if (element) {
                $uibModal.open({
                    templateUrl: '/ng1/components/shared/mention-modal/mention-modal.html',
                    controller: 'MentionModalController',
                    size: 'sm',
                    windowClass: 'mentions',
                    appendTo: element,
                    backdrop: false,
                    centered: false,
                    resolve: {
                        params: function() {
                            return {
                                selection: params.selection
                            };
                        }
                    }
                }).result.then(params.accept, params.cancel, emptyCallback);
            }
        };

        this.openConfirmationWithInputModal = function(params) {
            var title = getTranslationText(params, params.title);
            var message = getTranslationText(params, params.message);
            var modal = $uibModal.open({
                templateUrl: '/ng1/components/shared/confirmation-modal/confirmation-modal-with-input.html',
                controller: 'ConfirmationModalController',
                size: params.size ? params.size : 'md',
                windowClass: 'fade',
                resolve: {
                    params: function() {
                        return {
                            title: title,
                            message: message,
                            acceptButtonText: params.acceptButtonText || 'global.modal.accept',
                            focusCancel: params.focusCancel !== false, // set to true by default
                            hideCancelButton: params.hideCancelButton || false,
                            skipModalAlias: params.skipModalAlias
                        };
                    }
                }
            });
            modal.result.then(params.accept, params.cancel || emptyCallback);
        };

        this.openWKUsageConditionsConfirmationModal = function (params) {
            $uibModal.open({
                templateUrl: '/ng1/components/wkauth/wk-usage-conditions-modal.html',
                controller: 'WKUsageConditionsModalController',
                size: 'md',
                windowClass: 'fade',
                centered: true
            }).result.then(params.accept, emptyCallback);
        };

        this.shouldModalBeDisplayed = function (skipModalAlias) {
            var skipModalValue = localStorage.getItem('skipModal');
            var parsedSkipModalValue = !!skipModalValue ? JSON.parse(skipModalValue) : false;
            return !parsedSkipModalValue || parsedSkipModalValue[skipModalAlias] !== true;
        };

        this.saveSkipModalSetting = function (skipModalValue, skipModalAlias) {
            if (skipModalValue && skipModalAlias) {
                var skipModalSavedValue = localStorage.getItem('skipModal');
                var parsedSkipModalValue = !!skipModalSavedValue ? JSON.parse(skipModalSavedValue) : {};
                parsedSkipModalValue[skipModalAlias] = true;
                localStorage.setItem('skipModal', JSON.stringify(parsedSkipModalValue));
            }
        };

    }
]);