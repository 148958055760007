'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.timeoffSettingsSchemesService
 * @description
 * # timeoffSettingsSchemesService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('TimeoffSettingsSchemesService',
    ['$http', '$translate', 'KendoUtilsService', 'TimeoffSettingsWorkplacesService', 'SharedDataService', 'PAGE_SIZE',
    function ($http, $translate, KendoUtilsService, TimeoffSettingsWorkplacesService, SharedDataService, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/timeoff/schemas';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.create = function (data) {
        return $http({
            url: this.URL,
            method: 'POST',
            data: data.schema.timeOffSchema
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL + '/' + data.schema.timeOffSchema.id,
            method: 'PUT',
            data: data.schema.timeOffSchema
        });
    };

    this.delete = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE'
        });
    };

    this.archive = function(id) {
        return $http({
            url: this.URL + '/' + id + '/archive',
            method: 'PUT'
        })
    }

    this.activate = function(id) {
        return $http({
            url: this.URL + '/' + id + '/activate',
            method: 'PUT'
        })
    }

    this.duplicate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/duplicate',
            method: 'POST'
        });
    };

    this.findSchemaAbsences = function (id) {
        return $http({
            url: this.URL + '/' + id + '/absence-motives',
            method: 'GET',
        });
    };

    this.getEmployees = function (schemaId, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/' + schemaId + '/employees',
            method: 'GET',
            params: options
        });
    };

    this.updateEmployeesInSchema = function (schemaId, employees) {
        return $http({
            url: this.URL + '/' + schemaId + '/employees',
            method: 'POST',
            data: employees
        });
    };

    this.deleteEmployeeFromSchema = function (schemaId, employeeId) {
        return $http({
            url: this.URL + '/' + schemaId + '/employees/' + employeeId,
            method: 'DELETE'
        });
    };

    this.getEmployeesSortCriteria = function (sortOptions, defaultValue) {
        var sort;
        if (sortOptions && sortOptions.length > 0) {
            var sortField = sortOptions[0].field;
            if (sortField === 'fullName') {
                sort = [];
                sort.push('name,' + sortOptions[0].dir);
                sort.push('surname,' + sortOptions[0].dir);
            } else {
                sort = sortField + "," + sortOptions[0].dir;
            }
        } else {
            sort = defaultValue;
        }
        return sort;
    };

    this.getSchemaEmployeesDropDownListOptions = function() {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource = new kendo.data.DataSource({
            transport: {
                read: function(event) {
                    TimeoffSettingsWorkplacesService.findAll({
                        page: 0,
                        size: 20000,
                        sort: 'name,asc'
                    }).then(function(response) {
                        event.success(response.data.content);
                    });
                }
            }
        });
        var employeeTemplate = '<div class="flex align-items-center list-item-height">' +
                '<i class="icon icon-24 margin-right-10" ng-class="!dataItem.id ? \'icon-globe\' : \'icon-workplace margin-left-minus-1\'"></i>' +
                '<span ng-bind="dataItem.name"></span>' +
            '</div>';
        options.template = employeeTemplate;
        options.valueTemplate = employeeTemplate;
        options.optionLabel = {
            id: null,
            name: $translate.instant('segments.allUsers')
        };
        options.optionLabelTemplate = employeeTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getSchemasOptions = function() {
        var self = this;
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            var requestOptions = {
                active: true,
                page: 0,
                size: 20000,
                sort: 'name,asc'
            };
            self.findAll(requestOptions).then(function(response) {
                event.success(response.data.content);
            });
        };
        options.optionLabel = {
            name: $translate.instant('global.form.notSet')
        };
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.updateMotiveStatus = function(schemaId, groupId, motiveId, motiveEnabled) {
        var updateUrl = this.URL + '/' + schemaId + '/absence-motives/' + groupId + '/motives/' + motiveId;
        updateUrl += motiveEnabled ? '/enable' : '/disable';
        return $http({
            url: updateUrl,
            method: 'PUT'
        });
    };

    this.updateAbsenceMotiveInSchema = function(schemaId, groupId, motive) {
        return $http({
            url: this.URL + '/' + schemaId + '/absence-motives/' + groupId + '/motives/' + motive.id,
            method: 'PUT',
            data: motive
        });
    };

    this.createAbsenceMotiveInSchema = function(schemaId, groupId, motive) {
        return $http({
            url: this.URL + '/' + schemaId + '/absence-motives/' + groupId + '/motives',
            method: 'POST',
            data: motive
        });
    };

}]);
