'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ImportUsersController
 * @description
 * # ImportUsersController
 * Controller of the dialengaExeviBackoffice for import Users
 */
angular.module('dialengaExeviBackoffice').controller('ImportUsersController',
    ['$window', '$translate', '$location', 'NotificationService', 'ModalService',
    function ($window, $translate, $location, NotificationService, ModalService) {

    //***************************************** Attributes **************************************\\

    $window.notificationImportUsersError = function(message) {
        NotificationService.notifyError(message);
    }

    $window.notifyImportUsersSuccessAndRedirect = function(message) {
        $location.path('/users');
        var messageSuccess = $translate.instant('usersImport.importInProgress');
        NotificationService.notifySuccess(messageSuccess, 5000);   
    }

    $window.showImportUsersErrorLog = function(issues, warningCount, errorCount) {
        ModalService.openModal({
            templateUrl: '/ng1/components/import-users/import-users-errorlog-modal.html',
            controller: 'ImportUsersErrorlogModalController',
            resolveParams: {
                issues: issues,
                warningCount: warningCount,
                errorCount: errorCount
            },
            size: 'lg'
        });
    }
}]);
