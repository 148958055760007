(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:questionnaireAnswer
     * @description
     * # questionnaireAnswer
     */
    angular.module('dialengaExeviBackoffice').component('questionnaireAnswer', {
        bindings: {
            answer: '=',
            question: '=',
            answerIndex: '<',
            isLinkOptional: '<',
            linkableQuestions: '<',
            language: '<',
            isAnonymous: '=',
            validationFunction: '&',
            onChange: '&',
            isSegmentsDisabled: '<?'
        },
        templateUrl: '/ng1/components/questionnaire-question/questionnaire-answer.html',
        controllerAs: 'vm',
        controller: ['QuestionnaireService', 'SegmentService', 'ModalService', '$timeout', '$rootScope', function(QuestionnaireService, SegmentService, ModalService, $timeout, $rootScope) {
            var vm = this;
            vm.isSegmentsActive = false;

            this.$onInit = function() {
                vm.questions = [];
                vm.segmentsOptions = SegmentService.getSegmentsDropDownListOptions(segmentChangeCallback);
                vm.segmentsOptions.optionLabelTemplate = '<div class="flex align-items-center list-item-height"><span ng-bind="dataItem.name"></span></div>';
                
                if (vm.linkableQuestions.length > 0) {
                    vm.linkedQuestionsOptions = QuestionnaireService.getLinkedQuestionsDropDownListOptions(vm.linkableQuestions);
                }
                if (vm.answer.segment && vm.answer.segment.id) {
                    vm.isSegmentsActive = true;
                }
            };

            vm.showLinkActive = function ($event) {
                if (vm.question.multipleOptions.multiple && !$event.target.className.includes('active')) {
                    // If target includes 'active' class, click is triggered from HTML.
                    // If not, is triggered from multiple selection toggle in QuestionnaireQuestionController
                    return;
                }
                vm.answer.isLinkActive = !vm.answer.isLinkActive;
                if (!vm.answer.isLinkActive) {
                    vm.answer.linkedQuestion = null;
                }
            };

            vm.showSegments = function () {
                vm.isSegmentsActive = !vm.isSegmentsActive;
                if (!vm.isSegmentsActive) {
                    vm.answer.segment = null;
                }
            }

            vm.removeAnswerOption = function (answerIndex) {
                var answerOrder = vm.question.answers[answerIndex].order;
                vm.question.answers.splice(answerIndex, 1);
        
                // Reorder answers
                var numAnswers = vm.question.answers.length;
                for (var index = 0; index < numAnswers; index++) {
                    if (vm.question.answers[index].order > answerOrder)
                        vm.question.answers[index].order--;
                }
        
                vm.validationFunction();
            };

            function segmentChangeCallback() {
                // Change event called when dropdownlist read event has successfully finished
                // Set created segment from modal as selected item in dropdownlist
                $timeout(function () {
                    if (SegmentService.latestSegment) {
                        vm.answer.segment = SegmentService.latestSegment;
                        delete SegmentService.latestSegment;
                    }
                }, 100);
            }
    
            vm.addSegment = function () {
                ModalService.openSegmentModal({
                    accept: function () {
                        vm.segmentsOptions.dataSource.read();
                        // When read event ends, segmentChangeCallback function is called
                        $rootScope.$broadcast('dialenga:loadingEnd');
                    },
                    cancel: function() {
                        $rootScope.$broadcast('dialenga:loadingEnd');
                    }
                });
            }
        }]
    });
})();