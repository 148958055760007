(function() {
    'use strict';

    /**
     * @ngdoc function
     * @name dialengaExeviBackoffice.controller:QuestionnaireSummaryController
     * @description
     * # QuestionnaireSummaryController
     * Controller of the dialengaExeviBackoffice
     */
    angular.module('dialengaExeviBackoffice').controller('QuestionnaireSummaryController',
        ['$rootScope', '$routeParams', '$window', '$location','ModalService', 'Session', 'QuestionnaireService', 'DEVICE_PREVIEW_STATES', 'MESSAGE_STATES', 'CONTENT_KIND',
        function ($rootScope, $routeParams, $window, $location, ModalService, Session, QuestionnaireService, DEVICE_PREVIEW_STATES, MESSAGE_STATES, CONTENT_KIND) {
            var vm = this;

            vm.smallScreen = false;
            vm.currentPreviewState = DEVICE_PREVIEW_STATES.PREVIEW;
            vm.DEVICE_PREVIEW_STATES = DEVICE_PREVIEW_STATES;
            vm.MESSAGE_STATES = MESSAGE_STATES;
            vm.CONTENT_KIND = CONTENT_KIND;

            vm.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
            vm.isUserOnlyEditor = Session.isUserOnlyEditor;
            vm.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;

            angular.element($window).on('resize', function (event) {
                setScreenWidth(event.currentTarget.innerWidth);
                setPreviewClassBasedOnScreenWidth();
            });

            function setScreenWidth(windowWidth) {
                vm.smallScreen = windowWidth < 1750;
            }

            function setPreviewClassBasedOnScreenWidth() {
                if (vm.smallScreen && vm.questionnaire.pushEnabled == true && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.PUSH) {
                    vm.devicePreviewKind = 'notification';
                } else if (vm.smallScreen && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.PREVIEW) {
                    vm.devicePreviewKind = 'detail';
                } else {
                    vm.devicePreviewKind = '';
                }
            }

            vm.archiveQuestionnaire = function () {
                ModalService.openConfirmationModal({
                    title: 'publication.modal.archive.title',
                    message: 'publication.modal.archive.message',
                    acceptButtonText: 'global.form.archive',
                    isTextInterpolated: true,
                    focusCancel: true,
                    hideCancelButton: false,
                    accept: function () {
                        QuestionnaireService.expire(vm.questionnaire.id)
                            .then(function () {
                                $location.path('/questionnaires');
                            })
                            .catch(actionErrorHandler);
                    }
                });
            };

            vm.viewStatistics = function() {
                $location.path('/questionnaires/statistics/' + vm.questionnaire.id);
            };

            function init() {
                setScreenWidth($window.innerWidth);
                vm.selectedLanguage = Session.companyDefaultLanguage;
                QuestionnaireService.findOne($routeParams.id).then(function (response) {
                    vm.questionnaire = response.data;
                    setPreviewClassBasedOnScreenWidth();
                    QuestionnaireService.setQuestionnaireContentLanguage(vm.questionnaire);

                    if (vm.questionnaire.users) {
                        vm.questionnaire.users =  QuestionnaireService.orderRecipientsUsers(vm.questionnaire.users); 
                    }
                    QuestionnaireService.setUsersAvatarTooltips(vm.questionnaire.users);
                });
            }

            vm.cloneContent = function () {
                QuestionnaireService.duplicate($routeParams.id).then(function(response) {
                    ModalService.openQuestionnaireModal(response.data.id).result.finally(function () {
                        $rootScope.$broadcast('dialenga:loadingEnd');
                    });
                });
            };

            init();
        }
    ]);

})();
