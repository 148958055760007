'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:focusElement
 * @description
 * # focusElement
 */
angular.module('dialengaExeviBackoffice').directive('focusElement', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(attrs.focusElement, function (value) {
                if (value) {
                    $timeout(function () {
                        element.focus();
                    }, 300);
                }
            });

            if (attrs.focusElement) {
                element.focus();
            }
        }
    };
}]);
