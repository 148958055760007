'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationsSettingsMotivesController
 * @description
 * # ConversationsSettingsMotivesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationsSettingsMotivesController', ['$rootScope', '$scope', '$translate', 'ConversationsService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'PAGE_SIZE',
    function ($rootScope, $scope, $translate, ConversationsService, KendoUtilsService, ModalService, NotificationService, PAGE_SIZE) {
        var vm = this;

        vm.stateIsActive = true;
        vm.inputSearchField = '';

        var removeReloadMotivesListEventListener;

        vm.$onInit = function () {
            initGridReloadEvent();
        };

        vm.$onDestroy = function () {
            removeReloadMotivesListEventListener();
        };

        var gridColumns = [{
            field: "motive",
            title: $translate.instant('motives.motiveTag'),
            width: 300,
            template: '<no-default-language-tooltip available-languages="dataItem.availableLanguages"></no-default-language-tooltip>' +
                '<span class="message-category" style="background-color: {{dataItem.color | numberColorFilter}};" data-ng-bind="dataItem.motive"></span>',
        }, {
            field: "motiveInApp",
            title: $translate.instant('motives.motiveInApp'),
            template: '<span data-ng-bind="dataItem.motiveInApp"></span>',
        }, {
            command: [{
                template: '<div class="td-on-hover">'
            }, {
                name: "edit-button",
                template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate"><span class="icon icon-edit"></span></a>',
                click: editRowClickHandler
            }, {
                name: "delete-button",
                template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                    'data-ng-if="dataItem.deletable"><span class="icon icon-delete"></span></a>',
                click: deleteRowClickHandler
            }, {
                name: "archive-button",
                template: '<a class="k-button k-grid-archive-button" kendo-tooltip k-content="\'global.form.archive\' | translate" ' +
                    'data-ng-if="!dataItem.deletable && !dataItem.archived"><span class="icon icon-archive"></span></a>',
                click: archiveRowClickHandler
            }, {
                name: "activate-button",
                template: '<a class="k-button k-grid-activate-button" kendo-tooltip k-content="\'global.form.activate\' | translate" ' +
                    'data-ng-if="dataItem.archived"><span class="icon icon-unarchive"></span></a>',
                click: activateRowClickHandler
            }, {
                template: '</div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];
        var gridDataSource = {
            transport: {
                read: function (options) {
                    saveGridPreferences(options);
                    var requestOptions = getRequestOptions(options);
                    ConversationsService.findAllMotives(requestOptions).then(function (response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function () {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements"
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: false }, { dataBound: onDataBound }).then(function (response) {
            vm.gridOptions = response;
        });

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['motivesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['motivesGrid'].stateIsActive = vm.stateIsActive;
            KendoUtilsService.gridPreferences['motivesGrid'].inputSearchField = vm.inputSearchField;
        }

        vm.listItemsSearch = function(text) {
            vm.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        function editMotive(id) {
            ModalService.openModal({
                templateUrl: '/ng1/components/conversations-settings-motives/create-motive-modal.html',
                controller: 'CreateMotiveModalController',
                resolveParams: {
                    id: id
                },
                accept: function(motive) {
                    if (motive) {
                        $scope.$broadcast('dialenga:reloadMotivesList');
                    }
                }
            });
        }

        function editRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                editMotive(dataItem.id);
            });
        }

        function actionErrorHandler(error) {
            if (error.status === 422) {
                $scope.$broadcast('dialenga:reloadMotivesList');
            }
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();
    
            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
    
            ModalService.openConfirmationModal({
                title: 'motives.modal.delete.title',
                message: 'motives.modal.delete.message',
                messageData: {name: dataItem.motive},
                acceptButtonText: 'global.form.delete',
                accept: function () {
                    ConversationsService.deleteMotive(dataItem.id)
                        .then(function() {
                            $scope.$broadcast('dialenga:reloadMotivesList');
                            var message = $translate.instant('motives.modal.delete.notification', {name: dataItem.motive});
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }
    
        function archiveRowClickHandler(event) {
            event.preventDefault();
    
            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
    
            ModalService.openConfirmationModal({
                title: 'motives.modal.archive.title',
                message: 'motives.modal.archive.message',
                messageData: {name: dataItem.motive},
                acceptButtonText: 'global.form.archive',
                accept: function () {
                    ConversationsService.archiveMotive(dataItem.id)
                        .then(function() {
                            $scope.$broadcast('dialenga:reloadMotivesList');
                            var message = $translate.instant('motives.modal.archive.notification', {name: dataItem.motive});
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }
    
        function activateRowClickHandler(event) {
            event.preventDefault();
    
            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
    
            ModalService.openConfirmationModal({
                title: 'motives.modal.activate.title',
                message: 'motives.modal.activate.message',
                messageData: {name: dataItem.motive},
                acceptButtonText: 'global.form.activate',
                accept: function () {
                    ConversationsService.activateMotive(dataItem.id)
                        .then(function() {
                            $scope.$broadcast('dialenga:reloadMotivesList');
                            var message = $translate.instant('motives.modal.activate.notification', {name: dataItem.motive});
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function onDblClick(dataItem) {
            editMotive(dataItem.id);
        }

        $scope.$on("kendoWidgetCreated", function (event, widget) {
            if (widget === vm.motivesGrid) {
                if (KendoUtilsService.gridPreferences['motivesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['motivesGrid'];
                    vm.stateIsActive = requestOptions.stateIsActive;
                    vm.inputSearchField = requestOptions.inputSearchField;
                    vm.motivesGrid.dataSource.query(requestOptions);
                } else {
                    vm.motivesGrid.dataSource.read();
                }
                KendoUtilsService.dblClickGridCallback('kendo-grid', onDblClick);
            }
        });

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
        }

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.motivesGrid.dataSource, options).then(function () {
                $scope.$apply(function () {
                    vm.isSyncing = false;
                });
            });
        }

        function getRequestOptions(options) {
            var defaultSortOrder = ['contents.motive,asc'];
            var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder, vm.inputSearchField, 'contents.motive');
            requestOptions['archived'] = !vm.stateIsActive;

            return requestOptions;
        }

        vm.getMotivesByIsActive = function (isActive) {
            if (vm.stateIsActive !== isActive) {
                vm.stateIsActive = isActive;
                reloadGrid({ page: 1 });
            }
        };

        function initGridReloadEvent() {
            if (!removeReloadMotivesListEventListener) {
                removeReloadMotivesListEventListener = $scope.$on('dialenga:reloadMotivesList', function () {
                    $rootScope.$broadcast('dialenga:reloadMotivesPreview');
                    reloadGrid({ page: 1 });
                });
            }
        }
    }
]);