'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:preventEvent
 * @description
 * # preventEvent
 */
angular.module('dialengaExeviBackoffice').directive('preventEvent', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            element.bind(attributes.preventEvent, function (event) {
                event.preventDefault();
                return false;
            });
        }
    };
});
