'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:removeLineBreaks
 * @function
 * @description
 * # removeLineBreaks
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('removeLineBreaks', function () {
    return function (text) {
        return text.replace(/<br( )*(\/)*>/g, ' ');
    };
});
