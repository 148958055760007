(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewSummary
     * @description
     * # devicePreviewSummary
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewSummary', {
        bindings: {
            publication: '<',
            languages: '<',
            segments: '<?',
            segmentation: '<?',
            isPill: '<'
        },
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-summary.html',
        controllerAs: 'vm',
        controller: ['MESSAGE_STATES', 'POPULATION', 'SEGMENTATION_KIND', 'PUBLICATION_SCHEDULED_KIND',
            function(MESSAGE_STATES, POPULATION, SEGMENTATION_KIND, PUBLICATION_SCHEDULED_KIND) {
                var vm = this;

                vm.MESSAGE_STATES = MESSAGE_STATES;
                vm.POPULATION = POPULATION;
                vm.SEGMENTATION_KIND = SEGMENTATION_KIND;
                vm.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;
            }
        ]
    });
})();