'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:onImageLoad
 * @description
 * # onImageLoad
 */
angular.module('dialengaExeviBackoffice').directive('onImageLoad', function () {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {
                //call the function that was passed
                scope.$apply(attrs.onImageLoad);
            });
        }
    };
});