'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:rolesFilter
 * @function
 * @description
 * # rolesFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('rolesFilter', ['$filter', function ($filter) {

    function compareRoles(roleA, roleB) {
        return ('' + roleA).localeCompare(roleB, undefined, { sensitivity: 'base' });
    }

    return function (roles, separator) {
        separator = separator || ', ';
        var userRoles = [];
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].name !== 'ROLE_USER') {
                var role = $filter('roleFilter')(roles[i].name);
                userRoles.push(role);
            }
        }
        userRoles.sort(compareRoles);
        return userRoles.join(separator);
    };
}]);
