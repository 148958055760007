'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserFilekindsModalController
 * @description
 * # UserFilekindsModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserFilekindsModalController',
    ['$scope', '$uibModalInstance', 'ArrayUtilsService', 'UsersService', 'params', 'USER_ALLOWED_DOCS_KIND',
    function ($scope, $uibModalInstance, ArrayUtilsService, UsersService, params, USER_ALLOWED_DOCS_KIND) {
        var vm = this;

        vm.totalAvailableItems = 0;
        vm.addedFilekinds = [];
        vm.selectedFilekinds = [];
        vm.addedFilekindsIds = [];

        vm.arePendingChanges = false;
        vm.USER_ALLOWED_DOCS_KIND = USER_ALLOWED_DOCS_KIND;

        vm.filter = {
            availableFilekinds: '',
            addedFilekinds: ''
        };

        var initialAddedFilekindsIds;

        $scope.$watchCollection('vm.addedFilekindsIds', function (newValue) {
            if (newValue) {
                vm.arePendingChanges = !ArrayUtilsService.areEquals(initialAddedFilekindsIds, newValue);
            }
        });

        vm.accept = function () {
            var fileKindsSelected = vm.arePendingChanges ? vm.addedFilekinds : null;
            $uibModalInstance.close(fileKindsSelected);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function setAvailableFilekinds(data) {
            if (vm.filter.availableFilekinds.length > 0) {
                vm.availableFilekinds = data.filter( function(filekind) {
                    var searchString = filekind.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    var inputText = vm.filter.availableFilekinds.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    return searchString.toUpperCase().includes(inputText.toUpperCase());
                });
                vm.totalAvailableItems = vm.availableFilekinds.length;
            } else {
                vm.availableFilekinds = data;
                vm.totalAvailableItems = data.length;
            }
        }

        function getAllAvailableFilekinds() {
            vm.isSyncing = true;
            UsersService.getFileKinds().then(function (response) {
                vm.isSyncing = false;
                setAvailableFilekinds(response.data);
            });
        }

        vm.searchAvailableFilekinds = function(text) {
            // Clear selected file kinds when starting modifing the filter
            vm.selectedFilekinds = [];
            vm.filter.availableFilekinds = text || '';
            getAllAvailableFilekinds();
        };

        function getAddedFilekindsIds() {
            return vm.addedFilekinds.map(function (kind) {
                return kind.id;
            });
        }

        function init() {
            
            getAllAvailableFilekinds();
            vm.addedFilekinds = params.fileKinds;
            vm.allowanceKind = params.allowanceKind;
            initialAddedFilekindsIds = getAddedFilekindsIds();
        }
    
        init();
}]);