/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserHolidaysModalController
 * @description
 * # UserHolidaysModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserHolidaysModalController', ['$translate', '$uibModalInstance', 'TimeoffService', 'params',
    function($translate, $uibModalInstance, TimeoffService, params) {

        var vm = this;
        vm.isChanged = vm.isValid = false;
        vm.summaryHolidays = params.summaryHolidays;
        vm.userId = params.userId;
        vm.currentPeriod = params.currentPeriod;
        vm.percents = {};
        
        var latestPeriodHolidays = {
            content: {}
        };

        vm.periodHolidays = {
            content: {}
        };

        function getPercentsDays() {
            var startingDays = vm.periodHolidays.periodDays + vm.periodHolidays.previousPeriodDays + vm.periodHolidays.acquiredDays + vm.periodHolidays.extraDays;
            vm.periodHolidays.startingDays = parseFloat(startingDays.toFixed(2));

            if (vm.periodHolidays.startingDays === 0) {
                vm.percents.percentPreviousDays = 0;
                vm.percents.percentExtraDays = 0;
                vm.percents.percentPeriodDays = 0;
            } else {
                vm.percents.percentPreviousDays = vm.periodHolidays.previousPeriodDays > 0 ? (vm.periodHolidays.previousPeriodDays * 100) / vm.periodHolidays.startingDays : 0;
                vm.percents.percentExtraDays = vm.periodHolidays.extraDays > 0 ? (vm.periodHolidays.extraDays * 100) / vm.periodHolidays.startingDays : 0;
                vm.percents.percentPeriodDays = (vm.periodHolidays.periodDays + vm.periodHolidays.acquiredDays) > 0 ?
                                                (vm.periodHolidays.periodDays + vm.periodHolidays.acquiredDays) * 100 / vm.periodHolidays.startingDays : 0;
            }
        }

        function dataHasChanged() {
            return !angular.equals(vm.periodHolidays, latestPeriodHolidays);
        }

        function isValidForm() {
            return (vm.periodHolidays.periodDays >= 0 && vm.periodHolidays.periodDays <= vm.periodHolidays.schemaDays);
        }

        function updateTooltipContent(id, value) {
            var tooltip = $(id).data('kendoTooltip');
            tooltip.options.content = $translate.instant('users.holidays.amountDays', {days: value}, 'messageformat');
            tooltip.refresh();
        }

        function getAmountDaysTooltip(daysAmount) {
            updateTooltipContent('#amount-previous-days-modal', daysAmount.previousPeriodDays);
            updateTooltipContent('#amount-period-days-modal', daysAmount.periodDays + daysAmount.acquiredDays);
            updateTooltipContent('#amount-extra-days-modal', daysAmount.extraDays);
        }

        vm.accept = function() {
            $uibModalInstance.close(vm.periodHolidays);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.onInputFieldDecimalChange = function(value) {
            if (value) {
                value.toFixed(1);
            }
            vm.onChange();
        };

        vm.onChange = function() { 
            if (typeof vm.periodHolidays.acquiredDays !== 'undefined' && typeof vm.periodHolidays.previousPeriodDays !== 'undefined' && typeof vm.periodHolidays.extraDays !== 'undefined' &&
                (vm.periodHolidays.acquiredDays >= 0 && vm.periodHolidays.acquiredDays < 100) && (vm.periodHolidays.previousPeriodDays >= 0 && vm.periodHolidays.previousPeriodDays < 100) &&
                (vm.periodHolidays.extraDays >= 0 && vm.periodHolidays.extraDays < 100)) {
                getAmountDaysTooltip(vm.periodHolidays);
                getPercentsDays();
            }
            vm.isChanged = dataHasChanged();
            vm.isValid = isValidForm();
        };

        vm.onPeriodDaysModelChange = function() {
            if (typeof vm.periodDaysModel !== 'undefined') {
                vm.periodHolidays.periodDays = vm.periodDaysModel;
                vm.onChange();
            }
        };

        function init() {
            TimeoffService.getUserPeriodHolidays(vm.userId).then(function(response) {
                vm.periodHolidays = response.data;
                if (vm.periodHolidays.periodDays === vm.periodHolidays.schemaDays) {
                    vm.periodDaysModel = null;
                } else  {
                    vm.periodDaysModel = vm.periodHolidays.periodDays;
                }
                latestPeriodHolidays = angular.copy(response.data);
                getPercentsDays();
                getAmountDaysTooltip(vm.periodHolidays);
            });
        }

        init();
        
    }
]);