'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CreateLinkModalController
 * @description
 * # CreateLinkModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CreateLinkModalController',
    ['$scope', '$translate', '$uibModalInstance', 'params', 'UrlUtilsService',
    function ($scope, $translate, $uibModalInstance, params, UrlUtilsService) {

    //****************************************** Attributes *************************************\\
    $scope.valid = false;
    $scope.createLinkForm = { link: {} };

    //******************************************* Methods ***************************************\\
    $scope.accept = function () {
        var linkTitle = !!$scope.createLinkForm.link.alias ? $scope.createLinkForm.link.alias : $scope.createLinkForm.link.url;
        var generatedLink = '<a href="' + $scope.createLinkForm.link.url + '" target="_blank">' + linkTitle + '</a>';

        $uibModalInstance.close(generatedLink);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.onChange = function () {
        $scope.valid = UrlUtilsService.validateURL($scope.createLinkForm.link.url);
        if($scope.valid) {
            $scope.createLinkForm.link.url = UrlUtilsService.concatURLPrefixIfNotExists($scope.createLinkForm.link.url)
        }
    };

    $scope.getPlaceholder = function () {
        return !$scope.createLinkForm.link.url ? $translate.instant('model.message.title') : $scope.createLinkForm.link.url;
    };

    function init() {
        $scope.createLinkForm.link.alias = !!params.selection.text ? params.selection.text.trim() : null;
        $scope.createLinkForm.link.url = !!params.selection.url ? params.selection.url.trim() : null;
    }

    init();
}]);
