'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayQuestionnaireContentController
 * @description
 * # FeedbackTrayQuestionnaireContentController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayQuestionnaireContentController',
    ['$scope', '$translate', 'AttachedContentService', 'KendoUtilsService', 'ModalService', 'PublicationService', 'Session', 'UrlUtilsService', 'IMAGE_CROPPER', 'TRAY_QUESTION_KIND',
    function ($scope, $translate, AttachedContentService, KendoUtilsService, ModalService, PublicationService, Session, UrlUtilsService, IMAGE_CROPPER, TRAY_QUESTION_KIND) {

        $scope.IMAGE_CROPPER = IMAGE_CROPPER;
        $scope.TRAY_QUESTION_KIND = TRAY_QUESTION_KIND;

        $scope.textareaOptions = KendoUtilsService.getTextareaOptions();

        $scope.textareaOptions.keyup = function () {
            var language = $scope.selectedLanguage || Session.companyDefaultLanguage;
            if (!$scope.questionForm.question.contents[language]) {
                $scope.questionForm.question.contents[language] = {};
            }
            $scope.questionForm.question.contents[language].text = this.value();
            $scope.$apply(function () {
                $scope.onChange();
            });
        };
        $scope.textareaOptions.tools.splice(0,1);
        $scope.textareaOptions.tools.splice(7,2);

        $scope.textareaOptions.placeholder = $translate.instant('message.captionPlaceholder');

        $scope.showUrlLink = UrlUtilsService.showUrlLink;

        $scope.$parent.$watch('changed', function (newValue) {
            if (newValue) {
                $scope.testUrlLinks();
                $scope.validateModel();
                $scope.$parent.changed = false;
            }
        });

        $scope.onResourceSelected = function ($files) {
            if ($files.length > 0) {
                AttachedContentService.selectResource($files[0], $scope.questionForm, 'question', $scope.selectedLanguage);
                $scope.onChange();
            }
            document.getElementById('resource-drag-drop').classList.remove('dragging');
        };

        $scope.deleteResource = function () {
            AttachedContentService.deleteResource('questionResource', $scope.questionForm, 'question', $scope.selectedLanguage);
            $scope.onChange();
        };

        $scope.confirmAttachedResourceCheckboxDeactivation = function () {
            PublicationService.confirmAttachedResourceCheckboxDeactivation($scope.questionForm.question.contents, $scope.selectedLanguage);
        };

        $scope.toggleAttachedResource = function (isAttachedResourceEnabled) {
            if (!isAttachedResourceEnabled) {
                var languages = Object.keys($scope.questionForm.question.contents);
                for (var index = 0; index < languages.length; index++) {
                    AttachedContentService.deleteResource('questionResource', $scope.questionForm, 'question', languages[index]);
                }
            }
            $scope.onChange();
        };

        $scope.selectImageTab = function () {
            if ($scope.questionForm.question.contents[$scope.selectedLanguage] && $scope.questionForm.question.contents[$scope.selectedLanguage].youtubeURL) {
                ModalService.openConfirmationModal({
                    title: 'message.videoToggleConfirmation.title',
                    message: 'message.videoToggleConfirmation.description',
                    acceptButtonText: 'global.form.continue',
                    accept: function () {
                        $scope.toggleInput.isImageVisible = true;
                        $scope.clearFieldValueInAllLanguages('youtubeURL');
                        $scope.onChange();
                    }
                });
            } else {
                $scope.toggleInput.isImageVisible = true;
                $scope.onChange();
            }
        };
    
        $scope.selectVideoTab = function () {
            $scope.toggleInput.isImageVisible = false;
            var localizedContent = $scope.questionForm.question.contents[$scope.selectedLanguage];
            $scope.toggleInput.isOptionalImageVisible = !!localizedContent && !!(localizedContent.imageURL || localizedContent.imageOrder > -1);
            $scope.onChange();
        };

        $scope.toggleOptionalImageForVideo = function () {
            if (!$scope.toggleInput.isOptionalImageVisible) {
                AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.questionForm.images, $scope.questionForm.question.contents, $scope.selectedLanguage);
            }
            $scope.onChange();
        };

        $scope.hideRelatedEntityAdvice = function() {
            localStorage.setItem('hideRelatedEntityAdvice', JSON.stringify(true));
            $scope.showRelatedEntityAdvice = false;
        }
    }
]);
