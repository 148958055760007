'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:numberColorFilter
 * @function
 * @description
 * # numberColorFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('numberColorFilter', function () {
    return function (color) {
        if (typeof color === 'number') {
            color = '#' + ('00000' + (color | 0).toString(16)).substr(-6);
        }
        return color;
    };
});
