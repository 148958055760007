'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:AuditController
 * @description
 * # AuditController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('AuditController',
    ['$scope', 'AuditService', 'KendoUtilsService', 'PAGE_SIZE',
    function ($scope, AuditService, KendoUtilsService, PAGE_SIZE) {

    //******************************************* Attributes ************************************\\
    var gridColumns = [{
        field: "operationDate",
        headerTemplate: '<span translate="model.audit.operationDate"></span>',
        type: "date",
        format: "{0:dd/MM/yyyy h:mm:ss}",
        width: 180
    }, {
        field: "user.username",
        headerTemplate: '<span translate="model.audit.username"></span>',
        template: '<span class="truncate" ng-bind="dataItem.user.username" title="{{dataItem.user.username}}"></span>',
        type: "string"
    }, {
        field: "user.email",
        headerTemplate: '<span translate="model.audit.email"></span>',
        template: '<span class="truncate" ng-bind="dataItem.user.email"></span>',
        type: "string",
        width: 300
    }, {
        field: "remoteIP",
        headerTemplate: '<span translate="model.audit.remoteIP"></span>',
        template: '<span class="truncate" ng-bind="dataItem.remoteIP"></span>',
        type: "string"
    }, {
        field: "requestURL",
        headerTemplate: '<span translate="model.audit.requestURL"></span>',
        template: '<span class="truncate" ng-bind="dataItem.requestURL"></span>',
        type: "string"
    }, {
        field: "method",
        headerTemplate: '<span translate="model.audit.activity"></span>',
        template: '<span class="truncate" ng-bind="dataItem.method"></span>',
        type: "string"
    }];
    var gridDataSource = {
        transport: {
            read: function (options) {
                var sort = "operationDate,desc";
                if (options.data.sort && options.data.sort.length > 0)
                    sort = options.data.sort[0].field + "," + options.data.sort[0].dir;
                AuditService.findAll({
                    page: options.data.page - 1,
                    size: options.data.pageSize,
                    sort: sort
                }).then(function (response) {
                    options.success(response.data);
                }, function () {
                    options.error();
                });
            }
        },
        schema: {
            data: "content",
            total: "totalElements",
            parse: function (data) {
                $.each(data.content, function (idx, elem) {
                    if (elem.operationDate)
                        data.content[idx].operationDate = new Date(elem.operationDate);
                    if(!elem.user)
                        data.content[idx].user = {
                            username: '',
                            email: ''
                        };
                });
                return data;
            }
        },
        pageSize: PAGE_SIZE,
        serverPaging: true,
        serverSorting: true
    };

    KendoUtilsService.initializeGrid(gridColumns, gridDataSource).then(function (response) {
        $scope.gridOptions = response;
    });

    //******************************************** Methods **************************************\\
}]);
