'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemeEmployeesModalController
 * @description
 * # TimeoffSettingsSchemeEmployeesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemeEmployeesModalController',
    ['$scope', '$timeout', '$uibModalInstance', 'ArrayUtilsService', 'TimeoffSettingsSchemesService', 'TimeoffSettingsWorkplacesService',
    'UsersService', 'USER_STATES_FILTER', 'params',
    function ($scope, $timeout, $uibModalInstance, ArrayUtilsService, TimeoffSettingsSchemesService, TimeoffSettingsWorkplacesService,
        UsersService, USER_STATES_FILTER, params) {
        var vm = this;

        vm.totalAvailableItems = 0;
        vm.addedUsers = [];
        vm.selectedUsers = [];
        vm.filter = {
            availableUsers: '',
            addedUsers: ''
        };
        vm.addedUsersIds = [];
        vm.arePendingChanges = false;

        vm.globalSearchOpened = false;
        vm.groupSearchOpened = false;

        var initialAddedUsersIds;

        vm.originGroupDropdownOptions = TimeoffSettingsSchemesService.getSchemaEmployeesDropDownListOptions();

        $scope.$watchCollection('vm.addedUsersIds', function (newValue) {
            if (newValue) {
                vm.arePendingChanges = !ArrayUtilsService.areEquals(initialAddedUsersIds, newValue);
            }
        });

        vm.accept = function () {
            var users = vm.arePendingChanges ? vm.addedUsersIds : null;
            $uibModalInstance.close(users);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    
        function setAvailableUsers(data) {
            vm.availableUsers = data.content;
            vm.totalAvailableItems = data.totalElements;
        }
    
        function getWorkplaceAvailableUsers(workplaceId, options) {
            vm.isSyncing = true;
            TimeoffSettingsWorkplacesService.getEmployees(workplaceId, options).then(function (response) {
                vm.isSyncing = false;
                if (options['name'] === vm.filter.availableUsers || !options['name']) {
                    setAvailableUsers(response.data);
                }
            });
        }
    
        function getAllAvailableUsers(options) {
            vm.isSyncing = true;
            UsersService.findAllSegmentedUsers(options).then(function (response) {
                vm.isSyncing = false;
                if (options.name === vm.filter.availableUsers) {
                    setAvailableUsers(response.data);
                }
            });
        }

        function loadAvailableUsers() {
            var options = {
                page: 0,
                size: 20000
            };
            var availableUsersStates = [
                USER_STATES_FILTER.ANONYMOUS,
                USER_STATES_FILTER.PENDING_ACTIVATION,
                USER_STATES_FILTER.REGISTERED,
                USER_STATES_FILTER.EXPIRED_CREDENTIALS,
                USER_STATES_FILTER.LOCKED
            ];
            if (vm.originGroup) {
                if(!!vm.filter.availableUsers) {options['name'] = vm.filter.availableUsers;};
                getWorkplaceAvailableUsers(vm.originGroup, options);
            } else {
                options.name = vm.filter.availableUsers;
                options.state = availableUsersStates;
                getAllAvailableUsers(options);
            }
        }
    
        vm.originGroupChange = function () {
            loadAvailableUsers();
            vm.selectedUsers = [];
        };
    
        vm.searchAvailableUsers = function(clearInput) {
            if(clearInput) { vm.filter.availableUsers = ''; document.getElementById('global-search').focus();}
       
            if(vm.filter.availableUsers.length === 0) { vm.selectedUsers = []; }

            if (vm.filter.availableUsers.length >= 3 || vm.filter.availableUsers.length === 0) {
                loadAvailableUsers();
            }
        };
    
        vm.globalSearchOpen = function() {
            vm.globalSearchOpened = !vm.globalSearchOpened;
            $timeout(function() {
                document.getElementById('global-search').focus();
            }, 500);
        }

        vm.groupSearchOpen = function() {
            vm.groupSearchOpened = !vm.groupSearchOpened;
            $timeout(function() {
                document.getElementById('group-search').focus();
            }, 100);
        }
    
        vm.clearGroupSearch = function() {
            vm.filter.addedUsers = '';
            document.getElementById('group-search').focus();
        }

        function getAddedUsersIds() {
            return vm.addedUsers.map(function (user) {
                return user.id;
            });
        }

        function init() {
            TimeoffSettingsSchemesService.getEmployees(params.id).then(function (response) {
                vm.addedUsers = vm.addedUsers.concat(response.data.content);
                initialAddedUsersIds = getAddedUsersIds();
            });
        }
    
        init();
}]);
