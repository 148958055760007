(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:categoryPath
     * @description
     * # categoryPath
     */
    angular.module('dialengaExeviBackoffice').component('categoryPath', {
        bindings: {
            category: '<',
            clickAction: '&?'
        },
        templateUrl: '/ng1/components/category-path/category-path.html',
        controllerAs: 'vm',
        controller: [function() {
            var vm = this;
        }]
    });
})();