'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessageCreateController
 * @description
 * # MessageCreateController
 * Controller of the dialengaExeviBackoffice
 */
 angular.module('dialengaExeviBackoffice').controller('MessageCreateController',
    ['$scope', '$location', '$routeParams', 'LanguageService', 'Session', 'CONTENT_KIND', 'CONTENT_TYPE', 'MESSAGE_TABS',
    function ($scope, $location, $routeParams, LanguageService, Session, CONTENT_KIND, CONTENT_TYPE, MESSAGE_TABS) {

    //******************************************* Attributes ************************************\\
    
    $scope.selectedTab = MESSAGE_TABS.CONTENT;
    $scope.messageId = $routeParams.id;
    
    $scope.messageForm = { };
    $scope.language = {};
    $scope.messageForm.message = { contents: {}, gallery: {images: [] }};
    $scope.messageForm.valid = false;
    $scope.messageForm.isFormDirty = false;
    $scope.messageForm.isPublished = false;

    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.CONTENT_TYPE = CONTENT_TYPE;
    $scope.MESSAGE_TABS = MESSAGE_TABS;

    $scope.isPill = false;

    $scope.previewState = { opened: false };

    //******************************************** Methods **************************************\\

    $scope.loadTabContent = function(tab) {
        if ($scope.selectedTab !== tab) {
            if ($scope.messageForm.valid && $scope.messageForm.isFormDirty) {
                $scope.$broadcast('dialenga:savePublicationFormAndStay', {tab: $scope.selectedTab, redirect: true, nextTab: tab});
            }
        }
    };

    $scope.publicationFormSaveAndStay = function () {
        $scope.$broadcast('dialenga:savePublicationFormAndStay', {tab: $scope.selectedTab});
    };

    var init = function () {
        $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $scope.isPill = $location.path().search('/knowledge-center') > -1;
    };
    
    init();
}]);
