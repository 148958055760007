(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:conversationDetail
     * @description
     * # conversationDetail
     */
    angular.module('dialengaExeviBackoffice').component('conversationDetail', {
        bindings: {
            conversation: '<',
            conversationNotes: '<',
            isVisible: '<',
            closeView: '&',
            reloadGrid: '&',
            reloadGridAndSelectRow : '&',
            arePendingChanges: '='
        },
        controller: 'ConversationDetailController',
        controllerAs: 'self',
        templateUrl: '/ng1/components/conversation/conversation-detail.html'
    });
})();