'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserDocumentsController
 * @description
 * # UserDocumentsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserDocumentsController', 
    ['$scope', '$translate', '$routeParams', 'DocumentsHistoryService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'FileUtilsService', 'UtilsService', 'PAGE_SIZE', 'DOCUMENT_KIND',
    function($scope, $translate, $routeParams, DocumentsHistoryService, KendoUtilsService, ModalService, NotificationService, FileUtilsService, UtilsService, PAGE_SIZE, DOCUMENT_KIND) {

        var vm = this;
        vm.userId = $routeParams.id
        const DOCUMENT_KIND_ALL = '';
        const DOCUMENT_KIND_EMPTY = -1;

        vm.selectedType = DOCUMENT_KIND_ALL;

        vm.docsTypeOptions = {
            dataSource: {
                transport: {
                    read: function(event) {
                        DocumentsHistoryService.getDocumentKinds()
                        .then(function(response) {
                            var options = response.data;
                            options.unshift({ id: DOCUMENT_KIND_ALL, name: 'global.messages.stateFilters.all' });
                            options.push({ id: DOCUMENT_KIND_EMPTY, name: 'documents.documentsKind.filterTagNames.other' });
                            event.success(options);
                        });
                    }
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            autoWidth : true,
            valuePrimitive : true,
            valueTemplate: '<div><span translate="{{dataItem.name}}"></span></div>',
            template : '<div><span translate="{{dataItem.name}}"></span></div>'
        }

        var gridColumns = [{
            field: "",
            title: $translate.instant('model.users.documents.document'),
            template: '<div class="flex align-items-center"><img src="{{vm.iconFromMime(dataItem.resource.mimetype)}}" class="margin-right-5" alt="">' + 
                      '<span class="categories-table-container truncate" data-ng-bind="dataItem.resource.name"></span></div>',
            width: '50%',
            sortable: false
        }, {
            field: "",
            title: $translate.instant('model.users.documents.kind'),
            template: '<span data-ng-bind="dataItem.fileKind ? dataItem.fileKind.name : \'documents.documentsKind.filterTagNames.other\' | translate"></span>',
            width: '50%'
        },{
            field: "privateDocumentDate",
            title: $translate.instant('model.users.documents.documentDate'),
            template: '<span data-ng-bind="dataItem.privateDocumentDate | date: vm.formatDateFromDocumentKind(dataItem.fileKind.id)"></span>',
            width: '50%'
        }, {
            command: [{
                template: '<div class="td-on-hover">'
            }, {
                name: "view-button",
                template: '<a class="k-button k-grid-view-button" kendo-tooltip k-content="\'model.users.documents.viewDocument\' | translate"><span class="icon icon-eye-opened"></span></a>',
                click: viewRowClickHandler
            }, 
            //    {
            //     name: "replace-button",
            //     template: '<a class="k-button k-grid-replace-button" kendo-tooltip k-content="\'model.users.documents.replaceDocument\' | translate"><span class="icon icon-revert"></span></a>',
            //     click: replaceRowClickHandler
            // }, 
               {
                name: "delete-button",
                template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'model.users.documents.deleteDocument\' | translate"><span class="icon icon-delete"></span></a>',
                click: deleteRowClickHandler
            }, {
                template: '</div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];
        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.fileKind = vm.selectedType;
                    saveGridPreferences(options);
                    
                    var requestOptions = getRequestOptions(options);
                    DocumentsHistoryService.findAllUserDocuments(requestOptions, vm.userId).then(function(response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements"
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: false }, { dataBound: onDataBound }).then(function(response) {
            vm.gridOptions = response;
        });

        $scope.$on('dialenga:documentUploadSuccess', function() {
            reloadGrid();
        });

        //******************************************** Methods **************************************\\
        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['documentsUserGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['documentsUserGrid'].selectedType = $scope.selectedType;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === vm.documentsUserGrid) {
                if (KendoUtilsService.gridPreferences['documentsUserGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['documentsUserGrid'];
                    vm.selectedType = requestOptions.selectedType;
                    vm.inputSearchField = requestOptions.inputSearchField;
                    vm.documentsUserGrid.dataSource.query(requestOptions);
                } else {
                    vm.selectedType = DOCUMENT_KIND.ALL;
                    vm.documentsUserGrid.dataSource.read();
                }
            }
        });

        function viewRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
            var document = {
                pdf: null,
                name: dataItem.resource.nameWithExtension,
                url: dataItem.resource.url
            };
            FileUtilsService.getFileFromUrl(dataItem.resource.url, function(pdf) {
                document.pdf = pdf;

                ModalService.openModal({
                    templateUrl: '/ng1/components/user/user-document-modal.html',
                    controller: 'UserDocumentModalController',
                    size: 'xl',
                    resolveParams: {
                        document: document
                    }
                });
            });
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'users.documents.modal.delete.title',
                message: 'users.documents.modal.delete.message',
                acceptButtonText: 'global.modal.accept',
                accept: function() {
                    DocumentsHistoryService.deleteUserDocument(vm.userId, dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('users.documents.modal.delete.notification', { schemaName: dataItem.name });
                            NotificationService.notifySuccess(message, 1000);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function replaceRowClickHandler(event) {
            
        }

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
        }

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.documentsUserGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
            });
        }

        vm.setSelectedType = function() {
            reloadGrid({ page: 1 });
        };

        vm.iconFromMime = function(mimeType) {
            return UtilsService.getIconFromMimeType(mimeType);
        }

        vm.formatDateFromDocumentKind = function(fileKind) {
            return (fileKind == DOCUMENT_KIND.PAYROLL || fileKind == DOCUMENT_KIND.EXTRA || fileKind == DOCUMENT_KIND.CERT || fileKind == DOCUMENT_KIND.TIME_REGISTRATION ?
                    'MMMM yyyy' : 'dd MMMM yyyy');
        }

        function getRequestOptions(options) {
            var defaultSortOrder = ['lastModifiedDate,desc'];
            var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder);
            
            return requestOptions;
        }
    }
]);