'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:unsafeHtml
 * @function
 * @description
 * # unsafeHtml
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('unsafeHtml', ['$sce', function ($sce) {
    return function (string) {
        return $sce.trustAsHtml(string);
    };
}]);
