'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ExperiencesController
 * @description
 * # ExperiencesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ExperiencesController',
    ['$scope', '$timeout', '$translate', 'ExperienceService', 'KendoUtilsService', 'Session', 'PAGE_SIZE', 'RATING_EMOJI_CODES', 'RATING_KIND',
    'GRID_ICON_COLUMN_WIDTH', 'GRID_SHORT_DATE_COLUMN_WIDTH', 'GRID_CATEGORY_COLUMN_WIDTH', 'GRID_RATING_COLUMN_WIDTH',
    function ($scope, $timeout, $translate, ExperienceService, KendoUtilsService, Session, PAGE_SIZE, RATING_EMOJI_CODES, RATING_KIND,
        GRID_ICON_COLUMN_WIDTH, GRID_SHORT_DATE_COLUMN_WIDTH, GRID_CATEGORY_COLUMN_WIDTH, GRID_RATING_COLUMN_WIDTH) {

    //******************************************* Attributes ************************************\\
    $scope.inputSearchField = "";
    $scope.ratingEmojiCodes = RATING_EMOJI_CODES;
    $scope.currentSelectedRowIndex = -1;

    var gridColumns = [{
        field: "nps",
        title: $translate.instant('experiences.nps'),
        headerAttributes: { class: "text-center" },
        template: '<rating-icon ng-if="dataItem.nps" value="dataItem.nps"></rating-icon>',
        attributes: { style: "cursor: default;", class: "text-center" },
        width: Session.ratingKind === RATING_KIND.EMOJI ? GRID_ICON_COLUMN_WIDTH : GRID_RATING_COLUMN_WIDTH,
        sortable: false
    }, {
        field: "userFullName",
        title: $translate.instant('experiences.user'),
        attributes: { class: "truncate" },
        sortable: false,
        width: GRID_CATEGORY_COLUMN_WIDTH
    }, {
        field: "text",
        title: $translate.instant('experiences.text'),
        attributes: { class: "truncate" }
    }, {
        field: "createdDate",
        title: $translate.instant('experiences.creationDate'),
        type: "date",
        format: "{0:dd/MM/yyyy}",
        width: GRID_SHORT_DATE_COLUMN_WIDTH
    }, {
        command: [/*{
            name: "location-button",
            template: '<a class="k-button" ng-if="dataItem.latitude && dataItem.longitude" ng-href="http://maps.google.com/maps?q={{dataItem.latitude}},{{dataItem.longitude}}" ' +
                'target="_blank" kendo-tooltip k-content="\'experiences.viewLocation\' | translate"><span class="fa fa-map-marker"></span></a>'
        }, */{
            name: "image-button",
            template: '<a class="k-button visible" ng-if="dataItem.resource && dataItem.resource.isImage" ng-href="{{dataItem.resource.url}}" target="_blank" ' +
                'kendo-tooltip k-content="\'experiences.viewImage\' | translate"><span class="flex icon icon-image"></span></a>'
        }, {
            name: "file-button",
            template: '<a class="k-button visible" ng-if="dataItem.resource && !dataItem.resource.isImage" ng-href="{{dataItem.resource.url}}" target="_blank" ' +
                'kendo-tooltip k-content="\'experiences.viewFile\' | translate"><span class="flex icon icon-doc"></span></a>'
        }],
        attributes: {class: 'text-right'},
        title: "",
        width: 90
    }];
    var gridDataSource = {
        transport: {
            read: function (options) {
                var requestOptions = KendoUtilsService.getGridRequestOptions(options, 'createdDate,desc', $scope.inputSearchField, 'text');
                ExperienceService.findAll(requestOptions).then(function (response) {
                    $scope.numResults = response.data.totalElements;
                    options.success(response.data);
                }, function () {
                    options.error();
                });
            }
        },
        schema: {
            data: "content",
            total: "totalElements",
            parse: function (data) {
                $.each(data.content, function (idx, elem) {
                    if (elem.createdDate) {
                        data.content[idx].createdDate = new Date(elem.createdDate);
                    }
                    if (elem.resource) {
                        data.content[idx].resource.isImage = elem.resource.mimetype ? elem.resource.mimetype.startsWith('image/') : false;
                    }
                });
                return data;
            }
        },
        pageSize: PAGE_SIZE,
        serverPaging: true,
        serverSorting: true
    };

    //******************************************** Methods **************************************\\
    var init = function () {
        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { selectable: true }, { change: onRowSelectionChange }).then(function (response) {
            $scope.gridOptions = response;

            // On page change, deselect experience and close detail view
            $scope.gridOptions.pageable.change = function () {
                $scope.deselectExperienceAndClearSelectedRow();
            };
        });

        ExperienceService.statistics().then(function(response) {
            if (response.data) {
                $scope.dialengaHappinessScore = response.data.dialengaHappinessScore;
                $scope.interactionExperienceIndex = response.data.interactionExperienceIndex;
            }
        });
    };

    function reloadGrid(page) {
        var reloadOptions = {
            page: page || $scope.experiencesGrid.dataSource.page(),
            pageSize: $scope.experiencesGrid.dataSource.pageSize()
        };
        $scope.isSyncing = true;
        KendoUtilsService.reloadGridDatasource($scope.experiencesGrid.dataSource, reloadOptions).then(function () {
            $scope.$apply(function() {
                $scope.isSyncing = false;
            });
        });
    }

    $scope.listItemsSearch = function(text) {
        $scope.inputSearchField = text;
        if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
            reloadGrid(1);
            $scope.currentSelectedRowIndex = -1;
        }
    };

    $scope.deselectExperience = function () {
        // Set a timeout to apply changes to the scope inside a Kendo component
        // The use of $scope.$apply causes errors as a digest cycle was already being made in some cases
        $timeout(function () {
            $scope.selectedExperience = null;
        }, 0);
    };

    $scope.deselectExperienceAndClearSelectedRow = function () {
        $scope.deselectExperience();
        $scope.currentSelectedRowIndex = -1;
        $scope.experiencesGrid.clearSelection();
    };

    function onRowSelectionChange(e) {
        var selectedRow = this.select();
        $scope.currentSelectedRowIndex = selectedRow.index();

        if ($scope.currentSelectedRowIndex !== -1) {
            var selectedRowData = $scope.experiencesGrid.dataItem(selectedRow[0]);
            $scope.selectedExperience = selectedRowData;
        }
        else {
            $scope.deselectExperience();
        }
    }

    init();
}]);
