'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.AuthenticationService
 * @description
 * # AuthenticationService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('Session', ['UtilsService', 'APPROVAL_FLOW_KIND', 'USER_ROLES', function (UtilsService, APPROVAL_FLOW_KIND, USER_ROLES) {
    this.create = function (user) {
        var configuration = user.company.configuration;
        this.id = user.id;
        this.username = user.username;
        this.name = user.name;
        this.surname = user.surname;
        this.avatarThumbnailMUrl = user.avatarThumbnailMUrl;
        this.avatarThumbnailSUrl = user.avatarThumbnailSUrl;
        this.email = user.email;
        this.roles = user.roles;
        this.editorPermissions = user.editorPermissions;
        this.language = user.language;
        this.initials = user.name.charAt(0) + user.surname.charAt(0);
        this.companyId = user.company.id;
        this.companyColor = UtilsService.decimalToHex(user.company.color);
        this.ratingKind = configuration.ratingKind ? parseInt(configuration.ratingKind) : null;
        this.notificationChannel = configuration.requiredNotificationChannel ? parseInt(configuration.requiredNotificationChannel) : null;
        this.companyTimeZone = configuration.timeZone;
        this.activeModules = configuration.companyAppModules;
        this.companyDefaultLanguage = configuration.defaultLanguage;
        this.companyLanguages = configuration.availableLanguages;
        this.externalLoginBrand = user.company.brand;
        this.loginKind = configuration.loginKind;
        this.multiCountry = configuration.multiCountry;
        this.customNamings = configuration.customNamings;
        this.commentsConfig = configuration.commentsDefault;
        this.shareConfig = configuration.sharingDefault;
        this.ratingConfig = configuration.ratingDefault;
        this.approvalFlow = configuration.timeOffConfiguration ? configuration.timeOffConfiguration.approvalFlow : APPROVAL_FLOW_KIND.SINGLE;
        this.allowHalfDay = configuration.timeOffConfiguration ? !!configuration.timeOffConfiguration.allowsHalfDay : false;
        this.isUserPublisherOrAdmin = user.roles.indexOf(USER_ROLES.admin) !== -1 || user.roles.indexOf(USER_ROLES.publisher) !== -1;
        this.isUserOnlyEditor = user.roles.indexOf(USER_ROLES.manager) !== -1 && user.roles.indexOf(USER_ROLES.admin) === -1 && user.roles.indexOf(USER_ROLES.publisher) === -1;
    };
    this.update = function (user) {
        this.name = user.name;
        this.surname = user.surname;
        this.email = user.email;
        this.language = user.language;
        this.initials = user.name.charAt(0) + user.surname.charAt(0);
    };
    this.invalidate = function () {
        this.id = null;
        this.username = null;
        this.name = null;
        this.surname = null;
        this.avatarThumbnailMUrl = null;
        this.avatarThumbnailSUrl = null;
        this.email = null;
        this.roles = null;
        this.editorPermissions = null;
        this.language = null;
        this.initials = null;
        this.companyId = null;
        this.companyColor = null;
        this.ratingKind = null;
        this.notificationChannel = null;
        this.companyTimeZone = null;
        this.activeModules = null;
        this.companyDefaultLanguage = null;
        this.companyLanguages = null;
        this.externalLoginBrand = null;
        this.loginKind = null;
        this.multiCountry = null;
        this.customNamings = null;
        this.commentsConfig = null;
        this.shareConfig = null;
        this.ratingConfig = null;
        this.approvalFlow = null;
        this.allowHalfDay = null;
        this.isUserPublisherOrAdmin = null;
        this.isUserOnlyEditor = null;
    };
    return this;
}]);

angular.module('dialengaExeviBackoffice').service("AuthenticationService",
    ['$http', '$q', '$rootScope', '$translate', '$window', 'authService', 'Session',
        'SharedDataService', 'LOGIN_KIND', 'USER_ROLES', 'AVAILABLE_FEEDS',
    function ($http, $q, $rootScope, $translate, $window, authService, Session,
        SharedDataService, LOGIN_KIND, USER_ROLES, AVAILABLE_FEEDS) {
    var self = this;
    this.authenticateUser = function(ignoreAuthModule) {
        var requestConfig = {
            url: SharedDataService.apiUrl + '/authentication',
            method: 'GET'
        };
        if (ignoreAuthModule) {
            // Avoid interceptor catching 401 error and redirecting to login
            requestConfig.ignoreAuthModule = 'ignoreAuthModule';
        }
        return $http(requestConfig).then(function (response) {
            self.saveAuthorizationToken(response);
            $rootScope.account = self.getSessionUser(response.data);
            $rootScope.authenticated = !!$rootScope.account.username;
            return $q.resolve($rootScope.authenticated);
        }, function () {
            $rootScope.authenticated = false;
            return $q.resolve(false);
        });
    };
    this.authorize = function (roles, nextRoute) {
        if (self.isGloballyAuthorized(roles)) return $q.resolve(true);

        return self.authenticateUser().then(function (isUserAuthenticated) {
            if (isUserAuthenticated) {
                if (!self.isAuthorized(roles)) {
                    throw new AuthorizationError($translate.instant('global.modal.error.titleautherror'), $translate.instant('global.modal.error.403'));
                } else if (roles.indexOf(USER_ROLES.manager) !== -1 && Session.isUserOnlyEditor) {
                    var route = nextRoute.split('/')[1];
                    switch (Session.editorPermissions.availableFeeds) {
                        case AVAILABLE_FEEDS.ALL:
                            break;
                        case AVAILABLE_FEEDS.TIMELINE:
                            if (route == 'knowledge-center') {
                                throw new AuthorizationError($translate.instant('global.modal.error.titleautherror'), $translate.instant('global.modal.error.403'));
                            }
                            break;
                        case AVAILABLE_FEEDS.KNOWLEDGE_CENTER:
                            if (route == 'messages' || route == 'questionnaires') {
                                throw new AuthorizationError($translate.instant('global.modal.error.titleautherror'), $translate.instant('global.modal.error.403'));
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                $rootScope.$broadcast('event:auth-loginRequired');
            }
            return isUserAuthenticated;
        });
    };
    this.isGloballyAuthorized = function (authorizedRoles) {
        return (!angular.isArray(authorizedRoles) && authorizedRoles === USER_ROLES.all) || (authorizedRoles.indexOf(USER_ROLES.all) !== -1);
    };
    this.isAuthorized = function (authorizedRoles) {
        if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
        }

        var isAuthorized = false;
        angular.forEach(authorizedRoles, function (authorizedRole) {
            var authorized = (!!Session.username && Session.roles.indexOf(authorizedRole) !== -1);

            if (authorized || authorizedRole === USER_ROLES.all) {
                isAuthorized = true;
            }
        });
        return isAuthorized;
    };
    this.isEmployeeManagerOnly = function () {
        return Session.roles.indexOf(USER_ROLES.admin) == -1 &&
               Session.roles.indexOf(USER_ROLES.platformAdmin) == -1 && 
               Session.roles.indexOf(USER_ROLES.employeeManager) != -1;
    };
    this.userLoggedHasRole = function (role) {
        return Session.roles.indexOf(role) !== -1;
    };
    this.getSessionUser = function (data) {
        if (!Session.username || !$rootScope.account) {
            Session.create(data);
            $rootScope.setLanguage(Session.language);
        }
        return Session;
    };
    this.login = function (username, password, rememberme, companyLoginConfiguration) {
        var loginUrl = '/login';
        var loginData = "username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&rememberme=" + rememberme;
        if (companyLoginConfiguration && companyLoginConfiguration.loginKind === LOGIN_KIND.CUSTOM) {
            loginUrl = '/users/external-login';
            loginData += "&c=" + companyLoginConfiguration.company;
        }
        return $http({
            url: SharedDataService.apiUrl + loginUrl,
            method: 'POST',
            data: loginData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            ignoreAuthModule: 'ignoreAuthModule',
            ignoreErrorInterceptor: true
        }).then(function (response) {
            self.saveAuthorizationToken(response);
            var user = response.data;
            Session.create(user);
            $rootScope.account = Session;
            $rootScope.setLanguage(user.language);
            authService.loginConfirmed(user);

            return $q.resolve();
        }, function (error) {
            Session.invalidate();
            return $q.reject(error);
        });
    };
    this.logout = function () {
        $http.post(SharedDataService.apiUrl + '/logout').then(function () {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            $rootScope.authenticated = false;
            $rootScope.account = null;

            authService.loginCancelled();
        });
    };
    this.checkIfModuleIsActiveForUserCompany = function (module) {
        if (!Session.activeModules) {
            return false;
        }
        return Session.activeModules[module] && (Session.activeModules[module].state == '1' || Session.activeModules[module].state == '2');
    };
    $window.checkIfModuleIsActiveForUserCompany = this.checkIfModuleIsActiveForUserCompany;
    this.getUserAuthenticationStatus = function () {
        if (typeof $rootScope.authenticated === 'undefined' || $rootScope.authenticated === null) {
            return self.authenticateUser(true);
        } else {
            return $q.resolve($rootScope.authenticated);
        }
    };
    this.saveAuthorizationToken = function (response) {
        var authorizationBearer = response.headers('authorization');
        if (!!authorizationBearer) {
            authorizationBearer = authorizationBearer.replace(/^Bearer /, '');
            localStorage.setItem('accessToken', authorizationBearer);
        }
    };
}]);
