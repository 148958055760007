'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:messageCategoryFilter
 * @function
 * @description
 * # messageCategoryFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('messageCategoryFilter',
    ['$filter', '$sce',
    function ($filter, $sce) {
    return function (category) {
        if (!!category) {
            var categoryColor;
            if (category.hierarchy && category.hierarchy.length > 0) {
                categoryColor = $filter('messageCategoryColorFilter')(category, category.hierarchy.length - 1);
            } else {
                categoryColor = category.color;
            }
            return $sce.trustAsHtml('<span class="message-category" style="background-color:' +
                $filter('numberColorFilter')(categoryColor) + ';">' + category.name + '</span>');
        } else {
            return '';
        }
        
    };
}]);
