'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.ContactInfoService
 * @description
 * # ContactInfoService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ContactInfoService',
    ['$http', 'SharedDataService', 
    function ($http, SharedDataService) {

    this.URL = SharedDataService.apiUrl + '/companies/mine/contact-information';

    this.getCompanyContactInfo = function () {
        return $http({
            url: this.URL,
            method: 'GET'
        });
    };

    this.updateContactInfo = function(data) {
        return $http({
            url: this.URL,
            method: 'PUT',
            data: data
        }); 
    }

    
}]);