'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:PasswordUpdatedController
 * @description
 * # PasswordUpdatedController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('PasswordUpdatedController',
    ['$scope', '$rootScope', 'APP_PLATFORMS', 'APP_LINKS', 'resolvedParams',
    function ($scope, $rootScope, APP_PLATFORMS, APP_LINKS, resolvedParams) {

    var dialengaLogoPath = '/ng1/assets/img/logo-dialenga-texto.svg';
    var dialengaAppLogoPath = '/ng1/assets/img/dialenga-app-icon.png';


    $scope.title = resolvedParams.title;
    $scope.APP_PLATFORMS = APP_PLATFORMS;

    $scope.userAgentOS = null;
    $scope.platforms = APP_PLATFORMS;

    //******************************************* Methods ***************************************\\
    var getMobileOperatingSystem = function () {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return null;
        }

        if (/android/i.test(userAgent)) {
            return APP_PLATFORMS.ANDROID;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return APP_PLATFORMS.IOS;
        }

        return null;
    }

    function setDefaultValuesForMissingProperties() {
        $scope.userData.appName = $rootScope.userInfo.appName || 'Dialenga';
        $scope.userData.brandLogoURL = $rootScope.userInfo.brandLogoURL || dialengaLogoPath;
        $scope.userData.appLogoURL = $rootScope.userInfo.appLogoURL || dialengaAppLogoPath;
        $scope.userData.googlePlay = $rootScope.userInfo.googlePlay || APP_LINKS.ANDROID;
        $scope.userData.appStore = $rootScope.userInfo.appStore || APP_LINKS.IOS;
        $scope.userData.isAppUser = $rootScope.userInfo.isAppUser !== false;
        $scope.userData.isBackofficeUser = !!$rootScope.userInfo.isBackofficeUser;
    }

    var init = function () {
        if (!$rootScope.userInfo) {
            $rootScope.userInfo = {};
        }
        $scope.userData = $rootScope.userInfo;
        setDefaultValuesForMissingProperties();

        $scope.userAgentOS = getMobileOperatingSystem();
    };

    init();
}]);
