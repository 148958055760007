/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsWorkplaceHolidaysController
 * @description
 * # TimeoffSettingsWorkplaceHolidaysController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsWorkplaceHolidaysController',
    ['$rootScope', '$routeParams', '$translate', 'KendoUtilsService', 'ModalService', 'NotificationService', 'TimeoffSettingsWorkplacesService',
    function($rootScope, $routeParams, $translate, KendoUtilsService, ModalService, NotificationService, TimeoffSettingsWorkplacesService) {
        var vm = this;
        vm.years = [];
        vm.workplaceHolidays = [];
        vm.isSyncing = false;

        var removeAddWorkplaceHolidaysEventListener;

        vm.$onInit = function () {
            initExternalActionEvent();
            initializeAvailableYears();
            vm.selectedYear = vm.years[0];
            vm.yearsDropDownListOptions = getYearsDropDownListOptions();
            getSelectedYearHolidays();
        };

        vm.$onDestroy = function() {
            removeAddWorkplaceHolidaysEventListener();
        };

        function initExternalActionEvent() {
            if (!removeAddWorkplaceHolidaysEventListener) {
                removeAddWorkplaceHolidaysEventListener = $rootScope.$on('dialenga:addWorkplaceHolidays', function() {
                    vm.openHolidaysEditionModal();
                });
            }
        }

        function initializeAvailableYears() {
            var actualYear = new Date().getFullYear();
            for (var index = 0; index <= 10; index++) {
                vm.years.push(actualYear + index);
            }
        }

        function getYearsDropDownListOptions() {
            var options = KendoUtilsService.getDropDownListOptions();
            options.dataSource = vm.years;
            options.dataTextField = '';
            options.dataValueField = '';
            options.valuePrimitive = true;
            return options;
        }

        function getSelectedYearHolidays() {
            vm.isSyncing = true;
            TimeoffSettingsWorkplacesService.getHolidays($routeParams.id, vm.selectedYear).then(function (response) {
                vm.workplaceHolidays = response.data;
                vm.isSyncing = false;
            });
        }

        vm.openHolidaysDeleteModal = function(holidays, index) {
            ModalService.openConfirmationModal({
                title: 'workplaces.holidays.modal.delete.title',
                message: 'workplaces.holidays.modal.delete.message',
                messageData: {holidayName: holidays.name},
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    TimeoffSettingsWorkplacesService.deleteHolidaysFromWorkplace($routeParams.id, holidays.id).then(function () {
                        var message = $translate.instant('workplaces.holidays.notification.delete.message', {name: holidays.name});
                        NotificationService.notifySuccess(message);
                        getSelectedYearHolidays();
                    });
                }
            });
        };

        function saveHolidays(holidays) {
            var saveHolidaysService;
            if (holidays.id) {
                saveHolidaysService = TimeoffSettingsWorkplacesService.updateHolidaysInWorkplace;
            } else {
                saveHolidaysService = TimeoffSettingsWorkplacesService.createHolidaysInWorkplace;
            }
            return saveHolidaysService.call(TimeoffSettingsWorkplacesService, $routeParams.id, holidays);
        }

        vm.openHolidaysEditionModal = function(holidays) {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-settings-workplace/timeoff-settings-workplace-holidays-modal.html',
                controller: 'TimeoffSettingsWorkplaceHolidaysModalController',
                resolveParams: {
                    holidays: !!holidays ? angular.copy(holidays) : { day: 1, month: 1, year: vm.selectedYear }
                },
                accept: function (holidaysUpdated) {
                    saveHolidays(holidaysUpdated).then(function () {
                        var messageKey = !holidaysUpdated.id ? 'workplaces.holidays.notification.add.message' : 'workplaces.holidays.notification.edit.message';
                        var message = $translate.instant(messageKey);
                        NotificationService.notifySuccess(message);
                        getSelectedYearHolidays();
                    });
                }
            });
        };

        vm.onYearChange = function () {
            getSelectedYearHolidays();
        };

        vm.getFormattedDate = function (holidays) {
            var monthName = moment.months(holidays.month-1);
            return $translate.instant('workplaces.holidays.formattedDate', {day: holidays.day, month: monthName});
        };

        vm.preventDoubleClick = function (event) {
            event.stopPropagation();
        };

    }
]);