'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:stopEvent
 * @description
 * # stopEvent
 */
angular.module('dialengaExeviBackoffice').directive('stopEvent', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            element.bind(attributes.stopEvent, function (event) {
                event.stopPropagation();
            });
        }
    };
});
