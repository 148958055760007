(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:questionItem
     * @description
     * # questionItem
     */
    angular.module('dialengaExeviBackoffice').component('questionItem', {
        bindings: {
            question: '<',
            index: '<',
            isFirst: '<',
            isLast: '<',
            linkedItems: '<',
            isTray: '<?',
            hasCover: '<',
            questionnaireKind: '<',
            showEndingElement: '<?'
        },
        templateUrl: '/ng1/components/questionnaire-question/question-item.html',
        controllerAs: 'vm',
        controller: ['$location', '$rootScope', '$routeParams', '$window', 'ModalService', 'QuestionnaireService', 'FeedbackTraysService', 'QUESTION_KIND', 'TRAY_QUESTION_KIND', 'QUESTIONNAIRE_KIND',
            function($location, $rootScope, $routeParams, $window, ModalService, QuestionnaireService, FeedbackTraysService, QUESTION_KIND, TRAY_QUESTION_KIND, QUESTIONNAIRE_KIND) {
            var vm = this;

            vm.$onInit = function() {
                vm.showAllAnswersOptions = false;
                vm.answersToShow = 5;
                vm.questionKinds = vm.questionnaireKind === QUESTIONNAIRE_KIND.SURVEY ? QUESTION_KIND : TRAY_QUESTION_KIND;
            };

            vm.editQuestion = function (questionId) {
                if (vm.isTray) {
                    $location.path('/settings/conversations/feedback-trays/questionnaire/' + $routeParams.id + '/questions/' + questionId);
                } else {
                    $location.path('/questionnaires/' + $routeParams.id + '/questions/' + questionId);
                }
            };

            vm.deleteQuestion = function (questionId, questionKind, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                ModalService.openConfirmationModal({
                    title: 'global.form.delete',
                    message: questionKind === vm.questionKinds.ENDING ? 'questionnaire.modal.delete.ending' : 'questionnaire.modal.delete.question',
                    focusCancel: true,
                    hideCancelButton: false,
                    accept: function () {
                        if (vm.isTray) {
                            FeedbackTraysService.deleteQuestion($routeParams.id, questionId, 'questionnaire.question.error.delete').then(function() {
                                $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                            });
                        } else {
                            QuestionnaireService.deleteQuestion($routeParams.id, questionId, 'questionnaire.question.error.delete').then(function() {
                                $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                            });
                        }
                    }
                });
            };

            vm.editAnswers = function (questionId) {
                if (vm.isTray) {
                    $location.path('/settings/conversations/feedback-trays/questionnaire/' + $routeParams.id + '/questions/' + questionId);
                } else {
                    $location.path('/questionnaires/' + $routeParams.id + '/questions/' + questionId);
                }
                $location.hash('answers');
            };

            vm.deleteAnswer = function (questionId, answerId, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (vm.isTray) {
                    FeedbackTraysService.deleteAnswer($routeParams.id, questionId, answerId).then(function () {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                } else {
                    QuestionnaireService.deleteAnswer($routeParams.id, questionId, answerId).then(function () {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                }
            };

            function reorderQuestion(questionnaireId, questionId, newOrder) {
                if (vm.isTray) {
                    FeedbackTraysService.reorderQuestion($routeParams.id, questionId, newOrder).then(function () {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                } else {
                    QuestionnaireService.reorderQuestion(questionnaireId, questionId, newOrder).then(function () {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                }
            }

            //Necessary to call function from Angular code in sortable-question-list.component.ts file
            $window.reorderQuestion = reorderQuestion;

            vm.reorderItem = function(questionId, newOrder, $event){
                $event.preventDefault();
                $event.stopPropagation();
                reorderQuestion($routeParams.id, questionId, newOrder);
            };

            vm.collapsePanel = function(question, $event) {
                var eventName = vm.isTray ? 'dialenga:collapseQuestionPanelInTray' : 'dialenga:collapseQuestionPanel';
                $rootScope.$broadcast(eventName, {event: $event, item: question});
            };
        }]
    });
})();