'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.experienceService
 * @description
 * # experienceService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ExperienceService',
    ['$http', 'SharedDataService', 'PAGE_SIZE',
    function ($http, SharedDataService, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/feedback';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.statistics = function () {
        return $http({
            url: this.URL + '/statistics',
            method: 'GET'
        });
    };

}]);
