(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:dialengaTooltip
     * @description
     * # dialengaTooltip
     */
    angular.module('dialengaExeviBackoffice').component('dialengaTooltip', {
        bindings: {
            tooltipText: '<',
            classes: '@?'
        },
        controller: function () {
            var vm = this;
        },
        controllerAs: 'vm',
        template: '<span class="{{vm.classes}}" kendo-tooltip k-content="\'{{vm.tooltipText}}\'"></span>'
    });
})();