(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:questionLabel
     * @description
     * # questionLabel
     */
    angular.module('dialengaExeviBackoffice').component('questionLabel', {
        bindings: {
            kind: '<',
            order: '<',
            tooltip: '<?',
            isIncomplete: '<?',
            hideOrder: '<?',
            questionnaireKind: '<'
        },
        templateUrl: '/ng1/components/questionnaire-question/question-label.html',
        controllerAs: 'vm',
        controller: ['$filter','QUESTIONNAIRE_KIND','QUESTION_KIND','TRAY_QUESTION_KIND', function($filter, QUESTIONNAIRE_KIND, QUESTION_KIND, TRAY_QUESTION_KIND) {
            var vm = this;

            this.$onInit = function() {
                setQuestionLabelIcon();
            };

            this.$onChanges = function(changes) {
                if (changes.kind && changes.kind.currentValue != changes.kind.previousValue &&
                    Number.isInteger(changes.kind.currentValue) && Number.isInteger(changes.kind.previousValue)) {
                    setQuestionLabelIcon();
                }
            };

            function setQuestionLabelIcon() {
                vm.iconClass = $filter('questionIcon')(vm.kind, vm.questionnaireKind == QUESTIONNAIRE_KIND.SURVEY ? QUESTION_KIND : TRAY_QUESTION_KIND);
                vm.kindClass = vm.iconClass.replace('icon-', '');
                vm.kindClass = vm.kindClass == 'attachment' ? 'add-attachment' : vm.kindClass;
                vm.kindClass = vm.kindClass == 'questionnaire-ending' && vm.questionnaireKind == QUESTIONNAIRE_KIND.TRAY ? 'tray-questionnaire-ending' : vm.kindClass;
            }
        }]
    });
})();