'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.videoService
 * @description
 * # videoService
 */
angular.module('dialengaExeviBackoffice').service('videoService', function () {

    /**
     * @name getVideoId
     * @desc Gets the id of a YouTube video.
     * @param {String} url URL to get the id from.
     * @returns {String}
     */
    function getVideoId(url) {
        var video_id = url.split('?v=')[1];
        if(video_id && video_id.length > 1){
            // Regular links
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
        }else{
            // Shortened links
            video_id = url.split('youtu.be/')[1];
            if(video_id && video_id.length > 1){
                var paramsPosition = video_id.indexOf('?');
                if(paramsPosition != -1) {
                    video_id = video_id.substring(0, paramsPosition);
                }
            }else{
                // Embeded links
                video_id = url.split('/embed/')[1];
                if (!video_id || video_id.length == 0) {
                    // Invalid links
                    video_id = false;
                }
            }
        }
        return video_id;
    }

    /**
     * @name getThumbnail
     * @desc Gets the first frame thumbnail from a YouTube video.
     * @param {String} video_url to search a thumbnail for.
     * @returns {String}
     */
    this.getThumbnail = function (video_url) {
        var video_id = getVideoId(video_url);
        if(video_id)
            return 'http://img.youtube.com/vi/' + video_id + '/hqdefault.jpg';
        else
            return "";
    };

});