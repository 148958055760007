'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:decimalNumber
 * @function
 * @description
 * # decimalNumber
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('decimalNumber', function () {
    return function (value, numberOfDecimals) {
        numberOfDecimals = numberOfDecimals || 2;
        return value ? parseFloat(value.toFixed(numberOfDecimals)).toString().replace('.', ',') : value;
    };
});
