'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.questionnaireService
 * @description
 * # questionnaireService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('QuestionnaireService',
    ['$http', '$translate', '$timeout', 'KendoUtilsService', 'LanguageService', 'Session', 'SharedDataService', 'PublicationService', 'PAGE_SIZE', 'QUESTIONNAIRE_KIND',
    function ($http, $translate, $timeout, KendoUtilsService, LanguageService, Session, SharedDataService, PublicationService, PAGE_SIZE, QUESTIONNAIRE_KIND) {

    var linkedQuestionTemplate = '<div>' +
            '<question-label class="inline-flex" kind="dataItem.kind" order="dataItem.order" questionnaire-kind="'+QUESTIONNAIRE_KIND.SURVEY+'"></question-label>' +
            ' <span class="question-label-title margin-left-10" ng-bind="dataItem.title"></span>' +
        '</div>';

    this.URL = SharedDataService.apiUrl + '/questionnaires';
    this.surveysURL = SharedDataService.apiUrl + '/surveys';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.create = function (data) {
        return $http({
            url: this.URL,
            method: 'POST',
            data: data.questionnaire
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL + '/' + data.questionnaire.id,
            method: 'PUT',
            data: data.questionnaire
        });
    };

    this.delete = function (id, errorMessage) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE',
            localizedErrorString: errorMessage
        });
    };

    this.publish = function (questionnaireId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/publish',
            method: 'PUT'
        });
    };

    this.pause = function (questionnaireId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/pause',
            method: 'PUT'
        });
    };

    this.requestValidation = function(questionnaireId, dataPublication) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/request-validation',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.validate = function (questionnaireId, dataPublication) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/validate',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.updatePublicationDate = function (questionnaireId, dataPublication) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/update-scheduling',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.expire = function (questionnaireId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/expire',
            method: 'PUT'
        });
    };

    this.duplicate = function (questionnaireId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/duplicate',
            method: 'POST'
        });
    };

    this.findOneQuestion = function (questionnaireId, questionId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId,
            method: 'GET'
        });
    };

    this.createQuestion = function (data, questionnaireId) {
        var fd = PublicationService.getPublicationAsFormData(data.question, 'question', data.images, data.resources);
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.updateQuestion = function (data, questionnaireId) {
        var fd = PublicationService.getPublicationAsFormData(data.question, 'question', data.images, data.resources);
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + data.question.id,
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.deleteQuestion = function (questionnaireId, questionId, errorMessage) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId,
            method: 'DELETE',
            localizedErrorString: errorMessage
        });
    };

    this.deleteAnswer = function (questionnaireId, questionId, answerId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId + '/answers/' + answerId,
            method: 'DELETE'
        });
    };

    this.setRecipients = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients',
            method: 'PUT',
            data: recipients
        })
    };

    this.getRecipientsCount = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients-count',
            method: 'POST',
            data: recipients
        })
    };

    this.linkWithQuestion = function (questionnaireId, questionId, answerId, questionToLinkId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId + '/answers/' + answerId + '/link-with-question',
            method: 'PUT',
            data: questionToLinkId
        })
    };

    this.unlink = function (questionnaireId, questionId, answerId) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId + '/answers/' + answerId + '/unlink',
            method: 'PUT'
        })
    };

    this.statistics = function (id) {
        return $http({
            url: this.URL + '/' + id + '/statistics',
            method: 'GET'
        });
    };

    this.activity = function (id, params) {
        return $http({
            url: this.URL + '/' + id + '/activity-detail',
            method: 'GET',
            params: params
        });
    };

    this.answersDistribution = function (id) {
        return $http({
            url: this.URL + '/' + id + '/answers-distribution',
            method: 'GET'
        });
    };

    this.surveyAnswerDistribution = function (id) {
        return $http({
            url: this.surveysURL + '/' + id + '/answer-distribution',
            method: 'GET'
        });
    };

    this.surveyQuestionResponses = function (id, questionId, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.surveysURL + '/' + id + '/questions/' + questionId + '/responses',
            method: 'GET',
            params: options
        });
    };

    this.reorderQuestion = function (questionnaireId, questionId, newOrder) {
        return $http({
            url: this.URL + '/' + questionnaireId + '/questions/' + questionId + '/reorder',
            method: 'PUT',
            data: newOrder
        })
    };

    this.getLinkedQuestionsDropDownListOptions = function(questions) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource = questions;
        options.dataTextField = 'title';
        options.template = linkedQuestionTemplate;
        options.valueTemplate = linkedQuestionTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    function setContentLanguage(item) {
        var selectedLanguage = Session.companyDefaultLanguage;
        if (!item.contents[selectedLanguage]) {
            item.language = LanguageService.getMainContentLanguage(item.contents);
        } else {
            item.language = selectedLanguage;
        }
    }
    
    this.setQuestionnaireContentLanguage = function (questionnaire) {
        for (var questionIndex = 0; questionIndex < questionnaire.questions.length; questionIndex++) {
            var question = questionnaire.questions[questionIndex];
            setContentLanguage(question);
            if (question.answers) {
                for (var answerIndex = 0; answerIndex < question.answers.length; answerIndex++) {
                    setContentLanguage(question.answers[answerIndex]);
                }
            }
        }

        for (var endingIndex = 0; endingIndex < questionnaire.endings.length; endingIndex++) {
            setContentLanguage(questionnaire.endings[endingIndex]);
        }
        
        if (!!questionnaire.cover) {
            questionnaire.cover.language = Session.companyDefaultLanguage;
        }
    };

    this.setUsersAvatarTooltips = function(users) {
        if(users) {
            $timeout(function () {
                for (var index = 0; index < users.length; index++) {
                    var item = users[index];
                    var tooltipAvatar = $('#avatar-user-' + item.user).data('kendoTooltip');
                    if (tooltipAvatar) {
                        tooltipAvatar.options.content = item.name + ' '+ item.surname;
                        tooltipAvatar.refresh();
                    } else {
                        break;
                    }
                }
                var tooltipRemaining = '';
                for (index = 5; index < users.length; index++) {
                    item = users[index];
                    tooltipRemaining += item.name + ' '+ item.surname + '<br>';
                    if (index == users.length - 1) {
                        tooltipAvatar = $('#remaining-users').data('kendoTooltip');
                        if (tooltipAvatar) {
                            tooltipAvatar.options.content = tooltipRemaining;
                            tooltipAvatar.refresh();
                        }
                    }
                }
            }, 300);
        }
    }

    this.orderRecipientsUsers = function(users) {
        return users.sort(function (a, b) { return a.name.localeCompare(b.name) || a.surname.localeCompare(b.surname) || 0; }); 
    }
}]);
