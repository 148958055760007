'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffRequestsController
 * @description
 * # TimeoffRequestsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffRequestsController',
    ['$scope', '$translate', '$timeout', 'KendoUtilsService', 'TimeoffService', 'Session', 'GRID_SHORT_DATE_COLUMN_WIDTH', 'GRID_DATE_COLUMN_WIDTH', 'APPROVAL_FLOW_KIND',
    'PAGE_SIZE', 'TIMEOFF_KIND', 'TIMEOFF_REQUEST_STATUS', 'TIMEOFF_REQUEST_STATUS_FILTER', 'TIMEOFF_REQUEST_DETAIL_TABS',
    function($scope, $translate, $timeout, KendoUtilsService, TimeoffService, Session, GRID_SHORT_DATE_COLUMN_WIDTH, GRID_DATE_COLUMN_WIDTH, APPROVAL_FLOW_KIND,
        PAGE_SIZE, TIMEOFF_KIND, TIMEOFF_REQUEST_STATUS, TIMEOFF_REQUEST_STATUS_FILTER, TIMEOFF_REQUEST_DETAIL_TABS) {

        var vm = this;
        var TIMEOFF_KIND_ALL = 0;
        vm.selectDetailTab = TIMEOFF_REQUEST_DETAIL_TABS.DETAIL;
        vm.inputSearchField = '';
        vm.selectedTeams = [];
        vm.getAbsenceMotiveClass = TimeoffService.getAbsenceMotiveClass;
        vm.pendingHumanResources = false;
        vm.TIMEOFF_REQUEST_STATUS = TIMEOFF_REQUEST_STATUS;
        vm.APPROVAL_FLOW_KIND = APPROVAL_FLOW_KIND;
        vm.TIMEOFF_KIND = TIMEOFF_KIND;
        vm.approvalFlow = Session.approvalFlow;
        vm.showAdvancedFilter = false;
        var pageChanged = false;
        var dataGridHasChanged = false;

        var gridColumns = [{
            field: 'state',
            title: ' ',
            template: '<span></span>', // Show empty column
            width: 6,
            attributes: { class: "{{vm.getTimeoffRequestClass(dataItem.state)}}" }
        }, {
            field: 'employee.fullName',
            title: $translate.instant('timeoff.requests.grid.employee'),
            template: '<user-avatar [user]="dataItem.employee"></user-avatar>',
            width: 240
        }, {
            field: 'kind',
            title: $translate.instant('timeoff.requests.grid.requestKind'),
            template: '<span ng-if="dataItem.kind == ' + TIMEOFF_KIND.HOLIDAYS + '" class="motive-tag motive-tag--holidays">' +
                '<span class="motive-tag__label" translate="timeoff.requests.kindFilters.holidays"></span>' +
                '</span>' +
                '<span ng-if="dataItem.kind == ' + TIMEOFF_KIND.ABSENCE + '" class="motive-tag motive-tag--absences" ' +
                'ng-class="vm.getAbsenceMotiveClass(dataItem.absenceMotive.groupKind)">' +
                '<span class="motive-tag__label" ng-if="!dataItem.absenceMotive" translate="timeoff.requests.kindFilters.absences"></span>' +
                '<span class="motive-tag__label" ng-if="!!dataItem.absenceMotive" ng-bind="dataItem.absenceMotive.name" kendo-tooltip k-content="dataItem.absenceMotive.name"></span>' +
                '</span>'
        }, {
            field: 'startDate,endDate',
            title: $translate.instant('timeoffRequests.requestedPeriod'),
            template: '<span ng-if="dataItem.startDate === dataItem.endDate" ng-bind="dataItem.startDate | shortDateFilter"></span>' +
                '<span class="truncate" ng-if="dataItem.startDate !== dataItem.endDate" translate="timeoffRequests.fromDateToDate" ' +
                'translate-values="{\'startDate\': (dataItem.startDate | shortDateFilter), \'endDate\': (dataItem.endDate | shortDateFilter)}"></span>',
                width: GRID_DATE_COLUMN_WIDTH
        }, {
            field: 'warnings',
            title: ' ',
            width: 45,
            attributes: {style: "padding-left: 18px"},
            template: '<span class="flex no-hover padding-0">' +
                           '<span ng-if="!!dataItem.warnings && dataItem.warnings.length > 0" class="circle-indicator circle-indicator--danger"' +
                           ' data-ng-click="vm.openWarningsDetail()" kendo-tooltip k-content="\'timeoff.requests.grid.conflictTooltip\' | translate"></span>' +
                       '</span>',
            sortable: false
        }, {
            field: 'requestedDays',
            title: $translate.instant('timeoff.requests.grid.requestedTime'),
            template: '<div class="flex justify-space-between">' +
                            '<span ng-bind="vm.getRequestedTime(dataItem)"></span>' +
                            '<div class="flex align-items-center">' +
                                '<span class="flex no-hover k-grid-edit-button min-width-40 padding-0" style="font-size: 22px">' +
                                    '<span ng-if="dataItem.hasComments" class="icon icon-comment-fill" data-ng-click="vm.openDetailComments()"></span>' +
                                '</span>' +
                                '<span class="flex no-hover k-grid-edit-button min-width-40 padding-0" style="font-size: 22px">' +
                                    '<span ng-if="dataItem.hasAttachments" class="icon icon-attachment"></span>' +
                                    '<span ng-if="!dataItem.hasAttachments && dataItem.absenceMotive.requiresNote" kendo-tooltip k-content="\'timeoff.requests.grid.requestNoteTooltip\' | translate"' +
                                        ' class="icon icon-warning-circle-fill warning-light-intermediate-color white-on-selected"></span>' +
                                '</span>' +
                            '</div>' +
                        '</div>',
            width: 220
        }, {
            field: 'requestDate',
            title: $translate.instant('timeoff.requests.grid.requestDate'),
            type: 'datetime',
            format: '{0:dd/MM}',
            width: GRID_SHORT_DATE_COLUMN_WIDTH
        }];

        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.page = options.data.page - 1;
                    options.data.size = options.data.pageSize;
                    if (vm.pendingHumanResources) {
                        options.data.state = [TIMEOFF_REQUEST_STATUS_FILTER.APPROVED_BY_RESPONSIBLE];
                    } else {
                        options.data.state = TimeoffService.getStateFilter(vm.selectedState);
                    }
                    options.data.kind = TimeoffService.getKindFilter(vm.selectedButton);
                    options.data.sort = TimeoffService.getSortCriteria(options.data.sort, ['requestDate,desc']);
                    options.data['employee.team.id'] = vm.selectedTeams;                   
                    saveGridPreferences(options);

                    if (vm.inputSearchField && KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                        options.data['employee.name'] = vm.inputSearchField;
                    }
                    TimeoffService.findAll(options.data).then(function(response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: 'content',
                total: 'totalElements',
                parse: function(data) {
                    $.each(data.content, function(idx, elem) {
                        if (elem.requestDate) {
                            data.content[idx].requestDate = new Date(elem.requestDate);
                        }
                        if (elem.employee && elem.employee.name && elem.employee.surname) {
                            data.content[idx].employee.initials = elem.employee.name.charAt(0) + elem.employee.surname.charAt(0);
                            data.content[idx].employee.fullName = elem.employee.name + ' ' + elem.employee.surname;
                        }
                    });
                    return data;
                }
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true }, { change: onRowSelectionChange, dataBound: onDataBound }).then(function(response) {
            vm.gridOptions = response;
            vm.gridOptions.pageable.change = function() {
                pageChanged = true;
            };
        });

        vm.getTimeoffRequestClass = function(state) {
            var timeoffRequestClass = '';
            switch (state) {
                case TIMEOFF_REQUEST_STATUS.PENDING:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR:
                    timeoffRequestClass = 'bg-pending-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.APPROVED:
                    timeoffRequestClass = 'bg-approved-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.CANCELLED:
                    timeoffRequestClass = 'bg-cancelled-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.DISALLOWED:
                case TIMEOFF_REQUEST_STATUS.REJECTED:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_HHRR:
                    timeoffRequestClass = 'bg-rejected-color';
                    break;
                default:
                    break;
            }
            return timeoffRequestClass;
        };

        vm.timeoffKinds = [
            { id: TIMEOFF_KIND_ALL, title: 'timeoff.requests.kindFilters.all' },
            { id: TIMEOFF_KIND.HOLIDAYS, title: 'timeoff.requests.kindFilters.holidays' },
            { id: TIMEOFF_KIND.ABSENCE, title: 'timeoff.requests.kindFilters.absences' }
        ];

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['timeoffRequestsGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['timeoffRequestsGrid'].selectedState = vm.selectedState;
            KendoUtilsService.gridPreferences['timeoffRequestsGrid'].selectedButton = vm.selectedButton;
            KendoUtilsService.gridPreferences['timeoffRequestsGrid'].inputSearchField = vm.inputSearchField;
        }

        $scope.$on('kendoWidgetCreated', function(event, widget) {
            if (widget === vm.timeoffRequestsGrid) {
                if (KendoUtilsService.gridPreferences['timeoffRequestsGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['timeoffRequestsGrid'];
                    $timeout(function () {
                        // Timeout needed for Kendo dropdown to refresh the selected state
                        vm.selectedState = requestOptions.selectedState;
                        vm.selectedButton = requestOptions.selectedButton;
                        vm.inputSearchField = requestOptions.inputSearchField;

                        vm.timeoffRequestsGrid.dataSource.query(requestOptions);
                        $scope.$emit('dialenga:timeoffRequestsFiltersActives', {kind: vm.selectedButton, state: vm.selectedState });

                    });
                } else {
                    $timeout(function () {
                        // Timeout needed for Kendo dropdown to refresh the selected state
                        if (!!TimeoffService.navigateConditionsFromUser) {
                            vm.inputSearchField = TimeoffService.navigateConditionsFromUser.inputSearchField;
                            vm.selectedButton = TimeoffService.navigateConditionsFromUser.requestKind;
                            vm.selectedState = TimeoffService.navigateConditionsFromUser.requestState;
                            vm.timeoffRequestsGrid.dataSource.query(requestOptions);
                            delete TimeoffService.navigateConditionsFromUser;
                        } else {
                            vm.selectedState = [TIMEOFF_REQUEST_STATUS.PENDING];
                            vm.selectedButton = TIMEOFF_KIND_ALL;
                            vm.timeoffRequestsGrid.dataSource.read();
                        }
                        $scope.$emit('dialenga:timeoffRequestsFiltersActives', {kind: vm.selectedButton, state: vm.selectedState });

                    });
                }
            }
        });

        $scope.$on('dialenga:reloadTimeoffRequestsGrid', function(event) {
            reloadGrid({ page: 1 });
        });

        vm.openDetailComments = function() {
            vm.clickOnCommentIcon = true;
        }
        vm.openWarningsDetail = function() {
            vm.clickOnWarningsIcon = true;
        }

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.timeoffRequestsGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
                $scope.$emit('dialenga:timeoffRequestsFiltersActives', {kind: vm.selectedButton, state: vm.selectedState });

            });
        }

        vm.reloadGridFromDetailComponent = function(selectFirstRow, keepRow) {
            var gridPage;
            dataGridHasChanged = true;

            if (selectFirstRow === true) {
                // Select first row: when sending a new message, grid is refreshed and selected request becomes the first one.
                // By default, the selection is lost when refreshing the grid, so we manually select the first row.
                vm.selectedRequest.dataPosition.newIndexPosition = 0;
                gridPage = 1;
            } else {
                if (keepRow) {
                    if (vm.selectedRequest.dataPosition.newIndexPosition > 0) {
                        vm.selectedRequest.dataPosition.newIndexPosition++;
                    }
                }
                gridPage = vm.selectedRequest.dataPosition.actualPage;
            }
            var reloadOptions = {
                page: gridPage,
                pageSize: vm.timeoffRequestsGrid.dataSource.pageSize()
            };
            reloadGrid(reloadOptions);
        };

        vm.deselectRequest = function() {
            $timeout(function() {
                vm.selectedRequest = null;
                vm.selectDetailTab = TIMEOFF_REQUEST_DETAIL_TABS.DETAIL;
                $scope.$parent.vm.selectedRequest = null;
            }, 0);
        };

        vm.deselectRequestAndClearSelectedRow = function() {
            vm.deselectRequest();
            vm.currentSelectedRowIndex = -1;
            vm.timeoffRequestsGrid.clearSelection();
        };

        function getRowPositionIfGridItemsChange() {
            var dataPosition = {};
            dataPosition.actualPage = vm.timeoffRequestsGrid.dataSource.page();
            dataPosition.rowSelectedIndex = vm.currentSelectedRowIndex;
            dataPosition.itemsLeftOnPageAfter = vm.timeoffRequestsGrid.items().length - 1;

            if (dataPosition.itemsLeftOnPageAfter > 0) {
                dataPosition.newIndexPosition = dataPosition.rowSelectedIndex > 0 ? dataPosition.rowSelectedIndex - 1 : 0;
            } else {
                dataPosition.newIndexPosition = 0;
                dataPosition.actualPage = dataPosition.actualPage > 1 ? dataPosition.actualPage - 1 : 1;
            }
            return dataPosition;
        }

        function onRowSelectionChange(e) {
            vm.selectDetailTab = vm.clickOnCommentIcon ? TIMEOFF_REQUEST_DETAIL_TABS.CONVERSATION : TIMEOFF_REQUEST_DETAIL_TABS.DETAIL;
            vm.openWarnings = vm.clickOnWarningsIcon ? true : false;
            vm.clickOnCommentIcon = vm.clickOnWarningsIcon = false;

            var selectedRow = this.select();
            var selectedRowIndex = selectedRow.index();

            if (selectedRowIndex !== -1 && vm.currentSelectedRowIndex !== selectedRowIndex) {
                vm.currentSelectedRowIndex = selectedRowIndex;
                var selectedRowData = vm.timeoffRequestsGrid.dataItem(selectedRow[0]);
                TimeoffService.getRequestDetail(selectedRowData.kind, selectedRowData.id).then(function(response) {
                    vm.selectedRequest = response.data;
                    vm.selectedRequest.dataPosition = getRowPositionIfGridItemsChange();
                    $scope.$parent.vm.selectedRequest = response.data;
                });
            } else if (selectedRowIndex !== -1 && vm.currentSelectedRowIndex === selectedRowIndex) {
                if (!pageChanged && !dataGridHasChanged) {
                    vm.deselectRequestAndClearSelectedRow();
                } else {
                    pageChanged = false;
                    vm.currentSelectedRowIndex = selectedRowIndex;
                    var selectedRowData = vm.timeoffRequestsGrid.dataItem(selectedRow[0]);
                    TimeoffService.getRequestDetail(selectedRowData.kind, selectedRowData.id).then(function(response) {
                        vm.selectedRequest = response.data;
                        vm.selectedRequest.dataPosition = getRowPositionIfGridItemsChange();
                        $scope.$parent.vm.selectedRequest = response.data;
                    });
                }
            } else {
                vm.deselectRequest();
            }
        }

        function onDataBound(e) {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
            var items = e.sender.items();
            items.each(function (index) {
                var dataItem = vm.timeoffRequestsGrid.dataItem(this);
    
                if (dataItem.state === TIMEOFF_REQUEST_STATUS.CANCELLED) {
                    this.className += " opacity-50";
                }
            });

            if (items.length == 0) {
                vm.deselectRequestAndClearSelectedRow();
            }
            
            if (pageChanged && vm.selectedRequest) {
                this.select(items[0]);
                pageChanged = false;
            }            

            if (dataGridHasChanged && vm.selectedRequest) {
                this.select(items[vm.selectedRequest.dataPosition.newIndexPosition]);
                dataGridHasChanged = false;
            }            
        }

        vm.setSelected = function(kind) {
            vm.selectedButton = kind.id;
            vm.deselectRequest();
            reloadGrid({ page: 1 });
        };

        vm.listItemsSearch = function(text) {
            vm.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                vm.deselectRequest();
                reloadGrid({ page: 1 });
            }
        };

        vm.onStateChange = function() {
            if (typeof vm.selectedState !== 'undefined') {
                vm.pendingHumanResources = false;
                vm.deselectRequest();
                reloadGrid({ page: 1 });
            }
        };

        vm.onTeamsChange = function() {
            if (typeof vm.selectedTeams !== 'undefined') {
                vm.pendingHumanResources = false;
                vm.deselectRequest();
                reloadGrid({ page: 1 });
            }
        }
        
        vm.onlyPendingHHRR = function() {
            vm.pendingHumanResources = !vm.pendingHumanResources;
            vm.deselectRequest();
            reloadGrid({ page: 1});
        }

        vm.getRequestedTime = function(request) {
            return TimeoffService.getRequestedTime(request, true);
        };

        vm.openMultiselect = function () {
            var multiselect = $('#states').data('kendoMultiSelect');
            multiselect.open();
        };

        vm.openMultiselectTeams = function () {
            var multiselectTeam = $('#filter-teams').data('kendoMultiSelect');
            multiselectTeam.open();
        };

        vm.setAdvancedFilter = function () {
            vm.showAdvancedFilter = !vm.showAdvancedFilter;
            if (!vm.showAdvancedFilter && vm.selectedTeams.length > 0) {
                vm.selectedTeams = [];
                angular.forEach($('#filter-teams').data('kendoMultiSelect').dataItems(), function(team) {
                    team.enabled = false;
                });
                reloadGrid({ page: 1});
            }
        }

        function init() {
            if (!TimeoffService.navigateConditionsFromUser) {
                vm.timeoffStateOptions = TimeoffService.getTimeoffStatesDropDownListOptions();
            } else {
                vm.timeoffStateOptions = TimeoffService.getTimeoffStatesDropDownListOptions(TimeoffService.navigateConditionsFromUser.requestState);
            }
            vm.selectedTeamsOptions = TimeoffService.getSelectedTeamsDropDownListOptions();
        }

        init();

    }
]);