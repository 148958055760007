'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:HomeController
 * @description
 * # HomeController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('HomeController', ['$http', '$location', '$scope', 'AuthenticationService', 'SharedDataService', 'KendoUtilsService', 'QuestionnaireService', 'ConversationsService', 'UsersService',
    'Session', 'CONTENT_KIND', 'RATING_KIND', 'USER_ROLES', 'GRID_CATEGORY_COLUMN_WIDTH', 'GRID_DATE_COLUMN_WIDTH', 'GRID_ICON_COLUMN_WIDTH', 'GRID_RATING_COLUMN_WIDTH', 'MODULES', 'MESSAGE_STATES', 'EXPIRATION_MODE', 'PUBLICATION_SCHEDULED_KIND', 'AVAILABLE_FEEDS',
    function($http, $location, $scope, AuthenticationService, SharedDataService, KendoUtilsService, QuestionnaireService, ConversationsService, UsersService,
        Session, CONTENT_KIND, RATING_KIND, USER_ROLES, GRID_CATEGORY_COLUMN_WIDTH, GRID_DATE_COLUMN_WIDTH, GRID_ICON_COLUMN_WIDTH, GRID_RATING_COLUMN_WIDTH, MODULES, MESSAGE_STATES, EXPIRATION_MODE, PUBLICATION_SCHEDULED_KIND, AVAILABLE_FEEDS) {

        //***************************************** Attributes **************************************\\
        $scope.userRoles = USER_ROLES;
        $scope.states = MESSAGE_STATES;
        $scope.EXPIRATION_MODE = EXPIRATION_MODE;
        $scope.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;
        $scope.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;

        $scope.links = [{
            description: 'home.shortcuts.createMessage',
            icon: 'icon icon-create-message',
            url: '/messages/create',
            allowedRoles: [
                USER_ROLES.admin,
                USER_ROLES.publisher,
                USER_ROLES.manager
            ]
        }, {
            description: 'home.shortcuts.createDialogue',
            icon: 'icon icon-create-dialog',
            url: '/questionnaires',
            allowedRoles: [
                USER_ROLES.admin,
                USER_ROLES.publisher,
                USER_ROLES.manager
            ]
        }, {
            description: 'home.shortcuts.createKnowledgePill',
            icon: 'icon icon-create-knowledge',
            url: '/knowledge-center/create',
            allowedRoles: [
                USER_ROLES.admin,
                USER_ROLES.publisher,
                USER_ROLES.manager
            ],
            module: MODULES.KNOWLEDGE_CENTER
        }];

        if (AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.CONVERSATIONS)) {
            $scope.links.push({
                description: 'home.shortcuts.createConversation',
                icon: 'icon icon-new-conversation',
                url: '/requests',
                allowedRoles: [
                    USER_ROLES.admin
                ],
            });
        }

        $scope.gridOptions = {
            columns: [{
                    field: "kind",
                    attributes: { class: 'icon-cell' },
                    title: " ",
                    template: '<i class="{{dataItem.kind | contentKindIconFilter}}" ' +
                        'kendo-tooltip k-content="\'{{dataItem.kind}}\' | contentKindTooltipFilter"></i>',
                    width: GRID_ICON_COLUMN_WIDTH
                },
                {
                    field: "title",
                    headerTemplate: '<span translate="model.message.title"></span>',
                    template: '<div class="truncate-container-list"><span class="icon icon-heisenberg icon-22 margin-right-5" kendo-tooltip k-content="\'questionnaires.preview.anonymousSurvey\' | translate"' +
                        'ng-if="!!dataItem.anonymous"></span>' +
                        '<span class="truncate" data-ng-bind="dataItem.title"></span></div>'
                },
                {
                    field: "category.name",
                    headerTemplate: '<span translate="model.message.category"></span>',
                    template: '<span data-ng-bind-html="dataItem.category | messageCategoryFilter"></span>',
                    width: GRID_CATEGORY_COLUMN_WIDTH
                },
                {
                    field: "publicationDate",
                    headerTemplate: '<span translate="model.message.publicationDate"></span>',
                    type: "datetime",
                    format: "{0:dd/MM/yyyy HH:mm}",
                    width: GRID_DATE_COLUMN_WIDTH
                },{
                    field: "autoExpiration.autoExpirationEnabled",
                    title: " ",
                    template: '<div data-ng-if="dataItem.autoExpiration.autoExpirationEnabled == true">' +
                                    '<div data-ng-if="!expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) || (expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && (dataItem.state == states.EXPIRED || dataItem.state == states.PUBLISHED))">' +
                                        '<div ng-if="!dataItem.expirationMode || dataItem.expirationMode == EXPIRATION_MODE.AUTO_EXPIRED">' +
                                            '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDate\' | translate:dataItem" </span>' +
                                        '</div>' +
                                        '<div ng-if="dataItem.expirationMode == EXPIRATION_MODE.MANUALLY">' +
                                            '<span class="icon icon-autoarchive icon-40 grey-icon-color opacity-50" kendo-tooltip k-content="\'model.message.tooltipExpirationDateManual\' | translate:dataItem"></span>' +
                                        '</div>' +
                                    '</div>' +
                                    '<div data-ng-if="expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && dataItem.state !== states.EXPIRED && dataItem.state !== states.PUBLISHED">' +
                                        '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED">' +
                                            '<span class="icon icon-autoarchive icon-40 red-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDateExpired\' | translate:dataItem"></span>' +
                                        '</div>' +
                                        '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW">' +
                                            '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="getTooltipForScheduledNow(dataItem.autoExpiration)"></span>' +
                                        '</div>' +
                                    '</div>' +
                                '</div>',
                    width: 70,
                    sortable: false
                },{
                    field: "dialengaHappinessScore",
                    headerTemplate: '<span translate="model.message.dhs"></span>',
                    headerAttributes: { class: "text-center" },
                    template: '<rating-icon data-ng-if="dataItem.dialengaHappinessScore" value="dataItem.dialengaHappinessScore" show-title="true"></rating-icon>',
                    attributes: { class: "text-center" },
                    width: Session.ratingKind === RATING_KIND.EMOJI ? GRID_ICON_COLUMN_WIDTH : GRID_RATING_COLUMN_WIDTH,
                },
                {
                    command: [{
                        template:'<div class="td-on-hover">'
                    },{
                        name: "view-button",
                        template: '<a class="k-button k-grid-view-button" kendo-tooltip k-content="\'global.form.view\' | translate" data-ng-if="dataItem.kind === ' +
                            CONTENT_KIND.DIALOGUE + ' || dataItem.kind === ' + CONTENT_KIND.SURVEY + '"><span class="icon icon-eye-opened"></span></a>',
                        click: viewRowClickHandler
                    }, {
                        name: "edit-button",
                        template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate" data-ng-if="dataItem.kind !== ' +
                            CONTENT_KIND.DIALOGUE + ' && dataItem.kind !== ' + CONTENT_KIND.SURVEY + '"><span class="icon icon-edit"></span></a>',
                        click: editRowClickHandler
                    }, {
                        name: "stats-button",
                        template: '<a class="k-button k-grid-stats-button" kendo-tooltip k-content="\'global.form.viewreport\' | translate" data-ng-if="' + (Session.isUserPublisherOrAdmin || (Session.isUserOnlyEditor && $scope.viewStatisticsAllowed)) + '">' +
                            '<span class="icon icon-statistics"></span></a>',
                        click: statsRowClickHandler
                    }, {
                        template:'</div>'
                    }],
                    headerAttributes: { class: "table-header-icons-container" },
                    attributes: { class: 'text-right overflow-visible' },
                    title: " "
                }
            ],
            dataSource: {
                transport: {
                    read: function(options) {
                        $http({
                            url: SharedDataService.apiUrl + '/recent-activities',
                            method: 'GET'
                        }).then(function(response) {
                            options.success(response.data);
                            $scope.numResults = response.data.length;
                        }, function() {
                            options.error();
                        });
                    }
                },
                schema: {
                    model: { id: "id" },
                    total: "totalElements",
                    parse: function(data) {
                        $.each(data, function(idx, elem) {
                            if (elem.publicationDate)
                                data[idx].publicationDate = new Date(elem.publicationDate);
                        });
                        return data;
                    }
                }
            },
            filterable: false,
            scrollable: false,
            selectable: false,
            sortable: false,
            pageable: false
        };

        //******************************************* Methods ***************************************\\
        function viewRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                $location.path('/questionnaires/view/' + dataItem.id);
            });
        }

        function editRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                switch (parseInt(dataItem.kind)) {
                    case CONTENT_KIND.MESSAGE:
                        $location.path('/messages/content/update/' + dataItem.id);
                        break;
                    case CONTENT_KIND.DIALOGUE:
                    case CONTENT_KIND.SURVEY:
                        $location.path('/questionnaires/edit/' + dataItem.id);
                        break;
                    case CONTENT_KIND.KNOWLEDGE_PILL:
                        $location.path('/knowledge-center/content/update/' + dataItem.id);
                        break;
                    default:
                        break;
                }
            });
        }

        function statsRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                switch (parseInt(dataItem.kind)) {
                    case CONTENT_KIND.MESSAGE:
                        $location.path('/messages/statistics/' + dataItem.id);
                        break;
                    case CONTENT_KIND.DIALOGUE:
                    case CONTENT_KIND.SURVEY:
                        $location.path('/questionnaires/statistics/' + dataItem.id);
                        break;
                    case CONTENT_KIND.KNOWLEDGE_PILL:
                        $location.path('/knowledge-center/statistics/' + dataItem.id);
                        break;
                    default:
                        break;
                }
            });
        }

        $scope.navigateToUrl = function (url) {
            if (url === '/questionnaires') {
                QuestionnaireService.navigateFromHomeCreateSurvey = true;
            }
            if (url === '/requests') {
                sessionStorage.setItem('navigateFromHomeToRequests', JSON.stringify(true));
            }
            $location.path(url);
        }

        $scope.checkUserAccess = function(link) {
            var hasAccess = false;

            if (link.module) {
                if (!AuthenticationService.checkIfModuleIsActiveForUserCompany(link.module)) {
                    return false;
                }
            }

            var roles = link.allowedRoles;

            for (var index = 0; index < roles.length; index++) {

                if (Session.roles && Session.roles.indexOf(roles[index]) !== -1)
                    hasAccess = true;

            }

            return hasAccess;
        };

        $scope.checkDisabledShortcut = function(link) {
            for (var index = 0; index < link.allowedRoles.length; index++) {
                if (Session.roles.indexOf(link.allowedRoles[index]) !== -1) {
                    if (link.allowedRoles[index] == USER_ROLES.manager && Session.isUserOnlyEditor) {
                        switch (Session.editorPermissions.availableFeeds) {
                            case AVAILABLE_FEEDS.ALL:
                                return false;
                            case AVAILABLE_FEEDS.TIMELINE:
                                return link.url === '/knowledge-center/create';
                            case AVAILABLE_FEEDS.KNOWLEDGE_CENTER:
                                return link.url === '/messages/create' || link.url === '/questionnaires';
                            default:
                                return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }

        $scope.expirationDateIsEarlier = function(expirationDate) {
            var currentDate = new Date();
            return new Date(expirationDate) <= currentDate;
        };

        var onDblClick = function(dataItem) {
            var publicationKind = parseInt(dataItem.kind);
            if (publicationKind === CONTENT_KIND.MESSAGE || publicationKind === CONTENT_KIND.KNOWLEDGE_PILL) {
                var editUrl = (publicationKind === CONTENT_KIND.MESSAGE) ? '/messages/content/update/' : '/knowledge-center/content/update/';
                $scope.$apply(function() {
                    $location.path(editUrl + dataItem.id);
                });
            }
        };

        KendoUtilsService.dblClickGridCallback('kendo-grid', onDblClick);

        UsersService.getAnniversaryUsers().then(function(response) {
            $scope.anniversaryEmployees = response.data.content.filter(function (employee) {
                return (employee.anniversary && employee.anniversaryYear > 0) || !!employee.birthday;
            });
        }).catch(function(error) {
            console.error(error);
        });

    }
]);