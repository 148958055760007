'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:roleFilter
 * @function
 * @description
 * # roleFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('roleFilter',
    ['$translate', 'USER_ROLES',
    function ($translate, USER_ROLES) {
    return function (role) {
        var userRole = 'model.users.roles.';
        switch (role) {
            case USER_ROLES.manager:
                userRole += 'manager';
                break;
            case USER_ROLES.admin:
                userRole += 'admin';
                break;
            case USER_ROLES.employeeManager:
                userRole += 'employeeManager';
                break;
            case USER_ROLES.user:
                userRole += 'user';
                break;
            case USER_ROLES.platformAdmin:
                userRole += 'platformAdmin';
                break;
            case USER_ROLES.ftrayResponsible:
                userRole += 'ftrayResponsible';
                break;
            case USER_ROLES.documentsManager:
                userRole += 'documentsManager';
                break;
            case USER_ROLES.publisher:
                userRole += 'publisher';
                break;
            case USER_ROLES.teamResponsible:
                userRole += 'teamResponsible';
                break;
            case USER_ROLES.humanResources:
                userRole += 'humanResources';
                break;
            case USER_ROLES.ftrayManager:
                userRole += 'ftrayManager';
                break;
            case USER_ROLES.chatroomManager:
                userRole += 'chatroomManager';
                break;
            default:
                userRole = null;
                break;
        }

        if (userRole) {
            userRole += '.name';
        }

        return userRole ? $translate.instant(userRole) : '';
    };
}]);
