/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsTeamController
 * @description
 * # TimeoffSettingsTeamController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsTeamController', ['$rootScope', '$scope', '$routeParams', '$translate', 'ModalService', 'AuthenticationService', 'TimeoffSettingsTeamsService', 'TimeoffSettingsSchemesService',
    'KendoUtilsService', 'NotificationService', 'UsersService', 'MODULES', 'PAGE_SIZE',
    function($rootScope, $scope, $routeParams, $translate, ModalService, AuthenticationService, TimeoffSettingsTeamsService, TimeoffSettingsSchemesService,
        KendoUtilsService, NotificationService, UsersService, MODULES, PAGE_SIZE) {
        var vm = this;

        vm.optionResponsabile = false;

        var removeAddTeamEmployeesEventListener;

        vm.$onDestroy = function() {
            removeAddTeamEmployeesEventListener();
        };

        vm.inputSearchField = '';
        vm.isFormSubmiting = false;
        var titleEditableSelectedText, descriptionEditableSelectedText;
        var pressedKeys = {};
        var latestTeamLeader = '';

        vm.teamId = $routeParams.id;
        vm.isHolidaysModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.HOLIDAYS);

        vm.teamForm = {
            team: {}
        };

        var gridColumns = [{
            field: 'fullName',
            title: $translate.instant('timeoff.requests.grid.employee'),
            template: '<user-avatar [user]="dataItem"></user-avatar>'
        }, {
            field: 'username',
            title: $translate.instant('users.username'),
            template: '<span ng-bind="dataItem.username"></span>'
        }, {
            command: [{
                name: "delete-button",
                template: '<div class="td-on-hover"><a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                    '><span class="icon icon-delete"></span></a></div>',
                click: deleteRowClickHandler
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];

        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.page = options.data.page - 1;
                    options.data.size = options.data.pageSize;
                    var defaultSortOrder = ['name,asc', 'surname,asc'];
                    options.data.sort = TimeoffSettingsSchemesService.getEmployeesSortCriteria(options.data.sort, defaultSortOrder);
                    saveGridPreferences(options);

                    if (vm.inputSearchField && KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                        options.data['name'] = vm.inputSearchField;
                    }
                    TimeoffSettingsTeamsService.getEmployeesOfATeam($routeParams.id, options.data).then(function(response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: 'content',
                model: { id: 'id' },
                total: 'totalElements'
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };
        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false }).then(function(response) {
            vm.gridOptions = response;
        });

        function actionErrorHandler(error) {
            if (error.status === 422) {
                reloadGrid();
            }
        }

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['teamEmployeesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['teamEmployeesGrid'].inputSearchField = vm.inputSearchField;
        }

        $scope.$on('kendoWidgetCreated', function(event, widget) {
            if (widget === vm.teamEmployeesGrid) {
                if (KendoUtilsService.gridPreferences['teamEmployeesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['teamEmployeesGrid'];
                    vm.inputSearchField = requestOptions.inputSearchField;
                    vm.teamEmployeesGrid.dataSource.query(requestOptions);
                } else {
                    vm.teamEmployeesGrid.dataSource.read();
                }
            }
        });

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'schemes.employees.modal.delete.title',
                message: 'timeoff.teams.employees.modal.delete.message',
                messageData: { fullName: dataItem.name + ' ' + dataItem.surname },
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    TimeoffSettingsTeamsService.deleteEmployeeFromTeam($routeParams.id, dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('timeoff.teams.employees.notification.delete.message', { fullName: dataItem.name + ' ' + dataItem.surname });
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function saveTeamForm() {
            vm.isFormSubmiting = true;
            TimeoffSettingsTeamsService.update(vm.teamForm).then(function(response) {
                vm.teamForm.team = response.data;
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1000);
            }).finally(function() {
                vm.isFormSubmiting = false;
            });
        };

        vm.editableNameFocusOut = function($event) {
            var name = $event.currentTarget.innerText.trim();
            if (name !== '' && vm.teamForm.team.name !== name) {
                vm.teamForm.team.name = name;
                saveTeamForm();
            } else if (vm.teamForm.team.name) {
                $event.currentTarget.innerText = vm.teamForm.team.name;
            }
        };

        vm.editableDescriptionFocusOut = function($event) {
            var description = $event.currentTarget.innerText.trim();
            if (vm.teamForm.team.description !== description) {
                vm.teamForm.team.description = description;
                saveTeamForm();
            }
        };

        vm.teamLeaderFocusOut = function() {
            if (!angular.equals(vm.teamForm.team.responsible, latestTeamLeader)) {
                saveTeamForm();
                latestTeamLeader = vm.teamForm.team.responsible;
            }
        };

        vm.setResponsible = function() {
            vm.optionResponsabile = !vm.optionResponsabile;
            if (!vm.optionResponsabile && vm.teamForm.team.responsible) {
                delete vm.teamForm.team.responsible;
                saveTeamForm();
                latestTeamLeader = {};
            }
        };

        vm.editableTitleKeyDown = function(event) {
            contentEditableKeyDown(event, titleEditableSelectedText);
            titleEditableSelectedText = '';
        };

        vm.editableDescriptionKeyDown = function(event) {
            contentEditableKeyDown(event, descriptionEditableSelectedText);
            descriptionEditableSelectedText = '';
        };

        vm.limitTitleMaxLengthPaste = function(event) {
            titleEditableSelectedText = getSelectedText(event);
            limitMaxLengthPaste(event, titleEditableSelectedText);
        };

        vm.limitDescriptionMaxLengthPaste = function(event) {
            descriptionEditableSelectedText = getSelectedText(event);
            limitMaxLengthPaste(event, descriptionEditableSelectedText);
        };

        vm.onTitleSelectionChange = function(event) {
            titleEditableSelectedText = getSelectedText(event);
        };

        vm.onDescriptionSelectionChange = function(event) {
            descriptionEditableSelectedText = getSelectedText(event);
        };

        vm.titleEditableKeyUp = function(event) {
            contentEditableKeyUp(event);
            vm.onTitleSelectionChange(event);
        };

        vm.descriptionEditableKeyUp = function(event) {
            contentEditableKeyUp(event);
            vm.onDescriptionSelectionChange(event);
        };

        function contentEditableKeyDown(event, selectedText) {
            var keyCode = event.which || event.keyCode;
            pressedKeys[keyCode] = true;
            if (keyCode === 13) {
                event.currentTarget.blur();
            } else {
                preventIfMaxLengthReached(event, keyCode, selectedText);
            }
        };

        function contentEditableKeyUp(event) {
            var keyCode = event.which || event.keyCode;
            delete pressedKeys[keyCode];
        };

        function preventIfMaxLengthReached(event, keyCode, selectedText) {
            var editableContent = event.target;
            // Allow delete, ctrl, arrows and supr keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            var allowedKeys = [8, 17, 37, 38, 39, 40, 46, 91, 93, 224];
            if (allowedKeys.indexOf(keyCode) === -1 && editableContent.attributes.maxlength && editableContent.attributes.maxlength.value) {
                if (editableContent.innerText.length >= editableContent.attributes.maxlength.value) {
                    // If a selection is made, allow pressed key even if the maxlength is reached, as its value will replace the selected text
                    // Also allow combination of two keys involving ctrl key (for copy-paste functionality)
                    if ((!selectedText || selectedText.length === 0) && (Object.keys(pressedKeys).length !== 2 || (!pressedKeys[17] &&
                            !pressedKeys[91] && !pressedKeys[93] && !pressedKeys[224]))) {
                        event.preventDefault();
                    }
                }
            }
        };

        function getSelectedText(event) {
            var keyCode = event.which || event.keyCode;
            // Discard enter, shift, ctrl, alt, caps lock and escape keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            if ([13, 16, 17, 18, 20, 27, 91, 93, 224].indexOf(keyCode) !== -1) {
                return '';
            }
            var selectedText = '';
            if (window.getSelection) {
                // IE
                selectedText = window.getSelection().toString();
            } else if (document.selection && document.selection.type !== "Control") {
                // The document.selection.type checks that the selection is text rather than a control
                selectedText = document.selection.createRange().text;
            }
            return selectedText;
        }

        function limitMaxLengthPaste(event, selectedText) {
            event.preventDefault();
            var text;
            var maxLength = event.target.attributes.maxlength ? event.target.attributes.maxlength.value : 256;
            var remainingChars = maxLength - event.target.innerText.length;

            if (remainingChars > 0 || selectedText.length > 0) {
                if ((event.originalEvent || event).clipboardData) {
                    text = (event.originalEvent || event).clipboardData.getData('text/plain');
                    // Increase remaining chars with selected text length as it will be deleted when pasted new content
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.execCommand('insertText', false, text);
                } else if (window.clipboardData) {
                    // Compatible with Internet Explorer
                    text = window.clipboardData.getData('Text');
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.selection.createRange().pasteHTML(text);
                }
            }
        }

        vm.addEmployeesToTeam = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-settings-team/timeoff-settings-team-employees-modal.html',
                controller: 'TimeoffSettingsTeamEmployeesModalController',
                size: 'xl',
                resolveParams: {
                    id: $routeParams.id
                },
                accept: function(employees) {
                    if (!!employees) {
                        TimeoffSettingsTeamsService.updateEmployeesInTeam($routeParams.id, employees)
                            .then(function() {
                                reloadGrid({ page: 1 });
                                var message = $translate.instant('timeoff.teams.employees.notification.add.message');
                                NotificationService.notifySuccess(message);
                            })
                            .catch(actionErrorHandler);
                    }
                }
            });
        }

        vm.listItemsSearch = function(text) {
            vm.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.teamEmployeesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
            });
        }

        function initExternalActionEvent() {
            if (!removeAddTeamEmployeesEventListener) {
                removeAddTeamEmployeesEventListener = $rootScope.$on('dialenga:addTeamEmployees', function() {
                    addEmployeesToTeam();
                });
            }
        }

        var init = function() {
            if (vm.teamId) {
                TimeoffSettingsTeamsService.findOne(vm.teamId).then(function(response) {
                    vm.teamForm.team = response.data;
                    latestTeamLeader = vm.teamForm.team.responsible;
                    vm.optionResponsabile = !!vm.teamForm.team.responsible ? true : false;
                    vm.downloadTeamBalance = TimeoffSettingsTeamsService.URL + '/' + $routeParams.id + '/export';

                    var teamLeaderFilter = !!vm.teamForm.team.responsible ? vm.teamForm.team.responsible.name : '';
                    vm.teamLeaderOptions = UsersService.getEmployeeOptions(teamLeaderFilter);
                });

            }
            initExternalActionEvent();
        };

        init();
    }
]);