'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffRequestDetailController
 * @description
 * # TimeoffRequestDetailController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffRequestDetailController',
    ['$scope', '$filter', '$timeout', '$translate', 'AuthenticationService', 'NotificationService', 'TimeoffService', 'Session',
        'TIMEOFF_REQUEST_DETAIL_TABS', 'TIMEOFF_REQUEST_STATUS', 'TIMEOFF_REQUEST_WARNING_KINDS', 'USER_ROLES', 'APPROVAL_FLOW_KIND', 'TIMEOFF_KIND',
    function ($scope, $filter, $timeout, $translate, AuthenticationService, NotificationService, TimeoffService, Session, 
        TIMEOFF_REQUEST_DETAIL_TABS, TIMEOFF_REQUEST_STATUS, TIMEOFF_REQUEST_WARNING_KINDS, USER_ROLES, APPROVAL_FLOW_KIND, TIMEOFF_KIND) {

    var self = this;

    self.areActionsDisabled = false;
    self.currentPreviewState = $scope.$parent.vm.selectDetailTab;
    self.TIMEOFF_REQUEST_DETAIL_TABS = TIMEOFF_REQUEST_DETAIL_TABS;
    self.TIMEOFF_REQUEST_STATUS = TIMEOFF_REQUEST_STATUS;
    self.TIMEOFF_REQUEST_WARNING_KINDS = TIMEOFF_REQUEST_WARNING_KINDS;
    self.getAbsenceMotiveClass = TimeoffService.getAbsenceMotiveClass;
    self.APPROVAL_FLOW_KIND = APPROVAL_FLOW_KIND;
    self.TIMEOFF_KIND = TIMEOFF_KIND;
    self.forceApprove = false;
    self.approvalFlow = Session.approvalFlow;

    self.$onInit = function () {
        if (!AuthenticationService.isAuthorized([USER_ROLES.humanResources, USER_ROLES.admin])) {
            self.areActionsDisabled = true;
        }
    };

    self.$onChanges = function (changes) {
        if (changes && changes.request && changes.request.currentValue &&
            (!changes.request.previousValue || changes.request.currentValue.id !== changes.request.previousValue.id || changes.request.currentValue.state !== changes.request.previousValue.state)) {
            self.setPreviewState($scope.$parent.vm.selectDetailTab);
            self.warningOpened = $scope.$parent.vm.openWarnings;
            self.isConfirmationShown = false;
            self.confirmationAction = null;
            self.forceApprove = changes.request.currentValue.state === TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR;
            if (changes.request.currentValue.absenceMotive) {
                $timeout(function () {
                    var tooltip = $('#motive-name-' + changes.request.currentValue.id).data('kendoTooltip');
                    tooltip.options.content = changes.request.currentValue.absenceMotive.name;
                    tooltip.refresh();
                }, 200);
            }
            if (changes.request.currentValue.overlappedRequests) {
                $timeout(function () {
                    for (var index = 0; index < changes.request.currentValue.overlappedRequests.length; index++) {
                        var overlapItem = changes.request.currentValue.overlappedRequests[index];
                        var tooltipAvatar = $('#avatar-overlap-' + overlapItem.id).data('kendoTooltip');
                        tooltipAvatar.options.content = overlapItem.employee.name + ' '+ overlapItem.employee.surname;
                        tooltipAvatar.refresh();
                    }
                }, 200);
            }
            if (changes.request.currentValue.requestDate) {
                setDateTooltip('#request-date',self.request.requestDate);
            }
            if (changes.request.currentValue.responsibleApprovalDate) {
                setDateTooltip('#responsible-approval-date',self.request.responsibleApprovalDate);
            }
            if (changes.request.currentValue.humanResourcesApprovalDate) {
                setDateTooltip('#hhrr-approval-date',self.request.humanResourcesApprovalDate);
            }
            if (changes.request.currentValue.responseDate) {
                setDateTooltip('#response-date',self.request.responseDate);
            }
            if (changes.request.currentValue.disallowedDate) {
                setDateTooltip('#disallowed-date',self.request.disallowedDate);
            }
            if (changes.request.currentValue.cancellationDate) {
                setDateTooltip('#cancellation-date',self.request.cancellationDate);
            }
        }
    };

    self.$onDestroy = function () {
        // self.closeAnswerButtonBar();
    };

    self.scrollConversationToBottom = function () {
        $timeout(function () {
            var conversationContainer = document.getElementById('conversationContainer');
            conversationContainer.scrollTop = conversationContainer.scrollHeight;
        });
    };

    self.getCustomClass = function (state) {
        var customClass;
        switch (state) {
            case TIMEOFF_REQUEST_STATUS.PENDING:
            case TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE:
            case TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR:
                customClass = 'row-detail__state--pending';
                break;
            case TIMEOFF_REQUEST_STATUS.APPROVED:
                customClass = 'row-detail__state--approved';
                break;
            case TIMEOFF_REQUEST_STATUS.CANCELLED:
                customClass = 'row-detail__state--cancelled';
                break;
            case TIMEOFF_REQUEST_STATUS.DISALLOWED:
            case TIMEOFF_REQUEST_STATUS.REJECTED:
            case TIMEOFF_REQUEST_STATUS.REJECTED_BY_RESPONSIBLE:
            case TIMEOFF_REQUEST_STATUS.REJECTED_BY_HHRR:
                customClass = 'row-detail__state--rejected';
                break;
            default:
                customClass = '';
                break;
        }
        return customClass;
    };

    self.setPreviewState = function (state) {
        self.currentPreviewState = state;
    };

    self.getRequestedTime = function (request) {
        return TimeoffService.getRequestedTime(request);
    };

    function updateRequestData(requestData) {
        self.request = requestData;
    }

    function getFilterStates(state) {
        return TimeoffService.getRequestStates(state);
    } 

    function approveRequest(request) {
        TimeoffService.approveRequest(request.kind, request.id, self.confirmationComment, self.forceApprove).then(function(response) {
            updateRequestData(response.data);
            var states = getFilterStates(self.filterState);
            var keepRow = states.indexOf(response.data.state) !== -1;

            self.reloadGrid({selectFirstRow: false, keepRow: keepRow});
            self.isConfirmationShown = false;
            self.confirmationComment = '';
            var message = $translate.instant('timeoffRequests.approveSuccessMessage');
            NotificationService.notifySuccess(message);
        });
    }

    self.openApproveRequestConfirmation = function () {
        self.confirmationAction = approveRequest;
        self.showForceToggle = self.approvalFlow === APPROVAL_FLOW_KIND.DOUBLE &&
            self.request.state !== TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE &&
            self.request.kind === TIMEOFF_KIND.HOLIDAYS;
        self.isConfirmationShown = true;
    };

    function rejectRequest(request) {
        TimeoffService.rejectRequest(request.kind, request.id, self.confirmationComment).then(function(response) {
            updateRequestData(response.data);
            self.reloadGrid({selectFirstRow: false});
            self.isConfirmationShown = false;
            self.confirmationComment = '';
            var message = $translate.instant(self.request.state === self.TIMEOFF_REQUEST_STATUS.PENDING ||
                                                self.request.state === self.TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE ||
                                                self.request.state === self.TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR ? 'timeoffRequests.rejectSuccessMessage' : 'timeoffRequests.disallowSuccessMessage');
            NotificationService.notifySuccess(message);
        });
    }

    function setDateTooltip(id, fieldDate) {
        $timeout(function () {
            var tooltip = $(id).data('kendoTooltip');
            tooltip.options.content = $filter('date')(fieldDate,'dd/MM/yyyy');
            tooltip.refresh();
        }, 200);        
    }

    self.openRejectRequestConfirmation = function () {
        self.confirmationAction = rejectRequest;
        self.showForceToggle = false;
        self.isConfirmationShown = true;
    };

    self.closeActionRequestConfirmation = function () {
        self.isConfirmationShown = false;
    };

    self.scrollConversationToBottom = function () {
        $timeout(function () {
            var timeoffConversationContainer = document.getElementById('timeoffConversationContainer');
            if (timeoffConversationContainer) {
                timeoffConversationContainer.scrollTop = timeoffConversationContainer.scrollHeight;
            }
        });
    };

    self.autoGrowTextarea = function ($event) {
        if ($event.target) {
            $event.target.style.height = '5px';
            $event.target.style.height = ($event.target.scrollHeight) + 'px';

            self.scrollConversationToBottom();
        }
    };

    self.isByStep = function() {
        return self.request && (self.request.state === self.TIMEOFF_REQUEST_STATUS.PENDING ||
            self.request.state === self.TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE ||
            self.request.state === self.TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR) &&
            self.approvalFlow === APPROVAL_FLOW_KIND.DOUBLE && self.request.kind === self.TIMEOFF_KIND.HOLIDAYS;
    };
}]);
