'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.urlUtilsService
 * @description
 * # urlUtilsService
 */
angular.module('dialengaExeviBackoffice').service('UrlUtilsService',
    ['$window',
    function ($window) {

    var URL_REGEXP = /^(?:(?:(?:https?|ftp):)?\/\/){0,1}(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    
    this.showUrlLink = function (url) {
        if (url !== " ") $window.open(url, "_blank");
    };

    this.validateURL = function (url) {
        if (url && url.startsWith('tel:') && url.length > 4) {
            return true;
        }
        return URL_REGEXP.test(url);
    };

    this.concatURLPrefixIfNotExists = function (url) {
        if (url && url.startsWith('mailto:') || url.startsWith('tel:')) {
            return url;
        }
        return url.indexOf("http") == 0 ? url : "http://" + url;
    };

    this.isValidExternalURL = function (url) {
        return !url || url.trim() === '' || this.validateURL(url);
    };

}]);
