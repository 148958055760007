/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayCloneModalController
 * @description
 * # FeedbackTrayCloneModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayCloneModalController', 
    ['$scope', '$timeout', '$translate', 'params', 'FeedbackTraysService', '$uibModalInstance',
    function($scope, $timeout, $translate, params, FeedbackTraysService, $uibModalInstance) {

        var vm = this;

        vm.changed = false;
        vm.isFormValid = false;
        vm.nameAlreadyExist = false;

        vm.cloneTrayForm = { 
            nameInApp: $translate.instant('feedbackTray.clone.copyText') + params.name,
            initials: '',
            alias: !!params.alias ? ($translate.instant('feedbackTray.clone.copyText') + params.alias).substring(0,64) : '',
            copyResponsible: false,
            copyQuestionnaire: false,
            copyRecipients: false,
            hasQuestionnaire: params.hasQuestionnaire
         };

        var validateModel = function () {
            vm.isFormValid = (!!vm.cloneTrayForm.nameInApp && vm.cloneTrayForm.nameInApp.length > 0) && !!vm.cloneTrayForm.initials;
        };
    
        vm.onChangeName = function () {
            if (vm.nameAlreadyExist) {vm.nameAlreadyExist= false;}
            vm.onChange();
        };

        vm.onChange = function () {
            vm.changed = true;
        };
    
        $scope.$watch('vm.changed', function (newValue) {
            if (newValue) {
                validateModel();
                vm.changed = false;
            }
        });

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.accept = function () {
            // Call endpoint for clone selected tray
            FeedbackTraysService.duplicate(params.id, vm.cloneTrayForm)
                .then(function(response) {
                    $uibModalInstance.close();
                })
                .catch(function(error) {
                    vm.nameAlreadyExist = true;
                });
        };

        function init() {
            validateModel();
            $timeout(function() {
                $('#trayNameInApp').trigger('focus');
            });
        }
        
        init();
    }
]);
