(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:publicationMetadata
     * @description
     * # publicationMetadata
     */
    angular.module('dialengaExeviBackoffice').component('publicationMetadata', {
        bindings: {
            name: '<',
            icon: '<',
            state: '<?',
            statistics: '<?',
            isAnonymous: '<?'
        },
        templateUrl: '/ng1/components/shared/publication-preview/publication-metadata.html',
        controllerAs: 'vm',
        controller: function () {
            var vm = this;
        }
    });
})();
