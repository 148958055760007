'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnaireController
 * @description
 * # QuestionnaireController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnaireController',
    ['$scope', '$routeParams', '$location', '$rootScope', '$translate', 'LanguageService', 'ModalService', 'QuestionnaireService', 'CONTENT_KIND', 'QUESTION_KIND',
    function ($scope, $routeParams, $location, $rootScope, $translate, LanguageService, ModalService, QuestionnaireService, CONTENT_KIND, QUESTION_KIND) {

    //******************************************* Attributes ************************************\\
    $scope.questionnaireId = $routeParams.id;
    $scope.questionnaire = {};
    $scope.currentLanguage = $translate.use();
    $scope.currentStep = 1;
    $scope.questions = [];
    $scope.valid = false;

    $scope.globalCollapsed = false;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.QUESTION_KIND = QUESTION_KIND;
    $scope.previewState = { opened: false };

    //******************************************** Methods **************************************\\
    function areAllAnswersValid(linkedQuestions) {
        var numQuestions = $scope.questionnaire.questions.length;
        for(var index = 0; index < numQuestions; index++){
            var answers = $scope.questionnaire.questions[index].answers;
            for (var answerIndex = 0; answerIndex < answers.length; answerIndex++) {
                if (!answers[answerIndex].linkedQuestion) {
                    return false;
                } else if (linkedQuestions.indexOf(answers[answerIndex].linkedQuestion) === -1) {
                    linkedQuestions.push(answers[answerIndex].linkedQuestion);
                }
            }
        }
        return true;
    }

    $scope.validateModel = function () {
        var valid = true;
        if ($scope.questionnaire.questions.length < 1 || $scope.questionnaire.endings.length < 1) {
            valid = false;
        } else if ($scope.questionnaire.kind === CONTENT_KIND.DIALOGUE) {
            var linkedQuestions = [];
            if (areAllAnswersValid(linkedQuestions)) {
                // Check if all endings are linked
                var numEndings = $scope.questionnaire.endings.length;
                for(var endingIndex = 0; endingIndex < numEndings; endingIndex++){
                    var ending = $scope.questionnaire.endings[endingIndex];
                    if (linkedQuestions.indexOf(ending.id) === -1) {
                        valid = false;
                        break;
                    }
                }
            } else {
                valid = false;
            }
        }
        $scope.valid = valid;
    };

    $scope.collapsePanel = function(item, $event){
        $event.preventDefault();
        $event.stopPropagation();
        item.isCollapsed = !item.isCollapsed;
        var items = $scope.questionnaire.questions.concat($scope.questionnaire.endings);
        var notCollapsedItems = items.filter(function(item){
            return !item.isCollapsed;
        });
        $scope.globalCollapsed = notCollapsedItems.length === 0;
    };

    $scope.globalCollapse = function(){
        $scope.globalCollapsed = !$scope.globalCollapsed;
        var numQuestions = $scope.questionnaire.questions.length;
        for (var i = 0; i < numQuestions; i++) {
            $scope.questionnaire.questions[i].isCollapsed = $scope.globalCollapsed;
        }
        var numEndings = $scope.questionnaire.endings.length;
        for (var j = 0; j < numEndings; j++) {
            $scope.questionnaire.endings[j].isCollapsed = $scope.globalCollapsed;
        }
    };

    $scope.deleteQuestion = function (questionId, questionKind, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        ModalService.openConfirmationModal({
            title: 'global.form.delete',
            message: questionKind === QUESTION_KIND.ENDING ? 'questionnaire.modal.delete.ending' : 'questionnaire.modal.delete.question',
            focusCancel: true,
            hideCancelButton: false,
            accept: function () {
                QuestionnaireService.deleteQuestion($scope.questionnaireId, questionId, 'questionnaire.question.error.delete').then(function() {
                    $scope.init();
                });
            }
        });
    };

    function getUnlinkedAnswers(answers) {
        return answers.filter(function (answer) {
            return !answer.linkedQuestion;
        });
    }

    $scope.areAllAnswersLinked = function(answers) {
        var unlinkedAnswers = getUnlinkedAnswers(answers);
        return unlinkedAnswers.length === 0;
    };

    $scope.areAllAnswersLinkedById = function(questionId) {
        var question = $scope.questionnaire.questions.filter(function (question) {
            return question.id === questionId;
        });
        if (question[0])
            return $scope.areAllAnswersLinked(question[0].answers);
        else
            return false;
    };

    $scope.goToRecipients = function() {
        $location.path('/questionnaires/recipients/' + $scope.questionnaireId);
    };

    $scope.getQuestionStatusTooltip = function (answers) {
        var unlinkedAnswers = getUnlinkedAnswers(answers);
        if (unlinkedAnswers.length === 0) {
            return $translate.instant('questionnaires.tip.questionComplete');
        } else {
            return $translate.instant('questionnaires.tip.questionNotComplete', {number: unlinkedAnswers.length}, 'messageformat');
        }
    };

    function getLocalizedTitle(item, titleFieldName) {
        var title;
        titleFieldName = titleFieldName || 'title';
        if (!item.contents[$scope.currentLanguage]) {
            var language = LanguageService.getMainContentLanguage(item.contents);
            item.language = language;
            title = item.contents[language][titleFieldName];
        } else {
            item.language = $scope.currentLanguage;
            title = item.contents[$scope.currentLanguage][titleFieldName];
        }
        return title;
    }

    $scope.updateQuestionnaireItemsAndLinkedStatus = function (questionnaire) {
        $scope.items = [];
        var linkedQuestions = [];

        if(!!questionnaire.cover) {
            $scope.questionnaire.cover.localizedTitle = getLocalizedTitle(questionnaire.cover);
        }

        questionnaire.questions.map(function(question, index){
            question.localizedTitle = getLocalizedTitle(question);
            $scope.items[question.id] = {
                name: question.localizedTitle,
                index: index,
                questionKind: question.kind,
                questionOrder: question.order
            };
            if (question.answers) {
                var linked = question.answers.reduce(function(linked, answer){
                    answer.localizedTitle = getLocalizedTitle(answer, 'text');
                    if (answer.linkedQuestion) {
                        linked.push(answer.linkedQuestion);
                    }
                    return linked;
                }, []);
                if (linked.length > 0) {
                    linkedQuestions = linkedQuestions.concat(linked);
                }
            }
        });

        questionnaire.endings.map(function(ending, index){
            ending.localizedTitle = getLocalizedTitle(ending);
            $scope.items[ending.id] = {
                name: ending.localizedTitle,
                index: index,
                questionKind: ending.kind,
                questionOrder: ending.order,
                isLinked: linkedQuestions.indexOf(ending.id) > -1
            };
        });
    };

    function checkIfFirstQuestionHasImage(questionnaire) {
        if (questionnaire.questions.length === 0 && !questionnaire.cover) {
            return false;
        }
        var firstQuestionHasImage = false;

        if (questionnaire.questions.length > 0) {
            angular.forEach(questionnaire.questions[0].contents, function (content) {
                if (!!content.imageURL || !!content.youtubeURL) {
                    firstQuestionHasImage = true;
                }
            });
        }
        
        if(questionnaire.cover && questionnaire.cover.contents) {
            angular.forEach(questionnaire.cover.contents, function (content) {
                if (!!content.imageURL || !!content.youtubeURL) {
                    firstQuestionHasImage = true;
                }
            });
        }
        return firstQuestionHasImage;
    }

    $scope.updateQuestionnaireData = function(questionnaireData) {
        $scope.questionnaire = questionnaireData;
        if ($scope.questionnaire.users) {
            $scope.questionnaire.users =  QuestionnaireService.orderRecipientsUsers($scope.questionnaire.users);
        }
        QuestionnaireService.setUsersAvatarTooltips($scope.questionnaire.users);
        
    };

    $scope.editQuestionnaire = function () {
        ModalService.openQuestionnaireModal($scope.questionnaireId, function (questionnaire) {
            $scope.headerTitle = questionnaire.name;
            $scope.questionnaire.category = questionnaire.category;
            $scope.questionnaire.pushEnabled = questionnaire.pushEnabled;
            $scope.questionnaire.anonymous = questionnaire.anonymous;
        });
    };

    $scope.deleteQuestionnaire = function () {
        ModalService.openConfirmationModal({
            title: 'publication.modal.delete.title',
            message: 'publication.modal.delete.message',
            messageData: { contentType: CONTENT_KIND.DIALOGUE },
            acceptButtonText: 'global.form.delete',
            isTextInterpolated: true,
            accept: function () {
                QuestionnaireService.delete($scope.questionnaireId, 'questionnaires.error.delete')
                    .then(function() {
                        $location.path('/questionnaires');
                    })
                    .catch(actionErrorHandler);
            }
        });
    };

    $scope.init = function () {
        QuestionnaireService.findOne($scope.questionnaireId).then(function (response) {
            $scope.updateQuestionnaireData(response.data);
            $scope.firstQuestionHasImage = checkIfFirstQuestionHasImage(response.data);
            $scope.category = response.data.category;
            $scope.headerTitle = response.data.name;

            $scope.updateQuestionnaireItemsAndLinkedStatus(response.data);

            $scope.validateModel();
        });
    };

    $scope.invalidFormTooltip = $scope.questionnaire.kind === CONTENT_KIND.SURVEY ? 'questionnaires.tip.requirementsSurvey' : 'questionnaires.tip.requirements';
    $scope.init();

    $rootScope.$on('dialenga:reloadQuestionnairesList', function() {
        $scope.init();
    });

    $rootScope.$on('dialenga:collapseQuestionPanel', function(event, args) {
        $scope.collapsePanel(args.item, args.event);
    });

}]);
