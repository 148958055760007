'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnaireStatisticsController
 * @description
 * # QuestionnaireStatisticsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnaireStatisticsController',
    ['$rootScope', '$filter', '$scope', '$routeParams', '$translate', 'KendoUtilsService', 'QuestionnaireService', 'StatisticsService',
    'DateUtilsService', 'STATISTICS_DROPDOWN', 'GRID_TICK_COLUMN_WIDTH', 'CONTENT_KIND', 'PAGE_SIZE', 'QUESTION_KIND',
    function ($rootScope, $filter, $scope, $routeParams, $translate, KendoUtilsService, QuestionnaireService, StatisticsService,
        DateUtilsService, STATISTICS_DROPDOWN, GRID_TICK_COLUMN_WIDTH, CONTENT_KIND, PAGE_SIZE, QUESTION_KIND) {

    //******************************************* Attributes ************************************\\
    $scope.chartColors = ["#4ECDC4", "#1A535C", "#FF6B6B", "#FFE66D", "#E0FBFC", "#AB6BB6", "#EADDDD", "#9EEECC",
        "#EB8ABE", "#D8EF7F", "#3D6E9B", "#DEF2C5"];

    $scope.statisticsDropDown = STATISTICS_DROPDOWN;
    $scope.statisticsRelativeType = STATISTICS_DROPDOWN.TOTAL_SENT;
    $scope.previewState = { opened: false };
    $scope.isActivityDetailSelected = false;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.QUESTION_KIND = QUESTION_KIND;
    $scope.defaultResponsesLength = PAGE_SIZE / 2;
    $scope.surveyQuestionResponsesGrids = [];

    $scope.donutOptions = {
        theme: 'Material',
        legend: {
            visible: false
        },
        tooltip: {
            visible: true,
            template: function (e) {
                return $filter('percentage')(e.dataItem.percentage);
            }
        },
        seriesDefaults: { type: 'donut' },
        series: [{
            field: 'count',
            categoryField: 'title',
            colorField: 'color'
        }]
    };

    var gridColumns = [{
        field: "name",
        title: $translate.instant('users.name'),
        headerAttributes: {class: "text-left"},
        template: '<span class="truncate">{{dataItem.name}} {{dataItem.surname}}</span>'
    },
    {
        field: "viewedInInbox",
        title: $translate.instant('statistics.activity.inbox'),
        template: '<span class="green" data-ng-class="{\'icon icon-ok\': dataItem.viewedInInbox === true}" kendo-tooltip ' +
            'k-content="\'{{dataItem.viewedInInboxDate}}\' | date:\'dd/MM/yyyy HH:mm:ss\'"></span>',
        attributes: {class: "text-center"},
        width: GRID_TICK_COLUMN_WIDTH
    },
    {
        field: "open",
        title: $translate.instant('statistics.activity.opened'),
        template: '<span class="green" data-ng-class="{\'icon icon-ok\': dataItem.open === true}" kendo-tooltip ' +
            'k-content="\'{{dataItem.openDate}}\' | date:\'dd/MM/yyyy HH:mm:ss\'"></span>',
        attributes: {class: "text-center"},
        width: GRID_TICK_COLUMN_WIDTH
    },
    {
        field: "answered",
        title: $translate.instant('statistics.activity.ended'),
        template: '<span class="green" data-ng-class="{\'icon icon-ok\': dataItem.answered === true}" kendo-tooltip ' +
            'k-content="\'{{dataItem.answerDate}}\' | date:\'dd/MM/yyyy HH:mm:ss\'"></span>' +
            '<span class="response-badge margin-left-10" data-ng-if="dataItem.leafOrder != -1" ' +
                'data-ng-style="{\'background-color\': chartColors[dataItem.leafOrder]}" kendo-tooltip k-content="\'{{dataItem.title}}\'"></span>',
        attributes: {class: "text-center"},
        width: GRID_TICK_COLUMN_WIDTH
    }];

    var gridDataSource = StatisticsService.getActivityGridDataSource();
    var surveyQuestionResponsesGridDataSource = angular.copy(gridDataSource);
    gridDataSource['transport'] = {
        read: function (options) {
            var linkedQuestionId = (options.data && options.data.id) ? options.data.id : $scope.selectedItem;
            var sort = KendoUtilsService.getSortCriteria(options.data.sort, ["viewedInInbox,desc", "open,desc", "answered,desc"]);
            QuestionnaireService.activity($routeParams.id, {
                page: options.data.page - 1,
                size: options.data.pageSize,
                sort: sort,
                linkedQuestionId: linkedQuestionId
            }).then(function (response) {
                options.success(response.data);
            }, function () {
                options.error();
            });
        }
    };
    gridDataSource['requestEnd'] = function (event) {
        KendoUtilsService.reverseGridDefaultSortDirection('activity-grid');
    };

    KendoUtilsService.initializeGrid(gridColumns, gridDataSource).then(function (response) {
        $scope.gridOptions = angular.copy(response);
        $scope.surveyGridOptions = angular.copy(response);
    });

    var surveyQuestionResponsesGridColumns = [{
        field: "response",
        headerTemplate: '<span translate="users.name"></span>',
        headerAttributes: {class: "text-left"},
        template:   '<div class="personal-answers">' +
                        '<div class="item">' +
                            '<div class="text-container">' +
                                '<p data-ng-bind="dataItem.response"></p>' +
                            '</div>' +
                            '<p class="name text-xs" data-ng-bind="dataItem.fullUserName"></p>' +
                        '</div>' +
                    '</div>'
    }];

    $scope.surveyQuestionResponsesGridOptions;
    KendoUtilsService.initializeGrid(surveyQuestionResponsesGridColumns, surveyQuestionResponsesGridDataSource,
        {autoBind: false})
        .then(function (response) {
        $scope.surveyQuestionResponsesGridOptions = angular.copy(response);
    });

    function setCSVDownloadLinkURL(contentKind) {
        var contentKindURLPrefix = contentKind === CONTENT_KIND.SURVEY ? QuestionnaireService.surveysURL : QuestionnaireService.URL;
        $scope.downloadCSVUrl = contentKindURLPrefix + '/' + $routeParams.id + '/activity-detail/export-csv';
    }

    //******************************************** Methods **************************************\\
    var init = function () {
        $scope.statisticsDropdownOptions = StatisticsService.getRelativesTypesDropDownListOptions();
        $scope.backPath = !!$rootScope.previousRoute ? $rootScope.previousRoute.path : '/questionnaires';
        QuestionnaireService.findOne($routeParams.id).then(function (response) {
            $scope.questionnaire = response.data;
            $scope.questionnaire.publicationDate = DateUtilsService.getDateTimeFromTimestamp(response.data.publicationDate);

            QuestionnaireService.setQuestionnaireContentLanguage($scope.questionnaire);

            setCSVDownloadLinkURL(response.data.kind);

            if (response.data.kind === CONTENT_KIND.DIALOGUE) {
                $scope.iconPublication = "icon icon-content-dialog";
                QuestionnaireService.answersDistribution($routeParams.id).then(function (response) {
                    $scope.answers = response.data.leaves;
                    $scope.totalAnswered = response.data.totalAnswered;
                    for (var index = 0; index < $scope.answers.length; index++) {
                        $scope.answers[index].color = $scope.chartColors[$scope.answers[index].order % $scope.chartColors.length];
                    }
                    $scope.answersDistribution = new kendo.data.DataSource({
                        data: $scope.answers
                    });
                });
            } else if (response.data.kind === CONTENT_KIND.SURVEY) {
                $scope.iconPublication = "icon icon-content-survey";
                QuestionnaireService.surveyAnswerDistribution($routeParams.id).then(function (response) {
                    $scope.surveyQuestions = response.data;
                });
            }

            if ($scope.questionnaire.users) {
                $scope.questionnaire.users =  QuestionnaireService.orderRecipientsUsers($scope.questionnaire.users); 
            }
            QuestionnaireService.setUsersAvatarTooltips($scope.questionnaire.users);
        });

        QuestionnaireService.statistics($routeParams.id).then(function (response) {
            $scope.statistics = response.data;
            $scope.total = parseInt(response.data.sent);
        });
    };

    $scope.onSeriesClick = function (e) {
        if (e.dataItem && e.dataItem.id) {
            $scope.selectedItem = e.dataItem.id;
            KendoUtilsService.reloadGridDatasource($scope.activityGrid.dataSource, {id: e.dataItem.id});
        }
    };

    $scope.onLegendClick = function (id) {
        $scope.selectedItem = id;
        KendoUtilsService.reloadGridDatasource($scope.activityGrid.dataSource, {id: id});
    };

    $scope.clearFilters = function () {
        if ($scope.selectedItem) {
            $scope.selectedItem = null;
            KendoUtilsService.reloadGridDatasource($scope.activityGrid.dataSource);
        }
    };

    $scope.setActivityDetailSelected = function (isSelected) {
        $scope.isActivityDetailSelected = isSelected;
    };

    $scope.getSurveyQuestionResponsesGridOptions = function (question, index) {
        var options = angular.copy($scope.surveyQuestionResponsesGridOptions);

        surveyQuestionResponsesGridDataSource['transport'] = {
            read: function (options) {
                QuestionnaireService.surveyQuestionResponses($routeParams.id, question.questionId, {
                    page: options.data.page - 1,
                    size: options.data.pageSize,
                    sort: 'answerDate,asc'
                }).then(function (response) {
                    options.success(response.data);
                }, function () {
                    options.error();
                });
            }
        };

        var dataSource = angular.copy(surveyQuestionResponsesGridDataSource);
        options.dataSource = new kendo.data.DataSource(dataSource);
        $scope.surveyQuestionResponsesGrids[index] = options.dataSource;

        return options;
    };

    $scope.showMoreSurveyQuestionResponses = function (question, index) {
        question.resultsGridVisible = true;
        $scope.surveyQuestionResponsesGrids[index].read();
    };

    init();
}]);
