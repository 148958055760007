'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:conversationsMotiveFilter
 * @function
 * @description
 * # conversationsMotiveFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('conversationsMotiveFilter', ['$filter', '$sce', function ($filter, $sce) {
    return function (motive, textFieldName) {
        textFieldName = textFieldName || 'alias';
        return $sce.trustAsHtml('<span class="message-category" style="background-color:' +
            $filter('numberColorFilter')(motive.color) + ';">' + motive[textFieldName] + '</span>');
    };
}]);
