'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UsersController
 * @description
 * # UsersController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UsersController', ['$scope', '$filter', '$location', '$translate', '$timeout', 'KendoUtilsService', 'ModalService', 'NotificationService',
    'PublicationService', 'Session', 'UsersService', 'WKAuthenticationService', 'PAGE_SIZE', 'USER_STATES', 'GRID_ICON_COLUMN_WIDTH', 'USER_ROLES',
    function($scope, $filter, $location, $translate, $timeout, KendoUtilsService, ModalService, NotificationService,
        PublicationService, Session, UsersService, WKAuthenticationService, PAGE_SIZE, USER_STATES, GRID_ICON_COLUMN_WIDTH, USER_ROLES) {

        //******************************************* Attributes ************************************\\
        $scope.stateIsActive = true;
        $scope.exportCSVUrl = UsersService.URL + '/export-csv';
        $scope.inputSearchField = "";
        $scope.selectedCompanies = [];
        $scope.selectedWorkplaces = [];
        $scope.selectedDepartments = [];
        $scope.selectedTeams = [];
        $scope.selectedRoles = [];
        $scope.searchUsersOpened = false;
        $scope.showCompanyFilter = true;
        $scope.USER_STATES = USER_STATES;
        STATE_FILTER = {
            AVAILABLES: 1,
            ARCHIVED: 2,
            ACTIVES: 3,
            PENDING_ACTIVATION: 4,
            ACTIVES_PENDING_ACCESS: 5,
            LOCKED: 6
        }

        $scope.filterRolesOptions = [
            { id: USER_ROLES.admin, name: $translate.instant('model.users.roles.admin.name') },
            { id: USER_ROLES.publisher, name: $translate.instant('model.users.roles.publisher.shortName') },
            { id: USER_ROLES.manager, name: $translate.instant('model.users.roles.manager.shortName') },
            { id: USER_ROLES.employeeManager, name: $translate.instant('model.users.roles.employeeManager.name') },
            { id: USER_ROLES.humanResources, name: $translate.instant('model.users.roles.humanResources.shortName') },
            { id: USER_ROLES.documentsManager, name: $translate.instant('model.users.roles.documentsManager.shortName') },
            { id: USER_ROLES.ftrayManager, name: $translate.instant('model.users.roles.ftrayManager.shortName') },
            { id: USER_ROLES.chatroomManager, name: $translate.instant('model.users.roles.chatroomManager.name') }
        ];

        $scope.filterStateOptions = [
            { text: 'users.titleactive', value: STATE_FILTER.AVAILABLES, icon: 'icon-filter-lines black-45'},
            { text: 'users.titleinactive', value: STATE_FILTER.ARCHIVED, icon: 'icon-archive black-45'},
            { text: 'global.userstates.stateFilters.actives', value: STATE_FILTER.ACTIVES, icon: 'icon-ok-fill approved-color-soft'},
            { text: 'global.userstates.stateFilters.pendingactivation', value: STATE_FILTER.PENDING_ACTIVATION, icon: 'icon-state-pending-activation black-45'},
            { text: 'global.userstates.stateFilters.activesPendingAccess', value: STATE_FILTER.ACTIVES_PENDING_ACCESS, icon: 'icon-state-wating-access black-45'},
            { text: 'global.userstates.stateFilters.blocked', value: STATE_FILTER.LOCKED, icon: 'icon-state-locked advice-color'}
        ];
        $scope.selectedState = $scope.filterStateOptions[0];

        var gridColumns = [{
            field: "state",
            title: " ",
            type: "number",
            attributes: { class: "text-center" },
            template: '<span class="{{dataItem.state | userStateIconFilter:dataItem.lastAccessDate}}" ' +
                'kendo-tooltip k-content="\'{{dataItem.state}}\' | userStateTooltipFilter:dataItem.lastAccessDate"></span>',
            width: GRID_ICON_COLUMN_WIDTH
        }, {
            field: "avatar",
            title: " ",
            attributes: {class: "padding-left-5 padding-right-5"},
            template: '<avatar [name]="dataItem.name" [surname]="dataItem.surname" [thumbnails]="{thumbnail_S: dataItem.avatarThumbnailSUrl, thumbnail_M: dataItem.avatarThumbnailMUrl }" '+
                      '[state]="dataItem.state" [access]="dataItem.lastAccessDate"></avatar>',
            width: 55,
            sortable: false
        }, {
            field: "name",
            title: $translate.instant('users.name'),
            template: '<div class="flex align-items-center">' + '<span class="icon nucleo icon-confidential black-60 margin-right-4" style="font-size: 22px;" ng-if="!!dataItem.confidentialProfile" kendo-tooltip k-content="\'users.confidential.title\' | translate"></span>' + '<span data-ng-bind="dataItem.name"></span>' + '</div>',
            width: 150
        }, {
            field: "surname",
            title: $translate.instant('users.surname'),
            template: '<span class="truncate" data-ng-bind="dataItem.surname"></span>'
        }, {
            field: "rolesNames",
            title: $translate.instant('model.users.backofficeProfile'),
            template: '<div class="truncate" kendo-tooltip k-content="dataItem.rolesNamesTooltip">' +
                '<span data-ng-bind="dataItem.rolesNames"></span>' +
            '</div>',
            encoded: false,
            sortable: false
        }, {
            command: [{
                template:'<div class="td-on-hover">'
            },{
                name: "info-button",
                template: '<a class="k-button k-grid-info-button" ng-if="dataItem.state !=' + USER_STATES.PENDING_ACTIVATION +
                    ' && dataItem.info" kendo-tooltip k-content="\'{{dataItem.info}}\'"><span class=\'icon icon-info\'></span></a>',
                click: editRowClickHandler
            }, {
                name: "send-button",
                template: '<a class="k-button k-grid-send-button" ng-if="dataItem.state == ' + USER_STATES.PENDING_ACTIVATION +
                    ' || (dataItem.state == ' + USER_STATES.REGISTERED + ' && !dataItem.lastAccessDate)" ng-class="{\'disabled\': !dataItem.email && !dataItem.phoneNumber}"' +
                    'kendo-tooltip k-content="!dataItem.email && !dataItem.phoneNumber ? \'global.form.sendActivationLocked\' : \'global.form.sendActivation\' | translate">' +
                    '<span class="icon" ng-class="!dataItem.email && !dataItem.phoneNumber ? \'icon-send-locked\' : \'icon-send\'"></span></a>',
                click: sendActivationRowClickHandler
            }, {
                name: "edit-button",
                template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate"><span class=\'icon icon-edit\'></span></a>',
                click: editRowClickHandler
            }, {
                name: "delete-button",
                template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'{{dataItem.deleteTooltip}}\'"><span class="icon delete-icon"></span></a></div>',
                click: deleteRowClickHandler
            },{
                template:'</div>'
                }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: ""
        }];
        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.state = UsersService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
                    options.data.companies = $scope.selectedCompanies;
                    options.data.workplaces = $scope.selectedWorkplaces;
                    options.data.departments = $scope.selectedDepartments;
                    options.data.teams = $scope.selectedTeams;
                    options.data.roles = $scope.selectedRoles;
                    if ($scope.selectedState.value === STATE_FILTER.ACTIVES_PENDING_ACCESS) {
                        options.data.lastAccessDate = decodeURI('%20');
                    }
                    saveGridPreferences(options);

                    var requestOptions = getRequestOptions(options);
                    UsersService.findAll(requestOptions).then(function(response) {
                        $scope.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                total: "totalElements",
                parse: function(data) {
                    $.each(data.content, function(idx, elem) {
                        if ((parseInt(elem.state) !== USER_STATES.REGISTERED || elem.lastAccessDate) && elem.joinDate) {
                            if (elem.joinDate) {
                                data.content[idx].joinDate = $filter('date')(elem.joinDate, 'dd/MM/yyyy');
                            }
                            if (elem.lastAccessDate) {
                                data.content[idx].lastAccessDate = $filter('date')(elem.lastAccessDate, 'dd/MM/yyyy');
                            }
                            data.content[idx].info = getInfoTooltipContent();
                        }
                        if (parseInt(elem.state) === USER_STATES.DISABLED) {
                            data.content[idx].deleteTooltip = $translate.instant('users.reactivate');
                        } else {
                            data.content[idx].deleteTooltip = $translate.instant('global.form.archive');
                        }
                        data.content[idx].rolesNames = $filter('rolesFilter')(elem.roles);
                        data.content[idx].rolesNamesTooltip = elem.roles.length > 1 ? $filter('rolesFilter')(elem.roles, '<br />') : null;
                    });
                    return data;
                }
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        function getInfoTooltipContent() {
            return '<p class="tooltip-row">' +
                '<span translate="model.users.lastAccessDate"></span> ' +
                '<span ng-if="dataItem.lastAccessDate" ng-bind="dataItem.lastAccessDate"></span>' +
                '<span class="empty" ng-if="!dataItem.lastAccessDate">- -</span>' +
                '</p>' +
                '<p class="tooltip-row">' +
                '<span translate="model.users.joinDate"></span> ' +
                '<span ng-bind="dataItem.joinDate"></span>' +
                '</p>';
        }

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false }, { dataBound: onDataBound }).then(function(response) {
            $scope.gridOptions = response;
        });

        //******************************************** Methods **************************************\\
        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['usersGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['usersGrid'].stateIsActive = $scope.stateIsActive;
            KendoUtilsService.gridPreferences['usersGrid'].selectedButton = $scope.selectedButton;
            KendoUtilsService.gridPreferences['usersGrid'].inputSearchField = $scope.inputSearchField;
            KendoUtilsService.gridPreferences['usersGrid'].selectedState = $scope.selectedState;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === $scope.usersGrid) {
                if (KendoUtilsService.gridPreferences['usersGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['usersGrid'];
                    $scope.stateIsActive = requestOptions.stateIsActive;
                    $scope.selectedButton = requestOptions.selectedButton;
                    $scope.inputSearchField = requestOptions.inputSearchField;
                    $scope.selectedCompanies = requestOptions.companies;
                    $scope.selectedWorkplaces = requestOptions.workplaces;
                    $scope.selectedDepartments = requestOptions.departments;
                    $scope.selectedTeams = requestOptions.teams;
                    $scope.selectedRoles = requestOptions.roles;
                    $scope.selectedState = requestOptions.selectedState;

                    if (!!$scope.inputSearchField) {
                        $scope.searchUsersOpen();
                    }
                    
                    $scope.usersGrid.dataSource.query(requestOptions);
                } else {
                    $scope.selectedButton = USER_STATES.ALL;
                    $scope.usersGrid.dataSource.read();
                }
            }
        });

        function getRequestOptions(options) {
            var requestOptions = KendoUtilsService.getGridRequestOptions(options, 'joinDate,desc', $scope.inputSearchField, 'name');
            requestOptions.subsidiaryCompany = options.data.companies;
            requestOptions.headquarter = options.data.workplaces;
            requestOptions.department = options.data.departments;
            requestOptions.team = options.data.teams;
            requestOptions.lastAccessDate = options.data.lastAccessDate || null;
            requestOptions['roles.name'] = options.data.roles;
            return requestOptions;
        }

        $scope.listItemsSearch = function() {
            if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        $scope.searchUsersOpen = function() {
            $scope.searchUsersOpened = !$scope.searchUsersOpened;
            $timeout(function() {
                document.getElementById('search-users').focus();
            }, 100);
        }

        $scope.searchUsersClear = function() {
            $scope.inputSearchField = '';
            if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
            document.getElementById('search-users').focus();
        }

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
            var data = this.dataSource.view();
            for (var i = 0; i < data.length; i++) {
                var row = this.table.find("tr[data-uid='" + data[i].uid + "']");
                if (parseInt(data[i].state) === USER_STATES.DISABLED) {
                    row.find(".delete-icon").addClass("icon-unarchive");
                } else {
                    row.find(".delete-icon").addClass("icon-archive");
                }
            }
        }

        $scope.onEntityFilterChanged = function() {
            reloadGrid({ page: 1 });
        }

        $scope.onStateFilterChanged = function(state) {
            $scope.selectedState = state;
            switch (state.value) {
                case STATE_FILTER.AVAILABLES:
                    $scope.stateIsActive = true;
                    $scope.selectedButton = $scope.USER_STATES.ALL;
                    break;
                case STATE_FILTER.ARCHIVED:
                    $scope.stateIsActive = false;
                    break;
                case STATE_FILTER.ACTIVES:
                case STATE_FILTER.ACTIVES_PENDING_ACCESS:
                    $scope.stateIsActive = true;
                    $scope.selectedButton = $scope.USER_STATES.REGISTERED;
                    break;
                case STATE_FILTER.PENDING_ACTIVATION:
                    $scope.stateIsActive = true;
                    $scope.selectedButton = $scope.USER_STATES.PENDING_ACTIVATION;
                    break;
                case STATE_FILTER.LOCKED:
                    $scope.stateIsActive = true;
                    $scope.selectedButton = $scope.USER_STATES.LOCKED;
                    break;
                default:
                    break;
            }
            reloadGrid({ page: 1 });
            document.getElementById('icon-state-filter').click();
        }

        function promptA3TermsAndConditions(clientId) {
            ModalService.openWKUsageConditionsConfirmationModal({
                accept: function () {
                    WKAuthenticationService.acceptA3ConfigurationTerms().then(function (response) {
                        WKAuthenticationService.promptWKLoginForm(clientId);
                    });
                }
            });
        }

        $scope.syncExternalUsers = function () {
            WKAuthenticationService.getA3Configuration().then(function (response) {
                if (!response.data.termsAndConditionsAccepted) {
                    promptA3TermsAndConditions(response.data.clientId);
                } else {
                    WKAuthenticationService.promptWKLoginForm(response.data.clientId);
                }
            });
        };

        $scope.getExportCSVUrl = function () {
            var fileName = new Date().toISOString().split('.')[0];
            fileName = 'users-' + fileName.replaceAll(':', '_') + '.csv';
    
            var linkCsv = document.createElement("a");
            document.body.appendChild(linkCsv);

            var options = {};
            options.state = UsersService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
            options.subsidiaryCompany = $scope.selectedCompanies;
            options.headquarter = $scope.selectedWorkplaces;
            options.department = $scope.selectedDepartments;
            options.team = $scope.selectedTeams;
            options['roles.name'] = $scope.selectedRoles;
            if ($scope.selectedState.value === STATE_FILTER.ACTIVES_PENDING_ACCESS) {
                options.lastAccessDate = decodeURI('%20');
            }
            if (!!$scope.inputSearchField) {
                options.name = $scope.inputSearchField;
            }
            
            UsersService.downloadUsersCsv(options).then(function(response) {
                var file = new Blob([response.data], {type: 'text/csv'});
                var fileURL = window.URL.createObjectURL(file);
                linkCsv.href = fileURL;
                linkCsv.download = fileName;
                linkCsv.click();
                linkCsv.remove();
            });
        }

        function sendActivationRowClickHandler(event) {
            event.preventDefault();
            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'users.modal.sendActivation.title',
                message: 'users.modal.sendActivation.message',
                messageData: { "username": dataItem.username },
                focusCancel: true,
                hideCancelButton: false,
                accept: function() {
                    var message;
                    UsersService.sendActivationEmail(dataItem.id).then(function() {
                        message = $translate.instant('users.modal.sendActivation.success');
                        NotificationService.notifySuccess(message);
                    }, function() {
                        message = $translate.instant('users.modal.sendActivation.error');
                        NotificationService.notifyError(message);
                    });
                }
            });
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            var modal = {};
            var promise, notificationMessageKey, acceptButtonText;
            var contentType = 5;
            if (parseInt(dataItem.state) === USER_STATES.DISABLED) {
                modal.title = 'global.modal.reactivate.title';
                modal.message = 'global.modal.reactivate.messageuser';
                notificationMessageKey = 'publication.modal.archive.recovered';
                acceptButtonText = 'users.reactivate';
                promise = UsersService.activate;
            } else {
                modal.title = 'publication.modal.archive.title';
                modal.message = 'users.modal.archive.message';
                notificationMessageKey = 'publication.modal.archive.archived';
                acceptButtonText = 'global.form.archive';
                promise = UsersService.deactivate;
            }
            ModalService.openConfirmationModal({
                title: modal.title,
                message: modal.message,
                messageData: {
                    contentType: contentType,
                    user: dataItem.email
                },
                acceptButtonText: acceptButtonText,
                isTextInterpolated: parseInt(dataItem.state) !== USER_STATES.DISABLED,
                accept: function() {
                    promise.call(UsersService, dataItem.id).then(function() {
                        PublicationService.showSuccessNotification(notificationMessageKey, contentType);
                        reloadGrid();
                    });
                }
            });
        }

        function editRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                $location.path('/user/companydata/update/' + dataItem.id);
            });
        }

        function reloadGrid(options) {
            $scope.isSyncing = true;
            KendoUtilsService.reloadGridDatasource($scope.usersGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    $scope.isSyncing = false;
                });
            });
        }

        KendoUtilsService.dblClickGridRedirect('kendo-grid', '/user/companydata/update/');

        function init() {
            WKAuthenticationService.getA3Configuration().then(function (response) {
                $scope.isSyncUsersButtonVisible = response.data.enabled;
            });
            UsersService.getTeams().then(function (response) {
                $scope.filterTeamsOptions = response.data;
            });
            UsersService.getCompanies().then(function (response) {
                $scope.filterCompaniesOptions = response.data;
                $scope.showCompanyFilter = $scope.filterCompaniesOptions.length > 1;
            });
            UsersService.getWorkplaces().then(function (response) {
                $scope.filterWorkplacesOptions = response.data;
            });
            UsersService.getDepartments().then(function (response) {
                $scope.filterDepartmentsOptions = response.data;
            });
        }

        init();
    }
]);