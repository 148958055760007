'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemeController
 * @description
 * # TimeoffSettingsSchemeController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemeController',
    ['$rootScope', '$scope', '$route', '$routeParams', '$translate', 'TimeoffSettingsSchemesService', 'NotificationService', 'TIMEOFF_SETTINGS_SCHEME_TABS', 'selectedTab', 'Session',
    function ($rootScope, $scope, $route, $routeParams, $translate, TimeoffSettingsSchemesService, NotificationService, TIMEOFF_SETTINGS_SCHEME_TABS, selectedTab, Session) {
  
        var vm = this;

        vm.isFormSubmiting = false;
        vm.TIMEOFF_SETTINGS_SCHEME_TABS = TIMEOFF_SETTINGS_SCHEME_TABS;
        vm.selectedTab = selectedTab;
        $scope.selectedLanguage = vm.defaultLanguage = Session.companyDefaultLanguage;
        vm.availableCompanyLanguages = Session.companyLanguages;
        var titleEditableSelectedText;
        var pressedKeys = {};

        vm.schemaId = $routeParams.id;
        vm.schemaForm = {
            schema: {
                timeOffSchema: {
                    workDays: {}
                }
            }
        };

        function schemaNameSave() {
            vm.isFormSubmiting = true;
            var schemaForm = {
                schema: {
                    timeOffSchema: {}
                }
            };
            schemaForm.schema.timeOffSchema.id = vm.schemaId;
            schemaForm.schema.timeOffSchema.name = vm.schemaForm.schema.timeOffSchema.name;
            TimeoffSettingsSchemesService.update(schemaForm).then(function (response) {
                vm.schemaForm.schema.timeOffSchema.name = response.data.name;
                vm.isFormSubmiting = false;
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1000);
            }, function() {
                vm.isFormSubmiting = false;
            });
        };

        vm.loadTabContent = function (tab) {
            if (vm.selectedTab !== tab && vm.schemaId) {
                vm.selectedTab = tab;
                if (tab === TIMEOFF_SETTINGS_SCHEME_TABS.GENERAL) {
                    $route.updateParams({tabName: 'general'});
                } else if (tab === TIMEOFF_SETTINGS_SCHEME_TABS.ABSENCES) {
                    $route.updateParams({tabName: 'absences'});
                } else if (tab === TIMEOFF_SETTINGS_SCHEME_TABS.EMPLOYEES) {
                    $route.updateParams({tabName: 'employees'});
                }
            }
        };

        vm.editableNameFocusOut = function($event) {
            var name = $event.currentTarget.innerText.trim();
            if (name !== '' && vm.schemaForm.schema.timeOffSchema.name !== name) {
               vm.schemaForm.schema.timeOffSchema.name = name;
                schemaNameSave();
            } else if (vm.schemaForm.schema.timeOffSchema.name) {
                $event.currentTarget.innerText = vm.schemaForm.schema.timeOffSchema.name;
            }
        };

        vm.editableTitleKeyDown = function (event){
            contentEditableKeyDown(event, titleEditableSelectedText);
            titleEditableSelectedText = '';
        };

        vm.limitTitleMaxLengthPaste = function (event) {
            titleEditableSelectedText = getSelectedText(event);
            limitMaxLengthPaste(event, titleEditableSelectedText);
        };

        vm.onTitleSelectionChange = function (event) {
            titleEditableSelectedText = getSelectedText(event);
        };

        vm.titleEditableKeyUp = function (event) {
            contentEditableKeyUp(event);
            vm.onTitleSelectionChange(event);
        };

        vm.addEmployeesToSchema = function () {
            $rootScope.$broadcast('dialenga:addSchemaEmployees');
        };
        
        vm.setLanguage = function (language) {
           $scope.selectedLanguage = language;
        };

        function contentEditableKeyDown(event, selectedText){
            var keyCode = event.which || event.keyCode;
            pressedKeys[keyCode] = true;
            if (keyCode === 13) {
                event.currentTarget.blur();
            } else {
                preventIfMaxLengthReached(event, keyCode, selectedText);
            }
        };

        function contentEditableKeyUp(event) {
            var keyCode = event.which || event.keyCode;
            delete pressedKeys[keyCode];
        };

        function preventIfMaxLengthReached(event, keyCode, selectedText) {
            var editableContent = event.target;
            // Allow delete, ctrl, arrows and supr keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            var allowedKeys = [8, 17, 37, 38, 39, 40, 46, 91, 93, 224];
            if (allowedKeys.indexOf(keyCode) === -1 && editableContent.attributes.maxlength && editableContent.attributes.maxlength.value) {
                if (editableContent.innerText.length >= editableContent.attributes.maxlength.value) {
                    // If a selection is made, allow pressed key even if the maxlength is reached, as its value will replace the selected text
                    // Also allow combination of two keys involving ctrl key (for copy-paste functionality)
                    if ((!selectedText || selectedText.length === 0) && (Object.keys(pressedKeys).length !== 2 || (!pressedKeys[17]
                        && !pressedKeys[91] && !pressedKeys[93] && !pressedKeys[224]))) {
                        event.preventDefault();
                    }
                }
            }
        };

        function getSelectedText(event) {
            var keyCode = event.which || event.keyCode;
            // Discard enter, shift, ctrl, alt, caps lock and escape keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            if ([13, 16, 17, 18, 20, 27, 91, 93, 224].indexOf(keyCode) !== -1) {
                return '';
            }
            var selectedText = '';
            if (window.getSelection) {
                // IE
                selectedText = window.getSelection().toString();
            } else if (document.selection && document.selection.type !== "Control") {
                // The document.selection.type checks that the selection is text rather than a control
                selectedText = document.selection.createRange().text;
            }
            return selectedText;
        }

        function limitMaxLengthPaste(event, selectedText) {
            event.preventDefault();
            var text;
            var maxLength = event.target.attributes.maxlength ? event.target.attributes.maxlength.value : 256;
            var remainingChars = maxLength - event.target.innerText.length;
    
            if (remainingChars > 0 || selectedText.length > 0) {
                if ((event.originalEvent || event).clipboardData) {
                    text = (event.originalEvent || event).clipboardData.getData('text/plain');
                    // Increase remaining chars with selected text length as it will be deleted when pasted new content
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.execCommand('insertText', false, text);
                } else if (window.clipboardData) {
                    // Compatible with Internet Explorer
                    text = window.clipboardData.getData('Text');
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.selection.createRange().pasteHTML(text);
                }
            }
    
        }
        var init = function() {
            if (vm.schemaId) {
                TimeoffSettingsSchemesService.findOne(vm.schemaId).then(function (response) {
                    vm.schemaForm.schema = response.data;
                });
            }
        };
        
        init();
}]);