(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:noDefaultLanguageTooltip
     * @description
     * # noDefaultLanguageTooltip
     */
    angular.module('dialengaExeviBackoffice').directive('noDefaultLanguageTooltip', ['Session', function (Session) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                availableLanguages: '=?',
                contents: '=?'
            },
            template:
                '<span data-ng-if="isVisible" class="icon-left-vertical-center icon icon-alert" kendo-tooltip ' +
                    'k-content="\'language.contentTooltip.noDefaultLanguage\' | translate"></span>',
            link: function(scope, elem, attrs) {
                if (scope.availableLanguages) {
                    scope.isVisible = scope.availableLanguages.indexOf(Session.companyDefaultLanguage) === -1;
                } else if (scope.contents) {
                    var languages = Object.keys(scope.contents);
                    scope.isVisible = languages.length > 0 && languages.indexOf(Session.companyDefaultLanguage) === -1;
                }
            }
        };
    }]);

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:noDefaultLanguageInfoBox
     * @description
     * # noDefaultLanguageInfoBox
     */
    angular.module('dialengaExeviBackoffice').directive('noDefaultLanguageInfoBox',
        ['ArrayUtilsService', 'Session',
        function (ArrayUtilsService, Session) {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    data: '=',
                    isVisible: '=?'
                },
                template:
                    '<div class="notification-box" data-ng-if="isVisible">' +
                        '<span class="notification-box__text" data-translate="language.contentTooltip.noDefaultLanguage"></span>' +
                    '</div>',
                link: function(scope, elem, attrs) {
                    scope.isVisible = (typeof scope.isVisible !== 'undefined') ? scope.isVisible : false;

                    scope.$watch('data', function (newValue, oldValue) {
                        var newValueKeys = Object.keys(newValue);
                        if (newValueKeys.length > 0) {
                            if (newValueKeys.indexOf(Session.companyDefaultLanguage) > -1) {
                                // If default language is present and is the only one, hide the component
                                // If it's present but is not the only one, check if it is empty or not
                                scope.isVisible = newValueKeys.length !== 1 && !ArrayUtilsService.objectHasAnyValue(newValue[Session.companyDefaultLanguage]);
                            } else {
                                scope.isVisible = true;
                            }
                        } else {
                            scope.isVisible = false;
                        }
                    }, true);
                }
            };
        }
    ]);
})();