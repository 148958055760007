'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.TimeoffSettingsWorkplacesService
 * @description
 * # TimeoffSettingsWorkplacesService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('TimeoffSettingsWorkplacesService',
    ['$http', '$translate', 'KendoUtilsService', 'SharedDataService', 'PAGE_SIZE',
    function ($http, $translate, KendoUtilsService, SharedDataService, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/headquarters';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.update = function (id, workplace) {
        return $http({
            url: this.URL + '/' + id,
            method: 'PUT',
            data: workplace
        });
    };

    this.getHolidays = function (workplaceId, year) {
        return $http({
            url: this.URL + '/' + workplaceId + '/public-holidays',
            method: 'GET',
            params: {
                year: year
            }
        });
    };

    this.createHolidaysInWorkplace = function (workplaceId, holidays) {
        return $http({
            url: this.URL + '/' + workplaceId + '/public-holidays',
            method: 'POST',
            data: holidays
        });
    };

    this.updateHolidaysInWorkplace = function (workplaceId, holidays) {
        return $http({
            url: this.URL + '/' + workplaceId + '/public-holidays/' + holidays.id,
            method: 'PUT',
            data: holidays
        });
    };

    this.deleteHolidaysFromWorkplace = function (workplaceId, holidaysId) {
        return $http({
            url: this.URL + '/' + workplaceId + '/public-holidays/' + holidaysId,
            method: 'DELETE'
        });
    };

    this.getEmployees = function (workplaceId, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/' + workplaceId + '/employees',
            method: 'GET',
            params: options
        });
    };

    this.updateEmployeesInWorkplace = function (workplaceId, employees) {
        return $http({
            url: this.URL + '/' + workplaceId + '/employees',
            method: 'POST',
            data: employees
        });
    };

    this.deleteEmployeeFromWorkplace = function (workplaceId, employeeId) {
        return $http({
            url: this.URL + '/' + workplaceId + '/employees/' + employeeId,
            method: 'DELETE'
        });
    };

}]);