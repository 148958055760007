'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:clickOut
 * @description
 * # clickOut
 */
angular.module('dialengaExeviBackoffice').directive('clickOut', ['$document', '$timeout', function ($document, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var onClick = function (event) {
                var isChild = element[0].contains(event.target);
                var isSelf = element[0] == event.target;
                var isInside = isChild || isSelf;

                if (!isInside) {
                    scope.$apply(attrs.clickOut);
                }
            };

            scope.$watch(attrs.isActive, function (newValue, oldValue) {
                $timeout(function () {
                    if (newValue == true) {
                        $document.bind('click', onClick);
                    }
                    if (newValue !== oldValue && newValue == true) {
                        $document.bind('click', onClick);
                    } else if (newValue !== oldValue && newValue == false) {
                        $document.unbind('click', onClick);
                    }
                });
            });
        }
    };
}]);
