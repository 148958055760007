(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:questionList
     * @description
     * # questionList
     */
    angular.module('dialengaExeviBackoffice').component('questionList', {
        bindings: {
            question: '<',
            linkedItems: '<',
            isFirst: '<',
            isLast: '<',
            index: '<',
            isTray: '<?',
            hasCover: '<',
            questionnaireKind: '<',
            showEndingElement: '<?'
        },
        controller: function () {
            var vm = this;
        },
        controllerAs: 'vm',
        // template: '<div data-ng-repeat="question in vm.questions | orderBy: \'order\' track by $index">'+
        //     '<question-item question="question" index="$index" is-first="$first" is-last="$last" linked-items="vm.linkedItems"></question-item>' +
        //     '</div>',
        template: '<question-item question="vm.question" index="vm.index" is-first="vm.isFirst" is-last="vm.isLast" linked-items="vm.linkedItems" is-tray="vm.isTray" has-cover="vm.hasCover" questionnaire-kind="vm.questionnaireKind" show-ending-element="vm.showEndingElement"></question-item>'
    });
})();