'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:LanguageSelectorController
 * @description
 * # LanguageSelectorController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('LanguageSelectorController',
    ['$scope', '$rootScope', '$translate', 'ArrayUtilsService', 'LanguageService', 'ModalService', 'Session',
    function ($scope, $rootScope, $translate, ArrayUtilsService, LanguageService, ModalService, Session) {

    var self = this;

    var addedLanguagesInitialized = false;

    self.$onInit = function () {
        self.defaultLanguage = Session.companyDefaultLanguage;
        self.setLanguage(Session.companyDefaultLanguage);
        self.isDropDownOpened = false;
    };

    self.setLanguage = function (language) {
        self.selectedLanguage = language;
    };

    self.isLanguageAdded = function (language) {
        return !!language.added;
    };

    function removeLanguage(language) {
        // Delete content of the language we want to remove
        self.formContent[language.name] = {};
        delete self.formContent[language.name];

        // Check if content is a question and has any answer associated to the language we want to remove
        // If so, delete all of them
        if (self.answers && self.answers.length > 0) {
            for (var index = 0; index < self.answers.length; index++) {
                var answer = self.answers[index];
                if (answer.contents && answer.contents[language.name]) {
                    answer.contents[language.name] = {};
                    delete answer.contents[language.name];
                }
            }
        }
    }

    function setLanguageAsRemoved(language) {
        if (self.selectedLanguage === language.name) {
            self.selectedLanguage = Session.companyDefaultLanguage;
        }
        if (self.validationFunction) {
            $rootScope.$broadcast('dialenga:contentLanguagesHasChanged');
            self.validationFunction();
        }
        language.added = false;
        // If language removed has no longer setted in company, remove from availableLanguages
        if (Session.companyLanguages.indexOf(language.name) === -1) {
            var langIndex = self.availableLanguages.findIndex(function(lang) { return lang.name == language.name});
            if (langIndex !== -1) {
                self.availableLanguages.splice(langIndex, 1);
            }
        }
    }

    self.toggleLanguage = function (language) {
        if (!language.added) {
            // Add language
            language.added = true;
            self.formContent[language.name] = {};
            self.setLanguage(language.name);
        } else {
            // Remove language
            if (self.formContent[language.name] && ArrayUtilsService.objectHasAnyValue(self.formContent[language.name])) {
                // If language has content, confirm action
                ModalService.openConfirmationModal({
                    title: 'language.modal.remove.title',
                    message: 'language.modal.remove.message',
                    acceptButtonText: 'global.form.remove',
                    accept: function () {
                        removeLanguage(language);
                        setLanguageAsRemoved(language);
                    }
                });
            } else {
                delete self.formContent[language.name];
                setLanguageAsRemoved(language);
            }
        }
        self.isDropDownOpened = false;
    };

    self.toggleSelector = function () {
        self.isDropDownOpened = !self.isDropDownOpened;
    };

    function initializeAvailableLanguages() {
        var contentLanguages = Object.keys(self.formContent);
        var totalLanguages = Session.companyLanguages.concat(contentLanguages);
    
        self.availableLanguages = [];
        totalLanguages.forEach(function(language) {
            if (language !== Session.companyDefaultLanguage) {
                self.availableLanguages.push({
                    name: language,
                    translatedName: $translate.instant('global.language.' + language),
                    added: false
                });
            }
        });
        self.availableLanguages = ArrayUtilsService.removeDuplicates(self.availableLanguages, 'name');
        self.availableLanguages.sort(function(a,b) { return a.translatedName > b.translatedName ? 1 : b.translatedName > a.translatedName ? -1 : 0});
    }

    function addLanguages(languages) {
        for (var index = 0; index < self.availableLanguages.length; index++) {
            var availableLanguage = self.availableLanguages[index];
            availableLanguage.added = languages.indexOf(availableLanguage.name) !== -1;
        }
        addedLanguagesInitialized = true;
    }

    $scope.$watch('self.selectedLanguage', function (newValue) {
        if (newValue && self.validationFunction) {
            self.validationFunction();
        }
    });

    $scope.$watch('self.formContent', function (newValue, oldValue) {
        var oldValueKeys = oldValue ? Object.keys(oldValue) : [];
        var newValueKeys = newValue ? Object.keys(newValue) : [];
        if ((oldValueKeys.length === 0 || !addedLanguagesInitialized) && newValueKeys.length > 0) {
            if (!addedLanguagesInitialized) {
                initializeAvailableLanguages();
            }
            addLanguages(newValueKeys);

            var language = LanguageService.getMainContentLanguage(newValue);
            // If content does not have company default language, set language of first content
            if (language !== Session.companyDefaultLanguage) {
                language = self.availableLanguages[self.availableLanguages.findIndex(function(lang) {return lang.added})].name;
            }
            self.setLanguage(language);
        } else if (oldValueKeys.length !== newValueKeys.length) {
            $rootScope.$broadcast('dialenga:publicationLanguagesUpdate', {addedLanguages: newValueKeys});
        }
    }, true);
}]);
