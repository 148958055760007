'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:unsafeResourceUrl
 * @function
 * @description
 * # unsafeResourceUrl
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('unsafeResourceUrl', ['$sce', function ($sce) {
    return function (url) {
        if (url && !/^(f|ht)tps?:\/\//i.test(url)) {
            url = 'http://' + url;
        }
        return $sce.trustAsResourceUrl(url);
    };
}]);
