'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemesController
 * @description
 * # TimeoffSettingsSchemesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemesController', ['$scope', '$location', '$translate', 'TimeoffSettingsSchemesService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH',
    function($scope, $location, $translate, TimeoffSettingsSchemesService, KendoUtilsService, ModalService, NotificationService, PAGE_SIZE, GRID_ICON_COLUMN_WIDTH) {
        var vm = this;

        vm.inputSearchField = '';
        vm.stateIsActive = true;

        var removeTimeoffSettingsChangedTabEventListener;

        vm.$onInit = function() {
            initExternalActionEvent();
        };

        vm.$onDestroy = function() {
            removeTimeoffSettingsChangedTabEventListener();
        };

        var gridColumns = [{
            field: "name",
            title: $translate.instant('model.schemes.name'),
            template: '<span class="categories-table-container truncate" data-ng-bind="dataItem.name" title="{{dataItem.name}}"></span>'
        }, {
            field: "totalEmployees",
            title: $translate.instant('model.schemes.employees'),
            headerAttributes: { "class": "text-center" },
            attributes: { class: 'text-center' },
            sortable: false,
            width: 200
        }, {
            command: [{
                template: '<div class="td-on-hover">'
            }, {
                name: "edit-button",
                template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate"><span class="icon icon-edit"></span></a>',
                click: editRowClickHandler
            }, {
                name: "delete-button",
                template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                    'data-ng-if="dataItem.deletable && dataItem.active"><span class="icon icon-delete"></span></a>',
                click: deleteRowClickHandler
            }, {
                name: "archive-button",
                template: '<a class="k-button k-grid-archive-button" kendo-tooltip k-content="\'global.form.archive\' | translate" ' +
                    'data-ng-if="!dataItem.deletable && dataItem.active"><span class="icon icon-archive"></span></a>',
                click: archiveRowClickHandler
            }, {
                name: "activate-button",
                template: '<a class="k-button k-grid-activate-button" kendo-tooltip k-content="\'global.form.activate\' | translate" ' +
                    'data-ng-if="!dataItem.active"><span class="icon icon-unarchive"></span></a>',
                click: activateRowClickHandler
            }, {
                template: '</div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];
        var gridDataSource = {
            transport: {
                read: function(options) {
                    saveGridPreferences(options);
                    var requestOptions = getRequestOptions(options);
                    TimeoffSettingsSchemesService.findAll(requestOptions).then(function(response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements"
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: false }, { dataBound: onDataBound }).then(function(response) {
            vm.gridOptions = response;
        });

        //******************************************** Methods **************************************\\
        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['schemesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['schemesGrid'].inputSearchField = vm.inputSearchField;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === vm.schemesGrid) {
                if (KendoUtilsService.gridPreferences['schemesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['schemesGrid'];
                    vm.inputSearchField = requestOptions.inputSearchField;
                    vm.schemesGrid.dataSource.query(requestOptions);
                } else {
                    vm.schemesGrid.dataSource.read();
                }
                KendoUtilsService.dblClickGridRedirect('kendo-grid', 'settings/timeoff/scheme/general/update/');
            }
        });

        function editRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                $location.path('settings/timeoff/scheme/general/update/' + dataItem.id);
            });
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'schemes.modal.delete.title',
                message: 'schemes.modal.delete.message',
                messageData: { schemaName: dataItem.name },
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    TimeoffSettingsSchemesService.delete(dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('schemes.notification.delete.message', { schemaName: dataItem.name });
                            NotificationService.notifySuccess(message, 1000);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function archiveRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'schemes.modal.archive.title',
                message: 'schemes.modal.archive.message',
                messageData: { schemaName: dataItem.name },
                acceptButtonText: 'global.form.archive',
                accept: function() {
                    TimeoffSettingsSchemesService.archive(dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('schemes.notification.archive.message', { schemaName: dataItem.name });
                            NotificationService.notifySuccess(message, 1000);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function activateRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'schemes.modal.activate.title',
                message: 'schemes.modal.activate.message',
                messageData: { schemaName: dataItem.name },
                acceptButtonText: 'global.form.activate',
                accept: function() {
                    TimeoffSettingsSchemesService.activate(dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('schemes.notification.activate.message', { schemaName: dataItem.name });
                            NotificationService.notifySuccess(message, 1000);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
        }

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.schemesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
            });
        }

        function getRequestOptions(options) {
            var defaultSortOrder = ['lastModifiedDate,desc', 'name,asc'];
            var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder, vm.inputSearchField, 'name');
            requestOptions['active'] = vm.stateIsActive;

            return requestOptions;
        }

        vm.listItemsSearch = function(text) {
            vm.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        vm.getSchemasByIsActive = function(isActive) {
            if (vm.stateIsActive !== isActive) {
                vm.stateIsActive = isActive;
                reloadGrid({ page: 1 });
            }
        };

        function initExternalActionEvent() {
            if (!removeTimeoffSettingsChangedTabEventListener) {
                removeTimeoffSettingsChangedTabEventListener = $scope.$on('dialenga:timeoffSettingsChangedTab', function() {
                    if (vm.inputSearchField) {
                        vm.listItemsSearch('');
                    }
                });
            }
        }

    }
]);