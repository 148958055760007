'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayQuestionnaireController
 * @description
 * # FeedbackTrayQuestionnaireController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayQuestionnaireController', 
    ['$scope', '$routeParams', '$translate', '$location', 'FeedbackTraysService', 'ModalService', 'QUESTIONNAIRE_KIND',
    function($scope, $routeParams, $translate, $location, FeedbackTraysService, ModalService, QUESTIONNAIRE_KIND) {

        $scope.feedbackTrayId = $routeParams.id;
        $scope.currentLanguage = $translate.use();
        $scope.isSwitchSelected = true;
        $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;

        $scope.questionnaireTray = {
            fields: [
                {answers: []}
            ],
        }
        $scope.questionnaireHaveQuestions = false;
        $scope.isDataLoaded = false;

        // **** Methods ********************************************************************************

        $scope.switchState = function () {
            $scope.questionnaireTray.enabled = !$scope.questionnaireTray.enabled;
            if ($scope.questionnaireTray.enabled) {
                FeedbackTraysService.enableQuestionnaire($scope.feedbackTrayId);
            } else {
                FeedbackTraysService.disableQuestionnaire($scope.feedbackTrayId);
            }
        }

        $scope.createQuestionnaire = function() {
            if (!$scope.questionnaireTray.id) {
                FeedbackTraysService.createQuestionnaireInTray($scope.feedbackTrayId).then(function(response) {
                    $scope.questionnaireTray = response.data;
                    $scope.feedbackTray.questionnaire = response.data;
                    $scope.editQuestionnaireTray();
                });
            } else {
                $scope.editQuestionnaireTray();
            }
        }

        $scope.editQuestionnaireTray = function(){
            if ($scope.feedbackTray.questionnaire.enabled) {
                ModalService.openConfirmationModal({
                    title: 'feedbackTray.questionnaire.editConfirmationModal.title',
                    message: 'feedbackTray.questionnaire.editConfirmationModal.message',
                    acceptButtonText: 'global.form.continue',
                    showInfoIcon: true,
                    accept: function () {
                        // Call service for disable questionnaire an redirect to edit questionnaire
                        FeedbackTraysService.disableQuestionnaire($scope.feedbackTrayId).then(function() {
                            $location.path('/settings/conversations/feedback-trays/questionnaire/update/' + $scope.feedbackTrayId);
                        });
                    }
                });
            } else {
                $location.path('/settings/conversations/feedback-trays/questionnaire/update/' + $scope.feedbackTrayId);
            }
        }

        var getQuestionnaireTray = function() {
            FeedbackTraysService.findQuestionnaire($scope.feedbackTrayId).then(function(response) {
                if (response.data) {
                    $scope.questionnaireTray = response.data;
                    $scope.questionnaireHaveQuestions = response.data.fields.length > 0 || response.data.endings.length > 0;
                } 
                $scope.isDataLoaded = true;
                $scope.$emit('dialenga:questionnaireTrayDataUpdated', {haveQuestions: $scope.questionnaireHaveQuestions});

                if ($scope.questionnaireHaveQuestions) {
                    $scope.items = FeedbackTraysService.updateQuestionnaireItemsAndLinkedStatus($scope.questionnaireTray, $scope.currentLanguage);
                }
            });
        };

        var init = function() {
            getQuestionnaireTray();
        }

        init();

    }
]);