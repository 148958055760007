'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.statisticsService
 * @description
 * # statisticsService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('StatisticsService',
    ['$http', 'KendoUtilsService', 'SharedDataService', 'PAGE_SIZE', 'STATISTICS_DROPDOWN',
    function ($http, KendoUtilsService, SharedDataService, PAGE_SIZE, STATISTICS_DROPDOWN) {

    var relativeTypes = [
        {
            id: STATISTICS_DROPDOWN.TOTAL_SENT,
            name: 'statistics.dropdown.totalSent'
        },
        {
            id: STATISTICS_DROPDOWN.RELATIVE_BEFORE,
            name: 'statistics.dropdown.relativeBefore'
        }
    ];

    this.URL = SharedDataService.apiUrl + '/dialenga-statistics';

    this.globalStatistics = function (startDate, endDate) {

        return $http({
            url: this.URL + '/global',
            method: 'GET',
            params: {
                startDate: startDate,
                endDate: endDate
            }
        });
    };

    this.getPercentage = function (value, total) {
        if (parseInt(value) === 0) {
            return '0%';
        } else {
            var percentage = (parseInt(value) * 100) / parseInt(total);
            percentage = Math.round(percentage * 100 / 100);
            return percentage + '%';
        }
    };

    this.doughnutsChart = function (dataSet) {
        return {
            legend: {
                position: 'top',
                visible: false
            },
            chartArea: {
                margin: {
                    top: 1
                },
                height: 230,
                width: 275
            },
            seriesDefaults: {
                type: "donut",
                startAngle: 150
            },
            series: [{
                data: dataSet,
                overlay: {
                    gradient: "none"
                },
                labels: {
                    visible: false,
                    background: "transparent",
                    position: "outsideEnd",
                    template: "#= category #: \n #= value#"
                }
            }],
            tooltip: {
                visible: true,
                template: "#= category #: \n #= value#"
            }
        };
    };

    this.lineChart = function (label, data) {
        var keys = [],
            values = [];
        var dataLength = data.length;
        for (var index = 0; index < dataLength; index++) {
            var joinDate = new Date(data[index].joinDate);
            keys.push(joinDate);
            values.push(data[index].registeredUsers);
        }

        return {
            legend: {
                position: "top"
            },
            seriesDefaults: {
                type: "area",
                area: {
                    color: "rgba(52, 136, 200, 0.5)",
                    line: {
                        style: "smooth",
                        color: "rgba(52, 136, 200, 1)",
                        width: 3
                    }
                }
            },
            series: [{
                name: label,
                data: values,
                missingValues: "interpolate",
                markers: {
                    visible: true,
                    background: "rgba(52, 136, 200, 1)",
                    size: 10
                },
            }],
            valueAxis: {
                labels: {
                    format: "{0}",
                    step: 1
                },
                line: {
                    visible: false
                }
            },
            categoryAxis: {
                categories: keys,
                type: "date",
                baseUnit: "days",
                labels: {
                    dateFormats: {
                        days: "dd / MM / yy"
                    },
                    step: 15,
                    majorTicks: {
                        visible: false
                    },
                    rotation: {
                        angle: -35
                    }
                },
                line: {
                    visible: false
                },
                majorGridLines: {
                    visible: true,
                    step: 15
                },
                justified: false
            },
            tooltip: {
                visible: true,
                padding: {
                    right: 20
                },
                template: "#= kendo.format('{0:dd/MM/yyyy}',category) # <br> #= series.name #: #= value #"
            }
        };
    };

    this.getActivityGridDataSource = function () {
        return {
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements"
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };
    };

    this.getRelativesTypesDropDownListOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions();

        options.dataSource.transport.read = function (event) {
            event.success(relativeTypes);
        };
        options.dataSource.sort = { field: 'id', dir: 'asc' };

        var relativeTypesTemplate = '<div><span translate="{{dataItem.name}}"></span></div>';

        options.template = relativeTypesTemplate;
        options.valueTemplate = relativeTypesTemplate;

        return options;
    };

    this.getCommentsForPublication = function(id, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: SharedDataService.apiUrl + '/messages/' + id + '/comments',
            method: 'GET',
            params: options
        });
    };

}]);
