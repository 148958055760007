'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:stateTooltipFilter
 * @function
 * @description
 * # stateTooltipFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('stateTooltipFilter',
    ['$filter', 'MESSAGE_STATES',
    function ($filter, MESSAGE_STATES) {
    return function (state) {
        var tooltip = 'global.states.';
        switch(parseInt(state)){
            case MESSAGE_STATES.DRAFT:
                tooltip += 'draft';
                break;
            case MESSAGE_STATES.PENDING:
                tooltip += 'pending';
                break;
            case MESSAGE_STATES.PUBLISHED:
                tooltip += 'published';
                break;
            case MESSAGE_STATES.EXPIRED:
                tooltip += 'messageExpired';
                break;
            case MESSAGE_STATES.DELETED:
                tooltip += 'messageDeleted';
                break;
            case MESSAGE_STATES.PLANNED:
                tooltip += 'messagePlanned';
                break;
            default:
                tooltip = '';
                break;
        }
        return $filter('translate')(tooltip);
    };
}]);
