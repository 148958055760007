'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserProfileController
 * @description
 * # UserProfileController
 */
angular.module('dialengaExeviBackoffice').controller('UserProfileController',
    ['$scope', '$rootScope', '$routeParams', '$location', '$translate', 'DateUtilsService', 'GlobalService', 'LocationService', 'AuthenticationService', 'KendoUtilsService', 'ModalService', 
    'NotificationService', 'Session', 'TimeoffSettingsSchemesService', 'UsersService', 'NOTIFICATION_CHANNEL', 'AVAILABLE_FEEDS', 'PUSH_OPTION','ALLOWED_CATEGORIES_KIND',
    'MODULES', 'USER_ROLES', 'USER_STATES', 'USER_ALLOWED_DOCS_KIND',
    function($scope, $rootScope, $routeParams, $location, $translate, DateUtilsService, GlobalService, LocationService, AuthenticationService, KendoUtilsService, ModalService,
        NotificationService, Session, TimeoffSettingsSchemesService, UsersService, NOTIFICATION_CHANNEL, AVAILABLE_FEEDS, PUSH_OPTION, ALLOWED_CATEGORIES_KIND,
        MODULES, USER_ROLES, USER_STATES, USER_ALLOWED_DOCS_KIND) {

        var vm = this;
        var DEFAULT_COUNTRY_CODE = 34;
        vm.userId = $routeParams.id;
        vm.USER_ROLES = USER_ROLES;
        vm.USER_STATES = USER_STATES;
        vm.NOTIFICATION_CHANNEL = NOTIFICATION_CHANNEL;
        vm.AVAILABLE_FEEDS = AVAILABLE_FEEDS;
        vm.ALLOWED_CATEGORIES_KIND = ALLOWED_CATEGORIES_KIND;
        vm.USER_ALLOWED_DOCS_KIND = USER_ALLOWED_DOCS_KIND;
        vm.isHolidaysModuleActive = false;
        vm.isHolidaysModuleTempDeactivated = false;
        vm.isTimeTrackingModuleActive = false;
        vm.isTimeTrackingModuleTempDeactivated = false;
        vm.isDocumentsModuleTempDeactivated = false;
        vm.isDatePickerFocused = false;
        vm.isValidEntryDate = true;
        vm.isValidLeavingDate = true;
        vm.isValidSeniorityDate = true;
        vm.personalInfoDisplayed = false;
        vm.kendoSSNumber = '';
        vm.kendoChildren = '';

        vm.isEmployeeManagerOnly = AuthenticationService.isEmployeeManagerOnly();

        vm.availableRoles = [
            USER_ROLES.admin,
            USER_ROLES.publisher,
            USER_ROLES.manager,
            USER_ROLES.employeeManager
        ];

        vm.userForm = {
            user: {
                roles: [],
                phoneNumber: {
                    countryCode: { code: DEFAULT_COUNTRY_CODE }
                },
            },
            passwordInputType: "password",
            currentPasswordInputType: "password",
            newPasswordInputType: "password",
            isUsernameEditable: false,
            noRolesSpecified: undefined,
            valid: true,
            isValidNotificationChannel: true,
            isValidSocialSecurityNumber: true,
            isValidBankAccount: true
        };
        vm.userState = {};
        vm.companyName = Session.externalLoginBrand;

        vm.notesTextareaOptions = KendoUtilsService.getTextareaOptions();
        vm.notesTextareaOptions.keyup = function () {
            vm.userForm.user.notes = this.value();
        };
        vm.notesTextareaOptions.tools.splice(4,2);
        vm.notesTextareaOptions.tools.splice(0,1);
        vm.notesTextareaOptions.placeholder = $translate.instant('users.notesPlaceholder');

        vm.$onInit = function() {
            vm.isHolidaysModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.HOLIDAYS);
            vm.isHolidaysModuleTempDeactivated = Session.activeModules[MODULES.HOLIDAYS] && Session.activeModules[MODULES.HOLIDAYS].state == '2';
            vm.isDocumentsModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.DOCUMENTS);
            vm.isDocumentsModuleTempDeactivated = Session.activeModules[MODULES.DOCUMENTS] && Session.activeModules[MODULES.DOCUMENTS].state == '2';
            vm.isTimeTrackingModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.TIME_TRACKING);
            vm.isTimeTrackingModuleTempDeactivated = Session.activeModules[MODULES.TIME_TRACKING] && Session.activeModules[MODULES.TIME_TRACKING].state == '2';
            vm.userLoggedHasAdminRole = Session.roles.indexOf(USER_ROLES.admin) !== -1

            vm.userLanguagesOptions = UsersService.getUserLanguagesOptions();
            vm.userMaritalStatusOptions = UsersService.getUserMaritalStatusOptions();
            vm.userCountryCodesOptions = LocationService.getCountryCodesOptions();
            vm.userNationalityOptions = LocationService.getNationalityOptions();
            vm.userCountriesOptions = Session.multiCountry ? LocationService.getUserCountriesOptions() : null;
            vm.userNotificationChannel = $rootScope.account.notificationChannel;
            vm.userCompaniesOptions = UsersService.getCompaniesOptions();
            vm.userWorkplacesOptions = UsersService.getWorkplacesOptions();
            vm.userDepartmentsOptions = UsersService.getDepartmentsOptions();
            vm.userTeamsOptions = UsersService.getTeamsOptions();
            vm.pushNotificationOptions = UsersService.getpushNotificationOptions();
            vm.highlightContentOptions = UsersService.getHighlightContentOptions();
            vm.allowCategoriesKindOptions = UsersService.getAllowedKindOptions('#allowed-categories-kind');
            vm.allowFilesKindOptions = UsersService.getAllowedKindOptions('#allowed-documents-kind');
            vm.userSchemasOptions = TimeoffSettingsSchemesService.getSchemasOptions();
            vm.userScheduleOptions = UsersService.getSchedulesOptions();

            initializeAvailableRoles();

            var datePickerOptions = getDatePickerOptions();
            vm.entryDatePickerOptions = angular.copy(datePickerOptions);
            vm.leaveDatePickerOptions = angular.copy(datePickerOptions);
            vm.birthDatePickerOptions = angular.copy(datePickerOptions);
            vm.seniorityDatePickerOptions = angular.copy(datePickerOptions);

            var today = new Date().setFullYear(new Date().getFullYear() - 16);
            vm.birthDatePickerOptions.max = new Date(today);


            if ($scope.$parent.vm.user) {
                vm.userForm.user = $scope.$parent.vm.user;

                if (vm.userForm.user.companyEntryDate) {
                    vm.userForm.user.kendoCompanyEntryDate = moment(vm.userForm.user.companyEntryDate + 'T00:00:00Z').utc().format('yyyy-MM-DD');
                    vm.leaveDatePickerOptions.min = vm.userForm.user.kendoCompanyEntryDate;
                    vm.seniorityDatePickerOptions.max = vm.userForm.user.kendoCompanyEntryDate;
                }
                if (vm.userForm.user.companyLeavingDate) {
                    vm.userForm.user.kendoCompanyLeavingDate = moment(vm.userForm.user.companyLeavingDate + 'T00:00:00Z').utc().format('yyyy-MM-DD');
                    vm.entryDatePickerOptions.max = vm.userForm.user.kendoCompanyLeavingDate;
                    vm.seniorityDatePickerOptions.max = vm.userForm.user.kendoCompanyLeavingDate;
                }
                if (vm.userForm.user.seniorityDate) {
                    vm.userForm.user.kendoSeniorityDate = moment(vm.userForm.user.seniorityDate + 'T00:00:00Z').utc().format('yyyy-MM-DD');
                    vm.entryDatePickerOptions.min = vm.userForm.user.kendoSeniorityDate;
                    vm.leaveDatePickerOptions.min = vm.userForm.user.kendoSeniorityDate;
                }
                if (vm.userForm.user.privateInfo && vm.userForm.user.privateInfo.birthDate) {
                    vm.userForm.user.privateInfo.kendoBirthDate = moment(vm.userForm.user.privateInfo.birthDate + 'T00:00:00Z').utc().format('yyyy-MM-DD');
                }
                if (!vm.userForm.user.phoneNumber) {
                    vm.userForm.user.phoneNumber = { 
                        countryCode: { code: DEFAULT_COUNTRY_CODE }
                    };
                }
                vm.areRolesDisplayed = userHasAdvancedRoles();
                vm.userState= UsersService.getUserIcon(vm.userForm.user.state, vm.userForm.user.lastAccessDate);
                backofficeRolesSelected();
                initializeEditorPermissions();
                initializeDocumentsPermissions();
                copyInitialUserInfo();
            } else {
                vm.toggleRoleSelection(vm.USER_ROLES.user);                
                getInitialUserData();
            }
        };

        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget.options.name === 'DatePicker') {
                widget._dateInput.setOptions({
                    messages: {
                        year: 'yyyy',
                        month: 'mm',
                        day: 'dd'
                    }
                });
            }
        });

        function removeBlanksInFieldText (field) {
            var data = field.split(' ');
            return data.join('');
        }

        vm.onPasteIban = function(e) {
            e.preventDefault();
            var dataToPaste = e.originalEvent.clipboardData.getData('text/plain');
            vm.userForm.user.privateInfo.bankAccount = removeBlanksInFieldText(dataToPaste);
        };

        vm.onPasteSSNumber = function(e) {
            e.preventDefault();
            var dataToPaste = e.originalEvent.clipboardData.getData('text/plain');
            vm.userForm.user.privateInfo.socialSecurityNumber = removeBlanksInFieldText(dataToPaste);
        };

        function getDatePickerOptions() {
            return {
                dateInput: true,
                format: 'dd/MM/yyyy',
                weekNumber: true
            };
        }

        vm.datePickerFocusIn = function (event) {
            vm.isDatePickerFocused = true;

            if (event && event.target.id == 'seniority-date-picker') {
                if (!!vm.userForm.user.kendoCompanyEntryDate) {
                    vm.seniorityDatePicker.max(new Date(vm.userForm.user.kendoCompanyEntryDate));
                } else {
                    vm.seniorityDatePicker.max(new Date(2099, 11, 31));
                }
                if (!!vm.userForm.user.kendoCompanyLeavingDate) {
                    if (!vm.userForm.user.kendoCompanyEntryDate) {
                        vm.seniorityDatePicker.max(new Date(vm.userForm.user.kendoCompanyLeavingDate));
                    }
                } else {
                    if (!vm.userForm.user.kendoCompanyEntryDate) {
                        vm.seniorityDatePicker.max(new Date(2099, 11, 31));
                    }
                }
            }

            if (event && event.target.id == 'company-entry-date-picker') {
                if (!!vm.userForm.user.kendoSeniorityDate) {
                    vm.companyEntryDatePicker.min(new Date(vm.userForm.user.kendoSeniorityDate));
                } else {
                    vm.companyEntryDatePicker.min(new Date(1970, 0, 1));
                }
                if (!!vm.userForm.user.kendoCompanyLeavingDate) {
                    vm.companyEntryDatePicker.max(new Date(vm.userForm.user.kendoCompanyLeavingDate));
                } else {
                    vm.companyEntryDatePicker.max(new Date(2099, 11, 31));
                } 
            }

            if (event && event.target.id == 'company-leave-date-picker') {
                if (!!vm.userForm.user.kendoSeniorityDate) {
                    vm.companyLeaveDatePicker.min(new Date(vm.userForm.user.kendoSeniorityDate));
                } else {
                    vm.companyLeaveDatePicker.min(new Date(1970, 0, 1));
                }
                if (!!vm.userForm.user.kendoCompanyEntryDate) {
                    vm.companyLeaveDatePicker.min(new Date(vm.userForm.user.kendoCompanyEntryDate));
                } else {
                    if (!vm.userForm.user.kendoSeniorityDate) {
                        vm.companyLeaveDatePicker.min(new Date(1970, 0, 1));
                    }
                }
            }
        };
    
        vm.dateInputFocusOut = function (event) {
            vm.isDatePickerFocused = false;

            if (event && event.target.id == 'seniority-date-picker') {

                vm.isValidSeniorityDate = !!vm.userForm.user.kendoSeniorityDate ? DateUtilsService.isValidDate(new Date(vm.userForm.user.kendoSeniorityDate)) : true;

                if (!vm.isValidSeniorityDate) {
                    vm.userForm.user.kendoSeniorityDate = null;
                } else {
                    if (!!vm.userForm.user.kendoSeniorityDate) {
                        vm.companyEntryDatePicker.min(new Date(vm.userForm.user.kendoSeniorityDate));
                        vm.companyLeaveDatePicker.min(new Date(vm.userForm.user.kendoSeniorityDate));
                    }
                }
            }

            if (event && event.target.id == 'company-entry-date-picker') {

                vm.isValidEntryDate = !!vm.userForm.user.kendoCompanyEntryDate ? DateUtilsService.isValidDate(new Date(vm.userForm.user.kendoCompanyEntryDate)) : true;

                if (!vm.isValidEntryDate) {
                    vm.userForm.user.kendoCompanyEntryDate = null;
                } else {
                    if (!!vm.userForm.user.kendoCompanyEntryDate) {
                        vm.seniorityDatePicker.max(new Date(vm.userForm.user.kendoCompanyEntryDate));
                        vm.companyLeaveDatePicker.min(new Date(vm.userForm.user.kendoCompanyEntryDate));
                    } 
                }
            }

            if (event && event.target.id == 'company-leave-date-picker') {

                vm.isValidLeavingDate = !!vm.userForm.user.kendoCompanyLeavingDate ? DateUtilsService.isValidDate(new Date(vm.userForm.user.kendoCompanyLeavingDate)) : true;

                if (!vm.isValidLeavingDate) {
                    vm.userForm.user.kendoCompanyLeavingDate = null;
                } else {
                    if (!!vm.userForm.user.kendoCompanyLeavingDate) {
                        vm.companyEntryDatePicker.max(new Date(vm.userForm.user.kendoCompanyLeavingDate));
                        vm.seniorityDatePicker.max(new Date(vm.userForm.user.kendoCompanyLeavingDate));
                    } 
                }
            }

            vm.isValidBirthDate = !!vm.userForm.user.privateInfo.kendoBirthDate ? DateUtilsService.isValidDate(new Date(vm.userForm.user.privateInfo.kendoBirthDate)) : true;
            if (!vm.isValidBirthDate) {
                vm.userForm.user.privateInfo.kendoBirthDate = null;
            }
        };

        function userHasAdvancedRoles() {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                var roleName = vm.userForm.user.roles[index].name;
                if (roleName !== USER_ROLES.manager && vm.availableRoles.indexOf(roleName) !== -1) {
                    return true;
                }
            }
            return false;
        }

        function initializeEditorPermissions() {
            if (!vm.userForm.user.editorPermissions) {
                vm.userForm.user.editorPermissions = {
                    availableFeeds: AVAILABLE_FEEDS.ALL,
                    publishContentAllowed: false,
                    pushOptions: PUSH_OPTION.CUSTOMIZABLE,
                    viewStatisticsAllowed: true,
                    highlightContentAllowed: true,
                    allowedCategoriesKind: ALLOWED_CATEGORIES_KIND.ALL,
                    allowedCategories: []
                }
            }
        }

        function initializeDocumentsPermissions() {
            if (!vm.userForm.user.documentManagerPermissions) {
                vm.userForm.user.documentManagerPermissions = {
                    allowanceKind: USER_ALLOWED_DOCS_KIND.ALL,
                    allowedFileKinds: []
                }
            }
        }

        function getInitialUserData() {
            vm.userForm.user.language = Session.companyDefaultLanguage;
            vm.userForm.user.holidaysActive = Session.activeModules[MODULES.HOLIDAYS].enabledByDefault;
            vm.userForm.user.documentsActive = Session.activeModules[MODULES.DOCUMENTS].enabledByDefault;
            vm.userForm.user.isEditorUser = false;
            initializeEditorPermissions();
            initializeDocumentsPermissions();
            copyInitialUserInfo();
            vm.areRolesDisplayed = false;
        }

        function initializeAvailableRoles() {
            if (Session && Session.activeModules) {
                if (Session.activeModules[MODULES.HOLIDAYS] && (Session.activeModules[MODULES.HOLIDAYS].state == '1' || Session.activeModules[MODULES.HOLIDAYS].state == '2')) {
                    vm.availableRoles.push(USER_ROLES.humanResources);
                    vm.isHolidaysModuleActive = true;
                }
                if (Session.activeModules[MODULES.DOCUMENTS] && (Session.activeModules[MODULES.DOCUMENTS].state == '1' || Session.activeModules[MODULES.DOCUMENTS].state == '2')) {
                    vm.availableRoles.push(USER_ROLES.documentsManager);
                    vm.isDocumentsModuleActive = true;
                }
            }
            vm.availableRoles.push(USER_ROLES.ftrayManager);
            vm.availableRoles.push(USER_ROLES.chatroomManager);
            vm.availableRoles.push(USER_ROLES.ftrayResponsible);
            vm.availableRoles.push(USER_ROLES.teamResponsible);
        }

        function userHasRoles() {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                var roleName = vm.userForm.user.roles[index].name;
                if (roleName === USER_ROLES.user || roleName === USER_ROLES.manager || vm.availableRoles.indexOf(roleName) !== -1) {
                    return true;
                }
            }
            return false;
        }

        function parsePhoneNumberIfPresent() {
            if (vm.userForm.user.phoneNumber && vm.userForm.user.phoneNumber.number) {
                vm.userForm.user.phoneNumber.number = vm.userForm.user.phoneNumber.number.replace(/\s+/g, '');
            } else {
                vm.userForm.user.phoneNumber = { countryCode: { code: DEFAULT_COUNTRY_CODE } };
            }
        }

        function parseCategories() {
            if (vm.userForm.user.isEditorUser && vm.userForm.user.editorPermissions.allowedCategoriesKind !== vm.ALLOWED_CATEGORIES_KIND.ALL) {
                if (vm.userForm.user.editorPermissions.allowedCategories.length > 0) {
                    vm.userForm.user.editorPermissions.allowedCategories = vm.userForm.user.editorPermissions.allowedCategories.map(function (category) {
                        return { id: category.id};
                    })
                }
            } else {
                if (vm.userForm.user.editorPermissions) {
                    vm.userForm.user.editorPermissions.allowedCategories = [];
                }
            }
        }

        function parsePrivateInfoData() {
            if (vm.userForm.user.privateInfo && vm.userForm.user.privateInfo.maritalStatus) {
                var maritalStatusId = vm.userForm.user.privateInfo.maritalStatus;
                vm.userForm.user.privateInfo.maritalStatus = { id: maritalStatusId };
            } else {
                vm.userForm.user.privateInfo.maritalStatus = null;
            }
            if (vm.userForm.user.privateInfo && vm.userForm.user.privateInfo.nationality) {
                var nationalityId = vm.userForm.user.privateInfo.nationality;
                vm.userForm.user.privateInfo.nationality = { id: nationalityId };
            } else {
                vm.userForm.user.privateInfo.nationality = null;
            }
            if (vm.userForm.user.privateInfo && vm.userForm.user.privateInfo.socialSecurityNumber) {
                vm.userForm.user.privateInfo.socialSecurityNumber = removeBlanksInFieldText(vm.userForm.user.privateInfo.socialSecurityNumber);
            }
            if (vm.userForm.user.privateInfo && vm.userForm.user.privateInfo.bankAccount) {
                vm.userForm.user.privateInfo.bankAccount = removeBlanksInFieldText(vm.userForm.user.privateInfo.bankAccount);
            }
        }

        function parseEditorPermissions() {
            if (!vm.userForm.user.isEditorUser) {
                vm.userForm.user.editorPermissions = null;
            } 
        }

        function isValidNotificationChannel(user) {
            var valid = true;
            switch ($rootScope.account.notificationChannel) {
                case NOTIFICATION_CHANNEL.EMAIL:
                    valid = !!user.email && user.email !== '';
                    break;
                case NOTIFICATION_CHANNEL.SMS:
                    valid = !!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '';
                    break;
                case NOTIFICATION_CHANNEL.ANY:
                    valid = (!!user.email && user.email !== '') || (!!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '');
                    break;
                case NOTIFICATION_CHANNEL.BOTH:
                    valid = (!!user.email && user.email !== '') && (!!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '');
                    break;
                default:
                    break;
            }
            return valid;
        }

        function notifySuccessAndRedirect() {
            var message = ''
            if (vm.userId) {
                message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);
                UsersService.latestUser = angular.copy(vm.userForm.user);
            } else {
                message = $translate.instant('users.saveSuccess');
                NotificationService.notifySuccess(message);
                $location.path('/users');
            }
        }

        function updateUserData(userForm, field) {
            var promise;
            userForm.user.companyEntryDate = userForm.user.kendoCompanyEntryDate ? moment(userForm.user.kendoCompanyEntryDate).format('YYYY-MM-DD') : null;
            userForm.user.companyLeavingDate = userForm.user.kendoCompanyLeavingDate ? moment(userForm.user.kendoCompanyLeavingDate).format('YYYY-MM-DD') : null;
            userForm.user.seniorityDate = userForm.user.kendoSeniorityDate ? moment(userForm.user.kendoSeniorityDate).format('YYYY-MM-DD') : null;
            userForm.user.privateInfo.birthDate = userForm.user.privateInfo.kendoBirthDate ? moment(userForm.user.privateInfo.kendoBirthDate).format('YYYY-MM-DD') : null;
            userForm.user.subsidiaryCompany = !!userForm.user.subsidiaryCompany && userForm.user.subsidiaryCompany.id ? userForm.user.subsidiaryCompany : null;
            userForm.user.headquarter = !!userForm.user.headquarter && userForm.user.headquarter.id ? userForm.user.headquarter : null;
            userForm.user.department = !!userForm.user.department && userForm.user.department.id ? userForm.user.department : null;
            userForm.user.timeOffSchema = !!userForm.user.timeOffSchema && userForm.user.timeOffSchema.id ? userForm.user.timeOffSchema : null;
            userForm.user.team = !!userForm.user.team && userForm.user.team.id ? userForm.user.team : null;
            userForm.user.privateInfo.maritalStatus = !!userForm.user.privateInfo.maritalStatus && userForm.user.privateInfo.maritalStatus.id ? userForm.user.privateInfo.maritalStatus : null;
            userForm.user.privateInfo.nationality = !!userForm.user.privateInfo.nationality && userForm.user.privateInfo.nationality.id ? userForm.user.privateInfo.nationality : null;
            parseEditorPermissions();

            if (userForm.user.id) {
                promise = UsersService.update(userForm);
            } else {
                promise = UsersService.create(userForm);
            }
            promise.then(function() {
                notifySuccessAndRedirect();
            }, function() {
                if (field && field === 'email') {
                    vm.userForm.user.email = UsersService.latestUser.email;
                    document.getElementById("user-email").focus();
                } 
                if (field && field === 'username') {
                    vm.userForm.user.username = UsersService.latestUser.username;
                    document.getElementById("user-id").focus();
                } 
            });
        }

        function scrollToTop (pos) {
            $('html, body').animate({
                scrollTop: pos
            }, 300); 
        }

        vm.saveUser = function(isFormValid, isNameValid, isSurnameValid, isUsernameValid) {
            var isFormDirty = dataHasChanged();
            if (!isFormDirty && isFormValid) {
                notifySuccessAndRedirect();
            } else {
                vm.userForm.isValidNotificationChannel = isValidNotificationChannel(vm.userForm.user);
                vm.userForm.isValidBankAccount = (!!vm.userForm.user.privateInfo.bankAccount && (vm.userForm.user.privateInfo.bankAccount.trim().length == 29 || vm.userForm.user.privateInfo.bankAccount.trim().length == 24)) || !vm.userForm.user.privateInfo.bankAccount;
                vm.userForm.isValidSocialSecurityNumber = (!!vm.userForm.user.privateInfo.socialSecurityNumber && (vm.userForm.user.privateInfo.socialSecurityNumber.trim().length == 14 || vm.userForm.user.privateInfo.socialSecurityNumber.trim().length == 12)) || !vm.kendoSSNumber.trim();
                vm.userForm.valid = isFormValid && 
                                    vm.isValidEntryDate && 
                                    vm.isValidLeavingDate && 
                                    vm.userForm.isValidNotificationChannel && 
                                    (!Session.multiCountry || !!vm.userForm.user.country) &&
                                    vm.userForm.isValidBankAccount && vm.userForm.isValidSocialSecurityNumber;
                vm.userForm.noRolesSpecified = !userHasRoles();
                if (!vm.userForm.valid || vm.userForm.noRolesSpecified) {
                    if (vm.userForm.noRolesSpecified || !isNameValid || !isSurnameValid || !isUsernameValid || !vm.userForm.isValidBankAccount || !vm.userForm.isValidSocialSecurityNumber) {
                        vm.userForm.noRolesSpecified || !isNameValid || !isSurnameValid || !isUsernameValid ? scrollToTop(0) : scrollToTop(300);
                    } else {
                        !vm.personalInfoDisplayed ? scrollToTop(500) : scrollToTop(800);
                    }
                    $rootScope.$broadcast('dialenga:loadingEnd');
                    return;
                }

                parsePhoneNumberIfPresent();
                parseCategories();
                parsePrivateInfoData();

                parseEditorPermissions();
                updateUserData(vm.userForm);
            }
        };

        vm.generatePassword = function() {
            UsersService.generatePassword().then(function(response) {
                vm.userForm.user.password = response.data.password;
                vm.userForm.passwordInputType = "text";
            });
        };

        vm.showHidePassword = function(fieldName) {
            vm.userForm[fieldName] = GlobalService.showHidePassword(vm.userForm[fieldName]);
        };

        vm.showDisabledAdvice = function() {
            var message;
            if (vm.userHasAdminOrPublisherRol()) {
                message = $translate.instant('users.validation.editorRolAlreadyIncluded');
            } else {
                message = $translate.instant('users.validation.userMustHaveOneRol');
            }
            NotificationService.notifyInfo(message, false, {delay: 5000});
        }

        function hasChangedDropDownValue(modelName) {
            if (!vm.userForm.user[modelName] || !vm.userForm.user[modelName].id) {
                return !!UsersService.latestUser[modelName] && !!UsersService.latestUser[modelName].id;
            } else {
                return !UsersService.latestUser[modelName] || !UsersService.latestUser[modelName].id || UsersService.latestUser[modelName].id !== vm.userForm.user[modelName].id;
            }
        }

        function dataHasChanged() {
            return !angular.equals(vm.userForm.user, UsersService.latestUser) ||
                (hasChangedDropDownValue('headquarter') || hasChangedDropDownValue('timeOffSchema') || hasChangedDropDownValue('team'));
        }

        vm.goBack = function() {
            var isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(isFormDirty, '/users');
        };

        function copyInitialUserInfo() {
            if (!vm.userForm.user.phoneNumber) {
                vm.userForm.user.phoneNumber = { countryCode: { code: DEFAULT_COUNTRY_CODE } };
            }

            var initialDropdownValue = $translate.instant('global.form.notSet');
            if (!vm.userForm.user.subsidiaryCompany) {
                vm.userForm.user.subsidiaryCompany = {
                    name: initialDropdownValue
                };
            }
            if (!vm.userForm.user.headquarter) {
                vm.userForm.user.headquarter = {
                    name: initialDropdownValue
                };
            }
            if (!vm.userForm.user.department) {
                vm.userForm.user.department = {
                    name: initialDropdownValue
                };
            }
            if (!vm.userForm.user.team) {
                vm.userForm.user.team = {
                    name: initialDropdownValue
                };
            }
            if (!vm.userForm.user.timeOffSchema) {
                vm.userForm.user.timeOffSchema = {
                    name: initialDropdownValue
                };
            }
            if (!vm.userForm.user.privateInfo) {
                vm.userForm.user.privateInfo = {
                    socialSecurityNumber: '',
                    bankAccount: '',
                    birthDate: null,
                    maritalStatus: vm.userId ? { status: initialDropdownValue, id: '' } : null,
                    nationality: vm.userId ? { country: $translate.instant('global.form.notSetFemale'), id: '' } : null
                }
            } else {
                vm.userForm.user.privateInfo.socialSecurityNumber = vm.userForm.user.privateInfo.socialSecurityNumber ? vm.userForm.user.privateInfo.socialSecurityNumber : '';
                vm.userForm.user.privateInfo.bankAccount = vm.userForm.user.privateInfo.bankAccount ? vm.userForm.user.privateInfo.bankAccount : '';
                vm.userForm.user.privateInfo.birthDate = vm.userForm.user.privateInfo.birthDate ? vm.userForm.user.privateInfo.birthDate : null;
                vm.userForm.user.privateInfo.maritalStatus = vm.userForm.user.privateInfo.maritalStatus ? vm.userForm.user.privateInfo.maritalStatus : { status: initialDropdownValue, id: '' };
                vm.userForm.user.privateInfo.nationality = vm.userForm.user.privateInfo.nationality ? vm.userForm.user.privateInfo.nationality : { country: $translate.instant('global.form.notSetFemale'), id: '' };
            }
            
            UsersService.latestUser = angular.copy(vm.userForm.user);
        }

        vm.isRoleSelected = function(role) {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                if (vm.userForm.user.roles[index].name === role && role !== USER_ROLES.manager) {
                    return true;
                }
            }
            return false;
        };

        vm.toggleRoleSelection = function(role) {
            // If role is already selected, remove it from user roles and return
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                if (vm.userForm.user.roles[index].name === role) {
                    if (role == USER_ROLES.manager) {
                        vm.userForm.user.isEditorUser = false;
                    }
                    vm.userForm.user.roles.splice(index, 1);
                    backofficeRolesSelected();
                    if(vm.userId) {
                        vm.onChange();
                    }
                    return;
                }
            }
            // The role is not already selected: add it and check for special cases
            // if (role == USER_ROLES.admin) {
            //     var UserHasAppUserRole = vm.isRoleSelected(vm.USER_ROLES.user);
            //     vm.userForm.user.roles = [];
            //     vm.userForm.user.isEditorUser = false;
            //     if (UserHasAppUserRole) {
            //         vm.userForm.user.roles.push({ "name": vm.USER_ROLES.user });
            //     }
            // }

            vm.userForm.user.roles.push({ "name": role });

            if (role == USER_ROLES.admin || role == USER_ROLES.publisher) {
                var hasManagerRoleIndex = vm.userForm.user.roles.findIndex(function (rol) { return rol.name === USER_ROLES.manager; });
                if (hasManagerRoleIndex != -1) {
                    vm.userForm.user.roles.splice(hasManagerRoleIndex, 1);
                    vm.userForm.user.isEditorUser = false;
                }
            }
            if ( role == USER_ROLES.manager) {
                initializeEditorPermissions();
            }
            backofficeRolesSelected();
            if(vm.userId) {
                vm.onChange();
            }
        };

        vm.togglePublishContentAllowed = function() {
            vm.userForm.user.editorPermissions.publishContentAllowed = !vm.userForm.user.editorPermissions.publishContentAllowed;
            vm.onChange();
        };

        vm.toggleViewStatisticsAllowed = function() {
            vm.userForm.user.editorPermissions.viewStatisticsAllowed = !vm.userForm.user.editorPermissions.viewStatisticsAllowed;
            vm.onChange();
        };

        vm.setAvailableFeeds = function(section) {
            vm.userForm.user.editorPermissions.availableFeeds = section;
            if (vm.userId) {
                vm.onChange();
            }
        };

        vm.userHasFirstLevelRol = function() {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                if (vm.userForm.user.roles[index].name === USER_ROLES.admin || 
                    vm.userForm.user.roles[index].name === USER_ROLES.humanResources || 
                    vm.userForm.user.roles[index].name === USER_ROLES.documentsManager ) {
                        return true;
                }
            }
            return false;
        };

        vm.userHasAdminOrPublisherRol = function() {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                if (vm.userForm.user.roles[index].name === USER_ROLES.admin || 
                    vm.userForm.user.roles[index].name === USER_ROLES.publisher) {
                        return true;
                }
            }
            return false;
        };

        vm.editableRolForEmployeeManager = function (role) {
            if (role === USER_ROLES.admin || role === USER_ROLES.humanResources || role === USER_ROLES.documentsManager) {
                return false;
            } 
            return true;
        };

        vm.userhasRol = function(checkedRol) {
            return vm.userForm.user.roles.findIndex(function (rol) { return rol.name === checkedRol; }) !== -1;
        };

        vm.setAllowedDocumentsKindAll = function(kind) {
            vm.userForm.user.documentManagerPermissions.allowanceKind = kind;
            vm.userForm.user.documentManagerPermissions.allowedFileKinds = [];
            if (vm.userId) {
                vm.onChange();
            }
        };

        vm.setAllowCategoriesKindAll = function(kind) {
            vm.userForm.user.editorPermissions.allowedCategoriesKind = kind;
            vm.userForm.user.editorPermissions.allowedCategories = [];
            if (vm.userId) {
                vm.onChange();
            }
        };

        vm.onChangeCategoriesKind = function() {
            if (vm.userForm.user.editorPermissions.allowedCategories.length > 0) {
                vm.onChange();
            }
        }

        vm.selectCategoriesForUser = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/user/user-categories-modal.html',
                controller: 'UserCategoriesModalController',
                size: 'lg',
                resolveParams: {
                    categories: angular.copy(vm.userForm.user.editorPermissions.allowedCategories),
                    allowedCategoriesKind: vm.userForm.user.editorPermissions.allowedCategoriesKind
                },
                accept: function(categories) {
                    vm.userForm.user.editorPermissions.allowedCategories = categories;
                    if(vm.userId) {
                        vm.onChange();
                    }
                }
            });
        };

        vm.onChangeAllowedDocsKind = function() {
            if (vm.userForm.user.documentManagerPermissions.allowedFileKinds.length > 0) {
                vm.onChange();
            }
        }

        vm.selectFileKindsForUser = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/user/user-filekinds-modal.html',
                controller: 'UserFilekindsModalController',
                size: 'lg',
                resolveParams: {
                    fileKinds: angular.copy(vm.userForm.user.documentManagerPermissions.allowedFileKinds),
                    allowanceKind: vm.userForm.user.documentManagerPermissions.allowanceKind
                },
                accept: function(fileKinds) {
                    vm.userForm.user.documentManagerPermissions.allowedFileKinds = fileKinds;
                    if(vm.userId) {
                        vm.onChange();
                    }
                }
            });
        };

        vm.selectSegmentsForUser = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/user/user-segments-modal.html',
                controller: 'UserSegmentsModalController',
                size: 'lg',
                resolveParams: {
                    segments: angular.copy(vm.userForm.user.segments),
                },
                accept: function(segments) {
                    vm.userForm.user.segments = segments;
                    if(vm.userId) {
                        vm.onChange();
                    }
                }
            });
        };

        function getIndexOfItemInCategories(category) {
            var categoriesLength = vm.userForm.user.editorPermissions.allowedCategories.length;
            for (var index = 0; index < categoriesLength; index++) {
                if (vm.userForm.user.editorPermissions.allowedCategories[index].id === category.id) {
                    return index;
                }
            }
            return -1;
        }

        vm.removeCategoryFromUser = function(category) {
            var categoryIndex = getIndexOfItemInCategories(category);
            if (categoryIndex !== -1) {
                vm.userForm.user.editorPermissions.allowedCategories.splice(categoryIndex, 1);
                if(vm.userId) {
                    vm.onChange();
                }
            }
        };

        function getIndexOfItemInFilekinds(filekind) {
            var filekindsLength = vm.userForm.user.documentManagerPermissions.allowedFileKinds.length;
            for (var index = 0; index < filekindsLength; index++) {
                if (vm.userForm.user.documentManagerPermissions.allowedFileKinds[index].id === filekind.id) {
                    return index;
                }
            }
            return -1;
        }

        vm.removeFilekindFromUser = function(filekind) {
            var filekindIndex = getIndexOfItemInFilekinds(filekind);
            if (filekindIndex !== -1) {
                vm.userForm.user.documentManagerPermissions.allowedFileKinds.splice(filekindIndex, 1);
                if(vm.userId) {
                    vm.onChange();
                }
            }
        };

        function backofficeRolesSelected() {
            var selectedRoles = vm.availableRoles.filter(function(role) {
                return vm.isRoleSelected(role);
            });

            vm.userForm.user.isEditorUser = vm.userhasRol(USER_ROLES.manager);
            vm.userForm.user.isAppUser = vm.userhasRol(USER_ROLES.user);

            vm.firstSelectedRole = selectedRoles.length > 0 ? selectedRoles[0] : '';
        }

        function userCanRequestHolidays() {
            $scope.$parent.vm.user.canRequestHolidays = $scope.$parent.vm.user.headquarter && $scope.$parent.vm.user.headquarter.id &&
                $scope.$parent.vm.user.timeOffSchema && $scope.$parent.vm.user.timeOffSchema.id;
        }

        vm.onChange = function () {
            var isFormDirty = dataHasChanged();
            if (isFormDirty) {
                updateUserData(vm.userForm);
            }
        };

        vm.onChangeEmail = function () {
            var isFormDirty = dataHasChanged();
            if (isFormDirty) {
                updateUserData(vm.userForm, 'email');
            }
        };

        vm.onChangeUsername = function () {
            var isFormDirty = dataHasChanged();
            if (isFormDirty) {
                ModalService.openConfirmationModal({
                    title: 'users.modal.usernameEdited.title',
                    message: 'users.modal.usernameEdited.message',
                    messageData: {userId: vm.userForm.user.username},
                    acceptButtonText: 'global.form.continue',
                    isTextInterpolated: true,
                    accept: function() {
                        updateUserData(vm.userForm, 'username');
                    },
                    cancel: function() {
                        vm.userForm.user.username = UsersService.latestUser.username;
                        vm.cancelChanges = true;
                        vm.onChange();
                    }
                });
                document.getElementById("user-id").blur();
            }
        };

        vm.onChangeCompany = function () {
            if (!UsersService.latestUser.subsidiaryCompany || vm.userForm.user.subsidiaryCompany.id !== UsersService.latestUser.subsidiaryCompany.id) {
                $scope.$parent.vm.user.subsidiaryCompany = angular.copy(vm.userForm.user.subsidiaryCompany);
                vm.onChange();
            }
        };

        vm.onChangeWorkplace = function () {
            if (!UsersService.latestUser.headquarter || vm.userForm.user.headquarter.id !== UsersService.latestUser.headquarter.id) {
                $scope.$parent.vm.user.headquarter = angular.copy(vm.userForm.user.headquarter);
                userCanRequestHolidays();
                vm.onChange();
            }
        };

        vm.onChangeDepartment = function () {
            if (!UsersService.latestUser.department || vm.userForm.user.department.id !== UsersService.latestUser.department.id) {
                $scope.$parent.vm.user.department = angular.copy(vm.userForm.user.department);
                vm.onChange();
            }
        };

        vm.onChangeSchema = function () {
            if (!UsersService.latestUser.timeOffSchema || vm.userForm.user.timeOffSchema.id !== UsersService.latestUser.timeOffSchema.id) {
                $scope.$parent.vm.user.timeOffSchema = angular.copy(vm.userForm.user.timeOffSchema);
                userCanRequestHolidays();
                vm.onChange();
            }
        };
        
        vm.onChangeTeam = function () {
            if (!UsersService.latestUser.team || vm.userForm.user.team.id !== UsersService.latestUser.team.id) {
                vm.onChange();
            }
        };

        vm.onChangeSchedule = function () {
            if (!UsersService.latestUser.schedule || vm.userForm.user.schedule.id !== UsersService.latestUser.schedule.id) {
                $scope.$parent.vm.user.schedule = angular.copy(vm.userForm.user.schedule);
                vm.onChange();
            }
        };

        vm.onChangePhoneNumber = function () {
            parsePhoneNumberIfPresent();
            vm.onChange();
        };

        vm.onChangeCountryCode = function () {
            if (vm.userForm.user.phoneNumber.number) {
                vm.onChange();
            }
        };

        vm.onChangeLeavingDate = function () {
            if (vm.userForm.user.kendoCompanyLeavingDate && vm.userForm.user.state === USER_STATES.REGISTERED) {
                // Show advice modal an allow archive user
                ModalService.openConfirmationModal({
                    title: 'users.modal.userLeave.title',
                    message: 'users.modal.userLeave.message',
                    acceptButtonText: 'users.modal.userLeave.archiveButton',
                    cancelButtonText: 'users.modal.userLeave.noArchiveButton',
                    accept: function() {
                        UsersService.deactivate(vm.userId).then(function() {
                            var message = $translate.instant('users.modal.userLeave.userArchived');
                            NotificationService.notifySuccess(message, 1500);
                            $scope.$parent.vm.user.state = USER_STATES.DISABLED;
                            vm.userForm.user.state = USER_STATES.DISABLED;
                            vm.userState= UsersService.getUserIcon(vm.userForm.user.state, vm.userForm.user.lastAccessDate);
                            vm.onChange();
                        });
                    },
                    cancel: function() {
                        vm.onChange();
                    }
                });
            } else {
                vm.onChange();
            }
        };

        vm.onRestoredPreviousData = function() {
            message = $translate.instant('global.messages.dateInvalid');
            NotificationService.notifyWarning(message);
        }

        // Methods for user access and configuration tab
        vm.resetPassword = function() {
            var data = {};
            data.username = vm.userForm.user.username;
            UsersService.requestPasswordReset(data).then(function() {
                var message = $translate.instant('users.modal.notification.resetPassword');
                NotificationService.notifySuccess(message,1500);
                $rootScope.$broadcast('dialenga:loadingEnd');
            });

        };

        vm.sendActivationEmail = function() {
            var message;
            UsersService.sendActivationEmail(vm.userForm.user.id).then(function () {
                message = $translate.instant('users.modal.sendActivation.success');
                NotificationService.notifySuccess(message,1500);
                $rootScope.$broadcast('dialenga:loadingEnd');

            }, function () {
                message = $translate.instant('users.modal.sendActivation.error');
                NotificationService.notifyError(message);
                $rootScope.$broadcast('dialenga:loadingEnd');

            });
        };

        vm.sendAccessLinks = function() {
            var message;
            UsersService.sendAppLinksEmail(vm.userForm.user.id).then(function () {
                message = $translate.instant('users.modal.sendAppLinksEmail.success');
                NotificationService.notifySuccess(message,1500);
                $rootScope.$broadcast('dialenga:loadingEnd');

            }, function () {
                message = $translate.instant('users.modal.sendActivation.error');
                NotificationService.notifyError(message);
                $rootScope.$broadcast('dialenga:loadingEnd');

            });
        };

        vm.unlockUser = function () {
            var message;
            UsersService.unlockUser(vm.userForm.user.id).then(function (response) {
                vm.userForm.user.state = response.data.state;
                vm.userState = UsersService.getUserIcon(response.data.state, response.data.lastAccessDate);
                $scope.$parent.vm.userState = vm.userState;
                message = $translate.instant('users.modal.unlockUser.success');
                NotificationService.notifySuccess(message, 1500);
                $rootScope.$broadcast('dialenga:loadingEnd');
            }, function () {
                message = $translate.instant('users.modal.unlockUser.error');
                NotificationService.notifyError(message);
                $rootScope.$broadcast('dialenga:loadingEnd');
            });
        };

        vm.onChangeDocumentsState = function() {
            if(vm.userId) {
                UsersService.enableOrDisableDocumentsForUser(vm.userForm.user.id, vm.userForm.user.documentsCompanyEnabled).then(function() {
                    var message = $translate.instant('global.messages.changesUpdated.success');
                    NotificationService.notifySuccess(message,1500);
                });
            }
        };

        vm.onChangeTimeTrackingState = function() {
            if(vm.userId) {
                UsersService.enableOrDisableTimeTrackingForUser(vm.userForm.user.id, vm.userForm.user.timeTrackingCompanyEnabled).then(function() {
                    var message = $translate.instant('global.messages.changesUpdated.success');
                    NotificationService.notifySuccess(message,1500);
                });
            }
        };

        vm.onChangeHolidaysState = function() {
            if(vm.userId) {
                UsersService.enableOrDisableHolidaysForUser(vm.userForm.user.id, vm.userForm.user.holidaysCompanyEnabled).then(function() {
                    var message = $translate.instant('global.messages.changesUpdated.success');
                    NotificationService.notifySuccess(message,1500);
                    vm.userForm.user.canRequestHolidays = vm.userForm.user.holidaysActive && !!vm.userForm.user.timeOffSchema && !!vm.userForm.user.headquarter;
                });
            }
        };
    }
]);