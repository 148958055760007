'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:onEscape
 * @description
 * # onEscape
 */
angular.module('dialengaExeviBackoffice').directive('onEscape', function () {
    return function(scope, element, attrs) {
        element.bind('keydown keypress', function(event) {
            if(event.which === 27) {
                scope.$apply(function (){
                    scope.$eval(attrs.onEscape);
                });
                event.preventDefault();
            }
        });
    };
});