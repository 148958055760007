'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ImportUsersErrorlogModalController
 * @description
 * # ImportUsersErrorlogModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ImportUsersErrorlogModalController',
    ['$uibModalInstance', '$translate', '$rootScope', 'KendoUtilsService', 'params', 'PAGE_SIZE',
    function ($uibModalInstance, $translate, $rootScope, KendoUtilsService, params, PAGE_SIZE) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.hidePager = params.issues.length < 11;
    vm.selectedButton = 0;
    vm.isGridEmpty = false;

    vm.ISSUE_KIND = {
        ALL: 0,
        ERROR: 1,
        WARNING: 2
    }

    vm.issueKinds = [
        { id: vm.ISSUE_KIND.ALL, title: 'usersImport.modalFilters.all' },
        { id: vm.ISSUE_KIND.ERROR, title: 'usersImport.modalFilters.errors', counter: params.errorCount },
        { id: vm.ISSUE_KIND.WARNING, title: 'usersImport.modalFilters.warnings', counter: params.warningCount }
    ];

    //******************************************* Methods ***************************************\\
    vm.accept = function () {
        $uibModalInstance.close();
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    vm.setSelected = function(kind) {
        vm.isGridEmpty = false;
        vm.selectedButton = kind.id;
        var filteredGrid = params.issues;

        if (vm.selectedButton !== vm.ISSUE_KIND.ALL) {
            filteredGrid = params.issues.filter(function(item) {
                return item.kind === kind.id;
            });
        }

        if (filteredGrid.length === 0) {
            vm.isGridEmpty = true;
        }
        
        var errorsGrid = $("#errors-grid").data("kendoGrid");
        errorsGrid.dataSource.data(filteredGrid);
    };    

    vm.exportAsExcel = function() {
        var errorsGrid = $("#errors-grid").data("kendoGrid");
        errorsGrid.saveAsExcel();
        $rootScope.$broadcast('dialenga:loadingEnd');
    };

    vm.getIssueKind = function(kind) {
        return kind === vm.ISSUE_KIND.WARNING ? 'import-users-row import-users-row--warning' : 'import-users-row';
    };

    function init() {
        var gridColumns = [{
            field: "line",
            title: $translate.instant('usersImport.errors.line'),
            headerTemplate: '<span translate="usersImport.errors.line"></span>',
            attributes: { class: "{{vm.getIssueKind(dataItem.kind)}}" },
            width: "80px"
        }, {
            field: "error",
            title: $translate.instant('usersImport.errors.issue'),
            headerTemplate: '<span translate="usersImport.errors.issue"></span>'
        }];
    
        var gridDataSource = new kendo.data.ObservableObject({
            pageSize: PAGE_SIZE,
            data: params.issues
        });

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource).then(function (response) {
            vm.gridOptions = response;
            vm.gridOptions.excel = {
                allPages: true,
                filterable: true,
                fileName: "load-users-errors.xlsx"
            };
        });
    }

    init();
}]);