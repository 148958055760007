/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserHolidaysController
 * @description
 * # UserHolidaysController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserHolidaysController',
    ['$q','$rootScope','$timeout', '$scope', '$translate', '$routeParams', '$location', 'ModalService', 'TimeoffService', 'KendoUtilsService', 'NotificationService', 'TIMEOFF_REQUEST_STATUS', 
        'TIMEOFF_KIND', 'GRID_SHORT_DATE_COLUMN_WIDTH',
    function($q, $rootScope, $timeout, $scope, $translate, $routeParams, $location, ModalService, TimeoffService, KendoUtilsService, NotificationService, TIMEOFF_REQUEST_STATUS, 
        TIMEOFF_KIND, GRID_SHORT_DATE_COLUMN_WIDTH) {

        var vm = this;
        var TIMEOFF_KIND_ALL = 0;
        var requestsToShow = 5;
        vm.remainingRequests = 0;  
        vm.optionViewPortHolidays = 1; 
        vm.optionViewPortAbsences = 1; 

        vm.userId = $routeParams.id;
        vm.summaryHolidays = {
            content: {}
        };
        vm.summaryAbsences = {
            content: {}
        };
        vm.periodHolidays = {
            content: {}
        };

        vm.currentPeriod = vm.lastPeriod;
        vm.percents = {};
        var datasetHolidaysBalance = [];
        var datasetHolidaysActivity = [];
        var datasetHolidaysAvailable = [];
        var datasetFreeDisposal = [];
        var datasetFreeDisposalBalance = [];
        var datasetHolidaysEarnedSerieOne = [];
        var datasetHolidaysEarnedSerieTwo = [];
        var datasetAbsencesTipology = [];
        var datasetAbsencesConsumed = [];
        var datasetAbsencesRemunerable = [];
        vm.chartsIsLoading = true;
        
        var gridColumns = [{
            field: 'state',
            title: ' ',
            template: '<span></span>', // Show empty column
            width: 6,
            attributes: { class: "{{vm.getTimeoffRequestClass(dataItem.state)}}" }
        }, {
            field: 'kind',
            title: $translate.instant('timeoff.requests.grid.requestKind'),
            template: '<span class="truncate" ng-if="dataItem.kind == ' + TIMEOFF_KIND.HOLIDAYS + '" translate="timeoff.requests.kindFilters.holidays"></span>'+
                '<span class="truncate" ng-if="dataItem.kind == ' + TIMEOFF_KIND.ABSENCE + '" <span ng-if="!!dataItem.absenceMotive" ng-bind="dataItem.absenceMotive.name"></span></span>'
        }, {
            field: 'requestDate',
            title: $translate.instant('timeoff.requests.grid.requestDate'),
            template: '<span ng-bind="dataItem.requestDate | shortDateFilter"></span>',
            attributes: { class: 'text-center' },
            headerAttributes: { class: 'text-center' },
            width: GRID_SHORT_DATE_COLUMN_WIDTH
        }, {
            field: 'startDate',
            title: $translate.instant('users.holidays.grid.requestedDateBegin'),
            template: '<span ng-bind="dataItem.startDate | shortDateFilter"></span>',
            attributes: { class: 'text-center' },
            headerAttributes: { class: 'text-center' },

            width: GRID_SHORT_DATE_COLUMN_WIDTH
        }, {
            field: 'endDate',
            title: $translate.instant('users.holidays.grid.requestedDateEnd'),
            template: '<span ng-bind="dataItem.endDate | shortDateFilter"></span>',
            attributes: { class: 'text-center' },
            headerAttributes: { class: 'text-center' },

            width: GRID_SHORT_DATE_COLUMN_WIDTH
        }, {
            field: 'requestedDays',
            title: $translate.instant('timeoff.requests.grid.requestedTime'),
            template: '<span ng-bind="vm.getRequestedTime(dataItem)"></span>',
            headerAttributes: { class: 'text-center' },

            attributes: { class: 'text-center' },
            width: 180
        }];

        var gridDataSource = {
            transport: {
                read: function (options) {
                    options.data.page = options.data.page - 1;
                    options.data.size = requestsToShow;
                    options.data['employee.id'] = vm.userId;
                    
                    options.data.sort = TimeoffService.getSortCriteria(options.data.sort, ['requestDate,desc']);
                    saveGridPreferences(options);

                    TimeoffService.findAll(options.data).then(function (response) {
                        vm.numResults = response.data.totalElements;
                        vm.remainingRequests = vm.numResults - requestsToShow > 10 ? 10 : vm.numResults - requestsToShow;
                        options.success(response.data);
                    }, function () {
                        options.error();
                    });
                }
            },
            schema: {
                data: 'content',
                total: 'totalElements',
                parse: function (data) {
                    $.each(data.content, function (idx, elem) {
                        if (elem.requestDate) {
                            data.content[idx].requestDate = new Date(elem.requestDate);
                        }
                    });
                    return data;
                }
            },
            pageSize: 5,
            serverPaging: true,
            serverSorting: true
        };

        var gridPageable = {
            pageSize: 5,
            previousNext: false,
            numeric: false,
            info: false
        };
        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true, pageable: gridPageable }, { change: navigateFromSelectedRow }).then(function (response) {
            vm.gridOptions = response;
        });

        vm.getTimeoffRequestClass = function (state) {
            var timeoffRequestClass = '';
            switch (state) {
                case TIMEOFF_REQUEST_STATUS.PENDING:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR:
                    timeoffRequestClass = 'bg-pending-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.APPROVED:
                    timeoffRequestClass = 'bg-approved-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.CANCELLED:
                    timeoffRequestClass = 'bg-cancelled-color';
                    break;
                case TIMEOFF_REQUEST_STATUS.DISALLOWED:
                case TIMEOFF_REQUEST_STATUS.REJECTED:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_HHRR:
                    timeoffRequestClass = 'bg-rejected-color';
                    break;
                default:
                    break;
            }
            return timeoffRequestClass;
        };

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['timeoffRequestsUserGrid'] = angular.copy(options.data);
        }

        function setDataGraphUserHolidays() {
            var spacerBalance = (vm.summaryHolidays.balance.baseDays + vm.summaryHolidays.balance.previousPeriodDays + vm.summaryHolidays.balance.extraDays) / 70;
            var spacerActivity = (vm.summaryHolidays.activitySummary.requestedDaysPendingApproval + 
                         vm.summaryHolidays.activitySummary.approvedDays + 
                         vm.summaryHolidays.activitySummary.rejectedDays + 
                         vm.summaryHolidays.activitySummary.cancelledDays) / 70;
            var spacerAvailable = (vm.summaryHolidays.consumed.enjoyedDays + vm.summaryHolidays.consumed.futureApprovedDays + 
                        vm.summaryHolidays.activitySummary.requestedDaysPendingApproval + vm.summaryHolidays.consumed.availableDays) / 70;

            datasetHolidaysBalance = [{
                "value": vm.summaryHolidays.balance.baseDays,
                "color": "#76E3DD"
            }, {
                "value": vm.summaryHolidays.balance.baseDays > 0 &&
                         (vm.summaryHolidays.balance.previousPeriodDays > 0 || vm.summaryHolidays.balance.extraDays > 0) ? spacerBalance : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.balance.previousPeriodDays,
                "color": "#3D6E89"
            }, {
                "value": vm.summaryHolidays.balance.previousPeriodDays > 0 && 
                         (vm.summaryHolidays.balance.baseDays > 0 || vm.summaryHolidays.balance.extraDays > 0) ? spacerBalance : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.balance.extraDays,
                "color": "#FFC165"
            }, {
                "value": vm.summaryHolidays.balance.extraDays > 0 && 
                         (vm.summaryHolidays.balance.baseDays > 0 || vm.summaryHolidays.balance.previousPeriodDays > 0) ? spacerBalance : 0,
                "color": "white",
                "dummy": true
            }];

            datasetHolidaysActivity = [{
                "value": vm.summaryHolidays.activitySummary.cancelledDays,
                "color": "#B5B5B5"
            }, {
                "value": vm.summaryHolidays.activitySummary.cancelledDays > 0 && 
                         (vm.summaryHolidays.activitySummary.requestedDaysPendingApproval > 0 || 
                          vm.summaryHolidays.activitySummary.approvedDays > 0 || 
                          vm.summaryHolidays.activitySummary.rejectedDays > 0) ? spacerActivity : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.activitySummary.rejectedDays,
                "color": "#EB7C8A"
            }, {
                "value": vm.summaryHolidays.activitySummary.rejectedDays > 0 && 
                         (vm.summaryHolidays.activitySummary.requestedDaysPendingApproval > 0 || 
                          vm.summaryHolidays.activitySummary.approvedDays > 0 || 
                          vm.summaryHolidays.activitySummary.cancelledDays > 0) ? spacerActivity : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.activitySummary.approvedDays,
                "color": "#96D480"
            }, {
                "value": vm.summaryHolidays.activitySummary.approvedDays > 0 && 
                         (vm.summaryHolidays.activitySummary.requestedDaysPendingApproval > 0 || 
                          vm.summaryHolidays.activitySummary.rejectedDays > 0 || 
                          vm.summaryHolidays.activitySummary.cancelledDays > 0) ? spacerActivity : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.activitySummary.requestedDaysPendingApproval,
                "color": "#F8C84C"
            }, {
                "value": vm.summaryHolidays.activitySummary.requestedDaysPendingApproval > 0 && 
                        (vm.summaryHolidays.activitySummary.approvedDays > 0 || 
                         vm.summaryHolidays.activitySummary.rejectedDays > 0 || 
                         vm.summaryHolidays.activitySummary.cancelledDays > 0) ? spacerActivity : 0,
                "color": "white",
                "dummy": true
            }];

            datasetHolidaysAvailable = [{
                "value": vm.summaryHolidays.consumed.enjoyedDays,
                "color": "#4C9C30"
            }, {
                "value": vm.summaryHolidays.consumed.enjoyedDays > 0 ? spacerAvailable : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.consumed.futureApprovedDays,
                "color": "#96D480"
            }, {
                "value": vm.summaryHolidays.consumed.futureApprovedDays > 0 ? spacerAvailable : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.activitySummary.requestedDaysPendingApproval,
                "color": "#F8C84C"
            }, {
                "value": vm.summaryHolidays.activitySummary.requestedDaysPendingApproval > 0 ? spacerAvailable : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.consumed.availableDays,
                "color": "#E6E6E6"
            }, {
                "value": vm.summaryHolidays.consumed.availableDays > 0 ? spacerAvailable : 0,
                "color": "white",
                "dummy": true
            }, {
                "category": "",
                "value": (vm.summaryHolidays.consumed.availableDays + vm.summaryHolidays.consumed.futureApprovedDays + 
                            vm.summaryHolidays.activitySummary.requestedDaysPendingApproval + vm.summaryHolidays.consumed.enjoyedDays)*280/360,
                "color": "white",
                "dummy": true                
            }];

            datasetHolidaysEarnedSerieOne = [{
                "value": vm.summaryHolidays.earned ? vm.summaryHolidays.earned.earnedDays : 0,
                "color": "#3D6E89"
            }, {
                "value": vm.summaryHolidays.earned && vm.summaryHolidays.earned.earnedDays > 0 ? 0.7 : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.earned ? vm.summaryHolidays.earned.daysToEarn : 0,
                "color": "#E6E6E6"
            }, {
                "value": vm.summaryHolidays.earned && vm.summaryHolidays.earned.daysToEarn> 0 ? 0.5 : 0,
                "color": "white",
                "dummy": true
            }, {
                "category": "",
                "value": vm.summaryHolidays.earned ? (vm.summaryHolidays.earned.daysToEarn+vm.summaryHolidays.earned.earnedDays)*280/360 : 0,
                "color": "white",
                "dummy": true                
            }];

            datasetHolidaysEarnedSerieTwo = [{
                "value": vm.summaryHolidays.earned ? vm.summaryHolidays.earned.enjoyedDays : 0,
                "color": "#4C9C30"
            }, {
                "value": vm.summaryHolidays.earned && vm.summaryHolidays.earned.enjoyedDays > 0 ? 0.5 : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.earned ? vm.summaryHolidays.earned.earnedDays : 0,
                "color": "white"
            }, {
                "value": vm.summaryHolidays.earned && vm.summaryHolidays.earned.earnedDays > 0 ? 0.5 : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.earned ? vm.summaryHolidays.earned.daysToEarn : 0,
                "color": "white"
            }, {
                "value": vm.summaryHolidays.earned && vm.summaryHolidays.earned.daysToEarn> 0 ? 0.5 : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryHolidays.earned ? (vm.summaryHolidays.earned.enjoyedDays+vm.summaryHolidays.earned.earnedDays+vm.summaryHolidays.earned.daysToEarn)*280/360 : 0,
                "color": "white",
                "dummy": true                
            }]
        }

        function setDataGraphUserAbsences() {
            var spacerConsumed =  (vm.summaryAbsences.consumed.consumedDays + vm.summaryAbsences.consumed.futureDays) / 70;
            var spacerRemunerable =  (vm.summaryAbsences.remunerable.remunerableDays + vm.summaryAbsences.remunerable.nonRemunerableDays) / 70;
            if (vm.summaryAbsences.personalDays) {
                var spacerAvailable = (vm.summaryAbsences.personalDays.enjoyedDays + vm.summaryAbsences.personalDays.futureApprovedDays + vm.summaryAbsences.personalDays.pendingDays + vm.summaryAbsences.personalDays.availableDays) / 70;
                var spacerBalance = (vm.summaryAbsences.personalDays.periodDays + vm.summaryAbsences.personalDays.acquiredDays + vm.summaryAbsences.personalDays.extraDays) / 70;
            }

            datasetAbsencesConsumed = [{
                "value": vm.summaryAbsences.consumed.consumedDays,
                "color": "#6D92A6"
            }, {
                "value": vm.summaryAbsences.consumed.consumedDays > 0 && vm.summaryAbsences.consumed.futureDays > 0 ? spacerConsumed : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryAbsences.consumed.futureDays,
                "color": "#76E3DD"
            }, {
                "value": vm.summaryAbsences.consumed.futureDays > 0 && vm.summaryAbsences.consumed.consumedDays > 0 ? spacerConsumed : 0,
                "color": "white",
                "dummy": true
            }];

            datasetAbsencesRemunerable = [{
                "value": vm.summaryAbsences.remunerable.remunerableDays,
                "color": "#76D6DC"
            }, {
                "value": vm.summaryAbsences.remunerable.remunerableDays > 0 && vm.summaryAbsences.remunerable.nonRemunerableDays > 0 ? spacerRemunerable : 0,
                "color": "white",
                "dummy": true
            }, {
                "value": vm.summaryAbsences.remunerable.nonRemunerableDays,
                "color": "#E38FC2"
            }, {
                "value": vm.summaryAbsences.remunerable.nonRemunerableDays > 0 && vm.summaryAbsences.remunerable.remunerableDays > 0 ? spacerRemunerable : 0,
                "color": "white",
                "dummy": true
            }];

            datasetAbsencesTipology = [{
                "category": vm.summaryAbsences.typologySummary.healthName,
                "value": vm.summaryAbsences.typologySummary.health,
                "color": "#48C8D0"
            }, {
                "category": vm.summaryAbsences.typologySummary.personalName,
                "value": vm.summaryAbsences.typologySummary.personal,
                "color": "#63657C"
            }, {
                "category": vm.summaryAbsences.typologySummary.professionalName,
                "value": vm.summaryAbsences.typologySummary.professional,
                "color": "#F170B6"
            }, {
                "category": vm.summaryAbsences.typologySummary.noRemunerableName,
                "value": vm.summaryAbsences.typologySummary.noRemunerable,
                "color": "#DF7513"                
            }];

            if (vm.summaryAbsences.personalDays) {
                datasetFreeDisposal = [{
                    "value": vm.summaryAbsences.personalDays.enjoyedDays,
                    "color": "#4C9C30"
                }, {
                    "value": vm.summaryAbsences.personalDays.enjoyedDays > 0 ? spacerAvailable : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "value": vm.summaryAbsences.personalDays.futureApprovedDays,
                    "color": "#96D480"
                }, {
                    "value": vm.summaryAbsences.personalDays.futureApprovedDays > 0 ? spacerAvailable : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "value": vm.summaryAbsences.personalDays.pendingDays,
                    "color": "#F8C84C"
                }, {
                    "value": vm.summaryAbsences.personalDays.pendingDays > 0 ? spacerAvailable : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "value": vm.summaryAbsences.personalDays.availableDays,
                    "color": "#E6E6E6"
                }, {
                    "value": vm.summaryAbsences.personalDays.availableDays > 0 ? spacerAvailable : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "category": "",
                    "value": (vm.summaryAbsences.personalDays.availableDays + vm.summaryAbsences.personalDays.pendingDays + vm.summaryAbsences.personalDays.futureApprovedDays + vm.summaryAbsences.personalDays.enjoyedDays)*280/360,
                    "color": "white",
                    "dummy": true                
                }];

                datasetFreeDisposalBalance = [{
                    "value": vm.summaryAbsences.personalDays.periodDays,
                    "color": "#76E3DD"
                }, {
                    "value": vm.summaryAbsences.personalDays.periodDays > 0 &&
                             (vm.summaryAbsences.personalDays.acquiredDays > 0 || vm.summaryAbsences.personalDays.extraDays > 0) ? spacerBalance : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "value": vm.summaryAbsences.personalDays.acquiredDays,
                    "color": "#3D6E89"
                }, {
                    "value": vm.summaryAbsences.personalDays.acquiredDays > 0 && 
                             (vm.summaryAbsences.personalDays.periodDays > 0 || vm.summaryAbsences.personalDays.extraDays > 0) ? spacerBalance : 0,
                    "color": "white",
                    "dummy": true
                }, {
                    "value": vm.summaryAbsences.personalDays.extraDays,
                    "color": "#FFC165"
                }, {
                    "value": vm.summaryAbsences.personalDays.extraDays > 0 && 
                             (vm.summaryAbsences.personalDays.periodDays > 0 || vm.summaryAbsences.personalDays.acquiredDays > 0) ? spacerBalance : 0,
                    "color": "white",
                    "dummy": true
                }];
            }
            
        }

        function navigateFromSelectedRow() {
            var selectedRow = this.select();
            var selectedRowData = vm.timeoffRequestsUserGrid.dataItem(selectedRow[0]);
            var state = [];
            switch (selectedRowData.state) {
                case TIMEOFF_REQUEST_STATUS.PENDING:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR:
                    state.push(TIMEOFF_REQUEST_STATUS.PENDING);
                    break;
                case TIMEOFF_REQUEST_STATUS.APPROVED:
                    state.push(TIMEOFF_REQUEST_STATUS.APPROVED)
                    break;
                case TIMEOFF_REQUEST_STATUS.CANCELLED:
                    state.push(TIMEOFF_REQUEST_STATUS.CANCELLED);
                    break;
                case TIMEOFF_REQUEST_STATUS.DISALLOWED:
                case TIMEOFF_REQUEST_STATUS.REJECTED:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_RESPONSIBLE:
                case TIMEOFF_REQUEST_STATUS.REJECTED_BY_HHRR:
                    state.push(TIMEOFF_REQUEST_STATUS.REJECTED);
                    break;
                default:
                    break;
            }
            TimeoffService.navigateConditionsFromUser = {
                inputSearchField : $scope.$parent.vm.user.username,
                requestKind : selectedRowData.kind,
                requestState: state
            };
            $location.path('/timeoff/requests');
        }

        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget === vm.timeoffRequestsUserGrid) {
                if (KendoUtilsService.gridPreferences['timeoffRequestsUserGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['timeoffRequestsUserGrid'];
                    vm.timeoffRequestsUserGrid.dataSource.query(requestOptions);
                } else {
                    vm.timeoffRequestsUserGrid.dataSource.read();
                }
            }
        });

        vm.openSettingHolidaysUserModal = function() {
            ModalService.openModal({
                templateUrl: './ng1/components/user/user-holidays-modal.html',
                controller: 'UserHolidaysModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolveParams : {
                    summaryHolidays: vm.summaryHolidays,
                    userId: vm.userId,
                    currentPeriod: vm.currentPeriod
                },
                accept: function(periodHolidaysUpdated) {
                    TimeoffService.saveHolidaysUser(periodHolidaysUpdated).then(function () {
                        var message = $translate.instant('global.messages.changesUpdated.success');
                        NotificationService.notifySuccess(message, 1000);
                        getDataSummaryUserHolidays().then(function() {
                            setDataGraphUserHolidays();
                            updateChartsHolidays();
                        });
                    });
                }
            });
        };

        vm.openSettingAbsencesUserModal = function() {
            ModalService.openModal({
                templateUrl: './ng1/components/user/user-absences-modal.html',
                controller: 'UserAbsencesModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolveParams : {
                    summaryHolidays: vm.summaryHolidays,
                    userId: vm.userId,
                    currentPeriod: vm.currentPeriod
                },
                accept: function(periodAbsencesUpdated) {
                    TimeoffService.saveAbsencesUser(periodAbsencesUpdated).then(function () {
                        var message = $translate.instant('global.messages.changesUpdated.success');
                        NotificationService.notifySuccess(message, 1000);
                        getDataSummaryUserAbsences().then(function() {
                            setDataGraphUserAbsences();
                            updateChartsAbsences();
                        });
                    });
                }
            });
        };

        vm.getRequestedTime = function (request) {
            return TimeoffService.getRequestedTime(request, true);
        };

        vm.setViewportHolidays = function(option) {
            vm.optionViewPortHolidays = option;
        };
        vm.setViewportAbsences = function(option) {
            vm.optionViewPortAbsences = option;
        };

        vm.navigateToRequests = function() {
            TimeoffService.navigateConditionsFromUser = {
                inputSearchField: $scope.$parent.vm.user.username,
                requestKind: TIMEOFF_KIND_ALL,
                requestState: TIMEOFF_REQUEST_STATUS.PENDING
            };
            $location.path('/timeoff/requests');
        };

        vm.getMoreRequests = function() {
            requestsToShow += vm.remainingRequests;
            reloadGrid( {size: requestsToShow} );
        };

        vm.navigateToEditUser = function() {
            $scope.$parent.vm.loadTabContent(1);
            $timeout(function() {
                $rootScope.navigateToAnchor('company-entry-date-picker');
            },500);
        }

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.timeoffRequestsUserGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
            });
        }

        function getPeriodSummary() {
            var currentStartYearPeriod = new Date(vm.summaryHolidays.startBearDate).getFullYear();
            var currentEndYearPeriod = new Date(vm.summaryHolidays.endBearDate).getFullYear();

            if(currentStartYearPeriod === currentEndYearPeriod) {
                vm.currentPeriod = currentEndYearPeriod;
                vm.lastPeriod = parseInt(vm.currentPeriod) - 1;
            } else {
                vm.currentPeriod = currentStartYearPeriod + '-' + currentEndYearPeriod;
                vm.lastPeriod = (currentStartYearPeriod-1) + '-' + (currentEndYearPeriod-1);
            }
        }

        function initChartsHolidays() {
            vm.chartOpsHolidaysBalance = TimeoffService.donutsChartCompleteFiller(datasetHolidaysBalance);
            vm.chartOpsHolidaysAvailable = TimeoffService.donutsChartPartialFiller(datasetHolidaysAvailable);
            vm.chartOpsHolidaysEarned = TimeoffService.donutsChartPartialFillerTwoSeries(datasetHolidaysEarnedSerieOne,datasetHolidaysEarnedSerieTwo);
            vm.chartOpsHolidaysActivity = TimeoffService.donutsChartCompleteFiller(datasetHolidaysActivity);
        }

        function initChartsAbsences() {
            vm.chartOpsAbsencesConsumed = TimeoffService.donutsChartCompleteFiller(datasetAbsencesConsumed);
            vm.chartOpsAbsencesRemunerable = TimeoffService.donutsChartCompleteFiller(datasetAbsencesRemunerable);
            if(vm.summaryAbsences.personalDays) {
                vm.chartOpsFreeDisposal = TimeoffService.donutsChartPartialFiller(datasetFreeDisposal);
                vm.chartOpsFreeDisposalBalance = TimeoffService.donutsChartCompleteFiller(datasetFreeDisposalBalance)
            }
            vm.chartOpsAbsencesTipology = TimeoffService.doughnutsChart(datasetAbsencesTipology);
        }

        function updateChartsHolidays() {
            var chart = $('#kendo-balance-chart').data('kendoChart');
            chart.options.series[0].data = datasetHolidaysBalance;
            chart.refresh();
            chart = $('#kendo-availableDays-chart').data('kendoChart');
            chart.options.series[0].data = datasetHolidaysAvailable;
            chart.refresh();
        }

        function updateChartsAbsences() {
            var chart = $('#kendo-disposal-balance-chart').data('kendoChart');
            chart.options.series[0].data = datasetFreeDisposalBalance;
            chart.refresh();
            chart = $('#kendo-freeDisposal-chart').data('kendoChart');
            chart.options.series[0].data = datasetFreeDisposal;
            chart.refresh();
        }

        function getDataSummaryUserHolidays() {
            return TimeoffService.getUserSummaryHolidays(vm.userId).then(function(response) {
                vm.summaryHolidays = response.data;
                return $q.resolve();
            });
        }

        function getDataSummaryUserAbsences() {
            return TimeoffService.getUserSummaryAbsences(vm.userId).then(function(response) {
                vm.summaryAbsences = response.data;
                return $q.resolve();
            });
        }

        var init = function() {
            if (vm.userId) {
                getDataSummaryUserHolidays().then(function() {
                    getPeriodSummary();
                    setDataGraphUserHolidays();
                    initChartsHolidays();
                });
                getDataSummaryUserAbsences().then(function() {
                    setDataGraphUserAbsences();
                    initChartsAbsences();
                    vm.chartsIsLoading = false;
                });
            }
        };

        init();
    }
]);
