'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:fromNowFilter
 * @function
 * @description
 * # fromNowFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('fromNowFilter', ['$translate', function ($translate) {
    return function (date, noShowFrom) {
        var contentDate = moment(date);
        var currentDate = moment();
        if (currentDate.diff(contentDate, 'minutes', true) < 1) {
            return $translate.instant('message.preview.now');
        } else if (Math.round(currentDate.diff(contentDate, 'days', true)) === 1) {
            return $translate.instant('message.preview.yesterday');
        } else {
            if (noShowFrom){
                return moment(contentDate).fromNow(true)
            } else {
                var fromNow = moment(contentDate).fromNow()
                return fromNow[0].toUpperCase() + fromNow.slice(1);
            }           
        }
    };
}]);
