'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.WKAuthenticationService
 * @description
 * # WKAuthenticationService
 */
angular.module('dialengaExeviBackoffice').service('WKAuthenticationService',
    ['$http', '$q', 'Session', 'SharedDataService', function ($http, $q, Session, SharedDataService) {

    this.promptWKLoginForm = function (clientId) {
        var height = 700;
        var width = 550;
        var top = (window.screen.availTop + (window.screen.availHeight / 2)) - (height / 2);
        top = top > 0 ? top : 0;
        var left = (window.screen.availLeft + (window.screen.availWidth / 2)) - (width / 2);
        left = left > 0 ? left : 0;

        var options = 'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left;

        var url = location.origin + '/wkauth-success';
        url = encodeURIComponent(url);

        var wkExternalLoginUrl = 'https://login.wolterskluwercloud.com/auth/core/connect/authorize?client_id=' + clientId +
            '&response_type=code+id_token&redirect_uri=' + url +
            '&scope=WK.ES.A3EquipoContex+IDInfo+openid+offline_access&state=state&nonce=0925b077-d730-41c9-a550-39b8ad5bbd28';

        window.open(wkExternalLoginUrl, 'newwindow', options);
    };

    this.getA3Configuration = function () {
        if (!Session.companyId) {
            return $q.reject();
        }
        return $http({
            url: SharedDataService.apiUrl + '/companies/' + Session.companyId + '/configuration/a3config',
            method: 'GET'
        });
    };

    this.acceptA3ConfigurationTerms = function () {
        if (!Session.companyId) {
            return $q.reject();
        }
        return $http({
            url: SharedDataService.apiUrl + '/companies/' + Session.companyId + '/configuration/a3config/acceptTerms',
            method: 'PUT'
        });
    };

    this.sendCodeToGetWKA3EquipoCompanies = function (wkauthCode) {
        return $http({
            url: SharedDataService.apiUrl + '/users/external/wk-a3equipo-companies',
            method: 'POST',
            data: {
                code: wkauthCode
            },
            ignoreErrorInterceptor: true
        });
    };

    this.synchronizeWKA3EquipoUsersInCompanies = function (companies) {
        return $http({
            url: SharedDataService.apiUrl + '/users/external/wk-a3equipo-sync/companies',
            method: 'POST',
            data: companies,
            ignoreErrorInterceptor: true
        });
    };

}]);