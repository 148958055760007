(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:languageSelector
     * @description
     * # languageSelector
     */
    angular.module('dialengaExeviBackoffice').component('languageSelector', {
        bindings: {
            selectedLanguage: '=',
            formContent: '=',
            validationFunction: '&?',
            answers: '=?'
        },
        controller: 'LanguageSelectorController',
        controllerAs: 'self',
        templateUrl: '/ng1/components/language-selector/language-selector.html'
    });
})();
