'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.errorInterceptor
 * @description
 * # errorInterceptor
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ErrorInterceptor',
    ['$injector', '$location', '$q', '$rootScope', '$translate', 'LocalizedErrorsService', 'NotificationService',
    function ($injector, $location, $q, $rootScope, $translate, LocalizedErrorsService, NotificationService) {

    this.request = function (config) {
        var accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + accessToken;
        }
        return config;
    };
    
    this.response = function (response) {
        return response || $q.when(response);
    };

    this.responseError = function(response) {
        $rootScope.$broadcast('dialenga:loadingEnd');
        if (!response.config || !response.config.ignoreErrorInterceptor) {
            if (response.status === 404) {
                $location.path('/not-found').replace();
            }
            else if (response.status === 403) {
                var message = $translate.instant('403.notification');
                NotificationService.notifyError(message);
            }
            else if (response.status === 422 && response.config && response.config.localizedErrorString) {
                LocalizedErrorsService.showLocalizedErrorNotification(response.data, response.config.localizedErrorString);
            }
            else if (response.status === 500) {
                $rootScope.lastFailedRequest = response;
                $location.path('/server-error').replace();
            }
            else if (response.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
            else {
                LocalizedErrorsService.showLocalizedError(response.status, response.data);
            }
        }
        return $q.reject(response);
    };

    this.retryRequest = function () {
        if ($rootScope.lastFailedRequest) {
            var $http = $injector.get('$http');
            return $http($rootScope.lastFailedRequest.config);
        }
    };
    
}]);
