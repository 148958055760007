'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayQuestionnaireQuestionController
 * @description
 * # FeedbackTrayQuestionnaireQuestionController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayQuestionnaireQuestionController',
    ['$anchorScroll', '$scope', '$rootScope', '$routeParams', '$location', '$timeout', '$translate', 'KendoUtilsService', 'LanguageService', 'ModalService', 'ArrayUtilsService', 'NotificationService',
    'AttachedContentService', 'PublicationService', 'FeedbackTraysService', 'UrlUtilsService', 'LocationService', 'CONTENT_KIND', 'TRAY_QUESTION_KIND', 'QUESTIONNAIRE_KIND', 'RELATED_ENTITY',
    function ($anchorScroll, $scope, $rootScope, $routeParams, $location, $timeout, $translate, KendoUtilsService, LanguageService, ModalService, ArrayUtilsService, NotificationService,
        AttachedContentService, PublicationService, FeedbackTraysService, UrlUtilsService, LocationService, CONTENT_KIND, TRAY_QUESTION_KIND, QUESTIONNAIRE_KIND, RELATED_ENTITY) {

    //******************************************* Attributes ************************************\\
    $scope.isValidURL = false;
    $scope.isValidURLYoutube = false;
    $scope.titleView = '';
    $scope.questionKindDescription = '';
    $scope.feedbackTrayId = $routeParams.id;
    $scope.questionId = $routeParams.questionId;
    $scope.changed = false;
    $scope.valid = false;
    $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;
    $scope.MULTIPLE_KIND = { LIMITLESS: 1, EXACT: 2, RANGE: 3 };
    $scope.questionForm = {
        question: {
            contents: {},
            answers: [],
            multipleOptions: {
                multiple: false,
                multipleSelectionKind: $scope.MULTIPLE_KIND.LIMITLESS,
                minimum: null,
                maximum: null
            },
            kind: FeedbackTraysService.newContentKind ? FeedbackTraysService.newContentKind : TRAY_QUESTION_KIND.SINGLE_CHOICE,
            weightEnabled: false,
            allowAddingMessage: false,
            allowNewRequestKind: false,
            defaultEnding: false,
            autoCloseRequest: false,
            phone: {
                phoneNumber: {
                    countryCode: null
                }
            }
        },
        images: [],
        resources: [],
        backToRequests: true,
        isDisabledDefaultEnding: true,
        relatedEntitySelected : null,
        relatedEntityHasNewItems: false,
        relatedEntityHasObsoleteItems: false,
    };

    $scope.toggleInput = {
        isDescriptionEnabled: false,
        isImageGroupEnabled: false,
        isImageVisible: true,
        isOptionalImageVisible: false,
        phoneCallOptionEnabled: false
    };
    $scope.questions = [];
    $scope.relatedEntities = [
        {id: RELATED_ENTITY.COMPANY, name: $translate.instant('users.company')},
        {id: RELATED_ENTITY.WORKPLACE, name: $translate.instant('users.workplace')},
        {id: RELATED_ENTITY.DEPARTMENT, name: $translate.instant('users.department')},
        {id: RELATED_ENTITY.TEAM, name: $translate.instant('users.team')}
    ];
    $scope.showRelatedEntityAdvice = true;
    $scope.isSyncingRelatedEntity = false;

    $scope.previewState = { opened: false };
    $scope.closeRequestKnowMore = false;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.TRAY_QUESTION_KIND = TRAY_QUESTION_KIND;
    FeedbackTraysService.multipleAnswersTemp = {};

    //******************************************** Methods **************************************\\
    function resetAnswersWeights(answers) {
        for (var index = 0; index < answers.length; index++) {
            answers[index].weight = null;
        }
    }
    
    $scope.isPreviewVisibleFn = function(visibility) {
        return $scope.isPreviewVisible = visibility;
    }

    $scope.showCloseRequestKnowMore = function() {
        $scope.closeRequestKnowMore = true;
    }

    $scope.clearFieldValueInAllLanguages = function (fieldName) {
        var languages = Object.keys($scope.questionForm.question.contents);
        for (var index = 0; index < languages.length; index++) {
            if ($scope.questionForm.question.contents[languages[index]].hasOwnProperty(fieldName)) {
                $scope.questionForm.question.contents[languages[index]][fieldName] = '';
            }
        }
    };

    $scope.$watch('questionForm.question.answers', function (newValue, oldValue) {
        if (newValue && oldValue && JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue.length !== oldValue.length) {
            var newMaxValue = newValue.length;
            if ($scope.questionForm.question.multipleOptions.minimum > newMaxValue) {
                $scope.questionForm.question.multipleOptions.minimum = newMaxValue;
            }
            if ($scope.questionForm.question.multipleOptions.maximum > newMaxValue) {
                $scope.questionForm.question.multipleOptions.maximum = newMaxValue;
            }
        }
    }, true);

    function parseImageAndVideo() {
        if (!$scope.toggleInput.isImageGroupEnabled) {
            $scope.questionForm.images = [];
            AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.questionForm.images, $scope.questionForm.question.contents, $scope.selectedLanguage);
            $scope.clearFieldValueInAllLanguages('imageURL');
            $scope.clearFieldValueInAllLanguages('youtubeURL');
        } else {
            if ($scope.toggleInput.isImageVisible) {
                // Discard video if image tab is selected
                $scope.clearFieldValueInAllLanguages('youtubeURL');
            } else if (!$scope.toggleInput.isOptionalImageVisible) {
                // Discard image if video tab is selected and alt image toggle is disabled
                $scope.questionForm.images = [];
                $scope.clearFieldValueInAllLanguages('imageURL');
            }
        }
    }

    function deleteEmptyPhoneLabels() {
        if(!$scope.questionForm.question.phone.contents) {
            return
        }
        var keys = Object.keys($scope.questionForm.question.phone.contents);
        for (var index = 0; index < keys.length; index++) {
            var language = keys[index];
            var languageContent = $scope.questionForm.question.phone.contents[language];
            if (!ArrayUtilsService.objectHasAnyValue(languageContent)) {
                // If language is empty, delete it
                delete $scope.questionForm.question.phone.contents[language];
            }
        }
    }

    $scope.questionFormSave = function () {
        var isFormDirty = dataHasChanged();
        if (isFormDirty) {
            if ($scope.questionForm.question.kind === TRAY_QUESTION_KIND.SINGLE_CHOICE
                && $scope.questionForm.question.weightEnabled === false) {
                resetAnswersWeights($scope.questionForm.question.answers);
            }
            if ($scope.questionForm.question.kind === TRAY_QUESTION_KIND.REDIRECT) {
                 if ($scope.questionForm.question.activateOther) {
                    $scope.questionForm.question.otherOptionLinkedFieldId = $scope.questionForm.question.answers[$scope.questionForm.question.answers.length - 1].linkedField;
                 } else {
                    $scope.questionForm.question.otherOptionLinkedFieldId = null;
                 }
            }
            LanguageService.removeEmptyLanguages($scope.questionForm.question);
            deleteEmptyPhoneLabels();
            $scope.questionForm.question.linkedField = !$scope.questionForm.question.isLinkActive ? null : $scope.questionForm.question.linkedField;
            // parseImageAndVideo();
            if (!$scope.toggleInput.phoneCallOptionEnabled) {
                $scope.questionForm.question.phone = null;
            }
            var promise;
            if ($scope.questionForm.question.id) {
                promise = FeedbackTraysService.updateQuestion($scope.questionForm, $scope.feedbackTrayId);
            } else {
                promise = FeedbackTraysService.createQuestion($scope.questionForm, $scope.feedbackTrayId);
            }
            promise.then(function () {
                $location.path('/settings/conversations/feedback-trays/questionnaire/update/' + $scope.feedbackTrayId);
            }, formSubmitErrorHandler);
        } else {
            $location.path('/settings/conversations/feedback-trays/questionnaire/update/' + $scope.feedbackTrayId);
        }
    };

    function formSubmitErrorHandler() {
        $rootScope.$broadcast('dialenga:loadingEnd');
    }

    function isCurrentLanguageQuestionValid(content) {
        return typeof content !== 'undefined'
            && (content.text || content.commentText)
            && UrlUtilsService.isValidExternalURL(content.externalURL)
            && UrlUtilsService.isValidExternalURL(content.youtubeURL);
    }

    $scope.validateModel = function () {
        let isPhoneActionValid = !$scope.toggleInput.phoneCallOptionEnabled ||
                                 ($scope.toggleInput.phoneCallOptionEnabled && 
                                 (!!$scope.questionForm.question.phone.phoneNumber.countryCode && !!$scope.questionForm.question.phone.phoneNumber.countryCode.code) && 
                                 (!!$scope.questionForm.question.phone.phoneNumber.phoneNumber));
        $scope.valid = isPhoneActionValid && LanguageService.validateQuestionnaireNonEmptyLanguages($scope.questionForm.question, isCurrentLanguageQuestionValid);
    };

    $scope.onChange = function () {
        $scope.changed = true;
    };

    function resetMultipleOptions() {
        // If multiple selection is turned off, multipleSelectionKind sent to server should be EXACT
        $scope.questionForm.question.multipleOptions.multipleSelectionKind = $scope.MULTIPLE_KIND.EXACT;
        $scope.questionForm.question.multipleOptions.minimum = 1;
        $scope.questionForm.question.multipleOptions.maximum = 1;
    }

    function deselectActiveJumpItems() {
        var activeJumpItems = document.querySelectorAll('.icon-jump.answer__icon--active');
        for (var index = 0; index < activeJumpItems.length; index++) {
            activeJumpItems[index].click();
        }
    }

    function promptClearActiveLinksConfirmation() {
        ModalService.openConfirmationModal({
            title: 'global.modal.error.title',
            message: 'questionnaire.question.multipleSelection.removeLinksConfirmation',
            acceptButtonText: 'global.form.continue',
            accept: function () {
                deselectActiveJumpItems();
            },
            cancel: function () {
                $scope.questionForm.question.multipleOptions.multiple = false;
                resetMultipleOptions();
            }
        });
    }

    $scope.onMultipleSelectionChange = function () {
        if (!$scope.questionForm.question.multipleOptions.multiple) {
            resetMultipleOptions();
        } else {
            $scope.questionForm.question.multipleOptions.multipleSelectionKind = $scope.MULTIPLE_KIND.LIMITLESS;
            var linkedAnswers = $scope.questionForm.question.answers.filter(function (answer) {
                return answer.isLinkActive && !!answer.linkedField;
            });
            if (linkedAnswers.length > 0) {
                promptClearActiveLinksConfirmation();
            } else {
                deselectActiveJumpItems();
            }
        }
        $scope.onChange();
    };

    $scope.selectMultipleKind = function (kind) {
        if (kind === $scope.MULTIPLE_KIND.LIMITLESS) {
            $scope.questionForm.question.multipleOptions.minimum = null;
            $scope.questionForm.question.multipleOptions.maximum = null;
        } else if (kind === $scope.MULTIPLE_KIND.EXACT) {
            $scope.questionForm.question.multipleOptions.minimum = 1;
            $scope.questionForm.question.multipleOptions.maximum = 1;
        } else if (kind === $scope.MULTIPLE_KIND.RANGE) {
            $scope.questionForm.question.multipleOptions.minimum = 1;
            $scope.questionForm.question.multipleOptions.maximum = $scope.questionForm.question.answers.length;
        }
        $scope.questionForm.question.multipleOptions.multipleSelectionKind = kind;
    };

    function dataHasChanged() {
        return !angular.equals($scope.questionForm.question, FeedbackTraysService.latestQuestion) || !angular.equals($scope.toggleInput, FeedbackTraysService.toggleInput);
    }

    $scope.goBack = function() {
        var isFormDirty = dataHasChanged();
        $rootScope.goBackAndCheckForModifications(isFormDirty, '/settings/conversations/feedback-trays/questionnaire/update/' + $routeParams.id);
    };

    $scope.testUrlLinks = function () {
        $scope.isValidURL = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'externalURL');
        $scope.isValidURLYoutube = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'youtubeURL');
    };

    $scope.addAnswerOption = function (avoidValidation) {
        var index = $scope.questionForm.question.answers.length + 1;
        var localizedContent = {};
        localizedContent[$scope.selectedLanguage] = { text: '' };
        $scope.questionForm.question.answers.push({
            contents: localizedContent,
            isLinkActive: false,
            linkedField: null,
            order: index
        });

        if (!avoidValidation) {
            $scope.validateModel();
        }
    };

    var initAnswersLinks = function (answers) {
        for (var index = 0; index < answers.length; index++) {
            answers[index].isLinkActive = !!answers[index].linkedField;
        }
    };

    function getQuestionOrder(questionnaire) {
        return FeedbackTraysService.newContentOrder ? FeedbackTraysService.newContentOrder : questionnaire.fields.length + 1;
    }

    function initLinkDropDownList(questionnaire) {

        questionnaire.fields = questionnaire.fields.sort( function (a, b) { return a.order - b.order; } );
        questionnaire.endings = questionnaire.endings.sort( function (a, b) { return ((a.defaultEnding === b.defaultEnding) ? 0 : a.defaultEnding ? -1 : 1) || (a.order - b.order); } );

        var questionnaireItems = questionnaire.fields.concat(questionnaire.endings);
        var questionnaireItemTitle;
        for (var index = 0, endingOrder = 1; index < questionnaireItems.length; index++) {
            // Add questions and endings to available items to the link list, except the current one
            if (!$routeParams.questionId || $routeParams.questionId != questionnaireItems[index].id) {
                // If current language is not available in a questionnaire item,
                // get the language that best suits the user
                if (!questionnaireItems[index].contents[$scope.selectedLanguage]) {
                    var language = LanguageService.getMainContentLanguage(questionnaireItems[index].contents);
                    questionnaireItemTitle = FeedbackTraysService.getTitleWithoutFormat(questionnaireItems[index].contents[language].text);
                } else {
                    questionnaireItemTitle = FeedbackTraysService.getTitleWithoutFormat(questionnaireItems[index].contents[$scope.selectedLanguage].text);
                }
                $scope.questions.push({
                    id: questionnaireItems[index].id,
                    title: questionnaireItemTitle,
                    kind: questionnaireItems[index].kind,
                    order: questionnaireItems[index].kind !== $scope.TRAY_QUESTION_KIND.ENDING ? questionnaireItems[index].order : endingOrder++
                });
            }
        }
    }

    function setRelatedEntityDefaultText() {
        var entity = $scope.relatedEntities.find(function(entity) {
            return entity.id === $scope.questionForm.question.relatedEntityType
        });
        $scope.questionForm.question.contents[$scope.selectedLanguage] = {
            text: $translate.instant('feedbackTray.questionnaire.relatedEntity.defaultText', {entity: entity.name.toLowerCase()})
        };
        $scope.validateModel();
    }

    function initAnswersForRelatedEntity() {
        var apiUrlEntity = '';
        switch ($scope.questionForm.question.relatedEntityType) {
            case RELATED_ENTITY.COMPANY:
                apiUrlEntity = '/subsidiary-companies';
                break;
            case RELATED_ENTITY.WORKPLACE:
                apiUrlEntity = '/headquarters';
                break;
            case RELATED_ENTITY.DEPARTMENT:
                apiUrlEntity = '/departments';
                break;
            case RELATED_ENTITY.TEAM:
                apiUrlEntity = '/timeoff/teams';
                break;
            default:
                return;
        }
        $scope.questionForm.question.answers = [];
        $scope.questionForm.question.activateOther = false;

        FeedbackTraysService.getRelatedEntityItems(apiUrlEntity).then(function(response) {
            $scope.isSyncingRelatedEntity = false;
            document.getElementsByClassName('k-icon k-i-arrow-60-down')[0].classList.remove('k-i-loading');

            var relatedEntityItems = response.data.content;
            angular.forEach(relatedEntityItems, function(item) {
                $scope.questionForm.question.answers.push({
                    id: null,
                    relatedEntityId: item.id,
                    name: item.name,
                    linkedField: null,
                    newOption: null
                })
            });
            if(!!relatedEntityItems) {
                setRelatedEntityDefaultText();
            }
        });
    }

    function atLeastOneAnswerHasLink() {
        if (!$scope.questionForm.question.answers || $scope.questionForm.question.answers.length === 0) { return false }
        var firstAnswerLinkedIndex = $scope.questionForm.question.answers.findIndex(function(answer) {
            return !!answer.linkedField
        });
        return firstAnswerLinkedIndex !== -1;
    }

    function scrollToHashIfPresent() {
        var pathHash = $location.hash();
        if (pathHash) {
            $timeout(function () {
                $location.hash(pathHash);
                $anchorScroll();
            });
        }
    }

    $scope.getMinimumInputMaxValue = function () {
        return $scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.RANGE && $scope.questionForm.question.multipleOptions.maximum ?
            $scope.questionForm.question.multipleOptions.maximum : $scope.questionForm.question.answers.length;
    };

    $scope.decreaseValue = function (aliasName) {
        $scope.questionForm.question.multipleOptions[aliasName] -= 1;
        FeedbackTraysService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        if ($scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.EXACT && aliasName === 'minimum') {
            $scope.decreaseValue('maximum');
        }
        $scope.onChange();
    };

    $scope.increaseValue = function (aliasName) {
        $scope.questionForm.question.multipleOptions[aliasName] += 1;
        FeedbackTraysService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        if ($scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.EXACT && aliasName === 'minimum') {
            $scope.increaseValue('maximum');
        }
        $scope.onChange();
    };

    $scope.isMinimumIncreaseDisabled = function () {
        return $scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.RANGE ?
            $scope.questionForm.question.multipleOptions.minimum >= $scope.questionForm.question.multipleOptions.maximum :
            $scope.questionForm.question.multipleOptions.minimum >= $scope.questionForm.question.answers.length;
    };

    function isNumeric(value) {
        return value !== null && typeof value !== 'undefined' && !isNaN(value);
    }

    function setQuestionKindDescription(kind) {
        switch (kind) {
            case TRAY_QUESTION_KIND.SINGLE_CHOICE:
                $scope.questionKindDescription = $translate.instant('questionnaire.question.questiontitleWithOptionsList');
                break;
            case TRAY_QUESTION_KIND.OPEN:
                $scope.questionKindDescription = $translate.instant('questionnaire.question.questiontitleWithFreeAnswer');
                break;
            case TRAY_QUESTION_KIND.UPLOAD_FILE:
                $scope.questionKindDescription = $translate.instant('feedbackTray.questionnaire.question.questionTitleWithImage');
                break;
            case TRAY_QUESTION_KIND.COMMENT:
                $scope.questionKindDescription = $translate.instant('feedbackTray.questionnaire.question.questionTitleWithComment');
                break;
            case TRAY_QUESTION_KIND.ENDING:
                $scope.questionKindDescription = $translate.instant('questionnaire.question.endingtitle');
                break;
            case TRAY_QUESTION_KIND.REDIRECT:
                $scope.questionKindDescription = $translate.instant('questionnaire.question.questionRedirect');
                break;
            default:
                break;
        }
    }

    $scope.onNumInputChange = function (aliasName) {
        if (isNumeric($scope.questionForm.question.multipleOptions[aliasName])) {
            FeedbackTraysService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        } else {
            $scope.questionForm.question.multipleOptions[aliasName] = !!FeedbackTraysService.multipleAnswersTemp[aliasName] ? FeedbackTraysService.multipleAnswersTemp[aliasName] : 1;
        }
        $scope.onChange();
    };

    $scope.onChangeRelatedEntity = function () {

        if(!!$scope.questionForm.relatedEntitySelected && $scope.questionForm.relatedEntitySelected !== $scope.questionForm.question.relatedEntityType) {
            if (atLeastOneAnswerHasLink()) {

                var entity = $scope.relatedEntities.find(function(item) {
                    return item.id === $scope.questionForm.question.relatedEntityType;
                })
                ModalService.openConfirmationModal({
                    title: 'feedbackTray.questionnaire.relatedEntity.title',
                    message: 'feedbackTray.questionnaire.relatedEntity.modalChangeConfirmation',
                    messageData: { entity: entity.name },
                    focusCancel: true,
                    acceptButtonText: 'global.form.continue',
                    hideCancelButton: false,
                    accept: function () {
                        $scope.questionForm.question.relatedEntityType = $scope.questionForm.relatedEntitySelected;
                        $scope.questionForm.relatedEntityNameSelected = $scope.relatedEntities.find(function(entity) { 
                            return entity.id === $scope.questionForm.question.relatedEntityType;
                        }).name;
                        $scope.isSyncingRelatedEntity = true;
                        document.getElementsByClassName('k-icon k-i-arrow-60-down')[0].classList.add('k-i-loading');
                        $scope.questionForm.relatedEntityHasNewItems = false;
                        $scope.questionForm.relatedEntityHasObsoleteItems = false;
                        initAnswersForRelatedEntity();
                    },
                    cancel: function() {
                        $scope.questionForm.relatedEntitySelected = $scope.questionForm.question.relatedEntityType;
                    }
                });

            } else {
                $scope.questionForm.question.relatedEntityType = $scope.questionForm.relatedEntitySelected;
                $scope.questionForm.relatedEntityNameSelected = $scope.relatedEntities.find(function(entity) { 
                    return entity.id === $scope.questionForm.question.relatedEntityType;
                }).name;
                $scope.isSyncingRelatedEntity = true;
                document.getElementsByClassName('k-icon k-i-arrow-60-down')[0].classList.add('k-i-loading');
                $scope.questionForm.relatedEntityHasNewItems = false;
                $scope.questionForm.relatedEntityHasObsoleteItems = false;
                initAnswersForRelatedEntity();
            }
        }
    };

    $scope.onActivateOtherChange = function() {
        if ($scope.questionForm.question.activateOther) {
            var gender = $scope.questionForm.question.relatedEntityType === RELATED_ENTITY.COMPANY ? 'female' : 'male';
            $scope.questionForm.question.answers.push({
                id: null,
                relatedEntityId: null,
                name: $translate.instant('global.form.other', {gender: gender}, 'messageformat'),
                linkedField: null,
                newOption: null
            })
        } else {
            // Remove option 'Other'
            $scope.questionForm.question.answers.splice(-1);
        }
    }

    $scope.removeObsoleteOption = function(index) {
        $scope.questionForm.question.answers.splice(index, 1);
    }

    $scope.goBackToTray = function() {
        $location.path('/settings/conversations/feedback-trays/questionnaire/update/' + $routeParams.id);
    }

    function initializeQuestionnaireFromTray() {
        FeedbackTraysService.findQuestionnaire($scope.feedbackTrayId).then(function (response){

            initLinkDropDownList(response.data);
            $scope.userCountryCodesOptions = LocationService.getCountryCodesOptions({code: 34});

            if ($scope.questions.length > 0) {
                $scope.linkedQuestionsOps = FeedbackTraysService.getLinkedQuestionsDropDownListOptions($scope.questions);
            }
            $scope.relatedEntityOps = FeedbackTraysService.getRelatedEntityDropDownListOptions($scope.relatedEntities);

            if ($routeParams.questionId) {
                FeedbackTraysService.findOneQuestion($scope.feedbackTrayId, $routeParams.questionId).then(function (questionResponse) {
                    $scope.questionForm.question = questionResponse.data;
                    // Establish for redirect element, if have new items, obsolete items and obtain entity name for advice texts
                    if ($scope.questionForm.question.kind == TRAY_QUESTION_KIND.REDIRECT) {
                        $scope.questionForm.relatedEntitySelected = questionResponse.data.relatedEntityType;

                        var newItemIndex = $scope.questionForm.question.answers.findIndex(function(answer) {
                            return answer.newOption;
                        });
                        $scope.questionForm.relatedEntityHasNewItems = newItemIndex !== -1;

                        var obsoleteIndex = $scope.questionForm.question.answers.findIndex(function(answer) {
                            return answer.obsolete;
                        });
                        $scope.questionForm.relatedEntityHasObsoleteItems = obsoleteIndex !== -1;

                        $scope.questionForm.relatedEntityNameSelected = $scope.relatedEntities.find(function(entity) { 
                            return entity.id === questionResponse.data.relatedEntityType
                        }).name;
                    }
                    if (!$scope.questionForm.question.phone) {
                        $scope.questionForm.question.phone = { phoneNumber: {countryCode: null}};
                    }
                    $scope.titleView = $translate.instant(
                        $scope.questionForm.question.kind == TRAY_QUESTION_KIND.COMMENT || 
                        $scope.questionForm.question.kind == TRAY_QUESTION_KIND.ENDING ||
                        $scope.questionForm.question.kind == TRAY_QUESTION_KIND.REDIRECT ?
                        'feedbackTray.questionnaire.element.editElement' : 'feedbackTray.questionnaire.question.editQuestion'
                    );
                    setQuestionKindDescription($scope.questionForm.question.kind);
                    var questionContent = questionResponse.data.contents[$scope.selectedLanguage];
                    $scope.isAttachedResourceEnabled = !!(questionContent && questionContent.resourceURL);
                    $scope.toggleInput.phoneCallOptionEnabled = !!$scope.questionForm.question.phone && !!$scope.questionForm.question.phone.phoneNumber &&
                        !!$scope.questionForm.question.phone.phoneNumber.phoneNumber && !!$scope.questionForm.question.phone.phoneNumber.countryCode;

                    if (questionResponse.data.kind === TRAY_QUESTION_KIND.SINGLE_CHOICE) {
                        initAnswersLinks($scope.questionForm.question.answers);
                    }
                    $scope.questionForm.question.isLinkActive = !!$scope.questionForm.question.linkedField;
                    $scope.questionForm.isDisabledDefaultEnding = $scope.questionForm.question.defaultEnding;
                    FeedbackTraysService.latestQuestion = angular.copy($scope.questionForm.question);
                    // $scope.toggleInput.isImageGroupEnabled = !!questionContent && (!!questionContent.youtubeURL || !!questionContent.imageURL);
                    // $scope.toggleInput.isImageVisible = !(questionContent && questionContent.youtubeURL);
                    // $scope.toggleInput.isOptionalImageVisible = !$scope.toggleInput.isImageVisible && !!questionContent &&
                    //     !!(questionContent.imageURL || questionContent.imageOrder > -1);
                    FeedbackTraysService.toggleInput = angular.copy($scope.toggleInput);
                    $scope.testUrlLinks();
                    $scope.validateModel();
                    scrollToHashIfPresent();
                    if ($scope.questionForm.relatedEntityHasNewItems || $scope.questionForm.relatedEntityHasObsoleteItems) {
                        var message = $translate.instant('feedbackTray.questionnaire.relatedEntity.newItemsToast', {entity: $scope.questionForm.relatedEntityNameSelected});
                        NotificationService.notifyWarning(message, false, {delay: 5000});
                    }
                });

            } else {
                $scope.questionForm.question.kind = FeedbackTraysService.newContentKind ? FeedbackTraysService.newContentKind : TRAY_QUESTION_KIND.SINGLE_CHOICE;
                $scope.titleView = $translate.instant(
                    $scope.questionForm.question.kind == TRAY_QUESTION_KIND.COMMENT || $scope.questionForm.question.kind == TRAY_QUESTION_KIND.ENDING ?
                    'feedbackTray.questionnaire.element.addElement' : 'feedbackTray.questionnaire.question.addQuestion'
                );
                setQuestionKindDescription($scope.questionForm.question.kind);
                var newQuestionOrder = getQuestionOrder(response.data);
                $scope.questionForm.question.order = newQuestionOrder;
                for (var index = 1; index <= 2; index++) {
                    $scope.addAnswerOption(true);
                }
                $scope.questionForm.question.isLinkActive = false;
                $scope.questionForm.question.defaultEnding = !response.data.endings || response.data.endings.length === 0;
                $scope.questionForm.isDisabledDefaultEnding = $scope.questionForm.question.defaultEnding;

                FeedbackTraysService.latestQuestion = angular.copy($scope.questionForm.question);
            }
            KendoUtilsService.disableDropOnKendoEditor('kendoTextEditor');
        });
    }

    var init = function () {
        $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $scope.showRelatedEntityAdvice = !JSON.parse(localStorage.getItem('hideRelatedEntityAdvice')) === true;

        initializeQuestionnaireFromTray();
    };

    init();

    $scope.$on('$destroy', function() {
        $location.hash('');
        FeedbackTraysService.newContentKind = null;
        FeedbackTraysService.multipleAnswersTemp = {};
    });

}]);
