'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ContactInfoController
 * @description
 * # ContactInfoController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ContactInfoController',
    ['$scope','$rootScope','$translate', 'PublicationService', 'UrlUtilsService', 'ContactInfoService', 'KendoUtilsService', 'NotificationService',
    function ($scope, $rootScope, $translate, PublicationService, UrlUtilsService, ContactInfoService, KendoUtilsService, NotificationService) {
        
        var vm = this;
        
        var previousFormContactInfo;
        var map;
        var backPath = '/global-settings';
        var emailPattern = new RegExp(/(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

        vm.changed = false;
        vm.isFormDirty = false;

        vm.testButtonTooltip = $translate.instant('message.urlTest');
        vm.showUrlLink = UrlUtilsService.showUrlLink;

        vm.companyData = { };
        
        vm.isValidURLWebsite = true;
        vm.isValidURLFacebook = true;
        vm.isValidURLYoutube = true;
        vm.isValidURLTwitter = true;
        vm.isValidURLLinkedin = true;
        vm.isValidURLInstagram = true;
        vm.isValidEmail = false;

        vm.textareaOptions = KendoUtilsService.getTextareaOptions();
        vm.textareaOptions.keyup = function (e) {
            var targetField = document.getElementById(e.sender.element[0].id).getAttribute('k-ng-model').split('.');
            switch (targetField[2]) {
                case 'address':
                    vm.companyData.address = this.value();
                    break;
                case 'businessHours':
                    vm.companyData.businessHours = this.value();
                    break;
                case 'undergrounds':
                    vm.companyData.undergrounds = this.value();
                    break;
                case 'buses':
                    vm.companyData.buses = this.value();
                    break;
                case 'trains':
                    vm.companyData.trains = this.value();
                    break;
                case 'parkings':
                    vm.companyData.parkings = this.value();
                    break;
                case 'electricSpots':
                    vm.companyData.electricSpots = this.value();
                    break;
                default:
                    break;
            }
            $scope.$apply(function () {
                vm.onChange();
            });
        };
        vm.textareaOptions.tools.splice(4,2);
        vm.textareaOptions.tools.splice(0,1);
        vm.textareaOptions.placeholder = $translate.instant('message.captionPlaceholder');
        

        // ************* Methods ***********************
        function testUrlLinks() {
            vm.onChangeUrl(['website','facebook','youtube','twitter','linkedin','instagram']);
        }

        function initializeStaticMap(latitude, longitude) {

            if (map !== undefined) {
                map.remove();
            }
            map = L.map('static-map', {
                center: [latitude || 40.74935754719958, longitude || -73.98619012926535],
                static: true,
                zoom: latitude !== null && longitude !== null ? 17 : 11,
                zoomControl: false
            });

            var basemap = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
            L.tileLayer(basemap, {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions" target="_blank">CARTO</a>',
                subdomains: 'abcd',
                tileSize: 256

            }).addTo(map);

            if (latitude && longitude) {
                var locationIcon = L.icon({
                    iconUrl: '/ng1/assets/img/location.svg',
                    iconSize: [64, 64],
                    iconAnchor: [32, 32]
                });

                L.marker([latitude || 40.74935754719958, longitude || -73.98619012926535], {icon: locationIcon}).addTo(map);
            }
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
            if (map.tap) {
                map.tap.disable();
            }
        }

        function validateEmail() {
            return emailPattern.test(vm.companyData.email) || vm.companyData.email === '';
        }

        function dataHasChanged() {
            return !angular.equals(previousFormContactInfo, vm.companyData);
        }

        function validateModel() {
            vm.valid = (vm.isValidURLWebsite || !vm.companyData.website) && (vm.isValidURLFacebook || !vm.companyData.facebook) && (vm.isValidURLYoutube || !vm.companyData.youtube)
                    && (vm.isValidURLTwitter || !vm.companyData.twitter) && (vm.isValidURLLinkedin || !vm.companyData.linkedin) && (vm.isValidURLInstagram || !vm.companyData.instagram)
                    && ( (!!vm.companyData.latitude && !!vm.companyData.longitude) || (!vm.companyData.latitude && !vm.companyData.longitude) ) && validateEmail();
            vm.isFormDirty = dataHasChanged();
        }

        vm.onChangeUrl = function(urlsToCheck) {
            var urlTested = {};
            angular.forEach(urlsToCheck, function(urlCheck, idx, urlsToCheck) {
                switch (urlCheck) {
                    case 'website':
                        urlTested = PublicationService.validateUrls(vm.companyData.website);
                        vm.isValidURLWebsite = urlTested.valid;
                        vm.companyData.website = urlTested.data;
                        break;
                    case 'facebook':
                        urlTested = PublicationService.validateUrls(vm.companyData.facebook);
                        vm.isValidURLFacebook = urlTested.valid;
                        vm.companyData.facebook = urlTested.data;
                        break;
                    case 'youtube':
                        urlTested = PublicationService.validateUrls(vm.companyData.youtube);
                        vm.isValidURLYoutube = urlTested.valid;
                        vm.companyData.youtube = urlTested.data;
                        break;
                    case 'twitter':
                        urlTested = PublicationService.validateUrls(vm.companyData.twitter);
                        vm.isValidURLTwitter = urlTested.valid;
                        vm.companyData.twitter = urlTested.data;
                        break;
                    case 'linkedin':
                        urlTested = PublicationService.validateUrls(vm.companyData.linkedin);
                        vm.isValidURLLinkedin = urlTested.valid;
                        vm.companyData.linkedin = urlTested.data;
                        break;
                    case 'instagram':
                        urlTested = PublicationService.validateUrls(vm.companyData.instagram);
                        vm.isValidURLInstagram = urlTested.valid;
                        vm.companyData.instagram = urlTested.data;
                        break;
                    default:
                        break;
                }
                if (urlsToCheck.length == 1) {
                    vm.onChange();
                }
            });
        };

        vm.onKeyPress = function(event) {
            var keycode = event.keyCode;
            if(keycode == '13'){
                event.preventDefault();
            }
        };

        vm.onChange = function() {
            vm.changed = true;
            validateModel()
        };

        vm.onChangeCoord = function(latitude, longitude) {
            if ((latitude !== null && longitude !== null) || (latitude == null && longitude == null)) {
                initializeStaticMap(latitude, longitude);
            }
            vm.onChange();
        };

        vm.saveFormContactInfo = function() {
            vm.isFormDirty = dataHasChanged();
            if (vm.isFormDirty) {
                ContactInfoService.updateContactInfo(vm.companyData).then(function(response) {
                    var message = $translate.instant('global.messages.changesUpdated.success');
                    NotificationService.notifySuccess(message, 1000);

                    previousFormContactInfo = angular.copy(vm.companyData);
                    vm.isFormDirty = dataHasChanged();
                    $rootScope.$broadcast('dialenga:loadingEnd');


                }, function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                    var message = $translate.instant('global.messages.error.unknown');
                    NotificationService.notifyError(message);
                });
            }
        };

        vm.goBack = function() {
            vm.isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(vm.isFormDirty, backPath);
        };

        $scope.$watch('vm.changed', function(newValue) {
            if (newValue) {
                validateModel();
                vm.changed = false;
            }
        });

        var init = function() {
            ContactInfoService.getCompanyContactInfo().then(function (response) {
                vm.companyData = response.data;
                vm.companyData.latitude = parseFloat(vm.companyData.latitude);
                vm.companyData.longitude = parseFloat(vm.companyData.longitude);

                previousFormContactInfo = angular.copy(vm.companyData);

                initializeStaticMap(vm.companyData.latitude, vm.companyData.longitude);
                testUrlLinks();
                validateModel();
            });
        }

        init();
    
    }
]);