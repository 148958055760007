'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnaireEndingController
 * @description
 * # QuestionnaireEndingController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnaireEndingController',
    ['$scope', '$rootScope', '$routeParams', '$location', '$timeout', 'QuestionnaireService', 'SegmentService', 'ModalService',
    'KendoUtilsService', 'LanguageService', 'AttachedContentService', 'PublicationService', 'UrlUtilsService', 'CONTENT_KIND', 'QUESTION_KIND', 'QUESTIONNAIRE_KIND',
    function ($scope, $rootScope, $routeParams, $location, $timeout, QuestionnaireService, SegmentService, ModalService,
        KendoUtilsService, LanguageService, AttachedContentService, PublicationService, UrlUtilsService, CONTENT_KIND, QUESTION_KIND, QUESTIONNAIRE_KIND) {

        //******************************************* Attributes ************************************\\
        $scope.isValidURL = false;
        $scope.isValidURLYoutube = false;
        $scope.questionnaireId = $routeParams.id;
        $scope.changed = false;
        $scope.valid = false;
        $scope.questionForm = {
            question: {
                contents: {},
                answers: [],
                kind: QUESTION_KIND.ENDING
            },
            images: [],
            resources: []
        };
        $scope.toggleInput = {
            isDescriptionEnabled: false,
            isImageGroupEnabled: false,
            isImageVisible: true,
            isOptionalImageVisible: false
        };
        $scope.previewState = { opened: false };
        $scope.CONTENT_KIND = CONTENT_KIND;
        $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;

        //******************************************** Methods **************************************\\
        $scope.clearFieldValueInAllLanguages = function (fieldName) {
            var languages = Object.keys($scope.questionForm.question.contents);
            for (var index = 0; index < languages.length; index++) {
                if ($scope.questionForm.question.contents[languages[index]].hasOwnProperty(fieldName)) {
                    $scope.questionForm.question.contents[languages[index]][fieldName] = '';
                }
            }
        };

        $scope.getReadabilityIndex = function() {
            $scope.readabilityIndex = null;
            return;
        };

        $scope.questionFormSave = function () {
            var isFormDirty = dataHasChanged();
            if (isFormDirty) {
                LanguageService.removeEmptyLanguages($scope.questionForm.question);
                if ($scope.questionForm.question.segment && $scope.questionForm.question.segment.id === null) {
                    $scope.questionForm.question.segment = null;
                }
                if (!$scope.toggleInput.isDescriptionEnabled) {
                    $scope.clearFieldValueInAllLanguages('text');
                }
                if (!$scope.toggleInput.isImageGroupEnabled) {
                    $scope.questionForm.images = [];
                    AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.questionForm.images, $scope.questionForm.question.contents, $scope.selectedLanguage);
                    $scope.clearFieldValueInAllLanguages('imageURL');
                    $scope.clearFieldValueInAllLanguages('youtubeURL');
                } else {
                    if ($scope.toggleInput.isImageVisible) {
                        // Discard video if image tab is selected
                        $scope.clearFieldValueInAllLanguages('youtubeURL');
                    } else if (!$scope.toggleInput.isOptionalImageVisible) {
                        // Discard image if video tab is selected and alt image toggle is disabled
                        $scope.questionForm.images = [];
                        $scope.clearFieldValueInAllLanguages('imageURL');
                    }
                }
                var promise;
                if ($scope.questionForm.question.id) {
                    promise = QuestionnaireService.updateQuestion($scope.questionForm, $scope.questionnaireId);
                } else {
                    promise = QuestionnaireService.createQuestion($scope.questionForm, $scope.questionnaireId);
                }
                promise.then(function () {
                    $location.path('/questionnaires/edit/' + $scope.questionnaireId);
                }, formSubmitErrorHandler);
            } else {
                $location.path('/questionnaires/edit/' + $scope.questionnaireId);
            }
        };

        function formSubmitErrorHandler() {
            $rootScope.$broadcast('dialenga:loadingEnd');
        }

        function isCurrentLanguageQuestionValid(content) {
            return typeof content !== 'undefined'
                && content.title
                && UrlUtilsService.isValidExternalURL(content.externalURL)
                && UrlUtilsService.isValidExternalURL(content.youtubeURL);
        }

        $scope.validateModel = function () {
            $scope.valid = LanguageService.validatePublicationNonEmptyLanguages($scope.questionForm.question, isCurrentLanguageQuestionValid);
        };

        $scope.onChange = function () {
            $scope.changed = true;
        };

        $scope.onSegmentChange = function () {
            // On content creation, the segment drop down list is initialized with a default value (first item) after
            // the QuestionnaireService.latestQuestion variable is assigned a value, so we need to manually set the segment.
            if (!QuestionnaireService.latestQuestion.segment && $scope.questionForm.question.segment) {
                QuestionnaireService.latestQuestion.segment = angular.copy($scope.questionForm.question.segment);
            }
            $scope.onChange();
        };

        function dataHasChanged() {
            return !angular.equals($scope.questionForm.question, QuestionnaireService.latestQuestion) || !angular.equals($scope.toggleInput, QuestionnaireService.toggleInput);
        }

        $scope.goBack = function () {
            var isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(isFormDirty, '/questionnaires/edit/' + $routeParams.id);
        };

        $scope.addSegment = function () {
            ModalService.openSegmentModal({
                accept: function () {
                    $scope.segmentsOptions.dataSource.read();
                    // When read event ends, segmentChangeCallback function is called
                    $rootScope.$broadcast('dialenga:loadingEnd');
                },
                cancel: function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        };

        $scope.testUrlLinks = function () {
            $scope.isValidURL = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'externalURL');
            $scope.isValidURLYoutube = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'youtubeURL');
        };

        function segmentChangeCallback() {
            // Change event called when dropdownlist read event has successfully finished
            // Set created segment from modal as selected item in dropdownlist
            $timeout(function () {
                if (SegmentService.latestSegment) {
                    $scope.questionForm.question.segment = SegmentService.latestSegment;
                    delete SegmentService.latestSegment;
                }
            }, 100);
        }

        function getEndingOrder(questionnaire) {
            var questionnaireItems = questionnaire.questions.concat(questionnaire.endings);
            return questionnaireItems.length + 1;
        }

        var init = function () {
            $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
            $scope.segmentsOptions = SegmentService.getSegmentsDropDownListOptions(segmentChangeCallback);
            QuestionnaireService.findOne($scope.questionnaireId).then(function (response) {
                $scope.questionnaireName = response.data.name;
                $scope.questionnaireKind = response.data.kind;
                $scope.isAnonymous = response.data.anonymous;
                var newQuestionOrder = getEndingOrder(response.data);
                if ($routeParams.questionId) {
                    QuestionnaireService.findOneQuestion($scope.questionnaireId, $routeParams.questionId).then(function (questionResponse) {
                        $scope.questionForm.question = questionResponse.data;
                        var questionContent = questionResponse.data.contents[$scope.selectedLanguage];
                        $scope.isAttachedResourceEnabled = !!(questionContent && questionContent.resourceURL);

                        QuestionnaireService.latestQuestion = angular.copy($scope.questionForm.question);

                        $scope.toggleInput.isDescriptionEnabled = !!(questionContent && questionContent.text);
                        $scope.toggleInput.isImageGroupEnabled = !!questionContent && (!!questionContent.youtubeURL || !!questionContent.imageURL);
                        $scope.toggleInput.isImageVisible = !(questionContent && questionContent.youtubeURL);
                        $scope.toggleInput.isOptionalImageVisible = !$scope.toggleInput.isImageVisible && !!questionContent &&
                            !!(questionContent.imageURL || questionContent.imageOrder > -1);
                        QuestionnaireService.toggleInput = angular.copy($scope.toggleInput);
                        $scope.testUrlLinks();
                        $scope.validateModel();
                    });
                } else {
                    $scope.questionForm.question.order = newQuestionOrder;
                    QuestionnaireService.latestQuestion = angular.copy($scope.questionForm.question);
                }
                KendoUtilsService.disableDropOnKendoEditor('kendoTextEditor');
            });
        };

        $scope.$watch('changed', function (newValue) {
            if (newValue) {
                $scope.testUrlLinks();
                $scope.validateModel();
                $scope.changed = false;
            }
        });
        
        init();
    }
]);