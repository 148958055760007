/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsGlobalController
 * @description
 * # TimeoffSettingsGlobalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsGlobalController', 
    ['$translate', 'TimeoffSettingsGlobalService', 'NotificationService', 'REQUESTS_VALIDATION_TYPE',
    function($translate, TimeoffSettingsGlobalService, NotificationService, REQUESTS_VALIDATION_TYPE) {

        var vm = this;

        vm.REQUESTS_VALIDATION_TYPE = REQUESTS_VALIDATION_TYPE;
        vm.globalSettingsForm = {
            globalSettings : {}
        };

        vm.setApprovalFlow = function(approvalFlow) {
            vm.globalSettingsForm.globalSettings.approvalFlow = approvalFlow;
            vm.onChange();
        };

        vm.onChange = function() {
            saveTimeoffGlobalSettings();
        }

        function saveTimeoffGlobalSettings() {
            TimeoffSettingsGlobalService.update(vm.globalSettingsForm.globalSettings).then(function () {
                var message = $translate.instant('global.messages.changesUpdated.globalSettingsSuccess');
                NotificationService.notifySuccess(message, 1500);
            });
        };

        var init = function() {
            TimeoffSettingsGlobalService.getData().then(function (response) {
                vm.globalSettingsForm.globalSettings = response.data;
            });
        };

        init();
    }
]);