(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:feedbackTrayPreviewQuestionnaireContent
     * @description
     * # feedbackTrayPreviewQuestionnaireContent
     */
    angular.module('dialengaExeviBackoffice').component('feedbackTrayPreviewQuestionnaireContent', {
        bindings: {
            publication: '<',
            language: '<',
            isOpened: '<',
            phoneCallOptionEnabled: '<'
        },
        templateUrl: '/ng1/components/feedback-tray-preview/feedback-tray-preview-questionnaire-content.html',
        controllerAs: 'vm',
        controller: ['TRAY_QUESTION_KIND', function (TRAY_QUESTION_KIND) {
            var vm = this;
            vm.TRAY_QUESTION_KIND = TRAY_QUESTION_KIND;
            vm.MULTIPLE_KIND = { LIMITLESS: 1, EXACT: 2, RANGE: 3 };
        }]
    });
})();