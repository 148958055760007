'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:languageFilter
 * @function
 * @description
 * # languageFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('languageFilter', ['$filter', function ($filter) {
    return function (language) {
        return $filter('translate')('global.language.' + language);
    };
}]);
