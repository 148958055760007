(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:publicationPreviewDevice
     * @description
     * # publicationPreviewDevice
     */
    angular.module('dialengaExeviBackoffice').component('publicationPreviewDevice', {
        bindings: {
            publication: '<',
            language: '=?',
            newImageSrc: '<?',
            previewState: '=',
            segments: '<?',
            segmentation: '<?',
            contentKind: '<',
            isGallery: '<',
            isSurveyPreview: '<',
            hideTimeline: '<?',
            isAnonymous: '<?',
            isCover: '<?'
        },
        templateUrl: '/ng1/components/shared/publication-preview/publication-preview-device.html',
        controllerAs: 'vm',
        controller: ['$scope', '$routeParams', '$rootScope', '$translate', 'PublicationService', 'Session', 'CONTENT_KIND', 'DEVICE_PREVIEW_STATES',
            function($scope, $routeParams, $rootScope, $translate, PublicationService, Session, CONTENT_KIND, DEVICE_PREVIEW_STATES) {
                var vm = this;

                vm.language = vm.language || Session.language;

                vm.CONTENT_KIND = CONTENT_KIND;
                vm.DEVICE_PREVIEW_STATES = DEVICE_PREVIEW_STATES;

                this.$onInit = function() {
                    setSelectedState();

                    if (vm.publication && vm.publication.gallery && vm.publication.gallery.images) {
                        $scope.$watch('vm.publication.gallery.images', function(newValue, oldValue) {
                            if (newValue && newValue.length == 0 && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.GALLERY) {
                                vm.setPreviewState(vm.DEVICE_PREVIEW_STATES.PREVIEW);
                            }
                        });
                    }
                };

                function setSelectedState() {
                    // When loading a new message, selected state is reset to default one
                    if (PublicationService.devicePreviewState.message !== $routeParams.id) {
                        PublicationService.devicePreviewState = {
                            message: $routeParams.id,
                            state: vm.DEVICE_PREVIEW_STATES.PREVIEW
                        }
                    }
                    if (PublicationService.devicePreviewState.state === vm.DEVICE_PREVIEW_STATES.PUSH && !vm.publication.pushEnabled) {
                        vm.currentPreviewState = vm.DEVICE_PREVIEW_STATES.PREVIEW;
                    } else {
                        // Select saved state
                        vm.currentPreviewState = PublicationService.devicePreviewState.state;
                    }
                }

                vm.closePreview = function() {
                    vm.previewState.opened = false;
                };

                vm.setPreviewState = function(state) {
                    vm.currentPreviewState = PublicationService.devicePreviewState.state = state;
                };

                function updateLanguages(languages) {
                    if (Session.companyLanguages && Session.companyLanguages.length > 1) {
                        var addedLanguages = [];
                        angular.forEach(languages, function(language) {
                            addedLanguages.push($translate.instant('global.language.' + language));
                        });
                        vm.publicationLanguages = null;
                        vm.publicationLanguages = addedLanguages.sort().join(', ');
                    }
                }

                $rootScope.$on('dialenga:publicationLanguagesUpdate', function(event, args) {
                    updateLanguages(args.addedLanguages);
                });

                $scope.$watch('vm.publication', function(newValue, oldValue) {
                    if (newValue && oldValue !== newValue && !!newValue.contents) {
                        var languages = Object.keys(newValue.contents);
                        updateLanguages(languages);
                    }
                });

                $scope.$watch('vm.publication.pushEnabled', function(newValue, oldValue) {
                    if (oldValue === true && newValue === false && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.PUSH) {
                        vm.setPreviewState(vm.DEVICE_PREVIEW_STATES.PREVIEW);
                    }
                });
            }
        ]
    });
})();