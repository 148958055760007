'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:DialogueController
 * @description
 * # DialogueController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('DialogueController',
    ['$scope', '$location', '$routeParams', 'QuestionnaireService',
    function ($scope, $location, $routeParams, QuestionnaireService) {

        function getQuestionById(questionId) {
            return $scope.questionnaire.questions.filter(function (question) {
                return question.id === questionId;
            });
        }

        function getAnswersByQuestionId(questionId) {
            var question = getQuestionById(questionId);
            if (question[0]) {
                return question[0].answers;
            } else {
                return null;
            }
        }

        function updateUncompletedQuestionTooltip(questionId) {
            // Kendo tooltip needs to be manually updated
            var item = $('.icon-question.uncompleted[data-question-id="' + questionId + '"]');
            if (item.length > 0) {
                var questionAnswers = getAnswersByQuestionId(questionId);
                var tooltipText = $scope.getQuestionStatusTooltip(questionAnswers);

                var itemTooltip = item.data("kendoTooltip");
                itemTooltip.options.content = tooltipText;

                // Force refresh to apply changes
                itemTooltip.refresh();
            }
        }

        $scope.editAnswers = function (questionId) {
            $location.path('/questionnaires/' + $scope.questionnaireId + '/questions/' + questionId);
            $location.hash('answers');
        };

        $scope.editQuestion = function(question) {
            if (!$scope.originLink) {
                var url = '/questionnaires/' + $routeParams.id;
                if (question.answers && question.answers.length > 0) {
                    url += '/questions/';
                } else {
                    url += '/endings/';
                }
                url += question.id;
                $location.path(url);
            } else {
                delete $scope.originAnswer;
                delete $scope.originLink;
            }
        };

        $scope.startLinkSelection = function(answer, questionId, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.originAnswer = answer;
            $scope.originLink = questionId;
        };

        $scope.endLinkSelection = function(question, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.originAnswer.linkedQuestion = question.id;
            if ($scope.items[question.id]) $scope.items[question.id].isLinked = true;

            $scope.validateModel();

            QuestionnaireService.linkWithQuestion($routeParams.id, $scope.originLink, $scope.originAnswer.id, question.id);

            updateUncompletedQuestionTooltip($scope.originLink);

            delete $scope.originAnswer;
            delete $scope.originLink;
        };

        $scope.cancelLinkSelection = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            delete $scope.originAnswer;
            delete $scope.originLink;
        };

        $scope.unlinkQuestion = function(answer, questionId, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            answer.linkedQuestion = null;

            QuestionnaireService.unlink($routeParams.id, questionId, answer.id).then(function(response) {
                $scope.updateQuestionnaireData(response.data);

                $scope.updateQuestionnaireItemsAndLinkedStatus(response.data);

                updateUncompletedQuestionTooltip(questionId);

                $scope.validateModel();
            });
        };

}]);
