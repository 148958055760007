'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:compile
 * @description
 * # compile
 */
angular.module('dialengaExeviBackoffice').directive('compile', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
        restrict: 'A',
        replace: true,
        link: function (scope, ele, attrs) {
            $timeout(function () {
                $compile(ele.contents())(scope);
            });
        }
    };
}]);
