'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayResponsiblesModalController
 * @description
 * # FeedbackTrayResponsiblesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayResponsiblesModalController', 
    ['$scope', '$timeout', 'ArrayUtilsService', 'params', 'SegmentService', 'UsersService','$uibModalInstance', 'USER_STATES_FILTER',
    function($scope, $timeout, ArrayUtilsService, params, SegmentService, UsersService, $uibModalInstance, USER_STATES_FILTER) {

        var vm = this;
        vm.totalAvailableItems = 0;
        vm.selectedUsers = [];
        vm.filter = {
            availableUsers: '',
            addedUsers: ''
        };
        vm.addedUsersIds = [];
        vm.arePendingChanges = false;

        vm.globalSearchOpened = false;
        vm.groupSearchOpened = false;

        var initialAddedUsersIds;

        vm.originGroupDropdownOptions = SegmentService.getSegmentedUsersDropDownListOptions('segments.allUsers', undefined);
        
        $scope.$watchCollection('vm.addedUsersIds', function (newValue) {
            if (newValue) {
                vm.arePendingChanges = !ArrayUtilsService.areEquals(initialAddedUsersIds, newValue);
            }
        });

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.accept = function () {
            var users = vm.arePendingChanges ? vm.addedUsers : null;
            $uibModalInstance.close(users);
        };

        vm.originGroupChange = function () {
            loadAvailableSegmentedUsers();
            vm.selectedUsers = [];
        }

        function loadAvailableSegmentedUsers() {
            var options = {
                page: 0,
                size: 20000
            };
            var availableUsersStates = [
                USER_STATES_FILTER.ANONYMOUS,
                USER_STATES_FILTER.PENDING_ACTIVATION,
                USER_STATES_FILTER.REGISTERED,
                USER_STATES_FILTER.EXPIRED_CREDENTIALS,
                USER_STATES_FILTER.LOCKED
            ];
            if (vm.originGroup) {
                options['user.name'] = vm.filter.availableUsers;
                options['user.state'] = availableUsersStates;
                getSegmentAvailableUsers(vm.originGroup, options);
            } else {
                options.name = vm.filter.availableUsers;
                options.state = availableUsersStates;
                getAllAvailableUsers(options);
            }
        }

        function setAvailableUsers(data) {
            vm.availableUsers = data.content;
            vm.totalAvailableItems = data.totalElements;
        }

        function getSegmentAvailableUsers(segmentId, options) {
            vm.isSyncing = true;
            SegmentService.getSegmentUsers(segmentId, options).then(function (response) {
                vm.isSyncing = false;
                if (options['user.name'] === vm.filter.availableUsers) {
                    setAvailableUsers(response.data);
                }
            });
        }

        function getAllAvailableUsers(options) {
            vm.isSyncing = true;
            UsersService.findAllSegmentedUsers(options).then(function (response) {
                vm.isSyncing = false;
                if (options.name === vm.filter.availableUsers) {
                    setAvailableUsers(response.data);
                }
            });
        }

        vm.searchAvailableUsers = function(clearInput) {
            if(clearInput) { vm.filter.availableUsers = ''; document.getElementById('global-search').focus();}
       
            if(vm.filter.availableUsers.length === 0) { vm.selectedUsers = []; }

            if (vm.filter.availableUsers.length >= 3 || vm.filter.availableUsers.length === 0) {
                loadAvailableSegmentedUsers();
            }
        };

        vm.globalSearchOpen = function() {
            vm.globalSearchOpened = !vm.globalSearchOpened;
            $timeout(function() {
                document.getElementById('global-search').focus();
            }, 500);
        }

        vm.groupSearchOpen = function() {
            vm.groupSearchOpened = !vm.groupSearchOpened;
            $timeout(function() {
                document.getElementById('group-search').focus();
            }, 100);
        }
    
        vm.clearGroupSearch = function() {
            vm.filter.addedUsers = '';
            document.getElementById('group-search').focus();
        }

        function getAddedUsersIds() {
            return vm.addedUsers.map(function (user) {
                return user.user;
            });
        }

        function init() {
            vm.availableUsers = [];
            loadAvailableSegmentedUsers();
            vm.addedUsers = angular.copy(params.responsibles);
            initialAddedUsersIds = getAddedUsersIds();
        }
        
        init();
    }
]);
