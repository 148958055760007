'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:LoginController
 * @description
 * # LoginController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('LoginController',
    ['$scope', '$rootScope', '$translate', '$window', 'AuthenticationService',
        'GlobalService', 'OAuthAuthenticationService', 'SharedDataService', 'companyLoginConfiguration',
        'AVAILABILITY_KIND', 'LAYOUT_KIND', 'LOGIN_KIND', 'SSO_KIND',
    function ($scope, $rootScope, $translate, $window, AuthenticationService,
        GlobalService, OAuthAuthenticationService, SharedDataService, companyLoginConfiguration,
        AVAILABILITY_KIND, LAYOUT_KIND, LOGIN_KIND, SSO_KIND) {

    //***************************************** Attributes **************************************\\
    $scope.login = {
        username: '',
        password: '',
        rememberme: false,
        passwordInputType: 'password',
        alert: {
            message: '',
            show: false
        }
    };
    $scope.ssoConfigs = [];
    $scope.LAYOUT_KIND = LAYOUT_KIND;

    //******************************************* Methods ***************************************\\
    $scope.submit = function (form) {
        $scope.closeAlert();
        if (form && form.$valid) {
            AuthenticationService.login($scope.login.username, $scope.login.password, $scope.login.rememberme)
                .catch(function (error) {
                    if (error.data && error.data.error) {
                        if (error.data.error == 'InsufficientAuthenticationException') {
                            $scope.isWarning = true;
                        } else {
                            $scope.isWarning = false;
                        }
                        $translate('login.messages.error.' + error.data.error).then(
                            function (translatedText) {
                                $scope.login.alert.message = translatedText;
                            },
                            function () {
                                $scope.login.alert.message = $translate.instant('login.messages.error.authentication') + ': ' +
                                    $translate.instant('login.messages.error.UnknownException', {exceptionName: error.data.error});
                            }
                        );
                    } else {
                        $scope.login.alert.message = $translate.instant('login.messages.error.authentication');
                    }
                    $scope.login.alert.show = true;
                    $rootScope.$broadcast('dialenga:loadingEnd');
                });
        }
    };

    $scope.externalSubmit = function (ssoConfig) {
        $scope.closeAlert();
        if (ssoConfig.kind === SSO_KIND.DIALENGA) {
            $scope.showFullLoginForm();
        } else if ($scope.ssoConfigs.length > 0 && !$rootScope.isExternalAuthenticationActive) {
            localStorage.setItem('ssoConfigId', ssoConfig.id);
            if (ssoConfig.kind === SSO_KIND.SAML2 && ssoConfig.loginURL) {
                var saml2RedirectURI = ssoConfig.loginURL + '?RelayState=' + encodeURIComponent(SharedDataService.backofficeUrl);
                $window.open(saml2RedirectURI, '_self');
            } else {
                // Save in sessionStorage to have it available when redirection flow ends
                sessionStorage.loginConfiguration = JSON.stringify(ssoConfig);
                OAuthAuthenticationService.authorize(ssoConfig);
            }
        }
    };

    $scope.closeAlert = function () {
        $scope.login.alert.show = false;
    };

    $scope.showHidePassword = function () {
        $scope.login.passwordInputType = GlobalService.showHidePassword($scope.login.passwordInputType);
    };

    $scope.showFullLoginForm = function () {
        $scope.loginFormHidden = false;
    };

    $scope.toggleToExternalLogin = function () {
        if ($scope.ssoConfigs.length === 1) {
            $scope.externalSubmit($scope.ssoConfigs[0]);
        } else if ($scope.ssoConfigs.length > 1) {
            $scope.loginFormHidden = true;
        }
    };

    function initSSOConfiguration() {
        if (!companyLoginConfiguration || !companyLoginConfiguration.loginConfiguration || companyLoginConfiguration.loginConfiguration.ssoConfigs.length === 0) {
            return;
        }
        var ssoConfig;
        for (var index = 0; index < companyLoginConfiguration.loginConfiguration.ssoConfigs.length; index++) {
            ssoConfig = companyLoginConfiguration.loginConfiguration.ssoConfigs[index];
            if (ssoConfig.availabilityKind === AVAILABILITY_KIND.ALL || ssoConfig.availabilityKind === AVAILABILITY_KIND.BACKOFFICE) {
                $scope.ssoConfigs.push(ssoConfig);
            }
        }
    }

    function initLoginLayout() {
        var loginConfiguration = companyLoginConfiguration.loginConfiguration;
        $scope.ctaLayoutKind = loginConfiguration.ctaLayoutKind || LAYOUT_KIND.RECOMMENDED;
        $scope.selectedLanguage = $translate.proposedLanguage() || $translate.use();
        if (!!loginConfiguration.dialengaLoginHeaderContents.contents && !!loginConfiguration.dialengaLoginHeaderContents.contents[$scope.selectedLanguage]) {
            $scope.dialengaLoginHeader = companyLoginConfiguration.loginConfiguration.dialengaLoginHeaderContents.contents[$scope.selectedLanguage].text;
        }
        if (!!loginConfiguration.dialengaLoginTextContents.contents && !!loginConfiguration.dialengaLoginTextContents.contents[$scope.selectedLanguage]) {
            $scope.dialengaLoginText = loginConfiguration.dialengaLoginTextContents.contents[$scope.selectedLanguage].text;
        }
        if (!!loginConfiguration.dialengaMainCtaContents.contents && !!loginConfiguration.dialengaMainCtaContents.contents[$scope.selectedLanguage]) {
            $scope.dialengaMainCta = loginConfiguration.dialengaMainCtaContents.contents[$scope.selectedLanguage].text;
        }
    }

    function initLoginConfiguration() {
        if (!companyLoginConfiguration || !companyLoginConfiguration.loginConfiguration) {
            return;
        }
        initLoginLayout();
        initSSOConfiguration();
    }

    function init() {
        initLoginConfiguration();
        $scope.loginFormHidden = $scope.ssoConfigs.length > 0;
        if ($rootScope.externalLoginFailed) {
            $scope.login.alert.show = true;
            $scope.login.alert.message = $translate.instant('login.messages.error.authentication');
            $rootScope.externalLoginFailed = false;
        }
    }

    init();
}]);
