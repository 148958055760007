'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationDetailController
 * @description
 * # ConversationDetailController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationDetailController',
    ['$rootScope', '$q', '$timeout', '$translate', 'ConversationsService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'Session',
    'CONVERSATION_NOTES', 'CONVERSATIONS_TABS', 'CONVERSATION_VALIDATION_STATE', 'USER_ROLES',
    function($rootScope, $q, $timeout, $translate, ConversationsService, KendoUtilsService, ModalService, NotificationService, Session,
        CONVERSATION_NOTES, CONVERSATIONS_TABS, CONVERSATION_VALIDATION_STATE, USER_ROLES) {

        var self = this;
        self.CONVERSATION_NOTES = CONVERSATION_NOTES;
        self.CONVERSATIONS_TABS = CONVERSATIONS_TABS;
        self.CONVERSATION_VALIDATION_STATE = CONVERSATION_VALIDATION_STATE;

        self.motives = [];
        self.trays = [];
        self.selectedTab = self.CONVERSATIONS_TABS.DETAIL;
        self.isAdmin = Session.roles.indexOf(USER_ROLES.admin) !== -1;

        self.textareaOptions = KendoUtilsService.getTextareaOptions(applyEditorChangesToTextModel);
        self.textareaOptions.placeholder = $translate.instant('message.captionPlaceholder');

        self.textareaOptions.tools.splice(0,1);
        self.textareaOptions.tools.splice(3,2);

        self.iframe = angular.element( document.querySelector( 'iframe' ) );
        self.iframe.addClass('scroll scroll2');

        self.newMessageModel = '';
        self.newNoteModel= '';
        self.attachConversation = {
            files: [],
            images: []
        }
        self.attachNotes = {
            files: [],
            images: []
        }
        self.isValidMessage = false;
        self.isValidNotes = false;

        self.discardValidationPopoverTemplateUrl = '/ng1/components/conversation/conversation-discard-validation-popover.html';

        self.$onChanges = function (changes) {
            if (changes && changes.conversation && changes.conversation.currentValue &&
                (!changes.conversation.previousValue || changes.conversation.currentValue.id !== changes.conversation.previousValue.id)) {
                self.conversationResources = [];
                self.selectedTab = self.CONVERSATIONS_TABS.DETAIL;
                self.validationOptionsVisible = false;
                self.motiveOptionsVisible = false;
                self.trayOptionsVisible = false;
                self.detailInfoVisible = false;
                getAllMotives();
                getAllTrays();
                setValidationTooltip();
                if (changes.conversation.previousValue !== null) {
                    resetData(true);
                }
            }
        };

        // self.$onDestroy = function () {
        //     resetData();
        // };

        self.loadTabContent = function(selectedTab) {
            self.selectedTab = selectedTab;
            if (selectedTab === CONVERSATIONS_TABS.FILES) {
                self.isLoadingFiles = true;
                ConversationsService.getConversationResources(self.conversation.id).then(function (response) {
                    self.conversationResources = response.data;
                    self.isLoadingFiles = false;
                });
            }
            if (selectedTab === CONVERSATIONS_TABS.NOTES) {
                self.scrollNotesToBottom();
            }
        };

        self.showTextEditorOptions = function () {
            self.textEditorOptionsVisible = !self.textEditorOptionsVisible;
        }

        function applyEditorChangesToTextModel() {
            $timeout(function() {
                if (self.selectedTab === CONVERSATIONS_TABS.DETAIL) {
                    self.newMessageModel = $('#kendoTextEditorDetail').data('kendoEditor').value();
                } else if (self.selectedTab === CONVERSATIONS_TABS.NOTES) {
                    self.newNoteModel = $('#kendoTextEditorNotes').data('kendoEditor').value();
                }
               validateModel();
            });
        }

        self.showDetailInfo = function () {
            self.detailInfoVisible = !self.detailInfoVisible;
        }

        // self.autoGrowTextarea = function($event) {
        //     if ($event.target) {
        //         $event.target.style.height = '5px';
        //         $event.target.style.height = ($event.target.scrollHeight) + 'px';

        //         self.scrollConversationToBottom();
        //     }
        // };

        function selectImage (file) {
            var deferred = $q.defer();
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                deferred.resolve({ 
                    image: {
                        src: imageData,
                        name: file.name,
                        type: file.type
                    }, 
                    footnotes: {}
                });
            };
            reader.readAsDataURL(file);
           
            return deferred.promise;
        }

        function setValidationTooltip() {
            $timeout(function () {
                var validationTooltip = $('#dismiss-conversation-validation').data('kendoTooltip');
                if (validationTooltip) {
                        var tooltipText = self.conversation.validationState === CONVERSATION_VALIDATION_STATE.VALIDATED ? 'conversations.validatedConversation' : 'conversations.rejectedConversation';
                        validationTooltip.options.content = $translate.instant(tooltipText);
                        validationTooltip.refresh();
                }
            }, 750);
        }

        function resetData(resetAll) {
            if (self.selectedTab === CONVERSATIONS_TABS.DETAIL || resetAll) {
                self.newMessageModel = '';
                self.attachConversation.images = [];
                self.attachConversation.files = [];
                self.isValidMessage = false;
            }
            if (self.selectedTab === CONVERSATIONS_TABS.NOTES || resetAll) {
                self.newNoteModel = '';
                self.attachNotes.images = [];
                self.attachNotes.files = [];
                self.isValidNotes = false;
            }
            validateModel();
        }

        function validateModel() {
            self.isValidMessage = (self.newMessageModel && self.newMessageModel !== '') || 
                            self.attachConversation.files.length > 0 || 
                            self.attachConversation.images.length > 0;
            self.isValidNotes = (self.newNoteModel && self.newNoteModel !== '');
            self.arePendingChanges = self.isValidMessage || self.isValidNotes;
        } 

        self.updatePlaceholder = function() {
            var inputNotes = $("#kendoTextEditorNotes").data("kendoEditor");
            if ((self.attachNotes.images.length > 0 || self.attachNotes.files.length > 0) && self.newNoteModel == '') {
                inputNotes.options.placeholder = $translate.instant('conversations.notesPlaceholder');
                inputNotes.refresh();
            }
            if (self.attachNotes.images.length == 0 && self.attachNotes.files.length == 0 && self.newNoteModel == '') {
                inputNotes.options.placeholder = $translate.instant('message.captionPlaceholder');
                inputNotes.refresh();
            }
        };

        self.attachFile = function () {
            document.getElementById('message-attach-file').click();
        };
    
        self.onFileSelected = function ($files) {
            if ($files.length > 0) {
                if (self.selectedTab === CONVERSATIONS_TABS.DETAIL) {
                    if (self.isImage($files[0].type)) {
                        selectImage($files[0]).then(function(response) {
                            $files[0].src = response.image.src;
                            self.attachConversation.images.push($files[0]);
                            validateModel();
                        });
                    } else {
                        self.attachConversation.files.push($files[0]);
                    }
                }
                if (self.selectedTab === CONVERSATIONS_TABS.NOTES) {
                    if (self.isImage($files[0].type)) {
                        selectImage($files[0]).then(function(response) {
                            $files[0].src = response.image.src;
                            self.attachNotes.images.push($files[0]);
                            self.updatePlaceholder();
                        });
                    } else {
                        self.attachNotes.files.push($files[0]);
                        self.updatePlaceholder();
                    }
                }
                validateModel();
            }
        };

        self.removeAttachedFile = function (resourcesArray, index) {
            if (resourcesArray.length > index) {
                resourcesArray.splice(index, 1);
            }
            self.updatePlaceholder();
            validateModel();
        };

        self.removeFile = function(index) {
            self.removeAttachedFile(self.selectedTab === CONVERSATIONS_TABS.DETAIL ? self.attachConversation.files : self.attachNotes.files, index);
        };

        self.removeImage = function(index) {
            self.removeAttachedFile(self.selectedTab === CONVERSATIONS_TABS.DETAIL ? self.attachConversation.images : self.attachNotes.images, index);
        };

        self.sendMessage = function(tab) {
            var newMessage = '';
            var resources = [];

            if (tab === CONVERSATIONS_TABS.DETAIL) {
                newMessage = self.newMessageModel ? self.newMessageModel.replace(/\r?\n/g, '<br />') : '';
                resources = self.attachConversation.files.concat(self.attachConversation.images);
                ConversationsService.addMessageToConversationAsResponsible(self.conversation.id, newMessage, resources).then(function(response) {
                    resetData();
                    self.conversation.messages.push(response.data);
                    self.reloadGrid({ selectFirstRow: true });
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }); 
            }
            if (tab === CONVERSATIONS_TABS.NOTES) {
                newMessage = self.newNoteModel ? self.newNoteModel.replace(/\r?\n/g, '<br />') : '';
                resources = self.attachNotes.files.concat(self.attachNotes.images);
                ConversationsService.addNotesToConversationAsResponsible(self.conversation.id, newMessage, resources).then(function(response) {
                    resetData();
                    self.conversationNotes.push(response.data);
                    self.scrollNotesToBottom();
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }); 
            }
            
        };

        self.openValidationDropdown = function () {
            self.validationOptionsVisible = !self.validationOptionsVisible;
        };

        self.openMotiveDropdown = function() {
            self.motiveOptionsVisible = !self.motiveOptionsVisible;
        };

        self.openTrayDropdown = function() {
            self.trayOptionsVisible = !self.trayOptionsVisible;
        };

        function closeConversationAndReload() {
            ConversationsService.closeConversation(self.conversation.id).then(function() {
                self.reloadGridAndSelectRow();
                var message = $translate.instant('conversations.closeSuccessMessage');
                NotificationService.notifySuccess(message);
            });
        }

        self.closeConversation = function() {
            if (self.conversation) {
                var skipModalAlias = 'closeConversation';
                var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                if (!shouldModalBeDisplayed) {
                    closeConversationAndReload();
                } else {
                    ModalService.openConfirmationModal({
                        title: 'conversations.modal.close.title',
                        message: 'conversations.modal.close.message',
                        acceptButtonText: 'global.modal.close',
                        skipModalAlias: skipModalAlias,
                        accept: function() {
                            closeConversationAndReload();
                        }
                    });
                }
            }
        };

        function reopenConversationAndReload() {
            ConversationsService.reopenConversation(self.conversation.id).then(function() {
                self.reloadGridAndSelectRow();
                var message = $translate.instant('conversations.reopenSuccessMessage');
                NotificationService.notifySuccess(message);
            });
        }

        self.reopenConversation = function() {
            if (self.conversation) {
                if (!!self.conversation.validationState && (self.conversation.validationState === CONVERSATION_VALIDATION_STATE.VALIDATED ||
                    self.conversation.validationState === CONVERSATION_VALIDATION_STATE.REJECTED)) {
                        var skipModalAlias = 'reopenConversation';
                        var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                        if (!shouldModalBeDisplayed) {
                            reopenConversationAndReload();
                        } else {
                            ModalService.openConfirmationModal({
                                title: 'conversations.modal.reopen.title',
                                message: 'conversations.modal.reopen.message',
                                acceptButtonText: 'global.modal.accept',
                                skipModalAlias: skipModalAlias,
                                accept: function() {
                                    reopenConversationAndReload();
                                }
                            });
                        }
                } else {
                    reopenConversationAndReload();
                }
            }
        };

        function reloadConversation(validationState) {
            if (!self.conversation) {
                return false;
            }
            $rootScope.$broadcast('dialenga:updateSelectedRowValidationState', { validationState: validationState });
            ConversationsService.getResponsibleConversation(self.conversation.id).then(function (response) {
                self.conversation = response.data;
                setValidationTooltip();

                ConversationsService.getResponsibleConversationNotes(self.conversation.id).then(function(responseNotes) {
                    self.conversationNotes = responseNotes.data;

                    if (self.selectedTab === CONVERSATIONS_TABS.NOTES) {
                        self.scrollNotesToBottom();
                    }
                });
            });
        }

        self.openValidationModal = function () {
            if (self.conversation) {
                ModalService.openModal({
                    templateUrl: '/ng1/components/conversation/conversation-validation-modal.html',
                    controller: 'ConversationValidationModalController',
                    size: 'md',
                    accept: function(validationResponse) {
                        ConversationsService.validateConversation(self.conversation.id, validationResponse).then(function() {
                            if (!!validationResponse.validation && !!validationResponse.validation.validation) {
                                reloadConversation(validationResponse.validation.validation);
                                var translationKey = (validationResponse.validation.validation === CONVERSATION_VALIDATION_STATE.VALIDATED) ? 'conversations.validatedConversation' : 'conversations.rejectedConversation';
                                var message = $translate.instant(translationKey);
                                NotificationService.notifySuccess(message);
                            }
                        });
                    }
                });
            }
        };

        self.showDiscardValidationPopover = function () {
            var tooltip = $('#dismiss-conversation-validation').data('kendoTooltip');
            tooltip.hide();
            if (!self.validationOptionsVisible) {
                self.validationOptionsVisible = true;
            }
        };

        self.discardValidation = function () {
            self.validationOptionsVisible = false;
            ConversationsService.discardValidation(self.conversation.id).then(function() {
                reloadConversation(CONVERSATION_VALIDATION_STATE.PENDING);
                var message = $translate.instant('conversations.validationDiscarded');
                NotificationService.notifySuccess(message);
            });
        };

        self.scrollConversationToBottom = function() {
            $timeout(function() {
                var conversationContainer = document.getElementById('conversationContainer');
                conversationContainer.scrollTop = conversationContainer.scrollHeight;
            });
        };

        self.scrollNotesToBottom = function() {
            $timeout(function() {
                var notesContainer = document.getElementById('notesContainer');
                notesContainer.scrollTop = notesContainer.scrollHeight;
            });
        };

        self.updateMotive = function(motive) {
            ConversationsService.changeMotive(self.conversation.id, motive.id).then(function(response) {
                self.conversation = response.data;
                self.reloadGrid();
                self.motiveOptionsVisible = false;
                getAllMotives();
            });
        };

        function getAllMotives() {
            ConversationsService.getAllMotivesExceptOne(self.conversation.minimalMotive.id).then(function(response) {
                self.motives = response.data;
            });
        }

        self.updateTray = function(tray) {
            self.motiveOptionsVisible = false;
            self.trayOptionsVisible = false;
            ModalService.openConfirmationModal({
                title: 'conversations.modal.changeTray.title',
                message: 'conversations.modal.changeTray.message',
                messageData: { tray: tray.alias != null ? tray.alias : tray.nameInApp },
                acceptButtonText: 'global.form.send',
                accept: function() {
                    ConversationsService.changeTray(self.conversation.id, tray.id).then(function(response) {
                        self.conversation = response.data;
                        self.reloadGrid(false);
                        getAllTrays();
                    });
                }
            });
        };

        function getAllTrays() {
            ConversationsService.getAllTraysExceptOne(self.conversation.tray.id).then(function(response) {
                self.trays = response.data;
            });
        }

        self.isImage = function (mimetype) {
            return mimetype ? mimetype.startsWith('image/') : false;
        };

        self.getDialogQuestionnaireItemPositionClass = function(index) {
            var classToApply = '';
            if (index == 0 || (index > 0 && !self.conversation.messages[index-1].automaticConversation)) {
                classToApply = 'automatic-conversation__item--top';
            }
            if ((index > 0 && self.conversation.messages[index-1].automaticConversation) &&
                (index < self.conversation.messages.length-1 && self.conversation.messages[index+1].automaticConversation)) {
                classToApply = 'automatic-conversation__item';
            }
            if (index == self.conversation.messages.length-1 ||
                (index < self.conversation.messages.length-1 && !self.conversation.messages[index+1].automaticConversation)) {
                classToApply = 'automatic-conversation__item--bottom';
            }
            return classToApply;
        };
    }
]);
