'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessageStatisticsController
 * @description
 * # MessageStatisticsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('MessageStatisticsController',
    ['$scope', '$rootScope', '$routeParams', '$location', '$translate', '$timeout', 'KendoUtilsService', 'KnowledgeCenterService', 'LanguageService', 'MessageService', 'NotificationService', 'ModalService', 'Session', 'PublicationService',
    'StatisticsService', 'DateUtilsService', 'CONTENT_KIND', 'STATISTICS_DROPDOWN', 'COMMENT_STATES', 'GENERAL_CONFIG_STATES', 'MESSAGE_STATES', 'RATING_KIND',
    function($scope, $rootScope, $routeParams, $location, $translate, $timeout, KendoUtilsService, KnowledgeCenterService, LanguageService, MessageService, NotificationService, ModalService, Session, PublicationService,
        StatisticsService, DateUtilsService, CONTENT_KIND, STATISTICS_DROPDOWN, COMMENT_STATES, GENERAL_CONFIG_STATES, MESSAGE_STATES, RATING_KIND) {

        //******************************************* Attributes ************************************\\
        $scope.CONTENT_KIND = CONTENT_KIND;
        $scope.MESSAGE_STATES = MESSAGE_STATES;
        $scope.STATISTICS_TABS = {
            COMMENTS: 1,
            ACTIVITY: 2,
            INTERACTIONS: 3
        };
        $scope.interactionTypes = [];
        $scope.selectedTab = $scope.STATISTICS_TABS.ACTIVITY;
        $scope.statisticsDropDown = STATISTICS_DROPDOWN;
        $scope.statisticsRelativeType = STATISTICS_DROPDOWN.RELATIVE_BEFORE;
        $scope.isPill = false;
        var publicationId = $routeParams.id;
        var publicationEditUrl;

        $scope.totalComments = 0;
        $scope.comments = [];
        $scope.COMMENT_STATES = COMMENT_STATES;
        $scope.GENERAL_CONFIG_STATES = GENERAL_CONFIG_STATES;
        $scope.commentsConfig = Session.commentsConfig;
        $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
        $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
        $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;
        
        var ratingKind = Session.ratingKind;

        $scope.downloadCSVUrl = "";
        $scope.previewState = { opened: false };

        var DataService;

        var gridColumns = [];

        var INTERACTIONS_COLUMNS = [{ 
            column: 'hasImage',
            icon: 'icon-image-fill-s',
            fieldDate: 'openedImageDate',
            fieldAmount: "imageOpenedTimes",
            description: 'statistics.activity.interactions.hasImage',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 1
        },{
            column: 'hasVideo',
            icon: 'icon-Video-fill-s',
            fieldDate: 'openedVideoDate',
            fieldAmount: "videoOpenedTimes",
            description: 'statistics.activity.interactions.hasVideo',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 2
        },{
            column: 'hasLink',
            icon: 'icon-link-s',
            fieldDate: 'openedInternalURLDate',
            fieldAmount: "internalURLOpenedTimes",
            description: 'statistics.activity.interactions.hasLink',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 3
        },{
            column: 'hasExternalURL',
            icon: 'icon-external-link-s',
            fieldDate: 'openedExtendedURLDate',
            fieldAmount: "externalURLOpenedTimes",
            description: 'statistics.activity.interactions.hasExternalURL',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 4
        },{
            column: 'hasResource',
            icon: 'icon-attachment-s',
            fieldDate: 'openedResourceDate',
            fieldAmount: "resourceOpenedTimes",
            description: 'statistics.activity.interactions.hasResource',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 5
        },{
            column: 'shareable',
            icon: 'icon-share-s',
            fieldDate: 'sharedDate',
            fieldAmount: "sharedTimes",
            description: 'statistics.activity.interactions.shareable',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 6
        },{
            column: 'commentsEnabled',
            icon: 'icon-comment-fill-s',
            fieldDate: 'commentDate',
            fieldAmount: "commentTimes",
            description: 'statistics.activity.interactions.comments',
            total: 0,
            percentByRead: 0,
            percentBySent: 0,
            order: 7
        }];

        var gridDataSource = StatisticsService.getActivityGridDataSource();
        gridDataSource['transport'] = {
            read: function(options) {
                var sort = KendoUtilsService.getSortCriteria(options.data.sort, ["maxInteractionDate,desc", "openDate,desc", "viewedInInboxDate,desc"]);
                DataService.activity($routeParams.id, {
                    page: options.data.page - 1,
                    size: options.data.pageSize,
                    sort: sort
                }).then(function(response) {
                    options.success(response.data);
                }, function() {
                    options.error();
                });
            }
        };
        gridDataSource['requestEnd'] = function(event) {
            KendoUtilsService.reverseGridDefaultSortDirection('activity-grid');
        };

        $scope.loadTabContent = function(tab) {
            $scope.selectedTab = tab;
        };

        function getComments() {
            var options = {
                page: 0,
                size: 10000,
            };
            DataService.getCommentsForPublication($routeParams.id, options).then(function(response) {
                $scope.comments = response.data.content;
                $scope.totalComments = response.data.totalElements;
            });
        }

        function reorderGallery() {
            $scope.message.gallery.images = $scope.message.gallery.images.sort( function (a, b) { return a.order - b.order; } );
        }

        function setInteractionTypes() {
            $scope.interactionTypes.push({
                column: 'netPromoterScore', 
                icon: ratingKind === RATING_KIND.EMOJI ? 'icon-smile-s' : 'icon-star-fill', 
                fieldDate: 'netPromoterScoreDate', 
                description: 'statistics.activity.interactions.hasPromoterScore', 
                total: $scope.statistics.totalReactions,
                percentBySent: $scope.statistics.totalReactions > 0 ? Math.floor(($scope.statistics.totalReactions*100) / $scope.statistics.sent) : 0,
                percentByRead: $scope.statistics.totalReactions > 0 ? Math.floor(($scope.statistics.totalReactions*100) / $scope.statistics.read) : 0,
                order: 10
            });
            DataService.getInteractionsResume($routeParams.id).then(function(response) {
                var totalInteractions = response.data;
                $timeout(function() {
                    angular.forEach($scope.interactionTypes, function(interaction) {
                        if (interaction.column !== 'netPromoterScore') {
                            if (totalInteractions[interaction.fieldAmount]) {
                                interaction.total = totalInteractions[interaction.fieldAmount];
                                interaction.percentBySent = totalInteractions[interaction.fieldAmount] > 0 ? Math.floor((totalInteractions[interaction.fieldAmount]*100) / $scope.statistics.sent) : 0;
                                interaction.percentByRead = totalInteractions[interaction.fieldAmount] > 0 ? Math.floor((totalInteractions[interaction.fieldAmount]*100) / $scope.statistics.read) : 0;
                            } else {
                                interaction.total = 0;
                                interaction.percentBySent = 0;
                                interaction.percentByRead = 0;
                            }
                        }
                    });
                    $scope.interactionTypes.sort(function (a, b) { return a.order - b.order; });
                }, 200); 
            });
        }

        function setColumnsForDetailActivities() {
            var columns = [{
                    field: "name",
                    headerTemplate: '<span translate="users.name"></span>',
                    headerAttributes: { class: "text-left" },
                    template: '<span class="truncate">{{dataItem.name}} {{dataItem.surname}}</span>',
                    width: '40%'
                },
                {
                    field: "viewedInInboxDate",
                    headerTemplate: '<span translate="statistics.activity.inbox"></span>',
                    template: '<span class="approved-color-soft" ng-class="{\'icon icon-tick-fill icon-24\': !!dataItem.viewedInInboxDate}" kendo-tooltip ' +
                        'k-content="\'{{dataItem.viewedInInboxDate}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>',
                    attributes: { class: "text-center" },
                },{
                    field: "openDate",
                    headerTemplate: '<span translate="statistics.activity.opened"></span>',
                    template: '<span class="approved-color-soft" ng-class="{\'icon icon-tick-fill icon-24\': !!dataItem.openDate}" kendo-tooltip ' +
                        'k-content="\'{{dataItem.openDate}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>',
                    attributes: { class: "text-center" },
                },
                {
                    field: "netPromoterScore",
                    headerTemplate: '<span translate="statistics.messages.rating"></span>',
                    template: '<rating-icon ng-if="dataItem.netPromoterScore" value="dataItem.netPromoterScore" kendo-tooltip ' +
                        'k-content="\'{{dataItem.netPromoterScoreDate}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></rating-icon>',
                    attributes: { class: "text-center" },
                }
            ]; 
            var columnSet;
            angular.forEach(INTERACTIONS_COLUMNS, function(interaction) {
                if ($scope.message[interaction.column]) {
                    columnSet = {
                        field: interaction.column,
                        headerTemplate: '<div class="flex"><span class="icon ' + interaction.icon +' icon-16 black-45" kendo-tooltip k-content="\'' + interaction.description + '\' | translate"></span></div>',
                        template: '<span class="icon '+ interaction.icon + ' icon-16" ng-class="!!dataItem.' + interaction.fieldDate +' ? \'approved-color\' : \'black-15\'" kendo-tooltip ' +
                        'k-content="\'{{dataItem.' + interaction.fieldDate + '}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>', 
                        width: 30,
                        sortable: false
                    };
                    columns.splice(columns.length - 1, 0, columnSet);
                    $scope.interactionTypes.push(interaction);

                    if (interaction.column == 'commentsEnabled') {
                        columnSet = {
                            field: 'hasLikes',
                            headerTemplate: '<div class="flex"><span class="icon icon-heart-fill-s icon-16 black-45" kendo-tooltip k-content="\'statistics.activity.interactions.commentLikes\' | translate"></span></div>',
                            template: '<span class="icon icon-heart-fill-s icon-16" ng-class="!!dataItem.commentLikeDate ? \'approved-color\' : \'black-15\'" kendo-tooltip ' +
                            'k-content="\'{{dataItem.commentLikeDate}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>', 
                            width: 30,
                            sortable: false
                        };
                        columns.splice(columns.length - 2, 0, columnSet);
                        $scope.interactionTypes.splice($scope.interactionTypes.length - 1, 0, 
                            {
                            column: 'hasLikes', 
                            icon: 'icon-heart-fill-s', 
                            fieldDate: 'commentLikeDate', 
                            fieldAmount: "commentLikeTimes", 
                            description: 'statistics.activity.interactions.commentLikes',
                            total: 0,
                            percentByRead: 0,
                            percentBySent: 0, 
                            order: 8});

                        columnSet = {
                            field: 'commentMentions',
                            headerTemplate: '<div class="flex"><span class="icon icon-mention-s icon-16 black-45" kendo-tooltip k-content="\'statistics.activity.interactions.mentions\' | translate"></span></div>',
                            template: '<span class="icon icon-mention-s icon-16" ng-class="!!dataItem.mentionDate ? \'approved-color\' : \'black-15\'" kendo-tooltip ' +
                            'k-content="\'{{dataItem.mentionDate}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>', 
                            width: 30,
                            sortable: false
                        };
                        columns.splice(columns.length - 1, 0, columnSet);
                        $scope.interactionTypes.splice($scope.interactionTypes.length - 1, 0, 
                            {
                            column: 'commentMentions', 
                            icon: 'icon-mention-s', 
                            fieldDate: 'mentionDate', 
                            fieldAmount: "commentMentions", 
                            description: 'statistics.activity.interactions.mentions',
                            total: 0,
                            percentByRead: 0,
                            percentBySent: 0, 
                            order: 9});
                    }
                } else {
                    if (interaction.column == 'hasImage' && $scope.message.gallery && $scope.message.gallery.images && $scope.message.gallery.images.length > 0) {
                        columnSet = {
                            field: interaction.column,
                            headerTemplate: '<div class="flex"><span class="icon ' + interaction.icon +' icon-16 black-45" kendo-tooltip k-content="\'' + interaction.description + '\' | translate"></span></div>',
                            template: '<span class="icon '+ interaction.icon + ' icon-16" ng-class="!!dataItem.' + interaction.fieldDate +' ? \'approved-color\' : \'black-15\'" kendo-tooltip ' +
                            'k-content="\'{{dataItem.' + interaction.fieldDate + '}}\' | date:\'dd/MM/yyyy &nbsp;&nbsp; HH:mm\'"></span>', 
                            width: 30,
                            sortable: false
                        };
                        columns.splice(columns.length - 1, 0, columnSet);
                        $scope.interactionTypes.push(interaction);
                    }
                }
            });
            return columns;
        }

        $scope.getMoreComments = function() {
            getComments();
        };

        $scope.deleteComment = function(comment) {

            ModalService.openConfirmationModal({
                title: 'statistics.comments.modal.delete.title',
                message: 'statistics.comments.modal.delete.message',
                messageData: { fullName: comment.author.name + ' ' + comment.author.surname },
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    DataService.deleteComment($routeParams.id, comment.id).then(function(response) {
                            comment.stateId = COMMENT_STATES.COMPANY_DELETED;
                            var message = $translate.instant('statistics.comments.deleted');
                            NotificationService.notifySuccess(message, 1000);
                        })
                        .catch(actionErrorHandler);
                }
            });
        };

        $scope.clonePublication = function() {
            DataService.duplicate(publicationId).then(function(response) {
                $location.path( publicationEditUrl + response.data.id);
                KendoUtilsService.gridPreferences[$scope.isPill ? 'knowledgeCenterGrid' : 'messagesGrid'].selectedButton = 0;
            });
        };

        $scope.editPublication = function() {
            $location.path(publicationEditUrl + publicationId);
        };

        $scope.archivePublication = function() {
            var contentType = $scope.isPill ? CONTENT_KIND.KNOWLEDGE_PILL : CONTENT_KIND.MESSAGE; 
            ModalService.openConfirmationModal({
                title: 'publication.modal.archive.title',
                message: 'publication.modal.archive.message',
                messageData: { contentType: contentType },
                acceptButtonText: 'global.form.archive',
                isTextInterpolated: true,
                accept: function() {
                    DataService.expire(publicationId)
                        .then(function() {
                            PublicationService.showSuccessNotification('publication.modal.archive.archived', contentType);
                            KendoUtilsService.gridPreferences[$scope.isPill ? 'knowledgeCenterGrid' : 'messagesGrid'].stateIsActive = false;
                            KendoUtilsService.gridPreferences[$scope.isPill ? 'knowledgeCenterGrid' : 'messagesGrid'].page = 1;
                            $location.path($scope.isPill ? '/knowledge-center' : '/messages');

                        })
                        .catch(actionErrorHandler);
                }
            });
        };

        $scope.relocateMessage = function () {

            ModalService.openCategoriesModal({
                id: $scope.message.category ? $scope.message.category.id : null,
                accept: function (newHierarchy) {
                    // Call endpoint to relocate publication and return to messages list
                    MessageService.relocate($routeParams.id, newHierarchy.at(-1))
                        .then(function() {
                            var message = $translate.instant('publication.modal.relocate.message');
                            NotificationService.notifySuccess(message);
                            $location.path('/messages');
                        })
                        .catch(actionErrorHandler);
                        $rootScope.$broadcast('dialenga:loadingEnd');
                },
                cancel: function () {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        };
        
        //******************************************** Methods **************************************\\
        var init = function() {
            $scope.statisticsDropdownOptions = StatisticsService.getRelativesTypesDropDownListOptions();
            $scope.isPill = $location.path().search('/knowledge-center') > -1;
            if ($scope.isPill) {
                DataService = KnowledgeCenterService;
            } else {
                DataService = MessageService;
            }
            $scope.downloadCSVUrl = DataService.URL + '/' + $routeParams.id + '/activity-detail/export-csv';
            publicationEditUrl = $scope.isPill ? '/knowledge-center/content/update/' : '/messages/content/update/';

            DataService.findOne($routeParams.id).then(function(response) {
                $scope.message = response.data;
                if ($scope.message.gallery && $scope.message.gallery.images && $scope.message.gallery.images.length > 0) {
                    reorderGallery();
                }

                // Set the columns for kendo grid
                gridColumns = setColumnsForDetailActivities();

                // Order users recipients (if exist)
                if ($scope.message.users) {
                    $scope.message.users =  DataService.orderRecipientsUsers($scope.message.users); 
                }

                // Initialize kendo grid
                KendoUtilsService.initializeGrid(gridColumns, gridDataSource).then(function(response) {
                    $scope.gridOptions = response;
                });

                var language = LanguageService.getMainContentLanguage(response.data.contents);
                $scope.messageTitle = response.data.contents[language].title;
                $scope.message.publicationDate = DateUtilsService.getDateTimeFromTimestamp(response.data.publicationDate);
                DataService.setUsersAvatarTooltips($scope.message.users);
            });
            DataService.statistics($routeParams.id).then(function(response) {
                $scope.statistics = response.data;
                $scope.total = parseInt($scope.statistics.sent);

                setInteractionTypes();

            });
            getComments();
        };

        init();
    }
]);