'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTraysController
 * @description
 * # UsersController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTraysController',
    ['$scope', '$location', '$translate', 'KendoUtilsService', 'FeedbackTraysService', 'ModalService', 'NotificationService',
    'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH',
    function ($scope, $location, $translate, KendoUtilsService, FeedbackTraysService, ModalService, NotificationService,
        PAGE_SIZE, GRID_ICON_COLUMN_WIDTH) {
            
    var vm = this;

    //******************************************* Attributes ************************************\\
    vm.stateIsActive = true;
    vm.inputSearchField = "";
    vm.selectedTray = null;

    var removeReloadTrayListEventListener;

    var gridColumns = [{
        field: "initials",
        title: " ",
        type: "string",
        attributes: {class: "text-center"},
        template: '<avatar-hexagon class="flex" [initials]="dataItem.initials"></avatar-hexagon>',
        width: 90,
        sortable: false
    }, {
        field: "alias",
        title: $translate.instant('feedbackTrays.header.name'),
        template: '<no-default-language-tooltip available-languages="dataItem.availableLanguages"></no-default-language-tooltip>' +
            '<div class="flex align-items-center"><span class="icon icon-heisenberg icon-22 margin-right-5" kendo-tooltip k-content="\'feedbackTrays.isAnonymous\' | translate" ng-if="!!dataItem.anonymous"></span>' +
            '<span class="truncate" ng-bind="!!dataItem.alias ? dataItem.alias : dataItem.name" title="{{dataItem.name}}"></span></div>',
        width: '250px'
    }, {
        field: "responsibles",
        title: $translate.instant('feedbackTrays.header.responsible'),
        template: '<span class="truncate" ng-bind-html="dataItem.responsibles" kendo-tooltip k-content="\'{{dataItem.responsiblesTooltip}}\'"></span>',
        encoded: false,
        sortable: false
    }, {
        field: "questionnaire",
        title: $translate.instant('conversations.automaticConversation'),
        template: '<span class="state-flag state-flag--active state-flag__label " data-ng-if="dataItem.questionnaire && dataItem.questionnaire.enabled" data-translate="global.form.active_female"></span>' +
                  '<span class="state-flag state-flag--inactive state-flag__label" data-ng-if="dataItem.questionnaire && !dataItem.questionnaire.enabled" data-translate="global.form.inactive_female"></span>',
        encoded: false,
        sortable: false,
        width: '210px'
    }, {
        command: [ {
            name: "edit-button",
            template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate">' +
                '<span class=\'icon icon-edit\'></span></a>',
            click: editRowClickHandler
        }, {
            name: "delete-button",
            template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                'data-ng-if="dataItem.deletable"><span class="icon icon-delete"></span></a>',
            click: deleteRowClickHandler
        }, {
            name: "archive-button",
            template: '<a class="k-button k-grid-archive-button" kendo-tooltip k-content="\'global.form.archive\' | translate" ' +
                'data-ng-if="!dataItem.deletable && !dataItem.archived"><span class="icon icon-archive"></span></a>',
            click: archiveRowClickHandler
        }, {
            name: "activate-button",
            template: '<a class="k-button k-grid-activate-button" kendo-tooltip k-content="\'global.form.activate\' | translate" ' +
                'data-ng-if="dataItem.archived"><span class="icon icon-unarchive"></span></a>',
            click: activateRowClickHandler
        }],
        attributes: {class: 'text-right'},
        title: "",
        width: GRID_ICON_COLUMN_WIDTH*3
    }];
    var gridDataSource = {
        transport: {
            read: function (options) {
                saveGridPreferences(options);

                var requestOptions = getRequestOptions(options);
                FeedbackTraysService.findAll(requestOptions).then(function (response) {
                    vm.numResults = response.data.totalElements;
                    options.success(response.data);
                }, function () {
                    options.error();
                });
            }
        },
        schema: {
            data: "content",
            total: "totalElements",
            parse: function (data) {
                $.each(data.content, function (idx, elem) {
                    if (elem.responsibles.length === 0) {
                        data.content[idx].responsibles = $translate.instant('feedbackTrays.admins');
                        data.content[idx].responsiblesTooltip = null;
                    }
                    else {
                        var sortedResponsibles = elem.responsibles.sort();
                        data.content[idx].responsibles = sortedResponsibles.join(", ");
                        data.content[idx].responsiblesTooltip = sortedResponsibles.length > 1 ? sortedResponsibles.join("<br />") : null;
                    }
                });
                return data;
            }
        },
        pageSize: PAGE_SIZE,
        serverPaging: true,
        serverSorting: true
    };

    KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true },{ change: onChange }).then(function (response) {
        vm.gridOptions = response;
    });

    //******************************************** Methods **************************************\\

    vm.$onInit = function() {
        initGridReloadEvent();
    }

    vm.$onDestroy = function() {
        removeReloadTrayListEventListener();
    }

    function saveGridPreferences(options) {
        KendoUtilsService.gridPreferences['feedbackTraysGrid'] = angular.copy(options.data);
        KendoUtilsService.gridPreferences['feedbackTraysGrid'].stateIsActive = vm.stateIsActive;
        KendoUtilsService.gridPreferences['feedbackTraysGrid'].inputSearchField = vm.inputSearchField;
    }

    $scope.$on("kendoWidgetCreated", function (event, widget) {
        if (widget === vm.feedbackTraysGrid) {
            if (KendoUtilsService.gridPreferences['feedbackTraysGrid']) {
                var requestOptions = KendoUtilsService.gridPreferences['feedbackTraysGrid'];
                vm.stateIsActive = requestOptions.stateIsActive;
                vm.inputSearchField = requestOptions.inputSearchField;

                vm.feedbackTraysGrid.dataSource.query(requestOptions);
            } else {
                vm.feedbackTraysGrid.dataSource.read();
            }
            KendoUtilsService.dblClickGridRedirect('kendo-grid','/settings/conversations/feedback-trays/update/');
        }
    });

    function getRequestOptions(options) {
        var requestOptions = KendoUtilsService.getGridRequestOptions(options, 'lastModifiedDate,desc', vm.inputSearchField, 'alias');
        requestOptions['archived'] = !vm.stateIsActive;

        return requestOptions;
    }

    vm.getFeedbackTraysByIsActive = function (isActive) {
        if (vm.stateIsActive !== isActive) {
            vm.stateIsActive = isActive;
            reloadGrid({page: 1});
        }
    };

    vm.listItemsSearch = function(text) {
        vm.inputSearchField = text;
        if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
            reloadGrid({page: 1});
        }
    };

    function deleteRowClickHandler(event) {
        event.preventDefault();

        var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
        var feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        ModalService.openConfirmationModal({
            title: 'feedbackTray.modal.delete.title',
            message: 'feedbackTray.modal.delete.message',
            messageData: {feedbackTray: feedbackTray},
            acceptButtonText: 'global.form.delete',
            isTextInterpolated: true,
            accept: function () {
                FeedbackTraysService.delete(dataItem.id)
                    .then(function() {
                        reloadGrid();
                        var message = $translate.instant('feedbackTray.notification.delete.message', {feedbackTray: feedbackTray});
                        NotificationService.notifySuccess(message);
                    })
                    .catch(actionErrorHandler);
            }
        });
    }

    function archiveRowClickHandler(event) {
        event.preventDefault();

        var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
        var feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        ModalService.openConfirmationModal({
            title: 'feedbackTray.modal.archive.title',
            message: 'feedbackTray.modal.archive.message',
            messageData: {feedbackTray: feedbackTray},
            acceptButtonText: 'global.form.archive',
            isTextInterpolated: true,
            accept: function () {
                FeedbackTraysService.archive(dataItem.id)
                    .then(function() {
                        reloadGrid();
                        var message = $translate.instant('feedbackTray.notification.archive.message', {feedbackTray: feedbackTray});
                        NotificationService.notifySuccess(message);
                    })
                    .catch(actionErrorHandler);
            }
        });
    }

    function activateRowClickHandler(event) {
        event.preventDefault();

        var dataItem = this.dataItem($(event.currentTarget).closest("tr"));
        var feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        ModalService.openConfirmationModal({
            title: 'feedbackTray.modal.activate.title',
            message: 'feedbackTray.modal.activate.message',
            messageData: {feedbackTray: feedbackTray},
            acceptButtonText: 'global.form.activate',
            isTextInterpolated: true,
            accept: function () {
                FeedbackTraysService.activate(dataItem.id)
                    .then(function() {
                        reloadGrid();
                        var message = $translate.instant('feedbackTray.notification.activate.message', {feedbackTray: feedbackTray});
                        NotificationService.notifySuccess(message);
                    })
                    .catch(actionErrorHandler);
            }
        });
    }

    function editRowClickHandler(event) {
        event.preventDefault();

        var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

        $scope.$apply(function () {
            $location.path('/settings/conversations/feedback-trays/update/' + dataItem.id);
        });
    }

    function actionErrorHandler(error) {
        if (error.status === 422) {
            reloadGrid();
        }
    }

    function reloadGrid(options) {
        vm.isSyncing = true;
        KendoUtilsService.reloadGridDatasource(vm.feedbackTraysGrid.dataSource, options).then(function () {
            $scope.$apply(function() {
                vm.isSyncing = false;
            });
        });
    }

    function onChange() {
        var selectedRow = this.select();
        var newSelectedTray = vm.feedbackTraysGrid.dataItem(selectedRow[0]);

        if (newSelectedTray !== $scope.$parent.vm.selectedTray) {
            $scope.$parent.vm.selectedTray = (selectedRow.length > 0) ? newSelectedTray : null;
        } else {
            // Deselect the row
            this.clearSelection();
            $scope.$parent.vm.selectedTray = null;
        }
    }

    function initGridReloadEvent() {
        if (!removeReloadTrayListEventListener) {
            removeReloadTrayListEventListener = $scope.$on('dialenga:reloadTrayList', function () {
                vm.feedbackTraysGrid.clearSelection();
                $scope.$parent.vm.selectedTray = null;
                reloadGrid({ page: 1 });
            });
        }
    }
}]);
