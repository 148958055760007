'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationValidationModalController
 * @description
 * # ConversationValidationModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationValidationModalController',
    ['$timeout', '$uibModalInstance', 'KendoUtilsService', 'CONVERSATION_VALIDATION_STATE', 
    function ($timeout, $uibModalInstance, KendoUtilsService, CONVERSATION_VALIDATION_STATE) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.valid = false;
    vm.validation = {
        note: '',
        validation: null
    };
    vm.resources = [];
    vm.CONVERSATION_VALIDATION_STATE = CONVERSATION_VALIDATION_STATE;

    //******************************************* Methods ***************************************\\
    vm.accept = function () {
        $uibModalInstance.close({ validation: vm.validation, resources: vm.resources });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        vm.valid = !!vm.validation.validation;
    };

    vm.onChange = function () {
        validateModel();
    };

    vm.selectValidationStatus = function (status) {
        if (vm.validation.validation !== status) {
            vm.validation.validation = status;
            vm.onChange();
        }
    };

    vm.attachFile = function () {
        document.getElementById('conversation-validation-resource').click();
    };

    vm.onFileSelected = function ($files) {
        if ($files.length > 0) {
            vm.resources.push($files[0]);
            vm.onChange();
        }
    };

    vm.removeAttachedFile = function (index) {
        if (vm.resources.length > index) {
            vm.resources.splice(index, 1);
        }
        vm.onChange();
    };

    function applyEditorChangesToTextModel() {
        $timeout(function() {
            vm.validation.note = $('#kendoTextEditor').data('kendoEditor').value();
            vm.onChange();
        });
    }

    function init() {
        vm.textareaOptions = KendoUtilsService.getTextareaOptions(applyEditorChangesToTextModel);
        vm.textareaOptions.tools.splice(0,1);
        vm.textareaOptions.tools.splice(3,2);
    }

    init();
}]);