'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.kendoUtilsService
 * @description
 * # kendoUtilsService
 */
angular.module('dialengaExeviBackoffice').service('KendoUtilsService',
    ['$location', '$rootScope', '$timeout', '$translate', 'ModalService', 'DialogService', 'defaultLanguage', 'LANGUAGES_LOCALES', 'IMAGE_FREE_CROPPER',
    'FILTER_MIN_CHARS', 'KENDO_VERSION', 'PAGE_SIZE',
    function ($location, $rootScope, $timeout, $translate, ModalService, DialogService, defaultLanguage, LANGUAGES_LOCALES, IMAGE_FREE_CROPPER,
        FILTER_MIN_CHARS, KENDO_VERSION, PAGE_SIZE) {

    var textareaOptions, textareaAddLinkEvent, textareaAddGifEvent;
    var mentionsModalOpened = false;
    this.gridPreferences = [];

    var dropDownListOptions = {
        dataSource: {
            transport: {},
            sort: { field: 'name', dir: 'asc' }
        },
        autoWidth: true,
        dataTextField: 'name',
        dataValueField: 'id',
        optionLabelTemplate: '<span class="black45" translate="global.form.notSet"></span>'
    };

    this.initializeKendoMessages = function (language) {
        var locale = LANGUAGES_LOCALES[language] || LANGUAGES_LOCALES[defaultLanguage];
        var kendoCultureLocale = kendo.culture();
        if (kendoCultureLocale.name !== locale) {
            kendo.culture(locale);
        }
    };

    function getDataSourceDefaultPage(gridDataSource) {
        var page = gridDataSource.page() || 1;
        var totalPages = gridDataSource.totalPages();
        return page <= totalPages ? page : totalPages;
    }

    this.reloadGridDatasource = function (gridDataSource, readOptions) {
        readOptions = readOptions || {};
        var defaultPage = getDataSourceDefaultPage(gridDataSource);
        readOptions.page = readOptions.page || defaultPage;
        readOptions.pageSize = readOptions.pageSize ? readOptions.pageSize : (gridDataSource.pageSize() || PAGE_SIZE);
        readOptions.sort = readOptions.sort ? readOptions.sort : gridDataSource.sort();

        return gridDataSource.query(readOptions);
    };

    this.dblClickGridRedirect = function (gridId, urlEdit) {
        this.dblClickGridCallback(gridId, function (dataItem) {
            $rootScope.$apply(function () {
                $location.path(urlEdit + dataItem.id);
            });
        });
    };

    this.dblClickGridCallback = function (gridId, callback) {
        $(document).ready(function () {
            $("#" + gridId).on("dblclick", "tbody>tr", function () {
                var dataItem = $("#" + gridId).data("kendoGrid").dataItem(this);
                callback(dataItem);
            });
        });
    };

    this.shouldSearchBeApplied = function (searchFieldValue) {
        return searchFieldValue.length >= FILTER_MIN_CHARS || searchFieldValue.length === 0;
    };

    this.hideGridPagerIfJustOnePage = function (kendoGrid) {
        if (kendoGrid.dataSource.totalPages() <= 1) {
            kendoGrid.pager.element.addClass('pager-no-links');
        } else {
            kendoGrid.pager.element.removeClass('pager-no-links');
        }
    };

    this.initializeGrid = function (columns, dataSource, options, events) {
        var kendoUtilsScope = this;
        // Wait until translation promise is resolved to assure translations are displayed correctly
        var currentLanguage = $translate.proposedLanguage() || $translate.use();
        // We replace any possible quote char ", in order to avoid problems trying to load languages named ""es"" i.e.
        currentLanguage = currentLanguage.replace(new RegExp("\"", 'g'), "");
        return $translate.use(currentLanguage).then(function () {
            if (!options) options = {};
            if (!events) events = {};
            return {
                autoBind: options.autoBind !== false, // set to true by default
                columns: columns,
                dataSource: dataSource,
                change: events.change,
                dataBound: events.dataBound || function() {
                    kendoUtilsScope.hideGridPagerIfJustOnePage(this);
                },
                filterable: options.filterable || false,
                scrollable: options.scrollable || false,
                selectable: options.selectable || false,
                sortable: options.sortable !== false,
                pageable: getPageable(options.pageable)
            };
        });
    };

    function getPageable(pageable) {
        if (pageable === false) {
            return false;
        } else if (pageable) {
            return pageable;
        } else {
            return {
                buttonCount: 5,
                info: true,
                messages: {
                    display: $translate.instant('global.lists.displayedItems'),
                    empty: $translate.instant('global.lists.empty'),
                    itemsPerPage: $translate.instant('global.lists.itemsPerPage'),
                    morePages: $translate.instant('global.lists.morePages')
                },
                pageSize: PAGE_SIZE,
                pageSizes: [5, 10, 20, 100]
            }
        }
    }

    function getSelectedString(selection) {
        if (selection.anchorOffset === selection.focusOffset) {
            return '';
        } else {
            var startPosition = Math.min(selection.anchorOffset, selection.focusOffset);
            var endPosition = Math.max(selection.anchorOffset, selection.focusOffset);
            var selectedText = selection.focusNode.data || selection.focusNode.wholeText;
            return selectedText.substring(startPosition, endPosition);
        }
    }

    function getSelection(editor) {
        var selectedNode = { text: null, url: null };
        var selection = editor.getSelection();
        if (selection && selection.focusNode) {
            if (selection.focusNode.parentNode && selection.focusNode.parentNode.href) {
                // If node is a link, get URL and its associated text
                selectedNode.url = selection.focusNode.parentNode.href;
                selectedNode.text = selection.focusNode.data || selection.focusNode.wholeText;
            } else {
                selectedNode.text = getSelectedString(selection);
            }
        } else {
            selectedNode.text = editor.selectedHtml();
        }
        return selectedNode;
    }

    function executeAddLinkEvent() {
        if (!!textareaAddLinkEvent && typeof textareaAddLinkEvent === 'function') {
            textareaAddLinkEvent();
        }
    }

    function executeAddGifEvent() {
        if (!!textareaAddGifEvent && typeof textareaAddGifEvent === 'function') {
            textareaAddGifEvent();
        }
    }

    this.initializeTextEditor = function () {
        textareaOptions = {
            pasteCleanup: {
                all: false,
                css: true,
                keepNewLines: true,
                msAllFormatting: true
            },
            stylesheets: [
                location.origin + "/ng1/assets/styles/kendo/kendo-editor.css",
                "https://fonts.googleapis.com/css?family=Roboto:400,500,700"
            ],
            keydown: function(event) {
                if (event.key === '@') {   // Check if mentions should be triggered
                    var previousPositionText = '';
                    var nodeActive = event.sender.window.getSelection();
                    // Get cursor position in active node
                    var cursorOffset = event.sender.window.getSelection().focusOffset || 0;
                    // Get previous word, character or space 
                    var textToArray = nodeActive.focusNode.textContent.substring(0, cursorOffset).match(/\w+|\s+|[^\s\w]+/g);
                    previousPositionText = textToArray && textToArray.length > 0 ? textToArray[textToArray.length - 1] : '';
                    previousPositionText = previousPositionText.trim();

                    if (!previousPositionText) {
                        event.preventDefault();
                        // if previous character to @ is blank or null, launch mentions
                        var mentions = $('#mentions-btn');
                        if (mentions) {
                            mentions.trigger('click');
                        }
                    }
                }
            },
            tools: [
                {
                    name: "formatting",
                    items: [{
                        text: $translate.instant('global.textarea.formatting.subtitle'),
                        value: "h2"
                    }, {
                        text: $translate.instant('global.textarea.formatting.subtitle2'),
                        value: "h3"
                    }, {
                        text: $translate.instant('global.textarea.formatting.paragraph'),
                        value: "p"
                    }, {
                        text: $translate.instant('global.textarea.formatting.quote'),
                        value: "blockquote"
                    }]
                },
                "bold",
                "italic",
                "underline",
                "insertUnorderedList",
                "insertOrderedList",
                {
                    name: 'addLink',
                    template: '<a tabindex="0" class="k-tool" role="button" title="{{\'global.editor.addLink\' | translate}}"><span unselectable="on" ' +
                        'class="k-tool-icon k-icon icon icon-link"></span></a>',
                    exec: function() {
                        var editor = $(this).data('kendoEditor');
                        var selection = getSelection(editor);
                        ModalService.openCreateLinkInKendoEditorModal({
                            selection: selection,
                            accept: function(value) {
                                // Disable temporarily 'keepNewLines' property to allow pasting html tags
                                editor.options.pasteCleanup.keepNewLines = false;
                                editor.paste(value);
                                editor.options.pasteCleanup.keepNewLines = true;
                                executeAddLinkEvent();
                            }
                        });
                    }
                },
                'unlink',
                {
                    name: 'addGif',
                    template: '<a id="image-btn" tabindex="0" class="k-tool" role="button" title="{{\'global.editor.insertImage\' | translate}}"><span unselectable="on" ' +
                        'class="k-tool-icon k-icon icon icon-18 icon-insert-image"></a>',
                    exec: function() {
                        var editor = $(this).data('kendoEditor');
                        // Disable temporarily 'keepNewLines' property to allow pasting html tags
                        editor.options.pasteCleanup.keepNewLines = false;
                        DialogService.openImageBank(IMAGE_FREE_CROPPER, false, true, true, true, true).subscribe(function(response) {
                            if(response && response.accepted) {
                                var img = $("<img csp-src='" + (response.data.imageUrl || response.data.animatedGifUrl) + "' src='" + (response.data.imageUrl || response.data.animatedGifUrl) + "'/>")[0];
                                editor.paste(img.outerHTML);
                                img.remove();
                                editor.options.pasteCleanup.keepNewLines = true;
                                executeAddGifEvent();                      
                            }
                        });
                    }
                },
                {
                    name: 'mentions',
                    template: '<button id="mentions-btn" tabindex="0" class="k-button k-tool" role="button" title="{{\'global.editor.insertMention\' | translate}}" unselectable="on"><span ' +
                        'class="k-tool-icon k-icon icon icon-mention-s"></span></button>',
                    exec: function(event) {
                        if (mentionsModalOpened) { 
                            return
                        } else {
                            mentionsModalOpened = true;
                        };

                        var editor = $(this).data('kendoEditor');
                        var selection = getSelection(editor);
                        $('#mentions-btn').addClass('k-state-selected');

                        ModalService.openAddMention({
                            selection: selection.text.trim(),
                            accept: function(value) {
                                // Disable temporarily 'keepNewLines' property to allow pasting html tags
                                editor.options.pasteCleanup.keepNewLines = false;
                                editor.options.pasteCleanup.msAllFormatting = false;
                                editor.paste(value);
                                editor.options.pasteCleanup.keepNewLines = true;
                                editor.options.pasteCleanup.msAllFormatting = true;
                                executeAddLinkEvent();
                                mentionsModalOpened = false;
                                $('#mentions-btn').removeClass('k-state-selected');
                            },
                            cancel: function() {
                                mentionsModalOpened = false;
                                $('#mentions-btn').removeClass('k-state-selected');
                                editor.focus();
                            }
                        });
                    }
                }
            ],
            messages: {
                bold: $translate.instant('global.editor.bold'),
                italic: $translate.instant('global.editor.italic'),
                underline: $translate.instant('global.editor.underline'),
                insertUnorderedList: $translate.instant('global.editor.insertUnorderedList'),
                insertOrderedList: $translate.instant('global.editor.insertOrderedList'),
                formatting: $translate.instant('global.editor.format'),
                createLink: $translate.instant('global.editor.addLink'),
                unlink: $translate.instant('global.editor.removeLink'),
                addGif: $translate.instant('global.editor.insertImage')

            },
            encoded: false
        };
    };

    this.initializeTooltip = function () {
        kendo.ui.Tooltip.fn._show = function (show) {
            return function (target) {
                var e = {
                    sender: this,
                    target: target,
                    preventDefault: function () {
                        this.isDefaultPrevented = true;
                    }
                };
                e.sender.options.position = 'top';

                // If Kendo Tooltip content is empty, prevent displaying it.
                if(!e.sender.options.content) {
                    e.preventDefault();
                }
        
                if (typeof this.options.beforeShow === "function") {
                    this.options.beforeShow.call(this, e);
                }

                if (!e.isDefaultPrevented) {
                    // Only show the tooltip if preventDefault() wasn't called.
                    show.call(this, target);
                }
            };
        }(kendo.ui.Tooltip.fn._show);
    };

    this.disableDropOnKendoEditor = function (id) {
        $timeout(function () { // wait for the component to be in the DOM
            var editor = $("#" + id).data("kendoEditor");
            if (editor) {
                $(editor.body).on("drop", function (event) {
                    event.preventDefault();
                });
            }
        }, 100);
    };

    this.disableTextEditor = function (editor) {
        if (editor && editor.body) {
            editor.body.contentEditable = false;
        }
    };

    this.getDropDownListOptions = function (autoSelectFirstItemIfEmpty) {
        var options = angular.copy(dropDownListOptions);
        options.dataBound = function autoSelectFirstItem(event) {
            if (!this.value()) {
                if (autoSelectFirstItemIfEmpty) {
                    // If empty, the first item is selected by default
                    this.select(0);
                }

                // Change the color for the placeholder in the select element
                // var selectPlaceholder = this._inputWrapper[0].children[0];
                // selectPlaceholder.style.color = '#8c8c8c';

                event.sender.trigger("change");
            }
        };
        return options;
    };

    this.getDropDownListOptionsAutoSelect = function (itemToSelect) {
        var options = angular.copy(dropDownListOptions);
        options.dataBound = function autoSelectItem(event) {
            if (!this.value()) {
                if (!!itemToSelect) {
                    // If empty, the item passed will be selected by default
                    var field = Object.entries(itemToSelect)[0][0];
                    var value = Object.entries(itemToSelect)[0][1];

                    var index = this.dataSource._pristineData.findIndex(function(item, index) {
                        return item[field] == value; 
                    });

                    this.select(index);
                    event.sender.trigger("change");
                }
            }
        };
        return options;
    };

    this.getTextareaOptions = function (changeCallbackFunction) {
        $timeout(function () {
            // Replace hard coded value in title attribute with a localized one
            $(".k-widget.k-dropdown.k-header.k-editor-widget").attr("title",
                $translate.instant('global.editor.format')
            );
        });
        var options = angular.copy(textareaOptions);
        if (!!changeCallbackFunction && typeof changeCallbackFunction === 'function') {
            options.keyup = changeCallbackFunction;
            options.execute = changeCallbackFunction;
            textareaAddLinkEvent = changeCallbackFunction;
            textareaAddGifEvent = changeCallbackFunction;
        }
        return options;
    };

    this.reverseGridDefaultSortDirection = function (gridId) {
        $timeout(function() {
            var colSortHeaders = $("#" + gridId + " [data-role='columnsorter']");
            $.each(colSortHeaders, function(index, item) {
                if ($(item).attr("data-dir") === undefined) {
                    $(item).attr("data-dir", "asc");
                } else if ($(item).attr("data-dir") === "asc") {
                    $(item).attr('data-dir', "desc");
                } else if ($(item).attr("data-dir") === "desc") {
                    $(item).attr('data-dir', null);
                }
            });
        });
    };

    this.getGridRequestOptions = function (options, defaultSortOrder, inputSearchField, inputFieldName) {
        var sort = this.getSortCriteria(options.data.sort, defaultSortOrder);
        var requestOptions = {
            page: options.data.page - 1,
            size: options.data.pageSize,
            sort: sort
        };

        if (options.data.state) {
            requestOptions.state = options.data.state;
        }

        if (options.data.kind) {
            requestOptions.kind = options.data.kind;
        }

        if (options.data.fileKind) {
            requestOptions.fileKind = options.data.fileKind;
        }

        if (options.data['publicationGrouping.id']) {
            requestOptions['publicationGrouping.id'] = options.data['publicationGrouping.id'];
        }

        if (inputSearchField && this.shouldSearchBeApplied(inputSearchField)) {
            requestOptions[inputFieldName] = inputSearchField;
        }

        return requestOptions;
    };

    this.getSortCriteria = function (sortOptions, defaultValue) {
        var sort = defaultValue;
        var currentPath = $location.path();
        if (sortOptions && sortOptions.length > 0) {
            if (sortOptions[0].field == "title") {
                sortOptions[0].field = "publicationContents.title";
            } else if (sortOptions[0].field == "category.name") {
                sortOptions[0].field = "publicationGrouping.publicationGroupingContents.name";
            } else if (sortOptions[0].field == "motive" && currentPath === '/settings/conversations/motives') {
                sortOptions[0].field = "contents.motive";
            } else if (sortOptions[0].field == "motiveInApp") {
                sortOptions[0].field = "contents.motiveInApp";
            }
            sort = sortOptions[0].field + "," + sortOptions[0].dir;
        }
        return sort;
    };

    this.resetGrid = function(gridId) {
        if (this.gridPreferences[gridId]) {
            this.gridPreferences[gridId] = null;
        }
    };

    this.resetGridBySection = function(section) {
        var gridId;
        switch (section) {
            case 'messages':
                this.resetGrid('messagesGrid');
                break;
            case 'questionnaires':
                this.resetGrid('questionnairesGrid');
                break;
            case 'knowledge-center':
                this.resetGrid('knowledgeCenterGrid');
                break;
            case 'import-documents':
                this.resetGrid('documentsHistoryGrid');
                break;
            case 'settings/conversations/feedback-trays':
                this.resetGrid('feedbackTraysGrid');
                break;
            case 'settings/categories':
                this.resetGrid('categoriesGrid');
                break;
            case 'settings/users-groups':
                this.resetGrid('segmentsGrid');
                break;
            case 'users':
                this.resetGrid('usersGrid');
                break;
            case 'user':
                this.resetGrid('usersGrid');
                break;
            case 'timeoff':
                this.resetGrid('timeoffRequestsGrid');
                break;
            case 'settings/timeoff':
                this.resetGrid('schemesGrid');
                this.resetGrid('workplacesGrid');
                this.resetGrid('timeoffTeamsGrid');
        }
        return gridId;
    };

    this.preserveGridFiltersIfDirectDescendant = function (previousPath, nextPath) {
        var previousPathSplit = previousPath.split('/');
        var nextPathSplit = nextPath.split('/');
        if (previousPathSplit.length < 2 || nextPathSplit.length < 2) {
            return;
        }
        var userViews = ['user', 'users'];
        var previousPathSection = (previousPathSplit[1] === 'settings' && previousPathSplit.length > 2) ? 'settings/' + previousPathSplit[2] : previousPathSplit[1];
        var nextPathSection = (nextPathSplit[1] === 'settings' && nextPathSplit.length > 2) ? 'settings/' + nextPathSplit[2] : nextPathSplit[1];
        if (previousPathSection !== nextPathSection && (userViews.indexOf(previousPathSection) === -1 || userViews.indexOf(nextPathSection) === -1)) {
            this.resetGridBySection(previousPathSection);
        }
    };

}]);
