(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewNotification
     * @description
     * # devicePreviewNotification
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewNotification', {
        bindings: {
            contentTitle: '<'
        },
        controllerAs: 'vm',
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-notification.html'
    });
})();
