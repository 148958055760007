'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ResetPasswordController
 * @description
 * # ResetPasswordController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ResetPasswordController',
    ['$rootScope', '$scope', '$location', 'UsersService',
    function ($rootScope, $scope, $location, UsersService) {

    //***************************************** Attributes **************************************\\
    $scope.resetPasswordForm = {
        user: {
            username: '',
            email: ''
        },
        isValid: true,
        isSubmitted: false
    };
    $scope.isEmailActive = true;

    //******************************************* Methods ***************************************\\
    var validateActiveInput = function(form) {
        if ($scope.isEmailActive) {
            $scope.resetPasswordForm.user.username = '';
            return form.email.$valid;
        } else {
            $scope.resetPasswordForm.user.email = '';
            return form.username.$valid;
        }
    };

    $scope.submit = function (form) {

        var isValid = validateActiveInput(form);
        $scope.resetPasswordForm.isValid = isValid;

        if (isValid) {

            UsersService.requestPasswordReset($scope.resetPasswordForm.user).then(function () {

                $location.path('email-sent');

            }, function () {
                $rootScope.$broadcast('dialenga:loadingEnd');
            });

        } else {
            $rootScope.$broadcast('dialenga:loadingEnd');
        }
    };

    $scope.selectEmailInput = function(active) {
        $scope.resetPasswordForm.isValid = true;
        $scope.isEmailActive = active;
    }
}]);
