'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemeGeneralModalController
 * @description
 * # TimeoffSettingsSchemeGeneralModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemeGeneralModalController', ['$timeout', '$uibModalInstance', 'params',
    function($timeout, $uibModalInstance, params) {
        var vm = this;

        vm.changed = false;
        vm.isValidFormMotive = false;

        vm.motive = {
            contents: {}
        };
        vm.modalName = '';

        vm.group = params.group;
        vm.selectedLanguage = params.selectedLanguage;

        vm.onChange = function() {
            vm.changed = true;
            validateModel();
        };

        vm.accept = function() {
            $uibModalInstance.close(vm.motive);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function validateModel() {
            var isValidName = vm.motive.contents[vm.selectedLanguage].name !== '';
            var isValidrequireNote = vm.motive.requiresNote ? (vm.motive.daysToAttachFile !== null && vm.motive.daysToAttachFile >= 0) : true;
            vm.isValidFormMotive = isValidName && isValidrequireNote;
        }

        function init() {
            $timeout(function() {
                vm.selectedLanguage = params.selectedLanguage;
            });
            if (params.motive) {
                vm.motive = angular.copy(params.motive);
                vm.modalName = 'schemes.absences.modal.titleUpdate';
                validateModel();
            } else {
                vm.modalName = 'schemes.absences.modal.titleCreate';
            }
        }

        init();
    }
]);