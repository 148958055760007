/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemeGeneralController
 * @description
 * # TimeoffSettingsSchemeGeneralController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemeGeneralController', ['$rootScope', '$scope', '$translate', 'Session', 'TimeoffSettingsSchemesService',
    'NotificationService', 'SCHEME_KIND_DAYS', 'SCHEME_PERIOD_ACTIVATION_KIND', 'PERIOD_STATES', 'ModalService',
    function ($rootScope, $scope, $translate, Session, TimeoffSettingsSchemesService,
        NotificationService, SCHEME_KIND_DAYS, SCHEME_PERIOD_ACTIVATION_KIND, PERIOD_STATES, ModalService) {
        var vm = this;

        vm.changed = false;
        vm.isValidFormGeneral = false;
        vm.SCHEME_KIND_DAYS = SCHEME_KIND_DAYS;
        vm.SCHEME_PERIOD_ACTIVATION_KIND = SCHEME_PERIOD_ACTIVATION_KIND;
        vm.PERIOD_STATES = PERIOD_STATES;
        vm.selectedLanguage = Session.companyDefaultLanguage;

        vm.periods = {
            currentPeriod: {},
            nextPeriod: {}
        }
        vm.automaticPeriodEnabled = {
            day: '',
            month: ''
        };
        vm.schemaForm = {
            schema: {
                workDays: {}
            }
        };

        vm.setKindDays = function(daysKind) {
            vm.schemaForm.schema.timeOffSchema.daysKind = daysKind;
            vm.onChange();
        };

        vm.setWorkingDays = function(day) {
            day.workable = !day.workable;
            vm.onChange();
        };

        vm.setActivationMode = function(activationMode) {
            vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind = activationMode;
            if (activationMode === vm.SCHEME_PERIOD_ACTIVATION_KIND.AUTOMATIC) {
                if (!vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodDay && !vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodMonth) {
                    vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodDay = vm.schemaForm.schema.timeOffSchema.startBearDay;
                    vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodMonth = vm.schemaForm.schema.timeOffSchema.startBearMonth;
                }
            }
            checkAutoPeriodEnabled();
            vm.onChange();
        };

        vm.setDaysOfFreeDisposal = function(enabled) {
            vm.schemaForm.schema.enableDaysOfFreeDisposal = enabled;
            vm.onChange();
        };

        vm.setAutomaticActivationModal = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-settings-scheme/timeoff-settings-scheme-general-modal.html',
                controller: 'TimeoffSettingsSchemeGeneralModalController',
                size: 'md',
                resolveParams: {

                },
                accept: function() {

                }
            });
        };

        vm.toggleActiveNextPeriod = function() {
            vm.schemaForm.schema.nextPeriod.periodState = vm.schemaForm.schema.nextPeriod.periodState === vm.PERIOD_STATES.ENABLED ? 
                                                          vm.PERIOD_STATES.DISABLED : vm.PERIOD_STATES.ENABLED;
        };

        vm.cancelAutomaticActivation = function() {
            vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind = vm.SCHEME_PERIOD_ACTIVATION_KIND.MANUAL;
        };

        vm.$onDestroy = function() {
            var schemaName = vm.schemaForm.schema.timeOffSchema.name;
            vm.schemaForm.schema = TimeoffSettingsSchemesService.latestSchemaGeneral.schema;
            vm.schemaForm.schema.timeOffSchema.name = schemaName;
        };

        vm.onChange = function() {
            vm.changed = dataHasChanged();
            validateModel();
        };

        vm.onChangeNextCalendarDateEnabled = function() {
            checkAutoPeriodEnabled();
            vm.onChange();
        };

        vm.onChangeAllowPersonalDays = function () {
            if (!vm.schemaForm.schema.timeOffSchema.allowPersonalDays) {
                vm.schemaForm.schema.timeOffSchema.personalDaysAllowed =  TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.personalDaysAllowed;
            }
            vm.onChange();
        };

        vm.onChangeAllowBearDays = function () {
            if (!vm.schemaForm.schema.timeOffSchema.allowBearRequests) {
                vm.schemaForm.schema.timeOffSchema.bearDaysAllowed = TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.bearDaysAllowed;
            }
            vm.onChange();
        }

        vm.onChangeAllowPendingDays = function () {
            if (!vm.schemaForm.schema.timeOffSchema.allowPendingDays) {
                vm.schemaForm.schema.timeOffSchema.pendingDaysAllowed = TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.pendingDaysAllowed;
                vm.schemaForm.schema.timeOffSchema.limitPendingDay = TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.limitPendingDay;
                vm.schemaForm.schema.timeOffSchema.limitPendingMonth = TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.limitPendingMonth;
            }
            vm.onChange();
        }

        vm.onChangeShowNoticeContents = function () {
            if (vm.schemaForm.schema.timeOffSchema.showNoticeContents && !vm.schemaForm.schema.timeOffSchema.noticeContents) {
                vm.schemaForm.schema.timeOffSchema.noticeContents = {};
            }
            vm.onChange();
        };

        function schemaHasChanged() {
            var generalForm = angular.copy(vm.schemaForm.schema);
            delete generalForm.timeOffSchema.name;
            return !angular.equals(generalForm, TimeoffSettingsSchemesService.latestSchemaGeneral.schema)
        }

        function dataHasChanged() {
            return schemaHasChanged() || !angular.equals(vm.workingDays, TimeoffSettingsSchemesService.latestSchemaGeneral.workingDays);
        }

        vm.goBack = function() {
            var isFormDirty = dataHasChanged() && vm.changed;
            $rootScope.goBackAndCheckForModifications(isFormDirty, 'settings/timeoff/schemes');
        };

        vm.saveSchemaGeneralForm = function() {
            vm.schemaForm.schema.timeOffSchema.name = $scope.$parent.vm.schemaForm.schema.timeOffSchema.name;
            parseWorkDays();
            vm.schemaForm.schema.timeOffSchema.noticeContents = vm.schemaForm.schema.timeOffSchema.showNoticeContents ? vm.schemaForm.schema.timeOffSchema.noticeContents : {};
            // vm.schemaForm.schema.freeDisposalDaysBalance = !vm.schemaForm.schema.freeDisposalDaysAllowed ? null : vm.schemaForm.schema.freeDisposalDaysBalance;
            vm.schemaForm.schema.timeOffSchema.personalDaysAllowed = vm.schemaForm.schema.timeOffSchema.allowPersonalDays ? vm.schemaForm.schema.timeOffSchema.personalDaysAllowed : null;
            TimeoffSettingsSchemesService.update(vm.schemaForm).then(function() {
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1000);
                $rootScope.$broadcast('dialenga:loadingEnd');
                vm.changed = false;
                TimeoffSettingsSchemesService.latestSchemaGeneral = {
                    schema: angular.copy(vm.schemaForm.schema),
                    workingDays: angular.copy(vm.workingDays)
                };
                delete TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.name;
            }, function () {
                $rootScope.$broadcast('dialenga:loadingEnd');
            });
        };

        function parseWorkDays() {
            vm.schemaForm.schema.timeOffSchema.workDays.mondayEnabled = vm.workingDays[0].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.tuesdayEnabled = vm.workingDays[1].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.wednesdayEnabled = vm.workingDays[2].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.thursdayEnabled = vm.workingDays[3].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.fridayEnabled = vm.workingDays[4].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.saturdayEnabled = vm.workingDays[5].workable;
            vm.schemaForm.schema.timeOffSchema.workDays.sundayEnabled = vm.workingDays[6].workable;
        }

        function getWednesdayAbbreviationForCurrentLanguage(value) {
            var currentLanguage = $translate.proposedLanguage() || $translate.use();
            return currentLanguage === 'es' ? 'X' : value.charAt(0);
        }

        function initWorkingDays() {
            var weekDays = moment.weekdays(true).map(function(day) {
                return day.charAt(0).toUpperCase() + day.slice(1);
            });
            vm.workingDays = [
                { id: 0, name: weekDays[0].charAt(0), tooltip: weekDays[0], workable: vm.schemaForm.schema.timeOffSchema.workDays.mondayEnabled },
                { id: 1, name: weekDays[1].charAt(0), tooltip: weekDays[1], workable: vm.schemaForm.schema.timeOffSchema.workDays.tuesdayEnabled },
                { id: 2, name: getWednesdayAbbreviationForCurrentLanguage(weekDays[2]), tooltip: weekDays[2], workable: vm.schemaForm.schema.timeOffSchema.workDays.wednesdayEnabled },
                { id: 3, name: weekDays[3].charAt(0), tooltip: weekDays[3], workable: vm.schemaForm.schema.timeOffSchema.workDays.thursdayEnabled },
                { id: 4, name: weekDays[4].charAt(0), tooltip: weekDays[4], workable: vm.schemaForm.schema.timeOffSchema.workDays.fridayEnabled },
                { id: 5, name: weekDays[5].charAt(0), tooltip: weekDays[5], workable: vm.schemaForm.schema.timeOffSchema.workDays.saturdayEnabled },
                { id: 6, name: weekDays[6].charAt(0), tooltip: weekDays[6], workable: vm.schemaForm.schema.timeOffSchema.workDays.sundayEnabled }
            ];
        }

        function validateModel() {
            var validAnnualLeaveDays = vm.schemaForm.schema.timeOffSchema.annualLeaveDays !== null && vm.schemaForm.schema.timeOffSchema.annualLeaveDays >= 0;
            
            var validBearDays = !vm.schemaForm.schema.timeOffSchema.allowBearRequests || 
                                (vm.schemaForm.schema.timeOffSchema.bearDaysAllowed !== null && vm.schemaForm.schema.timeOffSchema.bearDaysAllowed >= 0);
            
            var validPendingDays = !vm.schemaForm.schema.timeOffSchema.allowPendingDays ||
                                   (vm.schemaForm.schema.timeOffSchema.pendingDaysAllowed !== null && vm.schemaForm.schema.timeOffSchema.pendingDaysAllowed >= 0 
                                        && !!vm.schemaForm.schema.timeOffSchema.limitPendingDay 
                                        && !!vm.schemaForm.schema.timeOffSchema.limitPendingMonth);
            
            var validPersonalDays = !vm.schemaForm.schema.timeOffSchema.allowPersonalDays ||
                                    (vm.schemaForm.schema.timeOffSchema.personalDaysAllowed !== null && vm.schemaForm.schema.timeOffSchema.personalDaysAllowed > 0)

            vm.isValidFormGeneral = validAnnualLeaveDays && validBearDays && validPendingDays && validPersonalDays;
        }

        function checkPeriods() {
            if (vm.schemaForm.schema.currentPeriod) {
                var currentPeriodStartYear = new Date(vm.schemaForm.schema.currentPeriod.firstDayOfYear).getFullYear();
                var currentPeriodEndYear = new Date(vm.schemaForm.schema.currentPeriod.lastDayOfYear).getFullYear();
                vm.periods.currentPeriod.period = currentPeriodStartYear !== currentPeriodEndYear ? currentPeriodStartYear.toString() + '-' + currentPeriodEndYear.toString() : currentPeriodStartYear.toString();
            }

            if (vm.schemaForm.schema.nextPeriod) {
                var nextPeriodStartYear = vm.schemaForm.schema.nextPeriod ? new Date(vm.schemaForm.schema.nextPeriod.firstDayOfYear).getFullYear() : null;
                var nextPeriodEndYear = vm.schemaForm.schema.nextPeriod ? new Date(vm.schemaForm.schema.nextPeriod.lastDayOfYear).getFullYear() : null;
                var defaultNextPeriod = nextPeriodStartYear ? nextPeriodStartYear.toString() : null;
                vm.periods.nextPeriod.period = nextPeriodStartYear !== nextPeriodEndYear ? nextPeriodStartYear.toString() + '-' + nextPeriodEndYear.toString() : defaultNextPeriod;
            }
        }

        function checkAutoPeriodEnabled() {
            if(vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind === vm.SCHEME_PERIOD_ACTIVATION_KIND.AUTOMATIC) {
                vm.automaticPeriodEnabled.day = vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodDay;
                vm.automaticPeriodEnabled.month = moment.months(vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.startNextPeriodMonth - 1);
            }
        }

        function checkAditionalOptions() {
            var advice = vm.schemaForm.schema.timeOffSchema.noticeContents;
            vm.schemaForm.schema.timeOffSchema.showNoticeContents = !!advice && !!advice[vm.selectedLanguage] && !!advice[vm.selectedLanguage].notice;
        }

        function init() {
            $scope.$parent.$watch('vm.schemaForm.schema', function(newValue) {
                if (newValue.timeOffSchema.id) {
                    vm.schemaForm = $scope.$parent.vm.schemaForm;
                    initWorkingDays();
                    checkPeriods();
                    checkAutoPeriodEnabled();
                    checkAditionalOptions();
                    TimeoffSettingsSchemesService.latestSchemaGeneral = {
                        schema: angular.copy(vm.schemaForm.schema),
                        workingDays: angular.copy(vm.workingDays)
                    };
                    delete TimeoffSettingsSchemesService.latestSchemaGeneral.schema.timeOffSchema.name;
                    validateModel();
                }
            });
        }

        init();
    }
]);