'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ProfileController
 * @description
 * # ProfileController
 */
angular.module('dialengaExeviBackoffice').controller('ProfileController',
    ['$rootScope', 'GlobalService', 'LocationService', 'Session', 'UsersService', 'FeedbackTraysService', 'NOTIFICATION_CHANNEL', 'STRENGTH_TYPE', 'USER_ROLES', 
     'USER_ALLOWED_DOCS_KIND', 'AVAILABLE_FEEDS', 'ALLOWED_CATEGORIES_KIND', 'PUSH_OPTION',
    function ($rootScope, GlobalService, LocationService, Session, UsersService, FeedbackTraysService, NOTIFICATION_CHANNEL, STRENGTH_TYPE, USER_ROLES, 
              USER_ALLOWED_DOCS_KIND, AVAILABLE_FEEDS, ALLOWED_CATEGORIES_KIND, PUSH_OPTION) {

        var vm = this;

        var DEFAULT_COUNTRY_CODE = 34;
        vm.NOTIFICATION_CHANNEL = NOTIFICATION_CHANNEL;
        vm.USER_ROLES = USER_ROLES;
        vm.USER_ALLOWED_DOCS_KIND = USER_ALLOWED_DOCS_KIND;
        vm.AVAILABLE_FEEDS = AVAILABLE_FEEDS;
        vm.PUSH_OPTION = PUSH_OPTION;
        vm.ALLOWED_CATEGORIES_KIND = ALLOWED_CATEGORIES_KIND;

        vm.editorRoleHasRestrictions = false;
        vm.docManagerHasRestrictions = false;

        vm.traysResponsible = [];

        vm.availableRoles = [
            USER_ROLES.admin,
            USER_ROLES.publisher,
            USER_ROLES.manager,
            USER_ROLES.employeeManager,
            USER_ROLES.humanResources,
            USER_ROLES.documentsManager,
            USER_ROLES.ftrayManager,
            USER_ROLES.chatroomManager,
            USER_ROLES.ftrayResponsible,
            USER_ROLES.teamResponsible
        ];

        vm.userForm = {
            user: {
                roles: [],
                phoneNumber: {
                    countryCode: { code: DEFAULT_COUNTRY_CODE }
                }
            },
            passwordInputType: "password",
            currentPasswordInputType: "password",
            newPasswordInputType: "password",
            isUsernameEditable: false,
            noRolesSpecified: undefined,
            valid: true,
            isValidNotificationChannel: true
        };

        vm.passwordStrength = {
            uppercase: false,
            lowercase: false,
            numbers: false,
            specialChar: false,
            minLength: false
        };
        vm.passwordStrengthValue = 0;
        vm.requestingData = true;

        function parsePhoneNumberIfPresent() {
            if (vm.userForm.user.phoneNumber && vm.userForm.user.phoneNumber.number) {
                vm.userForm.user.phoneNumber.number = vm.userForm.user.phoneNumber.number.replace(/\s+/g, '');
            } else {
                vm.userForm.user.phoneNumber = { countryCode: { code: DEFAULT_COUNTRY_CODE } };
            }
        }

        function isValidNotificationChannel(user) {
            var valid = true;
            switch ($rootScope.account.notificationChannel) {
                case NOTIFICATION_CHANNEL.EMAIL:
                    valid = !!user.email && user.email !== '';
                    break;
                case NOTIFICATION_CHANNEL.SMS:
                    valid = !!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '';
                    break;
                case NOTIFICATION_CHANNEL.ANY:
                    valid = (!!user.email && user.email !== '') || (!!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '');
                    break;
                case NOTIFICATION_CHANNEL.BOTH:
                    valid = (!!user.email && user.email !== '') && (!!user.phoneNumber && !!user.phoneNumber.number && user.phoneNumber.number !== '');
                    break;
                default:
                    break;
            }
            return valid;
        }

        function updateUserProfile(userForm) {
            UsersService.editProfile(userForm)
                .then(function (response) {
                    Session.update(response.data);
                    if (userForm.user.language !== UsersService.latestProfile.user.language) {
                        $rootScope.setLanguage(userForm.user.language);
                    }
                    GlobalService.redirectUserToHomeBasedOnRoles();
                });
        }

        vm.saveUser = function (isFormValid) {
            var isFormDirty = dataHasChanged();
            if (!isFormDirty) {
                GlobalService.redirectUserToHomeBasedOnRoles();
            } else {
                vm.userForm.isValidNotificationChannel = isValidNotificationChannel(vm.userForm.user);
                vm.userForm.valid = isFormValid && vm.userForm.isValidNotificationChannel && (!Session.multiCountry || !!vm.userForm.user.country);
                if (!vm.userForm.valid) {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                    return;
                }

                parsePhoneNumberIfPresent();
                updateUserProfile(vm.userForm);
            }
        };

        vm.showHidePassword = function (fieldName) {
            vm.userForm[fieldName] = GlobalService.showHidePassword(vm.userForm[fieldName]);
        };

        vm.toggleCollapse = function () {
            vm.userForm.user.changePassword = !vm.userForm.user.changePassword;
        };

        function dataHasChanged() {
            return !angular.equals(vm.userForm.user, UsersService.latestProfile.user);
        }

        vm.goBack = function() {
            var isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(isFormDirty, '/', function () {
                GlobalService.redirectUserToHomeBasedOnRoles();
            });
        };
        
        vm.validatePasswordStrength = function () {
            vm.passwordStrengthValue = UsersService.validatePasswordStrength(vm.userForm.user.newPassword, vm.passwordStrength);
        };

        vm.isProfileFormValid = function (isFormValid) {
            return isFormValid && (!vm.userForm.user.changePassword || (!!vm.userForm.user.newPassword && vm.userForm.user.newPassword.length > 0 && vm.passwordStrengthValue === STRENGTH_TYPE.VALID));
        };

        function userHasRole(role) {
            for (var index = 0; index < vm.userForm.user.roles.length; index++) {
                if (vm.userForm.user.roles[index].name === role) {
                    return true;
                }
            }
            return false;
        };

        function checkEditorRestrictions() {
            vm.editorRoleHasRestrictions = !vm.userForm.user.editorPermissions.publishContentAllowed ||
                                           !vm.userForm.user.editorPermissions.viewStatisticsAllowed ||
                                           !vm.userForm.user.editorPermissions.highlightContentAllowed ||
                                           (vm.userForm.user.editorPermissions.pushOptions == PUSH_OPTION.ALWAYS_OFF) ||
                                           (vm.userForm.user.editorPermissions.allowedCategoriesKind !== ALLOWED_CATEGORIES_KIND.ALL);
        }

        function checkdocsManagerRestrictions() {
            vm.docManagerHasRestrictions = vm.userForm.user.documentManagerPermissions.allowanceKind !== USER_ALLOWED_DOCS_KIND.ALL;
        }

        function copyInitialUserInfo() {
            if (!vm.userForm.user.phoneNumber) {
                vm.userForm.user.phoneNumber = { countryCode: { code: null } };
            }
            UsersService.latestProfile.user = angular.copy(vm.userForm.user);
        }

        function init() {
            UsersService.latestProfile = [];
            vm.userLanguagesOptions = UsersService.getUserLanguagesOptions();
            vm.userCountryCodesOptions = LocationService.getCountryCodesOptions();
            vm.userNotificationChannel = $rootScope.account.notificationChannel;
            
            UsersService.getProfile().then(function (response) {
                vm.userForm.user = response.data;
                vm.requestingData = false;
                vm.userForm.user.changePassword = false;
                vm.settedRoles = vm.availableRoles.filter(function(role) {
                    return userHasRole(role)
                });
                if (userHasRole(USER_ROLES.ftrayResponsible) && !userHasRole(USER_ROLES.admin)) {
                    FeedbackTraysService.findAllResponsible().then(function(response) {
                        vm.traysResponsible = response.data;
                    });
                }
                if (userHasRole(USER_ROLES.manager)) {
                    checkEditorRestrictions();
                }
                if (userHasRole(USER_ROLES.documentsManager)) {
                    checkdocsManagerRestrictions();
                }
                copyInitialUserInfo();
            });
        }

        init();
    }
]);
