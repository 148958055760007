'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffRequestModalController
 * @description
 * # TimeoffRequestModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffRequestModalController',
    ['$rootScope', '$filter', '$scope', '$uibModalInstance', '$translate', 'DateUtilsService', 'TimeoffService', 'NotificationService', 'Session', 'UsersService', 'TIMEOFF_KIND', 'TIMEOFF_REQUEST_STATUS', 'ABSENCE_MOTIVE_GROUPS',
    function ($rootScope, $filter, $scope, $uibModalInstance, $translate, DateUtilsService, TimeoffService, NotificationService, Session, UsersService, TIMEOFF_KIND, TIMEOFF_REQUEST_STATUS, ABSENCE_MOTIVE_GROUPS) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.changed = false;
    vm.valid = false;
    vm.request = {
        kind: TIMEOFF_KIND.HOLIDAYS,
        stateId: TIMEOFF_REQUEST_STATUS.PENDING,
        partial: false,
        halfDay: false,
        notificationEnabled: false,
        hours: null,
        minutes: null,
    };
    vm.attachedFiles = [];
    vm.rangeInstance = {};
    vm.allowHalfDay = Session.allowHalfDay;
    vm.holidaysAvailableDays = 0;
    vm.absencesAvailableDays = 0;

    vm.TIMEOFF_KIND = TIMEOFF_KIND;
    vm.TIMEOFF_REQUEST_STATUS = TIMEOFF_REQUEST_STATUS;
    vm.ABSENCE_MOTIVE_GROUPS = ABSENCE_MOTIVE_GROUPS;

    vm.timeoffRequestKind = [
        { id: TIMEOFF_KIND.HOLIDAYS, title: 'timeoff.requests.kindFilters.holidays' },
        { id: TIMEOFF_KIND.ABSENCE, title: 'timeoff.requests.kindFilters.absences' }
    ]; 

    //******************************************* Methods ***************************************\\
    vm.accept = function (saveAndClose) {
        vm.request.employeeId = vm.request.employee.id;
        vm.request.hours = vm.request.hours === null ? 0 : vm.request.hours;
        vm.request.minutes = vm.request.minutes === null ? 0 : vm.request.minutes;

        TimeoffService.createTimeoffRequestAsEmployee(vm.request, vm.attachedFiles).then(function () {
            if (saveAndClose) {
                $uibModalInstance.close(vm.request);
            } else {
                $rootScope.$broadcast('dialenga:refreshTimeoffRequestsGridAndNotification', {request: vm.request});
                $rootScope.$broadcast('dialenga:loadingEnd');
                resetFormData();
            }
        }, function (error) {
            if (error.data && error.data.localizedError) {
                console.error(error.data.localizedError);
            }
            $rootScope.$broadcast('dialenga:loadingEnd');
            var message = $translate.instant('global.messages.error.unknown');
            NotificationService.notifyError(message);
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    function validatePartialTime() {
        if(!vm.request.partial) {
            return true;
        } else {
            if(vm.request.hours > 0 && vm.request.hours < 8) {
                if((vm.request.minutes >= 0 && vm.request.minutes < 60) || vm.request.minutes === null) {
                    return true;
                } 
            } else {
                if(vm.request.minutes > 0 && vm.request.minutes < 60) {
                    return true;
                }
            }
        }
    }

    var validateModel = function () {
        vm.valid = false;
        if (vm.request.kind === TIMEOFF_KIND.HOLIDAYS) {
            if (!!vm.userSummaryHolidays && !!vm.request.days) {
                vm.valid = !!vm.userSummaryHolidays.consumed.availableDays && (parseFloat(vm.request.days) <= parseFloat(vm.userSummaryHolidays.consumed.availableDays));
            }
        } else {
            if (!!vm.request.employee) {
                if (vm.request.motive.groupKind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
                    vm.valid = !!vm.request.employee.id && !!vm.request.motive && !!vm.request.motive.id && !!vm.request.days && validatePartialTime();
                } else {
                    vm.valid = !!vm.request.employee.id && !!vm.request.motive && !!vm.request.motive.id && !!vm.request.days && !!vm.userSummaryAbsences.personalDays.availableDays && (parseFloat(vm.request.days) <= parseFloat(vm.userSummaryAbsences.personalDays.availableDays));
                }
            }
        }
    };

    vm.onChange = function () {
        vm.changed = true;
        validateModel();
    };

    vm.setKindRequest = function(kind) {
        vm.request.kind = kind.id;
        vm.onChange();
    };

    vm.setStateRequest = function(state) {
        vm.request.stateId = state;
        vm.onChange();
    }

    function getUserSummaryHolidays() {
        TimeoffService.getUserSummaryHolidays(vm.request.employee.id).then(function(response) {
            vm.userSummaryHolidays = response.data;
            vm.holidaysAvailableDays = $filter('number')(vm.userSummaryHolidays.consumed.availableDays);
            $('#motives-dropdown').data('kendoDropDownList').dataSource.read({schemaId: vm.userSummaryHolidays.schema.id});
            validateModel();
        });
    }

    function getUserSummaryAbsences() {
        TimeoffService.getUserSummaryAbsences(vm.request.employee.id).then(function(response) {
            vm.userSummaryAbsences = response.data;
            vm.absencesAvailableDays = $filter('number')(vm.userSummaryAbsences.personalDays.availableDays);
            validateModel();
        });
    }

    function resetFormData(keepEmployee) {
        vm.valid = false;
        vm.request.kind= TIMEOFF_KIND.HOLIDAYS;
        vm.request.stateId= TIMEOFF_REQUEST_STATUS.PENDING;
        vm.request.partial= false;
        vm.request.halfDay= false;
        vm.request.hours= null;
        vm.request.minutes= null;
        vm.request.notificationEnabled= false;
        vm.request.startDate = null;
        vm.request.endDate = null;
        vm.request.days = 0;
        vm.attachedFiles = [];
        vm.rangeInstance = {};
        vm.holidaysAvailableDays = 0;
        vm.absencesAvailableDays = 0;
        vm.request.comments = '';
        vm.request.motive = null;

        if(!keepEmployee) {
            delete vm.request.employee;
        }
    }

    vm.openRangeSelection = function () {
        var isEndDateShown = (vm.request.kind === TIMEOFF_KIND.HOLIDAYS && !vm.request.halfDay) || (vm.request.kind === TIMEOFF_KIND.ABSENCE && !vm.request.partial);
        if (isEndDateShown) {
            vm.rangeInstance.setActiveDate('start');
        }

        vm.rangeInstance.option({
            showSelector: isEndDateShown
        });
        vm.rangeInstance.show();
    };

    vm.togglePartialChange = function () {
        if (vm.request.partial && vm.request.startDate) {
            vm.request.endDate = angular.copy(vm.request.startDate);
            vm.rangeInstance.setVal([vm.request.startDate, vm.request.endDate]);
        }
        if (!vm.request.partial) {
            vm.request.hours = 0;
            vm.request.minutes = 0;
        }
        vm.dateRangeUpdated();
        vm.onChange();
    };

    vm.toggleHalfDayChange = function () {
        if (vm.request.halfDay && vm.request.startDate) {
            vm.request.endDate = angular.copy(vm.request.startDate);
            vm.rangeInstance.setVal([vm.request.startDate, vm.request.endDate]);
        }
        vm.dateRangeUpdated();
        vm.onChange();
    };

    vm.dateRangeUpdated = function () {
        vm.periodAsString = null;
        var selectedRange = vm.rangeInstance.getVal();
        if (selectedRange && selectedRange.length === 2 && selectedRange[0]){
            vm.request.startDate = new Date(selectedRange[0]);

            if (!selectedRange[1] || (vm.request.halfDay && vm.request.kind === TIMEOFF_KIND.HOLIDAYS) || (vm.request.partial && vm.request.kind === TIMEOFF_KIND.ABSENCE)) {
                vm.request.endDate = angular.copy(vm.request.startDate);
            } else {
                vm.request.endDate = new Date(selectedRange[1]);
                vm.request.halfDay = false;
                vm.request.partial = false;
            }

            var startDateTimestamp = vm.request.startDate.getTime();
            var endDateTimestamp = vm.request.endDate.getTime();
            if ((vm.request.kind === TIMEOFF_KIND.HOLIDAYS && !vm.request.halfDay) || (vm.request.kind === TIMEOFF_KIND.ABSENCE && !vm.request.partial)) {
                if (startDateTimestamp > endDateTimestamp) {
                    vm.request.endDate = angular.copy(vm.request.startDate);
                    endDateTimestamp = angular.copy(startDateTimestamp);
                }
            }
            vm.periodAsString = DateUtilsService.getFormattedPeriod(vm.request.startDate, vm.request.endDate);
            if (vm.request.kind === TIMEOFF_KIND.HOLIDAYS && vm.request.halfDay) {
                vm.request.days = $filter('number')(0.5);
                vm.onChange();
            } else {
                TimeoffService.getNumberOfWorkingDays(startDateTimestamp, endDateTimestamp, vm.request.employee.id).then(function (response) {
                    vm.request.days = response.data.days;
                    vm.onChange();
                });
            }
        }
    };

    vm.attachFile = function () {
        document.getElementById('timeoff-request-file').click();
    };

    vm.onFileSelected = function ($files) {
        if ($files.length > 0) {
            vm.attachedFiles.push($files[0]);
        }
    };

    vm.removeAttachedFile = function (index) {
        if (vm.attachedFiles.length > index) {
            vm.attachedFiles.splice(index, 1);
        }
    };

    $scope.$watch('vm.request.employee', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (!!newValue && newValue.id) {
                if (!!oldValue && oldValue.id) {
                    resetFormData(true);
                }
                getUserSummaryHolidays();
                getUserSummaryAbsences();
            } else if (!!vm.userSummaryHolidays) {
                resetFormData();
                vm.userSummaryHolidays.consumed.availableDays = '';
                $('#motives-dropdown').data('kendoDropDownList').dataSource.read();
                validateModel();
            }
        }
    });

    vm.setDateRangeError = function (event) {
        vm.calendarErrorMessage = event.message;
    };

    var init = function() {
        vm.motivesOptions = TimeoffService.getMotivesOptions();
        var employeeFilter = !!vm.request.employee ? vm.request.employee.name : '';
        vm.employeeOptions = UsersService.getEmployeeOptions(employeeFilter);
    };

    init();
}]);