/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsWorkplaceHolidaysModalController
 * @description
 * # TimeoffSettingsWorkplaceHolidaysModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsWorkplaceHolidaysModalController',
    ['$uibModalInstance', 'params',
    function($uibModalInstance, params) {
        var vm = this;
        vm.changed = false;

        vm.onChange = function () {
            vm.changed = true;
        };

        vm.accept = function() {
            $uibModalInstance.close(vm.holidayEditForm);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function init() {
            vm.holidayEditForm = params.holidays;
        }

        init();

    }
]);