(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:ratingIcon
     * @description
     * # ratingIcon
     */
    angular.module('dialengaExeviBackoffice').directive('ratingIcon', ['$rootScope', 'RATING_KIND',
        function ($rootScope, RATING_KIND) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                value: '=',
                showTitle: '=?'
            },
            template: '<div class="rating-icon-container" title="{{showTitle ? value : \'\'}}">' +
                '<span class="rating-icon rating-image" data-ng-class="\'emoji-\' + getRatingValue(value)" data-ng-show="ratingKind === kinds.EMOJI"></span>' +
                '<div class="stars" data-ng-show="ratingKind === kinds.STAR">' +
                    '<span data-ng-repeat="starClass in starsClasses track by $index" data-ng-class="starClass"></span>' +
                '</div>' +
            '</div>',
            controller: ['$scope', function ($scope) {
                $scope.kinds = RATING_KIND;
                $scope.ratingKind = $rootScope.account.ratingKind;

                $scope.getRatingValue = function(value) {
                    var ratingValue = Math.round(value);
                    if ($rootScope.account && $rootScope.account.ratingKind === RATING_KIND.STAR) {
                        var classes = [];
                        for (var index = 1; index <= 5; index++) {
                            if (ratingValue >= index) {
                                classes.push('icon icon-star-fill');
                            } else {
                                classes.push('icon icon-star');
                            }
                        }
                        $scope.starsClasses = classes;
                    }
                    return ratingValue;
                }
            }]
        };
    }]);

})();
