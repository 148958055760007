/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsSchemeAbsencesController
 * @description
 * # TimeoffSettingsSchemeAbsencesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsSchemeAbsencesController',
    ['$scope', '$translate', 'TimeoffSettingsSchemesService','NotificationService', 'ModalService', 'ABSENCE_MOTIVE_GROUPS',
    function($scope, $translate, TimeoffSettingsSchemesService, NotificationService, ModalService, ABSENCE_MOTIVE_GROUPS) {
        var vm = this;
        vm.schemaId = $scope.$parent.vm.schemaId;
        vm.ABSENCE_MOTIVE_GROUPS = ABSENCE_MOTIVE_GROUPS;

        vm.schemaAbsencesForm = {
            content: []
        };

        vm.updateAbsenceMotive = function(group, motive) {
            var defaultLanguage = '';
            if(!motive) {
                defaultLanguage = $scope.$parent.vm.defaultLanguage;
            } else {
                defaultLanguage = $scope.selectedLanguage;
            }
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-settings-scheme/timeoff-settings-scheme-absences-modal.html',
                controller: 'TimeoffSettingsSchemeAbsencesModalController',
                size: 'lg',
                resolveParams: {
                    motive: motive,
                    group: group,
                    selectedLanguage: defaultLanguage
                },
                accept: function (motiveUpdated) {
                    var promise;
                    if (motiveUpdated.id) {
                        promise = TimeoffSettingsSchemesService.updateAbsenceMotiveInSchema($scope.$parent.vm.schemaId, group.id, motiveUpdated);
                    } else {
                        promise = TimeoffSettingsSchemesService.createAbsenceMotiveInSchema($scope.$parent.vm.schemaId, group.id, motiveUpdated);
                    }
                    promise.then(function() {
                        initializeAbsencesMotives();
                        var message = $translate.instant('global.messages.changesUpdated.success');
                        NotificationService.notifySuccess(message, 2000);
                    })
                }
            }); 
        };

        vm.toggleActiveMotive = function(group, motive) {
            motive.enabled = !motive.enabled;
            TimeoffSettingsSchemesService.updateMotiveStatus($scope.$parent.vm.schemaId, group.id, motive.id, motive.enabled).then(function() {
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);
            });
        };

        function initializeAbsencesMotives() {
            TimeoffSettingsSchemesService.findSchemaAbsences(vm.schemaId).then(function (response) {
                vm.schemaAbsencesForm.content = response.data;
                TimeoffSettingsSchemesService.schemaAbsencesTemp = angular.copy(response.data);
            });
        }

        function init() {
            if (vm.schemaId) {
                initializeAbsencesMotives();
            }
        }
        
        init();
    }
]);