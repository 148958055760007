'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayQuestionnaireUpdateController
 * @description
 * # FeedbackTrayQuestionnaireUpdateController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayQuestionnaireUpdateController', 
    ['$scope', '$rootScope', '$routeParams', '$translate', '$location', 'ModalService', 'FeedbackTraysService', 'QUESTIONNAIRE_KIND', 'TRAY_QUESTION_KIND',
    function($scope, $rootScope, $routeParams, $translate, $location, ModalService, FeedbackTraysService, QUESTIONNAIRE_KIND, TRAY_QUESTION_KIND) {

        $scope.feedbackTrayId = $routeParams.id;
        $scope.currentLanguage = $translate.use();
        $scope.globalCollapsed = false;
        $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;

        $scope.questionnaire = {
            fields: []
        }

        // ******** Methods *****************************************************************
        $scope.collapsePanel = function(item, $event){
            $event.preventDefault();
            $event.stopPropagation();
            item.isCollapsed = !item.isCollapsed;
            var items = $scope.questionnaire.fields;
            var notCollapsedItems = items.filter(function(item){
                return !item.isCollapsed;
            });
            $scope.globalCollapsed = notCollapsedItems.length === 0;
        };

        $scope.globalCollapse = function(){
            $scope.globalCollapsed = !$scope.globalCollapsed;
            var numQuestions = $scope.questionnaire.fields.length;
            for (var i = 0; i< numQuestions; i++) {
                $scope.questionnaire.fields[i].isCollapsed = $scope.globalCollapsed;
            }
        };

        $scope.isPreviewVisibleFn = function(visibility) {
            return $scope.isPreviewVisible = visibility;
        }

        $scope.goBackAndEnableQuestionnaire = function(enable) {
            if (enable) {
                FeedbackTraysService.enableQuestionnaire($scope.feedbackTrayId);
            }
            $location.path('/settings/conversations/feedback-trays/questionnaire/' + $scope.feedbackTrayId);
        }

        $scope.addEnding = function() {
            FeedbackTraysService.newContentKind = TRAY_QUESTION_KIND.ENDING;
            $location.path('/settings/conversations/feedback-trays/questionnaire/' + $routeParams.id + '/endings/');
        }

        $scope.editEnding = function(endingId) {
            $location.path('/settings/conversations/feedback-trays/questionnaire/' + $routeParams.id + '/endings/' + endingId);
        }

        $scope.deleteEnding = function(endingId) {
            ModalService.openConfirmationModal({
                title: 'global.form.delete',
                message: 'questionnaire.modal.delete.ending',
                focusCancel: true,
                hideCancelButton: false,
                accept: function () {
                    FeedbackTraysService.deleteQuestion($scope.feedbackTrayId, endingId, 'questionnaire.question.error.delete').then(function() {
                        $rootScope.$broadcast('dialenga:reloadQuestionnairesList');
                    });
                }
            });
        }

        var getQuestionnaireTray = function() {
            FeedbackTraysService.findQuestionnaire($scope.feedbackTrayId).then(function(response) {
                $scope.questionnaire = response.data;
                if ($scope.questionnaire) {
                    $scope.items = FeedbackTraysService.updateQuestionnaireItemsAndLinkedStatus($scope.questionnaire, $scope.currentLanguage);
                }
            });
            
        };
        var init = function() {
            getQuestionnaireTray();
        }

        init();

        $rootScope.$on('dialenga:reloadQuestionnairesList', function() {
            init();
        });
    
        $rootScope.$on('dialenga:collapseQuestionPanelInTray', function(event, args) {
            $scope.collapsePanel(args.item, args.event);
        });
    }
]);