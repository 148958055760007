'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserSegmentsModalController
 * @description
 * # UserSegmentsModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserSegmentsModalController',
    ['$scope', '$timeout', '$uibModalInstance', 'ArrayUtilsService', 'SegmentService', 'params',
    function ($scope, $timeout, $uibModalInstance, ArrayUtilsService, SegmentService, params,) {
        var vm = this;

        vm.totalAvailableItems = 0;
        vm.addedSegments = [];
        // vm.selectedSegments = [];
        vm.addedSegmentsIds = [];
        vm.arePendingChanges = false;

        vm.globalSearchOpened = false;
        vm.groupSearchOpened = false;

        vm.filter = {
            availableSegments: '',
            addedSegments: ''
        };

        var initialAddedSegmentsIds;

        $scope.$watchCollection('vm.addedSegmentsIds', function (newValue) {
            if (newValue) {
                vm.arePendingChanges = !ArrayUtilsService.areEquals(initialAddedSegmentsIds, newValue);
            }
        });

        vm.accept = function () {
            var segments = vm.arePendingChanges ? vm.addedSegments : null;
            $uibModalInstance.close(segments);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function setAvailableSegments(data) {
            vm.availableSegments = data.content;
            vm.totalAvailableItems = data.numberOfElements;
        }

        function loadAvailableSegments() {
            var options = {
                name: vm.filter.availableSegments,
                active: true,
                page: 0,
                size: 20000,
                sort: 'name'
            };
            getAllAvailableSegments(options);
        }

        function getAllAvailableSegments(options) {
            vm.isSyncing = true;
            SegmentService.findAll(options).then(function (response) {
                vm.isSyncing = false;
                if (options.name === vm.filter.availableSegments) {
                    setAvailableSegments(response.data);
                }
            });
        }

        vm.searchAvailableSegments = function(clearInput) {
            if(clearInput) { vm.filter.availableSegments = ''; document.getElementById('global-search').focus();}
       
            if(vm.filter.availableSegments.length === 0) { vm.selectedSegments = []; }

            if (vm.filter.availableSegments.length >= 3 || vm.filter.availableSegments.length === 0) {
                loadAvailableSegments();
            }
        };

        vm.globalSearchOpen = function() {
            vm.globalSearchOpened = !vm.globalSearchOpened;
            $timeout(function() {
                document.getElementById('global-search').focus();
            }, 500);
        }

        vm.groupSearchOpen = function() {
            vm.groupSearchOpened = !vm.groupSearchOpened;
            $timeout(function() {
                document.getElementById('group-search').focus();
            }, 100);
        }

        vm.clearGroupSearch = function() {
            vm.filter.addedSegments = '';
            document.getElementById('group-search').focus();
        }

        function getAddedSegmentsIds() {
            return vm.addedSegments.map(function (segment) {
                return segment.id;
            });
        }

        function init() {
            loadAvailableSegments();
            vm.addedSegments = params.segments;
            initialAddedSegmentsIds = getAddedSegmentsIds();
        }
    
        init();
}]);