'use strict';

angular.module('dialengaExeviBackoffice').service('ArrayUtilsService', function () {

    this.contains = function (array, object, field) {
        var index = 0;
        var result = null;

        while (!result && index < array.length) {
            if (field) {
                result = array[index][field] === object[field] ? array[index] : null;
            } else {
                result = array[index] === object ? array[index] : null;
            }
            index++;
        }

        return result;
    };

    this.remove = function (array, object, field) {
        var index = 0;
        var result = null;

        while (!result && index < array.length) {
            if (field) {
                result = array[index][field] === object[field] ? array[index] : null;
            } else {
                result = array[index] === object ? array[index] : null;
            }
            if (result) {
                array.splice(index, 1);
            }
            index++;
        }

        return result;
    };

    this.removeDuplicates = function (array, equalityProperty) {
        var processedArray = array.concat();
        for (var i = 0; i < processedArray.length; i++) {
            for (var j = i + 1; j < processedArray.length; j++) {
                if ((equalityProperty && processedArray[i][equalityProperty] === processedArray[j][equalityProperty])
                    || (processedArray[i] === processedArray[j])) {
                    processedArray.splice(j--, 1);
                }
            }
        }
        return processedArray;
    };

    this.areEquals = function (firstArray, secondArray) {
        if (!firstArray || !secondArray || firstArray.length != secondArray.length) {
            return false;
        }
    
        var firstArrayLength = firstArray.length;
        for (var i = 0; i < firstArrayLength; i++) {
            // Check if we have nested arrays
            if (firstArray[i] instanceof Array && secondArray[i] instanceof Array) {
                if (!firstArray[i].equals(secondArray[i])) {
                    return false;
                }
            }
            else if (firstArray[i] != secondArray[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    };

    var isLocalizedKeyIncluded = function (key) {
        // List of internal attributes that we don't want to check against localized contents
        var excludedKeys = ['imageOrder', 'resourceOrder'];
        return excludedKeys.indexOf(key) === -1;
    }

    this.objectHasAnyValue = function (objectContent) {
        // If we have no proper value, we return false
        if (typeof (objectContent) === "undefined") {
            return false;
        }

        // We're considering now every single property for a given var
        // If the object has no attributes (keys) or every key is empty, we return false
        // In other case, we return true
        for (var key in objectContent) {
            if (objectContent.hasOwnProperty(key) &&
                isLocalizedKeyIncluded(key) &&
                objectContent[key] && objectContent[key].toString().trim() !== "") {
                return true;
            }
        }
        return false;
    };

});
