(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewContent
     * @description
     * # devicePreviewContent
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewContent', {
        bindings: {
            publication: '<',
            language: '<',
            image: '<',
            isQuestion: '<?',
            isPill: '<?',
            isAnonymous: '<?',
            isCover: '<?'
        },
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-content.html',
        controllerAs: 'vm',
        controller: ['$rootScope', '$scope', 'GlobalService', 'videoService', 'UtilsService', 'Session', 'CONTENT_KIND', 'QUESTION_KIND', 'GENERAL_CONFIG_STATES', 'RATING_KIND', 'MESSAGE_STATES',
            function ($rootScope, $scope, GlobalService, videoService, UtilsService, Session, CONTENT_KIND, QUESTION_KIND, GENERAL_CONFIG_STATES, RATING_KIND, MESSAGE_STATES) {
            var vm = this;
            vm.getVideoThumbnail = videoService.getThumbnail;
            vm.QUESTION_KIND = QUESTION_KIND;
            vm.CONTENT_KIND = CONTENT_KIND;
            vm.GENERAL_CONFIG_STATES = GENERAL_CONFIG_STATES;
            vm.commentsConfig = Session.commentsConfig;
            vm.shareConfig = Session.shareConfig;
            vm.ratingConfig = Session.ratingConfig;
            vm.showActionsBar = (vm.commentsConfig !== vm.GENERAL_CONFIG_STATES.FORCE_DISABLED || vm.ratingConfig !== vm.GENERAL_CONFIG_STATES.FORCE_DISABLED || vm.shareConfig !== vm.GENERAL_CONFIG_STATES.FORCE_DISABLED);
            vm.MULTIPLE_KIND = { LIMITLESS: 1, EXACT: 2, RANGE: 3 };
            vm.RATING_KIND = RATING_KIND;
            vm.MESSAGE_STATES = MESSAGE_STATES;
            vm.ratingKind = $rootScope.account.ratingKind;
            
            vm.getCategoryLocalizedName = function () {
                if (!vm.publication || !vm.publication.category) {
                    return;
                }
                return (!!vm.publication.category.contents && !!vm.publication.category.contents[vm.language]) ? vm.publication.category.contents[vm.language].name : vm.publication.category.name;
            };

            vm.getReadingTime = function(message) {
                return GlobalService.getEstimatedReadingTime(message);
            };

            vm.iconFromMime = function(mimetype) {
                return UtilsService.getIconFromMimeType(mimetype);
            };
            
            var removeDataPublicationListener = $scope.$watch('vm.publication', function (newValue, oldValue) {
                if (!!newValue && newValue !== oldValue) {
                    // Check if user's language is available. If not, set first available language for preview.
                    var languages = Object.keys(vm.publication.contents);
                    if (languages.indexOf(vm.language) === -1) {
                        vm.language = languages[0];
                    }
                    removeDataPublicationListener();
                }
            });
        }]
    });
})();
