/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsWorkplaceEmployeesController
 * @description
 * # TimeoffSettingsWorkplaceEmployeesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsWorkplaceEmployeesController', ['$scope', '$rootScope', '$routeParams', '$translate', 'ModalService', 'TimeoffSettingsSchemesService', 'KendoUtilsService',
    'NotificationService', 'TimeoffSettingsWorkplacesService', 'GRID_ICON_COLUMN_WIDTH', 'PAGE_SIZE',
    function($scope, $rootScope, $routeParams, $translate, ModalService, TimeoffSettingsSchemesService, KendoUtilsService,
        NotificationService, TimeoffSettingsWorkplacesService, GRID_ICON_COLUMN_WIDTH, PAGE_SIZE) {
        var vm = this;

        var removeAddWorkplaceEmployeesEventListener;

        vm.$onInit = function() {
            initExternalActionEvent();
        };

        vm.$onDestroy = function() {
            removeAddWorkplaceEmployeesEventListener();
        };

        vm.inputSearchField = '';

        var gridColumns = [{
            field: 'fullName',
            title: $translate.instant('timeoff.requests.grid.employee'),
            template: '<user-avatar [user]="dataItem"></user-avatar>',
            width: 350
        }, {
            field: 'username',
            title: $translate.instant('users.username'),
            template: '<span ng-bind="dataItem.username"></span>'
        }, {
            command: [{
                name: "delete-button",
                template: '<div class="td-on-hover"><a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                    '><span class="icon icon-delete"></span></a></div>',
                click: deleteRowClickHandler
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];
        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.page = options.data.page - 1;
                    options.data.size = options.data.pageSize;
                    var defaultSortOrder = ['name,asc', 'surname,asc'];
                    options.data.sort = TimeoffSettingsSchemesService.getEmployeesSortCriteria(options.data.sort, defaultSortOrder);
                    saveGridPreferences(options);

                    if (vm.inputSearchField && KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                        options.data['name'] = vm.inputSearchField;
                    }
                    TimeoffSettingsWorkplacesService.getEmployees($routeParams.id, options.data).then(function(response) {
                        vm.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: 'content',
                model: { id: 'id' },
                total: 'totalElements'
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false }).then(function(response) {
            vm.gridOptions = response;
        });

        function actionErrorHandler(error) {
            if (error.status === 422) {
                reloadGrid();
            }
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'schemes.employees.modal.delete.title',
                message: 'workplaces.employees.modal.delete.message',
                messageData: { fullName: dataItem.name + ' ' + dataItem.surname },
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    TimeoffSettingsWorkplacesService.deleteEmployeeFromWorkplace($routeParams.id, dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('workplaces.employees.notification.delete.message', { fullName: dataItem.name + ' ' + dataItem.surname });
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['workplaceEmployeesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['workplaceEmployeesGrid'].inputSearchField = vm.inputSearchField;
        }

        $scope.$on('kendoWidgetCreated', function(event, widget) {
            if (widget === vm.workplaceEmployeesGrid) {
                if (KendoUtilsService.gridPreferences['workplaceEmployeesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['workplaceEmployeesGrid'];
                    vm.inputSearchField = requestOptions.inputSearchField;
                    vm.workplaceEmployeesGrid.dataSource.query(requestOptions);
                } else {
                    vm.workplaceEmployeesGrid.dataSource.read();
                }
            }
        });

        function reloadGrid(options) {
            vm.isSyncing = true;
            KendoUtilsService.reloadGridDatasource(vm.workplaceEmployeesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    vm.isSyncing = false;
                });
            });
        }

        vm.listItemsSearch = function(text) {
            vm.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied(vm.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        function addEmployeesToWorkplace() {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-settings-workplace/timeoff-settings-workplace-employees-modal.html',
                controller: 'TimeoffSettingsWorkplaceEmployeesModalController',
                size: 'xl',
                resolveParams: {
                    id: $routeParams.id
                },
                accept: function(employees) {
                    if (!!employees) {
                        TimeoffSettingsWorkplacesService.updateEmployeesInWorkplace($routeParams.id, employees)
                            .then(function() {
                                reloadGrid({ page: 1 });
                                var message = $translate.instant('workplaces.employees.notification.add.message');
                                NotificationService.notifySuccess(message);
                            })
                            .catch(actionErrorHandler);
                    }
                }
            });
        }

        function initExternalActionEvent() {
            if (!removeAddWorkplaceEmployeesEventListener) {
                removeAddWorkplaceEmployeesEventListener = $rootScope.$on('dialenga:addWorkplaceEmployees', function() {
                    addEmployeesToWorkplace();
                });
            }
        }

    }
]);