'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayMotivesModalController
 * @description
 * # FeedbackTrayMotivesModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayMotivesModalController', 
    ['$scope', '$timeout', 'params', 'CategoryService', 'ConversationsService', '$uibModalInstance', 'Session',
    function($scope, $timeout, params, CategoryService, ConversationsService, $uibModalInstance, Session) {

        var vm = this;

        vm.allMotives = [];
        var initialSelectedMotives = [];
        vm.isMotiveListVisible = true;
        var motivesIdsInTray = params.motives;

        // Methods for create motive
        vm.changed = false;
        vm.valid = false;
        vm.motiveForm = { motive: { contents: {} } };

        var resetMotiveForm = function() {
            vm.motiveForm = { motive: { contents: {} } };
            vm.motiveForm.motive.color = vm.availableColors.redDark;
        };

        vm.acceptMotive = function () {
            var promiseService = (params && params.id) ? ConversationsService.updateMotive : ConversationsService.createMotive;
            return promiseService.call(ConversationsService, vm.motiveForm.motive).then(function(response) {
                var newMotive = response.data;
                newMotive.selected = true;
                vm.allMotives.push(newMotive);
                vm.isMotiveListVisible = true;
                resetMotiveForm();
            }, function (error) {
                console.error(error);
                vm.isMotiveListVisible = true;
            });
        };

        vm.cancelMotive = function() {
            vm.isMotiveListVisible = true;
            resetMotiveForm();
        }

        var validateModel = function () {
            var valid = !!vm.motiveForm.motive.color && !!vm.motiveForm.motive.contents;
            var languages = Object.keys(vm.motiveForm.motive.contents);

            if (languages.length > 0) {
                for (var index = 0; index < languages.length; index++) {
                    var motiveContent = vm.motiveForm.motive.contents[languages[index]];
                    if (!motiveContent.motive || !motiveContent.motiveInApp) {
                        valid = false;
                        break;
                    }
                }
            } else {
                valid = false;
            }        

            vm.valid = valid;
        };
    
        vm.selectColor = function (color) {
            vm.motiveForm.motive.color = color;
            vm.onChange();
        };
    
        vm.onChange = function () {
            vm.changed = true;
        };
    
        vm.modalSaveOnEnter = function () {
            var keyCode = event.which || event.keyCode;
            if (keyCode === 13 && vm.valid) {
                vm.accept();
            }
        };

        $scope.$watch('vm.changed', function (newValue, oldValue) {
            if (newValue) {
                validateModel();
                vm.changed = false;
            }
        });


        // Methods for select motives
        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.accept = function () {
            if(!angular.equals(vm.allMotives, initialSelectedMotives)) {
                var selectedMotives = getSelectedMotives();
                $uibModalInstance.close(selectedMotives);
            } else {
            $uibModalInstance.dismiss('cancel');
            }           
        };

        vm.selectMotive = function (motive) {
            if (vm.atLeastOneMotiveSelected() || !motive.selected) {
                motive.selected = !motive.selected;
            }
        }

        vm.showNewMotiveModal = function () {
            vm.isMotiveListVisible = !vm.isMotiveListVisible;
            $timeout(function() {
                $('#motiveNameInApp').trigger('focus');
            });            
            validateModel();
        }

        vm.atLeastOneMotiveSelected = function() {
            var selected = getSelectedMotives();
            return selected.length > 1;
        }

        function loadAllMotives() {
            var options = {
                page: 0,
                size: 20000, 
                archived: false
            };
            ConversationsService.findAllMotives(options).then(function (response) {
                vm.allMotives = response.data.content;
                setAddedMotives();
            });
        }

        function setAddedMotives() {
            angular.forEach(vm.allMotives, function (item, index) {
                item.selected = motivesIdsInTray.indexOf(item.id) !== -1;
            });
            initialSelectedMotives = angular.copy(vm.allMotives);
        }

        function getSelectedMotives() {
            return vm.allMotives.filter(function (motive) {
                return motive.selected;
            });
        }

        function init() {
            loadAllMotives();

            vm.availableColors = CategoryService.availableColors;
            vm.selectedLanguage = Session.companyDefaultLanguage;

            vm.motiveForm.motive.color = vm.availableColors['redDark'];
        }
        
        init();
    }
]);
