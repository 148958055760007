'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:userstateIconFilter
 * @function
 * @description
 * # userstateIconFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('userStateIconFilter',
    ['USER_STATES',
    function (USER_STATES) {
    return function (state, lastAccessDate) {
        var icon = 'icon ';
        switch(parseInt(state)){
            case USER_STATES.ANONYMOUS:
                icon += ' icon-help-fill';
                break;
            case USER_STATES.PENDING_ACTIVATION:
                icon += ' icon-state-pending-activation';
                break;
            case USER_STATES.REGISTERED:
                icon = (typeof lastAccessDate === 'undefined' || lastAccessDate) ? '' : 'icon icon-state-wating-access';
                break;
            case USER_STATES.EXPIRED:
                icon += ' icon-state-expired';
                break;
            case USER_STATES.DISABLED:
                icon += ' icon-state-archived';
                break;
            case USER_STATES.EXPIRED_CREDENTIALS:
                icon += ' icon-state-locked yellow';
                break;
            case USER_STATES.LOCKED:
                icon += ' icon-state-locked red';
                break;
            default:
                break;
        }
        return icon;
    };
}]);
