'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.notificationService
 * @description
 * # notificationService
 * Notification Service for growl like notifications in the dialengaExeviBackoffice base on Bootstrap Notify.
 * More info about configuring the notifications could be found in http://bootstrap-notify.remabledesigns.com/
 */
angular.module('dialengaExeviBackoffice').service('NotificationService',
    ['$rootScope',
    function ($rootScope) {

    var notificationAlert = null;

    var notify = function(type, message, keepNotificationOpened, options, additionalClasses) {
        var delay = 1500;
        if (keepNotificationOpened) {
            delay = 0;
        } else if (options && typeof options.delay !== 'undefined') {
            delay = options.delay;
        }
        options = options || {};
        notificationAlert = $.notify({
            // options
            message: message
        },{
            // settings
            type: type,
            delay: delay,
            z_index: 10000,
            placement: options.placement || {
                from: 'bottom',
                align: 'right'
            },
            animate: options.animate || {
                enter: 'animated fadeInUp',
                exit: 'animated fadeOutDown'
            },
            offset: options.offset || {
                x: 20,
                y: 50
            },
            template: '<div data-notify="container" class="alert alert-{0} ' + (additionalClasses || '') + '" role="alert">' +
                            '<button type="button" aria-hidden="true" class="close icon icon-close" data-notify="dismiss"></button>'+
                            '<span class="icon icon-ok"></span>' +
                            '<span class="icon icon-error"></span>' +
                            '<span class="icon icon-info"></span>' +
                            '<span class="icon icon-alert"></span>' +
                            '<svg class="spinner secondary margin-left-10" width="25px" height="25px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">' +
                                '<circle class="path" fill="none"  stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>' +
                            '</svg>' + 
                            '<p data-notify="message">{2}</p>' +
                      '</div>'   
        });
    };

    this.closeAllOpenedNotifications = function () {
        $.notifyClose();
    };

    this.notifyInfo = function (message, keepNotificationOpened, options) {
        notify('info', message, keepNotificationOpened, options);
    };

    this.notifyDownload = function (message, keepNotificationOpened, options) {
        notify('download', message, keepNotificationOpened, options);
    };

    this.notifySuccess = function (message, delay) {
        var options = {};
        if (typeof delay !== 'undefined') {
            options.delay = delay;
        }
        notify('success', message, false, options);
    };

    this.notifyWarning = function (message, keepNotificationOpened, options) {
        notify('warning', message, keepNotificationOpened, options);
    };

    this.notifyFullWarning = function (message) {
        var options = {
            offset: { x: 0, y: 0 },
            placement: {
                from: 'top',
                align: 'center'
            },
            animate: {
                enter: 'animated slideInDown',
                exit: 'animated slideOutUp'
            }
        }
        notify('warning', message, true, options, 'full-warning');
    };

    this.notifyError = function (message) {
        notify('danger', message);
    };

    $rootScope.$on('dialenga:closeOpenedNotifications', function() {
        if (notificationAlert) {
            notificationAlert.close();
        }
    });
    
}]);
