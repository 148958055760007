'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.timeoffService
 * @description
 * # timeoffService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('TimeoffService',
    ['$filter','$http', '$timeout', '$translate', 'DateUtilsService', 'KendoUtilsService', 'SharedDataService', 'TimeoffSettingsSchemesService','ABSENCE_MOTIVE_GROUPS', 'PAGE_SIZE',
        'TIMEOFF_KIND', 'TIMEOFF_KIND_FILTER', 'TIMEOFF_REQUEST_STATUS', 'TIMEOFF_REQUEST_STATUS_FILTER',
    function ($filter, $http, $timeout, $translate, DateUtilsService, KendoUtilsService, SharedDataService, TimeoffSettingsSchemesService, ABSENCE_MOTIVE_GROUPS, PAGE_SIZE,
        TIMEOFF_KIND, TIMEOFF_KIND_FILTER, TIMEOFF_REQUEST_STATUS, TIMEOFF_REQUEST_STATUS_FILTER) {

        this.URL = SharedDataService.apiUrl + '/timeoff';
    
        this.findAll = function (options) {
            if (options) {
                options.page = options.page || 0;
                options.size = options.size || PAGE_SIZE;
                options.sort = options.sort || undefined;
            }
            return $http({
                url: this.URL + '/request/my-company',
                method: 'GET',
                params: options
            });
        };

        this.getNumberOfWorkingDays = function (startDate, endDate, employeeId) {
            startDate = moment(startDate).format('YYYY-MM-DD');
            endDate = moment(endDate).format('YYYY-MM-DD');
            return $http({
                url: this.URL + '/request/working-days',
                method: 'GET',
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    employeeId : employeeId
                }
            });
        };

        function getBaseUrl(kind) {
            if (kind == TIMEOFF_KIND.HOLIDAYS) {
                return SharedDataService.apiUrl + '/holidays';
            } else {
                return SharedDataService.apiUrl + '/absences';
            }
        }

        this.getRequestDetail = function (kind, id) {
            var baseUrl = getBaseUrl(kind);
            return $http({
                url: baseUrl + '/request/my-company/' + id,
                method: 'GET'
            });
        };

        this.approveRequest = function (kind, id, comment, forceApprove) {
            if (comment) {
                comment = comment.replace(/\r?\n/g, '<br />');
            }
            var baseUrl = getBaseUrl(kind);
            var url;
            if (forceApprove) {
                url = baseUrl + '/request/my-company/' + id + '/approve-directly';
            } else {
                url = baseUrl + '/request/my-company/' + id + '/approve';
            }
            return $http({
                url: url,
                method: 'POST',
                data: comment
            });
        };

        this.rejectRequest = function (kind, id, comment) {
            if (comment) {
                comment = comment.replace(/\r?\n/g, '<br />');
            }
            var baseUrl = getBaseUrl(kind);
            return $http({
                url: baseUrl + '/request/my-company/' + id + '/reject',
                method: 'POST',
                data: comment
            });
        };

        this.getKindFilter = function (selectedButton) {
            var kind = [];
            switch (selectedButton) {
                case TIMEOFF_KIND.HOLIDAYS:
                    kind.push(TIMEOFF_KIND_FILTER.HOLIDAYS);
                    break;
                case TIMEOFF_KIND.ABSENCE:
                    kind.push(TIMEOFF_KIND_FILTER.ABSENCE);
                    break;
                default:
                    break;
            }
            return kind;
        };

        this.getStateFilter = function (selectedState) {
            var state = [];
            angular.forEach(selectedState, function(sel) {
                switch (sel) {
                    case TIMEOFF_REQUEST_STATUS.PENDING:
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.PENDING);
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.APPROVED_BY_RESPONSIBLE);
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.APPROVED_BY_HHRR);
                        break;
                    case TIMEOFF_REQUEST_STATUS.APPROVED:
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.APPROVED);
                        break;
                    case TIMEOFF_REQUEST_STATUS.CANCELLED:
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.CANCELLED);
                        break;
                    case TIMEOFF_REQUEST_STATUS.REJECTED:
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.REJECTED);
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.REJECTED_BY_RESPONSIBLE);
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.REJECTED_BY_HHRR);
                        state.push(TIMEOFF_REQUEST_STATUS_FILTER.DISALLOWED);
                        break;
                    default:
                        break;
                }
            });
            return state;
        };

        this.getRequestStates = function (selectedState) {
            var state = [];
            angular.forEach(selectedState, function(sel) {
                switch (sel) {
                    case TIMEOFF_REQUEST_STATUS.PENDING:
                        state.push(TIMEOFF_REQUEST_STATUS.PENDING);
                        state.push(TIMEOFF_REQUEST_STATUS.APPROVED_BY_RESPONSIBLE);
                        state.push(TIMEOFF_REQUEST_STATUS.APPROVED_BY_HHRR);
                        break;
                    case TIMEOFF_REQUEST_STATUS.APPROVED:
                        state.push(TIMEOFF_REQUEST_STATUS.APPROVED);
                        break;
                    case TIMEOFF_REQUEST_STATUS.CANCELLED:
                        state.push(TIMEOFF_REQUEST_STATUS.CANCELLED);
                        break;
                    case TIMEOFF_REQUEST_STATUS.REJECTED:
                        state.push(TIMEOFF_REQUEST_STATUS.REJECTED);
                        state.push(TIMEOFF_REQUEST_STATUS.REJECTED_BY_RESPONSIBLE);
                        state.push(TIMEOFF_REQUEST_STATUS.REJECTED_BY_HHRR);
                        state.push(TIMEOFF_REQUEST_STATUS.DISALLOWED);
                        break;
                    default:
                        break;
                }
            });
            return state;
        };

        function getSelectedItemsId(data) {
            return data.reduce(function(selected, item) {
                if (item.enabled) {
                    selected.push(item.id);
                }
                return selected;
            }, []);
        }

        function disableStateToggle(stateId) {
            var toggle = document.getElementById('state-toggle-' + stateId);
            toggle.classList.add('disabled');
        }

        function enableAllStateToggles() {
            var elements = document.querySelectorAll('#state-multiple-filter .toggle__container.disabled');
            for (var index = 0; index < elements.length; index++) {
                elements[index].classList.remove('disabled');
            }
        }

        this.getTimeoffStatesDropDownListOptions = function (selectedState) {
            var timeoffStates = [{
                id: TIMEOFF_REQUEST_STATUS.PENDING,
                name: 'timeoff.requests.stateFilters.pending',
                enabled: !!selectedState ? selectedState[0] === TIMEOFF_REQUEST_STATUS.PENDING : true,
                timeoffRequestClass: 'bg-pending-color'
            }, {
                id: TIMEOFF_REQUEST_STATUS.APPROVED,
                name: 'timeoff.requests.stateFilters.approved',
                enabled: !!selectedState ? selectedState[0] === TIMEOFF_REQUEST_STATUS.APPROVED : false,
                timeoffRequestClass: 'bg-approved-color'
            },{
                id: TIMEOFF_REQUEST_STATUS.REJECTED,
                name: 'timeoff.requests.stateFilters.rejected',
                enabled: !!selectedState ? selectedState[0] === TIMEOFF_REQUEST_STATUS.REJECTED : false,
                timeoffRequestClass: 'bg-rejected-color'
            },{
                id: TIMEOFF_REQUEST_STATUS.CANCELLED,
                name: 'timeoff.requests.stateFilters.cancelled',
                enabled: !!selectedState ? selectedState[0] === TIMEOFF_REQUEST_STATUS.CANCELLED : false,
                timeoffRequestClass: 'bg-cancelled-color'
            }];
            var options = KendoUtilsService.getDropDownListOptions();
            options.dataSource = timeoffStates;
            options.tagTemplate = '<span class="box-shadow__legend-circle flex align-items-start {{dataItem.timeoffRequestClass}}"></span>';
            options.template = '<div id="state-multiple-filter" class="multiselect-options">' +
                                    '<span class="box-shadow__legend-circle vertical-align-label margin-right-10 {{dataItem.timeoffRequestClass}}"></span>' +
                                    '<span class="multiselect-options__label" translate="{{dataItem.name}}"></span>' +
                                    '<label ng-attr-id="state-toggle-{{dataItem.id}}" class="toggle__container multiselect-options__switch margin-left-5 align-items-right">' +
                                        '<div class="toggle toggle--small" data-ng-class="{\'toggle--active\': dataItem.enabled}" style="width: 12px !important;">' +
                                            '<span class="toggle__indicator"></span>' +
                                        '</div>' +
                                    '</label>' +
                                '</div>';
            options.dataBound = function(event) {
                // Remove hidden delete button and tooltip to avoid removing item on click
                $timeout(function () {
                    $(event.sender.tagList).find('li span[aria-label="delete"]').remove();
                }, 200);
            };
            options.select = function(event) {
                event.dataItem.enabled = !event.dataItem.enabled;
                enableAllStateToggles();
            };
            options.deselect = function(event) {
                var selectedItemsId = getSelectedItemsId(event.sender.dataSource._data);
                if (selectedItemsId.length === 1) {
                    event.preventDefault();
                } else {
                    event.dataItem.enabled = !event.dataItem.enabled;
                    var index = selectedItemsId.indexOf(event.dataItem.id);
                    if (index > -1) {
                        selectedItemsId.splice(index, 1);
                        if (selectedItemsId.length === 1) {
                            disableStateToggle(selectedItemsId[0]);
                        }
                    }
                }
            };
            options.open = function (event) {
                var selectedItemsId = getSelectedItemsId(event.sender.dataSource._data);
                if (selectedItemsId.length === 1) {
                    disableStateToggle(selectedItemsId[0]);
                } else {
                    enableAllStateToggles();
                }
            };
            options.autoClose = false;
            options.autoWidth = true;
            options.clearButton = false;
            options.valuePrimitive = true;
            return options;
        };

        this.getSelectedTeamsDropDownListOptions = function () {
            
            var options = KendoUtilsService.getDropDownListOptions();
            options.dataSource.transport.read = function(event) {
                var requestOptions = {
                    active: true,
                    page: 0,
                    size: 10000,
                    sort: 'name,asc'
                };
                $http({
                    url: SharedDataService.apiUrl + '/timeoff/teams',
                    method: 'GET',
                    params: requestOptions
                }).then(function(response) {
                    event.success(response.data.content);
                });
            };
            options.tagTemplate = '<span class="truncate" ng-bind="dataItem.name"></span>';
            options.itemTemplate = '<div id="teams-multiple-filter" class="multiselect-options">' +
                                    '<span class="multiselect-options__label margin-right-20" translate="{{dataItem.name}}"></span>' +
                                    '<label ng-attr-id="state-toggle-{{dataItem.id}}" class="toggle__container multiselect-options__switch margin-left-5 align-items-right">' +
                                        '<div class="toggle toggle--small" data-ng-class="{\'toggle--active\': dataItem.enabled}" style="width: 12px !important;">' +
                                            '<span class="toggle__indicator"></span>' +
                                        '</div>' +
                                    '</label>' +
                                '</div>';
            
            options.select = function(event) {
                event.dataItem.enabled = !event.dataItem.enabled;
            };
            options.deselect = function(event) {
                var selectedItemsId = getSelectedItemsId(event.sender.dataSource._data);
                
                event.dataItem.enabled = !event.dataItem.enabled;
                var index = selectedItemsId.indexOf(event.dataItem.id);
                if (index > -1) {
                    selectedItemsId.splice(index, 1);
                }
            };
           
            options.placeholder = $translate.instant('global.form.notSelected');
            options.list = {
                width: '400px'
            };
            options.autoClose = false;
            options.autoWidth = true;
            options.clearButton = false;
            options.valuePrimitive = true;
            options.tagMode = "multiple";
            options.messages = {
                deleteTag: $translate.instant('global.form.delete')
            };
            return options;
        };

        this.getSortCriteria = function (sortOptions, defaultValue) {
            var sort;
            if (sortOptions && sortOptions.length > 0) {
                var sortField = sortOptions[0].field;
                if (sortField === 'employee.fullName') {
                    sort = [];
                    sort.push('employee.name,' + sortOptions[0].dir);
                    sort.push('employee.surname,' + sortOptions[0].dir);
                } else {
                    sort = sortField + "," + sortOptions[0].dir;
                }
            } else {
                sort = defaultValue;
            }
            return sort;
        };

        this.getRequestedTime = function (formData, isShortMode) {
            var requestedTime = '';
            if (formData.partial) {
                if (formData.hours && parseInt(formData.hours)) {
                    requestedTime += formData.hours + ' ' + (isShortMode ? 'h' : $translate.instant('timeoffRequests.hours', {hours: formData.hours}, 'messageformat'));
                    if (formData.minutes && parseInt(formData.minutes)) {
                        requestedTime += ' ';
                    }
                }
                if (formData.minutes && parseInt(formData.minutes)) {
                    requestedTime += formData.minutes + ' ' + (isShortMode ? '\'' : $translate.instant('timeoffRequests.minutes', {minutes: formData.minutes}, 'messageformat'));
                }
            } else {
                requestedTime += $filter('number')(formData.days) + ' ' + $translate.instant('timeoffRequests.days', {days: formData.days}, 'messageformat');
            }
            return requestedTime;
        };
        
        this.getAbsenceMotiveClass = function (groupKind) {
            var motiveColorClass = '';
            switch (groupKind) {
                case ABSENCE_MOTIVE_GROUPS.HEALTH:
                    motiveColorClass = 'blue-color';
                    break;
                case ABSENCE_MOTIVE_GROUPS.PERSONAL:
                    motiveColorClass = 'purple-color';
                    break;
                case ABSENCE_MOTIVE_GROUPS.PROFESSIONAL:
                    motiveColorClass = 'light-brown-color';
                    break;
                case ABSENCE_MOTIVE_GROUPS.NO_REMUNERABLE:
                    motiveColorClass = 'light-green-color';
                    break;
                case ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS:
                    motiveColorClass = 'free-disposal-days';
                    break;
            }
            return motiveColorClass;
        };

        this.getUserSummaryHolidays = function(id) {
            return $http({
                url: SharedDataService.apiUrl + '/holidays/request/my-company/' + id + '/summary',
                method: 'GET',
            });
        };

        this.getUserSummaryAbsences = function(id) {
            return $http({
                url: SharedDataService.apiUrl + '/absences/request/my-company/' + id + '/summary',
                method: 'GET',
            });
        };

        this.getUserPeriodHolidays = function(id) {
            return $http({
                url: SharedDataService.apiUrl + '/holidays/request/my-company/' + id + '/periods',
                method: 'GET',
            });
        };

        this.getUserPeriodAbsences = function(id) {
            return $http({
                url: SharedDataService.apiUrl + '/absences/request/my-company/' + id + '/periods',
                method: 'GET',
            });
        };

        this.saveHolidaysUser = function(data) {
            return $http({
                url: SharedDataService.apiUrl + '/holidays/request/periods/' + data.id,
                method: 'PUT',
                data: data
            });
        };

        this.saveAbsencesUser = function(data) {
            return $http({
                url: SharedDataService.apiUrl + '/absences/request/periods/' + data.id,
                method: 'PUT',
                data: data
            });
        };

        this.doughnutsChart = function (dataSet) {
            return {
                legend: {
                    position: 'top',
                    visible: false
                },
                plotArea: {
                    margin: {
                        top: 10,
                        left: 0
                    }
                },
                chartArea: {
                    height: 120,
                    width: 110
                },
                seriesDefaults: {
                    type: "donut",
                    startAngle: 90,
                    holeSize: 45
                },
                series: [{
                    data: dataSet,
                    overlay: {
                        gradient: "none"
                    },
                    labels: {
                        visible: false,
                        background: "transparent",
                        position: "outsideEnd",
                        template: "#= category #: \n #= value#"
                    }
                }],
                tooltip: {
                    visible: true,
                    template: "#= category #"
                }
            };
        };

        this.donutsChartCompleteFiller = function (dataSet) {
            return {
                legend: {
                    position: 'bottom',
                    visible: true
                },
                plotArea: {
                    margin: {
                        top: 0,
                        left: 0
                    }
                },
                chartArea: {
                    height: 75,
                    width: 75
                },
                seriesDefaults: {
                    type: "donut",
                    startAngle: 90,
                    holeSize: 30
                },
                series: [{
                    data: dataSet,
                    visual: function(e) {
                        if (e.dataItem.dummy) {
                            // Don't create a visual element for the "filler" segment
                            return null;
                        }
                        // Create the default visual element for the rest
                        return e.createVisual();
                    },
                    overlay: {
                        gradient: "none"
                    },
                    labels: {
                        visible: false,
                        background: "transparent",
                        position: "outsideEnd",
                        template: "#= category #: \n #= value#"
                    }
                }],
                tooltip: {
                    visible: false,
                    template: "#= category #"
                }
            };
        };

        this.donutsChartPartialFiller = function (dataSet) {
            return {
                legend: {
                    position: 'top',
                    visible: false
                },
                plotArea: {
                    margin: {
                        top: 0,
                        left: 0
                    }
                },
                chartArea: {
                    height: 75,
                    width: 75
                },
                seriesDefaults: {
                    type: "donut",
                    startAngle: -10,
                    holeSize: 30
                },
                series: [{
                    data: dataSet,
                    visual: function(e) {
                        if (e.dataItem.dummy) {
                            // Don't create a visual element for the "filler" segment
                            return null;
                        }
                        // Create the default visual element for the rest
                        return e.createVisual();
                    },
                    overlay: {
                        gradient: "none"
                    },
                    labels: {
                        visible: false,
                        background: "transparent",
                        position: "outsideEnd",
                        template: "#= category #: \n #= value#"
                    }
                }],
                tooltip: {
                    visible: false,
                    template: "#= category #: \n #= value#"
                }
            };
        };

        this.donutsChartPartialFillerTwoSeries = function (dataSetOne, dataSetTwo) {
            return {
                legend: {
                    position: 'top',
                    visible: false
                },
                plotArea: {
                    margin: {
                        top: 5,
                        left: 0
                    }
                },
                chartArea: {
                    height: 75,
                    width: 75
                },
                seriesDefaults: {
                    type: "donut",
                    startAngle: -10,
                    holeSize: 21,
                },
                series: [
                    {
                        data: dataSetOne,
                        visual: function(e) {
                            if (e.dataItem.dummy) {
                                // Don't create a visual element for the "filler" segment
                                return null;
                            }
                            // Create the default visual element for the rest
                            return e.createVisual();
                        },
                        overlay: {
                            gradient: "none"
                        },
                        labels: {
                            visible: false,
                        },
                        size: 7
                    },
                    {
                        data: dataSetTwo,
                        visual: function(e) {
                            if (e.dataItem.dummy) {
                                // Don't create a visual element for the "filler" segment
                                return null;
                            }
                            // Create the default visual element for the rest
                            return e.createVisual();
                        },
                        overlay: {
                            gradient: "none"
                        },
                        labels: {
                            visible: false,
                        },
                        size: 7
                    }
                ],
                tooltip: {
                    visible: false,
                    template: "#= category #: \n #= value#"
                }
            };
        };

        function parseAbsencesMotives(motivesGroups) {
            motivesGroups.sort(function(a, b) { return a.kind - b.kind });
            var parsedMotives = [];
            for (var indexGroup = 0; indexGroup < motivesGroups.length; indexGroup++) {
                var group = {
                    id: motivesGroups[indexGroup].id,
                    name: motivesGroups[indexGroup].name,
                    kind: motivesGroups[indexGroup].kind
                };
                parsedMotives.push(group);
                
                var existParsedMotives = false;
                for (var indexMotive = 0; indexMotive < motivesGroups[indexGroup].motives.length; indexMotive++) {
                    if (motivesGroups[indexGroup].motives[indexMotive].enabled) {
                        var motive = {
                            id: motivesGroups[indexGroup].motives[indexMotive].id,
                            name: motivesGroups[indexGroup].motives[indexMotive].name,
                            groupKind: motivesGroups[indexGroup].motives[indexMotive].groupKind,
                            enabled: motivesGroups[indexGroup].motives[indexMotive].enabled
                        };
                        parsedMotives.push(motive);
                        existParsedMotives = true;
                    }
                }
                if (!existParsedMotives) {
                    parsedMotives.pop();
                }
            }
            return parsedMotives;
        }

        this.getMotivesOptions = function() {
            var options = KendoUtilsService.getDropDownListOptions();
            options.dataSource.transport.read = function(event) {
                if (!event.data.schemaId) {
                    event.success([]);
                } else {
                    var schemaId = event.data.schemaId;
                    TimeoffSettingsSchemesService.findSchemaAbsences(schemaId).then(function(response) {
                        event.success(response.data);
                   });
                } 
            };
            options.dataSource.schema = {
                parse: function(response) {
                    return parseAbsencesMotives(response);
                }
            };
            options.valuePrimitive = false;
            options.dataSource.sort = {};
            options.optionLabel = {
                name: $translate.instant('global.form.select'),
            };
            options.optionLabelTemplate = '<span class="black45" translate="global.form.select"></span>';
            options.autoBind = false;
            options.select = function(e){
                if(e.dataItem.kind || !e.dataItem.enabled){
                  e.preventDefault();
                }
            };
            options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
            options.template = '<div class="absence-option" ng-class="{\'absence-option--no-hover\' : !!dataItem.kind}">' +
            '<span class="absence-option__icon icon"  ng-class="(!!dataItem.kind || !dataItem.enabled) ? (dataItem.kind | absenceMotiveIconFilter) : \'\'" ng-if="!!dataItem.kind"></span>' +
            '<span class="absence-option__label" ng-bind="dataItem.name" ng-class="{\'absence-title\' : !!dataItem.kind}"></span></div>';
            options.dataTextField = 'name';
            
            return options;
        };

        function getTimeoffRequestAsFormData(data, attachments) {
            var fd = new FormData();
            fd.append('timeOffRequest', new Blob([JSON.stringify(data)], { type: "application/json" }));
            var index;
            for (index = 0; index < attachments.length; index++) {
                fd.append('attachments', attachments[index]);
            }
            return fd;
        }

        this.createTimeoffRequestAsEmployee = function(request, attachments) {
            request.motive = request.motive.id ? request.motive.id : null;

            var parsedStartDate = moment(request.startDate, 'DD/MM/YYYY');
            var parsedEndDate = moment(request.endDate, 'DD/MM/YYYY');
            if (!parsedStartDate.isValid()) {
                parsedStartDate = DateUtilsService.getDateFromString(request.startDate);
                parsedStartDate = moment(parsedStartDate, 'DD/MM/YYYY');
            }
            if (!parsedEndDate.isValid()) {
                parsedEndDate = DateUtilsService.getDateFromString(request.endDate);
                parsedEndDate = moment(parsedEndDate, 'DD/MM/YYYY');
            }
            request.startDate = parsedStartDate.format('YYYY-MM-DDT00:00:00.000') + 'Z';
            request.endDate = parsedEndDate.format('YYYY-MM-DDT00:00:00.000') + 'Z';
            
            var fd = getTimeoffRequestAsFormData(request, attachments);
            var baseUrl = getBaseUrl(request.kind);
            return $http({
                url: baseUrl + '/request/as-employee',
                method: 'POST',
                data: fd,
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            });
        };
}]);
