'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.usersService
 * @description
 * # usersService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('UsersService',
    ['$http', '$translate', 'KendoUtilsService', 'SharedDataService', 'Session', 'PAGE_SIZE', 'STRENGTH_TYPE', 'USER_STATES', 'USER_STATES_FILTER', 'PUSH_OPTION', 'ALLOWED_CATEGORIES_KIND', 'LANGUAGES',
    function ($http, $translate, KendoUtilsService, SharedDataService, Session, PAGE_SIZE, STRENGTH_TYPE, USER_STATES, USER_STATES_FILTER, PUSH_OPTION, ALLOWED_CATEGORIES_KIND, LANGUAGES) {

    var userLanguageTemplate = '<div>' +
            '<span ng-if="dataItem" data-translate="{{\'global.language.\' + dataItem}}"></span>' +
        '</div>';

    var self = this;
    this.URL = SharedDataService.apiUrl + '/users';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.generatePassword = function () {
        return $http({
            url: this.URL + '/generate-password',
            method: 'GET'
        });
    };

    this.activateUser = function (data) {
        return $http({
            url: self.URL + '/activate',
            method: 'PUT',
            data: data
        });
    };

    this.resetPassword = function (data) {
        return $http({
            url: self.URL + '/reset-password',
            method: 'POST',
            data: data
        });
    };

    this.requestPasswordReset = function (data) {
        return $http({
            url: self.URL + '/request-password-reset',
            method: 'POST',
            data: data,
            ignoreAuthModule: 'ignoreAuthModule' // Avoid interceptor catching 401 error and redirecting to login
        });
    };

    this.create = function (data) {
        return $http({
            url: this.URL,
            method: 'POST',
            data: data.user
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL + '/' + data.user.id,
            method: 'PUT',
            data: data.user
        });
    };

    this.delete = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE'
        });
    };

    this.getProfile = function () {
        return $http({
            url: this.URL + '/profile',
            method: 'GET'
        });
    };

    this.editProfile = function (data) {
        return $http({
            url: this.URL + '/profile',
            method: 'PUT',
            data: data.user
        });
    };

    this.activate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/activate',
            method: 'PUT'
        });
    };

    this.deactivate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/deactivate',
            method: 'PUT'
        });
    };

    this.sendActivationEmail = function (id) {
        return $http({
            url: this.URL + '/' + id + '/resend-activation',
            method: 'GET'
        });
    };

    this.sendAppLinksEmail = function(id) {
        return $http({
            url: this.URL + '/' + id + '/send-app-links',
            method: 'GET' 
        });
    };

    this.unlockUser = function (id) {
        return $http({
            url: this.URL + '/' + id + '/unlock',
            method: 'PUT' 
        });
    };

    this.getUserLanguagesOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions(true);
        options.dataSource = LANGUAGES;
        options.dataTextField = '';
        options.dataValueField = '';
        options.valuePrimitive = true;
        options.template = userLanguageTemplate;
        options.valueTemplate = userLanguageTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.loadUsersCsv = function (usersCsv) {
        var formData = new FormData();
        formData.append("usersMultipart", usersCsv);
        return $http({
            url: this.URL + '/load-csv',
            method: 'POST',
            data: formData,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.downloadUsersCsv = function (options) {
        return $http({
            url: this.URL + '/export-csv',
            method: 'GET',
            params: options,
            responseType: 'blob'
        });
    };

    this.findAllSegmentedUsers = function (options) {
        options = options || {};
        options.page = options.page || 0;
        options.size = options.size || PAGE_SIZE;
        options.sort = options.sort || undefined;
        return $http({
            url: this.URL + '/segmented-users',
            method: 'GET',
            params: options
        });
    };

    function getDropDownOptions(url, sort) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            $http({
                url: SharedDataService.apiUrl + url,
                method: 'GET'
            }).then(function(response) {
                event.success(response.data);
            });
        };
        options.optionLabel = {
            name: $translate.instant('global.form.notSet')
        };
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    }

    this.getCompaniesOptions = function() {
        return getDropDownOptions('/subsidiary-companies/active');
    };

    this.getWorkplacesOptions = function() {
        return getDropDownOptions('/headquarters/active');
    };

    this.getDepartmentsOptions = function() {
        return getDropDownOptions('/departments/active');
    };

    this.getTeamsOptions = function() {
        return getDropDownOptions('/timeoff/teams/active');
    };

    this.getSchedulesOptions = function() {
        var requestOptions = {
            active: true,
            page: 0,
            size: 20000,
            sort: 'name,asc'
        };
        var options = KendoUtilsService.getDropDownListOptions();
        
        options.dataSource.transport.read = function(event) {
            $http({
                url: SharedDataService.apiUrl + '/time-tracking-schedule',
                method: 'GET',
                params: requestOptions
            }).then(function(response) {
                event.success(response.data.content);
            });
        };
        options.optionLabel = {
            name: $translate.instant('global.form.notSet')
        };
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getUserHolidaysPeriodsDropdown = function(id) {
        var options = KendoUtilsService.getDropDownListOptions(true);
        var url = SharedDataService.apiUrl + '/holidays/employees/' + id + '/periods';
        options.dataSource.transport.read = function(event) {
            $http({
                url: url,
                method: 'GET'
            }).then(function(response) {
                event.success(response.data);
            });
        };
        
        options.dataTextField = 'period';
        options.dataSource.schema = {
            parse: function(response) {
                var length = response.length;
                var dataItem;
                for (var idx = 0; idx < length; idx++) {
                    dataItem = response[idx];
                    var yearBegin = new Date(dataItem.startDate).getFullYear();
                    var yearEnd = new Date(dataItem.endDate).getFullYear();
                    dataItem.period = parseInt(yearBegin) == parseInt(yearEnd) ? yearBegin : yearBegin + ' - ' + yearEnd;
                }
                return response;
            }
        }
        options.valuePrimitive = true;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getpushNotificationOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions(true);
        options.dataSource = {
            data: [
                {text: $translate.instant('global.form.customizable'), value: PUSH_OPTION.CUSTOMIZABLE},
                {text: $translate.instant('global.form.alwaysOn'), value: PUSH_OPTION.ALWAYS_ON},
                {text: $translate.instant('global.form.alwaysOff'), value: PUSH_OPTION.ALWAYS_OFF}
            ]
        };
        options.dataTextField = "text";
        options.dataValueField = "value";
        options.valuePrimitive = true;
        return options;
    };

    this.getHighlightContentOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions(true);
        options.dataSource = {
            data: [
                {text: $translate.instant('global.form.allow'), value: true},
                {text: $translate.instant('global.form.notAllow'), value: false}
            ]
        };
        options.dataTextField = "text";
        options.dataValueField = "value";
        options.valuePrimitive = true;
        return options;
    };

    this.getAllowedKindOptions = function (elementId) {
        var options = KendoUtilsService.getDropDownListOptions(true);
        options.dataSource = {
            data: [
                {text: $translate.instant('global.form.include'), value: ALLOWED_CATEGORIES_KIND.INCLUSION},
                {text: $translate.instant('global.form.exclude'), value: ALLOWED_CATEGORIES_KIND.EXCLUSION}
            ]
        };
        options.dataTextField = "text";
        options.dataValueField = "value";
        options.optionLabel = $translate.instant('global.form.include');
        options.optionLabelTemplate = '<span class="button__label" translate="global.form.include"></span>';
        options.open = function () { 
            $(elementId).getKendoDropDownList().list.find("div.k-list-optionlabel").first().hide(); 
        };
        options.valuePrimitive = true;
        return options;
    };

    this.getUserIcon = function(state, lastAccessDate) {
        var userState = {};
        var icon = 'icon';
        switch (parseInt(state)) {
            case USER_STATES.ANONYMOUS:
                userState.iconClass = icon + ' icon-help-fill grey-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.anonymous');
                break;
            case USER_STATES.PENDING_ACTIVATION:
                userState.iconClass = icon + ' icon-state-pending-activation grey-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.pendingactivation');
                break;
            case USER_STATES.REGISTERED:
                userState.iconClass = (typeof lastAccessDate === 'undefined' || lastAccessDate) ? 'icon icon-ok-fill green-icon-color icon-24' : 'icon icon-state-wating-access grey-icon-color icon-24';
                userState.state = $translate.instant((typeof lastAccessDate === 'undefined' || lastAccessDate) ? 'global.userstates.active' : 'global.userstates.pendingAccess');
                break;
            case USER_STATES.EXPIRED:
                userState.iconClass= icon + ' icon-state-expired grey-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.expired');
                break;
            case USER_STATES.DISABLED:
                userState.iconClass = icon + ' icon-state-archived grey-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.disabled');
                break;
            case USER_STATES.EXPIRED_CREDENTIALS:
                userState.iconClass = icon + ' icon-state-locked yellow-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.expiredCredentials');
                break;
            case USER_STATES.LOCKED:
                userState.iconClass = icon + ' icon-state-locked red-icon-color icon-24';
                userState.state = $translate.instant('global.userstates.locked');
                break;
            default:
                break;
        }
        return userState;
    }

    this.getStateFilter = function (isStateActive, selectedButton) {
        var state = [];
        if (isStateActive) {
            switch (selectedButton) {
                case USER_STATES.PENDING_ACTIVATION:
                    state.push(USER_STATES_FILTER.PENDING_ACTIVATION);
                    break;
                case USER_STATES.REGISTERED:
                    state.push(USER_STATES_FILTER.REGISTERED);
                    break;
                case USER_STATES.EXPIRED_CREDENTIALS:
                    state.push(USER_STATES_FILTER.EXPIRED_CREDENTIALS);
                    break;
                case USER_STATES.LOCKED:
                    state.push(USER_STATES_FILTER.LOCKED);
                    break;
                default:
                    state.push(USER_STATES_FILTER.PENDING_ACTIVATION);
                    state.push(USER_STATES_FILTER.REGISTERED);
                    state.push(USER_STATES_FILTER.LOCKED);
                    break;

            }
        } else {
            state.push(USER_STATES_FILTER.EXPIRED);
            state.push(USER_STATES_FILTER.DISABLED);
        }
        return state;
    };

    this.enableOrDisableHolidaysForUser = function (id, enabled) {
        var finalUrl = enabled ? '/modules/enableTimeOff' : '/modules/disableTimeOff'; 
        return $http({
            url: this.URL + '/' + id + finalUrl,
            method: 'PUT'
        });
    };

    this.enableOrDisableDocumentsForUser = function (id, enabled) {
        var finalUrl = enabled ? '/modules/enableDocuments' : '/modules/disableDocuments'; 
        return $http({
            url: this.URL + '/' + id + finalUrl,
            method: 'PUT'
        });
    };

    this.enableOrDisableTimeTrackingForUser = function (id, enabled) {
        var finalUrl = enabled ? '/modules/enableTimeTracking' : '/modules/disableTimeTracking'; 
        return $http({
            url: this.URL + '/' + id + finalUrl,
            method: 'PUT'
        });
    };

    function getPasswordStrengthValue(passwordStrengthObj) {
        var strengthValue = 0;
        var strengthValidations = Object.keys(passwordStrengthObj);
        for (var index = 0; index < strengthValidations.length; index++) {
            var key = strengthValidations[index];
            if (passwordStrengthObj.hasOwnProperty(key) && passwordStrengthObj[key] === true) {
                strengthValue++;
            }
        }
        var passwordStrengthValue;
        switch (strengthValue) {
            case 1:
                passwordStrengthValue = STRENGTH_TYPE.VERY_WEAK;
                break;
            case 2:
                passwordStrengthValue = STRENGTH_TYPE.WEAK;
                break;
            case 3:
                passwordStrengthValue = STRENGTH_TYPE.REGULAR;
                break;
            case 4:
                passwordStrengthValue = STRENGTH_TYPE.ALMOST_VALID;
                break;
            case 5:
                passwordStrengthValue = STRENGTH_TYPE.VALID;
                break;
            default:
                passwordStrengthValue = 0;
                break;
        }
        return passwordStrengthValue;
    }

    this.validatePasswordStrength = function (password, passwordStrengthObj) {
        password = password || '';

        passwordStrengthObj.uppercase = new RegExp('(?=.*[A-Z])').test(password);
        passwordStrengthObj.lowercase = new RegExp('(?=.*[a-z])').test(password);
        passwordStrengthObj.numbers = new RegExp('(?=.*[0-9])').test(password);
        passwordStrengthObj.specialChar = new RegExp(/[!@#\$&\*%\.]+/).test(password);
        passwordStrengthObj.minLength = password && password.length >= 12;
        return getPasswordStrengthValue(passwordStrengthObj);
    };
        
    function getEmployees(employeeFilter) {
        var url = SharedDataService.apiUrl + '/users/employees';
        if (!!employeeFilter) {
            url += '?name=' + employeeFilter.toLowerCase() + '&state=' + USER_STATES_FILTER.REGISTERED + '&state=' + USER_STATES_FILTER.PENDING_ACTIVATION + '&state=' + USER_STATES_FILTER.LOCKED;
        }
        return $http({
            url: url,
            method: 'GET'
        });
    }

    this.getEmployeeOptions = function (filter) {
        var employeeFilter = filter || '';
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.serverFiltering = true;
        options.dataSource.transport.read = function(event) {
            if (!!employeeFilter) {
                getEmployees(employeeFilter).then(function(response) {
                    if (response.data.content.length > 0) {
                        document.getElementById('responsible-dropdown').classList.remove('select--remove-arrow');
                    } else {
                        document.getElementById('responsible-dropdown').classList.add('select--remove-arrow');
                    }
                    event.success(response.data.content);
                });
            } else {
                document.getElementById('responsible-dropdown').classList.add('select--remove-arrow');
                event.success([]);
            }
        };
        options.filtering = function(event) {
            if (event.filter) {
                employeeFilter = event.filter.value;
                document.getElementById('responsible-dropdown').classList.remove('select--remove-arrow');
                if (event.filter.value === '') { 
                    this.close();
                }
            } else {
                document.getElementById('responsible-dropdown').classList.add('select--remove-arrow');
            }
        };
        options.select = function(event) {
            if (!!event.dataItem) {
                employeeFilter = event.dataItem.name + ' ' + event.dataItem.surname;
            }
        };
        
        options.placeholder = $translate.instant('global.form.employeeName');
        options.messages = {
            clear: $translate.instant('global.modal.clear')
        };
        options.autoBind = true;
        options.autoWidth = true;
        options.minLength = 3;
        options.filter = 'contains';
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        options.template = '<div class="select-avatar-item min-width-300"><user-avatar [small]="true" [user]="dataItem" show-username="true"></user-avatar></div>';
        options.dataTextField = 'fullname';
        options.dataSource.schema = {
            parse: function(response) {
                var length = response.length;
                var dataItem;
                for (var idx = 0; idx < length; idx++) {
                    dataItem = response[idx];
                    dataItem.fullname = dataItem.name + ' ' + dataItem.surname;
                }
                return response;
            }
        }
        return options;
    };
    
    this.getUserMaritalStatusOptions = function() {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            $http({
                url: SharedDataService.apiUrl + '/marital-statuses',
                method: 'GET',
            }).then(function(response) {
                event.success(response.data);
            });
        };
        options.dataTextField = 'status';
        options.dataValueField = 'id';
        options.optionLabel = $translate.instant('global.form.notSet');
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getAnniversaryUsers = function () {
        return $http({
            url: this.URL + '/anniversary-users',
            method: 'GET'
        });

    };

    this.getCompanies = function () {
        return $http({
            url: SharedDataService.apiUrl + '/subsidiary-companies/active',
            method: 'GET'
        });
    }

    this.getWorkplaces = function () {
        return $http({
            url: SharedDataService.apiUrl + '/headquarters/active',
            method: 'GET'
        });
    }

    this.getDepartments = function () {
        return $http({
            url: SharedDataService.apiUrl + '/departments/active',
            method: 'GET'
        });
    }

    this.getTeams = function () {
        return $http({
            url: SharedDataService.apiUrl + '/timeoff/teams/active',
            method: 'GET'
        });
    }

    this.getFileKinds = function () {
        return $http({
            url: SharedDataService.apiUrl + '/file-kinds/allowed',
            method: 'GET'
        })
    }
}]);
