'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.messageService
 * @description
 * # messageService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('MessageService',
    ['$http', '$timeout', 'SharedDataService', 'PublicationService', 'FileUtilsService', 'MESSAGE_STATES_FILTER', 'MESSAGE_STATES', 'PAGE_SIZE',
    function ($http, $timeout, SharedDataService, PublicationService, FileUtilsService, MESSAGE_STATES_FILTER, MESSAGE_STATES, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/messages';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.update = function (data) {
        var fd = PublicationService.getPublicationAsFormData(data.message, 'message', data.images, data.resources);
        return $http({
            url: this.URL + '/' + data.message.id,
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.create = function (data) {
        var fd = PublicationService.getPublicationAsFormData(data.message, 'message', data.images, data.resources, data.message.gallery);
        return $http({
            url: this.URL,
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.delete = function (id, errorMessage) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE',
            localizedErrorString: errorMessage
        });
    };

    this.pause = function (id) {
        return $http({
            url: this.URL + '/' + id + '/pause',
            method: 'PUT'
        });
    };

    this.requestValidation = function (id, dataPublication) {
        return $http({
            url: this.URL + '/' + id + '/request-validation',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.validate = function (id, dataPublication) {
        return $http({
            url: this.URL + '/' + id + '/validate',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.publish = function (id) {
        return $http({
            url: this.URL + '/' + id + '/publish',
            method: 'PUT'
        });
    };

    this.expire = function (id) {
        return $http({
            url: this.URL + '/' + id + '/expire',
            method: 'PUT'
        });
    };

    this.duplicate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/duplicate',
            method: 'POST'
        });
    };

    this.relocate = function (id, category) {
        return $http({
            url: this.URL + '/' + id + '/relocate',
            method: 'PUT',
            data: category
        });
    };

    this.updatePublicationDate = function (id, dataPublication) {
        return $http({
            url: this.URL + '/' + id + '/update-scheduling',
            method: 'PUT',
            data: dataPublication
        });
    };

    this.setRecipients = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients',
            method: 'PUT',
            data: recipients
        })
    };

    this.getRecipientsCount = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients-count',
            method: 'POST',
            data: recipients
        })
    };

    this.statistics = function (id) {
        return $http({
            url: this.URL + '/' + id + '/statistics',
            method: 'GET'
        });
    };

    this.activity = function (id, params) {
        return $http({
            url: this.URL + '/' + id + '/activity-detail',
            method: 'GET',
            params: params
        });
    };

    this.getInteractionsResume = function(id) {
        return $http({
            url: this.URL + '/' + id + '/interactions',
            method: 'GET'
        });
    };

    this.getStateFilter = function (isStateActive, selectedButton) {
        var state = [];
        if (isStateActive) {
            switch (selectedButton) {
                case MESSAGE_STATES.DRAFT:
                    state.push(MESSAGE_STATES_FILTER.DRAFT);
                    break;
                case MESSAGE_STATES.PENDING:
                    state.push(MESSAGE_STATES_FILTER.PENDING);
                    break;
                case MESSAGE_STATES.PLANNED:
                    state.push(MESSAGE_STATES_FILTER.PLANNED);
                    break;
                case MESSAGE_STATES.PUBLISHED:
                    state.push(MESSAGE_STATES_FILTER.PUBLISHED);
                    break;
                default:
                    state.push(MESSAGE_STATES_FILTER.DRAFT);
                    state.push(MESSAGE_STATES_FILTER.PENDING);
                    state.push(MESSAGE_STATES_FILTER.PUBLISHED);
                    state.push(MESSAGE_STATES_FILTER.PLANNED);
                    state.push(MESSAGE_STATES_FILTER.PUBLISHING_NOW);
                    break;

            }
        } else {
            state.push(MESSAGE_STATES_FILTER.EXPIRED);
        }
        return state;
    };

    this.getCommentsForPublication = function(id, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/' + id + '/comments',
            method: 'GET',
            params: options
        });
    };

    this.deleteComment = function (publicationId, commentId) {
        return $http({
            url: this.URL + '/' + publicationId + '/comments/' + commentId + '/suppress',
            method: 'DELETE'
        });
    };

    this.addImageToGallery = function(id, data, images) {
        var fd = new FormData();
        fd.append('imagesGallery', new Blob([JSON.stringify(data)], { type: "application/json" }));
        for (var index = 0; index < images.length; index++) {
            fd.append('images', images[index], images[index].name );
        }
        return $http({
            url: this.URL + '/' + id + '/gallery/images',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    }

    this.deleteImageFromGallery = function(idMessage, idImage) {
        return $http({
            url: this.URL + '/' + idMessage + '/gallery/images/' + idImage,
            method: 'DELETE'
        });
    }

    this.updateImageGallery = function(idMessage, idImage, image, name) {
        var fd = new FormData();
        fd.append('image', FileUtilsService.dataURItoBlob(image), name);

        return $http({
            url: this.URL + '/' + idMessage + '/gallery/images/' + idImage + '/substitute',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    }

    this.reorderImageGallery = function(idMessage, idImage, newOrder) {

        return $http({
            url: this.URL + '/' + idMessage + '/gallery/images/' + idImage + '/reorder',
            method: 'PUT',
            data: newOrder
        });
    }

    this.setUsersAvatarTooltips = function(users) {
        if (users) {
            $timeout(function () {
                for (var index = 0; index < users.length; index++) {
                    var item = users[index];
                    var tooltipAvatar = $('#avatar-user-' + item.user).data('kendoTooltip');
                    if (tooltipAvatar) {
                        tooltipAvatar.options.content = item.name + ' '+ item.surname;
                        tooltipAvatar.refresh();
                    } else {
                        break;
                    }
                }
                var tooltipRemaining = '';
                for (index = 5; index < users.length; index++) {
                    item = users[index];
                    tooltipRemaining += item.name + ' '+ item.surname + '<br>';
                    if (index == users.length - 1) {
                        tooltipAvatar = $('#remaining-users').data('kendoTooltip');
                        if (tooltipAvatar) {
                            tooltipAvatar.options.content = tooltipRemaining;
                            tooltipAvatar.refresh();
                        }
                    }
                }
            }, 300);
        }
    }

    this.orderRecipientsUsers = function(users) {
        return users.sort(function (a, b) { return a.name.localeCompare(b.name) || a.surname.localeCompare(b.surname) || 0; }); 
    }
    
    this.getStatisticsFileCSV = function(url) {
        return $http({
            url: url,
            method: 'GET',
            responseType: 'blob'
        });
    }
}]);
