(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:disabledButton
     * @description
     * # disabledButton
     */
    angular.module('dialengaExeviBackoffice').component('dayMonthSelector', {
        bindings: {
            selectorId: '@',
            dayModel: '=',
            monthModel: '=',
            changeFunction: '&?',
            disabled: '<?'
        },
        controller: ['KendoUtilsService', function (KendoUtilsService) {
            var vm = this;

            vm.$onInit = function () {
                vm.daysDropDownListOptions = getDaysDropDownListOptions();
                vm.monthsDropDownListOptions = getMonthsDropDownListOptions();
            };

            function getDaysInMonth(monthIndex) {
                var daysInMonth = 31;
                if (monthIndex) {
                    monthIndex = parseInt(monthIndex);
                    var monthsThirty = [4, 6, 9, 11];
                    if (monthsThirty.indexOf(monthIndex) !== -1) {
                        daysInMonth = 30;
                        if (vm.dayModel > 30) {
                            vm.dayModel = 30;
                        }
                    } else if (monthIndex === 2) {
                        daysInMonth = 28;
                        if (vm.dayModel > 28) {
                            vm.dayModel = 28;
                        }
                    }
                }
                var days = [];
                for (var index = 1; index <= daysInMonth; index++) {
                    days.push(index);
                }
                return days;
            }

            function getDaysDropDownListOptions() {
                var options = KendoUtilsService.getDropDownListOptions();
        
                options.dataSource.transport.read = function (event) {
                    var days;
                    var monthIndex = event.data.monthIndex || vm.monthModel;
                    if (monthIndex) {
                        days = getDaysInMonth(monthIndex);
                    } else {
                        days = getDaysInMonth();
                    }
                    event.success(days);
                };
                options.dataTextField = '';
                options.dataValueField = '';
                options.valuePrimitive = true;
                options.enable = !vm.disabled;
                return options;
            }

            function getMonthsDropDownListOptions() {
                var options = KendoUtilsService.getDropDownListOptions();
                var months = moment.months().map(function (month, index) {
                    return { value: index + 1, name: month };
                });
                options.dataSource = months;
                options.dataTextField = 'name';
                options.dataValueField = 'value';
        
                var monthsTemplate = '<div>' +
                    '<span class="capitalize" ng-bind="dataItem.name"></span>' +
                '</div>';
                options.change = function () {
                    var selectedValue = this.value();
                    $('#' + vm.selectorId + ' #day-input').data('kendoDropDownList').dataSource.read({monthIndex: selectedValue});
                    vm.onChange();
                };
                options.template = monthsTemplate;
                options.valueTemplate = monthsTemplate;
                options.valuePrimitive = true;
                options.enable = !vm.disabled;
                return options;
            }

            vm.onChange = function () {
                if (!!vm.changeFunction) {
                    vm.changeFunction();
                }
            };
        }],
        controllerAs: 'vm',
        templateUrl: '/ng1/components/shared/day-month-selector/day-month-selector.html'
    });
})();
