'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:stateIconFilter
 * @function
 * @description
 * # stateIconFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('stateIconFilter', ['MESSAGE_STATES', function (MESSAGE_STATES) {
    return function (state) {
        var icon = 'icon';
        switch(parseInt(state)){
            case MESSAGE_STATES.DRAFT:
                icon += ' icon-state-draft';
                break;
            case MESSAGE_STATES.PENDING:
                icon += ' icon-state-pending-approval yellow';
                break;
            case MESSAGE_STATES.EXPIRED:
                icon += ' icon-state-archived';
                break;
            case MESSAGE_STATES.PLANNED:
                icon += ' icon-state-scheduled blue';
                break;
            case MESSAGE_STATES.PUBLISHING_NOW:
            case MESSAGE_STATES.PUBLISHED:
            case MESSAGE_STATES.DELETED:
                icon = '';
                break;
            default:
                break;
        }
        return icon;
    };
}]);
