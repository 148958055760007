'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:InstallViaTestflightController
 * @description
 * # InstallViaTestflightController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('InstallViaTestflightController',
    ['$scope', '$window', 'GlobalService', 'companyTestflightConfiguration', 'defaultLanguage', 'LANGUAGES',
    function ($scope, $window, GlobalService, companyTestflightConfiguration, defaultLanguage, LANGUAGES) {

    $scope.companyTestflightConfiguration = companyTestflightConfiguration.data;

    $scope.openAppLink = function () {
        $window.open(companyTestflightConfiguration.data.testFlightUrl, '_system');
    };

    $scope.openTestflight = function () {
        $window.open('https://apps.apple.com/es/app/testflight/id899247664', '_system');
    };

    var init = function () {
        var language = companyTestflightConfiguration.language;
        if (LANGUAGES.indexOf(language) === -1) {
            language = defaultLanguage;
        }
        GlobalService.changeLanguage(language);
    };

    init();

}]);
