'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.conversationsTraysService
 * @description
 * # conversationsTraysService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ConversationsService',
    ['$http', '$timeout', '$translate', 'KendoUtilsService', 'SharedDataService', 'CONVERSATION_VALIDATION_STATE', 'PAGE_SIZE',
    function ($http, $timeout, $translate, KendoUtilsService, SharedDataService, CONVERSATION_VALIDATION_STATE, PAGE_SIZE) {

    var motivesIconValueTemplate = '<div class="message-category" data-ng-style="{\'background-color\': (dataItem.color | numberColorFilter)};">{{dataItem.motive}}</div>';

    var traysIconValueTemplate = '{{dataItem.tray}}';

    this.URL = SharedDataService.apiUrl + '/conversations';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/responsible',
            method: 'GET',
            params: options
        });
    };

    this.findAllWithoutResponse = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/responsible/without-response',
            method: 'GET',
            params: options
        });
    }

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.statistics = function () {
        return $http({
            url: this.URL + '/statistics',
            method: 'GET'
        })
    }

    var getPublicationAsFormData = function (publication, publicationAlias, resources) {
        var fd = new FormData();
        var content = typeof publication !== 'string' ? JSON.stringify(publication) : publication;
        
        fd.append(publicationAlias, new Blob([content], { type: "application/json" }));
        var index;
        if (resources && resources.length > 0) {
            for (index = 0; index < resources.length; index++) {
                fd.append('resources', resources[index]);
            }
        }
        return fd;
    };

    this.addMessageToConversationAsResponsible = function (id, message, resources) {
        var fd = getPublicationAsFormData(message, 'conversationMessage', resources);

        return $http({
            url: this.URL + '/responsible/' + id + '/messages',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.addNotesToConversationAsResponsible = function (id, note, resources) {
        var fd = getPublicationAsFormData(note, 'note', resources);

        return $http({
            url: this.URL + '/' + id + '/notes',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.getResponsibleConversation = function (id) {
        return $http({
            url: this.URL + '/responsible/' + id,
            method: 'GET'
        });
    };

    this.getResponsibleConversationNotes = function (id) {
        return $http({
            url: this.URL + '/' + id + '/notes',
            method: 'GET'
        });
    };
    
    this.closeConversation = function (id) {
        return $http({
            url: this.URL + '/' + id + '/close',
            method: 'PUT'
        });
    };

    this.reopenConversation = function (id) {
        return $http({
            url: this.URL + '/' + id + '/reopen',
            method: 'PUT'
        });
    };

    this.validateConversation = function (id, validationResponse) {
        var fd = new FormData();

        fd.append('validation', new Blob([JSON.stringify(validationResponse.validation)], { type: "application/json" }));

        var index;
        if (validationResponse.resources && validationResponse.resources.length > 0) {
            for (index = 0; index < validationResponse.resources.length; index++) {
                fd.append('resources', validationResponse.resources[index]);
            }
        }
        return $http({
            url: this.URL + '/' + id + '/validate',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.discardValidation = function (id) {
        return $http({
            url: this.URL + '/' + id + '/discard-validation',
            method: 'PUT'
        });
    }

    this.findAllMotives = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives',
            method: 'GET',
            params: options
        });
    };

    this.getMotive = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/' + id,
            method: 'GET'
        });
    };

    this.createMotive = function (motive) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives',
            method: 'POST',
            data: motive
        });
    };

    this.updateMotive = function (motive) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/' + motive.id,
            method: 'PUT',
            data: motive
        });
    };

    this.deleteMotive = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/' + id,
            method: 'DELETE'
        });
    };

    this.activateMotive = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/' + id + '/unarchive',
            method: 'PUT'
        });
    };

    this.archiveMotive = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/' + id + '/archive',
            method: 'PUT'
        });
    };

    this.getAllMotivesExceptOne = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-motives/all-responsible?motiveExcluded='+id,
            method: 'GET'
        });
    };

    this.getAllTraysExceptOne = function (id) {
        return $http({
            url: SharedDataService.apiUrl + '/feedback-trays/all?trayExcluded='+id,
            method: 'GET'
        });
    };

    this.changeMotive = function (id, motiveId) {
        return $http({
            url: this.URL + '/' + id + '/change-motive',
            method: 'PUT',
            data: motiveId
        });
    };

    this.changeTray = function (id, trayId) {
        return $http({
            url: this.URL + '/' + id + '/change-tray',
            method: 'PUT',
            data: trayId
        });
    };

    this.getMotivesIconValueDropDownListOptions = function() {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            $http({
                url: SharedDataService.apiUrl + '/feedback-motives/all-responsible',
                method: 'GET'
            }).then(function(response) {
                event.success(response.data);
            });
        };
        options.dataTextField = 'motive';
        options.optionLabel = {
            id: null,
            motive: $translate.instant('conversations.allMotives'),
            color: 0
        };
        options.optionLabelTemplate = '<span class="black45" translate="conversations.allMotives"></span>';
        options.template = motivesIconValueTemplate;
        options.valueTemplate = motivesIconValueTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    /**
     * Calls 2 different endpoints, depending on whether anonymous trays need to be included or not
     * @param  {Boolean} includeAnonymous True for include anonymous trays
     * @return {Object} The value of requested query
     */
    this.getTraysIconValueDropDownListOptions = function(includeAnonymous) {
        var options = KendoUtilsService.getDropDownListOptions();
        var url = includeAnonymous ? '/feedback-trays/all-responsible' : '/feedback-trays/all-responsible/public';
        options.dataSource.transport.read = function(event) {
            $http({
                url: SharedDataService.apiUrl + url,
                method: 'GET'
            }).then(function(response) {
                event.success(response.data);
            });
        };
        options.dataTextField = 'tray';
        options.optionLabel = {
            id: null,
            tray: $translate.instant('conversations.allTrays')
        };
        options.optionLabelTemplate = '<span class="black45" translate="conversations.allTrays"></span>';
        options.template = traysIconValueTemplate;
        options.valueTemplate = traysIconValueTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getConversationResources = function (id) {
        return $http({
            url: this.URL + '/' + id + '/resources',
            method: 'GET'
        });
    };

    this.createConversationWithEmployee = function(conversation,resources) {
        var fd = getPublicationAsFormData(conversation.data, 'conversation', resources);

        return $http({
            url: this.URL + '/responsible',
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    function getSelectedItemsId(data) {
        return data.reduce(function(selected, item) {
            if (item.enabled) {
                selected.push(item.id);
            }
            return selected;
        }, []);
    }

    function disableStateToggle(stateId) {
        var toggle = document.getElementById('validation-toggle-' + stateId);
        toggle.classList.add('disabled');
    }

    function enableAllStateToggles() {
        var elements = document.querySelectorAll('#validation-multiple-filter .toggle__container.disabled');
        for (var index = 0; index < elements.length; index++) {
            elements[index].classList.remove('disabled');
        }
    }

    this.getValidationStatesDropDownListOptions = function (selectedStates, onDataBound) {
        var validationStates = [{
            id: CONVERSATION_VALIDATION_STATE.NO_REQUIRED,
            name: 'conversations.noValidationRequired',
            enabled: !!selectedStates ? selectedStates.indexOf(CONVERSATION_VALIDATION_STATE.NO_REQUIRED) !== -1 : true,
            class: 'bg-white box-shadow__legend-circle--border'
        }, {
            id: CONVERSATION_VALIDATION_STATE.PENDING,
            name: 'conversations.pendingValidation',
            enabled: !!selectedStates ? selectedStates.indexOf(CONVERSATION_VALIDATION_STATE.PENDING) !== -1 : true,
            class: 'bg-pending-color'
        }, {
            id: CONVERSATION_VALIDATION_STATE.VALIDATED,
            name: 'conversations.validatedConversation',
            enabled: !!selectedStates ? selectedStates.indexOf(CONVERSATION_VALIDATION_STATE.VALIDATED) !== -1 : true,
            class: 'bg-approved-color'
        },{
            id: CONVERSATION_VALIDATION_STATE.REJECTED,
            name: 'conversations.rejectedConversation',
            enabled: !!selectedStates ? selectedStates.indexOf(CONVERSATION_VALIDATION_STATE.REJECTED) !== -1 : true,
            class: 'bg-rejected-color'
        }];
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource = validationStates;
        options.placeholder = $translate.instant('timeoff.requests.stateFilters.all');
        options.tagTemplate = '<span class="box-shadow__legend-circle flex align-items-start {{dataItem.class}}"></span>';
        options.itemTemplate = '<div id="validation-multiple-filter" class="multiselect-options">' +
                                '<span class="box-shadow__legend-circle vertical-align-label margin-right-10 {{dataItem.class}}"></span>' +
                                '<span class="multiselect-options__label" translate="{{dataItem.name}}"></span>' +
                                '<label ng-attr-id="validation-toggle-{{dataItem.id}}" class="toggle__container multiselect-options__switch margin-left-5 align-items-right">' +
                                    '<div class="toggle toggle--small" data-ng-class="{\'toggle--active\': dataItem.enabled}" style="width: 12px !important;">' +
                                        '<span class="toggle__indicator"></span>' +
                                    '</div>' +
                                '</label>' +
                            '</div>';
        options.dataBound = function(event) {
            // Remove hidden delete button and tooltip to avoid removing item on click
            $timeout(function () {
                $(event.sender.tagList).find('li span[aria-label="delete"]').remove();
            }, 200);
            if (!!onDataBound) {
                onDataBound();
            }
        };
        options.select = function(event) {
            event.dataItem.enabled = !event.dataItem.enabled;
            enableAllStateToggles();
        };
        options.deselect = function(event) {
            var selectedItemsId = getSelectedItemsId(event.sender.dataSource._data);
            if (selectedItemsId.length === 1) {
                event.preventDefault();
            } else {
                event.dataItem.enabled = !event.dataItem.enabled;
                var index = selectedItemsId.indexOf(event.dataItem.id);
                if (index > -1) {
                    selectedItemsId.splice(index, 1);
                    if (selectedItemsId.length === 1) {
                        disableStateToggle(selectedItemsId[0]);
                    }
                }
            }
        };
        options.open = function (event) {
            var selectedItemsId = getSelectedItemsId(event.sender.dataSource._data);
            if (selectedItemsId.length === 1) {
                disableStateToggle(selectedItemsId[0]);
            } else {
                enableAllStateToggles();
            }
        };
        options.autoClose = false;
        options.autoWidth = true;
        options.clearButton = false;
        options.valuePrimitive = true;
        return options;
    };

}]);
