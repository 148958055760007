'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:KnowledgeCenterController
 * @description
 * # KnowledgeCenterController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('KnowledgeCenterController',

    ['$rootScope', '$scope', '$timeout', '$location', '$translate', '$window', 'CategoryService', 'KendoUtilsService', 'KnowledgeCenterService', 'MessageService', 'ModalService',
        'PublicationService', 'CategoriesTreeService', 'DateUtilsService', 'SharedDataService', 'NotificationService', 'Session', 'CONTENT_KIND', 'MESSAGE_STATES', 'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH', 'EXPIRATION_MODE',
        'GRID_DATE_COLUMN_WIDTH', 'GRID_CATEGORY_COLUMN_WIDTH', 'GRID_RATING_COLUMN_WIDTH', 'RATING_KIND', 'CATEGORY_CONTAINER_STATE', 'PUBLICATION_SCHEDULED_KIND',
        function($rootScope, $scope, $timeout, $location, $translate, $window, CategoryService, KendoUtilsService, KnowledgeCenterService, MessageService, ModalService,
            PublicationService, CategoriesTreeService, DateUtilsService, SharedDataService, NotificationService, Session, CONTENT_KIND, MESSAGE_STATES, PAGE_SIZE, GRID_ICON_COLUMN_WIDTH, EXPIRATION_MODE,
            GRID_DATE_COLUMN_WIDTH, GRID_CATEGORY_COLUMN_WIDTH, GRID_RATING_COLUMN_WIDTH, RATING_KIND, CATEGORY_CONTAINER_STATE, PUBLICATION_SCHEDULED_KIND) {

            //******************************************* Attributes ************************************\\
            $scope.actionButtons = [];
            $scope.stateIsActive = true;
            $scope.states = MESSAGE_STATES;
            $scope.inputSearchField = "";
            $scope.selectedKnowledgePill = null;
            $scope.isCategoryContainerOpened = false;
            $scope.EXPIRATION_MODE = EXPIRATION_MODE;
            $scope.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;

            MESSAGE_STATES.ALL = 0;
            $scope.CATEGORY_CONTAINER_STATE = CATEGORY_CONTAINER_STATE;
            $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.CLOSED;
            $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
            $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
            $scope.highlightContentAllowed = Session.editorPermissions ? Session.editorPermissions.highlightContentAllowed : false;
            $scope.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;
            $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;

            var knowledgeCenterUpdatePath = '/knowledge-center/content/update/';

            $scope.messagesStates = [
                { id: MESSAGE_STATES.ALL, title: 'global.messages.stateFilters.all' },
                { id: MESSAGE_STATES.DRAFT, title: 'global.messages.stateFilters.draft' },
                { id: MESSAGE_STATES.PENDING, title: 'global.messages.stateFilters.pending' },
                { id: MESSAGE_STATES.PLANNED, title: 'global.messages.stateFilters.planned' },
                { id: MESSAGE_STATES.PUBLISHED, title: 'global.messages.stateFilters.published' }
            ];

            var gridColumns = [{
                field: "state",
                title: " ",
                template: '<span class="icon icon-star-fill kcenter-star" data-ng-if="dataItem.highlighted" ' +
                    'kendo-tooltip k-content="\'knowledgecenter.highlighted\' | translate"></span>' +
                    '<span class="{{dataItem.state | stateIconFilter}}" data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + '"' +
                    ' kendo-tooltip k-content="\'{{dataItem.state}}\' | stateTooltipFilter"></span>' +
                    '<div data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '"><spinner-dialenga></spinner-dialenga></div>',
                width: GRID_ICON_COLUMN_WIDTH,
                attributes: { class: "relative text-center" },
                sortable: false
            }, {
                field: "title",
                title: $translate.instant('model.message.title'),
                template: '<div class="flex"><no-default-language-tooltip available-languages="dataItem.availableLanguages"></no-default-language-tooltip>' +
                    '<div class="flex align-items-center"><span class="icon icon-gallery-simple icon-24 margin-right-5" ng-if="dataItem.gallery"></span><span class="truncate" data-ng-bind="dataItem.title" title="{{dataItem.title}}"></span></div></div>'
            }, {
                field: "category.name",
                title: $translate.instant('model.message.location'),
                template: '<span data-ng-bind-html="dataItem.category | messageCategoryFilter"></span>',
                width: GRID_CATEGORY_COLUMN_WIDTH
            }, {
                field: "publicationDate",
                title: $translate.instant('model.message.publicationDate'),
                type: "datetime",
                template: '<div class="flex">{{dataItem.publicationDate | date:\'dd/MM/yyyy HH:mm\'}}</div>',
                width: GRID_DATE_COLUMN_WIDTH
            }, {
                field: "autoExpiration.autoExpirationEnabled",
                title: " ",
                template: '<div data-ng-if="dataItem.autoExpiration.autoExpirationEnabled == true">' +
                                '<div data-ng-if="!expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) || (expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && (dataItem.state == states.EXPIRED || dataItem.state == states.PUBLISHED))">' +
                                    '<div ng-if="!dataItem.expirationMode || dataItem.expirationMode == EXPIRATION_MODE.AUTO_EXPIRED">' +
                                        '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDate\' | translate:dataItem"></span>' +
                                    '</div>' +
                                    '<div ng-if="dataItem.expirationMode == EXPIRATION_MODE.MANUALLY">' +
                                        '<span class="icon icon-autoarchive icon-40 grey-icon-color opacity-50" kendo-tooltip k-content="\'model.message.tooltipExpirationDateManual\' | translate:dataItem"></span>' +
                                    '</div>' +
                                '</div>' +
                                '<div data-ng-if="expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && dataItem.state !== states.EXPIRED && dataItem.state !== states.PUBLISHED">' +
                                    '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED">' +
                                        '<span class="icon icon-autoarchive icon-40 red-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDateExpired\' | translate:dataItem"></span>' +
                                    '</div>' +
                                    '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW">' +
                                        '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="getTooltipForScheduledNow(dataItem.autoExpiration)"></span>' +
                                    '</div>' +
                                '</div>' +
                            '</div>',
                width: 70,
                sortable: false
            }, {
                field: "dialengaHappinessScore",
                // title: $translate.instant('model.message.dhs'),
                headerTemplate: '<div class="flex">' + $translate.instant('model.message.dhs') + '</div>',
                headerAttributes: { class: "text-center" },
                template: '<div class="flex"><rating-icon data-ng-if="dataItem.dialengaHappinessScore" value="dataItem.dialengaHappinessScore" show-title="true"></rating-icon></div>',
                attributes: { class: "text-center" },
                width: Session.ratingKind === RATING_KIND.EMOJI ? GRID_ICON_COLUMN_WIDTH : GRID_RATING_COLUMN_WIDTH,
                sortable: false
            }, {
                command: [{
                    name: "action-button",
                    template: '<div class="td-on-hover"><a data-ng-class="{\'link-inactive opacity-50\': button.disabled}" class="k-button k-grid-edit-button" ' +
                        'kendo-tooltip k-content="\'{{button.title}}\' | translate" ' +
                        'data-ng-repeat="button in actionButtons[dataItem.state]" data-ng-click="performAction(dataItem, button.action)" ' +
                        'data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + 
                        ' && (!button.conditionalField || (!!dataItem[button.conditionalField] && dataItem.scheduleKind == ' + PUBLICATION_SCHEDULED_KIND.SCHEDULED +') || dataItem.scheduleKind == '+ PUBLICATION_SCHEDULED_KIND.NOW +')">' +
                        '<span data-ng-class="button.icon"></span></a>' +
                        '<span data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '" data-translate="global.states.publishing"></span></div>'
                }],
                headerAttributes: { class: "table-header-icons-container" },
                attributes: { class: 'text-right overflow-visible' },
                title: ""
            }];

            var gridDataSource = {
                transport: {
                    read: function(options) {
                        options.data.state = MessageService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
                        if ($scope.selectedCategoryId) {
                            options.data['publicationGrouping.id'] = $scope.selectedCategoryId;
                        }
                        saveGridPreferences(options);

                        var defaultSortOrder = ["highlighted,desc", "lastModifiedDate,desc", "publicationDate,desc"];
                        var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder, $scope.inputSearchField, 'omniSearch');
                        KnowledgeCenterService.findAll(requestOptions).then(function(response) {
                            PublicationService.planUpdateIfThereArePublishingPublications(response.data.content, $scope.knowledgeCenterGrid.dataSource);
                            $scope.numResults = response.data.totalElements;
                            options.success(response.data);
                        }, function() {
                            options.error();
                        });
                    }
                },
                schema: {
                    data: "content",
                    model: { id: "id" },
                    total: "totalElements",
                    parse: function(data) {
                        $.each(data.content, function(idx, elem) {
                            if (elem.publicationDate)
                                data.content[idx].publicationDate = new Date(elem.publicationDate);
                        });
                        return data;
                    }
                },
                pageSize: PAGE_SIZE,
                serverPaging: true,
                serverSorting: true
            };

            KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true }, { change: onChange, dataBound: onDataBound }).then(function(response) {
                $scope.gridOptions = response;
            });

            //******************************************** Methods **************************************\\
            var init = function() {
                $scope.actionButtons[MESSAGE_STATES.DRAFT] = [];
                $scope.actionButtons[MESSAGE_STATES.PENDING] = [];
                $scope.actionButtons[MESSAGE_STATES.PLANNED] = [];
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED] = [];
                $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW] = [];
                $scope.actionButtons[MESSAGE_STATES.EXPIRED] = [];
                $scope.actionButtons[MESSAGE_STATES.DELETED] = [];

                // User has Admin or publisher Rol or has editor rol and can publish contents (is like publisher rol)
                if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                    $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                    $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'validate', title: 'global.form.validate', icon: 'icon icon-double-validation' });
                    $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                    $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send' });
                    $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'pauseEdit', title: 'global.form.pauseedit', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
                    
                    if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                        $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                    }
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'archive', title: 'global.form.archive', icon: 'icon icon-archive' });
                    
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send', disabled: true });
                // User has Editor role and not allowed publish contents
                } else {

                    $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'requestValidation', title: 'global.form.pendingvalidation', icon: 'icon icon-first-validation', conditionalField: 'publicationDate' });
                    $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                    $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                    $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
                
                    $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                    
                    if ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) {
                        $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                    } 
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                    
                }
                if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                    $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                }
                $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
            };

            function saveGridPreferences(options) {
                KendoUtilsService.gridPreferences['knowledgeCenterGrid'] = angular.copy(options.data);
                KendoUtilsService.gridPreferences['knowledgeCenterGrid'].stateIsActive = $scope.stateIsActive;
                KendoUtilsService.gridPreferences['knowledgeCenterGrid'].selectedButton = $scope.selectedButton;
                KendoUtilsService.gridPreferences['knowledgeCenterGrid'].inputSearchField = $scope.inputSearchField;
                KendoUtilsService.gridPreferences['knowledgeCenterGrid'].selectedCategoryId = $scope.selectedCategoryId;
            }

            $scope.$on("kendoWidgetCreated", function(event, widget) {
                if (widget === $scope.knowledgeCenterGrid) {
                    if (KendoUtilsService.gridPreferences['knowledgeCenterGrid']) {
                        var requestOptions = KendoUtilsService.gridPreferences['knowledgeCenterGrid'];
                        $scope.stateIsActive = requestOptions.stateIsActive;
                        $scope.selectedButton = requestOptions.selectedButton;
                        $scope.inputSearchField = requestOptions.inputSearchField;
                        $scope.selectedCategoryId = requestOptions.selectedCategoryId;
                    } else {
                        $scope.selectedButton = MESSAGE_STATES.ALL;
                    }
                    CategoriesTreeService.selectCategory($scope.selectedCategoryId);
                }
            });

            $scope.getTooltipForScheduledNow = function(item) {
                return $translate.instant('model.message.tooltipExpirationDateFromNow', {amount: item.autoExpirationAmount, kind: item.autoExpirationKind}, 'messageformat');
            }

            function unselectKnowledgePill() {
                $scope.selectedKnowledgePill = null;
                $scope.enabledHighlightingButton = false;
            }

            function onDataBound() {
                KendoUtilsService.hideGridPagerIfJustOnePage(this);
                unselectKnowledgePill();

                PublicationService.hideGridColumnIfContentIsNotPublished($scope.knowledgeCenterGrid, 'dialengaHappinessScore', $scope.stateIsActive, $scope.selectedButton);
            }

            function onChange(e) {
                var selectedRow = this.select();
                var newSelectedKnowledgePill = $scope.knowledgeCenterGrid.dataItem(selectedRow[0]);

                if (newSelectedKnowledgePill !== $scope.selectedKnowledgePill) {
                    $scope.selectedKnowledgePill = (selectedRow.length > 0) ? newSelectedKnowledgePill : null;
                    $timeout(function() {
                        // Highlighting button is enabled if there's a selectedRow and the knowledgePill is published
                        $scope.enabledHighlightingButton = $scope.selectedKnowledgePill && parseInt($scope.selectedKnowledgePill.state) === MESSAGE_STATES.PUBLISHED;
                    });
                } else {
                    // Unselect the row
                    this.clearSelection();
                    unselectKnowledgePill();
                }
            }

            $scope.expirationDateIsEarlier = function (expirationDate) {
                var currentDate = new Date();
                return new Date(expirationDate) <= currentDate;
            }

            $scope.setSelected = function(button) {
                $scope.selectedButton = button.id;
                reloadGrid({ page: 1 });
            };

            $scope.listItemsSearch = function(text) {
                $scope.inputSearchField = text;
                if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                    reloadGrid({ page: 1 });
                }
            };

            function pauseAndEditContent(id, state) {
                var skipModalAlias = 'pauseAndEditPublication';
                var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                if (!shouldModalBeDisplayed) {
                    KnowledgeCenterService.pause(id)
                        .then(function() {
                            $location.path(knowledgeCenterUpdatePath + id);
                        })
                        .catch(actionErrorHandler);
                } else {
                    ModalService.openConfirmationModal({
                        title: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.title' : 'publication.modal.pauseAndEdit.titleForPending',
                        message: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.message' : 'publication.modal.pauseAndEdit.messageForPending',
                        messageData: { contentType: CONTENT_KIND.KNOWLEDGE_PILL },
                        acceptButtonText: 'global.form.edit',
                        skipModalAlias: skipModalAlias,
                        isTextInterpolated: true,
                        accept: function() {
                            KnowledgeCenterService.pause(id)
                                .then(function() {
                                    $location.path(knowledgeCenterUpdatePath + id);
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                }
            }

            function requestValidation(pillId, contentType) {
                KnowledgeCenterService.requestValidation(pillId)
                    .then(function() {
                        PublicationService.showSuccessNotification('publication.modal.validationRequest.validationRequested', contentType);
                        reloadGrid();
                    })
                    .catch(actionErrorHandler);
            }

            $scope.performAction = function(pill, action) {
                var contentType = CONTENT_KIND.KNOWLEDGE_PILL;
                var date, time;
                switch (action) {
                    case 'update':
                        if (pill.state === MESSAGE_STATES.PENDING && !$scope.isUserPublisherOrAdmin) {
                            pauseAndEditContent(pill.id, MESSAGE_STATES.PENDING);
                        } else {
                            $location.path(knowledgeCenterUpdatePath + pill.id);
                        }
                        break;
                    case 'view':
                        $location.path('/knowledge-center/view/' + pill.id);
                        break;
                    case 'validate':
                        date = DateUtilsService.getDateFromTimestamp(pill.publicationDate || undefined);
                        time = DateUtilsService.getTimeFromTimestamp(pill.publicationDate || undefined);
                        if (pill.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED && pill.publicationDate < new Date()) {
                            date = DateUtilsService.getDateFromTimestamp(undefined);
                            time = DateUtilsService.getTimeFromTimestamp(undefined);
                        }
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.validate.title',
                            message: 'publication.modal.validate.message',
                            messageData: {
                                contentType: contentType,
                                date: date,
                                time: time
                            },
                            acceptButtonText: 'publication.modal.validate.confirm',
                            isTextInterpolated: true,
                            accept: function() {
                                KnowledgeCenterService.validate(pill.id)
                                    .then(function() {
                                        PublicationService.showSuccessNotification('publication.modal.validate.validated', contentType);
                                        reloadGrid();
                                    })
                                    .catch(actionErrorHandler);
                            }
                        });
                        break;
                    case 'requestValidation':
                        if (pill.scheduleKind === PUBLICATION_SCHEDULED_KIND.SCHEDULED && !pill.publicationDate) {
                            return;
                        }
                        var skipModalAlias = 'requestValidation';
                        var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                        if (!shouldModalBeDisplayed) {
                            requestValidation(pill.id, contentType);
                        } else {
                            ModalService.openConfirmationModal({
                                title: 'publication.modal.validationRequest.title',
                                message: 'publication.modal.validationRequest.message',
                                messageData: { contentType: contentType },
                                acceptButtonText: 'global.form.pendingvalidation',
                                isTextInterpolated: true,
                                skipModalAlias: skipModalAlias,
                                accept: function() {
                                    requestValidation(pill.id, contentType);
                                }
                            });
                        }
                        break;
                    case 'pauseEdit':
                        pauseAndEditContent(pill.id);
                        break;
                    case 'delete':
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.delete.title',
                            message: 'publication.modal.delete.message',
                            messageData: { contentType: contentType },
                            acceptButtonText: 'global.form.delete',
                            isTextInterpolated: true,
                            accept: function() {
                                KnowledgeCenterService.delete(pill.id, 'knowledgecenter.error.delete')
                                    .then(reloadGrid)
                                    .catch(actionErrorHandler);
                            }
                        });
                        break;
                    case 'sendNow':
                        date = DateUtilsService.getDateFromTimestamp(pill.publicationDate);
                        time = DateUtilsService.getTimeFromTimestamp(pill.publicationDate);
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.send.title',
                            message: 'publication.modal.send.message',
                            messageData: {
                                contentType: contentType,
                                date: date,
                                time: time
                            },
                            acceptButtonText: 'global.form.sendnow',
                            isTextInterpolated: true,
                            accept: function() {
                                KnowledgeCenterService.publish(pill.id)
                                    .then(function() {
                                        PublicationService.showSuccessNotification('publication.modal.send.sent', contentType);
                                        reloadGrid();
                                    })
                                    .catch(actionErrorHandler);
                            }
                        });
                        break;
                    case 'statistics':
                        $location.path('/knowledge-center/statistics/' + pill.id);
                        break;
                    case 'archive':
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.archive.title',
                            message: 'publication.modal.archive.message',
                            messageData: { contentType: contentType },
                            acceptButtonText: 'global.form.archive',
                            isTextInterpolated: true,
                            focusCancel: true,
                            hideCancelButton: false,
                            accept: function() {
                                KnowledgeCenterService.expire(pill.id)
                                    .then(function() {
                                        PublicationService.showSuccessNotification('publication.modal.archive.archived', contentType);
                                        reloadGrid();
                                    })
                                    .catch(actionErrorHandler);
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            function reloadGrid(options) {
                $scope.isSyncing = true;
                PublicationService.updatePublications($scope.knowledgeCenterGrid.dataSource, options).then(function() {
                    $scope.$apply(function() {
                        $scope.isSyncing = false;
                    });
                });
            }

            $window.reloadKnowledgeCenterGrid = function(selectedCategoryId) {
                if ($location.path() === '/knowledge-center') {
                    $scope.selectedCategoryId = selectedCategoryId;
                    reloadGrid({ page: 1 });
                }
            };

            $window.showArchiveConfirmationModal = function(category, callback, self) {
                ModalService.openConfirmationModal({
                    title: 'categories.modal.archiveFolder.title',
                    message: 'categories.modal.archiveFolder.message',
                    messageData: { categoryName: category.name },
                    acceptButtonText: 'global.form.archive',
                    accept: function() {
                        CategoryService.archive(category.id).then(function() {
                            callback(self);
                        });
                    }
                });
            };

            function actionErrorHandler(error) {
                if (error.status === 422) {
                    reloadGrid();
                }
            }

            function redirectToPath(url) {
                $scope.$apply(function() {
                    $location.path(url);
                });
            }

            $scope.getPillsByIsActive = function(isActive) {
                if ($scope.stateIsActive !== isActive) {
                    $scope.stateIsActive = isActive;
                    reloadGrid({ page: 1 });
                }
            };

            $scope.changeHighlighting = function() {
                var error, highlightingService;
                if ($scope.selectedKnowledgePill.highlighted === false) {
                    error = 'knowledgecenter.error.highlight';
                    highlightingService = KnowledgeCenterService.highlight;
                } else {
                    error = 'knowledgecenter.error.unhighlight';
                    highlightingService = KnowledgeCenterService.unhighlight;
                }
                highlightingService.call(KnowledgeCenterService, $scope.selectedKnowledgePill.id, error).then(function() {
                    reloadGrid();
                }).finally(function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                });

            };

            $scope.getHighlightingTranslationKey = function(selectedRow) {
                if (selectedRow == null || selectedRow.highlighted === false) {
                    return 'knowledgecenter.sethighlighted';
                } else {
                    return 'knowledgecenter.setunhighlighted';
                }
            };

            $scope.cloneSelectedContent = function() {
                if (!$scope.selectedKnowledgePill || !$scope.selectedKnowledgePill.id) {
                    return;
                }

                KnowledgeCenterService.duplicate($scope.selectedKnowledgePill.id).then(function(response) {
                    $location.path(knowledgeCenterUpdatePath + response.data.id);
                    $timeout(function() {
                        KendoUtilsService.gridPreferences['knowledgeCenterGrid'].stateIsActive = true;
                        $scope.stateIsActive = true;
                    }, 500);
                });
            };

            /* Download CSV */

            $scope.openDatePopover = false;

            $scope.downloadCSV = function(event) {
                $scope.openDatePopover = false;

                var urlRequests = KnowledgeCenterService.URL + '/statistics/export-csv?startDate=' + event.startDate + '&endDate=' + event.endDate;
                var message = $translate.instant('statistics.downloading');
                var fileName = 'publications-statistics-' + moment().format("YYYYMMDD") + '.csv'
    
                var beginProcess = new Date().getTime();
    
                NotificationService.notifyDownload(message, true);
    
                KnowledgeCenterService.getStatisticsFileCSV(urlRequests).then(function(response) {
                    SharedDataService.saveDownloadedFile(response.data, fileName);
    
                    var endProcess = new Date().getTime();
                    var delayCloseNotification = (endProcess - beginProcess) <= 1500 ? 1500 - (endProcess - beginProcess) : 0;
    
                    $timeout(function() {
                        NotificationService.closeAllOpenedNotifications();
                    }, delayCloseNotification)
                });

            }

            $scope.cancelDownload = function(event) {
                $scope.openDatePopover = false;
            }

            /* End Download CSV */

            $scope.$on('$destroy', function() {
                PublicationService.stopWaitingForUpdates();
            });

            var onDblClick = function(dataItem) {
                var rowState = parseInt(dataItem.state);

                if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                    switch (rowState) {
                        case MESSAGE_STATES.DRAFT:
                        case MESSAGE_STATES.PENDING:
                            redirectToPath(knowledgeCenterUpdatePath + dataItem.id);
                            break;
                        case MESSAGE_STATES.PUBLISHED:
                            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                                redirectToPath('/knowledge-center/statistics/' + dataItem.id);
                            } else {
                                redirectToPath(knowledgeCenterUpdatePath + dataItem.id);
                            }
                            break;
                        case MESSAGE_STATES.PLANNED:
                            pauseAndEditContent(dataItem.id, MESSAGE_STATES.PLANNED);
                            break;
                        case MESSAGE_STATES.EXPIRED:
                            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                                redirectToPath('/knowledge-center/statistics/' + dataItem.id);
                            } else {
                                redirectToPath('/knowledge-center/view/' + dataItem.id);
                            }
                            break;
                        default:
                            break;
                    }
                // User has Editor role and not allowed publish contents
                } else {
                    switch (rowState) {
                        case MESSAGE_STATES.DRAFT:
                        case MESSAGE_STATES.PENDING:
                            redirectToPath(knowledgeCenterUpdatePath + dataItem.id);
                            break;
                        case MESSAGE_STATES.PLANNED:
                            redirectToPath('/knowledge-center/view/' + dataItem.id);
                            break;
                        case MESSAGE_STATES.PUBLISHED:
                        case MESSAGE_STATES.EXPIRED:
                            if ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) {
                                redirectToPath('/knowledge-center/statistics/' + dataItem.id);
                            } else {
                                redirectToPath('/knowledge-center/view/' + dataItem.id);
                            }
                            break;
                        default:
                            break;
                    }
                }
            };

            KendoUtilsService.dblClickGridCallback('kendo-grid', onDblClick);

            $scope.openCategoryMenu = function() {
                $scope.setCategoryContainerState($scope.CATEGORY_CONTAINER_STATE.OPENED);
            };

            $scope.setCategoryContainerState = function(state) {
                $scope.categoryContainerState = state;
                if ($scope.categoryContainerState === $scope.CATEGORY_CONTAINER_STATE.EDITION) {
                    $('#categoryMenuFolder').fadeOut(200, function() {
                        $('#menuCategoriesNotification').fadeIn(1000)
                    });
                } else if ($scope.categoryContainerState === $scope.CATEGORY_CONTAINER_STATE.OPENED) {
                    $('#menuCategoriesNotification').fadeOut(200, function() {
                        $('#categoryMenuFolder').fadeIn(1000)
                    });
                }
            };

            $scope.selectAllCategories = function() {
                if (!!$scope.selectedCategoryId && $scope.categoryContainerState != $scope.CATEGORY_CONTAINER_STATE.EDITION) {
                    CategoriesTreeService.clearSelectedCategoryPath();
                }
            };

            $scope.createNewContent = function() {
                KnowledgeCenterService.selectedCategoryPath = CategoriesTreeService.getSelectedCategoryPath();
                $location.path('/knowledge-center/create');
            };

            init();
        }
    ]);