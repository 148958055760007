'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UpdatePasswordController
 * @description
 * # UpdatePasswordController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UpdatePasswordController',
['$scope', '$location', '$rootScope', '$routeParams', 'GlobalService', 'SharedDataService', 'UsersService', 'defaultLanguage', 'LANGUAGES', 'STRENGTH_TYPE', 'USER_ROLES', 'resolvedParams',
    function ($scope, $location, $rootScope, $routeParams, GlobalService, SharedDataService, UsersService, defaultLanguage, LANGUAGES, STRENGTH_TYPE, USER_ROLES, resolvedParams) {

    //***************************************** Attributes **************************************\\
    var dialengaLogoPath = '/ng1/assets/img/logo-dialenga-texto.svg';

    $scope.updatePasswordForm = {
        user: {
            password: ''
        },
        passwordInputType: 'password'
    };

    $scope.title = resolvedParams.title;
    $scope.saveButtonText = resolvedParams.saveButtonText;
    $scope.showPrivacyPolicyLink = resolvedParams.showPrivacyPolicyLink;

    $scope.passwordStrength = {
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false,
        minLength: false
    };
    $scope.passwordStrengthValue = 0;

    //******************************************* Methods ***************************************\\
    $scope.isFormValid = function () {
        return (!resolvedParams.showPrivacyPolicyLink || $scope.updatePasswordForm.privacyPolicyAccepted) && $scope.updatePasswordForm.user.password &&
            $scope.updatePasswordForm.user.password.length > 0 && $scope.passwordStrengthValue === STRENGTH_TYPE.VALID;
    };

    function isBackofficeUser(userRoles) {
        for (var index = 0; index < userRoles.length; index++) {
            var role = userRoles[index];
            if (role === USER_ROLES.admin || role === USER_ROLES.manager || role === USER_ROLES.publisher || role === USER_ROLES.ftrayResponsible ||
                role === USER_ROLES.humanResources || role === USER_ROLES.documentsManager || role === USER_ROLES.teamResponsible || role === USER_ROLES.platformAdmin) {
                    return true;
                }
        }
        return false;
    }

    $scope.submit = function () {

        if ($scope.isFormValid()) {

            $scope.updatePasswordForm.user.token = $routeParams.token;

            resolvedParams.submitAction($scope.updatePasswordForm.user).then(function (response) {

                var hasBackofficeRole = isBackofficeUser(response.data.roles);
                if (response.data.company && response.data.company.configuration) {
                    var webAppConfiguration = response.data.company.configuration.webAppConfiguration;
                    $rootScope.userInfo = {
                        appName: response.data.company.configuration.appName,
                        brandLogoURL: webAppConfiguration ? webAppConfiguration.loginLogoUrl : null,
                        appLogoURL: response.data.company.emailLogoURL,
                        googlePlay: response.data.company.configuration.googlePlay,
                        appStore: response.data.company.configuration.appStore,
                        isAppUser: response.data.roles.indexOf(USER_ROLES.user) !== -1,
                        isBackofficeUser: hasBackofficeRole
                    };
                }

                $location.search({'token': null, 'lang': null});
                $location.path(resolvedParams.redirectionPath);

            }, function (error) {
                console.error(error);
                $rootScope.$broadcast('dialenga:loadingEnd');
            });

        } else {
            $rootScope.$broadcast('dialenga:loadingEnd');
        }
    };

    $scope.showHidePassword = function (fieldName) {
        $scope.updatePasswordForm[fieldName] = GlobalService.showHidePassword($scope.updatePasswordForm[fieldName]);
    };

    $scope.validatePasswordStrength = function () {
        $scope.passwordStrengthValue = UsersService.validatePasswordStrength($scope.updatePasswordForm.user.password, $scope.passwordStrength);
    };

    function initBrandingLogo() {
        if ($routeParams.c) {
            $scope.brandingLogo = SharedDataService.apiUrl + '/companies/logo?brand=' + $routeParams.c;
        } else {
            $scope.brandingLogo = dialengaLogoPath;
        }
    }

    var init = function () {
        initBrandingLogo();
        var language = resolvedParams.language;
        if (LANGUAGES.indexOf(language) === -1) {
            language = defaultLanguage;
        }
        GlobalService.changeLanguage(language);
    };

    init();
}]);
