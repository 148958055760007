/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsWorkplaceLocationController
 * @description
 * # TimeoffSettingsWorkplaceLocationController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsWorkplaceLocationController',
    ['$scope', '$rootScope', '$routeParams', '$translate', 'KendoUtilsService', 'LocationService', 'NotificationService', 'PublicationService', 'TimeoffSettingsWorkplacesService', 'UrlUtilsService', 'UsersService',
    function($scope, $rootScope, $routeParams, $translate, KendoUtilsService, LocationService, NotificationService, PublicationService, TimeoffSettingsWorkplacesService, UrlUtilsService, UsersService) {
        var vm = this;

        var map;
        var lastWorkplaceDirector = '';
        var emailPattern = new RegExp(/(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        vm.changed = false;
        vm.isValidForm = false;
        vm.isValidURLWebsite = true;
        vm.workplaceForm = { };
        vm.testButtonTooltip = $translate.instant('message.urlTest');
        vm.textareaOptions = KendoUtilsService.getTextareaOptions();
        vm.textareaOptions.keyup = function (e) {
            var targetField = document.getElementById(e.sender.element[0].id).getAttribute('k-ng-model').split('.');
            switch (targetField[2]) {
                case 'address':
                    vm.workplaceForm.address = this.value();
                    break;
                case 'businessHours':
                    vm.workplaceForm.businessHours = this.value();
                    break;
                case 'undergrounds':
                    vm.workplaceForm.undergrounds = this.value();
                    break;
                case 'buses':
                    vm.workplaceForm.buses = this.value();
                    break;
                case 'trains':
                    vm.workplaceForm.trains = this.value();
                    break;
                case 'parkings':
                    vm.workplaceForm.parkings = this.value();
                    break;
                case 'electricSpots':
                    vm.workplaceForm.electricSpots = this.value();
                    break;
                default:
                    break;
            }
            $scope.$apply(function () {
                vm.onChange();
            });
        };
        vm.textareaOptions.tools.splice(4,4);
        vm.textareaOptions.tools.splice(0,1);
        vm.textareaOptions.placeholder = $translate.instant('message.captionPlaceholder');
        vm.showUrlLink = UrlUtilsService.showUrlLink;

        vm.$onInit = function() {
            vm.countriesOptions = LocationService.getCountriesOptions(onCountrySet);
            vm.autonomousCommunitiesOptions = LocationService.getAutonomousCommunitiesOptions(vm.onChange);
            TimeoffSettingsWorkplacesService.findOne($routeParams.id).then(function (response) {
                vm.workplaceForm = response.data;
                vm.workplaceForm.latitude = vm.workplaceForm.latitude !== null ? parseFloat(response.data.latitude) : null;
                vm.workplaceForm.longitude = vm.workplaceForm.longitude !== null ? parseFloat(response.data.longitude) : null;
                if (!!response.data.country && response.data.country.id) {
                    setAutonomousCommunity(response.data.country.id);
                }
                TimeoffSettingsWorkplacesService.latestWorkplaceLocation = angular.copy(vm.workplaceForm);
                var worplaceDirectorFilter = !!vm.workplaceForm.director ? vm.workplaceForm.director.name : '';
                vm.worplaceDirectorOptions = UsersService.getEmployeeOptions(worplaceDirectorFilter);
                lastWorkplaceDirector = vm.workplaceForm.director;
                initializeStaticMap(vm.workplaceForm.latitude, vm.workplaceForm.longitude);
            });
        };

        function setAutonomousCommunity(countryId) {
            var autonomousCommunitiesDropdown = $('#autonomous-communities-dropdown').data('kendoDropDownList');
            autonomousCommunitiesDropdown.text('');
            autonomousCommunitiesDropdown.dataSource.read({
                countryId: countryId
            });
        }

        function onCountrySet(value) {
            if (value) {
                setAutonomousCommunity(value);
            }
            vm.onChange();
        }

        function initializeStaticMap(latitude, longitude) {
            if (map !== undefined) {
                map.remove();
            }
            var center, zoomLevel;
            if (latitude !== null && longitude !== null) {
                center = [parseFloat(latitude) || 40.749357547, parseFloat(longitude) || -73.986190129];
                zoomLevel = 17;
            } else {
                center = [40.749357547, -73.986190129];
                zoomLevel = 11;
            }
            map = L.map('static-map', {
                center: center,
                static: true,
                zoom: zoomLevel,
                zoomControl: false
            });

            var basemap = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
            L.tileLayer(basemap, {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions" target="_blank">CARTO</a>',
                subdomains: 'abcd',
                tileSize: 256

            }).addTo(map);

            if (latitude && longitude) {
                var locationIcon = L.icon({
                    iconUrl: '/ng1/assets/img/location.svg',
                    iconSize: [64, 64],
                    iconAnchor: [32, 32]
                });

                L.marker([parseFloat(latitude) || 40.749357547, parseFloat(longitude) || -73.986190129], {icon: locationIcon}).addTo(map);
            }
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
            if (map.tap) {
                map.tap.disable();
            }
        }

        vm.onChangeCoord = function(latitude, longitude) {
            if ((latitude !== null && longitude !== null) || (latitude == null && longitude == null)) {
                initializeStaticMap(latitude, longitude);
            }
            vm.onChange();
        };

        vm.onKeyPress = function(event) {
            var keycode = event.keyCode;
            if(keycode == '13'){
                event.preventDefault();
            }
        };

        vm.worplaceDirectorFocusOut = function() {
            if (!angular.equals(vm.workplaceForm.director, lastWorkplaceDirector)) {
                vm.onChange();
                lastWorkplaceDirector = vm.workplaceForm.director;
            }
        };

        function validateEmail() {
            return emailPattern.test(vm.workplaceForm.email);
        }

        function validateModel() {
            var isValidEmail = !vm.workplaceForm.email || validateEmail();
            vm.isValidForm = (vm.isValidURLWebsite || !vm.workplaceForm.website) && isValidEmail &&
                ( (!!vm.workplaceForm.latitude && !!vm.workplaceForm.longitude) || (!vm.workplaceForm.latitude && !vm.workplaceForm.longitude) );
        }

        vm.onChange = function() {
            vm.changed = true;
            validateModel();
        };

        vm.onChangeUrl = function() {
            var urlTested = {};
            urlTested = PublicationService.validateUrls(vm.workplaceForm.website);
            vm.isValidURLWebsite = urlTested.valid;
            vm.workplaceForm.website = urlTested.data;
            vm.onChange();
        };

        function dataHasChanged() {
            return !angular.equals(vm.workplaceForm, TimeoffSettingsWorkplacesService.latestWorkplaceLocation);
        }

        vm.goBack = function () {
            var isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(isFormDirty, 'settings/my-company/workplaces');
        };

        vm.saveWorkplaceLocation = function () {
            vm.workplaceForm.name = $scope.$parent.vm.workplaceForm.workplace.name;
            TimeoffSettingsWorkplacesService.update($routeParams.id, vm.workplaceForm).then(function () {
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1000);
                TimeoffSettingsWorkplacesService.latestWorkplaceLocation = vm.workplaceForm;
                $rootScope.$broadcast('dialenga:loadingEnd');
                vm.changed = false;
            }, function () {
                $rootScope.$broadcast('dialenga:loadingEnd');
            });
        };

    }
]);