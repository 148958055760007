'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:passwordCheck
 * @description
 * # passwordCheck
 */
angular.module('dialengaExeviBackoffice').directive('passwordCheck', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            var firstPassword = '#' + attrs.passwordCheck;
            elem.add(firstPassword).on('keyup', function () {
                scope.$apply(function () {
                    var isValid = elem.val() === $(firstPassword).val();
                    ctrl.$setValidity('passwordnotmatch', isValid);
                });
            });
        }
    }
});