'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffSettingsWorkplaceController
 * @description
 * # TimeoffSettingsWorkplaceController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffSettingsWorkplaceController',
    ['$rootScope', '$route', '$routeParams', '$translate', '$location', 'NotificationService', 'TimeoffSettingsWorkplacesService', 'AuthenticationService', 'TIMEOFF_SETTINGS_WORKPLACE_TABS', 'MODULES', 'selectedTab',
    function($rootScope, $route, $routeParams, $translate, $location, NotificationService, TimeoffSettingsWorkplacesService, AuthenticationService, TIMEOFF_SETTINGS_WORKPLACE_TABS, MODULES, selectedTab) {
        var vm = this;

        vm.isFormSubmiting = false;
        vm.TIMEOFF_SETTINGS_WORKPLACE_TABS = TIMEOFF_SETTINGS_WORKPLACE_TABS;
        vm.selectedTab = selectedTab;
        var titleEditableSelectedText;
        var pressedKeys = {};

        vm.workplaceId = $routeParams.id;
        vm.isChangedForm = false;
        vm.workplaceForm = {
            workplace: { }
        };

        vm.loadTabContent = function(tab) {
            if (vm.selectedTab !== tab) {
                vm.selectedTab = tab;
                if (tab === TIMEOFF_SETTINGS_WORKPLACE_TABS.LOCATION) {
                    $route.updateParams({ tabName: 'location' });
                } else if (tab === TIMEOFF_SETTINGS_WORKPLACE_TABS.HOLIDAYS) {
                    $route.updateParams({ tabName: 'holidays' });
                } else if (tab === TIMEOFF_SETTINGS_WORKPLACE_TABS.EMPLOYEES) {
                    $route.updateParams({ tabName: 'employees' });
                }
            }
        };

        function workplaceNameSave() {
            vm.isFormSubmiting = true;
            TimeoffSettingsWorkplacesService.update($routeParams.id, vm.workplaceForm.workplace).then(function () {
                vm.isFormSubmiting = false;
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1000);
            }, function() {
                vm.isFormSubmiting = false;
            });
        }

        vm.editableNameFocusOut = function($event) {
            var name = $event.currentTarget.innerText.trim();
            if (name !== '') {
                vm.workplaceForm.workplace.name = name;
                workplaceNameSave();
            } else if (vm.workplaceForm.workplace.name) {
                $event.currentTarget.innerText = vm.workplaceForm.workplace.name;
            }
        };

        vm.editableTitleKeyDown = function(event) {
            contentEditableKeyDown(event, titleEditableSelectedText);
            titleEditableSelectedText = '';
        };

        vm.limitTitleMaxLengthPaste = function(event) {
            titleEditableSelectedText = getSelectedText(event);
            limitMaxLengthPaste(event, titleEditableSelectedText);
        };

        vm.onTitleSelectionChange = function(event) {
            titleEditableSelectedText = getSelectedText(event);
        };

        vm.titleEditableKeyUp = function(event) {
            contentEditableKeyUp(event);
            vm.onTitleSelectionChange(event);
        };

        function contentEditableKeyDown(event, selectedText) {
            var keyCode = event.which || event.keyCode;
            pressedKeys[keyCode] = true;
            if (keyCode === 13) {
                event.currentTarget.blur();
            } else {
                preventIfMaxLengthReached(event, keyCode, selectedText);
            }
        }

        function contentEditableKeyUp(event) {
            var keyCode = event.which || event.keyCode;
            delete pressedKeys[keyCode];
        }

        vm.addHolidaysToWorkplace = function () {
            $rootScope.$broadcast('dialenga:addWorkplaceHolidays');
        };

        vm.addEmployeesToWorkplace = function () {
            $rootScope.$broadcast('dialenga:addWorkplaceEmployees');
        };

        function preventIfMaxLengthReached(event, keyCode, selectedText) {
            var editableContent = event.target;
            // Allow delete, ctrl, arrows and supr keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            var allowedKeys = [8, 17, 37, 38, 39, 40, 46, 91, 93, 224];
            if (allowedKeys.indexOf(keyCode) === -1 && editableContent.attributes.maxlength && editableContent.attributes.maxlength.value) {
                if (editableContent.innerText.length >= editableContent.attributes.maxlength.value) {
                    // If a selection is made, allow pressed key even if the maxlength is reached, as its value will replace the selected text
                    // Also allow combination of two keys involving ctrl key (for copy-paste functionality)
                    if ((!selectedText || selectedText.length === 0) && (Object.keys(pressedKeys).length !== 2 || (!pressedKeys[17] &&
                            !pressedKeys[91] && !pressedKeys[93] && !pressedKeys[224]))) {
                        event.preventDefault();
                    }
                }
            }
        };

        function getSelectedText(event) {
            var keyCode = event.which || event.keyCode;
            // Discard enter, shift, ctrl, alt, caps lock and escape keys, and Command key in Safari / Chrome (left, right) and Firefox (both the same)
            if ([13, 16, 17, 18, 20, 27, 91, 93, 224].indexOf(keyCode) !== -1) {
                return '';
            }
            var selectedText = '';
            if (window.getSelection) {
                // IE
                selectedText = window.getSelection().toString();
            } else if (document.selection && document.selection.type !== "Control") {
                // The document.selection.type checks that the selection is text rather than a control
                selectedText = document.selection.createRange().text;
            }
            return selectedText;
        }

        function limitMaxLengthPaste(event, selectedText) {
            event.preventDefault();
            var text;
            var maxLength = event.target.attributes.maxlength ? event.target.attributes.maxlength.value : 256;
            var remainingChars = maxLength - event.target.innerText.length;

            if (remainingChars > 0 || selectedText.length > 0) {
                if ((event.originalEvent || event).clipboardData) {
                    text = (event.originalEvent || event).clipboardData.getData('text/plain');
                    // Increase remaining chars with selected text length as it will be deleted when pasted new content
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.execCommand('insertText', false, text);
                } else if (window.clipboardData) {
                    // Compatible with Internet Explorer
                    text = window.clipboardData.getData('Text');
                    remainingChars += selectedText.length;
                    text = text.substring(0, remainingChars);
                    document.selection.createRange().pasteHTML(text);
                }
            }

        }

        function init () {
            if (vm.workplaceId) {
                TimeoffSettingsWorkplacesService.findOne(vm.workplaceId).then(function (response) {
                    vm.workplaceForm.workplace = response.data;
                });
            }
        }

        init();

    }
]);