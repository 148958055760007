'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.languageService
 * @description
 * # languageService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('LanguageService',
    ['ArrayUtilsService', 'Session', 'SharedDataService', 'UrlUtilsService', 'QUESTION_KIND',
    function (ArrayUtilsService, Session, SharedDataService, UrlUtilsService, QUESTION_KIND) {

    this.getMainContentLanguage = function (contents) {
        return SharedDataService.getMainContentLanguage(contents);
    };

    function deleteEmptyLanguages(dataContent) {
        if (!dataContent) { return; }
        
        var keys = Object.keys(dataContent);
        for (var index = 0; index < keys.length; index++) {
            var language = keys[index];
            var languageContent = dataContent[language];
            if (!ArrayUtilsService.objectHasAnyValue(languageContent)) {
                // If language is empty, delete it
                delete dataContent[language];
            }
        }
    }

    this.removeEmptyLanguages = function (formData) {
        if (!formData.contents) {
            return;
        }

        deleteEmptyLanguages(formData.contents);

        if (formData.answers) {
            for (var index = 0; index < formData.answers.length; index++) {
                var answer = formData.answers[index];
                deleteEmptyLanguages(answer.contents);
            }
        }
    };

    function isCurrentLanguagePublicationValid(content) {
        return typeof content !== 'undefined'
            && content.title
            && (content.text && content.text.trim())
            && UrlUtilsService.isValidExternalURL(content.externalURL)
            && UrlUtilsService.isValidExternalURL(content.youtubeURL);
    }

    this.validatePublicationNonEmptyLanguages = function (formData, validationFunction) {
        var valid = true;
        var nonEmptyLanguages = [];

        validationFunction = validationFunction || isCurrentLanguagePublicationValid;

        var keys = Object.keys(formData.contents);
        for (var index = 0; index < keys.length; index++) {
            var language = keys[index];
            var content = formData.contents[language];
            if (ArrayUtilsService.objectHasAnyValue(content)) {
                // If not empty, validate language
                nonEmptyLanguages.push(language);
                if (!validationFunction(content)) {
                    valid = false;
                    break;
                }
            }
        }
        return valid && nonEmptyLanguages.length > 0;
    };

    this.validateQuestionnaireNonEmptyLanguages = function (formData, validationFunction) {
        var valid = true;
        var nonEmptyLanguages = [];

        validationFunction = validationFunction || isCurrentLanguagePublicationValid;

        var keys = Object.keys(formData.contents);
        for (var index = 0; index < keys.length; index++) {
            var language = keys[index];
            var content = formData.contents[language];
            if (ArrayUtilsService.objectHasAnyValue(content)) {
                // If not empty, validate language
                nonEmptyLanguages.push(language);
                if (!validationFunction(content, formData.kind)) {
                    valid = false;
                    break;
                }
            }
        }

        if (valid && formData.kind === QUESTION_KIND.SINGLE_CHOICE) {
            if (formData.answers) {
                var numValidAnswers = 0;
                // Check if any answer belongs to a non-filled language.
                // If so, the form is not valid
                for (var index = 0; index < formData.answers.length; index++) {
                    var answer = formData.answers[index];
                    var nonEmptyAnswerLanguages = [];
                    var keys = Object.keys(answer.contents);
                    for (var languageIndex = 0; languageIndex < keys.length; languageIndex++) {
                        var language = keys[languageIndex];
                        if (!!answer.contents[language].text && answer.contents[language].text.trim() !== '') {
                            nonEmptyAnswerLanguages.push(language);
                        }
                    }
                    if (nonEmptyAnswerLanguages.length === 0
                        || JSON.stringify(nonEmptyAnswerLanguages.sort()) !== JSON.stringify(nonEmptyLanguages.sort())) {
                        valid = false;
                        break;
                    } else {
                        numValidAnswers++;
                    }
                }
                if (numValidAnswers < 2) {
                    valid = false;
                }
            } else {
                valid = false;
            }
        }

        return valid && nonEmptyLanguages.length > 0;
    };

    this.getDisabledTooltipTextBasedOnAvailableLanguages = function() {
        return (Session.companyLanguages && Session.companyLanguages.length > 1) ? 'formValidation.invalidMultipleLanguages' : 'formValidation.invalidSingleLanguage';
    };

}]);
