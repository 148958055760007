'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.segmentService
 * @description
 * # segmentService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('SegmentService',
    ['$http', '$translate', 'KendoUtilsService', 'SharedDataService', 'POPULATION', 'PAGE_SIZE',
    function ($http, $translate, KendoUtilsService, SharedDataService, POPULATION, PAGE_SIZE) {

    var segmentLabelTemplate = '<div class="flex align-items-center list-item-height">' +
                        '<i class="icon icon-24 margin-right-10" ng-class="{\'icon-globe\' : !dataItem.id}"></i>' +
                        '<span class="truncate" ng-bind="dataItem.name"></span>' +
                        '</div>';

    var segmentTemplate = '<div class="flex align-items-center list-item-height">' +
            '<i class="icon icon-24 icon-group margin-right-5"></i> <span class="truncate" ng-bind="dataItem.name"></span>' +
        '</div>';

    var segmentSelectedTemplate = '<div>' +
            '<i class="icon icon-users-fill margin-right-5"></i>' +
            ' <span ng-bind="dataItem.name"></span>' +
        '</div>';

    function getDropDownListOptions(options, labelName, currentSegmentId, changeCallback) {
        options.dataSource = new kendo.data.DataSource({
            transport: {
                read: function(event) {
                    $http({
                        url: SharedDataService.apiUrl + '/segments',
                        method: 'GET',
                        params: {
                            sort: 'name',
                            active: true
                        }
                    }).then(function(response) {
                        var dropDownItems = response.data.content;
                        if (currentSegmentId) {
                            // Populate DropDownList with all segments except the current segment
                            dropDownItems = dropDownItems.filter(function(item) {
                                return item.id !== currentSegmentId;
                            });
                        }
                        event.success(dropDownItems);
                    });
                }
            },
            change: changeCallback
        });
        options.template = segmentTemplate;
        options.optionLabelTemplate = segmentLabelTemplate;
        options.optionLabel = {
            id: null,
            name: $translate.instant(labelName),
            description: ''
        };
        return options;
    }

    this.URL = SharedDataService.apiUrl + '/segments';

    this.findAll = function (options) {
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findAllPaginated = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return this.findAll(options);
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.create = function (data) {
        return $http({
            url: this.URL,
            method: 'POST',
            data: data.segment
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL + '/' + data.segment.id,
            method: 'PUT',
            data: data.segment
        });
    };

    this.delete = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE'
        });
    };

    this.archive = function (id) {
        return $http({
            url: this.URL + '/' + id + '/archive',
            method: 'PUT'
        });
    };

    this.activate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/activate',
            method: 'PUT'
        });
    };

    this.statistics = function () {
        return $http({
            url: this.URL + '/statistics',
            method: 'GET'
        });
    };

    this.getSegmentsDropDownListOptions = function(changeCallback) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.valueTemplate = segmentSelectedTemplate;
        return getDropDownListOptions(options, 'global.form.notSet', null, changeCallback);
    };

    this.getSegmentedUsersDropDownListOptions = function(labelName, currentSegmentId) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.valueTemplate = segmentTemplate;
        return getDropDownListOptions(options, labelName, currentSegmentId);
    };

    this.getSegmentUsers = function(segmentId, options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL + '/' + segmentId + '/users',
            method: 'GET',
            params: options
        });
    };

    this.saveSegmentUsers = function(segmentId, segmentUsers) {
        return $http({
            url: this.URL + '/' + segmentId + '/users',
            method: 'POST',
            data: segmentUsers
        });
    };

    this.getSortCriteria = function (sortOptions, defaultValue) {
        var sort = defaultValue;
        if (sortOptions && sortOptions.length > 0) {
            var sortField = sortOptions[0].field;
            if (sortField === 'name') {
                sortField = 'user.name';
            }
            else if (sortField === 'surname') {
                sortField = 'user.surname';
            }
            else if (sortField === 'email') {
                sortField = 'user.email';
            }
            else if (sortField === 'state') {
                sortField = 'user.state';
            }
            sort = sortField + ',' + sortOptions[0].dir;
        }
        return sort;
    };

    this.deleteUserFromSegment = function (segmentId, userId, errorMessage) {
        return $http({
            url: this.URL + '/' + segmentId + '/users/' + userId,
            method: 'DELETE',
            localizedErrorString: errorMessage
        });
    };

}]);
