(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:timezoneWarning
     * @description
     * # timezoneWarning
     */
    angular.module('dialengaExeviBackoffice').directive('timezoneWarning',
        ['Session', 'DateUtilsService',
        function (Session, DateUtilsService) {
        return {
            restrict: 'E',
            replace: true,
            scope: {},
            controller: ['$scope', function($scope) {
                $scope.userTimeZone = moment.tz.guess();
                $scope.companyTimeZone = Session.companyTimeZone;
                $scope.regionOffset = DateUtilsService.getRegionsOffset($scope.userTimeZone, $scope.companyTimeZone);
            }],
            templateUrl: '/ng1/components/timezone-warning/timezone-warning.html'
        };
    }]);
})();
