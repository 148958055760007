'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayContentController
 * @description
 * # FeedbackTrayContentController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayContentController', ['$scope', 'ModalService',
    function($scope, ModalService) {
        var vm = this;
        vm.isPreviewVisible = true;
        vm.defaultTrayPreviewColor = 7065068; // #6BCDEC
        vm.showMore = false;

        vm.closePreview = function() {
            vm.isPreviewVisible = false;
        };

        vm.addResponsibles = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/feedback-tray/feedback-tray-reponsibles-modal.html',
                controller: 'FeedbackTrayResponsiblesModalController',
                size: 'xl',
                resolveParams: {
                    responsibles: $scope.feedbackTray.responsibles
                },
                accept: function (users) {
                    if (users) {
                        $scope.feedbackTray.responsibles = [];
                        for (var idx = 0; idx < users.length; idx++) {
                            $scope.feedbackTray.responsibles.push(users[idx]);
                            if (idx === users.length - 1) {
                                $scope.onChange();
                            }
                        }
                    }
                }
            });
        };

        function getTrayMotivesIds(motives) {
            var motivesIds = [];
            angular.forEach(motives, function (value, index) {
                motivesIds.push(value.id)
            });
            return motivesIds;
        }

        vm.toggleShowAnonymousDisclaimerState = function() {
            vm.showMore = !vm.showMore;
            var parsedAnonymousTrayBand = JSON.parse(localStorage.getItem('anonymousTrayBand'));
            var trayIndex = parsedAnonymousTrayBand.findIndex(function(item) {
                return item.trayId == $scope.feedbackTrayId;
            });
            if (trayIndex > -1) {
                parsedAnonymousTrayBand[trayIndex].showInfo = vm.showMore;
                localStorage.setItem('anonymousTrayBand', JSON.stringify(parsedAnonymousTrayBand));
            }
        }

        function getShowAnonymousDisclaimerState(trayId) {
            var state = true;
            if (trayId) {
                var anonymousTrayBand = localStorage.getItem('anonymousTrayBand');
                var parsedAnonymousTrayBand = !!anonymousTrayBand ? JSON.parse(anonymousTrayBand) : [];

                var trayIndex = parsedAnonymousTrayBand.findIndex(function(item) {
                    return item.trayId == trayId;
                });

                if (trayIndex > -1) {
                    state = parsedAnonymousTrayBand[trayIndex].showInfo;
                } else {
                    parsedAnonymousTrayBand.push({trayId: trayId, showInfo: true});
                    localStorage.setItem('anonymousTrayBand', JSON.stringify(parsedAnonymousTrayBand));
                }
            }
            return state;
        }

        vm.addMotives = function () {
            ModalService.openModal({
                templateUrl: '/ng1/components/feedback-tray/feedback-tray-motives-modal.html',
                controller: 'FeedbackTrayMotivesModalController',
                size: 'md',
                resolveParams: {
                    motives: getTrayMotivesIds($scope.feedbackTray.motives)
                },
                accept: function (motives) {
                    delete $scope.feedbackTray.motives;
                    $scope.feedbackTray.motives = angular.copy(motives);
                    $scope.onChange();
                }
            });
        };

        vm.$onInit = function() {
            vm.showMore = getShowAnonymousDisclaimerState($scope.feedbackTrayId);
        }
    }
]);