(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:timeoffRequestDetail
     * @description
     * # timeoffRequestDetail
     */
    angular.module('dialengaExeviBackoffice').component('timeoffRequestDetail', {
        bindings: {
            request: '<',
            isVisible: '<',
            closeView: '&',
            reloadGrid: '&',
            filterState: '<'
        },
        controller: 'TimeoffRequestDetailController',
        controllerAs: 'self',
        templateUrl: '/ng1/components/timeoff-request/timeoff-request-detail.html'
    });
})();