'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:arrayToStringFilter
 * @function
 * @description
 * # arrayToStringFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('arrayToStringFilter', function () {
    return function (array, fields) {
        var result = '';

        if (angular.isArray(array) && array.length) {
            if (fields) {
                var splitedFields = fields.split(',');
                var resultArray = [];

                for (var i = 0; i < array.length; i++) {
                    var value = '';

                    for (var j = 0; j < splitedFields.length; j++) {
                        value += array[i][splitedFields[j]];
                        if (j < splitedFields.length - 1) {
                            value += ' ';
                        }
                    }

                    resultArray.push(value);
                }

                result = resultArray.join(', ');
            } else {
                result = array.join(', ');
            }
        }

        return result;
    };
});
