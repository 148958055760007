/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserDocumentModalController
 * @description
 * # UserDocumentModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserDocumentModalController', ['$uibModalInstance', '$timeout', 'params',
    function($uibModalInstance, $timeout, params) {

        var vm = this;
        vm.document = params.document;

        function render() {
            var options = {
                height: '100%',
                forcePDFJS: true,
                pdfOpenParams : {
                    statusbar: 1,
                } 
            }

            $timeout(function () {
                PDFObject.embed(vm.document.pdf, "#pdf_viewer", options)
            }, 800);
        }

        vm.accept = function() {
            
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function init() {
            render();
        }

        init();
    }
]);