'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:absenceMotiveIconFilter
 * @function
 * @description
 * # absenceMotiveIconFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('absenceMotiveIconFilter', ['ABSENCE_MOTIVE_GROUPS', function (ABSENCE_MOTIVE_GROUPS) {
    return function (absenceMotiveGroupId) {
        var icon = '';
        switch (absenceMotiveGroupId) {
            case ABSENCE_MOTIVE_GROUPS.HEALTH:
                icon = 'icon-health blue-color';
                break;
            case ABSENCE_MOTIVE_GROUPS.PERSONAL:
                icon = 'icon-personal-issues purple-color';
                break;
            case ABSENCE_MOTIVE_GROUPS.PROFESSIONAL:
                icon = 'icon-professional-issues light-brown-color';
                break;
            case ABSENCE_MOTIVE_GROUPS.NO_REMUNERABLE:
                icon = 'icon-family-health light-green-color';
                break;
            case ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS:
                icon = 'icon-free-disposal-days';
                break;
        }
        return icon;
    };
}]);
