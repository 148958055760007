'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.ActiveFeaturesService
 * @description
 * # ActiveFeaturesService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('ActiveFeaturesService',
    ['$http', 'SharedDataService', 
    function ($http, SharedDataService) {

    this.URL = SharedDataService.apiUrl + '/companies';

    this.getConfigurationModules = function (id) {
        return $http({
            url: this.URL + '/' + id + '/configuration/modules' ,
            method: 'GET'
        });
    };

    this.enableModule = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/enable',
            method: 'PUT'
        });
    };

    this.disableModule = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/disable',
            method: 'PUT'
        });
    };

    this.temporarilyDeactivateModule = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/disableTemporarily',
            method: 'PUT'
        });
    };

    this.temporarilyActivateModule = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/enableForEmployees',
            method: 'PUT'
        });
    };

    this.enableByDefault = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/defaultEnabled',
            method: 'PUT'
        });
    };

    this.disableByDefault = function (companyId, moduleId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/modules/' + moduleId + '/defaultDisabled',
            method: 'PUT'
        });
    };

    this.showSignature = function(companyId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/remote-signature/showBackoffice',
            method: 'PUT'
        })
    }

    this.hideSignature = function(companyId) {
        return $http({
            url: this.URL + '/' + companyId + '/configuration/remote-signature/hideBackoffice',
            method: 'PUT'
        })
    }

    this.getRemoteSignatureStats = function() {
        return $http({
            url: SharedDataService.apiUrl + '/remote-signatures/statistics' ,
            method: 'GET'
        });
    }
}]);