'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:htmlToPlaintextFilter
 * @function
 * @description
 * # htmlToPlaintextFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('htmlToPlaintextFilter', function () {
    return function (text) {
        return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
});
