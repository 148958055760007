(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:questionDialog
     * @description
     * # questionDialog
     */
    angular.module('dialengaExeviBackoffice').component('questionDialog', {
        bindings: {
            index: '<',
            isHidden: '<?',
            isTray: '<?',
            showEndingElement: '<?',
            hasCover: '=',
            questionnaireKind: '<'
        },
        templateUrl: '/ng1/components/questionnaire-question/question-dialog.html',
        controllerAs: 'vm',
        controller: ['$location', '$routeParams', 'QuestionnaireService', 'FeedbackTraysService', 'QUESTIONNAIRE_KIND', 'QUESTION_KIND', 'TRAY_QUESTION_KIND',
            function($location, $routeParams, QuestionnaireService, FeedbackTraysService, QUESTIONNAIRE_KIND, QUESTION_KIND, TRAY_QUESTION_KIND) {
            var vm = this;

            vm.questionsKinds = function() {
                return vm.questionnaireKind === QUESTIONNAIRE_KIND.SURVEY ? QUESTION_KIND : TRAY_QUESTION_KIND;
            }

            vm.showQuestionDialog = function() {
                vm.isQuestionDialogOpened = true;
            };

            vm.closeQuestionDialog = function () {
                vm.isQuestionDialogOpened = false
            };

            function createQuestionAndRedirect(questionKind) {
                if ( vm.isTray ) {
                    FeedbackTraysService.newContentKind = questionKind;
                    FeedbackTraysService.newContentOrder = vm.index;
                    var addOrEditElementUrl = '/settings/conversations/feedback-trays/questionnaire/' + $routeParams.id;
                    addOrEditElementUrl += questionKind == vm.questionsKinds().ENDING ? '/endings/' : '/questions/';
                    $location.path(addOrEditElementUrl);
                } else {
                    QuestionnaireService.newContentKind = questionKind;
                    QuestionnaireService.newContentOrder = questionKind == vm.questionsKinds().COVER ? 0 : vm.index;
                    $location.path('/questionnaires/' + $routeParams.id + '/questions/');
                }
            }

            vm.createOptionsListQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().SINGLE_CHOICE);
            };

            vm.createFreeAnswerQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().OPEN);
            };

            vm.createAttachementQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().UPLOAD_FILE);
            };

            vm.createCommentQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().COMMENT);
            };

            vm.createCoverQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().COVER);
            };

            vm.createEndingQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().ENDING);
            };

            vm.createRedirectionQuestion = function() {
                createQuestionAndRedirect(vm.questionsKinds().REDIRECT);
            };
        }]
    });
})();