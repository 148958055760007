(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name dialengaExeviBackoffice.directive:searchInput
     * @description
     * # searchInput
     */
    angular.module('dialengaExeviBackoffice').directive('searchInput', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                placeholder: '@',
                modelQuery: '=',
                searchFunction: '&?',
                ngDisabled: '=',
                classes: '@?',
                inputId: '<?'
            },
            template:
                '<div class="relative">' +
                    '<input type="text" ng-attr-id="{{inputId}}" class="input input--search {{classes}}" maxlength="1024" placeholder="{{placeholder}}"' +
                        ' ng-model="modelQuery" ng-model-options="{ debounce: 200 }" ng-change="updateModel()"' +
                        ' ng-disabled="ngDisabled" />' +
                    '<div class="icon icon-remove" data-ng-click="clearSearchInput()"></div>' +
                '</div>',
            controller: ['$scope', function ($scope) {
                $scope.updateModel = function () {
                    if (!!$scope.searchFunction) {
                        if ($scope.modelQuery.length > 2) {
                            $scope.searchFunction({text: $scope.modelQuery});
                        } else if ($scope.modelQuery.length == 0) {
                            $scope.searchFunction({text: ''});
                        }
                    }
                };

                $scope.clearSearchInput = function() {
                    $scope.modelQuery = '';
                    if (!!$scope.searchFunction) {
                        $scope.searchFunction({text: ''});
                    }
                };
            }]
        };
    });
})();