'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:OAuthSuccessController
 * @description
 * # LoginController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('OAuthSuccessController',
    ['$rootScope', 'authService', 'OAuthAuthenticationService', 'authCode', 'Session',
    function ($rootScope, authService, OAuthAuthenticationService, authCode, Session) {

    var vm = this;

    function clearSession() {
        $rootScope.externalLoginFailed = false;
        Session.invalidate();
        authService.loginCancelled();
    }

    if (authCode) {
        var company = localStorage.getItem('lastUserLoginBrand');
        if (company) {
            OAuthAuthenticationService.authenticate(authCode, company);
        } else {
            clearSession();
        }
    } else {
        clearSession();
    }

}]);