(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:dialengaInputMask
     * @description
     * # dialengaInputMask
     */
    angular.module('dialengaExeviBackoffice').component('dialengaInputMask', {
        bindings: {
            isDisabled: '<?',
            isReadOnly: '<?',
            dialengaId: '@?',
            classes: '@?',
            inputModel: '=',
            kInputMask: '<',
            inputPlaceholder: '<',
            changeFunction: '&?',
            removeBlanksOnPaste: '<?'
        },
        controller: ['$timeout', function ($timeout) {
            var vm = this;
            vm.inputOnFocus = false;
            vm.isValid = true;
            vm.lastInputModel = '';
            vm.currentInput = '';

            if (typeof vm.isDisabled === 'undefined' || vm.isDisabled === null) {
                vm.isDisabled = false;
            }
            if (typeof vm.isReadOnly === 'undefined' || vm.isReadOnly === null) {
                vm.isReadOnly = false;
            }

            vm.$onInit = function() {
                vm.inputModel = vm.inputModel ? removeBlanksInFieldText(vm.inputModel) : '';
                vm.lastInputModel = vm.inputModel;
                vm.currentInput = vm.inputModel;
            }
            
            vm.modelValueIsChanging = function() {

            };


            vm.modelValueOnFocus = function() {
                vm.inputOnFocus = true;
            };

            vm.modelValueOnFocusOut = function() {
                vm.inputOnFocus = false;
                vm.isWarningMessageActive = false;
            };

            vm.modelValueChanged = function() {

                vm.isValid = checkValidation();
                if (vm.isValid) {
                    if (removeBlanksInFieldText(vm.currentInput) !== vm.lastInputModel) {
                        $timeout(function() {
                            vm.inputModel = removeBlanksInFieldText(vm.currentInput);
                            vm.lastInputModel = vm.inputModel;
    
                            vm.changeFunction();
                        }, 250);
                    } else {
                        vm.currentInput = vm.lastInputModel;
                    }
                } else {
                    vm.currentInput = vm.lastInputModel;
                }
            }

            vm.modelValueCancel = function() {
                $('#' + vm.dialengaId).trigger('blur');
                vm.currentInput = vm.lastInputModel;
            };

            vm.onKeyUp = function(event) {
                if (event.keyCode === 13) {
                    event.currentTarget.blur();
                } else if (event.keyCode === 27) {
                    event.currentTarget.blur();
                    vm.modelValueCancel();

                }
            };

            vm.onPaste = function(e) {
                if (vm.removeBlanksOnPaste) {
                    var dataToPaste = e.originalEvent.clipboardData.getData('text/plain');
                    vm.currentInput = removeBlanksInFieldText(dataToPaste);
                    vm.modelValueChanged();
                }
            };

            function removeBlanksInFieldText (field) {
                var data = field.split(' ');
                return data.join('');
            }

            function checkValidation () {
                return vm.currentInput.length == vm.inputPlaceholder.length || !vm.currentInput;
            }
        }],
        controllerAs: 'vm',
        template: `<div class="dialenga-input__container dui__container">
                        <input id="{{vm.dialengaId}}" name="{{vm.dialengaId}}" class="dialenga-input {{vm.classes}}" type="text"
                            data-ng-model="vm.currentInput" k-ng-model="vm.kInputModel" kendo-masked-text-box="vm.kendoMaskedTextBox" k-prompt-char= "' '" k-clear-prompt-char="true"
                            k-mask="'{{vm.kInputMask}}'" placeholder="{{vm.inputPlaceholder}}" k-ng-disabled="vm.isDisabled" k-ng-readonly="vm.isReadOnly" k-on-change="vm.modelValueIsChanging()"
                            ng-focus="vm.modelValueOnFocus()" ng-blur="vm.modelValueOnFocusOut()" ng-keyup="vm.onKeyUp($event)" ng-on-change="vm.modelValueChanged()" ng-paste="vm.onPaste($event)"/>

                        <span class="dui__icon dui__icon--read-only icon icon-lock-fill-s" data-ng-if="isReadOnly"></span>

                        <div class="dui__actions-container" >
                            <div  class="dui__message" [ngClass]="{'dui__message--error': !vm.isValid}">
                                <span class="dui__message__text dui__message__text--align-right"
                                [ngClass]="{'warning' : colorWarningMessage == this.MESSAGE_COLOR.WARNING,
                                'danger' : colorWarningMessage == this.MESSAGE_COLOR.DANGER}"
                                [textContent]="warningMessage" data-ng-if="vm.isWarningMessageActive && vm.isValid && vm.inputOnFocus"></span>
                            </div>
                            <div class="dui__action-buttons" data-ng-if="vm.inputOnFocus && !vm.isReadOnly && !vm.isDisabled">
                                <div ng-attr-id="{{vm.dialengaId}}-dialenga-accept-button" class="dui__action-button" ng-mousedown="vm.modelValueAccept($event)" >
                                    <span class="dui__action-button__icon icon icon-accept"></span>
                                </div>
                                <div ng-attr-id="{{vm.dialengaId}}-dialenga-cancel-button" class="dui__action-button" ng-mousedown="vm.modelValueCancel($event)" >
                                    <span class="dui__action-button__icon icon icon-revert"></span>
                                </div>
                            </div>
                        </div>
                    </div>` 
    });
})();
