'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserHolidaysExportModalController
 * @description
 * # UserHolidaysExportModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('UserHolidaysExportModalController',
    ['$rootScope', '$uibModalInstance', 'UsersService', 'params',
    function ($rootScope, $uibModalInstance, UsersService, params) {
        var vm = this;

        vm.userHolidaysPeriods = [];
        vm.periodOptions = {};

        vm.accept = function () {
            $uibModalInstance.close(vm.userHolidaysPeriods);
        };

        vm.cancel = function () {
            $rootScope.$broadcast('dialenga:loadingEnd');
            $uibModalInstance.dismiss('cancel');
        };

        var init = function() {
            vm.periodOptions = UsersService.getUserHolidaysPeriodsDropdown(params.id);
        };

        init();
}]);
