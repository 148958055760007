'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:roleDescriptionFilter
 * @function
 * @description
 * # roleDescriptionFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('roleDescriptionFilter',
    ['$translate', 'USER_ROLES',
    function ($translate, USER_ROLES) {
    return function (role) {
        var roleDescription = 'model.users.roles.';
        switch (role) {
            case USER_ROLES.admin:
                roleDescription += 'admin';
                break;
            case USER_ROLES.publisher:
                roleDescription += 'publisher';
                break;
            case USER_ROLES.manager:
                roleDescription += 'manager';
                break;
            case USER_ROLES.employeeManager:
                roleDescription += 'employeeManager';
                break;   
            case USER_ROLES.documentsManager:
                roleDescription += 'documentsManager';
                break;
            case USER_ROLES.humanResources:
                roleDescription += 'humanResources';
                break;    
            case USER_ROLES.ftrayManager:
                roleDescription += 'ftrayManager';
                break;    
            case USER_ROLES.chatroomManager:
                roleDescription += 'chatroomManager';
                break;    
            case USER_ROLES.ftrayResponsible:
                roleDescription += 'ftrayResponsible';
                break;    
            case USER_ROLES.teamResponsible:
                roleDescription += 'teamResponsible';
                break;    
            default:
                roleDescription = null;
                break;
        }

        if (roleDescription) {
            roleDescription += '.description';
        }

        return roleDescription ? $translate.instant(roleDescription) : '';
    };
}]);
