'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:messageType
 * @function
 * @description
 * # messageType
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('messageType',
    ['$filter',
    function ($filter) {
    return function (input) {
        switch (input) {
            case 'TYPE_QUESTION':
                return $filter('translate')('model.message.type.question');
            case 'TYPE_FREE':
                return $filter('translate')('model.message.type.free');
            default:
                return input;
        }
    };
}]);
