'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.constants
 * @description
 * # constants
 * Constant in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice')
    .constant('USER_ROLES', {
        all: '*',
        admin: 'ROLE_ADMIN',
        manager: 'ROLE_MANAGER',
        user: 'ROLE_USER',
        platformAdmin: 'ROLE_PLATFORM_ADMIN',
        ftrayResponsible: 'ROLE_FTRAY_RESPONSIBLE',
        documentsManager: 'ROLE_DOCUMENTS_MANAGER',
        publisher: 'ROLE_PUBLISHER',
        teamResponsible: 'ROLE_TEAM_RESPONSIBLE',
        humanResources: 'ROLE_HUMAN_RESOURCES',
        employeeManager: 'ROLE_EMPLOYEE_MANAGER',
        ftrayManager: 'ROLE_FTRAY_MANAGER',
        chatroomManager: 'ROLE_CHATROOM_MANAGER'
    }).constant('MESSAGE_STATES', {
        DRAFT: 1,
        PENDING: 2,
        PUBLISHED: 3,
        EXPIRED: 4,
        DELETED: 5,
        PLANNED: 6,
        PUBLISHING_NOW: 7
    }).constant('MESSAGE_STATES_FILTER', {
        DRAFT: 'DRAFT',
        PENDING: 'PENDING_VALIDATION',
        PUBLISHED: 'PUBLISHED',
        EXPIRED: 'EXPIRED',
        DELETED: 'DELETED',
        PLANNED: 'PLANNED',
        PUBLISHING_NOW: 'PUBLISHING_NOW'
    }).constant('MESSAGE_TABS', {
        CONTENT: 1,
        RECIPIENTS: 2,
        SHIPMENT: 3
    }).constant('USER_STATES', {
        ALL: 0,
        ANONYMOUS: 1,
        PENDING_ACTIVATION: 2,
        REGISTERED: 3,
        EXPIRED: 4,
        DISABLED: 5,
        EXPIRED_CREDENTIALS: 6,
        LOCKED: 7
    }).constant('USER_STATES_FILTER', {
        ANONYMOUS: 'ANONYMOUS',
        PENDING_ACTIVATION: 'PENDING_ACTIVATION',
        REGISTERED: 'REGISTERED',
        EXPIRED: 'EXPIRED',
        DISABLED: 'DISABLED',
        EXPIRED_CREDENTIALS: 'EXPIRED_CREDENTIALS',
        LOCKED: 'LOCKED'
    }).constant('POPULATION', {
        ALL: 1,
        SELECTED_USERS: 2,
        SEGMENTS: 3,
        NO_GROUP: 4
    }).constant('SEGMENTATION_KIND', {
        INTERSECTION: 1,
        UNION: 2
    }).constant('LANGUAGES', [
        'es',
        'en',
        'fr',
        'it',
        'ca_ES',
        'eu_ES',
        'sk',
        'zh',
        'ro'
    ])
    .constant('LANGUAGES_LOCALES', {
        es: 'es-ES',
        en: 'en-GB',
        fr: 'fr-FR',
        it: 'it-IT',
        'ca_ES': 'ca-ES',
        'eu_ES': 'eu-ES',
        sk: 'sk-SK',
        zh: 'zh',
        ro: 'ro'
    })
    .constant('PAGE_SIZE', 10)
    .constant('RATING_EMOJI_CODES', {
        1: '&#x1F620',
        2: '&#x1F614',
        3: '&#x1F610',
        4: '&#x1F600',
        5: '&#x1F60D'
    })
    .constant('IMAGE_CROPPER', {
        MINIMUM_HEIGHT: 202,
        RECOMMENDED_HEIGHT: 402,
        MINIMUM_WIDTH: 360,
        RECOMMENDED_WIDTH: 720,
        ASPECT_RATIO: 16 / 9
    })
    .constant('IMAGE_CROPPER_GALLERY', {
        MINIMUM_HEIGHT: 202,
        MINIMUM_WIDTH: 360,
        ASPECT_RATIO: 'none'
    })
    .constant('IMAGE_CROPPER_CATEGORY', {
        MINIMUM_HEIGHT: 135,
        MINIMUM_WIDTH: 360,
        ASPECT_RATIO: 8 / 3
    })
    .constant('IMAGE_CROPPER_CHATROOM', {
        MINIMUM_HEIGHT: 202,
        MINIMUM_WIDTH: 202,
        ASPECT_RATIO: 1 / 1
    })
    .constant('IMAGE_FREE_CROPPER', {
        MINIMUM_HEIGHT: '',
        MINIMUM_WIDTH: '',
        ASPECT_RATIO: 'none'
    })
    .constant('CONTENT_KIND', {
        MESSAGE: 1,
        DIALOGUE: 2,
        KNOWLEDGE_PILL: 3,
        SURVEY: 4
    })
    .constant('CONTENT_TYPE', {
        IMAGE: 1,
        GALLERY: 2
    })
    .constant('QUESTION_KIND', {
        SINGLE_CHOICE: 1,
        OPEN: 2,
        ENDING: 3,
        COVER: 4
    })
    .constant('TRAY_QUESTION_KIND', {
        SINGLE_CHOICE: 1,
        OPEN: 2,
        COMMENT: 3,
        UPLOAD_FILE: 4,
        ENDING: 5,
        REDIRECT: 6
    })
    .constant('RELATED_ENTITY', {
        COMPANY: 1,
        WORKPLACE: 2,
        DEPARTMENT: 3,
        TEAM: 4
    })
    .constant('QUESTIONNAIRE_KIND', {
        SURVEY: 1,
        TRAY: 2
    })
    .constant('RATING_KIND', {
        EMOJI: 1,
        STAR: 2
    })
    .constant('UPDATE_PUBLICATION_TIMEOUT', 30 * 1000)
    .constant('STATISTICS_DROPDOWN', {
        TOTAL_SENT: 1,
        RELATIVE_BEFORE: 2
    })
    .constant('FILTER_MIN_CHARS', 3)
    .constant('SEGMENTED_USER_ORIGIN', {
        MANAGED: 1,
        DIALOGUE: 2,
        SYNCHRONIZED: 3
    })
    .constant('IMPORT_STATES', {
        INITIAL: 1,
        LOADING: 2,
        SUCCESS: 3,
        KO: 4
    })
    .constant('SUPPORT_MAIL', 'support@dialenga.com')
    .constant('APP_PLATFORMS', {
        ANDROID: 1,
        IOS: 2
    })
    .constant('APP_LINKS', {
        ANDROID: 'https://play.google.com/store/apps/details?id=com.dialenga.dialenga&hl=es',
        IOS: 'https://itunes.apple.com/es/app/dialenga/id1299625439?mt=8'
    })
    .constant('GRID_ICON_COLUMN_WIDTH', 65)
    .constant('GRID_DATE_COLUMN_WIDTH', 165)
    .constant('GRID_SHORT_DATE_COLUMN_WIDTH', 140)
    .constant('GRID_RATING_COLUMN_WIDTH', 120)
    .constant('GRID_ROLES_COLUMN_WIDTH', 130)
    .constant('GRID_TICK_COLUMN_WIDTH', 180)
    .constant('GRID_FULL_NAME_COLUMN_WIDTH', 200)
    .constant('GRID_CATEGORY_COLUMN_WIDTH', 160)
    .constant('DEVICE_PREVIEW_STATES', {
        PUSH: 1,
        HIGHLIGHTED: 2,
        TIMELINE: 3,
        PREVIEW: 4,
        SUMMARY: 5,
        GALLERY: 6
    })
    .constant('defaultLanguage', 'es')
    .constant('NOTIFICATION_CHANNEL', {
        NONE: 0,
        EMAIL: 1,
        SMS: 2,
        ANY: 3,
        BOTH: 4
    })
    .constant('CONVERSATION_STATE', {
        OPEN: 'OPEN',
        CLOSED: 'CLOSED',
        MESSAGE_PENDING: 'MESSAGE_PENDING'
    }).constant('CONVERSATION_VALIDATION_STATE', {
        NO_REQUIRED: 0,
        PENDING: 1,
        VALIDATED: 2,
        REJECTED: 3
    }).constant('CONVERSATION_NOTES', {
        NORMAL: 1,
        CLOSING: 2,
        REOPENING: 3,
        VALIDATION: 4,
        REJECTION: 5,
        DISCARDED_VALIDATION: 6
    }).constant('MODULES', {
        CONVERSATIONS: 'conversations',
        EXPERIENCES: 'experiences',
        KNOWLEDGE_CENTER: 'knowledgeCenter',
        DOCUMENTS: 'documents',
        HOLIDAYS: 'timeOff',
        TIME_TRACKING: 'timeTracking'
    }).constant('MODULES_STATES', {
        DISABLED: 0,
        ENABLED: 1,
        EMPLOYEE_DISABLED: 2
    }).constant('DOCUMENT_TRANSACTION_STATE', {
        SUCCESSFUL: 1,
        SOME_ERRORS: 2,
        FAILURE: 3
    }).constant('KENDO_VERSION', '2019.1.115')
    .constant('LOGIN_KIND', {
        DIALENGA: 1,
        CUSTOM: 2,
        OAUTH2: 3,
        SAML2: 4
    })
    .constant('CATEGORY_CONTAINER_STATE', {
        CLOSED: 1,
        OPENED: 2,
        EDITION: 3
    })
    .constant('CATEGORY_AVAILABILITY', {
        ALL: 1,
        TIMELINE: 2,
        KNOWLEDGE_CENTER: 3
    }).constant('AVAILABLE_FEEDS', {
        ALL: 1,
        TIMELINE: 2,
        KNOWLEDGE_CENTER: 3
    }).constant('PUSH_OPTION', {
        CUSTOMIZABLE: 1,
        ALWAYS_ON: 2,
        ALWAYS_OFF: 3
    }).constant('ALLOWED_CATEGORIES_KIND', {
        ALL: 1,
        INCLUSION: 2,
        EXCLUSION: 3
    }).constant('USER_ALLOWED_DOCS_KIND', {
        ALL: 1,
        INCLUSION: 2,
        EXCLUSION: 3
    }).constant('APPROVAL_FLOW_KIND', {
        SINGLE: 1,
        DOUBLE: 2
    }).constant('TIMEOFF_REQUEST_STATUS', {
        PENDING: 1,
        APPROVED: 2,
        CANCELLED: 3,
        REJECTED: 4,
        APPROVED_BY_RESPONSIBLE: 5,
        APPROVED_BY_HHRR: 6,
        REJECTED_BY_RESPONSIBLE: 7,
        REJECTED_BY_HHRR: 8,
        DISALLOWED: 9
    }).constant('TIMEOFF_REQUEST_STATUS_FILTER', {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        CANCELLED: 'CANCELLED',
        REJECTED: 'REJECTED',
        APPROVED_BY_RESPONSIBLE: 'APPROVED_BY_RESPONSIBLE',
        APPROVED_BY_HHRR: 'APPROVED_BY_HHRR',
        REJECTED_BY_RESPONSIBLE: 'REJECTED_BY_RESPONSIBLE',
        REJECTED_BY_HHRR: 'REJECTED_BY_HHRR',
        DISALLOWED: 'DISALLOWED'
    }).constant('TIMEOFF_REQUEST_DETAIL_TABS', {
        DETAIL: 1,
        CONVERSATION: 2
    }).constant('TIMEOFF_KIND', {
        HOLIDAYS: 1,
        ABSENCE: 2
    }).constant('TIMEOFF_KIND_FILTER', {
        HOLIDAYS: 'HOLIDAYS',
        ABSENCE: 'ABSENCE'
    }).constant('TIMEOFF_REQUEST_ACTION_KIND', {
        APPROVE: 1,
        REJECT: 2
    }).constant('ABSENCE_MOTIVE_GROUPS', {
        HEALTH: 1,
        PERSONAL: 2,
        PROFESSIONAL: 3,
        NO_REMUNERABLE: 4,
        OTHER: 5,
        PERSONAL_DAYS: 6
    }).constant('TIMEOFF_SETTINGS_TABS', {
        SCHEMES: 1,
        GLOBAL: 2
    }).constant('USER_TABS', {
        PROFILE: 1,
        HOLIDAYS: 2,
        DOCUMENTS: 3,
        ACCESS: 4,
        COMPANY: 5,
        NOTES: 6
    }).constant('TIMEOFF_SETTINGS_SCHEME_TABS', {
        GENERAL: 1,
        ABSENCES: 2,
        EMPLOYEES: 3
    }).constant('TIMEOFF_SETTINGS_WORKPLACE_TABS', {
        LOCATION: 1,
        HOLIDAYS: 2,
        EMPLOYEES: 3
    }).constant('SCHEME_KIND_DAYS', {
        WORKING: 1,
        CALENDAR: 2
    }).constant('SCHEME_PERIOD_ACTIVATION_KIND', {
        AUTOMATIC: 1,
        MANUAL: 2
    }).constant('TIMEOFF_TABS', {
        REQUESTS: 1
    }).constant('TIMEOFF_REQUEST_WARNING_KINDS', {
        OVERLAP: 1,
        BEAR_DAYS: 2,
        NO_AVAILABLE_DAYS: 3
    }).constant('CONVERSATIONS_TABS', {
        DETAIL: 1,
        FILES: 2,
        NOTES: 3
    }).constant('CONVERSATIONS_SETTINGS_TABS', {
        MOTIVES: 1,
        FEEDBACK_TRAYS: 2
    }).constant('FEEDBACKTRAY_TABS', {
        TRAY: 1,
        USERS_AND_GROUPS: 2,
        QUESTIONNAIRE: 3
    }).constant('COMMENT_STATES', {
        PUBLISHED: 1,
        PENDING_MODERATION: 2,
        USER_DELETED: 3,
        COMPANY_DELETED: 4,
        USER_EDITED: 5,
        COMPANY_EDITED: 6,
        FLAGGED: 7
    }).constant('GENERAL_CONFIG_STATES', {
        ENABLED: 1,
        DISABLED: 2,
        FORCE_ENABLED: 3,
        FORCE_DISABLED: 4
    }).constant('REQUESTS_VALIDATION_TYPE', {
        SIMPLE: 1,
        DOUBLE: 2
    }).constant('PERIOD_STATES', {
        ENABLED: 1,
        DISABLED: 2
    }).constant('PUBLICATION_SCHEDULED_KIND', {
        SCHEDULED: 1,
        NOW: 2
    }).constant('DOCUMENT_KIND', {
        ALL: 0,
        PAYROLL: 1,
        EXTRA: 2,
        CERT: 3,
        TIME_REGISTRATION: 4, 
        OTHER: 5
    }).constant('UPLOAD_FILE_MODE', {
        MULTIPLE: 1,
        SINGLE: 2
    }).constant('EXPIRATION_MODE', {
        MANUALLY: 1,
        AUTO_EXPIRED: 2
    }).constant('STRENGTH_TYPE', {
        VERY_WEAK: 1,
        WEAK: 2,
        REGULAR: 3,
        ALMOST_VALID: 4,
        VALID: 5
    }).constant('DOCUMENTS_TABS', {
        ALL_DOCUMENTS : 1,
        REMOTE_SIGNATURE: 2
    }).constant('CHATROOM_TABS', {
        GENERAL : 1,
        USERS: 2
    }).constant('MY_COMPANY_TABS', {
        GLOBAL : 1,
        COMPANIES: 2,
        WORKPLACES: 3,
        DEPARTMENTS: 4,
        TEAMS: 5,
        EXTERNAL_LINKS: 6
    }).constant('COMPANY_TABS', {
        GENERAL : 1,
        USERS: 2
    }).constant('DOCUMENT_TABS', {
        GLOBAL : 1,
        CONVENTIONS: 2
    }).constant('TIME_TRACKING_TABS', {
        SCHEDULES : 1,
        GLOBAL: 2,
        NOTIFICATIONS: 3
    }).constant('SSO_KIND', {
        DIALENGA: 0,
        OAUTH2: 1,
		SAML2: 2
    }).constant('AVAILABILITY_KIND', {
        ALL: 1,
		APP: 2,
		WEBAPP: 3,
        APP_WEBAPP: 4,
        BACKOFFICE: 5
    }).constant('LAYOUT_KIND', {
        RECOMMENDED: 1,
        STACKED: 2,
        SELECT: 3
    }).constant('SCHEDULE_TABS', {
        GENERAL : 1,
        RECORD_KINDS: 2,
        USERS: 3
    });

