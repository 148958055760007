'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnairePreviewController
 * @description
 * # QuestionnairePreviewController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnairePreviewController',
    ['$scope', '$rootScope', '$routeParams', '$location', '$timeout', '$translate', 'DateUtilsService', 'PublicationService', 'QuestionnaireService', 'ModalService', 
    'LanguageService', 'Session', 'CONTENT_KIND', 'MESSAGE_STATES', 'PUBLICATION_SCHEDULED_KIND', 'breadcrumbs', 'contentType', 'mainTitle', 'DataService',
    function ($scope, $rootScope, $routeParams, $location, $timeout, $translate, DateUtilsService, PublicationService, QuestionnaireService, ModalService, 
        LanguageService, Session, CONTENT_KIND, MESSAGE_STATES, PUBLICATION_SCHEDULED_KIND, breadcrumbs, contentType, mainTitle, DataService) {

    //******************************************* Attributes ************************************\\
    var dateFormat = 'dd/MM/yyyy';
    var timeFormat = 'HH:mm';
    var lastKind;
    $scope.dateFormatPlaceholder = 'dd/mm/yyyy';
    $scope.timeFormatPlaceholder = 'hh:mm';

    $scope.currentStep = 3;
    $scope.publicationStates = MESSAGE_STATES;
    $scope.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;
    $scope.MESSAGES_EXPIRATION_KIND = {
        AFTER_N_HOURS: {
            ID: 1,
            NAME: $translate.instant('global.form.timeInterval.hours'),
            TYPE: 'Hours',
            MIN: 1,
            MAX: 999
        },
        AFTER_N_DAYS: {
            ID: 2,
            NAME: $translate.instant('global.form.timeInterval.days'),
            TYPE: 'Days',
            MIN: 1,
            MAX: 365
        },
        AFTER_N_WEEKS: {
            ID: 3,
            NAME: $translate.instant('global.form.timeInterval.weeks'),
            TYPE: 'Weeks',
            MIN: 1,
            MAX: 52
        },
        AFTER_N_MONTHS: {
            ID: 4,
            NAME: $translate.instant('global.form.timeInterval.months'),
            TYPE: 'Months',
            MIN: 1,
            MAX: 12
        },
        EXACT_DATE: {
            ID: 5
        }
    };

    $scope.timePickerOptions = {
        dateInput: true,
        format: timeFormat
    };

    $scope.archiveTimePickerOptions = {
        dateInput: true,
        format: timeFormat
    };

    $scope.dateDiff = {
        years : 0,
        months: 0,
        days: 0,
        hours: 0
    };

    $scope.kindTimeToFileOptions = {
        dataSource: [
            { id: 1, name: $translate.instant('global.form.timeInterval.hours'), type: 'Hours', minAmount: 1, maxAmount: 999, maxLength: 3, inputId: 'amount-time-hours'},
            { id: 2, name: $translate.instant('global.form.timeInterval.days') , type: 'Days', minAmount: 1, maxAmount: 365, maxLength: 3, inputId: 'amount-time-days'},
            { id: 3, name: $translate.instant('global.form.timeInterval.weeks') , type: 'Weeks', minAmount: 1, maxAmount: 52, maxLength: 2, inputId: 'amount-time-weeks'},
            { id: 4, name: $translate.instant('global.form.timeInterval.months') , type: 'Months', minAmount: 1, maxAmount: 12, maxLength: 2, inputId: 'amount-time-months'}
        ],
        dataTextField: 'name',
        dataValueField: 'id',
        valuePrimitive: true,
        autoWidth : true,
        valueTemplate: '<div><span translate="{{dataItem.name}}"></span></div>',
        template : '<div><span translate="{{dataItem.name}}"></span></div>'
    };
    $scope.dateToFile = '';
    $scope.showInputError = false;

    $scope.isInputFocused = false;

    $scope.datetimeModel = {};
    $scope.datetimeArchiveModel = {};

    $scope.isValidDateTime = true;
    $scope.isValidArchiveDateTime = true;
    $scope.isValidPendingDateTime = true;
    $scope.isDateAfterCurrent = true;

    $scope.breadcrumbs = breadcrumbs;
    $scope.mainTitle = mainTitle;
    $scope.previewState = { opened: false };

    $scope.contentType = contentType;
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.isPublished = false;
    $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
    $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
    $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;

    $scope.userTimeZone = moment.tz.guess();
    $scope.companyTimeZone = Session.companyTimeZone;
    $scope.regionOffset = DateUtilsService.getRegionsOffset($scope.userTimeZone, $scope.companyTimeZone);

    var initialDate = null;
    var initialScheduleKind = null;

    //******************************************** Methods **************************************\\
    // $scope.savePublication = function () {
    //     if ($scope.previewForm.$valid && (($scope.datetimeModel.previewKendoDate && $scope.datetimeModel.previewKendoTime)
    //         || (!$scope.datetimeModel.previewKendoDate && parseInt($scope.publication.state) !== MESSAGE_STATES.PENDING))) {
    //         // Save content whether all fields are filled or if date is empty
    //         var isFormDirty = dataHasChanged();
    //         if (isFormDirty || initialScheduleKind !== $scope.publication.scheduleKind) {

    //             var dataPublication = {
    //                 publicationDate: DateUtilsService.getTimestampFromDateAndTime($scope.datetimeModel.previewKendoDate, $scope.datetimeModel.previewKendoTime),
    //                 scheduleKind: $scope.publication.scheduleKind
    //             }
    //             DataService.updatePublicationDate($routeParams.id, dataPublication).then(function () {
    //                 $location.path(breadcrumbs.mainList.statePath);
    //             }, formSubmitErrorHandler);
    //         } else {
    //             $location.path(breadcrumbs.mainList.statePath);
    //         }
    //     } else {
    //         formSubmitErrorHandler();
    //     }
    // };

    $scope.savePublication = function () {

        parseAutoexpirationData();
        var dataPublication = getDataPublication();
        
        DataService.updatePublicationDate($routeParams.id, dataPublication).then(function () {
            PublicationService.showSuccessNotification('global.messages.changesUpdated.success', contentType);

            $location.path(breadcrumbs.mainList.statePath);
        }, formSubmitErrorHandler);
    };

    $scope.$on("kendoWidgetCreated", function (event, widget) {
        if (widget.options.name === 'DatePicker') {
            if(widget._dateInput) {
                widget._dateInput.setOptions({
                    messages: {
                        year: 'yyyy',
                        month: 'mm',
                        day: 'dd'
                    }
                });
            }
        } else if (widget.options.name === 'TimePicker') {
            widget._dateInput.setOptions({
                messages: {
                    hour: 'hh',
                    minute: 'mm'
                },
                min: new Date(1900, 0, 1),
                max: new Date(2099, 11, 31)
            });
        }
    });

    function formSubmitErrorHandler() {
        $rootScope.$broadcast('dialenga:loadingEnd');
    }

    function dataHasChanged() {
        var areDatesEquals = (!$scope.datetimeModel.previewKendoDate && !DataService.initialPreviewData.date) || DateUtilsService.areDatesEquals($scope.datetimeModel.previewKendoDate, DataService.initialPreviewData.date);
        var areTimesEquals = (!$scope.datetimeModel.previewKendoTime && !DataService.initialPreviewData.time) || angular.equals($scope.datetimeModel.previewKendoTime, DataService.initialPreviewData.time);
        return !areDatesEquals || !areTimesEquals;
    }

    function parseAutoexpirationData() {
        if ($scope.publication.autoExpiration.autoExpirationKind !== $scope.MESSAGES_EXPIRATION_KIND.EXACT_DATE.ID) {
            $scope.publication.autoExpiration.autoExpirationDate = null;
        } else {
            $scope.publication.autoExpiration.autoExpirationAmount = null;
            $scope.publication.autoExpiration.autoExpirationDate = DateUtilsService.getTimestampFromDateAndTime($scope.datetimeArchiveModel.previewKendoDate, $scope.datetimeArchiveModel.previewKendoTime)
        }
    }

    function getDataPublication() {
        var data = {};
        if ($scope.publication.autoExpiration.autoExpirationEnabled) {
            data = {
                autoExpiration: $scope.publication.autoExpiration,
                publicationDate: DateUtilsService.getTimestampFromDateAndTime($scope.datetimeModel.previewKendoDate, $scope.datetimeModel.previewKendoTime),
                scheduleKind: $scope.publication.scheduleKind
            }; 
        } else {
            data = {
                publicationDate: DateUtilsService.getTimestampFromDateAndTime($scope.datetimeModel.previewKendoDate, $scope.datetimeModel.previewKendoTime),
                scheduleKind: $scope.publication.scheduleKind
            };
        }
        return data;
    }

    $scope.sendPublication = function () {
        
        parseAutoexpirationData();
        var dataPublication = getDataPublication();

        if ($scope.isUserPublisherOrAdmin) {
            validatePublication($routeParams.id, contentType, dataPublication);
        } else {
            promptConfirmationForContentValidationRequest($routeParams.id, dataPublication);
        }
    };
    
    $scope.archiveOrDelete = function (id) {
        PublicationService.archiveOrDelete(id,$scope.isPublished,contentType,DataService);
    };

    function navigateToList() {
        $location.path(breadcrumbs.mainList.statePath);
    }

    function requestValidation(publicationId, dataPublication) {
        DataService.requestValidation(publicationId, dataPublication)
            .then(function () {
                PublicationService.showSuccessNotification('publication.modal.validationRequest.validationRequested', contentType);
                navigateToList();
            }, formSubmitErrorHandler);
    }

    function promptConfirmationForContentValidationRequest(publicationId, dataPublication) {
        var skipModalAlias = 'requestValidation';
        var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
        if (!shouldModalBeDisplayed) {
            requestValidation(publicationId, dataPublication);
        } else {
            ModalService.openConfirmationModal({
                title: 'publication.modal.validationRequest.title',
                message: 'publication.modal.validationRequest.message',
                messageData: { contentType: contentType },
                acceptButtonText: 'global.form.pendingvalidation',
                isTextInterpolated: true,
                skipModalAlias: skipModalAlias,
                accept: function () {
                    requestValidation(publicationId, dataPublication);
                },
                cancel: function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        }
    }

    function validatePublication(publicationId, contentType, dataPublication) {
        DataService.validate(publicationId, dataPublication)
            .then(function () {
                if ($scope.publication.scheduleKind === PUBLICATION_SCHEDULED_KIND.NOW) {
                    PublicationService.showSuccessNotification('publication.modal.send.sent', contentType);
                }
            })
            .then(function () {
                navigateToList()
            });
    }

    function updatePublicationDate() {
        // Update publicationDate to automatically view updates in summary tab of device preview component
        $scope.publication.publicationDate = DateUtilsService.getTimestampFromDateAndTime($scope.datetimeModel.previewKendoDate, $scope.datetimeModel.previewKendoTime);
        $scope.dateInCompanyTimeZone = DateUtilsService.getCompanyLocalDateTime(new Date($scope.publication.publicationDate), $scope.userTimeZone, $scope.companyTimeZone);
    }

    $scope.inputFocusIn = function () {
        $scope.isInputFocused = true;
    };

    $scope.dateInputFocusOut = function () {
        datePickerValueChanged();
        $scope.isInputFocused = false;
    };

    $scope.archiveDateInputFocusOut = function () {
        archiveDatePickerValueChanged();
        $scope.isInputFocused = false;
    };

    $scope.hourInputFocusOut = function () {
        timePickerValueChanged();
        $scope.isInputFocused = false;
    };

    $scope.archiveHourInputFocusOut = function () {
        archiveTimePickerValueChanged();
        $scope.isInputFocused = false;
    };

    function isDatePreviousToCurrentDate(date) {
        var currentDate = new Date();
        currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
        return date < currentDate;
    }

    function validateDateAndTime() {
        $scope.isDateAfterCurrent = true;
        $scope.isValidDateTime = true;
        $scope.isValidPendingDateTime = true;

        if (!$scope.datetimeModel.previewKendoDate) {
            if (!isDateEmpty()) {
                // previewKendoDate is null when selected date is disabled in calendar (previous to current date)
                // Check if this is the case to show corresponding error message.
                if (DateUtilsService.isValidDate(new Date($scope.datetimeModel.previewDate)) && !!$scope.datetimeModel.previewKendoTime) {
                    var previewDate = DateUtilsService.getDateFromString($scope.datetimeModel.previewDate);
                    updateTimePickerDate(previewDate);
                    $scope.isDateAfterCurrent = !isDatePreviousToCurrentDate($scope.datetimeModel.previewKendoTime);
                } else {
                    $scope.isValidDateTime = false;
                }
            } else {
                if (parseInt($scope.publication.state) === MESSAGE_STATES.PENDING) {
                    $scope.isValidPendingDateTime = false;
                }
            }
        } else {
            $scope.isValidDateTime = !!$scope.datetimeModel.previewKendoTime;
            if ($scope.isValidDateTime) {
                updateTimePickerDate($scope.datetimeModel.previewKendoDate);
                $scope.isDateAfterCurrent = !isDatePreviousToCurrentDate($scope.datetimeModel.previewKendoTime);
            }
        }
        updatePublicationDate();
        if ($scope.publication.autoExpiration.autoExpirationEnabled && $scope.publication.autoExpiration.autoExpirationKind !== $scope.MESSAGES_EXPIRATION_KIND.EXACT_DATE.ID) {
            getPublicationArchiveDate();
        }        
    }

    function validateArchiveDateAndTime() {
        $scope.isValidArchiveDateTime = true;

        if (!$scope.datetimeArchiveModel.previewKendoDate) {
            if (!isArchiveDateEmpty()) {
                // previewKendoDate is null when selected date is disabled in calendar (previous to current date)
                // Check if this is the case to show corresponding error message.
                if (DateUtilsService.isValidDate(new Date($scope.datetimeArchiveModel.previewDate)) && !!$scope.datetimeArchiveModel.previewKendoTime) {
                    var previewDate = DateUtilsService.getDateFromString($scope.datetimeArchiveModel.previewDate);
                    updateTimePickerArchiveDate(previewDate);
                } else {
                    $scope.isValidArchiveDateTime = false;
                }
            } else {
                $scope.isValidArchiveDateTime = false;
            }
        } else {
            $scope.isValidArchiveDateTime = !!$scope.datetimeArchiveModel.previewKendoTime;
            if ($scope.isValidArchiveDateTime) {
                updateTimePickerArchiveDate($scope.datetimeArchiveModel.previewKendoDate);
                var publication = $scope.publication.scheduleKind === PUBLICATION_SCHEDULED_KIND.NOW ? new Date() : $scope.publication.publicationDate;
                if ($scope.publication.autoExpiration.autoExpirationKind === $scope.MESSAGES_EXPIRATION_KIND.EXACT_DATE.ID) {
                    $scope.isValidArchiveDateTime = $scope.datetimeArchiveModel.previewKendoTime > publication;
                }
            }
        }
        getPublicationArchiveDate();
    }

    function setDefaultTime(date) {
        var currentDate = new Date();
        if (DateUtilsService.areDatesEquals(currentDate, date)) {
            date.setHours(currentDate.getHours());
            date.setMinutes(currentDate.getMinutes());
        } else {
            date.setHours(12);
            date.setMinutes(0);
        }
    }

    function updateTimePickerDate(date) {
        var selectedDate = angular.copy(date);
        if ($scope.datetimeModel.previewKendoTime) {
            selectedDate.setHours($scope.datetimeModel.previewKendoTime.getHours());
            selectedDate.setMinutes($scope.datetimeModel.previewKendoTime.getMinutes());
        } else {
            $scope.datetimeModel.previewKendoTime = new Date(selectedDate);
            setDefaultTime(selectedDate);
        }

        $scope.datetimeModel.previewKendoTime = selectedDate;
        if ($scope.datetimeArchiveModel.previewKendoDate < $scope.datetimeModel.previewKendoDate) {
            $scope.publication.autoExpiration.autoExpirationEnabled = false;
        }
    }

    function updateTimePickerArchiveDate(date) {
        var selectedDate = angular.copy(date);
        if ($scope.datetimeArchiveModel.previewKendoTime) {
            selectedDate.setHours($scope.datetimeArchiveModel.previewKendoTime.getHours());
            selectedDate.setMinutes($scope.datetimeArchiveModel.previewKendoTime.getMinutes());
            $scope.datetimeArchiveModel.previewKendoDate = angular.copy(selectedDate); 
        } else {
            $scope.datetimeArchiveModel.previewKendoTime = new Date(selectedDate);
            setDefaultTime(selectedDate);
        }
        $scope.datetimeArchiveModel.previewKendoTime = angular.copy(selectedDate);
    }

    function datePickerValueChanged() {
        $scope.kendoTimePicker.enable(!isDateEmpty());
        if (isDateEmpty() && !$scope.datetimeModel.previewKendoTime) {
            $scope.datetimeModel.previewTime = '';
        }

        if ($scope.datetimeModel.previewKendoDate && isTimeEmpty()) {
            var defaultTime = angular.copy($scope.datetimeModel.previewKendoDate);
            setDefaultTime(defaultTime);

            var defaultDate = new Date(defaultTime.getTime());
            $timeout(function() {
                $scope.datetimeModel.previewKendoTime = defaultDate;
                $scope.datetimeModel.previewTime = kendo.toString(defaultDate, timeFormat);
                $scope.isValidTime = true;
                validateDateAndTime();
            });
        } else {
            validateDateAndTime();
        }
    }

    function archiveDatePickerValueChanged() {
        $scope.kendoArchiveTimePicker.enable(!isArchiveDateEmpty());
        if (isArchiveDateEmpty() && !$scope.datetimeArchiveModel.previewKendoTime) {
            $scope.datetimeArchiveModel.previewTime = '';
        }

        if ($scope.datetimeArchiveModel.previewKendoDate && isArchiveTimeEmpty()) {
            var defaultTime = angular.copy($scope.datetimeArchiveModel.previewKendoDate);

            setDefaultTime(defaultTime);

            var defaultDate = new Date(defaultTime.getTime());
            $timeout(function() {
                $scope.datetimeArchiveModel.previewKendoTime = angular.copy(defaultDate);
                $scope.datetimeArchiveModel.previewKendoDate = angular.copy(defaultDate);
                $scope.datetimeArchiveModel.previewTime = kendo.toString(defaultDate, timeFormat);
                $scope.isValidTime = true;
                validateArchiveDateAndTime();
            });
        } else {
            var publDate = $scope.publication.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW ? new Date() : new Date($scope.publication.publicationDate);
            if ($scope.datetimeArchiveModel.previewDate == kendo.toString(publDate, dateFormat)) {
                var archiveTime = new Date(publDate.getTime()+3600000);
                $timeout(function() {
                    $scope.datetimeArchiveModel.previewKendoTime = angular.copy(archiveTime);
                    $scope.datetimeArchiveModel.previewKendoDate = angular.copy(archiveTime);
                    $scope.datetimeArchiveModel.previewTime = kendo.toString(archiveTime, timeFormat);
                    $scope.isValidTime = true;
                    validateArchiveDateAndTime();
                });
            } else {
                validateArchiveDateAndTime();
            }
        }
    }

    function isDateEmpty() {
        return !$scope.datetimeModel.previewDate || $scope.datetimeModel.previewDate === $scope.dateFormatPlaceholder; 
    }

    function isArchiveDateEmpty() {
        return !$scope.datetimeArchiveModel.previewDate || $scope.datetimeArchiveModel.previewDate === $scope.dateFormatPlaceholder; 
    }

    function isTimeEmpty() {
        return !$scope.datetimeModel.previewTime || $scope.datetimeModel.previewTime === $scope.timeFormatPlaceholder; 
    }

    function isArchiveTimeEmpty() {
        return !$scope.datetimeArchiveModel.previewTime || $scope.datetimeArchiveModel.previewTime === $scope.timeFormatPlaceholder; 
    }

    function timePickerValueChanged() {
        validateDateAndTime();
    }

    function archiveTimePickerValueChanged() {
        validateArchiveDateAndTime();
    }

    function setDateAndTimeValues(date, time) {
        $timeout(function() {
            $scope.datetimeModel.previewKendoDate = date;
            $scope.datetimeModel.previewDate = kendo.toString(date, dateFormat);
            $scope.datetimeModel.previewKendoTime = time;
            $scope.datetimeModel.previewTime = kendo.toString(time, timeFormat);
        });
    }

    function setArchiveDateAndTimeValues(date, time) {
        $timeout(function() {
            $scope.datetimeArchiveModel.previewKendoDate = date;
            $scope.datetimeArchiveModel.previewDate = kendo.toString(date, dateFormat);
            $scope.datetimeArchiveModel.previewKendoTime = time;
            $scope.datetimeArchiveModel.previewTime = kendo.toString(time, timeFormat);
        });
    }

    $scope.goBack = function() {
        var isFormDirty = dataHasChanged();

        $rootScope.goBackAndCheckForModifications(isFormDirty, breadcrumbs.recipients.statePath);
    };

    $scope.setAutoExpiration = function() {
        if ($scope.publication.autoExpiration.autoExpirationEnabled) {
            if (!$scope.publication.autoExpiration.autoExpirationKind) {
                $scope.publication.autoExpiration.autoExpirationKind = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.ID;
                $scope.publication.autoExpiration.autoExpirationAmount = 7;
            }
            getPublicationArchiveDate();
        } else {
            $scope.isValidArchiveDateTime = true;
        }
    };

    $scope.setAfterWhile = function() {
        $scope.publication.autoExpiration.autoExpirationKind = !!lastKind ? lastKind : $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.ID;
        $scope.publication.autoExpiration.autoExpirationAmount = !$scope.publication.autoExpiration.autoExpirationAmount ? 7 : $scope.publication.autoExpiration.autoExpirationAmount;
        getPublicationArchiveDate();
    };

    $scope.setExactDate = function() {
        lastKind = $scope.publication.autoExpiration.autoExpirationKind;
        $scope.publication.autoExpiration.autoExpirationKind = $scope.MESSAGES_EXPIRATION_KIND.EXACT_DATE.ID;

        if (!$scope.datetimeArchiveModel.previewKendoDate) {
            $scope.datetimeArchiveModel.previewKendoDate = $scope.publication.autoExpiration.autoExpirationDate;
            $scope.datetimeArchiveModel.previewDate = kendo.toString($scope.publication.autoExpiration.autoExpirationDate, dateFormat);
            $scope.datetimeArchiveModel.previewKendoTime = $scope.publication.autoExpiration.autoExpirationDate;
            $scope.datetimeArchiveModel.previewTime = kendo.toString($scope.publication.autoExpiration.autoExpirationDate, timeFormat);
            archiveDatePickerValueChanged();
        } else {
        getPublicationArchiveDate();
        }
    };

    $scope.onChangeAmountTime = function () {
        $scope.showInputError = false;
        switch($scope.publication.autoExpiration.autoExpirationKind) {
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_HOURS.ID:
                validateInputAndShowError('messages.error.inputErrorHours');
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.ID:
                validateInputAndShowError('messages.error.inputErrorDays');
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_WEEKS.ID:
                validateInputAndShowError('messages.error.inputErrorWeeks');
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_MONTHS.ID:
                validateInputAndShowError('messages.error.inputErrorMonths');
                break;
            default:
                break;
        }
        getPublicationArchiveDate();
    }

    $scope.increaseAmount = function() {
        if ($scope.publication.autoExpiration.autoExpirationAmount < $scope.getMaxAmount()) {
            $scope.publication.autoExpiration.autoExpirationAmount++;
        } 
        $scope.onChangeAmountTime();
    };

    $scope.decreaseAmount = function() {
        if ($scope.publication.autoExpiration.autoExpirationAmount > 1) {
            $scope.publication.autoExpiration.autoExpirationAmount--;
        }
        $scope.onChangeAmountTime();
    };

    $scope.selectScheduleKind = function(kind) {
        if (kind === $scope.PUBLICATION_SCHEDULED_KIND.SCHEDULED && ($scope.datetimeModel.previewKendoDate === null || $scope.datetimeModel.previewKendoDate === undefined)) {
            $scope.publication.autoExpiration.autoExpirationEnabled = false;
        }
        $scope.publication.scheduleKind = kind;
        validateDateAndTime();
        getPublicationArchiveDate();
    }

    function getPublicationTitle(publication) {
        if (contentType === CONTENT_KIND.DIALOGUE || contentType === CONTENT_KIND.SURVEY) {
            return publication.name;
        } else {
            var language = LanguageService.getMainContentLanguage(publication.contents);
            return publication.contents && publication.contents[language] && publication.contents[language].title;
        }
    }

    function getAmountKind() {
        var amountKind ='';
        switch ($scope.publication.autoExpiration.autoExpirationKind) {
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_HOURS.ID:
                amountKind = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_HOURS.TYPE;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.ID:
                amountKind = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.TYPE;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_WEEKS.ID:
                amountKind = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_WEEKS.TYPE;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_MONTHS.ID:
                amountKind = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_MONTHS.TYPE;
                break;
            default:
                break;
        }
        return amountKind;
    }

    $scope.getMaxAmount = function() {
        var maxAmount ='';
        switch ($scope.publication.autoExpiration.autoExpirationKind) {
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_HOURS.ID:
                maxAmount = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_HOURS.MAX;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.ID:
                maxAmount = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_DAYS.MAX;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_WEEKS.ID:
                maxAmount = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_WEEKS.MAX;
                break;
            case $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_MONTHS.ID:
                maxAmount = $scope.MESSAGES_EXPIRATION_KIND.AFTER_N_MONTHS.MAX;
                break;
            default:
                break;
        }
        return maxAmount;
    };

    function getPublicationArchiveDate() {
        var date = $scope.publication.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW ? moment() : $scope.publication.publicationDate;

        // kind: after a while
        if ($scope.publication.autoExpiration.autoExpirationKind !== $scope.MESSAGES_EXPIRATION_KIND.EXACT_DATE.ID) {
            $scope.publication.autoExpiration.autoExpirationDate = date !== null ? 
                    new Date(moment(date).add($scope.publication.autoExpiration.autoExpirationAmount, getAmountKind())) 
                    : null;
        // kind: In a date                                                                    
        } else {
            $scope.publication.autoExpiration.autoExpirationDate = DateUtilsService.getTimestampFromDateAndTime($scope.datetimeArchiveModel.previewKendoDate, $scope.datetimeArchiveModel.previewKendoTime);
        }
    }

    function validateInputAndShowError(translate) {
        var message = '';
        if ($scope.publication.autoExpiration.autoExpirationAmount > $scope.getMaxAmount() 
            || $scope.publication.autoExpiration.autoExpirationAmount === undefined) {
            message = $translate.instant(translate, {kind: 'max', amount: $scope.getMaxAmount()}, 'messageformat');
            showError(message);
            $scope.publication.autoExpiration.autoExpirationAmount = $scope.getMaxAmount();
        } else if ($scope.publication.autoExpiration.autoExpirationAmount === null) {
            message = $translate.instant(translate, {kind: 'min', amount: 1}, 'messageformat');
            showError(message);
            $scope.publication.autoExpiration.autoExpirationAmount = 1;
        }  
    }

    function showError(message) {
        var inputError = document.getElementById('input-error');
        inputError.innerHTML = message;

        $scope.showInputError = true;
        $timeout(function () {
            $scope.showInputError = false;
        }, 4000);
    }

    function setDatePickerOptions(publicationDate) {
        var currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        var options = {
            dateInput: true,
            format: dateFormat,
            change: function () {
                if(this.value()) {
                    $scope.kendoTimePicker.enable(true);
                    document.getElementById('kendo-time-picker').focus();

                    $scope.forceUpdateDisabledDates();
                    if ($scope.datetimeArchiveModel.previewKendoDate < $scope.datetimeModel.previewKendoDate) {
                        $scope.datetimeArchiveModel.previewKendoDate = '';
                    }
                }
            },
            disableDates: function(date) {
                if (date) {
                    date.setHours(0, 0, 0, 0);
                }
                return !(date && (date.getTime() >= currentDate.getTime() || (publicationDate && date.getTime() === publicationDate.getTime())));
            },
            weekNumber: true
        };

        $scope.datePickerOptions = options;
    }

    $scope.forceUpdateDisabledDates = function() {
        var archiveDate = $('#kendo-archive-date-picker').data("kendoDatePicker");
        archiveDate.setOptions({ disableDates: $scope.archiveDisabledDates });
        archiveDate._dateInput.setOptions({
            messages: {
                year: 'yyyy',
                month: 'mm',
                day: 'dd'
            }
        });
    }

    $scope.archiveDisabledDates = function (date) {
        if (date) {
            date.setHours(0, 0, 0, 0);
        } 
        var currentDate = $scope.datetimeModel.previewKendoDate ? $scope.datetimeModel.previewKendoDate : new Date();
        currentDate.setHours(0, 0, 0, 0);
        return !(date && (date.getTime() >= currentDate.getTime() || ($scope.datetimeModel.previewKendoDate && date.getTime() === $scope.datetimeModel.previewKendoDate.getTime())));
    }

    function setArchiveDatePickerOptions() {
        var options = {
            dateInput: true,
            format: dateFormat,
            change: function () {
                if(this.value()) {
                    $scope.kendoArchiveTimePicker.enable(true);
                    document.getElementById('kendo-archive-time-picker').focus();
                }
            },
            disableDates: $scope.archiveDisabledDates,
            weekNumber: true
        };
        $scope.archiveDatePickerOptions = options;
    }
    
    function initializeKendoDatePicker() {
        var publicationDate, publicationTime;
        if ($scope.publication.publicationDate) {
            var publicationDateTime = parseInt($scope.publication.publicationDate);
            publicationDate = new Date(publicationDateTime);
            publicationDate.setHours(0, 0, 0, 0);
            publicationTime = new Date(publicationDateTime);
            setDateAndTimeValues(publicationDate, publicationTime);
            $scope.isDateAfterCurrent = !isDatePreviousToCurrentDate(publicationTime);
        } else {
            $scope.kendoTimePicker.enable(false);
        }

        setDatePickerOptions(publicationDate);

        DataService.initialPreviewData = {
            date: angular.copy(publicationDate),
            time: angular.copy(publicationTime)
        };
    }

    function initializeKendoArchiveDatePicker() {
        var publicationArchiveDate, publicationArchiveTime;
        if ($scope.publication.autoExpiration.autoExpirationDate) {
            var publicationArchiveDateTime = parseInt($scope.publication.autoExpiration.autoExpirationDate);
            publicationArchiveDate = new Date(publicationArchiveDateTime);
            // publicationArchiveDate.setHours(0, 0, 0, 0);
            publicationArchiveTime = new Date(publicationArchiveDateTime);
            setArchiveDateAndTimeValues(publicationArchiveDate, publicationArchiveTime);
        } else {
            $scope.kendoArchiveTimePicker.enable(false);
        }

        setArchiveDatePickerOptions();

        DataService.initialArchiveData = {
            date: angular.copy(publicationArchiveDate),
            time: angular.copy(publicationArchiveTime)
        };
    }

    function reorderGallery() {
        $scope.publication.gallery.images = $scope.publication.gallery.images.sort( function (a, b) { return a.order - b.order; } );
    }

    var init = function () {
        DataService.findOne($routeParams.id).then(function (response) {
            $scope.publication = response.data;
            $scope.publication.scheduleKind = $scope.publication.scheduleKind === null ?  PUBLICATION_SCHEDULED_KIND.SCHEDULED : $scope.publication.scheduleKind;

            if ($scope.publication.gallery && $scope.publication.gallery.images && $scope.publication.gallery.images.length > 0) {
                reorderGallery();
            }

            if ($scope.publication.scheduleKind === PUBLICATION_SCHEDULED_KIND.NOW && $scope.publication.autoExpiration.autoExpirationEnabled && !$scope.publication.autoExpiration.autoExpirationDate) {
                getPublicationArchiveDate();
            }

            if (response.data.kind) {
                $scope.contentType = response.data.kind;
            }

            if ($scope.contentType === CONTENT_KIND.DIALOGUE || $scope.contentType === CONTENT_KIND.SURVEY) {
                DataService.setQuestionnaireContentLanguage($scope.publication);
            }

            $scope.publicationKindIcon = PublicationService.getPublicationKindIcon($scope.contentType);
            $scope.category = response.data.category;
            $scope.headerTitle = getPublicationTitle(response.data);

            initialDate = response.data.publicationDate || null;
            initialScheduleKind = response.data.scheduleKind;

            if (response.data.publicationDate) {
                var publicationDate = new Date(parseInt(response.data.publicationDate));
                $scope.dateInCompanyTimeZone = DateUtilsService.getCompanyLocalDateTime(publicationDate, $scope.userTimeZone, $scope.companyTimeZone);
            }

            if ($scope.publication.users) {
                $scope.publication.users =  QuestionnaireService.orderRecipientsUsers($scope.publication.users); 
            }
            QuestionnaireService.setUsersAvatarTooltips($scope.publication.users);

            initializeKendoDatePicker();
            initializeKendoArchiveDatePicker();
        });
    };

    init();
}]);
