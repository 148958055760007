'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:documentStateTooltipFilter
 * @function
 * @description
 * # documentStateTooltipFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('documentStateTooltipFilter',
    ['$filter', 'DOCUMENT_TRANSACTION_STATE',
    function ($filter, DOCUMENT_TRANSACTION_STATE) {

    return function (state) {
        var tooltip = 'documents.history.';
        switch(parseInt(state)){
            case DOCUMENT_TRANSACTION_STATE.SOME_ERRORS:
                tooltip += 'someErrors';
                break;
            case DOCUMENT_TRANSACTION_STATE.FAILURE:
                tooltip += 'failure';
                break;
            case DOCUMENT_TRANSACTION_STATE.SUCCESSFUL:
            default:
                tooltip = '';
                break;
        }
        return $filter('translate')(tooltip);
    };
}]);
