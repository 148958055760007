'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ActiveFeaturesController
 * @description
 * # ActiveFeaturesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ActiveFeaturesController',
    ['$rootScope', '$translate', '$timeout', 'Session', 'ActiveFeaturesService', 'ModalService', 'NotificationService', 'MODULES_STATES', 'MODULES',
    function ($rootScope, $translate, $timeout, Session, ActiveFeaturesService, ModalService, NotificationService, MODULES_STATES, MODULES) {
        
        var vm = this;
        var companyId = Session.companyId;
        var contentService = ActiveFeaturesService;
        var drawing = kendo.drawing;
        vm.MODULES_STATES = MODULES_STATES;

        vm.activeFeatures = {};
        vm.signatureStatsData = {
            category: [],
            consumedBySent: [],
            consumedBySigned: [],
            average: [],
        };

        vm.changeKcenterState = function(state) {
            vm.activeFeatures.knowledgeCenter.state = state;
            if (vm.activeFeatures.knowledgeCenter.state == MODULES_STATES.DISABLED) {
                parseTemporarilyDeactivateToggles();
            }
            setModuleState(vm.activeFeatures.knowledgeCenter.id, vm.activeFeatures.knowledgeCenter.state);
        };

        vm.changeConversationsState = function(state) {
            vm.activeFeatures.conversations.state = state;
            if (vm.activeFeatures.conversations.state == MODULES_STATES.DISABLED) {
                parseTemporarilyDeactivateToggles();
            }
            setModuleState(vm.activeFeatures.conversations.id, vm.activeFeatures.conversations.state);
        };

        vm.changeChatState = function(state) {
            vm.activeFeatures.chat.state = state;
            if (vm.activeFeatures.chat.state == MODULES_STATES.DISABLED) {
                parseTemporarilyDeactivateToggles();
            }
            setModuleState(vm.activeFeatures.chat.id, vm.activeFeatures.chat.state);
        };

        vm.changeExperiencesState = function(state) {
            vm.activeFeatures.experiences.state = state;
            if (vm.activeFeatures.experiences.state == MODULES_STATES.DISABLED) {
                parseTemporarilyDeactivateToggles();
            }
            setModuleState(vm.activeFeatures.experiences.id, vm.activeFeatures.experiences.state);
        };

        vm.changeEmployeesDirectoryState = function(state) {
            vm.activeFeatures.employeeDirectory.state = state;
            if (vm.activeFeatures.employeeDirectory.state == MODULES_STATES.DISABLED) {
                parseTemporarilyDeactivateToggles();
            }
            setModuleState(vm.activeFeatures.employeeDirectory.id, vm.activeFeatures.employeeDirectory.state);
        };

        vm.onToggleKcenter = function () {
            if (vm.activeFeatures.knowledgeCenter.temporarilyDeactivate) {
                vm.activeFeatures.knowledgeCenter.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.knowledgeCenter.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.knowledgeCenter.id, vm.activeFeatures.knowledgeCenter.temporarilyDeactivate);
        };

        vm.onToggleConversations = function () {
            if (vm.activeFeatures.conversations.temporarilyDeactivate) {
                vm.activeFeatures.conversations.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.conversations.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.conversations.id, vm.activeFeatures.conversations.temporarilyDeactivate);
        };

        vm.onToggleChat = function () {
            if (vm.activeFeatures.chat.temporarilyDeactivate) {
                vm.activeFeatures.chat.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.chat.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.chat.id, vm.activeFeatures.chat.temporarilyDeactivate);
        };

        vm.onToggleExperiences = function () {
            if (vm.activeFeatures.experiences.temporarilyDeactivate) {
                vm.activeFeatures.experiences.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.experiences.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.experiences.id, vm.activeFeatures.experiences.temporarilyDeactivate);
        };

        vm.onToggleTimeOff = function () {
            if (vm.activeFeatures.timeOff.temporarilyDeactivate) {
                vm.activeFeatures.timeOff.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.timeOff.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.timeOff.id, vm.activeFeatures.timeOff.temporarilyDeactivate);
        };

        vm.onToggleDocuments = function () {
            if (vm.activeFeatures.documents.temporarilyDeactivate) {
                vm.activeFeatures.documents.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.documents.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.documents.id, vm.activeFeatures.documents.temporarilyDeactivate);
        };

        vm.onToggleEmployeesDirectory = function () {
            if (vm.activeFeatures.employeeDirectory.temporarilyDeactivate) {
                vm.activeFeatures.employeeDirectory.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.employeeDirectory.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.employeeDirectory.id, vm.activeFeatures.employeeDirectory.temporarilyDeactivate);
        };

        vm.onToggleTimeTracking = function () {
            if (vm.activeFeatures.timeTracking.temporarilyDeactivate) {
                vm.activeFeatures.timeTracking.state = MODULES_STATES.EMPLOYEE_DISABLED;
            } else {
                vm.activeFeatures.timeTracking.state = MODULES_STATES.ENABLED;
            }
            setTemporarilyDeactivateModule(vm.activeFeatures.timeTracking.id, vm.activeFeatures.timeTracking.temporarilyDeactivate);
        };

        vm.onToggleByDefault = function (moduleId, state) {
            var setModuleService = state ? contentService.enableByDefault : contentService.disableByDefault;

            setModuleService.call(contentService, companyId, moduleId).then(function(response) {
                updateActiveModules(response.data.module, response.data.state, response.data.enabledByDefault);              
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);
            });
        };

        vm.onToggleHideSignature = function() {
            var setSignatureVisibility = vm.activeFeatures.documents.backofficeHidden ? contentService.hideSignature : contentService.showSignature;
            setSignatureVisibility.call(contentService, companyId).then(function(response) {
                Session.activeModules.documents.backofficeHidden = response.data.backofficeHidden;
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);
            })
        };

        vm.showNewHireInfo = function() {
            ModalService.openConfirmationModal({
                title: 'activeFeatures.signature.newHireModal.title',
                message: 'activeFeatures.signature.newHireModal.message',
                acceptButtonText: 'global.form.back',
                acceptButtonTextClass: ' ',
                hideCancelButton: true
            });
        };

        function updateActiveModules(module,state, byDefault) {
            var moduleName = '';
            switch (module) {
                case 1:
                    moduleName = MODULES.KNOWLEDGE_CENTER;
                    break;
                case 2:
                    moduleName = MODULES.CONVERSATIONS;
                    break;
                case 3:
                    moduleName = MODULES.EXPERIENCES;
                    break;
                case 5:
                    moduleName = MODULES.HOLIDAYS;
                    break;
                case 6:
                    moduleName = MODULES.DOCUMENTS;
                    break;
                case 9:
                    moduleName = MODULES.TIME_TRACKING;
                    break;
                default:
                    break;
            }
            if (moduleName !== '') {
                $rootScope.account.activeModules[moduleName].state = state;
            }
            if (byDefault !== undefined) {
                Session.activeModules[moduleName].enabledByDefault = byDefault;
            }
        }

        function parseTemporarilyDeactivateToggles() {
            var modules = Object.keys(vm.activeFeatures);
            for (var idx = 0; idx < modules.length; idx++) {
                vm.activeFeatures[modules[idx]].temporarilyDeactivate = vm.activeFeatures[modules[idx]].state == MODULES_STATES.EMPLOYEE_DISABLED;
            }
        }

        function setModuleState(moduleId, moduleState) {
            var setModuleService = moduleState == MODULES_STATES.ENABLED ? contentService.enableModule : contentService.disableModule;
            
            setModuleService.call(contentService, companyId, moduleId).then(function(response) {
                updateActiveModules(response.data.module, response.data.state);              
                $rootScope.$broadcast('dialenga:updateModulesInSideMenu');
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);
            });
        }

        function setTemporarilyDeactivateModule(moduleId, state) {
            var setModuleService = state ? contentService.temporarilyDeactivateModule : contentService.temporarilyActivateModule;

            setModuleService.call(contentService, companyId, moduleId).then(function(response) {
                // AuthenticationService.authenticateUser(true);
                updateActiveModules(response.data.module, response.data.state)
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message, 1500);            
            });
        }

        function setCategorieMonthsAndParseConsumptions() {
            angular.forEach(vm.signatureStats.consumptionBySent, function (item, index) {
                // Category axis
                vm.signatureStatsData.category.push(moment(item.month, 'M').format('MMM').charAt(0).toUpperCase());
                // consumedBySigned series
                vm.signatureStatsData.consumedBySigned.push({
                    value: vm.signatureStats.consumptionBySigned[index].count,
                    bothRadius: !item.count
                });
                // consumedBySent series
                vm.signatureStatsData.consumedBySent.push({
                    value: item.count,
                    bothRadius: !vm.signatureStats.consumptionBySigned[index].count
                });
                // Average serie
                vm.signatureStatsData.average.push(vm.signatureStats.averageConsumption);
            });
        }

        function createChart() {
            $("#signatures-chart").kendoChart({
                chartArea: {
                    width: 220,
                    height: 85
                },
                panes: [{
                    clip: false
                }],
                valueAxis: {
                    min: 0,
                    visible: false,
                  	majorGridLines: {
                        visible: false
                    },
                    line: {
                        visible: false
                    }
                },
                categoryAxis: {
                    categories: vm.signatureStatsData.category,
                    labels: {
                        padding: {
                            top: 0
                        },
                        font: "10px Gotham"
                    },
                    majorGridLines: {
                        visible: false
                    },
                    line: {
                        visible: false
                    }
                },
                series: [{
                    type: "column",
                    stack: true,
                    field: "value",
                    color: "#092E38",
                  	gap: 1,
                    data: vm.signatureStatsData.consumedBySigned,
                    highlight: {
                        toggle: function (e) {
                            // Don't create a highlight overlay,
                            // we'll modify the existing visual instead
                            e.preventDefault();

                            var visual = e.visual;
                            var opacity = e.show ? 0.8 : 1;

                            visual.opacity(opacity);
                        }
                    },
                    visual: function (e) {
                        return e.dataItem.bothRadius ? bothRadius(e.rect, e.options.color, e) : radiusBottom(e.rect, e.options.color, e);
                    }
                },{
                    type: "column",
                    color: "#2D9CDB",
                    field: "value",
                  	gap: 1,
                    data: vm.signatureStatsData.consumedBySent,
                    highlight: {
                        toggle: function (e) {
                            // Don't create a highlight overlay,
                            // we'll modify the existing visual instead
                            e.preventDefault();

                            var visual = e.visual;
                            var opacity = e.show ? 0.8 : 1;

                            visual.opacity(opacity);
                        }
                    },
                    visual: function (e) {
                        return e.dataItem.bothRadius ? bothRadius(e.rect, e.options.color, e) : radiusTop(e.rect, e.options.color, e);;
                    }
                },{
                    type: "line",
                    color: "#2D9CDB",
                    data: vm.signatureStatsData.average,
                    width: 1,
                  	markers: {
                        visible: false,
                        size: 0,
                        color: "#2D9CDB"
                    },
                    highlight: {
                        markers: {
                            visible: false,
                            border: {
                                width: 0,
                                color: "#2D9CDB"
                            }
                        }
                    },
                    tooltip: {
                        visible: false
                    }
                }],
                tooltip: {
                    visible: true,
                    format: "{0}"
                }
            });
        }

        function bothRadius(rect, color, e) {
            var origin = rect.origin;
            var bottomRight = rect.bottomRight();
            var radiusX = rect.width() /2;
            var radiusY = radiusX;
            var gradient = new drawing.LinearGradient({
                stops: [{
                    offset: 0,
                    color: color
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 1,
                    color: color
                }]
            });

            var path = new drawing.Path({
                    fill: gradient,
                    stroke: {
                        color: "none"
                    }
                }).moveTo(origin.x, origin.y)
                .lineTo(origin.x, bottomRight.y)
                .arc(180, 0, radiusX, radiusY, true)
                .lineTo(bottomRight.x, origin.y)
                .arc(0, 180, radiusX, radiusY, true);
            
            var group = new drawing.Group();
            group.append(path);
            return e.value > 0 ? group : '';
        }

        function radiusBottom(rect, color, e) {
            var origin = rect.origin;
            var bottomRight = rect.bottomRight();
            var radiusX = rect.width() /2;
            var radiusY = radiusX;
            var gradient = new drawing.LinearGradient({
                stops: [{
                    offset: 0,
                    color: color
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 1,
                    color: color
                }]
            });

            var path = new drawing.Path({
                    fill: gradient,
                    stroke: {
                        color: "none"
                    }
                }).moveTo(origin.x, origin.y)
                .lineTo(origin.x, bottomRight.y)
                .arc(180, 0, radiusX, radiusY, true)
                .lineTo(bottomRight.x, origin.y)
            
            var group = new drawing.Group();
            group.append(path);
            return e.value > 0 ? group : '';
        }

        function radiusTop (rect, color, e) {
            var origin = rect.origin;
            var bottomRight = rect.bottomRight();
            var radiusX = rect.width() /2;
            var radiusY = radiusX;
            var gradient = new drawing.LinearGradient({
                stops: [{
                    offset: 0,
                    color: color
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 0.5,
                    color: color,
                    opacity: 0.9
                }, {
                    offset: 1,
                    color: color
                }]
            });

            var path = new drawing.Path({
                    fill: gradient,
                    stroke: {
                        color: "none"
                    }
                }).moveTo(bottomRight.x, bottomRight.y)
                .lineTo(bottomRight.x, origin.y)
                .arc(0, 180, radiusX, radiusY, true)
                .lineTo(origin.x, bottomRight.y)
            
            var group = new drawing.Group();
            group.append(path);
            return e.value > 0 ? group : '';
          }

        var init = function() {
            ActiveFeaturesService.getConfigurationModules(companyId).then(function (response) {
                vm.activeFeatures = response.data;
                if (vm.activeFeatures.documents.productionMode) {
                    ActiveFeaturesService.getRemoteSignatureStats().then(function (resp) {
                        vm.signatureStats = resp.data;

                        setCategorieMonthsAndParseConsumptions();
                        $timeout(function(){
                            createChart();
                        });
                    })
                }
                parseTemporarilyDeactivateToggles();
            });
        }

        init();
    
    }
]);