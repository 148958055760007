'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:GlobalController
 * @description
 * # GlobalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('GlobalController',
    ['$rootScope', '$scope', '$timeout', 'SharedDataService', 'USER_ROLES', 'CATEGORY_CONTAINER_STATE',
        function ($rootScope, $scope, $timeout, SharedDataService, USER_ROLES, CATEGORY_CONTAINER_STATE) {

            //****************************************** Attributes *************************************\\
            $scope.environment = SharedDataService.environmentName;

            $scope.CATEGORY_CONTAINER_STATE = CATEGORY_CONTAINER_STATE;
            $scope.categoryContainerState = CATEGORY_CONTAINER_STATE.CLOSED;

            $scope.isNavBarCollapsed = true;
            
            // $rootScope.helpBoxImprovedUserProfileAdvice = false;

            //******************************************* Methods ***************************************\\
            var init = function () {
                var currentUrl = window.location.href;
                if (currentUrl.search('localhost') > -1 || currentUrl.search('127.0.0.1') > -1) {
                    $scope.environment = 'local ' + $scope.environment;
                }

                // $timeout(function() {
                //     var helpBoxVisible = localStorage.getItem('helpBoxImprovedUserProfileAdvice');
                //     $rootScope.helpBoxImprovedUserProfileAdvice = JSON.parse(helpBoxVisible) === true || JSON.parse(helpBoxVisible) === null;
                // }, 500);

            };

            // $rootScope.hideHelpBoxImprovedUserProfileAdvice = function () {
            //     localStorage.setItem('helpBoxImprovedUserProfileAdvice', JSON.stringify(false));
            //     $rootScope.helpBoxImprovedUserProfileAdvice = false;
            // };

            $scope.checkAuditAccess = function () {
                return $rootScope.account.roles && $rootScope.account.roles.indexOf(USER_ROLES.admin) !== -1;
            };

            init();

        }]);
