'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConversationsController
 * @description
 * # ConversationsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConversationsController',
    ['$rootScope', '$scope', '$timeout', '$translate', '$window', 'KendoUtilsService', 'ConversationsService', 'NotificationService', 'Session', 'ModalService', 'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH',
    'GRID_DATE_COLUMN_WIDTH', 'GRID_RATING_COLUMN_WIDTH', 'GRID_TICK_COLUMN_WIDTH', 'CONVERSATION_STATE', 'CONVERSATION_VALIDATION_STATE', 'RATING_KIND',
    function ($rootScope, $scope, $timeout, $translate, $window, KendoUtilsService, ConversationsService, NotificationService, Session, ModalService, PAGE_SIZE, GRID_ICON_COLUMN_WIDTH,
        GRID_DATE_COLUMN_WIDTH, GRID_RATING_COLUMN_WIDTH, GRID_TICK_COLUMN_WIDTH, CONVERSATION_STATE, CONVERSATION_VALIDATION_STATE, RATING_KIND) {

    //******************************************* Attributes ************************************\\
    $scope.CONVERSATIONS_FILTER = {
        OPEN: 0,
        WITHOUT_RESPONSE: 1,
        CLOSED: 2
    };
    var pageChanged = false;
    var dataGridHasChanged = false;
    var ignoreRowSelection = false;

    $scope.selectedFilter = $scope.CONVERSATIONS_FILTER.OPEN;
    $scope.inputSearchField = "";

    $scope.currentSelectedRowIndex = -1;

    var responsibleBolderTextClass = 'data-ng-class="{\'font-medium black\': (dataItem.lastResponsibleMessageDate == null ' +
        '|| dataItem.lastResponsibleMessageDate < dataItem.lastMessageOn) && dataItem.closedOn == null}"';

    var gridColumns = [{
        field: 'validationState',
        title: ' ',
        template: '<span></span>', // Show empty column
        width: 6,
        attributes: { class: "{{getStatusClass(dataItem)}}" }
    }, {
        field: "tray",
        title: $translate.instant('conversations.header.tray'),
        type: "string",
        template: '<div class="flex align-items-center"><span class="icon icon-heisenberg icon-22 margin-right-5" kendo-tooltip k-content="\'feedbackTrays.isAnonymous\' | translate" ng-if="!!dataItem.anonymous"></span>'+
            '<span class="nowrap truncate" data-ng-bind="dataItem.minimalTray.nameInList" ' + responsibleBolderTextClass + '></span></div>',
        width: GRID_TICK_COLUMN_WIDTH
    }, {
        field: "motive",
        title: $translate.instant('conversations.header.motive'),
        template: '<div class="flex"><span data-ng-bind-html="dataItem.minimalMotive | conversationsMotiveFilter"></span></div>',
        width: GRID_DATE_COLUMN_WIDTH
    }, {
        field: "messages.sender",
        title: $translate.instant('conversations.header.sender'),
        template: '<div class="flex"><span ng-if="dataItem.anonymous" class="truncate" data-translate="global.userstates.anonymous"></span>' + 
            '<span ng-if="!dataItem.anonymous" class="truncate" data-ng-bind="dataItem.author.fullName" ' + responsibleBolderTextClass + '></span></div>',
        width: 250
    }, {
        field: "messages.message",
        title: $translate.instant('conversations.header.message'),
        template: '<div class="flex conversation-last-message"><span class="truncate" ng-style="{ \'font-style\': !dataItem.lastMessage ? \'italic\' : \'normal\' }" ' +
            'data-ng-bind-html="getTitleWithoutFormat(dataItem) | unsafeHtml" compile ' + responsibleBolderTextClass + '></span></div>'
    }, {
        field: "lastMessageOn",
        title: $translate.instant('conversations.header.date'),
        type: "datetime",
        template: '<div class="flex"><span data-ng-bind="dataItem.lastMessageOn | date:\'dd/MM/yyyy HH:mm\'" ' + responsibleBolderTextClass + '></span></div>',
        width: GRID_DATE_COLUMN_WIDTH
    },  {
        field: "rating",
        title: " ",
        template: '<div class="flex"><rating-icon data-ng-if="dataItem.rating" value="dataItem.rating" show-title="true"></rating-icon></div>',
        attributes: { class: "text-center" },
        width: Session.ratingKind === RATING_KIND.EMOJI ? GRID_ICON_COLUMN_WIDTH : GRID_RATING_COLUMN_WIDTH
    }, {
        command: [{
            name: "action-button",
            template: '<div class="td-on-hover"><a class="k-button k-grid-edit-button" data-ng-if="dataItem.closedOn" ng-click="reopenConversation(dataItem)" kendo-tooltip k-content="\'conversations.modal.reopen.title\' | translate"><span class="icon icon-unarchive"></span></a>'+
            '<a class="k-button k-grid-edit-button" data-ng-if="!dataItem.closedOn" ng-click="closeConversation(dataItem)" kendo-tooltip k-content="\'conversations.modal.close.title\' | translate"><span class="icon icon-archive"></span></a></div>'
        }],
        headerAttributes: { class: "table-header-icons-container" },
        attributes: { class: 'text-right overflow-visible' },
        title: "",
        width: GRID_DATE_COLUMN_WIDTH
    }];

    var gridDataSource = {
        transport: {
            read: findAllConversations
        },
        schema: {
            data: "content",
            total: "totalElements",
            parse: function (data) {
                $.each(data.content, function (idx, elem) {
                    if (elem.lastMessageOn)
                        data.content[idx].lastMessageOn = new Date(elem.lastMessageOn);
                });
                return data;
            }
        },
        pageSize: PAGE_SIZE,
        serverPaging: true,
        serverSorting: true
    };

    //******************************************** Methods **************************************\\
    function findAllConversations(options) {
        var requestOptions = getRequestOptions(options);

        ConversationsService.findAll(requestOptions).then(function (response) {
            $scope.numResults = response.data.totalElements;
            options.success(response.data);
        }, function () {
            options.error();
        });
    }

    function findAllConversationsWithoutResponse(options) {
        var requestOptions = getRequestOptions(options);

        ConversationsService.findAllWithoutResponse(requestOptions).then(function (response) {
            $scope.numResults = response.data.totalElements;
            options.success(response.data);
        }, function () {
            options.error();
        });
    }

    function getValidationStateFilter(selectedStates) {
        var state = [];
        angular.forEach(selectedStates, function(selectedState) {
            switch (selectedState) {
                case CONVERSATION_VALIDATION_STATE.NO_REQUIRED:
                    state.push('null');
                    break;
                case CONVERSATION_VALIDATION_STATE.PENDING:
                    state.push('PENDING');
                    break;
                case CONVERSATION_VALIDATION_STATE.VALIDATED:
                    state.push('VALIDATED');
                    break;
                case CONVERSATION_VALIDATION_STATE.REJECTED:
                    state.push('REJECTED');
                    break;
                default:
                    break;
            }
        });
        return state;
    }

    function getRequestOptions(options) {
        var requestOptions = KendoUtilsService.getGridRequestOptions(options, 'lastMessageOn,desc', $scope.inputSearchField, 'omniSearch');

        if ($scope.selectedFilter === $scope.CONVERSATIONS_FILTER.CLOSED) {
            requestOptions['state'] = CONVERSATION_STATE.CLOSED;
        } else {
            requestOptions['state'] = CONVERSATION_STATE.OPEN;
        }

        if ($scope.isValidationActive) {
            if ($scope.selectedFilter === $scope.CONVERSATIONS_FILTER.CLOSED && typeof $scope.selectedValidationStates !== 'undefined') {
                requestOptions['validationState'] = getValidationStateFilter($scope.selectedValidationStates);
            } else if ($scope.selectedFilter !== $scope.CONVERSATIONS_FILTER.CLOSED && $scope.applyFilterValidation === true) {
                requestOptions['validationState'] = ['VALIDATED', 'REJECTED'];
            }
        }

        if ($scope.selectedMotive && $scope.selectedMotive.id != null) {
            requestOptions['motive.id'] = $scope.selectedMotive.id;
        }

        if ($scope.selectedTray && $scope.selectedTray.id != null) {
            requestOptions['tray.id'] = $scope.selectedTray.id;
        }

        return requestOptions;
    }
    
    function lookForFeedbackTraysWithQuestionnaire() {
        var areFeedbackTraysWithQuestionnaire = false;
        var trays = $('#trays').data('kendoDropDownList').dataSource.data();
        if (trays.length < 2) {
            return false;
        }
        for (var index = 0; index < trays.length; index++) {
            if (!!trays[index].questionnaireId) {
                areFeedbackTraysWithQuestionnaire = true;
                break;
            }
        }
        return areFeedbackTraysWithQuestionnaire;
    }

    $scope.exportConversations = function () {
        var exportConversationsUrl = ConversationsService.URL + '/export-csv';
        var onlyOneTray = '';

        if ($scope.selectedTray && $scope.selectedTray.id) {
            onlyOneTray = '?trayId=' + $scope.selectedTray.id;
        }

        // var areFeedbackTraysWithQuestionnaire = (!$scope.selectedTray || !$scope.selectedTray.id) ? lookForFeedbackTraysWithQuestionnaire() : false;
        // if (areFeedbackTraysWithQuestionnaire) {
        //     var skipModalAlias = 'exportConversations';
        //     var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
        //     if (!shouldModalBeDisplayed) {
        //         $window.open(exportConversationsUrl, '_blank');
        //     } else {
        //         ModalService.openConfirmationModal({
        //             title: 'conversations.modal.downloadCSV.title',
        //             message: 'conversations.modal.downloadCSV.message',
        //             acceptButtonText: 'global.modal.download',
        //             skipModalAlias: skipModalAlias,
        //             accept: function() {
        //                 $window.open(exportConversationsUrl, '_blank');
        //             }
        //         });
        //     }
        // } else {
            $window.open(exportConversationsUrl + onlyOneTray, '_blank');
        // }
    };

    $scope.getStatusClass = function(dataItem) {
        if (!dataItem.validationState) {
            return '';
        }
        var statusClass = '';
        if (dataItem.validationState === CONVERSATION_VALIDATION_STATE.VALIDATED) {
            statusClass = 'bg-approved-color';
        } else if (dataItem.validationState === CONVERSATION_VALIDATION_STATE.REJECTED) {
            statusClass = 'bg-rejected-color';
        } else if (dataItem.validationState === CONVERSATION_VALIDATION_STATE.PENDING) {
            statusClass = 'bg-pending-color';
        }
        return statusClass;
    };

    $scope.getTitleWithoutFormat = function(dataItem) {

        if (!dataItem.lastMessage) {
            return $translate.instant('conversations.senderAttachFile', { sender: dataItem.lastSender });
        } else {
            var mentionFullRegex = /<mention.*?>(.*?)<\/mention>/g;
            var mentionRegex = /^<mention id="(.+)">(.*?)<\/mention>(.*)$/;

            var title = dataItem.lastMessage;
            var textParagraphs = title.split('<p>');
            textParagraphs = textParagraphs.filter(function(item) {
                return item !== '';
            })
            var plainText = textParagraphs.join(" ");
            title = plainText.replace('</p>', '');
    
            // Clean text for mention tag an replace it for @
            var mentions = title.match(mentionFullRegex);

            angular.forEach(mentions, function(mention) {
                title = title.replace(mention, '@' + mention.match(mentionRegex)[2]);
            });

            var element = document.createElement("div");
            element.innerHTML = title;
    
            var text = element.textContent || element.innerText || "";
            element.remove();
            return text;
        }

    };

    $scope.getOpenConversations = function () {
        filterDataGrid($scope.CONVERSATIONS_FILTER.OPEN, findAllConversations);
    };

    $scope.getConversationsWithoutAnswer = function () {
        filterDataGrid($scope.CONVERSATIONS_FILTER.WITHOUT_RESPONSE, findAllConversationsWithoutResponse);
    };

    $scope.getClosedConversations = function () {
        filterDataGrid($scope.CONVERSATIONS_FILTER.CLOSED, findAllConversations);
    };

    function filterDataGrid(filter, service) {
        $scope.selectedFilter = filter;
        $scope.deselectConversation();
        $scope.conversationsGrid.dataSource.transport.read = service;
        $scope.reloadGrid(false, 1);
        $scope.currentSelectedRowIndex = -1;
    }

    $scope.onMotivesChange = function () {
        $scope.reloadGrid(false, 1);
    };

    $scope.onTraysChange = function () {
        if (!!$scope.selectedTray && !!$scope.selectedTray.id) {
            $scope.isValidationActive = $scope.selectedTray.requiresValidation;
        } else {
            lookForFeedbackTraysWithValidation();
        }
        $scope.reloadGrid(false, 1);
    };

    $scope.onApplyFilterValidationChange = function () {
        $scope.reloadGrid(false, 1);
    };

    $scope.listItemsSearch = function (text) {
        $scope.inputSearchField = text;
        if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
            $scope.reloadGrid(false, 1);
            $scope.currentSelectedRowIndex = -1;
        }
    };

    function reloadDatasource(dataSource, options) {
        $scope.isSyncing = true;
        KendoUtilsService.reloadGridDatasource(dataSource, options).then(function () {
            $scope.$apply(function () {
                $scope.isSyncing = false;
            });
        });
        ConversationsService.statistics()
            .then(function (response) {
                $scope.statistics = response.data;
            });
    }

    $scope.reloadGrid = function (selectFirstRow, page) {
        var gridPage;
        if (selectFirstRow === true) {
            // Select first row: when sending a new message, grid is refreshed and selected conversation becomes the first one.
            // By default, the selection is lost when refreshing the grid, so we manually select the first row.
            if ($scope.selectedConversation) {
                $scope.selectedConversation.dataPosition.newIndexPosition = 0;
            }
            gridPage = 1;
            dataGridHasChanged = true;
        } else {
            gridPage = $scope.conversationsGrid.dataSource.page();
        }

        var reloadOptions = {
            page: page || gridPage,
            pageSize: $scope.conversationsGrid.dataSource.pageSize()
        };

        reloadDatasource($scope.conversationsGrid.dataSource, reloadOptions);
    };

    $scope.reloadGridAndSelectRow = function () {

        var reloadOptions = {
            page: $scope.selectedConversation.dataPosition.actualPage,
            pageSize: $scope.conversationsGrid.dataSource.pageSize()
        };
        dataGridHasChanged = true;

        reloadDatasource($scope.conversationsGrid.dataSource, reloadOptions);
    };

    $scope.deselectConversation = function () {
        // Set a timeout to apply changes to the scope inside a Kendo component
        // The use of $scope.$apply causes errors as a digest cycle was already being made in some cases
        $timeout(function () {
            $scope.selectedConversation = null;
        }, 0);
    };

    $scope.deselectConversationAndClearSelectedRow = function () {
        $scope.deselectConversation();
        $scope.currentSelectedRowIndex = -1;
        $scope.conversationsGrid.clearSelection();
    };

    $scope.newConversation = function(employee) {
        ModalService.openModal({
            templateUrl: '/ng1/components/conversation-employee-modal/conversation-employee-modal.html',
            controller: 'ConversationEmployeeModalController',
            resolveParams: {
                employee: employee
            },
            size: 'lg',
            accept: function(conversation) {
                var message = $translate.instant('conversations.newConversationMessage', {name: conversation.employee.name, surname: conversation.employee.surname});
                NotificationService.notifySuccess(message);
                $scope.reloadGrid(true);
            }
        });
    };

    function reloadGridAndShowNotification(notificationMessage) {
        if ($scope.selectedConversation) {
            $scope.reloadGridAndSelectRow();
        } else {
            $scope.reloadGrid();
        }
        var message = $translate.instant(notificationMessage);
        NotificationService.notifySuccess(message);
    }

    function reopenConversationAndReload(conversationId) {
        ConversationsService.reopenConversation(conversationId).then(function() {
            reloadGridAndShowNotification('conversations.reopenSuccessMessage');
        });
    }

    $scope.reopenConversation = function(dataItem) {
        if (!!dataItem.validationState && (dataItem.validationState === CONVERSATION_VALIDATION_STATE.VALIDATED ||
            dataItem.validationState === CONVERSATION_VALIDATION_STATE.REJECTED)) {
                var skipModalAlias = 'reopenConversation';
                var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                if (!shouldModalBeDisplayed) {
                    reopenConversationAndReload(dataItem.id);
                } else {
                    ModalService.openConfirmationModal({
                        title: 'conversations.modal.reopen.title',
                        message: 'conversations.modal.reopen.message',
                        acceptButtonText: 'global.modal.accept',
                        skipModalAlias: skipModalAlias,
                        accept: function() {
                            reopenConversationAndReload(dataItem.id);
                        }
                    });
                }
        } else {
            reopenConversationAndReload(dataItem.id);
        }
    };

    function closeConversationAndReload(conversationId) {
        ConversationsService.closeConversation(conversationId).then(function() {
            reloadGridAndShowNotification('conversations.closeSuccessMessage');
        });
    }

    $scope.closeConversation = function(dataItem) {
        var skipModalAlias = 'closeConversation';
        var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
        if (!shouldModalBeDisplayed) {
            closeConversationAndReload(dataItem.id);
        } else {
            ModalService.openConfirmationModal({
                title: 'conversations.modal.close.title',
                message: 'conversations.modal.close.message',
                acceptButtonText: 'global.modal.close',
                skipModalAlias: skipModalAlias,
                accept: function() {
                    closeConversationAndReload(dataItem.id);
                }
            });
        }
    };

    $scope.onValidationStateChange = function() {
        if (typeof $scope.selectedValidationStates !== 'undefined') {
            $scope.reloadGrid(false, 1);
        }
    };

    $scope.refreshData = function() {
        $scope.reloadGrid(false, 1);
        $timeout( function() {
            $rootScope.$broadcast('dialenga:loadingEnd');
        }, 300);
    }

    function getRowPositionIfGridItemsChange() {
        var dataPosition = {};
        dataPosition.actualPage = $scope.conversationsGrid.dataSource.page();
        dataPosition.pageSize = $scope.conversationsGrid.dataSource.pageSize();
        dataPosition.rowSelectedIndex = $scope.currentSelectedRowIndex;
        dataPosition.itemsLeftOnPageAfter = $scope.conversationsGrid.items().length - 1;

        if (dataPosition.itemsLeftOnPageAfter > 0) {
            dataPosition.newIndexPosition = dataPosition.rowSelectedIndex > 0 ? dataPosition.rowSelectedIndex - 1 : 0;
        } else {
            dataPosition.newIndexPosition = 0;
            dataPosition.actualPage = dataPosition.actualPage > 1 ? dataPosition.actualPage - 1 : 1;
        }
        return dataPosition;
    }

    function rowChange(selectedRow) {

        var selectedRowIndex = selectedRow.index();

        if (selectedRowIndex === -1) {
            $scope.deselectConversation();
        } else {
            if ($scope.currentSelectedRowIndex === selectedRowIndex && !pageChanged && !dataGridHasChanged) {
                $scope.deselectConversationAndClearSelectedRow();
            } else {
                pageChanged = false;
                $scope.currentSelectedRowIndex = selectedRowIndex;
                var selectedRowData = $scope.conversationsGrid.dataItem(selectedRow[0]);
                ConversationsService.getResponsibleConversation(selectedRowData.id)
                    .then(function (response) {
                        $scope.selectedConversation = response.data;
    
                        ConversationsService.getResponsibleConversationNotes(selectedRowData.id)
                            .then(function(responseNotes) {
                                $scope.selectedConversationNotes = responseNotes.data;
                            });
    
                        $scope.selectedConversation.dataPosition = getRowPositionIfGridItemsChange();
                        $scope.selectedConversation.isLastMessageMine = (!!response.data.messages && response.data.messages[response.data.messages.length - 1].mine);
                    });
            }
        }
    }

    function onRowSelectionChange(e) {
        e.preventDefault();
        var selectedRow = this.select();

        if (!!$scope.selectedRowPendingChanges && !ignoreRowSelection && selectedRow.index() !== $scope.currentSelectedRowIndex && $scope.currentSelectedRowIndex !== -1) {
            ModalService.openConfirmationModal({
                title: 'global.modal.error.title',
                message: 'conversations.modal.changesPending.message',
                acceptButtonText: 'conversations.modal.changesPending.discardAndExit',
                acceptButtonTextClass: 'button--danger',
                accept: function() {
                    ignoreRowSelection = false;
                    rowChange(selectedRow);
                },
                cancel: function() {
                    ignoreRowSelection = true;
                    var items = e.sender.items();
                    e.sender.select(items[$scope.currentSelectedRowIndex]);
                }
            });
        } else {
            if (!ignoreRowSelection) {
                rowChange(selectedRow);
            }
            ignoreRowSelection = false;
        }
    }

    function onDataBound(e) {
        KendoUtilsService.hideGridPagerIfJustOnePage(this);
        var items = e.sender.items();
        items.each(function (index) {
            var dataItem = $scope.conversationsGrid.dataItem(this);
            if (dataItem.closedOn != null) {
                return;
            }

            if (isConversationWithoutResponse(dataItem.lastResponsibleMessageDate, dataItem.lastMessageOn)) {
                this.className += " k-state-no-response";
            }
        });

        if (items.length == 0) {
            $scope.selectedConversation = null;
        }

        if (pageChanged && $scope.selectedConversation) {
            this.select(items[0]);
            pageChanged = false;
        }
        
        if (dataGridHasChanged && $scope.selectedConversation) {
            this.select(items[$scope.selectedConversation.dataPosition.newIndexPosition]);
            dataGridHasChanged = false;
        }
    }

    function isConversationWithoutResponse(lastResponsibleMessageDate, lastMessageOn) {
        return momentAWeekAgo().isSameOrAfter(lastMessageOn) && (lastResponsibleMessageDate == null || lastResponsibleMessageDate < lastMessageOn);
    }

    function momentAWeekAgo() {
        return moment().subtract(7, "days");
    }

    function initializeUpdateSelectedRowValidationStateEvent() {
        if (!$rootScope.updateSelectedRowValidationStateListener) {
            $rootScope.updateSelectedRowValidationStateListener = $rootScope.$on('dialenga:updateSelectedRowValidationState', function (event, args) {
                if (args.validationState) {
                    // If new validation state is selected in filter, no grid reload is needed
                    if ($scope.selectedValidationStates.indexOf(args.validationState) !== -1) {
                        var grid = $('#kendo-grid').data('kendoGrid');
                        var selectedItem = grid.dataItem(grid.select());
                        selectedItem.validationState = args.validationState;
                    } else {
                        $scope.reloadGridAndSelectRow();
                    }
                }
            });
        }
    }
    
    function lookForFeedbackTraysWithValidation() {
        var trays = $('#trays').data('kendoDropDownList').dataSource.data();
        for (var index = 0; index < trays.length; index++) {
            if (trays[index].requiresValidation) {
                $scope.isValidationActive = true;
                break;
            } else if (index === (trays.length - 1)) {
                $scope.isValidationActive = false;
            }
        }
    }

    function init() {
        $scope.motivesOptions = ConversationsService.getMotivesIconValueDropDownListOptions();
        var includeAnonymous = true;
        $scope.traysOptions = ConversationsService.getTraysIconValueDropDownListOptions(includeAnonymous);
        $scope.selectedValidationStates = [CONVERSATION_VALIDATION_STATE.NO_REQUIRED,
                                           CONVERSATION_VALIDATION_STATE.PENDING,
                                           CONVERSATION_VALIDATION_STATE.VALIDATED,
                                           CONVERSATION_VALIDATION_STATE.REJECTED];
        $scope.validationStateOptions = ConversationsService.getValidationStatesDropDownListOptions($scope.selectedValidationStates);

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { selectable: true }, { change: onRowSelectionChange, dataBound: onDataBound }).then(function (response) {
            $scope.gridOptions = response;

            $scope.gridOptions.pageable.change = function () {
                pageChanged = true;
            };
        });

        ConversationsService.statistics()
            .then(function (response) {
                $scope.statistics = response.data;
            });

        if (ConversationsService.navigateFromHomeCreateConversation) {
            // Comming from Home (create conversation)
            $scope.newConversation();
            delete ConversationsService.navigateFromHomeCreateConversation;
        }

        if (ConversationsService.navigateFromUserNewConversation) {
            // Comming from User edit view (create conversation)
            $scope.newConversation(ConversationsService.navigateFromUserNewConversation);
            delete ConversationsService.navigateFromUserNewConversation;
        }

        initializeUpdateSelectedRowValidationStateEvent();
    }

    init();

}]);
