'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ImageCropEditionModalController
 * @description
 * # ImageCropEditionModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ImageCropEditionModalController',
    ['$scope', 'params', '$uibModalInstance',
    function ($scope, params, $uibModalInstance) {

    $scope.accept = function () {
        // TODO: choose between PNG and JPEG, if JPEG set fillColor in cropper settings
        $uibModalInstance.close($scope.croppedImage);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var init = function () {
        if (!params.image) {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.croppedImage = '';

        $scope.originImage = params.image;
        $scope.cropperOptions = params.cropOptions;
        $scope.mimeType = params.mimeType;
        $scope.modalTitle = params.modalTexts ? params.modalTexts.title : 'message.imageModalTitle';
        $scope.modalSubtitle = params.modalTexts ? params.modalTexts.subtitle : 'message.imageModalSubtitle';
        $scope.showSubtitle = params.showModalSubtitle;
    };

    init();
}]);
