'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:questionIcon
 * @function
 * @description
 * # questionIcon
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('questionIcon', function () {
    return function (questionKind, questionsKinds) {
        var icon = 'icon-';
        switch (parseInt(questionKind)) {
            case questionsKinds.SINGLE_CHOICE:
                icon += 'questionnaire-options';
                break;
            case questionsKinds.OPEN:
                icon += 'open-question';
                break;
            case questionsKinds.ENDING:
                icon += 'questionnaire-ending';
                break;
            case questionsKinds.COVER:
                icon += 'cover';
                break;
            case questionsKinds.UPLOAD_FILE:
                icon += 'attachment';
                break;
            case questionsKinds.COMMENT:
                icon += 'questionnaire-text';
                break;
            case questionsKinds.REDIRECT:
                icon += 'questionnaire-redirect';
                break;
            default:
                break;
        }
        return icon;
    };
});
