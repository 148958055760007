'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:inputRemainingChars
 * @description
 * # inputRemainingChars
 */
angular.module('dialengaExeviBackoffice').directive('inputRemainingChars', function () {
    return {
        restrict: 'A',
        compile: function (element, attrs) {
            element.after('<p ng-show="inputItems[\'' + attrs.id + '\'].remainingChars < 11" class="dui__message__text dui__message__text--align-right warning">' +
                          '<span translate="global.form.remainingChars" data-translate-values="{current: inputItems[\'' + attrs.id + '\'].currentChars, total: inputItems[\'' + attrs.id + '\'].totalChars}" style="font-size: 12px"></span></p>'); 
        },
        controller: ['$scope', '$element', '$attrs', function( $scope, $element, $attrs ) {

            if (!$scope.inputItems) {
                $scope.inputItems = [];
            }

            $scope.$watch($attrs.ngModel, function(newValue) {
                if (newValue) {
                    var remainingChars = $attrs.maxlength - newValue.length;
                    $scope.inputItems[$attrs.id] = {
                        remainingChars: remainingChars,
                        currentChars: newValue.length,
                        totalChars: $attrs.maxlength,
                        colorClass: (remainingChars < 10) ? 'danger' : 'black'
                    };
                } else {
                    $scope.inputItems[$attrs.id] = {
                        remainingChars: $attrs.maxlength,
                        colorClass: 'black'
                    };
                }
            });

        }]
    };
});