'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ConfirmationModalController
 * @description
 * # ConfirmationModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ConfirmationModalController',
    ['$scope', '$uibModalInstance', 'ModalService', 'params',
    function ($scope, $uibModalInstance, ModalService, params) {

    //****************************************** Attributes *************************************\\
    $scope.modal = {
        title: params.title,
        message: params.message,
        acceptButtonText: params.acceptButtonText,
        acceptButtonTextClass: params.acceptButtonTextClass,
        cancelButtonText: params.cancelButtonText,
        focusCancel: params.focusCancel,
        hideCancelButton: params.hideCancelButton,
        skipModalAlias: !!params.skipModalAlias,
        showInfoIcon: params.showInfoIcon || false
    };
    
    //******************************************* Methods ***************************************\\
    $scope.accept = function () {
        ModalService.saveSkipModalSetting($scope.modal.skipModalValue, params.skipModalAlias);
        $uibModalInstance.close($scope.modal.inputData);
    };

    $scope.cancel = function () {
        ModalService.saveSkipModalSetting($scope.modal.skipModalValue, params.skipModalAlias);
        $uibModalInstance.dismiss('cancel');
    };

    $scope.closeModal = function () {
        if (!!params.closeModalOnlyFromTopButton) {
            ModalService.saveSkipModalSetting($scope.modal.skipModalValue, params.skipModalAlias);
            $uibModalInstance.dismiss('closeButton');
        } else {
            $scope.cancel();
        }
    };
}]);
