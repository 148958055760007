'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.LocationService
 * @description
 * # LocationService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('LocationService', 
    ['$http', '$translate', 'KendoUtilsService', 'SharedDataService',
    function ($http, $translate, KendoUtilsService, SharedDataService) {
    
    var self = this;
    self.URL = SharedDataService.apiUrl + '/locations';

    function getCountryCodes() {
        return $http({
            url: SharedDataService.apiUrl + '/country-codes',
            method: 'GET'
        });
    }

    this.getCountryCodesOptions = function (selectDefault) {
        var options = !!selectDefault ? KendoUtilsService.getDropDownListOptionsAutoSelect(selectDefault) : KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            getCountryCodes().then(function(response) {
                response.data = response.data.sort(function(a, b) { 
                    if (a.country < b.country) { return -1; }
                    if (a.country > b.country) { return 1; }
                    return 0;
                });
                event.success(response.data);
            });
        };
        options.dataTextField = '';
        options.dataValueField = 'code';
        var countryCodesTemplate = '<div>' +
            '<span>{{dataItem.country}} (+{{dataItem.code}})</span>' +
        '</div>';
        options.template = countryCodesTemplate;
        options.valuePrimitive = true;
        options.valueTemplate = '<div class="max-width-90-percent">' +
            '<span class="truncate">(+{{dataItem.code}})</span>' +
            '</div>';
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getUserCountriesOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            getCountryCodes().then(function(response) {
                event.success(response.data);
            });
        };
        options.dataSource.sort = { field: 'country', dir: 'asc' };
        options.dataTextField = 'country';
        options.dataValueField = 'isoCode';
        options.valuePrimitive = true;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getCountriesOptions = function (changeCallback) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            getCountryCodes().then(function(response) {
                event.success(response.data);
            });
        };
        options.dataSource.sort = { field: 'country', dir: 'asc' };
        options.dataTextField = 'country';
        options.dataValueField = 'id';
        options.dataBound = function () {
            var selectedValue = this.value();
            if (selectedValue) {
                $('#autonomous-communities-dropdown').data('kendoDropDownList').dataSource.read({countryId: selectedValue});
            }
        };
        options.change = function () {
            var selectedValue = this.value();
            if (!!changeCallback) {
                changeCallback(selectedValue);
            }
        };
        options.autoBind = false;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getNationalityOptions = function () {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function (event) {
            getCountryCodes().then(function(response) {
                event.success(response.data);
            });
        };
        options.filter = 'startswith';
        options.messages = {
            clear: $translate.instant('global.form.delete')
        };
        options.dataSource.sort = { field: 'country', dir: 'asc' };
        options.dataTextField = 'country';
        options.dataValueField = 'id';
        options.placeholder = $translate.instant('global.form.notSetFemale');
        options.optionLabel = $translate.instant('global.form.notSetFemale');
        options.optionLabelTemplate = '<span class="black45" translate="global.form.notSetFemale"></span>';
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    function getAutonomousRegions(countryId) {
        return $http({
            url: self.URL + '/' + countryId + '/autonomous-regions',
            method: 'GET'
        });
    }

    this.getAutonomousCommunitiesOptions = function(changeCallback) {
        var countryId;
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            countryId = event.data.countryId;
            if (countryId) {
                getAutonomousRegions(countryId).then(function(response) {
                    event.success(response.data);
                });
            }
        };
        options.dataBound = function () {
            this.enable(true);
            var selectedValue = this.value();
            if (selectedValue) {
                $('#provinces-dropdown').data('kendoDropDownList').dataSource.read({countryId: countryId, autonomousCommunityId: selectedValue});
            }
        };
        options.change = function () {
            if (!!changeCallback) {
                var selectedValue = this.value();
                changeCallback(selectedValue);
            }
        };
        options.autoBind = false;
        options.enable = false;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    function getProvinces(countryId, autonomousCommunityId) {
        return $http({
            url: self.URL + '/' + countryId + '/autonomous-regions/' + autonomousCommunityId + '/provinces',
            method: 'GET'
        });
    }

    this.getProvincesOptions = function(changeCallback) {
        var countryId;
        var autonomousCommunityId;
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.transport.read = function(event) {
            countryId = event.data.countryId;
            autonomousCommunityId = event.data.autonomousCommunityId;
            if (countryId && autonomousCommunityId) {
                getProvinces(countryId, autonomousCommunityId).then(function(response) {
                    event.success(response.data);
                });
            }
        };
        options.dataBound = function () {
            this.enable(true);
            var selectedValue = this.value();
            if (selectedValue) {
                $('#municipalities-dropdown').data('kendoComboBox').dataSource.read({
                    countryId: countryId,
                    autonomousCommunityId: autonomousCommunityId,
                    provinceId: selectedValue
                });
            }
        };
        options.change = function () {
            if (!!changeCallback) {
                var selectedValue = this.value();
                changeCallback(selectedValue);
            }
        };
        options.autoBind = false;
        options.enable = false;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    function getMunicipalities(countryId, autonomousCommunityId, provinceId, municipalitiesFilter) {
        var url = self.URL + '/' + countryId + '/autonomous-regions/' + autonomousCommunityId + '/provinces/' + provinceId + '/municipalities';
        if (!!municipalitiesFilter) {
            url += '?name=' + municipalitiesFilter.toLowerCase();
        }
        return $http({
            url: url,
            method: 'GET',
            params: {
                page: 0,
                size: 20000,
                sort: 'name,asc'
            }
        });
    }

    this.getMunicipalitiesOptions = function() {
        var municipalitiesFilter;
        var countryId, autonomousCommunityId, provinceId;
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource.serverFiltering = true;
        options.dataSource.transport.read = function(event) {
            if (event.data.countryId && event.data.autonomousCommunityId && event.data.provinceId) {
                countryId = event.data.countryId;
                autonomousCommunityId = event.data.autonomousCommunityId;
                provinceId = event.data.provinceId;
            }
            if (event.data.municipalitiesFilter) {
                municipalitiesFilter = event.data.municipalitiesFilter;
            }
            if (countryId && autonomousCommunityId && provinceId) {
                getMunicipalities(countryId, autonomousCommunityId, provinceId, municipalitiesFilter).then(function(response) {
                    event.success(response.data.content);
                });
            }
        };
        options.dataBound = function () {
            this.enable(true);
            municipalitiesFilter = '';
        };
        options.filtering = function (event) {
            if (event.filter) {
                municipalitiesFilter = event.filter.value;
            }
        };
        options.autoBind = false;
        options.enable = false;
        options.filter = 'contains';
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

}]);
