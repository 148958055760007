'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:ServerErrorController
 * @description
 * # ServerErrorController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('ServerErrorController',
    ['$scope', '$rootScope', '$translate', 'ErrorInterceptor', 'NotificationService',
    function ($scope, $rootScope, $translate, ErrorInterceptor, NotificationService) {

    $scope.copyErrorToClipboard = function () {
        var tempInput = document.createElement("textarea");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = JSON.stringify($rootScope.lastFailedRequest);
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        var successMessage = $translate.instant('500.copiedToClipboard');
        NotificationService.notifySuccess(successMessage);
    };

    $scope.retryLastAction = function () {
        ErrorInterceptor.retryRequest();
    };

}]);
