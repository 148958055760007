'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:dFilter
 * @function
 * @description
 * # dFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('dFilter', ['$filter', function ($filter) {
    return function (input, filter) {
        if (filter) {
            var splitedFilter = filter.split(/^\w*:/);
            if (splitedFilter.length > 1) {
                return $filter(filter.match(/^\w*/))(input, splitedFilter[1].trim());
            } else {
                return $filter(filter)(input);
            }
        } else {
            return input;
        }
    };
}]);