'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CategoriesController
 * @description
 * # CategoriesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CategoriesController', ['$scope', '$location', '$translate', 'CategoryService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'PAGE_SIZE',
    function($scope, $location, $translate, CategoryService, KendoUtilsService, ModalService, NotificationService, PAGE_SIZE) {

        //******************************************* Attributes ************************************\\
        $scope.stateIsActive = true;
        $scope.inputSearchField = "";

        var gridColumns = [{
            field: "publicationGroupingContents.name",
            title: $translate.instant('model.category.name'),
            template: '<no-default-language-tooltip available-languages="dataItem.availableLanguages"></no-default-language-tooltip>' +
                '<span class="categories-table-container truncate" data-ng-bind-html="dataItem | messageCategoryFilter"></span>',
            width: 350
        }, {
            field: "contentsCount",
            title: $translate.instant('model.category.contents'),
            template: '<div ng-if="!dataItem.subfoldersCount && !dataItem.contentsCount" class="no-contents">' +
                '<span class="no-contents--label" translate="model.category.contentsEmpty"></span>' +
                '<info-tooltip class="no-contents--icon" tooltip-text="\'model.category.contentsEmptyTooltip\'"></info-tooltip>' +
                '</div>' +
                '<span ng-if="dataItem.subfoldersCount" translate="model.category.contentsFoldersDetail" ' +
                'translate-values="{\'numFolders\': dataItem.subfoldersCount}" translate-interpolation="messageformat"></span>' +
                '<span ng-if="dataItem.subfoldersCount && dataItem.contentsCount">, </span>' +
                '<span ng-if="dataItem.contentsCount" translate="model.category.contentsDetail" ' +
                'translate-values="{\'numContents\': dataItem.contentsCount}" translate-interpolation="messageformat"></span>',
            sortable: false
        }, {
            command: [{
                template:'<div class="td-on-hover">'
            },{
                name: "edit-button",
                template: '<a class="k-button k-grid-edit-button" kendo-tooltip k-content="\'global.form.edit\' | translate"><span class="icon icon-edit"></span></a>',
                click: editRowClickHandler
            }, {
                name: "delete-button",
                template: '<a class="k-button k-grid-delete-button" kendo-tooltip k-content="\'global.form.delete\' | translate" ' +
                    'data-ng-if="dataItem.deletable && dataItem.active"><span class="icon icon-delete"></span></a>',
                click: deleteRowClickHandler
            }, {
                name: "archive-button",
                template: '<a class="k-button k-grid-archive-button" kendo-tooltip k-content="\'global.form.archive\' | translate" ' +
                    'data-ng-if="!dataItem.deletable && dataItem.active"><span class="icon icon-archive"></span></a>',
                click: archiveRowClickHandler
            }, {
                name: "activate-button",
                template: '<a class="k-button k-grid-activate-button" kendo-tooltip k-content="\'global.form.activate\' | translate" ' +
                    'data-ng-if="!dataItem.active"><span class="icon icon-unarchive"></span></a>',
                click: activateRowClickHandler
            }, {
                template:'</div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: " "
        }];
        var gridDataSource = {
            transport: {
                read: function(options) {
                    saveGridPreferences(options);

                    var defaultSortOrder = ['lastModifiedDate,desc', 'publicationGroupingContents.name,asc'];
                    var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder, $scope.inputSearchField, 'publicationGroupingContents.name');
                    requestOptions['active'] = $scope.stateIsActive;

                    CategoryService.findAll(requestOptions).then(function(response) {
                        $scope.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                model: { id: "id" },
                total: "totalElements"
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false }).then(function(response) {
            $scope.gridOptions = response;
        });

        //******************************************** Methods **************************************\\
        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['categoriesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['categoriesGrid'].stateIsActive = $scope.stateIsActive;
            KendoUtilsService.gridPreferences['categoriesGrid'].inputSearchField = $scope.inputSearchField;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === $scope.categoriesGrid) {
                if (KendoUtilsService.gridPreferences['categoriesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['categoriesGrid'];
                    $scope.stateIsActive = requestOptions.stateIsActive;
                    $scope.inputSearchField = requestOptions.inputSearchField;

                    $scope.categoriesGrid.dataSource.query(requestOptions);
                } else {
                    $scope.categoriesGrid.dataSource.read();
                }
            }
        });

        function editRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            $scope.$apply(function() {
                $location.path('/settings/categories/update/' + dataItem.id);
            });
        }

        function actionErrorHandler(error) {
            if (error.status === 422) {
                reloadGrid();
            } else {
                var message = $translate.instant('global.messages.error.unknown');
                NotificationService.notifyError(message);
            }
        }

        function deleteRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'categories.modal.delete.title',
                message: 'categories.modal.delete.message',
                messageData: { categoryName: dataItem.name },
                acceptButtonText: 'global.form.delete',
                accept: function() {
                    CategoryService.delete(dataItem.id).then(function() {
                            reloadGrid();
                            var message = $translate.instant('categories.notification.delete.message', { categoryName: dataItem.name });
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function archiveRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'categories.modal.archive.title',
                message: 'categories.modal.archive.message',
                messageData: { categoryName: dataItem.name },
                acceptButtonText: 'global.form.archive',
                accept: function() {
                    CategoryService.archive(dataItem.id)
                        .then(function() {
                            reloadGrid();
                            var message = $translate.instant('categories.notification.archive.message', { categoryName: dataItem.name });
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function activateRowClickHandler(event) {
            event.preventDefault();

            var dataItem = this.dataItem($(event.currentTarget).closest("tr"));

            ModalService.openConfirmationModal({
                title: 'categories.modal.activate.title',
                message: 'categories.modal.activate.message',
                messageData: { categoryName: dataItem.name },
                acceptButtonText: 'global.form.activate',
                accept: function() {
                    CategoryService.activate(dataItem.id)
                        .then(function() {
                            reloadGrid();
                            var message = $translate.instant('categories.notification.activate.message', { categoryName: dataItem.name });
                            NotificationService.notifySuccess(message);
                        })
                        .catch(actionErrorHandler);
                }
            });
        }

        function reloadGrid(options) {
            $scope.isSyncing = true;
            KendoUtilsService.reloadGridDatasource($scope.categoriesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    $scope.isSyncing = false;
                });
            });
        }

        $scope.listItemsSearch = function(text) {
            $scope.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        $scope.getCategoriesByIsActive = function(isActive) {
            if ($scope.stateIsActive !== isActive) {
                $scope.stateIsActive = isActive;
                reloadGrid({ page: 1 });
            }
        };

        /* Download CSV */

        $scope.openDatePopover = false;

        $scope.downloadCSV = function(event) {
            var urlRequests = CategoryService.URL + '/statistics/export-csv?startDate=' + event.startDate + '&endDate=' + event.endDate;
            var downloadStatisticsReport = angular.element('<a id="statistics-report" href="'+ urlRequests + '" target="_self" is-external-link></a>');
            downloadStatisticsReport[0].click();
            angular.element(document.querySelector('#statistics-report')).remove();
            $scope.openDatePopover = false;
        }

        $scope.cancelDownload = function(event) {
            $scope.openDatePopover = false;
        }

        /* End Download CSV */

        KendoUtilsService.dblClickGridRedirect('kendo-grid', '/settings/categories/update/');
    }
]);