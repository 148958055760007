'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffController
 * @description
 * # TimeoffController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffController', 
    ['$scope', '$route', '$translate', '$timeout', 'ModalService', 'SharedDataService', 'NotificationService', 'TIMEOFF_TABS', 'TIMEOFF_KIND', 'selectedTab',
    function($scope, $route, $translate, $timeout, ModalService, SharedDataService, NotificationService, TIMEOFF_TABS, TIMEOFF_KIND, selectedTab) {
        var vm = this;

        vm.TIMEOFF_TABS = TIMEOFF_TABS;
        vm.selectedTab = selectedTab;
        vm.selectedRequest = null;
        var timeoffRequestsGridFilterKind = '';
        var timeoffRequestsGridFilterState = '';

        vm.loadTabContent = function(tab) {
            if (vm.selectedTab !== tab) {
                vm.selectedTab = tab;
                if (tab === TIMEOFF_TABS.REQUESTS) {
                    $route.updateParams({ tabName: 'requests' });
                }
            }
        };

        vm.cloneSelectedContent = function() {

        };

        function getMessageToNotify(kind, state) {
            var textToTranslate = $translate.instant('timeoffRequests.notification.add.requestSuccess', {kind: kind}, 'messageformat');
            var isStateOfNewRequestShowingInFilter = timeoffRequestsGridFilterState.indexOf(state) !== -1;

            if( (timeoffRequestsGridFilterKind === 0 && !isStateOfNewRequestShowingInFilter) ||
                (timeoffRequestsGridFilterKind !== 0 && (timeoffRequestsGridFilterKind !== kind || !isStateOfNewRequestShowingInFilter)) ) {
                    textToTranslate += '<br>' + $translate.instant('timeoffRequests.notification.add.noShowing');
            }
            
            return textToTranslate;
        }

        vm.newRequestForEmployee =  function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff-request-modal/timeoff-request-modal.html',
                controller: 'TimeoffRequestModalController',
                size: 'lg',
                resolveParams: {
                    title: 'timeoff.requests.newRequest',
                },
                accept: function(request) {
                    $scope.$broadcast('dialenga:refreshTimeoffRequestsGridAndNotification', {request: request});
                }
            });
        };

        vm.exportToCsv = function() {
            ModalService.openModal({
                templateUrl: '/ng1/components/timeoff/timeoff-exportCsv-modal.html',
                controller: 'TimeoffExportCsvModalController',
                size: 'md',
                
                accept: function(downloadOptions) {
                    if (downloadOptions.requestsReport) {
                        var urlRequests = SharedDataService.apiUrl + '/timeoff/request/export-csv?startDate='+kendo.toString(downloadOptions.previewKendoStartDate, 'yyyy-MM-dd')+'&endDate='+kendo.toString(downloadOptions.previewKendoEndDate,'yyyy-MM-dd');
                        var downloadRequestReport = angular.element('<a id="requests-report" href="'+ urlRequests + '" target="_self" is-external-link></a>');
                        downloadRequestReport[0].click();
                        angular.element(document.querySelector('#requests-report')).remove();
                    }
                    if (downloadOptions.balanceReport) {
                        var urlBalance = SharedDataService.apiUrl + '/holidays/my-company/holidays-periods';
                        var downloadBalanceReport = angular.element('<a id="balance-report" href="'+ urlBalance + '" target="_self" is-external-link></a>');
                        $timeout (function() {
                            downloadBalanceReport[0].click();
                        }, downloadOptions.requestsReport ? 1500 : null);
                        angular.element(document.querySelector('#balance-report')).remove();
                    }
                }
            });
        };

        $scope.$on('dialenga:timeoffRequestsFiltersActives', function(event, args) {
            timeoffRequestsGridFilterKind = args.kind;
            timeoffRequestsGridFilterState = args.state;
        });

        $scope.$on('dialenga:refreshTimeoffRequestsGridAndNotification', function(event, args) {
            $scope.$broadcast('dialenga:reloadTimeoffRequestsGrid');
            var message = getMessageToNotify(args.request.kind, args.request.stateId);
            NotificationService.notifySuccess(message);
        });
    }
]);