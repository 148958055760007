'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:QuestionnaireQuestionController
 * @description
 * # QuestionnaireQuestionController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('QuestionnaireQuestionController',
    ['$anchorScroll', '$scope', '$rootScope', '$routeParams', '$location', '$timeout', '$translate', 'KendoUtilsService', 'LanguageService', 'SegmentService', 'ModalService',
    'AttachedContentService', 'PublicationService', 'QuestionnaireService', 'GlobalService', 'UrlUtilsService', 'UtilsService', 'CONTENT_KIND', 'QUESTION_KIND', 'QUESTIONNAIRE_KIND',
    function ($anchorScroll, $scope, $rootScope, $routeParams, $location, $timeout, $translate, KendoUtilsService, LanguageService, SegmentService, ModalService,
        AttachedContentService, PublicationService, QuestionnaireService, GlobalService, UrlUtilsService, UtilsService, CONTENT_KIND, QUESTION_KIND, QUESTIONNAIRE_KIND) {

    //******************************************* Attributes ************************************\\
    $scope.isValidURL = false;
    $scope.isValidURLYoutube = false;
    $scope.questionnaireId = $routeParams.id;
    $scope.changed = false;
    $scope.valid = false;
    $scope.isCover = false;
    $scope.QUESTIONNAIRE_KIND = QUESTIONNAIRE_KIND;
    $scope.MULTIPLE_KIND = { LIMITLESS: 1, EXACT: 2, RANGE: 3 };
    $scope.questionForm = {
        question: {
            contents: {},
            answers: [],
            multipleOptions: {
                multiple: false,
                multipleSelectionKind: $scope.MULTIPLE_KIND.LIMITLESS,
                minimum: null,
                maximum: null
            },
            weightEnabled: false
        },
        images: [],
        resources: []
    };
    $scope.toggleInput = {
        isDescriptionEnabled: false,
        isImageGroupEnabled: false,
        isImageVisible: true,
        isOptionalImageVisible: false
    };
    $scope.questions = [];

    $scope.previewState = { opened: false };
    $scope.CONTENT_KIND = CONTENT_KIND;
    $scope.QUESTION_KIND = QUESTION_KIND;
    QuestionnaireService.multipleAnswersTemp = {};
    $scope.readabilityIndex = null;
    $scope.readingTime = null;

    //******************************************** Methods **************************************\\
    function resetAnswersWeights(answers) {
        for (var index = 0; index < answers.length; index++) {
            answers[index].weight = null;
        }
    }

    $scope.getImageForReadIndex = function() {
        return UtilsService.getReadabilityIndexImageFile($scope.readabilityIndex);
    };

    $scope.getTextForReadIndex = function() {
        return UtilsService.getReadabilityIndexText($scope.readabilityIndex);
    };

    $scope.getReadabilityIndex = function() {
        // Detect language of entered text
        // var textLanguage = UtilsService.detectLanguage($scope.messageForm.message.contents[$scope.language.selectedLanguage].text);

        // Get plain text for next operations (clean html tags and replace special characters)
        var textPlain = UtilsService.getPlainTextFromString($scope.questionForm.question.contents[$scope.selectedLanguage].text);

        var syllablesFromText = 0;
        var sentencesFromText = 1;
        var wordsFromText = [];
        
        // Obtain sentences number from text
        sentencesFromText = UtilsService.getSentencesNumberFromText(textPlain); 

        // Obtain words from text
        wordsFromText = textPlain.split(' ');
        // remove last position if empty: when text end with a white space
        if (wordsFromText[wordsFromText.length - 1] === '') {
            wordsFromText.pop();
        }

        // Calculate readability index if text have 3 or more words
        if (wordsFromText.length > 2) {
            // Obtain syllables number from text
            for( var index = 0; index < wordsFromText.length; index++) {
                syllablesFromText += UtilsService.syllablesCounterSpanish(wordsFromText[index]);
            }
    
            // Obtain Readability Index 
            var textData = {
                sentences: sentencesFromText,
                words: wordsFromText,
                syllables: syllablesFromText,
                indexKind: 'INFLESZ'
            }
            $scope.readabilityIndex = UtilsService.calculateReadabilityIndex(textData);

            // Update tooltip text for readability index
            $timeout(function () {
                var readIndexTooltip = $('#read-index-tooltip').data('kendoTooltip');
                if (readIndexTooltip) {
                        readIndexTooltip.options.content = $translate.instant('message.readability.tooltip', {readabilityIndex: $scope.readabilityIndex});
                        readIndexTooltip.refresh();
                }
            }, 500);
        } else {
            $scope.readabilityIndex = null;
            return;
        }
    };

    $scope.clearFieldValueInAllLanguages = function (fieldName) {
        var languages = Object.keys($scope.questionForm.question.contents);
        for (var index = 0; index < languages.length; index++) {
            if ($scope.questionForm.question.contents[languages[index]].hasOwnProperty(fieldName)) {
                $scope.questionForm.question.contents[languages[index]][fieldName] = '';
            }
        }
    };

    $scope.$watch('questionForm.question.answers', function (newValue, oldValue) {
        if (newValue && oldValue && JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue.length !== oldValue.length) {
            var newMaxValue = newValue.length;
            if ($scope.questionForm.question.multipleOptions.minimum > newMaxValue) {
                $scope.questionForm.question.multipleOptions.minimum = newMaxValue;
            }
            if ($scope.questionForm.question.multipleOptions.maximum > newMaxValue) {
                $scope.questionForm.question.multipleOptions.maximum = newMaxValue;
            }
        }
    }, true);

    function parseImageAndVideo() {
        if (!$scope.toggleInput.isImageGroupEnabled) {
            $scope.questionForm.images = [];
            AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.questionForm.images, $scope.questionForm.question.contents, $scope.selectedLanguage);
            $scope.clearFieldValueInAllLanguages('imageURL');
            $scope.clearFieldValueInAllLanguages('youtubeURL');
        } else {
            if ($scope.toggleInput.isImageVisible) {
                // Discard video if image tab is selected
                $scope.clearFieldValueInAllLanguages('youtubeURL');
            } else if (!$scope.toggleInput.isOptionalImageVisible) {
                // Discard image if video tab is selected and alt image toggle is disabled
                $scope.questionForm.images = [];
                $scope.clearFieldValueInAllLanguages('imageURL');
            }
        }
    }

    $scope.questionFormSave = function () {
        var isFormDirty = dataHasChanged();
        if (isFormDirty) {
            if ($scope.questionForm.question.kind === QUESTION_KIND.SINGLE_CHOICE
                && $scope.questionForm.question.weightEnabled === false) {
                resetAnswersWeights($scope.questionForm.question.answers);
            }
            LanguageService.removeEmptyLanguages($scope.questionForm.question);

            parseAnswersSegment($scope.questionForm.question.answers);            
            if ($scope.questionForm.question.segment && $scope.questionForm.question.segment.id === null) {
                $scope.questionForm.question.segment = null;
            }
            if (!$scope.toggleInput.isDescriptionEnabled) {
                $scope.clearFieldValueInAllLanguages('text');
            }
            $scope.questionForm.question.linkedQuestion = !$scope.questionForm.question.isLinkActive ? null : $scope.questionForm.question.linkedQuestion;
            parseImageAndVideo();
            var promise;
            if ($scope.questionForm.question.id) {
                promise = QuestionnaireService.updateQuestion($scope.questionForm, $scope.questionnaireId);
            } else {
                promise = QuestionnaireService.createQuestion($scope.questionForm, $scope.questionnaireId);
            }
            promise.then(function () {
                $location.path('/questionnaires/edit/' + $scope.questionnaireId);
            }, formSubmitErrorHandler);
        } else {
            $location.path('/questionnaires/edit/' + $scope.questionnaireId);
        }
    };

    function parseAnswersSegment(answers) {
        if (answers) {
            for (var index = 0; index < answers.length; index++) {
                if (answers[index].segment && answers[index].segment.id == null) {
                    answers[index].segment = null;
                } 
            }
        }
    }

    function formSubmitErrorHandler() {
        $rootScope.$broadcast('dialenga:loadingEnd');
    }

    function isCurrentLanguageQuestionValid(content, kind) {
        return typeof content !== 'undefined'
            && content.title
            && (kind !== QUESTION_KIND.COVER || !!content.buttonLabel)
            && UrlUtilsService.isValidExternalURL(content.externalURL)
            && UrlUtilsService.isValidExternalURL(content.youtubeURL);
    }

    $scope.validateModel = function () {
        $scope.valid = LanguageService.validateQuestionnaireNonEmptyLanguages($scope.questionForm.question, isCurrentLanguageQuestionValid);
    };

    $scope.onChange = function () {
        $scope.changed = true;
    };

    function resetMultipleOptions() {
        // If multiple selection is turned off, multipleSelectionKind sent to server should be EXACT
        $scope.questionForm.question.multipleOptions.multipleSelectionKind = $scope.MULTIPLE_KIND.EXACT;
        $scope.questionForm.question.multipleOptions.minimum = 1;
        $scope.questionForm.question.multipleOptions.maximum = 1;
    }

    function deselectActiveJumpItems() {
        var activeJumpItems = document.querySelectorAll('.icon-jump.answer__icon--active');
        for (var index = 0; index < activeJumpItems.length; index++) {
            activeJumpItems[index].click();
        }
    }

    function promptClearActiveLinksConfirmation() {
        ModalService.openConfirmationModal({
            title: 'global.modal.error.title',
            message: 'questionnaire.question.multipleSelection.removeLinksConfirmation',
            acceptButtonText: 'global.form.continue',
            accept: function () {
                deselectActiveJumpItems();
            },
            cancel: function () {
                $scope.questionForm.question.multipleOptions.multiple = false;
                resetMultipleOptions();
            }
        });
    }

    $scope.onMultipleSelectionChange = function () {
        if (!$scope.questionForm.question.multipleOptions.multiple) {
            resetMultipleOptions();
        } else {
            $scope.questionForm.question.multipleOptions.multipleSelectionKind = $scope.MULTIPLE_KIND.LIMITLESS;
            var linkedAnswers = $scope.questionForm.question.answers.filter(function (answer) {
                return answer.isLinkActive && !!answer.linkedQuestion;
            });
            if (linkedAnswers.length > 0) {
                promptClearActiveLinksConfirmation();
            } else {
                deselectActiveJumpItems();
            }
        }
        $scope.onChange();
    };

    $scope.selectMultipleKind = function (kind) {
        if (kind === $scope.MULTIPLE_KIND.LIMITLESS) {
            $scope.questionForm.question.multipleOptions.minimum = null;
            $scope.questionForm.question.multipleOptions.maximum = null;
        } else if (kind === $scope.MULTIPLE_KIND.EXACT) {
            $scope.questionForm.question.multipleOptions.minimum = 1;
            $scope.questionForm.question.multipleOptions.maximum = 1;
        } else if (kind === $scope.MULTIPLE_KIND.RANGE) {
            $scope.questionForm.question.multipleOptions.minimum = 1;
            $scope.questionForm.question.multipleOptions.maximum = $scope.questionForm.question.answers.length;
        }
        $scope.questionForm.question.multipleOptions.multipleSelectionKind = kind;
    };

    function dataHasChanged() {
        return !angular.equals($scope.questionForm.question, QuestionnaireService.latestQuestion) || !angular.equals($scope.toggleInput, QuestionnaireService.toggleInput);
    }

    $scope.goBack = function() {
        var isFormDirty = dataHasChanged();
        $rootScope.goBackAndCheckForModifications(isFormDirty, '/questionnaires/edit/' + $routeParams.id);
    };

    $scope.testUrlLinks = function () {
        $scope.isValidURL = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'externalURL');
        $scope.isValidURLYoutube = PublicationService.validateUrlLinks($scope.questionForm.question, $scope.selectedLanguage, 'youtubeURL');
    };

    $scope.addAnswerOption = function (avoidValidation) {
        var index = $scope.questionForm.question.answers.length + 1;
        var localizedContent = {};
        localizedContent[$scope.selectedLanguage] = { text: '' };
        $scope.questionForm.question.answers.push({
            contents: localizedContent,
            isLinkActive: false,
            linkedQuestion: null,
            order: index
        });

        if (!avoidValidation) {
            $scope.validateModel();
        }
    };

    var initAnswersLinks = function (answers) {
        for (var index = 0; index < answers.length; index++) {
            answers[index].isLinkActive = !!answers[index].linkedQuestion;
        }
    };

    function getQuestionOrder(questionnaire) {
        return QuestionnaireService.newContentOrder || QuestionnaireService.newContentOrder == 0 ? QuestionnaireService.newContentOrder : questionnaire.questions.length + 1;
    }

    function initLinkDropDownList(questionnaire) {
        var questionnaireItems = questionnaire.questions.concat(questionnaire.endings);
        var questionnaireItemTitle;
        for (var index = 0; index < questionnaireItems.length; index++) {
            // Add questions and endings to available items to the link list, except the current one
            if (!$routeParams.questionId || $routeParams.questionId != questionnaireItems[index].id) {
                // If current language is not available in a questionnaire item,
                // get the language that best suits the user
                if (!questionnaireItems[index].contents[$scope.selectedLanguage]) {
                    var language = LanguageService.getMainContentLanguage(questionnaireItems[index].contents);
                    questionnaireItemTitle = questionnaireItems[index].contents[language].title;
                } else {
                    questionnaireItemTitle = questionnaireItems[index].contents[$scope.selectedLanguage].title;
                }

                $scope.questions.push({
                    id: questionnaireItems[index].id,
                    title: questionnaireItemTitle,
                    kind: questionnaireItems[index].kind,
                    order: questionnaireItems[index].order
                });
            }
        }
    }

    function scrollToHashIfPresent() {
        var pathHash = $location.hash();
        if (pathHash) {
            $timeout(function () {
                $location.hash(pathHash);
                $anchorScroll();
            });
        }
    }

    $scope.getMinimumInputMaxValue = function () {
        return $scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.RANGE && $scope.questionForm.question.multipleOptions.maximum ?
            $scope.questionForm.question.multipleOptions.maximum : $scope.questionForm.question.answers.length;
    };

    $scope.decreaseValue = function (aliasName) {
        $scope.questionForm.question.multipleOptions[aliasName] -= 1;
        QuestionnaireService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        if ($scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.EXACT && aliasName === 'minimum') {
            $scope.decreaseValue('maximum');
        }
        $scope.onChange();
    };

    $scope.increaseValue = function (aliasName) {
        $scope.questionForm.question.multipleOptions[aliasName] += 1;
        QuestionnaireService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        if ($scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.EXACT && aliasName === 'minimum') {
            $scope.increaseValue('maximum');
        }
        $scope.onChange();
    };

    $scope.isMinimumIncreaseDisabled = function () {
        return $scope.questionForm.question.multipleOptions.multipleSelectionKind === $scope.MULTIPLE_KIND.RANGE ?
            $scope.questionForm.question.multipleOptions.minimum >= $scope.questionForm.question.multipleOptions.maximum :
            $scope.questionForm.question.multipleOptions.minimum >= $scope.questionForm.question.answers.length;
    };

    function isNumeric(value) {
        return value !== null && typeof value !== 'undefined' && !isNaN(value);
    }

    $scope.onNumInputChange = function (aliasName) {
        if (isNumeric($scope.questionForm.question.multipleOptions[aliasName])) {
            QuestionnaireService.multipleAnswersTemp[aliasName] = $scope.questionForm.question.multipleOptions[aliasName];
        } else {
            $scope.questionForm.question.multipleOptions[aliasName] = !!QuestionnaireService.multipleAnswersTemp[aliasName] ? QuestionnaireService.multipleAnswersTemp[aliasName] : 1;
        }
        $scope.onChange();
    };

    $scope.onToggleAllowSegment = function() {
        if (!$scope.questionForm.question.allowSegment) {
            $scope.questionForm.question.segment = null;
        }
    };

    $scope.addSegment = function () {
        ModalService.openSegmentModal({
            accept: function () {
                $scope.segmentsOptions.dataSource.read();
                // When read event ends, segmentChangeCallback function is called
                $rootScope.$broadcast('dialenga:loadingEnd');
            },
            cancel: function() {
                $rootScope.$broadcast('dialenga:loadingEnd');
            }
        });
    };

    function segmentChangeCallback() {
        // Change event called when dropdownlist read event has successfully finished
        // Set created segment from modal as selected item in dropdownlist
        $timeout(function () {
            if (SegmentService.latestSegment) {
                $scope.questionForm.question.segment = SegmentService.latestSegment;
                delete SegmentService.latestSegment;
            }
        }, 100);
    }

    var init = function () {
        $scope.disabledTooltipText = LanguageService.getDisabledTooltipTextBasedOnAvailableLanguages();
        $scope.segmentsOptions = SegmentService.getSegmentsDropDownListOptions(segmentChangeCallback);

        QuestionnaireService.findOne($scope.questionnaireId).then(function (response){
            $scope.questionnaireName = response.data.name;
            $scope.questionnaireKind = response.data.kind;
            $scope.isAnonymous = response.data.anonymous;
            initLinkDropDownList(response.data);
            if ($scope.questions.length > 0) {
                $scope.linkedQuestionsOps = QuestionnaireService.getLinkedQuestionsDropDownListOptions($scope.questions);
            }
            if ($routeParams.questionId) {
                QuestionnaireService.findOneQuestion($scope.questionnaireId, $routeParams.questionId).then(function (questionResponse) {
                    $scope.questionForm.question = questionResponse.data;
                    if ($scope.questionForm.question.segment && $scope.questionForm.question.segment.id) {
                        $scope.questionForm.question.allowSegment = true;
                    }
                    var questionContent = questionResponse.data.contents[$scope.selectedLanguage];
                    $scope.isAttachedResourceEnabled = !!(questionContent && questionContent.resourceURL);

                    if (questionResponse.data.kind === QUESTION_KIND.SINGLE_CHOICE) {
                        initAnswersLinks($scope.questionForm.question.answers);
                    }
                    $scope.isCover = questionResponse.data.kind === QUESTION_KIND.COVER;
                    $scope.questionForm.question.isLinkActive = !!$scope.questionForm.question.linkedQuestion;

                    QuestionnaireService.latestQuestion = angular.copy($scope.questionForm.question);
                    $scope.toggleInput.isDescriptionEnabled = !!(questionContent && questionContent.text) || $scope.isCover;
                    $scope.toggleInput.isImageGroupEnabled = (!!questionContent && (!!questionContent.youtubeURL || !!questionContent.imageURL)) || $scope.isCover;
                    $scope.toggleInput.isImageVisible = !(questionContent && questionContent.youtubeURL);
                    $scope.toggleInput.isOptionalImageVisible = !$scope.toggleInput.isImageVisible && !!questionContent &&
                        !!(questionContent.imageURL || questionContent.imageOrder > -1);
                    QuestionnaireService.toggleInput = angular.copy($scope.toggleInput);
                    $scope.testUrlLinks();
                    $scope.validateModel();
                    $scope.readingTime = GlobalService.getEstimatedReadingTime(questionResponse.data.contents[$scope.selectedLanguage].text);
                    $scope.getReadabilityIndex();            

                    scrollToHashIfPresent();
                });
            } else {
                $scope.questionForm.question.kind = QuestionnaireService.newContentKind ? QuestionnaireService.newContentKind : QUESTION_KIND.SINGLE_CHOICE;
                $scope.isCover = $scope.questionForm.question.kind === QUESTION_KIND.COVER;

                var newQuestionOrder = getQuestionOrder(response.data);
                $scope.questionForm.question.order = newQuestionOrder;

                $scope.toggleInput.isDescriptionEnabled = $scope.isCover;
                $scope.toggleInput.isImageGroupEnabled = $scope.isCover;

                if ($scope.isCover) {
                    $timeout(function() {
                        $scope.questionForm.question.contents[$scope.selectedLanguage] = { buttonLabel: $translate.instant('global.form.start') };
                    },200);
                } else {
                    for (var index = 1; index <= 2; index++) {
                        $scope.addAnswerOption(true);
                    }
                }
                $scope.questionForm.question.isLinkActive = false;
                QuestionnaireService.latestQuestion = angular.copy($scope.questionForm.question);
            }
            KendoUtilsService.disableDropOnKendoEditor('kendoTextEditor');
        });
    };

    init();

    $scope.$watch('changed', function (newValue) {
        if (newValue) {
            $scope.testUrlLinks();
            $scope.validateModel();
            $scope.changed = false;
        }
    });
        
    $scope.$on('$destroy', function() {
        $location.hash('');
        QuestionnaireService.newContentKind = null;
        QuestionnaireService.multipleAnswersTemp = {};
    });

}]);
