'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:messageTextFilter
 * @function
 * @description
 * # messageTextFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('maxLengthTextFilter', function () {
    return function (text) {
        var length = 120;

        if (text) {
            if (length < text.length) {
                text = text.substr(0, length) + '...';
            }
        }

        return text;
    };
});