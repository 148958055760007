'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.feedbackTraysService
 * @description
 * # feedbackTraysService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('FeedbackTraysService',
    ['$http', '$translate', 'LanguageService', 'KendoUtilsService', 'SharedDataService', 'PAGE_SIZE', 'QUESTIONNAIRE_KIND',
    function ($http, $translate, LanguageService, KendoUtilsService, SharedDataService, PAGE_SIZE, QUESTIONNAIRE_KIND) {

    this.URL = SharedDataService.apiUrl + '/feedback-trays';
    var self = this;

    var linkedFieldTemplate = '<div class="truncated max-width-450">' +
            '<question-label kind="dataItem.kind" order="dataItem.order" questionnaire-kind="'+QUESTIONNAIRE_KIND.TRAY+'"></question-label>' +
            ' <span class="question-label-title truncate margin-left-10" ng-bind="dataItem.title"></span>' +
        '</div>';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findAllResponsible = function () {
        return $http({
            url: this.URL + '/all-responsible',
            method: 'GET'
        });
    };

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    this.create = function (data) {
        return $http({
            url: this.URL,
            method: 'POST',
            data: data
        });
    };

    this.update = function (data) {
        return $http({
            url: this.URL + '/' + data.id,
            method: 'PUT',
            data: data
        });
    };

    this.duplicate = function (id, data) {
        return $http({
            url: this.URL + '/' + id + '/duplicate',
            method: 'POST',
            data: data
        });
    };

    this.delete = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'DELETE'
        });
    };

    this.activate = function (id) {
        return $http({
            url: this.URL + '/' + id + '/activate',
            method: 'PUT'
        });
    };

    this.archive = function (id) {
        return $http({
            url: this.URL + '/' + id + '/archive',
            method: 'PUT'
        });
    };

    this.setRecipients = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients',
            method: 'PUT',
            data: recipients
        })
    };

    this.getRecipientsCount = function (id, recipients) {
        return $http({
            url: this.URL + '/' + id + '/recipients-count',
            method: 'POST',
            data: recipients
        })
    };

    function isCurrentLanguagePublicationValid(content) {
        return typeof content !== "undefined" && !!content.nameInApp && !!content.initials;
    }

    this.validateNonEmptyLanguages = function (formData) {
        return LanguageService.validatePublicationNonEmptyLanguages(formData, isCurrentLanguagePublicationValid);
    };

    this.getTitleWithoutFormat = function(title) {

        var textParagraphs = title.split('<p>');
        textParagraphs = textParagraphs.filter(function(item) {
            return item !== '';
        })
        var plainText = textParagraphs.join(" ");
        title = plainText.replace('</p>', '');

        var element = document.createElement("div");
        element.innerHTML = title;

        // Parse unordered lists to show bullet in each element 
        var unOrderedLists = element.getElementsByTagName('ul')
        angular.forEach(unOrderedLists, function(list) {
            for (i = 0; i < list.childNodes.length; i++) {
                list.childNodes[i].innerText = ' • ' + list.childNodes[i].innerText;
            }
        })
        // Parse ordered lists to show number in each element
        var orderedLists = element.getElementsByTagName('ol')
        angular.forEach(orderedLists, function(list) {
            for (i = 0; i < list.childNodes.length; i++) {
                list.childNodes[i].innerText = ' ' + (i+1)+ '. ' + list.childNodes[i].innerText;
            }
        })
        var text = element.textContent || element.innerText || "";
        element.remove();
        return text;
    }

    function getLocalizedTitle(item, titleFieldName, currentLanguage) {
        var title;
        titleFieldName = titleFieldName || 'text';
        if (!item.contents[currentLanguage]) {
            item.language = LanguageService.getMainContentLanguage(item.contents);
        } else {
            item.language = currentLanguage;
        }
        title = item.contents[item.language][titleFieldName];
        return self.getTitleWithoutFormat(title);
    }

    this.updateQuestionnaireItemsAndLinkedStatus = function (questionnaire, currentLanguage) {
        
        var items = [];
        var linkedFields = [];

        questionnaire.fields.map(function(field, index){
            field.localizedTitle = getLocalizedTitle(field, 'text', currentLanguage);
            items[field.id] = {
                name: field.localizedTitle,
                index: index,
                questionKind: field.kind,
                questionOrder: field.order
            };
            if (field.answers) {
                var linked = field.answers.reduce(function(linked, answer){
                    answer.localizedTitle = !!answer.contents ? getLocalizedTitle(answer, 'text', currentLanguage) : answer.name;
                    if (answer.linkedField) {
                        linked.push(answer.linkedField);
                    }
                    return linked;
                }, []);
                if (linked.length > 0) {
                    linkedFields = linkedFields.concat(linked);
                }
            }
        });

        questionnaire.endings = questionnaire.endings.sort( function (a, b) { return ((a.defaultEnding === b.defaultEnding) ? 0 : a.defaultEnding ? -1 : 1) || (a.order - b.order); } );
        questionnaire.endings.map(function(ending, index){
            ending.localizedTitle = getLocalizedTitle(ending, 'text', currentLanguage);
            items[ending.id] = {
                name: ending.localizedTitle,
                index: index,
                questionKind: ending.kind,
                questionOrder: index + 1
            };
        });
        return items;
    };

    this.getLinkedQuestionsDropDownListOptions = function(questions) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.dataSource = questions;
        options.dataTextField = 'title';
        options.optionLabel = {
            title: '<span class="black45" translate="global.form.notSet"></span>',
            id: null
        };
        options.template = linkedFieldTemplate;
        options.valueTemplate = linkedFieldTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.getRelatedEntityDropDownListOptions = function(entities) {
        var options = KendoUtilsService.getDropDownListOptions();
        options.autobind = false;
        options.dataSource = entities;
        options.dataTextField = 'name';
        options.dataValueField = 'id';
        options.optionLabel = {
            name: $translate.instant('documents.import.attachFiles'),
            id: null
        };
        options.optionLabelTemplate = '<span class="black45 no-option-label" translate="documents.import.attachFiles"></span>';
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    this.createQuestionnaireInTray = function(feedbackTrayId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire',
            method: 'POST'
        });
    };

    this.findQuestionnaire = function(feedbackTrayId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire',
            method: 'GET'
        });
    };

    this.findOneQuestion = function(feedbackTrayId, questionId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields/' + questionId,
            method: 'GET'
        });
    };
    
    this.createQuestion = function (data, feedbackTrayId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields',
            method: 'POST',
            data: data.question,
        });
    };

    this.updateQuestion = function (data, feedbackTrayId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields/' + data.question.id,
            method: 'PUT',
            data: data.question
        });
    };

    this.deleteQuestion = function (feedbackTrayId, fieldId, errorMessage) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields/' + fieldId,
            method: 'DELETE',
            localizedErrorString: errorMessage
        });
    };

    this.reorderQuestion = function (feedbackTrayId, fieldId, newOrder) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields/' + fieldId + '/reorder',
            method: 'PUT',
            data: newOrder
        })
    };

    this.deleteAnswer = function (feedbackTrayId, fieldId, answerId) {
        return $http({
            url: this.URL + '/' + feedbackTrayId + '/questionnaire/fields/' + fieldId + '/answers/' + answerId,
            method: 'DELETE'
        });
    };

    this.enableQuestionnaire = function (id) {
        return $http({
            url: this.URL + '/' + id + '/questionnaire/enable',
            method: 'PUT'
        });
    };

    this.disableQuestionnaire = function (id) {
        return $http({
            url: this.URL + '/' + id + '/questionnaire/disable',
            method: 'PUT'
        });
    };

    this.getRelatedEntityItems = function(entityUrl) {
        var options = {
            page: 0,
            size: 20000,
            sort: 'name,asc',
            active: true
        }
        return $http({
            url: SharedDataService.apiUrl + entityUrl,
            method: 'GET',
            params: options
        });
    }

}]);
