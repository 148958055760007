'use strict';

angular.module('dialengaExeviBackoffice').config(['$translateProvider', 'defaultLanguage', function ($translateProvider, defaultLanguage) {
    $translateProvider.useStaticFilesLoader({
        prefix: './ng1/i18n/',
        suffix: '.json?v=' + (+new Date).toString(36)
    });
    $translateProvider.preferredLanguage(defaultLanguage);
    $translateProvider.useCookieStorage();

    // Set to null to avoid a wrong conversion of the 'ñ' character
    $translateProvider.useSanitizeValueStrategy(null);

    // App uses default translation, but we register MessageFormat interpolation to be available when needed
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
}]);
