'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MentionModalController
 * @description
 * # MentionModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('MentionModalController',
    ['$scope', '$timeout', '$uibModalInstance', 'params', 'UsersService',
    function ($scope, $timeout, $uibModalInstance, params, UsersService) {

    //******************************************* Methods ***************************************\\
    $scope.accept = function () {
        var mention = ' ' + '<mention contenteditable="false" id="' + $scope.user.id + '">' + $scope.user.name + ' ' + $scope.user.surname + '</mention>'+ ' ';
        $uibModalInstance.close(mention);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.onChange = function () {
        if ($scope.user && $scope.user.id) {
            $scope.accept(); 
        }
    };


    function init() {
        var employeeFilter = params.selection || '';
        $scope.usersOptions = UsersService.getEmployeeOptions(employeeFilter);

        $timeout(function() {
            var select = $("#select-user-dropdown").data("kendoComboBox");
            select.focus();

            if (params.selection) {
                $timeout(function() {
                    select.value(params.selection);
                    select.open();
                }, 100);
            }
        }, 100); 
    }

    init();
}]);
