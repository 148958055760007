'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:messageCategoryColorFilter
 * @function
 * @description
 * # messageCategoryColorFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('messageCategoryColorFilter',
    ['UtilsService', function (UtilsService) {
    return function (category, level) {
        var categoryColor = category.color;
        if (category.hierarchy && category.hierarchy.length > 0) {
            var brightnessPercentage = level < 4 ? level * 20 : 80;
            categoryColor = UtilsService.increaseBrightness(category.color, brightnessPercentage);
        }
        return categoryColor;
    };
}]);
