'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:FeedbackTrayUsersController
 * @description
 * # FeedbackTrayUsersController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('FeedbackTrayUsersController',
    ['$filter', '$rootScope', '$routeParams', '$scope', '$translate', 'FeedbackTraysService', 'ModalService', 'NotificationService', 'SegmentService', 'UsersGroupsService', 'POPULATION', 'SEGMENTATION_KIND',
    function($filter, $rootScope, $routeParams, $scope, $translate, FeedbackTraysService, ModalService, NotificationService, SegmentService, UsersGroupsService, POPULATION, SEGMENTATION_KIND) {
        var vm = this;

        vm.recipients = {};
        vm.POPULATION = POPULATION;
        vm.segmentationTypes = SEGMENTATION_KIND;
        vm.intersectionCount = null;
        vm.unionCount = null;
        vm.selectedSegments = [];
        vm.addedSegments = [];
        vm.filter = {
            segments: '',
            addedSegments: ''
        };
        vm.areAllAvailableSegmentsAdded = true;

        var feedbackTrayId = $routeParams.id;
        var areRecipientsInitialized = true;

        vm.$onInit = function () {
            FeedbackTraysService.findOne(feedbackTrayId).then(function(response) {
                initializeData(response.data);
            });

            vm.getFilteredSegments();

            if ($scope.$parent.usersGroupsScope) {
                // Discard changes when leaving tab
                $scope.$parent.usersGroupsScope.discardChanges = function () {
                    vm.recipients = angular.copy(FeedbackTraysService.initialRecipients);
                    $scope.$parent.usersGroupsScope.isFormDirty = false;
                };
            }
        };

        function initializeData(data) {
            vm.feedbackTray = data;

            Array.prototype.push.apply(vm.addedSegments, data.segments);
            vm.filteredAddedSegments = angular.copy(vm.addedSegments);

            if (!data.population) {
                vm.selectedPopulationKind = POPULATION.ALL;
                // Set recipients data as empty for the first time to allow saving the default state
                areRecipientsInitialized = false;
            } else if (data.population === POPULATION.SEGMENTS && data.segments.length === 0) {
                vm.selectedPopulationKind = POPULATION.NO_GROUP;
            } else {
                vm.selectedPopulationKind = data.population;
            }

            vm.recipients = UsersGroupsService.getRecipients(data);

            getRecipientsCount();
        }

        vm.getFilteredSegments = function(text) {
            vm.filter.segments = text || '';
            vm.isSyncing = true;
            var options = {
                name: vm.filter.segments,
                active: true
            };
            SegmentService.findAll(options).then(function (response) {
                vm.isSyncing = false;
                if (options.name === vm.filter.segments) {
                    vm.segments = response.data.content;
                    vm.areAllAvailableSegmentsAdded = UsersGroupsService.isAddAllSegmentsLinkEnabled(vm.segments, vm.recipients.segments);
                }
            });
        };

        function setInitialRecipientsData() {
            if (!FeedbackTraysService.initialRecipients) {
                FeedbackTraysService.initialRecipients = angular.copy(vm.recipients);
            }
        }

        function formatRecipients() {
            if (vm.selectedPopulationKind === POPULATION.ALL) {
                vm.recipients = UsersGroupsService.getInitialRecipientsAllKind();
            } else if (vm.selectedPopulationKind === POPULATION.SEGMENTS) {
                vm.recipients = UsersGroupsService.getInitialRecipientsSegmentsKind(vm.addedSegments, vm.recipients.segmentationKind);
                vm.areAllAvailableSegmentsAdded = UsersGroupsService.isAddAllSegmentsLinkEnabled(vm.segments, vm.recipients.segments);
            } else if (vm.selectedPopulationKind === POPULATION.NO_GROUP) {
                vm.recipients = UsersGroupsService.getInitialRecipientsSegmentsKind([], vm.recipients.segmentationKind);
            }
            setInitialRecipientsData();
        }

        function clearSegmentationKindCount() {
            vm.intersectionCount = null;
            vm.unionCount = null;
        }

        function getRecipientsCount() {
            formatRecipients();
            clearSegmentationKindCount();
    
            vm.isUserCountLoading = true;
            FeedbackTraysService.getRecipientsCount(feedbackTrayId, vm.recipients).then(function (response) {
                vm.unionCount = response.data.unionCount;
                vm.intersectionCount = (vm.addedSegments.length >= 2) ? response.data.intersectionCount : null;
                vm.isUserCountLoading = false;
            });
        }

        vm.setPopulationKind = function (population) {
            vm.selectedPopulationKind = population;
    
            getRecipientsCount();
        };

        vm.selectSegmentFromAvailables = function (segment, $event) {
            vm.selectedSegments = UsersGroupsService.addAvailableSegmentToSelected(vm.selectedSegments, segment, $event);
        };
    
        vm.doubleClickedSegment = function (segment) {
            vm.addedSegments.push(segment);
            searchAddedSegments();
            getRecipientsCount();
        };

        vm.addSelectedSegments = function () {
            if (document.querySelectorAll('#available-segments-box .selected').length > 0) {
                Array.prototype.push.apply(vm.addedSegments, vm.selectedSegments);
                vm.selectedSegments = [];
                searchAddedSegments();
                getRecipientsCount();
                angular.element(document.querySelectorAll("#available-segments-box .selected")).addClass("added").removeClass("selected");
            }
        };
    
        vm.removeAddedItem = function (segment) {
            angular.element(document.querySelectorAll('*[data-id="' + segment.id + '"]')).removeClass("added");
    
            var segmentIndex = UsersGroupsService.getIndexOfItemInSegments(vm.addedSegments, segment.id);
            if (segmentIndex !== -1) {
                vm.addedSegments.splice(segmentIndex, 1);
                getRecipientsCount();
            }
            searchAddedSegments();
            if(vm.addedSegments.length <= 1){
                vm.recipients.segmentationKind = vm.segmentationTypes.UNION;
            }
        };

        vm.removeAllAddedSegments = function(){
            vm.addedSegments = [];
            vm.filter.addedSegments = '';
            searchAddedSegments();
            getRecipientsCount();
    
            angular.element(document.querySelectorAll("#available-segments-box .added")).removeClass("added");
            vm.recipients.segmentationKind = vm.segmentationTypes.UNION;
        };
    
        vm.addAllSegments = function(){
            vm.addedSegments = angular.copy(vm.segments);
            searchAddedSegments();
            getRecipientsCount();
    
            angular.element(document.querySelectorAll("#available-segments-box .available-segment")).addClass("added").removeClass("selected");
        };

        function searchAddedSegments() {
            vm.filteredAddedSegments = $filter('filter')(vm.addedSegments, vm.filter.addedSegments);
        }
    
        vm.getFilteredAddedSegments = function(text) {
            vm.filter.addedSegments = text;
            searchAddedSegments();
        };

        function setRecipients() {
            formatRecipients();
    
            FeedbackTraysService.setRecipients(feedbackTrayId, vm.recipients).then(function () {
                FeedbackTraysService.initialRecipients = angular.copy(vm.recipients);
                $scope.$parent.usersGroupsScope.isFormDirty = false;
                var message = $translate.instant('global.messages.changesUpdated.success');
                NotificationService.notifySuccess(message);
                $rootScope.$broadcast('dialenga:loadingEnd');
            }, function(error) {
                $rootScope.$broadcast('dialenga:loadingEnd');
                var message = $translate.instant('global.messages.error.unknown');
                NotificationService.notifyError(message);
            });
        }

        vm.submitUsersGroups = function () {
            if (vm.isFormValid()) {
                if (!vm.selectedPopulationKind === POPULATION.ALL &&
                    UsersGroupsService.areNoRecipients(vm.recipients.segmentationKind, vm.unionCount, vm.intersectionCount)) {
                    ModalService.openConfirmationModal({
                        title: 'feedbackTray.modal.noEstimatedUsers.title',
                        message: 'feedbackTray.modal.noEstimatedUsers.message',
                        messageData: {},
                        focusCancel: true,
                        hideCancelButton: false,
                        accept: function () {
                            setRecipients();
                        }
                    });
                } else {
                    setRecipients();
                }
            }
        };

        function dataHasChanged() {
            return !angular.equals(vm.recipients, FeedbackTraysService.initialRecipients);
        }
        
        vm.goBack = function() {
            var isFormDirty = dataHasChanged();
            $rootScope.goBackAndCheckForModifications(isFormDirty, '/settings/conversations/feedback-trays/');
        };

        vm.isFormValid = function () {
            var isFormDirty = dataHasChanged();
            return !areRecipientsInitialized || isFormDirty;
        };

        $scope.$watch('vm.recipients', function () {
            if ($scope.$parent.usersGroupsScope) {
                $scope.$parent.usersGroupsScope.isFormDirty = dataHasChanged();
            }
        }, true);
    }
]);