'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:contentKindIconFilter
 * @function
 * @description
 * # contentKindIconFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice')
    .filter('contentKindIconFilter', ['CONTENT_KIND', function (CONTENT_KIND) {
        return function (kind) {
            var icon;
            switch(parseInt(kind)){
                case CONTENT_KIND.MESSAGE:
                    icon = 'icon icon-menu-messages';
                    break;
                case CONTENT_KIND.DIALOGUE:
                case CONTENT_KIND.SURVEY:
                    icon = 'icon icon-menu-dialogs';
                    break;
                case CONTENT_KIND.KNOWLEDGE_PILL:
                    icon = 'icon icon-menu-knowledge';
                    break;
                default:
                    break;
            }
            return icon;
        };
    }])
    .filter('contentKindTooltipFilter', ['$translate', 'CONTENT_KIND', function ($translate, CONTENT_KIND) {
        return function (kind) {
            var tooltip;
            switch(parseInt(kind)){
                case CONTENT_KIND.MESSAGE:
                    tooltip = $translate.instant('message.title');
                    break;
                case CONTENT_KIND.DIALOGUE:
                case CONTENT_KIND.SURVEY:
                    tooltip = $translate.instant('model.questionnaire.title');
                    break;
                case CONTENT_KIND.KNOWLEDGE_PILL:
                    tooltip = $translate.instant('knowledgecenter.title');
                    break;
                default:
                    break;
            }
            return tooltip;
        };
    }]);
