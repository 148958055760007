'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:WKUsageConditionsModalController
 * @description
 * # WKUsageConditionsModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('WKUsageConditionsModalController',
    ['$scope', '$uibModalInstance',
    function ($scope, $uibModalInstance) {

    $scope.modalForm = { termsAndConditionsAccepted: false };

    $scope.accept = function () {
        if ($scope.modalForm.termsAndConditionsAccepted) {
            $uibModalInstance.close();
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

}]);
