(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:experienceDetail
     * @description
     * # experienceDetail
     */
    angular.module('dialengaExeviBackoffice').component('experienceDetail', {
        bindings: {
            experience: '<',
            isVisible: '<',
            closeView: '&'
        },
        controller: function () {
            var self = this;

            self.isImage = function (mimetype) {
                return mimetype ? mimetype.startsWith('image/') : false;
            };
        },
        controllerAs: 'self',
        templateUrl: '/ng1/components/experience/experience-detail.html'
    });
})();