(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dialengaExeviBackoffice.directive:navigateTo
   * @description
   * # navigateTo
   */
  angular.module('dialengaExeviBackoffice').directive('navigateTo',
    ['$location', 'GlobalService', function ($location, GlobalService) {
    return function (scope, element, attrs) {
      var path;

      attrs.$observe('navigateTo', function (val) {
        path = val;
      });

      element.bind('click', function () {
        scope.$apply(function () {

          if (attrs.isExternalLink != undefined || path == '/login') {
            if( path == '/login') { path = '/' };
            window.location = path;
          } else {
            if (path === '/') {
              GlobalService.redirectUserToHomeBasedOnRoles();
            } else {
              $location.path(path);
            }
          }

        });
      });
    };
  }]);

})();

