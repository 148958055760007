'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:imageDragAndDrop
 * @description
 * # imageDragAndDrop
 */
angular.module('dialengaExeviBackoffice').directive('imageDragAndDrop', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            images: '=',
            formContent: '=',
            language: '=?',
            required: '=',
            croppedImageSrc: '=',
            cropOptions: '=',
            modalTexts: '=?',
            onChangeFunction: '&',
            gifsAllowed: '=?',
            picsAllowed: '=?',
            uploadAllowed: '=?',
            insertInEditor: '=?'
        },
        templateUrl: '/ng1/components/shared/image-drag-and-drop/image-drag-and-drop.html',
        controller: ['$scope', '$translate', 'AttachedContentService', 'FileUtilsService', 'ModalService', 'NotificationService', 'DialogService',
            function ($scope, $translate, AttachedContentService, FileUtilsService, ModalService, NotificationService, DialogService) {

            var unbind = $scope.$watch('formContent', function (newFormValue) {
                if (newFormValue && (!$scope.language || newFormValue[$scope.language])) {
                    var formContent = !$scope.language ? newFormValue : newFormValue[$scope.language];
                    AttachedContentService.setImage(formContent, $scope.images);
                    if (formContent.imageURL) {
                        unbind();
                    }
                }
            });

            function processCroppedImage(croppedImage, selectedFile) {
                $scope.croppedImageSrc = croppedImage;

                var multiPartImage = FileUtilsService.dataURItoBlob(croppedImage);
                $scope.images.push({ name: selectedFile.name, image: multiPartImage });

                var formContent;
                if ($scope.language) {
                    if (typeof ($scope.formContent[$scope.language]) == "undefined") {
                        $scope.formContent[$scope.language] = {};
                    }
                    formContent = $scope.formContent[$scope.language];
                } else {
                    formContent = $scope.formContent;
                }

                if (formContent.imageOrder !== "undefined" && formContent.imageOrder > -1) {
                    // If an image was already attached, remove it before adding the new one
                    var removedImageIndex = formContent.imageOrder;
                    $scope.images.splice(removedImageIndex, 1);

                    if ($scope.language) {
                        AttachedContentService.reorderItemsAfterRemove(formContent, 'imageOrder', removedImageIndex);
                    }
                }

                formContent.imageOrder = $scope.images.length - 1;
                formContent.imageName = null;
                formContent.imageURL = null;
                formContent.imageWeight = multiPartImage.size;

                $scope.onChangeFunction();
            }

            $scope.itemHasImage = function () {
                if ($scope.language) {
                    return $scope.formContent[$scope.language] && ($scope.formContent[$scope.language].imageURL || $scope.formContent[$scope.language].imageOrder > -1);
                } else {
                    return $scope.formContent && ($scope.formContent.imageURL || $scope.formContent.imageOrder > -1);
                }
            };

            $scope.onImageSelected = function ($files) {
                document.getElementById('image-file-upload').value = '';
                if ($files.length > 0) {
                    var selectedFile = $files[0];

                    AttachedContentService.selectImage(selectedFile).then(function (response) {
                        if (response.size.width < $scope.cropOptions.MINIMUM_WIDTH || response.size.height < $scope.cropOptions.MINIMUM_HEIGHT) {
                            var message = $translate.instant('message.imageSizeError', {width: $scope.cropOptions.MINIMUM_WIDTH, height: $scope.cropOptions.MINIMUM_HEIGHT});
                            NotificationService.notifyError(message);
                        } else {
                            if ( selectedFile.type == 'image/gif' && $scope.gifsAllowed ) {
                                processCroppedImage(response.image, selectedFile);
                            } else {
                                ModalService.openImageEditionModal({
                                    image: response.image,
                                    mimeType: response.mimeType,
                                    cropOptions: $scope.cropOptions,
                                    modalTexts: $scope.modalTexts,
                                    showModalSubtitle: true,
                                    accept: function (croppedImage) {
                                        unbind();
                                        processCroppedImage(croppedImage, selectedFile);
                                    }
                                });
                            } 
                        }
                    }, function () {
                        var message = $translate.instant('message.imageFormatError');
                        NotificationService.notifyError(message);
                    });
                }
                document.getElementById('image-drag-drop').classList.remove('dragging');
            };

            $scope.onImageSearch = function() {
                DialogService.openImageBank($scope.cropOptions, false, $scope.gifsAllowed, $scope.picsAllowed, $scope.uploadAllowed, $scope.insertInEditor).subscribe(function(response) {
                    if(response && response.accepted) {
                        processCroppedImage(response.data.imageSrc, {name: response.data.filename});
                    }
                });
            };

            $scope.deleteImage = function () {
                if ($scope.language) {
                    AttachedContentService.deleteLocalizedImage('image-file-upload', $scope.images, $scope.formContent, $scope.language);
                } else {
                    AttachedContentService.deleteSingleImage('image-file-upload', $scope.images, $scope.formContent);
                }
                $scope.croppedImageSrc = null;
                $scope.onChangeFunction();
            };

            $scope.$watch('language', function (newLanguageValue) {
                if (newLanguageValue && $scope.formContent[newLanguageValue]) {
                    AttachedContentService.setImage($scope.formContent[newLanguageValue], $scope.images);
                }
            });
        }]
    };
});
