'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:Saml2SuccessController
 * @description
 * # Saml2SuccessController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('Saml2SuccessController',
    ['$rootScope', '$routeParams', 'authService', 'AuthenticationService', 'GlobalService', 'SharedDataService', 'Session',
    function ($rootScope, $routeParams, authService, AuthenticationService, GlobalService, SharedDataService, Session) {

    var vm = this;

    function cancelLogin() {
        $rootScope.externalLoginFailed = false;
        authService.loginCancelled();
    }

    var authCode = $routeParams.access_token;
    if (authCode) {
        localStorage.setItem('accessToken', authCode);
        AuthenticationService.authenticateUser(true).then(function (isAuthenticated) {
            if (!isAuthenticated) {
                cancelLogin();
            } else {
                SharedDataService.checkForNewFeaturesAdvice(Session.language);
                GlobalService.redirectUserToHomeBasedOnRoles();
            }
        }, function (error) {
            console.log(error);
            cancelLogin();
        });
    } else {
        cancelLogin();
    }

}]);