'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:TimeoffExportCsvModalController
 * @description
 * # TimeoffExportCsvModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('TimeoffExportCsvModalController',
    ['$scope', '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        var vm = this;

        var currentYear = new Date().getFullYear();
        var dateFormat = 'dd/MM/yyyy';
        vm.dateFormatPlaceholder = 'dd/mm/yyyy';
        vm.isValidForm = true;

        vm.downloadOptions = {
            requestsReport: false,
            balanceReport: false,
            startDate: kendo.toString(new Date(currentYear,0,1), dateFormat),
            endDate: kendo.toString(new Date(currentYear,11,31), dateFormat),
            previewKendoStartDate: new Date(currentYear,0,1),
            previewKendoEndDate: new Date(currentYear,11,31)
        };
        var lastStartDate = vm.downloadOptions.previewKendoStartDate;
        var lastEndDate = vm.downloadOptions.previewKendoEndDate;
        
        $scope.$on("kendoWidgetCreated", function (event, widget) {
            if (widget.options.name === 'DatePicker') {
                widget._dateInput.setOptions({
                    messages: {
                        year: 'yyyy',
                        month: 'mm',
                        day: 'dd'
                    }
                });
            } 
        });
        
        function setDatePickerOptions(publicationDate) {
            var options = {
                dateInput: true,
                format: dateFormat,
                weekNumber: true
            };
            vm.datePickerOptions = options;
        }

        vm.accept = function () {
            $uibModalInstance.close(vm.downloadOptions);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.dateInputFocusOut = function () {
            vm.onDatesChanges();
        };

        vm.inputFocusIn = function () {
            vm.isValidForm = false; 
        }

        vm.onDatesChanges = function(date) {
            vm.isValidForm = true;
            
            if (!vm.downloadOptions.previewKendoStartDate) {
                vm.downloadOptions.previewKendoStartDate = lastStartDate;
            }
            if (!vm.downloadOptions.previewKendoEndDate) {
                vm.downloadOptions.previewKendoEndDate = lastEndDate;
            }
            if (vm.downloadOptions.previewKendoEndDate < vm.downloadOptions.previewKendoStartDate) {
                vm.downloadOptions.previewKendoStartDate = lastStartDate;
                vm.downloadOptions.previewKendoEndDate = lastEndDate;
            }
            lastStartDate = vm.downloadOptions.previewKendoStartDate;
            lastEndDate = vm.downloadOptions.previewKendoEndDate;
        };

        var init = function() {
            setDatePickerOptions();
        };

        init();
}]);
