'use strict';

angular.module('dialengaExeviBackoffice').service('FileUtilsService', ['$q', function ($q) {

    this.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    };

    this.removeExtension = function (fileName) {
        return fileName.replace(/\.[^/.]+$/, "");
    };

    this.getBase64ImageDimensions = function (fileData) {
        var deferred = $q.defer();

        var image = new Image();

        image.onload = function () {
            deferred.resolve({
                height: this.height,
                width: this.width
            });
        };

        image.src = fileData;

        return deferred.promise;
    };

    this.getFileFromUrl = function(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (this.status === 200) {
                var fr = new FileReader();
                fr.onload = function(){
                    callback(this.result);
                };
                fr.readAsDataURL(this.response);
            } else {
                //TODO: show error message
            }
        };

        xhr.onerror = function () {
            //TODO: show error message
        };

        xhr.send();
    }

}]);