'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:inputNumberMaxLength
 * @description
 * # inputNumberMaxLength
 */
angular.module('dialengaExeviBackoffice').directive('inputNumberMaxLength', ['$filter', function ($filter) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('input', function () {
                // REGEXP: matches INTEGER or INTEGER + ('.' || ',') + DECIMAL
                // var inputRegexp = /(^[0-9]+)([\.|,]?)?([0-9]*)?$/g;
                var inputRegexp = /(^\d+)([\.,]?)?(\d*)?$/g;  
                var match = inputRegexp.exec(this.value);
                var inputValue;
                if (match && typeof match[1] !== 'undefined' && match[1] !== null) {
                    var integerMaxLength = this.maxLength > 0 ? this.maxLength : (attrs.integerMaxLength > 0 ? attrs.integerMaxLength : 2); // Not setting maxlength returns -1 value
                    inputValue = match[1].toString().slice(0, integerMaxLength);
                    if (typeof match[2] !== 'undefined' && match[2] !== null) {
                        inputValue += match[2];
                    }
                    if (typeof match[3] !== 'undefined' && match[3] !== null) {
                        var decimalMaxLength = attrs.decimalMaxLength || 1;
                        inputValue += match[3].toString().slice(0, decimalMaxLength);
                    }
                    this.value = inputValue;
                }
            });
        }
    };
}]);