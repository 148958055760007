(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewGallery
     * @description
     * # devicePreviewGallery
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewGallery', {
        bindings: {
            publication: '<',
            language: '<'
        },
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-gallery.html',
        controllerAs: 'vm',
        controller: ['Session', function (Session) {
            var vm = this;
            
            vm.Session = Session;
        }]
    });
})();