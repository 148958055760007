'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:decimalLocalizedFilter
 * @function
 * @description
 * # decimalLocalizedFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('decimalLocalizedFilter', ['$filter', function ($filter) {

    function countDecimals(value) {
        if (Math.floor(value) === value) {
            return 0;
        }
        return value.toString().split('.')[1].length || 0;
    }

    return function (number, maxNumDecimals) {
        if (!number) {
            return 0;
        }
        maxNumDecimals = maxNumDecimals || 2;
        var numDecimals = countDecimals(number);
        return $filter('number')(number, Math.min(numDecimals, maxNumDecimals));
    };
}]);