(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:devicePreviewKcenterTimeline
     * @description
     * # devicePreviewTimeline
     */
    angular.module('dialengaExeviBackoffice').component('devicePreviewKcenterTimeline', {
        bindings: {
            publication: '<',
            language: '<',
            image: '<'
        },
        templateUrl: '/ng1/components/shared/publication-preview/device-preview-kcenter-timeline.html',
        controllerAs: 'vm',
        controller: ['videoService', 'Session', function (videoService, Session) {
            var vm = this;

            vm.getVideoThumbnail = videoService.getThumbnail;
            vm.Session = Session;

            vm.getCategoryLocalizedName = function () {
                if (!vm.publication || !vm.publication.category) {
                    return;
                }
                return (!!vm.publication.category.contents && !!vm.publication.category.contents[vm.language]) ? vm.publication.category.contents[vm.language].name : vm.publication.category.name;
            };
        }]
    });
})();