'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:UserController
 * @description
 * # UserController
 * Controller of the dialengaExeviBackoffice for user Creation / Edition
 */
angular.module('dialengaExeviBackoffice').controller('UserController',
    ['$location', '$rootScope', '$scope', '$route', '$routeParams', 'SharedDataService', 'AuthenticationService', 'ModalService', 'UsersService', 'Session',
    'ConversationsService', 'MODULES', 'USER_ROLES', 'USER_STATES', 'USER_TABS', 'selectedTab',
    function($location, $rootScope, $scope, $route, $routeParams, SharedDataService, AuthenticationService, ModalService, UsersService, Session,
        ConversationsService, MODULES, USER_ROLES, USER_STATES, USER_TABS, selectedTab) {

        var vm = this;

        vm.userId = $routeParams.id;
        vm.isCompanyEntryDateToday = false;
        vm.isBirthdateToday = false;
        var currentDay = new Date().getDate();
        var currentMonth = new Date().getMonth();
        vm.USER_TABS = USER_TABS;
        vm.USER_STATES = USER_STATES;
        vm.selectedTab = selectedTab;
        vm.userState = {};

        vm.userLoggedHasDocumentsManagerRole = AuthenticationService.userLoggedHasRole(USER_ROLES.documentsManager);
        vm.userLoggedHasHumanResourcesRole = AuthenticationService.userLoggedHasRole(USER_ROLES.humanResources);
        vm.userLoggedHasAdminRole = AuthenticationService.userLoggedHasRole(USER_ROLES.admin);
        vm.companyBrand = Session.externalLoginBrand;

        vm.deleteUser = function() {
            var modal = {};
            var promise;
            var categoryKind = 5;
            var isUserDeleted = parseInt(vm.user.state) === USER_STATES.DISABLED;
            if (isUserDeleted) {
                modal.title = 'global.modal.reactivate.title';
                modal.message = 'global.modal.reactivate.messageuser';
                modal.buttonText = 'users.reactivate';
                promise = UsersService.activate;
            } else {
                modal.title = 'publication.modal.archive.title';
                modal.message = 'users.modal.archive.message';
                modal.buttonText = 'global.form.archive';
                promise = UsersService.deactivate;
            }
            ModalService.openConfirmationModal({
                title: modal.title,
                message: modal.message,
                messageData: {
                    contentType: categoryKind,
                    user: vm.user.email
                },
                acceptButtonText: modal.buttonText,
                isTextInterpolated: !isUserDeleted,
                accept: function() {
                    promise.call(UsersService, vm.user.id).then(function() {
                        $location.path('/users');
                    });
                },
                cancel: function() {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        };

        vm.downloadUserHolidays = function() {
            $rootScope.$broadcast('dialenga:loadingEnd');
            ModalService.openModal({
                templateUrl: '/ng1/components/user/user-holidays-export-modal.html',
                controller: 'UserHolidaysExportModalController',
                size: 'md',
                resolveParams: {
                    id: $routeParams.id
                },
                accept: function(period) {
                    if (period) {
                        var urlRequests = SharedDataService.apiUrl + '/holidays/employees/' + vm.userId + '/periods/' + period + '/export';
                        var downloadRequestReport = angular.element('<a id="request-report" href="'+ urlRequests + '" target="_self" is-external-link></a>');
                        downloadRequestReport[0].click();
                        angular.element(document.querySelector('#request-report')).remove();
                    }
                }
            });
        }

        vm.newConversationWithUser = function() {
            var userData = {
                user: vm.user.id,
                username: vm.user.username,
                name: vm.user.name,
                surname: vm.user.surname,
                fullName: vm.user.name + ' ' + vm.user.surname,
                state: vm.user.state,
                avatarUrl: vm.user.avatarUrl,
                avatarThumbnailSUrl: vm.user.avatarThumbnailSUrl,
                avatarThumbnailMUrl: vm.user.avatarThumbnailMUrl,
                id: vm.user.id
            };
            sessionStorage.setItem('navigateFromUserProfileToRequests', JSON.stringify(userData));
            $location.path('requests');
        };

        vm.loadTabContent = function(tab) {
            if (vm.selectedTab !== tab && (tab === USER_TABS.PROFILE || !!vm.userId)) {
                vm.selectedTab = tab;
                if (tab === USER_TABS.PROFILE) {
                    $route.updateParams({ tabName: 'personaldata' });
                } else if (tab === USER_TABS.COMPANY) {
                    $route.updateParams({ tabName: 'companydata'});
                } else if (tab === USER_TABS.HOLIDAYS) {
                    $route.updateParams({ tabName: 'holidays' });
                } else if (tab === USER_TABS.DOCUMENTS) {
                    $route.updateParams({ tabName: 'documents' });
                } else if (tab === USER_TABS.ACCESS) {
                    $route.updateParams({ tabName: 'access' });
                } else if (tab === USER_TABS.NOTES) {
                    $route.updateParams({ tabName: 'notes' });
                }
            }
        };

        vm.goBack = function() {
            $location.path('/users');
        }

        vm.userNotesHaveContent = function() {
            if (!vm.user || !vm.user.notes) {
                return false;
            }
            var textNotes = vm.user.notes.replace(/<(.|\n)*?>/g, '');
            return !!textNotes;
        }

        function checkCompanyEntryDate() {
            vm.isCompanyEntryDateToday = (new Date(vm.user.companyEntryDate).getDate() == currentDay) && (new Date(vm.user.companyEntryDate).getMonth() == currentMonth);
        }

        function checkBirthdate() {
            vm.isBirthdateToday = (new Date(vm.user.privateInfo.birthDate).getDate() == currentDay) && (new Date(vm.user.privateInfo.birthDate).getMonth() == currentMonth);
        }

        $scope.$watch('vm.user.companyEntryDate', function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                checkCompanyEntryDate();
            }
        });

        $scope.$watch('vm.user.privateInfo.birthDate', function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                checkBirthdate();
            }
        });

        function init() {
            vm.isHolidaysModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.HOLIDAYS);
            vm.isDocumentsModuleActive = AuthenticationService.checkIfModuleIsActiveForUserCompany(MODULES.DOCUMENTS);
            if (vm.userId) {
                UsersService.findOne(vm.userId).then(function(response) {
                    vm.user = response.data;
                    vm.userState= UsersService.getUserIcon(vm.user.state, vm.user.lastAccessDate);
                });
            } else {
                vm.user = {
                    roles: []
                };
            }
        }

        init();
    }
]);