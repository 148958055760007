'use strict';

/**
 * @ngdoc directive
 * @name dialengaExeviBackoffice.directive:backButton
 * @description
 * # backButton
 */
angular.module('dialengaExeviBackoffice').directive('backButton', ['$window', function ($window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('click', function() {
                $window.history.back();
            });
        }
    };
}]);
