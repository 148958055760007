'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.attachedContentService
 * @description
 * # attachedContentService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('AttachedContentService',
    ['$q', 'FileUtilsService',
    function ($q, FileUtilsService) {

    var acceptedImageTypes = [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/jpg",
        "image/png"
    ];

    function deleteInputFile(inputId) {
        // Clear input value, to allow selecting the same file after removing it.
        var input = document.getElementById(inputId);
        try {
            input.value = null;
        } catch (ex) { }
        if (input && input.value) {
            input.parentNode.replaceChild(input.cloneNode(true), input);
        }
    }

    function deleteImage(images, formData) {
        if (formData.imageOrder !== -1) {
            images.splice(formData.imageOrder, 1);
            formData.imageOrder = -1;
        }
        formData.imageURL = null;
        formData.imageName = null;
        formData.imageExtension = null;
        formData.imageWeight = 0;
    }

    function checkIfResourceHasOneLanguage(resource, form, model) {
        var languagesForResource = 0;
        var languages = Object.keys(form[model].contents);

        angular.forEach(languages, function(language) {
            var resourceIndex = form[model].contents[language].resources.findIndex(function(item) {
                return item.originalName === resource.originalName;
            });
            languagesForResource = resourceIndex > -1 ? languagesForResource + 1 : languagesForResource;
        })

        return languagesForResource === 1;
    }

    this.selectImage = function (file) {
        var deferred = $q.defer();
        if (acceptedImageTypes.indexOf(file.type) > -1) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                FileUtilsService.getBase64ImageDimensions(imageData).then(function(size) {
                    deferred.resolve({
                        image: imageData,
                        size: size,
                        mimeType: imageData.split(',')[0].split(':')[1].split(';')[0]
                    });
                });
            };
            reader.readAsDataURL(file);
        } else {
            deferred.reject();
        }
        return deferred.promise;
    };

    this.deleteSingleImage = function (inputId, images, formData) {
        deleteInputFile(inputId);
        deleteImage(images, formData);
    };

    this.deleteLocalizedImage = function (inputId, images, formData, language) {
        deleteInputFile(inputId);
        deleteImage(images, formData[language]);

        var removedImageIndex = formData[language].imageOrder;
        if (removedImageIndex !== -1 && removedImageIndex < images.length - 1) {
            this.reorderItemsAfterRemove(formData, 'imageOrder', removedImageIndex);
        }
    };

    this.setImage = function (form, images) {
        if (form.imageURL) {

            document.getElementById("image").src = form.imageURL;

        } else if (form.imageOrder > -1) {

            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                document.getElementById("image").src = e.target.result;
            };

            var file = images[form.imageOrder];
            if (file && file.image) {
                reader.readAsDataURL(file.image);
            }

        }
    };

    this.selectResource = function (file, form, model, language) {
        form.resources.push(file);

        if (typeof (form[model].contents[language]) == "undefined") {
            form[model].contents[language] = {};
        }

        form[model].contents[language].resourceOrder = form.resources.length - 1;
        form[model].contents[language].resourceURL = null;
        form[model].contents[language].resourceWeight = file.size;
    };

    this.selectMultipleResources = function (files, form, model, language) {

        if (typeof (form[model].contents[language]) == "undefined") {
            form[model].contents[language] = {
                resources: []
            };
        } else if (typeof (form[model].contents[language].resources) == "undefined") {
            form[model].contents[language].resources = [];
        }

        for (let index = 0; index < files.length; index++) {
            const resource = {
                extension: null,
                name: files[index].name.split('.').slice(0, -1).join('.'),
                originalName: files[index].name,
                order: 0,
                mimetype: files[index].type,
                url: null,
                weight: files[index].size
            }
            form[model].contents[language].resources.push(resource);

            // Add resource file to array for multipart if it does not already exist
            var resourceInMultipartIndex = form.resources.findIndex(function(item) {
                return item.name === files[index].name && item.size === files[index].size;
            })

            if (resourceInMultipartIndex === -1) { form.resources.push(files[index]); }

        }
    };

    this.reorderItemsAfterRemove = function (formData, itemOrderFieldName, removedItemIndex) {
        var keys = Object.keys(formData);
        for (var index = 0; index < keys.length; index++) {
            var language = keys[index];
            if (formData[language][itemOrderFieldName] > -1
                && formData[language][itemOrderFieldName] > removedItemIndex) {
                formData[language][itemOrderFieldName] -= 1;
            }
        }
    };

    this.deleteResource = function (inputId, form, model, language) {
        deleteInputFile(inputId);

        var removedResourceIndex = form[model].contents[language].resourceOrder;
        var shouldReorderResources = removedResourceIndex > -1 ? removedResourceIndex < form.resources.length - 1 : false;

        if (removedResourceIndex > -1) {
            form.resources.splice(removedResourceIndex, 1);
        }
       
        form[model].contents[language].resourceOrder = -1;
        form[model].contents[language].resourceURL = null;
        form[model].contents[language].resourceName = null;
        form[model].contents[language].resourceExtension = null;
        form[model].contents[language].resourceWeight = 0;

        if (shouldReorderResources) {
            this.reorderItemsAfterRemove(form[model].contents, 'resourceOrder', removedResourceIndex);
        }
    };

    this.removeAllResources = function (form, model) {
        var languages = Object.keys(form[model].contents);
        for (var idx = 0; idx < languages.length; idx++) {
            form[model].contents[languages[idx]].resources = [];
        };
        form['resources'] = [];
    };

    this.deleteItemFromResources = function (resource, form, model, language, index) {
        var resourceHasOneLanguage = checkIfResourceHasOneLanguage(resource, form, model);

        // Remove resource from current language content
        form[model].contents[language].resources.splice(index, 1);
        // If resource has empty url (creation), remove resource from multipart array if only one language has this resource
        if (!resource.url && resourceHasOneLanguage) {
            var resourcesMultipartIndex = form.resources.findIndex(function(item) {
                return item.name === resource.originalName;
            });

            if (resourcesMultipartIndex > -1) { form.resources.splice(resourcesMultipartIndex, 1); }
        }
    };

    this.deleteInputFile = function (inputId) {
        deleteInputFile(inputId);
    };

}]);
