(function() {
    'use strict';

    /**
     * @ngdoc function
     * @name dialengaExeviBackoffice.controller:PublicationSummaryController
     * @description
     * # PublicationSummaryController
     * Controller of the dialengaExeviBackoffice
     */
    angular.module('dialengaExeviBackoffice').controller('PublicationSummaryController',
        ['$location', '$routeParams', '$translate', '$window', 'Session', 'CONTENT_KIND', 'DEVICE_PREVIEW_STATES', 'DataService', 'breadcrumbParentInfo',
        function ($location, $routeParams, $translate, $window, Session, CONTENT_KIND, DEVICE_PREVIEW_STATES, DataService, breadcrumbParentInfo) {
            var vm = this;
            vm.selectedLanguage = Session.companyDefaultLanguage;

            vm.smallScreen = false;
            vm.breadcrumbParentInfo = breadcrumbParentInfo;
            vm.CONTENT_KIND = CONTENT_KIND;
            vm.currentPreviewState = DEVICE_PREVIEW_STATES.PREVIEW;
            vm.DEVICE_PREVIEW_STATES = DEVICE_PREVIEW_STATES;

            function setPublicationLanguagesInSummary(languages) {
                var addedLanguages = [];
                angular.forEach(languages, function (language) {
                    addedLanguages.push($translate.instant('global.language.' + language));
                });
                vm.publicationLanguages = addedLanguages.sort().join(', ');
            }

            function initializeContentLanguages(content) {
                vm.languages = Object.keys(content);
                if (vm.languages.length === 1 && vm.languages[0] === Session.companyDefaultLanguage) {
                    // Hide language selector if only company's default language is available
                    vm.languageSelectorHidden = true;
                } else if (vm.languages.length > 0) {
                    var companyDefaultLanguageIndex = vm.languages.indexOf(Session.companyDefaultLanguage);
                    if (companyDefaultLanguageIndex === -1) {
                        // Select first displayed language if company's default language is not available
                        vm.selectedLanguage = vm.languages[0];
                    } else if (companyDefaultLanguageIndex > 0) {
                        // Show company's default language the first
                        var defaultLanguage = vm.languages[companyDefaultLanguageIndex];
                        vm.languages.splice(companyDefaultLanguageIndex, 1);
                        vm.languages.splice(0, 0, defaultLanguage);
                    }
                }
                setPublicationLanguagesInSummary(vm.languages);
            }

            function init() {
                setScreenWidth($window.innerWidth);
                if (breadcrumbParentInfo.type === CONTENT_KIND.MESSAGE) {
                    vm.backPath = '/messages';
                } else if (breadcrumbParentInfo.type === CONTENT_KIND.KNOWLEDGE_PILL) {
                    vm.backPath = '/knowledge-center';
                }
                DataService.findOne($routeParams.id).then(function (response) {
                    vm.publication = response.data;
                    setPreviewClassBasedOnScreenWidth();
                    initializeContentLanguages(response.data.contents);
                });
            }

            function setScreenWidth(windowWidth) {
                vm.smallScreen = windowWidth < 1750;
            }

            function setPreviewClassBasedOnScreenWidth() {
                if (vm.smallScreen && vm.publication.pushEnabled == true && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.PUSH) {
                    vm.devicePreviewKind = 'notification';
                } else if (vm.smallScreen && vm.currentPreviewState === vm.DEVICE_PREVIEW_STATES.PREVIEW) {
                    vm.devicePreviewKind = 'detail';
                } else {
                    vm.devicePreviewKind = '';
                }
            }

            angular.element($window).on('resize', function (event) {
                setScreenWidth(event.currentTarget.innerWidth);
                setPreviewClassBasedOnScreenWidth();
            });

            vm.setLanguage = function (language) {
                vm.selectedLanguage = language;
            };

            vm.cloneContent = function () {
                DataService.duplicate($routeParams.id).then(function(response) {
                    if (breadcrumbParentInfo.type === CONTENT_KIND.MESSAGE) {
                        $location.path('/messages/content/update/' + response.data.id);
                    } else if (breadcrumbParentInfo.type === CONTENT_KIND.KNOWLEDGE_PILL) {
                        $location.path('/knowledge-center/content/update/' + response.data.id);
                    }
                });
            };

            init();
        }
    ]);

})();
