'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.categoryService
 * @description
 * # categoryService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('CategoryService',
    ['$http', '$translate', 'CategoriesService', 'KendoUtilsService', 'LanguageService', 'SharedDataService', 'NotificationService', 'PAGE_SIZE',
    function ($http, $translate, CategoriesService, KendoUtilsService, LanguageService, SharedDataService, NotificationService, PAGE_SIZE) {

    this.availableColors = {
        redDark: 10362398, orangeDark: 13916194, yellowDark: 12487702, greenDark: 2198821,blueDark: 31872,
        secondaryBlueDark: 739233, purpleDark: 7809446, pinkDark: 10687892, greyDark: 4936020,
        redBase: 13839915, orangeBase: 15628583, yellowBase: 15908385, greenBase: 5095769, blueBase: 374214,
        secondaryBlueBase: 2654180, purpleBase: 10375914, pinkBase: 14901206, greyBase: 7893368,
        redLight: 16734286, orangeLight: 16753475, yellowLight: 16765501, greenLight: 7199352, blueLight: 1626340,
        secondaryBlueLight: 3059702, purpleLight: 11760868, pinkLight: 15636463, greyLight: 10725031,
        redLightest: 16744833, orangeLightest: 16760687, yellowLightest: 15653731, greenLightest: 10151842, blueLightest: 6481395,
        secondaryBlueLightest: 7586806, purpleLightest: 12889335, pinkLightest: 16167659, greyLightest: 12435393
    };

    var categoriesIconValueTemplate = '<div class="category-container-select">' +
            '<span class="category-full-color" ng-bind="dataItem.name" data-ng-style="{\'background-color\': (dataItem.color | numberColorFilter)};"></span>' +
        '</div>';

    this.URL = SharedDataService.apiUrl + '/categories';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findAllActive = function () {
        return $http({
            url: this.URL + '/active',
            method: 'GET'
        });
    };

    this.findAllKnowledgeCenterHierarchy = function() {
        return $http({
            url: this.URL + '/knowledge-center/hierarchy',
            method: 'GET'
        })
    }

    this.findOne = function (id) {
        return $http({
            url: this.URL + '/' + id,
            method: 'GET'
        });
    };

    function getCategoryAsFormData(data) {
        var fd = new FormData();
        fd.append('category', new Blob([JSON.stringify(data.category)], { type: "application/json" }));
        for (var index = 0; index < data.images.length; index++) {
            fd.append('image', data.images[index].image, data.images[index].name);
        }
        return fd;
    }

    this.update = function (data) {
        var fd = getCategoryAsFormData(data);
        return $http({
            url: this.URL + '/' + data.category.id,
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.create = function (data) {
        var fd = getCategoryAsFormData(data);
        return $http({
            url: this.URL,
            method: 'POST',
            data: fd,
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    };

    this.delete = function (id) {
        return CategoriesService.deleteCategoryAsPromise(id);
    };

    this.archive = function (id) {
        return CategoriesService.archiveCategoryAsPromise(id);
    };

    this.activate = function (id) {
        return CategoriesService.activateCategoryAsPromise(id);
    };

    this.getCategoriesIconValueDropDownListOptions = function() {
        var options = KendoUtilsService.getDropDownListOptions();
        var url = SharedDataService.apiUrl + '/categories/active';
        options.dataSource.transport.read = function(event) {
            $http({
                url: url,
                method: 'GET'
            }).then(function(response) {
                event.success(response.data);
            });
        };
        options.optionLabel = {
            name: $translate.instant('global.form.select')
        };
        options.optionLabelTemplate = '<span class="black45" translate="global.form.select"></span>';
        options.dataBound = function(event) {
            options.categoryHasData = event.sender.dataSource.data().length > 0;
        };
        options.open = function(event) {
            if (event.sender.dataSource.data().length == 0) {
                event.preventDefault();
                var message = $translate.instant('categories.noCategoriesAvailables');
                NotificationService.notifyInfo(message, false, {delay: 5000});
            }
        }
        options.dataTextField = 'name';
        options.template = categoriesIconValueTemplate;
        options.valueTemplate = categoriesIconValueTemplate;
        options.noDataTemplate = $translate.instant('global.form.emptyDataSet');
        return options;
    };

    function isCurrentLanguagePublicationValid(content) {
        return typeof content !== "undefined" && !!content.name;
    }

    this.validateNonEmptyLanguages = function (formData) {
        return LanguageService.validatePublicationNonEmptyLanguages(formData, isCurrentLanguagePublicationValid);
    };

}]);
