'use strict';

/**
 * @ngdoc filter
 * @name dialengaExeviBackoffice.filter:tickPlusDateFilter
 * @function
 * @description
 * # tickPlusDateFilter
 * Filter in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').filter('tickPlusDateFilter',
    ['$sce', '$filter',
    function ($sce, $filter) {
    return function (date) {
        var html = '';

        if (date) {
            var value;
            var answer;

            if (angular.isNumber(date)) {
                value = $filter('date')(date, 'medium');
            } else if (angular.isArray(date)) {
                var answers = date;

                if (answers.length) {
                    for (var i = 0; i < answers.length; i++) {
                        if (!answer || answers[i].selectedDate > answer.selectedDate) {
                            answer = answers[i];
                        }
                    }
                }
            }

            if (answer) {
                html = '<i class="icon-ok fa-2x success-color" kendo-tooltip k-content="' + $filter('date')(answer.selectedDate, 'medium') + '"></i><span class="answer-short-text" kendo-tooltip k-content="' + answer.text + '">' + answer.text + '</span>';
            } else if (value) {
                html = '<i class="icon-ok fa-2x success-color" kendo-tooltip k-content="' + value + '"></i>';
            }
        }

        return $sce.trustAsHtml(html);
    };
}]);
