(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:disabledButton
     * @description
     * # disabledButton
     */
    angular.module('dialengaExeviBackoffice').component('disabledButton', {
        bindings: {
            buttonId: '<?',
            classes: '<',
            text: '<?',
            tooltipText: '<?',
            iconClass: '<?'
        },
        controller: function () {
            var vm = this;
        },
        controllerAs: 'vm',
        template: '<div class="inline-block">' +
                '<button ng-attr-id="{{vm.buttonId}}" class="button button--disabled" ng-class="vm.classes" ' +
                    'kendo-tooltip k-content="\'{{vm.tooltipText}}\' | translate" type="button">' +
                    '<span ng-if="vm.iconClass" class="button__icon icon {{vm.iconClass}}"></span>' +
                    '<span class="button__label" translate="{{vm.text}}" ng-if="vm.text"></span>' +
                '</button>' +
            '</div>'
    });
})();