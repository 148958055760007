(function() {
    'use strict';

    /**
     * @ngdoc component
     * @name dialengaExeviBackoffice.component:feedbackTrayQuestionnaireAnswer
     * @description
     * # feedbackTrayQuestionnaireAnswer
     */
    angular.module('dialengaExeviBackoffice').component('feedbackTrayQuestionnaireAnswer', {
        bindings: {
            answer: '=',
            question: '=',
            answerIndex: '<',
            isLinkOptional: '<',
            linkableQuestions: '<',
            language: '<',
            validationFunction: '&',
            onChange: '&'
        },
        templateUrl: '/ng1/components/feedback-tray/feedback-tray-questionnaire-answer.html',
        controllerAs: 'vm',
        controller: ['FeedbackTraysService', function(FeedbackTraysService) {
            var vm = this;
            vm.isSegmentsActive = false;

            this.$onInit = function() {
                vm.questions = [];

                if (vm.linkableQuestions.length > 0) {
                    vm.linkedFieldsOptions = FeedbackTraysService.getLinkedQuestionsDropDownListOptions(vm.linkableQuestions);
                }
            };

            vm.showLinkActive = function ($event) {
                if (vm.question.multipleOptions.multiple && !$event.target.className.includes('active')) {
                    // If target includes 'active' class, click is triggered from HTML.
                    // If not, is triggered from multiple selection toggle in QuestionnaireQuestionController
                    return;
                }
                vm.answer.isLinkActive = !vm.answer.isLinkActive;
                if (!vm.answer.isLinkActive) {
                    vm.answer.linkedField = null;
                }
            };

            vm.removeAnswerOption = function (answerIndex) {
                var answerOrder = vm.question.answers[answerIndex].order;
                vm.question.answers.splice(answerIndex, 1);
        
                // Reorder answers
                var numAnswers = vm.question.answers.length;
                for (var index = 0; index < numAnswers; index++) {
                    if (vm.question.answers[index].order > answerOrder)
                        vm.question.answers[index].order--;
                }
        
                vm.validationFunction();
            };
        }]
    });
})();