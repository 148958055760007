'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:MessagesController
 * @description
 * # MessagesController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('MessagesController', 
    ['$scope', '$location', '$translate', '$timeout', 'KendoUtilsService', 'MessageService', 'ModalService', 'PublicationService', 'DateUtilsService', 'SharedDataService', 'NotificationService', 'Session', 'RATING_KIND', 'EXPIRATION_MODE',
    'CONTENT_KIND', 'MESSAGE_STATES', 'PAGE_SIZE', 'GRID_ICON_COLUMN_WIDTH', 'GRID_DATE_COLUMN_WIDTH', 'GRID_CATEGORY_COLUMN_WIDTH', 'GRID_RATING_COLUMN_WIDTH', 'PUBLICATION_SCHEDULED_KIND',
    function($scope, $location, $translate, $timeout, KendoUtilsService, MessageService, ModalService, PublicationService, DateUtilsService, SharedDataService, NotificationService, Session, RATING_KIND, EXPIRATION_MODE,
        CONTENT_KIND, MESSAGE_STATES, PAGE_SIZE, GRID_ICON_COLUMN_WIDTH, GRID_DATE_COLUMN_WIDTH, GRID_CATEGORY_COLUMN_WIDTH, GRID_RATING_COLUMN_WIDTH, PUBLICATION_SCHEDULED_KIND) {

        //******************************************* Attributes ************************************\\
        $scope.actionButtons = [];
        $scope.stateIsActive = true;
        $scope.inputSearchField = "";
        $scope.selectedMessage = null;
        $scope.isUserPublisherOrAdmin = Session.isUserPublisherOrAdmin;
        $scope.isUserOnlyEditor = Session.isUserOnlyEditor;
        $scope.viewStatisticsAllowed = Session.editorPermissions ? Session.editorPermissions.viewStatisticsAllowed : false;
        $scope.publishContentAllowed = Session.editorPermissions ? Session.editorPermissions.publishContentAllowed : false;

        $scope.states = MESSAGE_STATES;
        $scope.EXPIRATION_MODE = EXPIRATION_MODE;
        $scope.PUBLICATION_SCHEDULED_KIND = PUBLICATION_SCHEDULED_KIND;

        MESSAGE_STATES.ALL = 0;

        $scope.messagesStates = [
            { id: MESSAGE_STATES.ALL, title: 'global.messages.stateFilters.all' },
            { id: MESSAGE_STATES.DRAFT, title: 'global.messages.stateFilters.draft' },
            { id: MESSAGE_STATES.PENDING, title: 'global.messages.stateFilters.pending' },
            { id: MESSAGE_STATES.PLANNED, title: 'global.messages.stateFilters.planned' },
            { id: MESSAGE_STATES.PUBLISHED, title: 'global.messages.stateFilters.published' }
        ];

        var gridColumns = [{
            field: "state",
            title: " ",
            template: '<span class="{{dataItem.state | stateIconFilter}}" data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + '"' +
                ' kendo-tooltip k-content="\'{{dataItem.state}}\' | stateTooltipFilter"></span>' +
                '<div data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '"><spinner-dialenga></spinner-dialenga></div>',
            width: GRID_ICON_COLUMN_WIDTH,
            sortable: false
        }, {
            field: "title",
            title: $translate.instant('model.message.title'),
            template: '<div class="flex align-items-center">' +
            '<no-default-language-tooltip available-languages="dataItem.availableLanguages"></no-default-language-tooltip>' +
            '<span class="icon icon-gallery-simple icon-24 margin-right-5" ng-if="dataItem.gallery"></span><span class="truncate" data-ng-bind="dataItem.title" title="{{dataItem.title}}"></span></div>'
        }, {
            field: "category.name",
            title: $translate.instant('model.message.category'),
            template: '<span data-ng-bind-html="dataItem.category | messageCategoryFilter"></span>',
            width: GRID_CATEGORY_COLUMN_WIDTH
        }, {
            field: "publicationDate",
            title: $translate.instant('model.message.publicationDate'),
            type: "datetime",
            format: "{0:dd/MM/yyyy HH:mm}",
            width: GRID_DATE_COLUMN_WIDTH
        }, {
            field: "autoExpiration.autoExpirationEnabled",
            title: " ",
            template: '<div data-ng-if="dataItem.autoExpiration.autoExpirationEnabled == true">' +
                            '<div data-ng-if="!expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) || (expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && (dataItem.state == states.EXPIRED || dataItem.state == states.PUBLISHED))">' +
                                '<div ng-if="!dataItem.expirationMode || dataItem.expirationMode == EXPIRATION_MODE.AUTO_EXPIRED">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDate\' | translate:dataItem" </span>' +
                                '</div>' +
                                '<div ng-if="dataItem.expirationMode == EXPIRATION_MODE.MANUALLY">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color opacity-50" kendo-tooltip k-content="\'model.message.tooltipExpirationDateManual\' | translate:dataItem"></span>' +
                                '</div>' +
                            '</div>' +
                            '<div data-ng-if="expirationDateIsEarlier(dataItem.autoExpiration.autoExpirationDate) && dataItem.state !== states.EXPIRED && dataItem.state !== states.PUBLISHED">' +
                                '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED">' +
                                    '<span class="icon icon-autoarchive icon-40 red-icon-color" kendo-tooltip k-content="\'model.message.tooltipExpirationDateExpired\' | translate:dataItem"></span>' +
                                '</div>' +
                                '<div ng-if="dataItem.scheduleKind == PUBLICATION_SCHEDULED_KIND.NOW">' +
                                    '<span class="icon icon-autoarchive icon-40 grey-icon-color" kendo-tooltip k-content="getTooltipForScheduledNow(dataItem.autoExpiration)"></span>' +
                                '</div>' +
                            '</div>' +
                        '</div>',
            width: 70,
            sortable: false
        },{
            field: "dialengaHappinessScore",
            title: $translate.instant('model.message.dhs'),
            headerAttributes: { class: "text-center" },
            template: '<rating-icon data-ng-if="dataItem.dialengaHappinessScore" value="dataItem.dialengaHappinessScore" show-title="true"></rating-icon>',
            attributes: { class: "text-center" },
            width: Session.ratingKind === RATING_KIND.EMOJI ? GRID_ICON_COLUMN_WIDTH : GRID_RATING_COLUMN_WIDTH,
            sortable: false
        }, {
            command: [{
                name: "action-button",
                template: '<div class="td-on-hover"><a data-ng-class="{\'link-inactive opacity-50\': button.disabled}" class="k-button k-grid-edit-button" ' +
                    'kendo-tooltip k-content="\'{{button.title}}\' | translate" ' +
                    'data-ng-repeat="button in actionButtons[dataItem.state]" data-ng-click="performAction(dataItem, button.action)" ' +
                    'data-ng-if="dataItem.state != ' + MESSAGE_STATES.PUBLISHING_NOW + 
                    ' && (!button.conditionalField || (!!dataItem[button.conditionalField] && dataItem.scheduleKind == ' + PUBLICATION_SCHEDULED_KIND.SCHEDULED +') || dataItem.scheduleKind == '+ PUBLICATION_SCHEDULED_KIND.NOW +')">' +
                    '<span data-ng-class="button.icon"></span></a>' +
                    '<span data-ng-if="dataItem.state == ' + MESSAGE_STATES.PUBLISHING_NOW + '" data-translate="global.states.publishing"></span></div>'
            }],
            headerAttributes: { class: "table-header-icons-container" },
            attributes: { class: 'text-right overflow-visible' },
            title: ""
        }];

        var gridDataSource = {
            transport: {
                read: function(options) {
                    options.data.state = MessageService.getStateFilter($scope.stateIsActive, $scope.selectedButton);
                    saveGridPreferences(options);

                    var defaultSortOrder = ['lastModifiedDate,desc', 'publicationDate,desc'];
                    var requestOptions = KendoUtilsService.getGridRequestOptions(options, defaultSortOrder, $scope.inputSearchField, 'omniSearch');
                    MessageService.findAll(requestOptions).then(function(response) {
                        PublicationService.planUpdateIfThereArePublishingPublications(response.data.content, $scope.messagesGrid.dataSource);
                        $scope.numResults = response.data.totalElements;
                        options.success(response.data);
                    }, function() {
                        options.error();
                    });
                }
            },
            schema: {
                data: "content",
                total: "totalElements",
                parse: function(data) {
                    $.each(data.content, function(idx, elem) {
                        if (elem.publicationDate)
                            data.content[idx].publicationDate = new Date(elem.publicationDate);
                    });
                    return data;
                }
            },
            pageSize: PAGE_SIZE,
            serverPaging: true,
            serverSorting: true
        };

        KendoUtilsService.initializeGrid(gridColumns, gridDataSource, { autoBind: false, selectable: true }, { change: onChange, dataBound: onDataBound }).then(function(response) {
            $scope.gridOptions = response;
        });

        //******************************************** Methods **************************************\\
        var init = function() {
            $scope.actionButtons[MESSAGE_STATES.DRAFT] = [];
            $scope.actionButtons[MESSAGE_STATES.PENDING] = [];
            $scope.actionButtons[MESSAGE_STATES.PLANNED] = [];
            $scope.actionButtons[MESSAGE_STATES.PUBLISHED] = [];
            $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW] = [];
            $scope.actionButtons[MESSAGE_STATES.EXPIRED] = [];
            $scope.actionButtons[MESSAGE_STATES.DELETED] = [];

            // User has Admin or publisher Rol or has editor rol and can publish contents (is like publisher rol)
            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'validate', title: 'global.form.validate', icon: 'icon icon-double-validation' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send' });
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'pauseEdit', title: 'global.form.pauseedit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
                
                if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                }
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'archive', title: 'global.form.archive', icon: 'icon icon-archive' });
                
                $scope.actionButtons[MESSAGE_STATES.PUBLISHING_NOW].push({ action: 'sendNow', title: 'global.form.sendnow', icon: 'icon icon-send', disabled: true });
            // User has Editor role and not allowed publish contents
            } else {

                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'requestValidation', title: 'global.form.pendingvalidation', icon: 'icon icon-first-validation', conditionalField: 'publicationDate' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'update', title: 'global.form.continueedition', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.DRAFT].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });

                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'update', title: 'global.form.edit', icon: 'icon icon-edit' });
                $scope.actionButtons[MESSAGE_STATES.PENDING].push({ action: 'delete', title: 'global.form.delete', icon: 'icon icon-delete' });
             
                $scope.actionButtons[MESSAGE_STATES.PLANNED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                
                if ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) {
                    $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
                } 
                $scope.actionButtons[MESSAGE_STATES.PUBLISHED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
                
            }
            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'statistics', title: 'global.form.viewreport', icon: 'icon icon-statistics' });
            }
            $scope.actionButtons[MESSAGE_STATES.EXPIRED].push({ action: 'view', title: 'global.form.view', icon: 'icon icon-eye-opened' });
        };

        function saveGridPreferences(options) {
            KendoUtilsService.gridPreferences['messagesGrid'] = angular.copy(options.data);
            KendoUtilsService.gridPreferences['messagesGrid'].stateIsActive = $scope.stateIsActive;
            KendoUtilsService.gridPreferences['messagesGrid'].selectedButton = $scope.selectedButton;
            KendoUtilsService.gridPreferences['messagesGrid'].inputSearchField = $scope.inputSearchField;
        }

        $scope.$on("kendoWidgetCreated", function(event, widget) {
            if (widget === $scope.messagesGrid) {
                if (KendoUtilsService.gridPreferences['messagesGrid']) {
                    var requestOptions = KendoUtilsService.gridPreferences['messagesGrid'];
                    $scope.stateIsActive = requestOptions.stateIsActive;
                    $scope.selectedButton = requestOptions.selectedButton;
                    $scope.inputSearchField = requestOptions.inputSearchField;

                    $scope.messagesGrid.dataSource.query(requestOptions);
                } else {
                    $scope.selectedButton = MESSAGE_STATES.ALL;
                    $scope.messagesGrid.dataSource.read();
                }
            }
        });

        $scope.getTooltipForScheduledNow = function(item) {
            return $translate.instant('model.message.tooltipExpirationDateFromNow', {amount: item.autoExpirationAmount, kind: item.autoExpirationKind}, 'messageformat');
        }

        $scope.listItemsSearch = function(text) {
            $scope.inputSearchField = text;
            if (KendoUtilsService.shouldSearchBeApplied($scope.inputSearchField)) {
                reloadGrid({ page: 1 });
            }
        };

        $scope.expirationDateIsEarlier = function(expirationDate) {
            var currentDate = new Date();
            return new Date(expirationDate) <= currentDate;
        };

        function onDataBound() {
            KendoUtilsService.hideGridPagerIfJustOnePage(this);
            $scope.selectedMessage = null;

            PublicationService.hideGridColumnIfContentIsNotPublished($scope.messagesGrid, 'dialengaHappinessScore', $scope.stateIsActive, $scope.selectedButton);
        }

        function onChange() {
            var selectedRow = this.select();
            var newSelectedMessage = $scope.messagesGrid.dataItem(selectedRow[0]);

            if (newSelectedMessage !== $scope.selectedMessage) {
                $scope.selectedMessage = (selectedRow.length > 0) ? newSelectedMessage : null;
            } else {
                // Deselect the row
                this.clearSelection();
                $scope.selectedMessage = null;
            }
        }

        $scope.setSelected = function(button) {
            $scope.selectedButton = button.id;
            reloadGrid({ page: 1 });
        };

        function pauseAndEditContent(id, state) {
            var skipModalAlias = 'pauseAndEditPublication';
            var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
            if (!shouldModalBeDisplayed) {
                MessageService.pause(id)
                    .then(function() {
                        $location.path('/messages/content/update/' + id);
                    })
                    .catch(actionErrorHandler);
            } else {
                ModalService.openConfirmationModal({
                    title: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.title' : 'publication.modal.pauseAndEdit.titleForPending',
                    message: state === MESSAGE_STATES.PLANNED ? 'publication.modal.pauseAndEdit.message' : 'publication.modal.pauseAndEdit.messageForPending',
                    messageData: { contentType: CONTENT_KIND.MESSAGE },
                    acceptButtonText: 'global.form.edit',
                    skipModalAlias: skipModalAlias,
                    isTextInterpolated: true,
                    accept: function() {
                        MessageService.pause(id)
                            .then(function() {
                                $location.path('/messages/content/update/' + id);
                            })
                            .catch(actionErrorHandler);
                    }
                });
            }
        }

        function requestValidation(messageId, contentType) {
            MessageService.requestValidation(messageId)
                .then(function() {
                    PublicationService.showSuccessNotification('publication.modal.validationRequest.validationRequested', contentType);
                    reloadGrid();
                })
                .catch(actionErrorHandler);
        }

        $scope.performAction = function(message, action) {
            var contentType = CONTENT_KIND.MESSAGE;
            var date, time;
            switch (action) {
                case 'update':
                    if (message.state === MESSAGE_STATES.PENDING && !$scope.isUserPublisherOrAdmin) {
                        pauseAndEditContent(message.id, MESSAGE_STATES.PENDING);
                    } else {
                        $location.path('/messages/content/update/' + message.id);
                    }
                    break;
                case 'view':
                    $location.path('/messages/view/' + message.id);
                    break;
                case 'validate':
                    date = DateUtilsService.getDateFromTimestamp(message.publicationDate || undefined);
                    time = DateUtilsService.getTimeFromTimestamp(message.publicationDate || undefined);
                    if (message.scheduleKind == PUBLICATION_SCHEDULED_KIND.SCHEDULED && message.publicationDate < new Date()) {
                        date = DateUtilsService.getDateFromTimestamp(undefined);
                        time = DateUtilsService.getTimeFromTimestamp(undefined);
                    }
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.validate.title',
                        message: 'publication.modal.validate.message',
                        messageData: {
                            contentType: contentType,
                            date: date,
                            time: time
                        },
                        acceptButtonText: 'publication.modal.validate.confirm',
                        isTextInterpolated: true,
                        accept: function() {
                            MessageService.validate(message.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.validate.validated', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'requestValidation':
                    if (message.scheduleKind === PUBLICATION_SCHEDULED_KIND.SCHEDULED && !message.publicationDate) {
                        return;
                    }
                    var skipModalAlias = 'requestValidation';
                    var shouldModalBeDisplayed = ModalService.shouldModalBeDisplayed(skipModalAlias);
                    if (!shouldModalBeDisplayed) {
                        requestValidation(message.id, contentType);
                    } else {
                        ModalService.openConfirmationModal({
                            title: 'publication.modal.validationRequest.title',
                            message: 'publication.modal.validationRequest.message',
                            messageData: { contentType: contentType },
                            acceptButtonText: 'global.form.pendingvalidation',
                            isTextInterpolated: true,
                            skipModalAlias: skipModalAlias,
                            accept: function() {
                                requestValidation(message.id, contentType);
                            }
                        });
                    }
                    break;
                case 'pauseEdit':
                    pauseAndEditContent(message.id);
                    break;
                case 'delete':
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.delete.title',
                        message: 'publication.modal.delete.message',
                        messageData: { contentType: contentType },
                        acceptButtonText: 'global.form.delete',
                        isTextInterpolated: true,
                        accept: function() {
                            MessageService.delete(message.id, 'messages.error.delete')
                                .then(reloadGrid)
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'sendNow':
                    date = DateUtilsService.getDateFromTimestamp(message.publicationDate);
                    time = DateUtilsService.getTimeFromTimestamp(message.publicationDate);
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.send.title',
                        message: 'publication.modal.send.message',
                        messageData: {
                            contentType: contentType,
                            date: date,
                            time: time
                        },
                        acceptButtonText: 'global.form.sendnow',
                        isTextInterpolated: true,
                        accept: function() {
                            MessageService.publish(message.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.send.sent', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                case 'statistics':
                    $location.path('/messages/statistics/' + message.id);
                    break;
                case 'archive':
                    ModalService.openConfirmationModal({
                        title: 'publication.modal.archive.title',
                        message: 'publication.modal.archive.message',
                        messageData: { contentType: contentType },
                        acceptButtonText: 'global.form.archive',
                        isTextInterpolated: true,
                        accept: function() {
                            MessageService.expire(message.id)
                                .then(function() {
                                    PublicationService.showSuccessNotification('publication.modal.archive.archived', contentType);
                                    reloadGrid();
                                })
                                .catch(actionErrorHandler);
                        }
                    });
                    break;
                default:
                    break;
            }
        };

        function reloadGrid(options) {
            $scope.isSyncing = true;
            PublicationService.updatePublications($scope.messagesGrid.dataSource, options).then(function() {
                $scope.$apply(function() {
                    $scope.isSyncing = false;
                });
            });
        }

        function actionErrorHandler(error) {
            if (error.status === 422) {
                reloadGrid();
            }
        }

        function redirectToPath(url) {
            $scope.$apply(function() {
                $location.path(url);
            });
        }

        $scope.getMessagesByIsActive = function(isActive) {
            if ($scope.stateIsActive !== isActive) {
                $scope.stateIsActive = isActive;
                reloadGrid({ page: 1 });
            }
        };

        $scope.cloneSelectedContent = function() {
            if (!$scope.selectedMessage || !$scope.selectedMessage.id) {
                return;
            }

            MessageService.duplicate($scope.selectedMessage.id).then(function(response) {
                $location.path('/messages/content/update/' + response.data.id);
                KendoUtilsService.gridPreferences['messagesGrid'].stateIsActive = true;
            });
        };

        /* Download CSV */

        $scope.openDatePopover = false;

        $scope.downloadCSV = function(event) {
            $scope.openDatePopover = false;

            var urlRequests = MessageService.URL + '/statistics/export-csv?startDate=' + event.startDate + '&endDate=' + event.endDate;
            var message = $translate.instant('statistics.downloading');
            var fileName = 'publications-statistics-' + moment().format("YYYYMMDD") + '.csv'

            var beginProcess = new Date().getTime();

            NotificationService.notifyDownload(message, true);

            MessageService.getStatisticsFileCSV(urlRequests).then(function(response) {
                SharedDataService.saveDownloadedFile(response.data, fileName);

                var endProcess = new Date().getTime();
                var delayCloseNotification = (endProcess - beginProcess) <= 1500 ? 1500 - (endProcess - beginProcess) : 0;

                $timeout(function() {
                    NotificationService.closeAllOpenedNotifications();
                }, delayCloseNotification)
            });
            
        }

        $scope.cancelDownload = function(event) {
            $scope.openDatePopover = false;
        }

        /* End Download CSV */


        /* Relocate message to Knowledge Center Modal */
        $scope.relocateMessage = function () {

            if (!$scope.selectedMessage || !$scope.selectedMessage.id) { return; }

            ModalService.openCategoriesModal({
                id: $scope.selectedMessage.category ? $scope.selectedMessage.category.id : null,
                accept: function (newHierarchy) {
                    // Call endpoint to relocate publication and refresh grid
                    MessageService.relocate($scope.selectedMessage.id, newHierarchy.at(-1))
                        .then(function() {
                            var message = $translate.instant('publication.modal.relocate.message');
                            NotificationService.notifySuccess(message);
                            reloadGrid();
                        })
                        .catch(actionErrorHandler);
                    $rootScope.$broadcast('dialenga:loadingEnd');
                },
                cancel: function () {
                    $rootScope.$broadcast('dialenga:loadingEnd');
                }
            });
        };
        /* End relocate message to Knowledge Center */

        $scope.$on('$destroy', function() {
            PublicationService.stopWaitingForUpdates();
        });

        var onDblClick = function(dataItem) {
            var rowState = parseInt(dataItem.state);

            if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.publishContentAllowed)) {
                switch (rowState) {
                    case MESSAGE_STATES.DRAFT:
                    case MESSAGE_STATES.PENDING:
                        redirectToPath('/messages/content/update/' + dataItem.id);
                        break;
                    case MESSAGE_STATES.PUBLISHED:
                        if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                            redirectToPath('/messages/statistics/' + dataItem.id);
                        } else {
                            redirectToPath('/messages/content/update/' + dataItem.id);
                        }
                        break;
                    case MESSAGE_STATES.PLANNED:
                        pauseAndEditContent(dataItem.id, MESSAGE_STATES.PLANNED);
                        break;
                    case MESSAGE_STATES.EXPIRED:
                        if ($scope.isUserPublisherOrAdmin || ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed)) {
                            redirectToPath('/messages/statistics/' + dataItem.id);
                        } else {
                            redirectToPath('/messages/view/' + dataItem.id);
                        }
                        break;
                    default:
                        break;
                }
            // User has Editor role and not allowed publish contents
            } else {
                switch (rowState) {
                    case MESSAGE_STATES.DRAFT:
                        redirectToPath('/messages/content/update/' + dataItem.id);
                        break;
                    case MESSAGE_STATES.PENDING:
                        pauseAndEditContent(dataItem.id, MESSAGE_STATES.PENDING);
                        break;
                    case MESSAGE_STATES.PLANNED:
                        redirectToPath('/messages/view/' + dataItem.id);
                        break;
                    case MESSAGE_STATES.PUBLISHED:
                    case MESSAGE_STATES.EXPIRED:
                        if ($scope.isUserOnlyEditor && $scope.viewStatisticsAllowed) {
                            redirectToPath('/messages/statistics/' + dataItem.id);
                        } else {
                            redirectToPath('/messages/view/' + dataItem.id);
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        KendoUtilsService.dblClickGridCallback('kendo-grid', onDblClick);

        init();
    }
]);