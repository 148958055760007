'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.publicationService
 * @description
 * # publicationService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('PublicationService',
    ['$location','$translate', '$timeout', 'FormUtilsService', 'GlobalService', 'KendoUtilsService', 'ModalService', 'NotificationService', 'UrlUtilsService', 'FileUtilsService',
    'CONTENT_KIND', 'DEVICE_PREVIEW_STATES', 'MESSAGE_STATES', 'UPDATE_PUBLICATION_TIMEOUT',
    function ($location,$translate, $timeout, FormUtilsService, GlobalService, KendoUtilsService, ModalService, NotificationService, UrlUtilsService, FileUtilsService,
        CONTENT_KIND, DEVICE_PREVIEW_STATES, MESSAGE_STATES, UPDATE_PUBLICATION_TIMEOUT) {

    this.devicePreviewState = {
        messageId: null,
        state: DEVICE_PREVIEW_STATES.PREVIEW
    };

    var updatePublicationsListTimeout = null;

    var updatePublicationsList = function (dataSource, options) {
        return KendoUtilsService.reloadGridDatasource(dataSource, options);
    };

    var clearUpdatePublicationsListTimeout = function () {
        $timeout.cancel(updatePublicationsListTimeout);
    }

    var planFutureUpdate = function (dataSource) {
        clearUpdatePublicationsListTimeout();
        updatePublicationsListTimeout = $timeout(updatePublicationsList, UPDATE_PUBLICATION_TIMEOUT, true, dataSource);
    };

    var planUpdateForPublishingPublications = function (publications, dataSource) {
        var areTherePublishingPublications = publications.some(function (element, index, array) {
            return parseInt(element.state) === MESSAGE_STATES.PUBLISHING_NOW;
        });
        if (areTherePublishingPublications) {
            planFutureUpdate(dataSource);
        }
    };

    this.getPublicationAsFormData = function (publication, publicationAlias, images, resources, imagesGallery) {
        var fd = new FormData();
        fd.append(publicationAlias, new Blob([JSON.stringify(publication)], { type: "application/json" }));
        var index;
        if (images && images.length > 0) {
            for (index = 0; index < images.length; index++) {
                fd.append('images[]', images[index].image, images[index].name);
            }
        }
        if (resources && resources.length > 0) {
            for (index = 0; index < resources.length; index++) {
                fd.append('resources[]', resources[index]);
            }
        }
        if (imagesGallery) {
            for (index = 0; index < imagesGallery.images.length; index++) {
                fd.append('gallery', FileUtilsService.dataURItoBlob(imagesGallery.images[index].image.src), imagesGallery.images[index].image.name);
            }
        }
        return fd;
    };

    this.updatePublications = function (dataSource, options) {
        return updatePublicationsList(dataSource, options);
    };

    this.planUpdateIfThereArePublishingPublications = function (publications, dataSource) {
        planUpdateForPublishingPublications(publications, dataSource);
    };

    this.stopWaitingForUpdates = function () {
        clearUpdatePublicationsListTimeout();
    };

    this.showSuccessNotification = function (messageKey, contentType) {
        var message = $translate.instant(messageKey, {contentType: contentType}, 'messageformat');
        NotificationService.notifySuccess(message);
    };

    this.validateUrlLinks = function (data, language, model) {
        if (data.contents[language]) {
            var url = data.contents[language][model];
            if (url) {
                var isValidURL = UrlUtilsService.validateURL(url);
                if (isValidURL) {
                    data.contents[language][model] = UrlUtilsService.concatURLPrefixIfNotExists(url);
                }
                return isValidURL;
            }
        }
        return false;
    };

    this.validateUrls = function (dataUrl) {
        var isValidURL = false;
        if (dataUrl) {
            isValidURL = UrlUtilsService.validateURL(dataUrl);
            if (isValidURL) {
                dataUrl = UrlUtilsService.concatURLPrefixIfNotExists(dataUrl);
            }
            return {valid: isValidURL, data: dataUrl};
        }
        return {valid: isValidURL, data: dataUrl};
    };

    this.hideGridColumnIfContentIsNotPublished = function (grid, columnName, stateIsActive, currentState) {
        if (!stateIsActive || [MESSAGE_STATES.ALL, MESSAGE_STATES.PUBLISHED, MESSAGE_STATES.EXPIRED].indexOf(currentState) > -1) {
            grid.showColumn(columnName);
        } else {
            grid.hideColumn(columnName);
        }
    };

    this.getPublicationKindIcon = function (publicationKind) {
        var publicationKindIcon;
        if (publicationKind === CONTENT_KIND.MESSAGE) {
            publicationKindIcon = 'icon icon-content-message';
        } else if ([CONTENT_KIND.DIALOGUE, CONTENT_KIND.SURVEY].indexOf(publicationKind) !== -1) {
            publicationKindIcon = 'icon icon-content-survey';
        } else if (publicationKind === CONTENT_KIND.KNOWLEDGE_PILL) {
            publicationKindIcon = 'icon icon-content-knowledge-center';
        }
        return publicationKindIcon;
    };

    function isAttachedResourceNotEmptyInOtherLanguages(publicationContents, selectedLanguage) {
        var languages = Object.keys(publicationContents);
        for (var index = 0; index < languages.length; index++) {
            var language = languages[index];
            if (language !== selectedLanguage &&
                (publicationContents[language].resourceURL || publicationContents[language].resourceOrder > -1)) {
                return true;
            }
        }
        return false;
    }

    function otherLanguagesHaveAttachedResources(publicationContents, selectedLanguage) {
        var languages = Object.keys(publicationContents);
        for (var index = 0; index < languages.length; index++) {
            var language = languages[index];
            if (language !== selectedLanguage && !!publicationContents[language].resources && publicationContents[language].resources.length > 0) {
                return true;
            }
        }
        return false;
    }

    this.confirmAttachedResourceCheckboxDeactivation = function (publicationContents, selectedLanguage) {
        var inputId = 'attachedResourceEnabled';
        var askForConfirmation = Object.keys(publicationContents).length > 1 && isAttachedResourceNotEmptyInOtherLanguages(publicationContents, selectedLanguage);
        if (askForConfirmation) {
            ModalService.openConfirmationModal({
                title: 'message.confirmAttachedResourceDeletionTitle',
                message: 'message.confirmAttachedResourceDeletionDescription',
                accept: function () {
                    FormUtilsService.confirmCheckboxDeactivation(inputId, false);
                },
                cancel: function () {
                    FormUtilsService.confirmCheckboxDeactivation(inputId, true);
                }
            });
        } else {
            FormUtilsService.confirmCheckboxDeactivation(inputId, false);
        }
    };

    this.confirmAttachedResourcesDeactivation = function (publicationContents, selectedLanguage) {
        var inputId = 'attachedResourceEnabled';
        var askForConfirmation = Object.keys(publicationContents).length > 1 && otherLanguagesHaveAttachedResources(publicationContents, selectedLanguage);
        if (askForConfirmation) {
            ModalService.openConfirmationModal({
                title: 'message.confirmResourcesDeletionTitle',
                message: 'message.confirmResourcesDeletionDescription',
                accept: function () {
                    FormUtilsService.confirmCheckboxDeactivation(inputId, false);
                },
                cancel: function () {
                    FormUtilsService.confirmCheckboxDeactivation(inputId, true);
                }
            });
        } else {
            FormUtilsService.confirmCheckboxDeactivation(inputId, false);
        }
    };

    function confirmDeleteArchiveAction (title, message, acceptButtonText, promise, id, contentType, DataService, localizedErrorString) {
        ModalService.openConfirmationModal({
            title: title,
            message: message,
            messageData: { contentType: contentType },
            acceptButtonText: acceptButtonText,
            isTextInterpolated: true,
            accept: function () {
                promise.call (DataService, id, localizedErrorString)
                    .then( function() {
                        var dataReturnPath = null;
                        if (contentType === CONTENT_KIND.MESSAGE) {
                            dataReturnPath = "/messages";
                        } else if (contentType === CONTENT_KIND.KNOWLEDGE_PILL) {
                            dataReturnPath = "/knowledge-center";
                        } else if (contentType === CONTENT_KIND.DIALOGUE || contentType === CONTENT_KIND.SURVEY) {
                            dataReturnPath = "/questionnaires";
                        } else {
                            GlobalService.redirectUserToHomeBasedOnRoles();
                        }
                        $location.path(dataReturnPath);
                    })
                    .catch(actionErrorHandler)
            }
        });
    }

    this.archiveOrDelete = function (id,isPublished,contentType,DataService) {
        if (isPublished) {
            confirmDeleteArchiveAction(
                'publication.modal.archive.title',
                'publication.modal.archive.message',
                'global.form.archive',
                DataService.expire,
                id,
                contentType,
                DataService
            );
        } else {
            confirmDeleteArchiveAction(
                'publication.modal.delete.title',
                'publication.modal.delete.message',
                'global.form.delete',
                DataService.delete,
                id,
                contentType,
                DataService,
                'messages.error.delete'
            );
        }
    };

}]);
