/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:HTMLComponentsController
 * @description
 * # HTMLComponentsController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('HTMLComponentsController', ['$scope',
    function($scope) {

        $scope.workingDays = [
            { id: 0, name: 'L', tooltip: 'Lunes', workable: true },
            { id: 1, name: 'M', tooltip: 'Martes', workable: true },
            { id: 2, name: 'X', tooltip: 'Miércoles', workable: true },
            { id: 3, name: 'J', tooltip: 'Jueves', workable: true },
            { id: 4, name: 'V', tooltip: 'Viernes', workable: true },
            { id: 5, name: 'S', tooltip: 'Sábado', workable: false },
            { id: 6, name: 'D', tooltip: 'Domingo', workable: false }
        ]

        $scope.onChange = function() {
            // vm.changed = true;
        };

        var init = function() {};

        init();
    }
]);