'use strict';

/**
 * @ngdoc function
 * @name dialengaExeviBackoffice.controller:CreateSchemaModalController
 * @description
 * # CreateSchemaModalController
 * Controller of the dialengaExeviBackoffice
 */
angular.module('dialengaExeviBackoffice').controller('CreateSchemaModalController',
    ['$scope', '$timeout', '$uibModalInstance', 'TimeoffSettingsSchemesService',
    function ($scope, $timeout, $uibModalInstance, TimeoffSettingsSchemesService) {

    //****************************************** Attributes *************************************\\
    var vm = this;
    vm.changed = false;
    vm.valid = false;
    vm.schemaForm = {
        schema: {
            timeOffSchema: {}
        }
    };
    $timeout(function() {
        $('#schemaName').focus();
    });

    //******************************************* Methods ***************************************\\
    vm.accept = function () {
        TimeoffSettingsSchemesService.create(vm.schemaForm).then(function (response) {
            $uibModalInstance.close(response.data);
        }, function (error) {
            console.error(error);
            $uibModalInstance.close();
        });
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    var validateModel = function () {
        var valid = true;
        if (!vm.schemaForm.schema.timeOffSchema.name) {
            valid = false;
        }
        vm.valid = valid;
    };

    vm.onChange = function () {
        vm.changed = true;
    };

    vm.modalSaveOnEnter = function () {
        var keyCode = event.which || event.keyCode;
        if (keyCode === 13 && vm.valid) {
            vm.accept();
        } 
    };

    $scope.$watch('vm.changed', function (newValue, oldValue) {
        if (newValue) {
            validateModel();
            vm.changed = false;
        }
    });
}]);
