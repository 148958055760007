'use strict';

/**
 * @ngdoc service
 * @name dialengaExeviBackoffice.DocumentsHistoryService
 * @description
 * # DocumentsHistoryService
 * Service in the dialengaExeviBackoffice.
 */
angular.module('dialengaExeviBackoffice').service('DocumentsHistoryService',
    ['$http', 'SharedDataService', 'PAGE_SIZE',
    function ($http, SharedDataService, PAGE_SIZE) {

    this.URL = SharedDataService.apiUrl + '/documents/transactions';

    this.findAll = function (options) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: this.URL,
            method: 'GET',
            params: options
        });
    };

    this.findOne = function (transactionId) {
        return $http({
            url: this.URL + '/' + transactionId,
            method: 'GET'
        })
    };

    this.findAllUserDocuments = function (options, id) {
        if (options) {
            options.page = options.page || 0;
            options.size = options.size || PAGE_SIZE;
            options.sort = options.sort || undefined;
        }
        return $http({
            url: SharedDataService.apiUrl + '/documents/employees/' + id,
            method: 'GET',
            params: options
        });
    };

    this.deleteUserDocument = function (userId, documentId) {
        return $http({
            url: SharedDataService.apiUrl + '/documents/employees/' + userId + '/document/' + documentId,
            method: 'DELETE'
        })
    };

    this.getDocumentKinds = function() {
        return $http({
            url: SharedDataService.apiUrl + '/file-kinds/allowed',
            method: 'GET'
        });
    }

}]);
